import React, { useState, useEffect, useContext } from 'react';
import {
    Datalist,
    AddButton,
    Input,
    CreateItemButtons,
    UpdateItemButtons,
    DeleteItemButtons,
    BackOnlyButton,
    BackSendEditDelButtons,
    ApproveRejectButtons,
    OverrideSaveButtons,
    OverrideWithBackButtons,
    HelpTip,
    TextArea,
    Watermark,
} from '../../components';
import {
    AuthContext,
    MasterListContext,
    DataShareContext,
} from '../../contexts';
import { dbopsSemiFGs } from '../../firebase_dbops';
import {
    permissions,
    userActions,
    lengths,
    systemActions,
} from '../../lib/constants';
import { clone, displayDate, saveWithFiveDecimals } from '../../lib/util';
import ProdSFGForm from './ProdSFGForm';
import ProdSFGView from './ProdSFGView';

import validate from '../../validators';

import '../PagesCommon.css';
import './ProdOrdersPage.css';
import tooltips from '../../lib/tooltips';

let defaultProdOrder = {
    productionUID: '',
    productionNumber: '',
    sfgArr: [],
    meta: { action: '' },
    note: '',
};

export default (props) => {
    let {
        purpose,
        currentDocument,

        backCancelBtnFn,
        gotoEditViewFn,
        gotoDeleteViewFn,
        gotoOverrideViewFn,

        createProdOrderFn,
        deleteProdOrderFn,
        updateProdOrdersFn,
        statusChangeFn,
        overrideProductionFn,

        setIsLoading,
        setAlertErrorMsg,
    } = props;

    let { masterSemiFGsList, masterInventoryRMsList } =
        useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);
    let { dataShare, setDataShare, defaultDataShare, serverDate } =
        useContext(DataShareContext);

    let [generalError, setGeneralError] = useState('');
    let [datalistDisplayResetCounter, setDatalistDisplayResetCounter] =
        useState(1);

    let [productionObj, setProductionObj] = useState(defaultProdOrder);
    let [sfgUIDstate, setSfgUID] = useState('');
    let [prodOrderDateInState, setProdOrderDateInState] = useState(serverDate);
    let [noteInState, setNoteInState] = useState('');
    let [description, setDescription] = useState('');

    let [preview, setPreview] = useState(false);
    let [confirmDeleteText, setConfirmDeleteText] = useState('');

    // set productionObj from currentDocument if purpose !== 'ADD'
    useEffect(() => {
        if (purpose !== 'ADD') {
            // making a copy so no side effect will change currentDocument.
            let currentDocumentCopy = {
                // will copy meta and metaHistory as is.
                ...currentDocument,
                // copy rmArr and not a reference.
                sfgArr: clone(currentDocument.sfgArr),
            };

            setProdOrderDateInState(currentDocumentCopy.prodOrderDate);
            setProductionObj(currentDocumentCopy);
            setNoteInState(currentDocumentCopy.note || '');
            setDescription(currentDocumentCopy.productionDescription || '');
        }
    }, []);

    //! ==========================================================
    //? Code to handle creating Production Order from inventory SFG.

    useEffect(() => {
        if (dataShare.isActive) {
            setSfgUID(dataShare.data);
        }
    }, []);

    useEffect(() => {
        if (sfgUIDstate && dataShare.isActive) {
            //! reset dataShare to default values
            setDataShare(defaultDataShare);

            addToSfgArrFn();
        }
    }, [sfgUIDstate]);
    //! ==========================================================

    const cancelBtnFromEditViewFn = () => {
        // 1. reset values.
        setProductionObj(currentDocument);

        // 2. reset errorMessages.
        setGeneralError('');

        // 3. go back to profile view.
        backCancelBtnFn();
    };

    // =====================================================

    const deleteErrors = (prodCopy) => {
        delete prodCopy.prodOrderDateError;
        delete prodCopy.noteError;
        delete prodCopy.productionDescriptionError;

        prodCopy.sfgArr.forEach((sfg) => {
            delete sfg.sfgOrderedQtyError;
            delete sfg.sfgProducedQtyError;

            sfg.rmArr.forEach((rm) => {
                delete rm.rmProjectedUseQtyError;
                delete rm.rmActualUsedQtyError;
            });
        });
        setGeneralError('');
    };

    const addToSfgArrFn = async () => {
        let prodCopy = {
            ...productionObj,
            sfgArr: clone(productionObj.sfgArr),
        };

        deleteErrors(prodCopy);

        setIsLoading(true);
        let response = await dbopsSemiFGs.getSFGFn(sfgUIDstate);
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message || response.message);
            setIsLoading(false);
            return;
        }

        let sfgDoc = response.data;
        sfgDoc.sfgOrderedQty = 0;
        sfgDoc.sfgBalanceQty = 0;
        sfgDoc.sfgOverrideQty = 0;
        sfgDoc.sfgProducedQty = 0;

        sfgDoc.rmArr.forEach((rm) => {
            rm.rmProjectedUseQty = 0;
            rm.rmActualUsedQty = 0;
            rm.rmBalanceQty = 0;
            rm.rmOverrideQty = 0;
        });
        prodCopy.sfgArr.push(sfgDoc);

        setProductionObj(prodCopy);
        setDatalistDisplayResetCounter(datalistDisplayResetCounter + 1);
        setIsLoading(false);
    };

    const removeSfgFromArrFn = (index) => {
        let prodCopy = {
            ...productionObj,
            sfgArr: clone(productionObj.sfgArr),
        };

        deleteErrors(prodCopy);

        prodCopy.sfgArr.splice(index, 1);
        setProductionObj(prodCopy);
    };

    const updateSfgValFn = (val, sfgIndex, propertyName) => {
        let prodCopy = {
            ...productionObj,
            sfgArr: clone(productionObj.sfgArr),
        };

        // perform this only when not in override.
        if (purpose !== 'OVERRIDE') {
            //!  default value for rms that are alone in their group.
            let rmAloneMap = {};

            prodCopy.sfgArr[sfgIndex].rmArr.forEach((rm) => {
                rmAloneMap[rm.group] =
                    rmAloneMap[rm.group] !== undefined ? false : true;
            });
            prodCopy.sfgArr[sfgIndex].rmArr.forEach((rm) => {
                if (rmAloneMap[rm.group])
                    rm.rmProjectedUseQty = saveWithFiveDecimals(
                        rm.rmRequiredQty * val
                    );
            });
            //! =====================================================
        }

        prodCopy.sfgArr[sfgIndex][propertyName] = val;

        setProductionObj(prodCopy);
    };

    const updateRmValFn = (val, sfgIndex, rmIndex, propertyName) => {
        let prodCopy = {
            ...productionObj,
            sfgArr: clone(productionObj.sfgArr),
        };

        prodCopy.sfgArr[sfgIndex].rmArr[rmIndex][propertyName] = val;

        setProductionObj(prodCopy);
    };

    // for saving functionality
    const previewPOBtnFn = () => {
        let prodCopy = {
            ...productionObj,
            sfgArr: clone(productionObj.sfgArr),
        };
        prodCopy.prodOrderDate = prodOrderDateInState;
        prodCopy.note = noteInState;
        prodCopy.productionDescription = description;

        let response = validate.prodOrders(
            prodCopy,
            purpose,
            masterInventoryRMsList
        );
        if (response.error) {
            setProductionObj(response.errorObj.productionObj);
            setAlertErrorMsg(response.errorObj.hackError);
            setGeneralError(response.errorObj.generalError);
        } else {
            // no error
            setProductionObj(response.data);
            setPreview(true);
        }
    };

    const saveProdOrderFn = (userActionPassed) => {
        let prodCopy = {
            ...productionObj,
            sfgArr: clone(productionObj.sfgArr),
        };
        prodCopy.prodOrderDate = prodOrderDateInState;
        prodCopy.note = noteInState;
        prodCopy.productionDescription = description;

        // check if there is a change in the payload from currentDocument
        if (
            (purpose === 'EDIT' || purpose === 'OVERRIDE') &&
            validate.noChange(prodCopy, currentDocument)
        ) {
            backCancelBtnFn();
            return;
        }

        let response = validate.prodOrders(
            prodCopy,
            purpose,
            masterInventoryRMsList,
            userActionPassed
        );
        if (response.error) {
            console.error(response.errorObj.productionObj);
            setProductionObj(response.errorObj.productionObj);
            setAlertErrorMsg(response.errorObj.hackError);
            setGeneralError(response.errorObj.generalError);
            return;
        }

        //! this will ensure no tempRmIndex or tempSfgIndex that the app puts into
        //! the productionObj to display correctly.
        let validObj = {
            ...response.data,
            sfgArr: clone(response.data.sfgArr),
        };
        setProductionObj(response.data);

        if (purpose === 'ADD') createProdOrderFn(validObj);
        if (purpose === 'EDIT') updateProdOrdersFn(validObj, currentDocument);
        if (purpose === 'DELETE') deleteProdOrderFn(validObj, currentDocument);

        if (purpose === 'OVERRIDE')
            overrideProductionFn(validObj, currentDocument);
    };

    const statusChangeLocalFn = (userActionPassed) => {
        let prodCopy = {
            ...productionObj,
            sfgArr: clone(productionObj.sfgArr),
        };
        prodCopy.prodOrderDate = prodOrderDateInState;
        prodCopy.note = noteInState;
        prodCopy.productionDescription = description;

        // just validate to be sure schema is correct.
        let response = validate.prodOrders(
            prodCopy,
            purpose,
            masterInventoryRMsList,
            userActionPassed
        );
        if (response.error) {
            console.error(response.errorObj.productionObj);
            setProductionObj(response.errorObj.productionObj);
            setAlertErrorMsg(response.errorObj.hackError);
            setGeneralError(response.errorObj.generalError);
            return;
        }

        //! this will ensure no tempRmIndex or tempSfgIndex that the app puts into
        //! the productionObj to display correctly.
        let validObj = {
            ...response.data,
            sfgArr: clone(response.data.sfgArr),
        };
        statusChangeFn(userActionPassed, validObj, currentDocument);
    };

    // conditional render of contents  =============================

    let sfgArrUI = [];
    let noteUI = null;

    let buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    let mainContent = null;
    let datalist = null;

    productionObj = {
        ...productionObj,
        sfgArr: clone(productionObj.sfgArr),
    };

    let addBtn = <AddButton disabled />;

    let productionDateInputUI = (
        <div className='form-profile-info-items'>
            <p className='fpii-label'>SFG Job Order Date</p>
            <Input
                type='date'
                maxLength='12'
                onChange={(e) => setProdOrderDateInState(e.target.value)}
                value={prodOrderDateInState}
                errorMessage={productionObj.prodOrderDateError}></Input>
        </div>
    );

    let productionDateDisplayUI = (
        <div className='form-profile-info-items'>
            <p className='fpii-label'>SFG Job Order Date</p>
            <p className='fpii-text'>
                {productionObj.prodOrderDate
                    ? displayDate(productionObj.prodOrderDate)
                    : '---'}
            </p>
        </div>
    );

    let sfgListLabel = (
        <div className='span-2-columns-container label-padding label-highlight'>
            <label className='parent-labels'>Semi-Finished Goods List</label>
        </div>
    );

    if (
        (purpose === 'ADD' && !preview) ||
        (purpose === 'EDIT' &&
            (productionObj.meta.action === userActions.CREATED ||
                productionObj.meta.action === userActions.UPDATED ||
                productionObj.meta.action === userActions.REJECTED))
    ) {
        let datalistOptions = masterSemiFGsList.map((sfg) => sfg.sfgUID);
        let sfgAlreadyInTheUI = productionObj.sfgArr.map((sfg) => sfg.sfgUID);

        if (
            datalistOptions.includes(sfgUIDstate) &&
            !sfgAlreadyInTheUI.includes(sfgUIDstate)
        )
            addBtn = <AddButton onClick={addToSfgArrFn} />;

        let datalistLabel = (
            <span>
                Add Semi-Finished Good
                <HelpTip content={tooltips.prodOrderSfgArr}></HelpTip>
            </span>
        );

        datalist = (
            <div className='get-item-from-db-container'>
                <div className='datalist-with-addbtn-container'>
                    <Datalist
                        label={datalistLabel}
                        list={datalistOptions}
                        initialValue={''}
                        getInputValue={(val) => setSfgUID(val)}
                        errorMessage={''}
                        resetCounter={datalistDisplayResetCounter}></Datalist>
                    {addBtn}
                </div>
            </div>
        );

        for (let index = 0; index < productionObj.sfgArr.length; index += 1) {
            let trashIcon = (
                <i
                    className='ms-Icon ms-Icon--Delete clickable-icon'
                    aria-hidden='true'
                    onClick={() => removeSfgFromArrFn(index)}></i>
            );

            //! ====================================================
            //! bug fix. sfgObj will be copied to ensure tempRmIndex that will be
            //! added inside the rmObjs will not affect the state productionObj.
            let sfgCopy = {
                ...productionObj.sfgArr[index],
                rmArr: clone(productionObj.sfgArr[index].rmArr),
            };

            //! ===================================================

            sfgArrUI.push(
                <div className='span-2-columns-container' key={index}>
                    <ProdSFGForm
                        trashIcon={trashIcon}
                        sfgIndex={index}
                        sfgObj={sfgCopy}
                        masterInventoryRMsList={masterInventoryRMsList}
                        updateSfgValFn={updateSfgValFn}
                        updateRmValFn={updateRmValFn}
                    />
                </div>
            );
        }

        if (sfgArrUI.length === 0) sfgListLabel = null;

        noteUI = (
            <div className='span-2-columns-container'>
                <TextArea
                    label={<span className='parent-labels'>Notes</span>}
                    maxLength={lengths.noteLength}
                    value={noteInState}
                    onChange={(e) => setNoteInState(e.target.value)}
                    errorMessage={productionObj.noteError}
                />
            </div>
        );

        //! Max number of rms in the list
        if (productionObj.sfgArr.length >= lengths.prodOrderSfgArr)
            datalist = null;

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>SFG Job Order</p>
                    <p className='fpii-text'>
                        {productionObj.productionUID || `(Auto Generated)`}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Status</p>
                    <p className='fpii-text'>
                        {(productionObj.meta && productionObj.meta.action) ||
                            '---'}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Description</p>
                    <Input
                        type='text'
                        maxLength={lengths.description}
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                        errorMessage={
                            productionObj.productionDescriptionError
                        }></Input>
                </div>

                {productionDateInputUI}

                {sfgListLabel}

                <div className='span-2-columns-container'>{sfgArrUI}</div>

                {datalist}

                {noteUI}
            </section>
        );
    }

    if (
        purpose === 'OVERRIDE' ||
        (purpose === 'ADD' && preview) ||
        purpose === 'VIEW' ||
        purpose === 'DELETE'
    ) {
        //! =============================================================================
        sfgArrUI = productionObj.sfgArr.map((sfg, index) => {
            return (
                <ProdSFGView
                    key={sfg.sfgUID}
                    sfgObj={sfg}
                    sfgIndex={index}
                    purpose={purpose}
                    updateSfgValFn={updateSfgValFn}
                    updateRmValFn={updateRmValFn}
                />
            );
        });

        noteUI = (
            <div className='span-2-columns-container'>
                <TextArea
                    label={<span className='parent-labels'>Notes</span>}
                    disabled
                    maxLength={lengths.longTextLength}
                    value={noteInState}
                />
            </div>
        );

        if (
            (productionObj.meta.action === userActions.SENT_FOR_APPROVAL ||
                purpose === 'OVERRIDE') &&
            authUser.permissions.includes(permissions.PROD_ORDER_APPROVE)
        ) {
            noteUI = (
                <div className='span-2-columns-container'>
                    <TextArea
                        label={<span className='parent-labels'>Notes</span>}
                        maxLength={lengths.noteLength}
                        value={noteInState}
                        onChange={(e) => setNoteInState(e.target.value)}
                        errorMessage={productionObj.noteError}
                    />
                </div>
            );
        }

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>SFG Job Order</p>
                    <p className='fpii-text'>
                        {productionObj.productionUID || `(Auto Generated)`}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Status</p>
                    <p className='fpii-text'>
                        {(productionObj.meta && productionObj.meta.action) ||
                            '---'}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Description</p>
                    <p className='fpii-text'>
                        {productionObj.productionDescription || ''}
                    </p>
                </div>

                {productionDateDisplayUI}

                {sfgListLabel}

                <div className='span-2-columns-container'>{sfgArrUI}</div>

                {noteUI}
            </section>
        );
    }

    // =========================================================================
    // Button sections for users with Request permision ===================

    if (purpose === 'ADD') {
        buttonSection = (
            <CreateItemButtons
                createText='Create Order'
                previewText='Preview Order'
                cancelPreviewFn={() => setPreview(false)}
                cancelFn={backCancelBtnFn}
                createFn={saveProdOrderFn}
                previewFn={previewPOBtnFn}
                isPreview={preview}
            />
        );
    }

    if (purpose === 'EDIT') {
        buttonSection = (
            <UpdateItemButtons
                saveFn={saveProdOrderFn}
                cancelFn={cancelBtnFromEditViewFn}
            />
        );
    }

    if (purpose === 'DELETE') {
        buttonSection = (
            <DeleteItemButtons
                cancelFn={backCancelBtnFn}
                deleteFn={() => saveProdOrderFn(userActions.DELETED)}
                confirmeDeleteFn={(e) => setConfirmDeleteText(e.target.value)}
                confirmDeleteTextValue={confirmDeleteText}
                confirmDeleteTextPlaceHolder='Enter Production Number'
                uidValue={productionObj.productionUID}
            />
        );
    }

    // ==================================================================
    // Button section for users with Request permission.
    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.PROD_ORDER_REQUEST) &&
        !authUser.permissions.includes(permissions.PROD_ORDER_APPROVE)
    ) {
        if (
            productionObj.meta.action === userActions.CREATED ||
            productionObj.meta.action === userActions.UPDATED ||
            productionObj.meta.action === userActions.REJECTED
        ) {
            buttonSection = (
                <BackSendEditDelButtons
                    backBtnFn={backCancelBtnFn}
                    sendForApprovalBtnFn={() =>
                        statusChangeLocalFn(userActions.SENT_FOR_APPROVAL)
                    }
                    editBtnFn={gotoEditViewFn}
                    deleteBtnFn={gotoDeleteViewFn}
                />
            );
        }
    }

    // =========================================================================
    // Button section for users with Approve permision ===================

    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.PROD_ORDER_APPROVE) &&
        !authUser.permissions.includes(permissions.PROD_ORDER_REQUEST)
    ) {
        if (productionObj.meta.action === userActions.SENT_FOR_APPROVAL) {
            buttonSection = (
                <ApproveRejectButtons
                    backBtnFn={backCancelBtnFn}
                    approveBtnFn={() =>
                        statusChangeLocalFn(userActions.APPROVED)
                    }
                    rejectBtnFn={() =>
                        statusChangeLocalFn(userActions.REJECTED)
                    }
                />
            );
        }

        if (
            productionObj.meta.action === userActions.APPROVED ||
            productionObj.meta.action === userActions.OVERRIDDEN ||
            productionObj.meta.action === systemActions.FULFILLED
        ) {
            buttonSection = (
                <OverrideWithBackButtons
                    backBtnFn={backCancelBtnFn}
                    overrideBtnFn={gotoOverrideViewFn}
                />
            );
        }
    }

    // =========================================================================
    //! button section for users with both Request and Approve permissions.
    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.PROD_ORDER_APPROVE) &&
        authUser.permissions.includes(permissions.PROD_ORDER_REQUEST)
    ) {
        if (
            productionObj.meta.action === userActions.CREATED ||
            productionObj.meta.action === userActions.UPDATED ||
            productionObj.meta.action === userActions.REJECTED
        ) {
            buttonSection = (
                <BackSendEditDelButtons
                    backBtnFn={backCancelBtnFn}
                    sendForApprovalBtnFn={() =>
                        statusChangeLocalFn(userActions.SENT_FOR_APPROVAL)
                    }
                    editBtnFn={gotoEditViewFn}
                    deleteBtnFn={gotoDeleteViewFn}
                />
            );
        }

        if (productionObj.meta.action === userActions.SENT_FOR_APPROVAL) {
            buttonSection = (
                <ApproveRejectButtons
                    backBtnFn={backCancelBtnFn}
                    approveBtnFn={() =>
                        statusChangeLocalFn(userActions.APPROVED)
                    }
                    rejectBtnFn={() =>
                        statusChangeLocalFn(userActions.REJECTED)
                    }
                />
            );
        }

        // if document status is APPROVED
        if (
            productionObj.meta.action === userActions.APPROVED ||
            productionObj.meta.action === userActions.OVERRIDDEN ||
            productionObj.meta.action === systemActions.FULFILLED
        ) {
            buttonSection = (
                <OverrideWithBackButtons
                    backBtnFn={backCancelBtnFn}
                    overrideBtnFn={gotoOverrideViewFn}
                />
            );
        }
    }

    if (
        purpose === 'OVERRIDE' &&
        authUser.permissions.includes(permissions.PROD_ORDER_APPROVE)
    ) {
        buttonSection = (
            <OverrideSaveButtons
                cancelFn={cancelBtnFromEditViewFn}
                saveFn={() => saveProdOrderFn(userActions.OVERRIDDEN)}
            />
        );
    }

    if (!authUser.subscriptionStatusIsActive) {
        buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    }

    let generalErrorUI = (
        <div className='general-error-message'>{generalError}</div>
    );

    return (
        <div className='common-form'>
            <section className='form-title-section'>SFG Job Order Form</section>

            {mainContent}
            {buttonSection}
            {generalErrorUI}

            <Watermark purpose={purpose} currentDocument={currentDocument} />
        </div>
    );
};
