import { responseFn, saveWithTwoDecimalOnly } from '../lib/util';
import { lengths, regEx } from '../lib/constants';
import { generalMessage, appMessage } from '../lib/messages';
import { validateUID } from '../lib/util';

export const pdvr = (pdvr) => {
    // clear all errors

    let errorInObj = false;

    delete pdvr.supplierUIDError;
    delete pdvr.pdvrUIDError;
    delete pdvr.pdsiArrError;
    delete pdvr.pdvrDateError;
    delete pdvr.noteError;
    delete pdvr.pdvrDiscountError;

    let hackError = '';

    pdvr.pdvrUID = pdvr.pdvrUID.trim();

    // validate pdvrUID
    errorInObj = validateUID(pdvr.pdvrUID).error;
    pdvr.pdvrUIDError = validateUID(pdvr.pdvrUID).errorMessage;

    //  validate if there is at least one SI.
    if (pdvr.pdsiArr.length === 0) {
        errorInObj = true;
        pdvr.pdsiArrError = 'Must have at least one SI';
    }

    if (pdvr.pdsiArr.length > lengths.pdvrPdsiArrLength) {
        errorInObj = true;
        hackError = appMessage.sourceCodeErrorMessage(
            'PDVR: pdsiArr length error.'
        );
    }

    if (pdvr.pdvrDate === '') {
        errorInObj = true;
        pdvr.pdvrDateError = generalMessage.REQUIRED;
    }

    if (!regEx.date.test(pdvr.pdvrDate)) {
        errorInObj = true;
        pdvr.pdvrDateError = generalMessage.INVALID_DATE;
    }

    if (pdvr.note.length > lengths.noteLength) {
        errorInObj = true;
        pdvr.noteError = generalMessage.INVALID_LENGTH;
    }

    let pdsiTotals = 0;

    pdvr.pdsiArr.forEach((siObj) => {
        // validate same supplierUID for all pdpoObjs in pdsiArr
        // ! should never run unless UI was hacked.
        if (siObj.supplierUID !== pdvr.supplierUID) {
            errorInObj = true;
            hackError = appMessage.sourceCodeErrorMessage(
                'PDVR: supplierUID not equal in pdvrObj schema.'
            );
        }

        pdsiTotals = saveWithTwoDecimalOnly(pdsiTotals + siObj.pdsiTotalsNett);
    });

    // validate pdvrDiscount
    if (pdvr.pdvrDiscount === '') pdvr.pdvrDiscount = 0;

    // pdvr.pdvrDiscount cannot be greater than pdsiTotals
    if (Number(pdvr.pdvrDiscount) > pdsiTotals) {
        errorInObj = true;
        pdvr.pdvrDiscountError = 'Cannot be greater than Totals (Gross)';
    } else {
        if (isNaN(pdvr.pdvrDiscount)) {
            errorInObj = true;
            pdvr.pdvrDiscountError = generalMessage.INVALID;
        }
        if (!isNaN(pdvr.pdvrDiscount) && Number(pdvr.pdvrDiscount) < 0) {
            errorInObj = true;
            pdvr.pdvrDiscountError = generalMessage.INVALID;
        }
    }

    let pdvrTotalsNett = saveWithTwoDecimalOnly(pdsiTotals - pdvr.pdvrDiscount);

    if (errorInObj || hackError) {
        let errorObj = {
            pdvrObj: pdvr,
            hackError,
        };
        // console.log(errorObj);
        return responseFn(null, true, errorObj, 'App: Error with PDVR object.');
    }

    let correctSchemaPDSIArr = pdvr.pdsiArr.map((si) => {
        return {
            pdsiUID: si.pdsiUID,
            pdsiDate: si.pdsiDate,
            pdsiTotalsNett: si.pdsiTotalsNett,
            supplierUID: si.supplierUID,
        };
    });

    // proper schema
    let correctSchema = {
        meta: pdvr.meta || {},
        metaHistory: pdvr.metaHistory || [],
        pdvrUID: pdvr.pdvrUID,
        pdvrNumber: pdvr.pdvrUID,
        pdvrDate: pdvr.pdvrDate,
        supplierUID: pdvr.supplierUID,
        note: pdvr.note,
        pdsiArr: correctSchemaPDSIArr,
        pdvrTotalsNett,
        pdvrDiscount: saveWithTwoDecimalOnly(pdvr.pdvrDiscount),
    };

    return responseFn(correctSchema, false, {}, 'App: Validation successful.');
};
