import React from 'react';
import './Checkbox.css';

export default (props) => {
    let { value, checked, onChange, label, disabled } = props;

    let cls = disabled
        ? 'checkbox-label checkbox-hover-disable'
        : 'checkbox-label';

    return (
        <label className={cls}>
            <input
                type='checkbox'
                onChange={onChange}
                disabled={disabled}
                checked={checked}
                value={value}
            ></input>{' '}
            {label}
        </label>
    );
};
