import React from 'react';

export default (props) => {
    let {
        backBtnFn,
        approveBtnFn,
        rejectBtnFn,
        // approveText = 'Approve',
        icon,
    } = props;

    let buttonSection = (
        <section className='form-button-section'>
            <button className='goto-left btn' onClick={backBtnFn}>
                Back
            </button>
            {/* <span className='desktop-view'>
                <span className='m-top-10 m-right-10'>{icon}</span>
                <button
                    className='m-left-15 btn btn-primary'
                    onClick={approveBtnFn}
                >
                    {approveText}
                </button>
            </span> */}
            <span className='flex-row'>
                <span className='m-top-10'>{icon}</span>
                <button
                    className='m-lr-15 btn btn-primary'
                    onClick={approveBtnFn}
                >
                    Approve
                </button>
            </span>

            <button className='m-left-15 btn btn-danger' onClick={rejectBtnFn}>
                Reject
            </button>
        </section>
    );

    return buttonSection;
};
