export default {
    prodOrderRmActualUsedQty: 'Number of RM actual quantity used for the SFG',
    prodOrderSfgProducedQty: 'Number of produced SFG',
    assemFgAssembledQty: 'Number of assembled FG',
    assemSfgActualUsedQty: 'Number of SFG actual quantity used for the FG',
    assemRmActualUsedQty: 'Number of RM actual quantity used for the FG',
    sfgSetupRmRequiredQty: 'The RM quantity required to make 1 unit of an SFG',
    sfgSetupRmGroup:
        'Group items. eg. Same color, different brands that can be selected during Production Order',
    fgSetupRmRequiredQty: 'The RM quantity required to make 1 unit of an FG',
    fgSetupRmGroup:
        'RM items in the same group can be interchanged during Assembly Order',
    fgSetupSfgRequiredQty: 'The SFG quantity required to make 1 unit of an FG',
    fgSetupSfgGroup:
        'SFG items in the same group can be interchanged during Assembly Order',
    adminPermissionWarning:
        'Grants user to (1) assign and remove roles to users and (2) create, update, delete users',

    actualAndProjectedQtyNotEqual:
        'Ordered/Projected Qty will be adjusted automatically',
    editingActualQty: 'This will overwrite existing actual quantity',
    pdpoRmArr: 'Max 20 RMs per PO',
    pddrPoArr: 'Max 20 POs per DR',
    pdsiPoArr: 'Max 20 POs per SI',
    pdsiDrArr: 'Max 20 GRs per SI',

    sdpoSfgArr: 'Max 20 SFGs per PO',
    sdpoFgArr: 'Max 20 FGs per PO',
    sddrPoArr: 'Max 10 SOs per DR',
    sfgSetupRmArr: 'Max 20 RMs per SFG',
    fgSetupRmArr: 'Max 20 RMs per FG',
    fgSetupSfgArr: 'Max 20 SFGs per FG',

    prodOrderSfgArr: 'Max 5 SFGs per Production Order',
    productionDeliveredArr:
        'Max 4 Production Orders per SFG Production Delivered Form',

    assemblyOrderFgArr: 'Max 5 FGs per Assembly Order',
    assemblyDeliveredArr:
        'Max 2 Assebmly Orders per FG Assembly Delivered Form',

    subscriptionBillingCycle:
        'If a month doesn’t have the subscription start date, the subscription will be billed on the last day of the month. For example, a subscription starting on January 31 bills on February 28 (or February 29 in a leap year), then March 31, April 30, and so on.',

    reportAP:
        'Includes invoices Approved but not Paid on or before current report month',
    reportAR:
        'Includes invoices Approved but not Paid on or before current report month',
    reportPurchaseItem:
        'Includes invoices Approved in the current report month. Sorted by Amount',
    reportPurchaseSupplier:
        'Includes invoices Approved in the current report month. Sorted by Amount',
    reportSalesCustomer:
        'Includes invoices Approved in the current report month. Sorted by Amount',
    reportSalesItem:
        'Includes invoices Approved in the current report month. Sorted by Amount',
    reportSFG: 'Based off the average of the prices during SFG Setup',
    reportFG: 'Based off the average of the prices during FG Setup',
    reportRM: 'Based off the last purchase price on an approved PO',
    inventoryTransferArr: 'Max 20 items',
    disableEditingItems:
        'Cannot edit list. There are existing order(s) for this item',
};
