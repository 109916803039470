import React from 'react';
import { sortReportItems } from '../../lib/util';
import ReportsForm from './ReportsForm';
import ReportItemsEach from './ReportItemsEach';

import tooltips from '../../lib/tooltips';

export default ({ backCancelBtnFn, semiFgReportObj }) => {
    let sfgUI = [];
    let sfgReport = [];
    let sfgMap = semiFgReportObj.sfgMap;

    for (let key in sfgMap) {
        let itemObj = {};
        itemObj.itemUID = key;
        itemObj.total = sfgMap[key];

        sfgReport.push(itemObj);
    }

    sortReportItems(sfgReport);

    sfgReport.forEach((item, index) => {
        sfgUI.push(
            <ReportItemsEach
                key={index}
                index={index}
                item={item}
                mobileLabel='SFG Amount'
            />
        );
    });
    return (
        <ReportsForm
            helpText={tooltips.reportSFG}
            uid={`Semi Finished Goods - ${semiFgReportObj.reportMonth} ${semiFgReportObj.reportYear}`}
            nameLabel='Semi Finished Goods'
            amountLabel='SFG Inventory Amount'
            total={semiFgReportObj.total}
            list={sfgUI}
            backCancelBtnFn={backCancelBtnFn}
        />
    );
};
