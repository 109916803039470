export default (customerMasterlistStringFormat) => {
    let newArr = [];
    let customerObjsInObjFormat = JSON.parse(customerMasterlistStringFormat);

    for (let key in customerObjsInObjFormat) {
        newArr.push({
            customerUID: key,
            contactPerson: customerObjsInObjFormat[key].a,
            contactNumber1: customerObjsInObjFormat[key].b,
            priceListNum: customerObjsInObjFormat[key].c,
        });
    }

    return newArr;
};
