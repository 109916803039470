import React from 'react';
import './CustomerItem.css';
import '../CollectionItems.css';

export default (props) => {
    let { obj, serialNumber, onClick, heading } = props;

    let serial = 'S/No.';
    let itemName = 'Customer Name';
    let contactPerson = 'Contact Person';
    let contactNumber = 'Contact Number';
    let priceListNum = 'Price List';
    let headingClass = 'text-bold list-table-heading';

    let serialLabel = serial + ':';
    let itemNameLabel = itemName + ':';
    let personLabel = contactPerson + ':';
    let numberLabel = contactNumber + ':';
    let priceListNumLabel = priceListNum + ':';

    if (!heading) {
        serial = serialNumber;
        itemName = obj.customerUID;
        contactPerson = obj.contactPerson;
        contactNumber = obj.contactNumber1;
        priceListNum = obj.priceListNum || '';
        headingClass = '';
    }

    return (
        <div
            className={`setup-sup-cus-item collection-item ${headingClass}`}
            onClick={onClick}
        >
            <div className='collection-item-entry serial'>
                {' '}
                <span className='entry-label'>{serialLabel}</span>
                {serial}
            </div>
            <div className='collection-item-entry name'>
                {' '}
                <span className='entry-label'>{itemNameLabel}</span>
                {itemName}
            </div>
            <div className='collection-item-entry contact-person'>
                {' '}
                <span className='entry-label'>{personLabel}</span>
                {contactPerson}
            </div>
            <div className='collection-item-entry contact-number'>
                {' '}
                <span className='entry-label'>{numberLabel}</span>
                {contactNumber}
            </div>
            <div className='collection-item-entry price-list-num'>
                {' '}
                <span className='entry-label'>{priceListNumLabel}</span>
                {priceListNum}
            </div>
        </div>
    );
};
