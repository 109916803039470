import React, { useState } from 'react';
import firebaseAuth from '../../firebase_auth/firebaseAuth';
import LoginForm from './LoginForm';
import EmailSentForm from './EmailSentForm';
import ForgetPasswordForm from './ForgetPasswordForm';
import { Overlay, Spinner } from '../../components';

import './LoginPage.css';

const views = {
    LOGIN: 'login',
    RESEND_VERIFICATION: 'resendVerification',
    VERIFICATION_SENT: 'verificationSent',
    FORGET_PASSWORD: 'forgetPassword',
    FORGET_LINK_SENT: 'forgetLinkSent',
    RESEND: 'resent',
};

const LoginPage = (props) => {
    let [loginErrMsg, setLoginErrMsg] = useState('');
    let [forgetPasswordErrMsg, setForgetPasswordErrMsg] = useState('');
    let [resendVerificationErrMsg, setResendVerificationErrMsg] = useState('');
    let [formView, setFormView] = useState(views.LOGIN);

    let [isLoading, setIsLoading] = useState(false);

    const loginBtnFn = async (email, password) => {
        setIsLoading(true);
        let response = await firebaseAuth.loginFn(email, password);
        if (response.error) {
            setLoginErrMsg(response.errorObj.message || response.message);
            setResendVerificationErrMsg(
                response.errorObj.message || response.message
            );
            setIsLoading(false);
        } else {
            let user = response.data.user;
            // onAuthStateChange listener will send the email verificaation.
            if (!user.emailVerified) {
                setFormView(views.VERIFICATION_SENT);
                setIsLoading(false);
            }
        }

        // memory leak error. no need as component will be unmounted.
        // setIsLoading(false);
    };

    const sendPasswordResetFn = async (email) => {
        setIsLoading(true);
        let response = await firebaseAuth.forgotPasswordFn(email);
        if (response.error)
            setForgetPasswordErrMsg(
                response.errorObj.message || response.message
            );
        else setFormView(views.FORGET_LINK_SENT);

        setIsLoading(false);
    };

    const redirectLinkFn = (e) => {
        // equivalent to views object
        switch (e.target.id) {
            case views.LOGIN:
                setLoginErrMsg('');
                setFormView(views.LOGIN);
                break;

            case views.FORGET_PASSWORD:
                setForgetPasswordErrMsg('');
                setFormView(views.FORGET_PASSWORD);
                break;

            case views.RESEND_VERIFICATION:
                setResendVerificationErrMsg('');
                setFormView(views.RESEND_VERIFICATION);
                break;

            default:
                setLoginErrMsg('');
                setFormView(views.LOGIN);
                break;
        }
    };

    let form = null;

    if (formView === views.LOGIN) {
        form = (
            <LoginForm
                purpose='login'
                loginBtnFn={loginBtnFn}
                redirectLinkFn={redirectLinkFn}
                views={views}
                loginErrMsg={loginErrMsg}
            ></LoginForm>
        );
    }

    if (formView === views.RESEND_VERIFICATION) {
        form = (
            <LoginForm
                purpose='resendVerificationEmail'
                loginBtnFn={loginBtnFn}
                redirectLinkFn={redirectLinkFn}
                views={views}
                loginErrMsg={resendVerificationErrMsg}
            ></LoginForm>
        );
    }

    if (formView === views.VERIFICATION_SENT) {
        form = (
            <EmailSentForm
                purpose='verifyEmailSent'
                redirectLinkFn={redirectLinkFn}
                views={views}
            ></EmailSentForm>
        );
    }

    if (formView === views.FORGET_LINK_SENT) {
        form = (
            <EmailSentForm
                purpose='passResetSent'
                redirectLinkFn={redirectLinkFn}
                views={views}
            ></EmailSentForm>
        );
    }

    if (formView === views.FORGET_PASSWORD) {
        form = (
            <ForgetPasswordForm
                purpose='passResetSent'
                redirectLinkFn={redirectLinkFn}
                views={views}
                sendPasswordResetFn={sendPasswordResetFn}
                forgetPasswordErrMsg={forgetPasswordErrMsg}
            ></ForgetPasswordForm>
        );
    }

    let overlay = (
        <Overlay>
            <Spinner />
        </Overlay>
    );

    if (!isLoading) overlay = null;

    return (
        <div className='login-page'>
            {overlay}
            <div className='brand'>
                <i
                    className='brand-icon ms-Icon ms-Icon--CubeShape'
                    aria-hidden='true'
                ></i>
                <span className='brand-text'>Business Logic</span>
            </div>

            {form}

            <div className='terms'>Terms of Use</div>
        </div>
    );
};

export default LoginPage;
