import React from 'react';
import DetailedMessage from './DetailedMessage';
import './AlertMessage.css';

export default (props) => {
    let {
        isError,
        alertMessage,
        detailObj1,
        detailObj2,
        hideAlertFn,
        useDetails = false,
    } = props;

    let appClass = 'appMessage';
    if (isError) appClass = 'appMessage appErrorMessage';

    if (useDetails) {
        let detailComp1 =
            detailObj1 && detailObj1.mainMessage ? (
                <DetailedMessage detailObj={detailObj1} />
            ) : null;
        let detailComp2 =
            detailObj2 && detailObj2.mainMessage ? (
                <DetailedMessage detailObj={detailObj2} />
            ) : null;

        return (
            <div className={appClass}>
                <div
                    id='detailed-messages-container'
                    className='detailed-messages-container'
                >
                    {detailComp1}
                    {detailComp2}
                </div>

                <i
                    className='ms-Icon ms-Icon--Cancel appMsg-icon-ctrl'
                    onClick={hideAlertFn}
                ></i>
            </div>
        );
    }

    return (
        <div className={appClass}>
            {alertMessage}
            <i
                className='ms-Icon ms-Icon--Cancel appMsg-icon-ctrl'
                onClick={hideAlertFn}
            ></i>
        </div>
    );
};
