import { lengths } from '../lib/constants';
import { generalMessage } from '../lib/messages';
import { validateUID } from '../lib/util';

export const supplier = (supplierObj, purpose) => {
    let supplierNameErr = '';
    let contactNumber1Err = '';
    let contactNumber2Err = '';
    let faxNumberErr = '';
    let supplierCodeErr = '';
    let contactPersonErr = '';
    let addressErr = '';
    let noteErr = '';

    let {
        supplierUID,
        supplierName,
        contactNumber1,
        contactNumber2,
        faxNumber,
        contactPerson,
        supplierCode,
        address,
        note,
    } = supplierObj;

    //! supplierName validation
    supplierNameErr = validateUID(supplierName).errorMessage;

    // supplierCode validation
    if (supplierCode.length > lengths.code)
        supplierCodeErr = generalMessage.INVALID_LENGTH;

    //! contactNumber1 validation !! REQUIRED
    if (contactNumber1 === '') contactNumber1Err = generalMessage.REQUIRED;
    else {
        // if (!regEx.phone.test(contactNumber1))
        //     contactNumber1Err = generalMessage.INVALID_FORMAT;
        if (contactNumber1.length > lengths.phone)
            contactNumber1Err = generalMessage.INVALID_LENGTH;
    }

    // contactNumber2 validation
    // if (contactNumber2 !== '' && !regEx.phone.test(contactNumber2))
    //     contactNumber2Err = generalMessage.INVALID_FORMAT;
    if (contactNumber2.length > lengths.phone)
        contactNumber2Err = generalMessage.INVALID_LENGTH;

    // faxNumber validation
    // if (faxNumber !== '' && !regEx.phone.test(faxNumber))
    //     faxNumberErr = generalMessage.INVALID_FORMAT;
    if (faxNumber.length > lengths.phone)
        faxNumberErr = generalMessage.INVALID_LENGTH;

    // contactPerson validation
    if (contactPerson.length > lengths.contactPerson)
        contactPersonErr = generalMessage.INVALID_LENGTH;

    // address validation
    if (address.length > lengths.regTextLength)
        addressErr = generalMessage.INVALID_LENGTH;

    // note valiation
    if (note.length > lengths.longTextLength)
        noteErr = generalMessage.INVALID_LENGTH;

    if (
        supplierNameErr ||
        contactNumber1Err ||
        contactNumber2Err ||
        faxNumberErr ||
        contactPersonErr ||
        supplierCodeErr ||
        addressErr ||
        noteErr
    ) {
        return {
            error: true,
            errorObj: {
                supplierNameErr,
                contactNumber1Err,
                contactNumber2Err,
                faxNumberErr,
                contactPersonErr,
                addressErr,
                noteErr,
                supplierCodeErr,
            },
        };
    }

    // this will attempt to ensure supplier UID and supplierName are always equal.
    // unless UI is hacked.
    if (purpose === 'ADD') supplierUID = supplierName;
    else supplierName = supplierUID;

    // if no error.
    let completeSupplierSchema = {
        ...supplierObj,
        supplierUID,
        supplierName,
        contactNumber1,
        contactNumber2,
        faxNumber,
        contactPerson,
        supplierCode,
        address,
        note,
    };

    return {
        error: false,
        errorObj: null,
        data: completeSupplierSchema,
    };
};
