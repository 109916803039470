import React, { useState, useEffect, useContext } from 'react';
import { permissions as p, lengths } from '../../lib/constants';
import {
    Input,
    UpdateItemButtons,
    DeleteItemButtons,
    SetupViewButtons,
    Checkbox,
    WarningTip,
} from '../../components';
import tooltips from '../../lib/tooltips';
import validate from '../../validators';
import '../PagesCommon.css';
import './UserManagementPage.css';
import { AuthContext } from '../../contexts';

export default (props) => {
    let {
        purpose,

        backCancelBtnFn,
        gotoEditViewFn,
        gotoDeleteViewFn,

        currentDocument,

        deleteUserFn,
        createUserFn,
        updateUserFn,
    } = props;

    let { authUser } = useContext(AuthContext);

    let [userUID, setUserUID] = useState('');

    let [displayName, setDisplayName] = useState('');
    let [email, setEmail] = useState('');
    let [password, setPassword] = useState('');

    let [displayNameError, setDisplayNameError] = useState('');
    let [emailError, setEmailError] = useState('');

    let [passwordError, setPasswordError] = useState('');

    let [confirmDeleteText, setConfirmDeleteText] = useState('');

    let [permissionArrState, setPermissionArrState] = useState([]);

    const setCurrentDocumentRoles = () => {
        // set the values
        setEmail(currentDocument.email);
        setDisplayName(currentDocument.displayName);
        setUserUID(currentDocument.userUID);
        setPermissionArrState([...currentDocument.permissions]);
    };

    useEffect(() => {
        if (purpose !== 'ADD') {
            setCurrentDocumentRoles();
        }
    }, []);

    const cancelBtnFromEditViewFn = () => {
        // 1. reset values.
        setCurrentDocumentRoles();

        // 2. go back to profile view.
        backCancelBtnFn();
    };

    // =====================================================

    const togglePermissionFn = (permission) => {
        if (permissionArrState.includes(permission)) {
            let tempPermissionArr = permissionArrState.filter(
                (prmsn) => prmsn !== permission
            );
            setPermissionArrState(tempPermissionArr);
        } else {
            let tempPermissionArr = [...permissionArrState];
            tempPermissionArr.push(permission);
            setPermissionArrState(tempPermissionArr);
        }
    };

    const permissionIsPresent = (permission) => {
        return permissionArrState.includes(permission);
    };

    const saveBtnFn = () => {
        // userObj
        let userObj = {
            userUID: userUID,
            email: email,
            password: password,
            displayName: displayName,
            permissions: permissionArrState,
        };

        // check if there is any change in the permissions array.
        if (purpose === 'EDIT') {
            let noChange = validate.authUserPermissionsNoChange(
                userObj.permissions,
                currentDocument.permissions
            );

            if (noChange) {
                cancelBtnFromEditViewFn();
                return;
            }
        }

        let response = validate.authUser(userObj, purpose);
        if (response.error) {
            setEmailError(response.errorObj.emailError);
            setPasswordError(response.errorObj.passwordError);
            setDisplayNameError(response.errorObj.displayNameError);
            console.error(response.errorObj);
            return;
        }

        if (purpose === 'ADD') createUserFn(response.data);
        if (purpose === 'DELETE') deleteUserFn(response.data, currentDocument);
        if (purpose === 'EDIT') updateUserFn(response.data, currentDocument);
    };

    // conditional render of contents  =============================

    let buttonSection = null;

    let mainContent = null;

    let inputEmailUI = (
        <Input
            label='Email'
            value={email}
            maxLength={lengths.email}
            placeholder='Required'
            onChange={(e) => setEmail(e.target.value)}
            errorMessage={emailError}
        />
    );

    let inputPasswordUI = (
        <Input
            label='Password'
            value={password}
            type='password'
            maxLength={lengths.password}
            placeholder='Required'
            onChange={(e) => setPassword(e.target.value)}
            errorMessage={passwordError}
        />
    );

    let inputDisplayNameUI = (
        <Input
            label='Display Name'
            maxLength={lengths.displayName}
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            errorMessage={displayNameError}
        />
    );

    if (purpose !== 'ADD') {
        inputEmailUI = (
            <Input
                label='Email'
                value={email}
                maxLength={lengths.email}
                placeholder='Required'
                disabled={true}
                errorMessage={emailError}
            />
        );

        inputPasswordUI = (
            <Input
                label='Password'
                value={password}
                type='password'
                maxLength={lengths.password}
                placeholder='Required'
                disabled={true}
                errorMessage={passwordError}
            />
        );

        inputDisplayNameUI = (
            <Input
                label='Display Name'
                maxLength={lengths.displayName}
                value={displayName}
                disabled={true}
                errorMessage={displayNameError}
            />
        );
    }

    let disabledCheckbox = false;

    if (purpose === 'VIEW' || purpose === 'DELETE') {
        disabledCheckbox = true;
        inputPasswordUI = null;
    }

    if (purpose === 'EDIT') {
        inputPasswordUI = null;
    }

    mainContent = (
        <section className='form-main-section'>
            {inputEmailUI}

            {inputPasswordUI}

            {inputDisplayNameUI}

            <div className='span-2-columns-container label-padding label-highlight m-top-20'>
                <label className='parent-labels'>Permissions</label>
            </div>

            {/* Purchase dept section */}
            <div className='span-2-columns-container show-border-darker pad-around-10 m-bottom-10'>
                <div className='view-table-row bg-grey-l2 pad-around-10'>
                    <div className='cell'>
                        <span className='m-right-5 parent-labels'>
                            Purchase Department
                        </span>
                    </div>
                </div>
                <div className='view-table-row'>
                    <div className='cell'>
                        <Checkbox
                            label='Purchase Order Requester'
                            onChange={() => togglePermissionFn(p.PDPO_REQUEST)}
                            checked={permissionIsPresent(p.PDPO_REQUEST)}
                            disabled={disabledCheckbox}
                        />
                    </div>

                    <div className='cell'>
                        <Checkbox
                            label='Purchase Order Approver'
                            onChange={() => togglePermissionFn(p.PDPO_APPROVE)}
                            checked={permissionIsPresent(p.PDPO_APPROVE)}
                            disabled={disabledCheckbox}
                        />
                    </div>

                    <div className='cell'>
                        <Checkbox
                            label='Goods Receipt Requester'
                            onChange={() => togglePermissionFn(p.PDDR_REQUEST)}
                            checked={permissionIsPresent(p.PDDR_REQUEST)}
                            disabled={disabledCheckbox}
                        />
                    </div>

                    <div className='cell'>
                        <Checkbox
                            label='Goods Receipt Approver'
                            onChange={() => togglePermissionFn(p.PDDR_APPROVE)}
                            checked={permissionIsPresent(p.PDDR_APPROVE)}
                            disabled={disabledCheckbox}
                        />
                    </div>

                    <div className='cell'>
                        <Checkbox
                            label='Supplier Invoice Requester'
                            onChange={() => togglePermissionFn(p.PDSI_REQUEST)}
                            checked={permissionIsPresent(p.PDSI_REQUEST)}
                            disabled={disabledCheckbox}
                        />
                    </div>

                    <div className='cell'>
                        <Checkbox
                            label='Supplier Invoice Approver'
                            onChange={() => togglePermissionFn(p.PDSI_APPROVE)}
                            checked={permissionIsPresent(p.PDSI_APPROVE)}
                            disabled={disabledCheckbox}
                        />
                    </div>

                    <div className='cell'>
                        <Checkbox
                            label='Voucher Receipt Requester'
                            onChange={() => togglePermissionFn(p.PDVR_REQUEST)}
                            checked={permissionIsPresent(p.PDVR_REQUEST)}
                            disabled={disabledCheckbox}
                        />
                    </div>

                    <div className='cell'>
                        <Checkbox
                            label='Voucher Receipt Approver'
                            onChange={() => togglePermissionFn(p.PDVR_APPROVE)}
                            checked={permissionIsPresent(p.PDVR_APPROVE)}
                            disabled={disabledCheckbox}
                        />
                    </div>
                </div>
            </div>

            {/* operations dept section */}
            <div className='span-2-columns-container show-border-darker pad-around-10 m-bottom-10'>
                <div className='view-table-row bg-grey-l2 pad-around-10'>
                    <div className='cell'>
                        <span className='m-right-5 parent-labels'>
                            Operations Department
                        </span>
                    </div>
                </div>
                <div className='view-table-row'>
                    <div className='cell'>
                        <Checkbox
                            label='SFG Production Order Requester'
                            onChange={() =>
                                togglePermissionFn(p.PROD_ORDER_REQUEST)
                            }
                            checked={permissionIsPresent(p.PROD_ORDER_REQUEST)}
                            disabled={disabledCheckbox}
                        />
                    </div>
                    <div className='cell'>
                        <Checkbox
                            label='SFG Production Order Approver'
                            onChange={() =>
                                togglePermissionFn(p.PROD_ORDER_APPROVE)
                            }
                            checked={permissionIsPresent(p.PROD_ORDER_APPROVE)}
                            disabled={disabledCheckbox}
                        />
                    </div>

                    <div className='cell'>
                        <Checkbox
                            label='SFG Production Requester'
                            onChange={() =>
                                togglePermissionFn(
                                    p.PRODUCTION_DELIVERED_REQUEST
                                )
                            }
                            checked={permissionIsPresent(
                                p.PRODUCTION_DELIVERED_REQUEST
                            )}
                            disabled={disabledCheckbox}
                        />
                    </div>
                    <div className='cell'>
                        <Checkbox
                            label='SFG Production Approver'
                            onChange={() =>
                                togglePermissionFn(
                                    p.PRODUCTION_DELIVERED_APPROVE
                                )
                            }
                            checked={permissionIsPresent(
                                p.PRODUCTION_DELIVERED_APPROVE
                            )}
                            disabled={disabledCheckbox}
                        />
                    </div>
                    <div className='cell'>
                        <Checkbox
                            label='FG Assembly Order Requester'
                            onChange={() =>
                                togglePermissionFn(p.ASSEMBLY_ORDER_REQUEST)
                            }
                            checked={permissionIsPresent(
                                p.ASSEMBLY_ORDER_REQUEST
                            )}
                            disabled={disabledCheckbox}
                        />
                    </div>
                    <div className='cell'>
                        <Checkbox
                            label='FG Assembly Order Approver'
                            onChange={() =>
                                togglePermissionFn(p.ASSEMBLY_ORDER_APPROVE)
                            }
                            checked={permissionIsPresent(
                                p.ASSEMBLY_ORDER_APPROVE
                            )}
                            disabled={disabledCheckbox}
                        />
                    </div>

                    <div className='cell'>
                        <Checkbox
                            label='FG Assembly Requester'
                            onChange={() =>
                                togglePermissionFn(p.ASSEMBLY_DELIVERED_REQUEST)
                            }
                            checked={permissionIsPresent(
                                p.ASSEMBLY_DELIVERED_REQUEST
                            )}
                            disabled={disabledCheckbox}
                        />
                    </div>
                    <div className='cell'>
                        <Checkbox
                            label='FG Assembly Approver'
                            onChange={() =>
                                togglePermissionFn(p.ASSEMBLY_DELIVERED_APPROVE)
                            }
                            checked={permissionIsPresent(
                                p.ASSEMBLY_DELIVERED_APPROVE
                            )}
                            disabled={disabledCheckbox}
                        />
                    </div>
                </div>
            </div>

            {/* Sales dept section */}
            <div className='span-2-columns-container show-border-darker pad-around-10 m-bottom-10'>
                <div className='view-table-row bg-grey-l2 pad-around-10'>
                    <div className='cell'>
                        <span className='m-right-5 parent-labels'>
                            Sales Department
                        </span>
                    </div>
                </div>
                <div className='view-table-row'>
                    <div className='cell'>
                        <Checkbox
                            label='Sales Order Requester'
                            onChange={() => togglePermissionFn(p.SDPO_REQUEST)}
                            checked={permissionIsPresent(p.SDPO_REQUEST)}
                            disabled={disabledCheckbox}
                        />
                    </div>
                    <div className='cell'>
                        <Checkbox
                            label='Sales Order Approver'
                            onChange={() => togglePermissionFn(p.SDPO_APPROVE)}
                            checked={permissionIsPresent(p.SDPO_APPROVE)}
                            disabled={disabledCheckbox}
                        />
                    </div>
                    <div className='cell'>
                        <Checkbox
                            label='Delivery Receipt Requester'
                            onChange={() => togglePermissionFn(p.SDDR_REQUEST)}
                            checked={permissionIsPresent(p.SDDR_REQUEST)}
                            disabled={disabledCheckbox}
                        />
                    </div>
                    <div className='cell'>
                        <Checkbox
                            label='Delivery Receipt Approver'
                            onChange={() => togglePermissionFn(p.SDDR_APPROVE)}
                            checked={permissionIsPresent(p.SDDR_APPROVE)}
                            disabled={disabledCheckbox}
                        />
                    </div>

                    <div className='cell'>
                        <Checkbox
                            label='Customer Invoice Requester'
                            onChange={() => togglePermissionFn(p.SDSI_REQUEST)}
                            checked={permissionIsPresent(p.SDSI_REQUEST)}
                            disabled={disabledCheckbox}
                        />
                    </div>
                    <div className='cell'>
                        <Checkbox
                            label='Customer Invoice Approver'
                            onChange={() => togglePermissionFn(p.SDSI_APPROVE)}
                            checked={permissionIsPresent(p.SDSI_APPROVE)}
                            disabled={disabledCheckbox}
                        />
                    </div>

                    <div className='cell'>
                        <Checkbox
                            label='Counter Receipt Requester'
                            onChange={() => togglePermissionFn(p.SDCR_REQUEST)}
                            checked={permissionIsPresent(p.SDCR_REQUEST)}
                            disabled={disabledCheckbox}
                        />
                    </div>
                    <div className='cell'>
                        <Checkbox
                            label='Counter Receipt Approver'
                            onChange={() => togglePermissionFn(p.SDCR_APPROVE)}
                            checked={permissionIsPresent(p.SDCR_APPROVE)}
                            disabled={disabledCheckbox}
                        />
                    </div>
                </div>
            </div>

            {/* Setup section */}
            <div className='span-2-columns-container show-border-darker pad-around-10 m-bottom-10'>
                <div className='view-table-row bg-grey-l2 pad-around-10'>
                    <div className='cell'>
                        <span className='m-right-5 parent-labels'>Setup</span>
                    </div>
                </div>

                <div className='view-table-row'>
                    <div className='cell'>
                        <Checkbox
                            label='Suppliers Setup'
                            onChange={() =>
                                togglePermissionFn(p.SUPPLIER_SETUP)
                            }
                            checked={permissionIsPresent(p.SUPPLIER_SETUP)}
                            disabled={disabledCheckbox}
                        />
                    </div>
                    <div className='cell'>
                        <Checkbox
                            label='Customers Setup'
                            onChange={() =>
                                togglePermissionFn(p.CUSTOMER_SETUP)
                            }
                            checked={permissionIsPresent(p.CUSTOMER_SETUP)}
                            disabled={disabledCheckbox}
                        />
                    </div>
                    <div className='cell'>
                        <Checkbox
                            label='Raw Materials Setup'
                            onChange={() => togglePermissionFn(p.RM_SETUP)}
                            checked={permissionIsPresent(p.RM_SETUP)}
                            disabled={disabledCheckbox}
                        />
                    </div>
                    <div className='cell'>
                        <Checkbox
                            label='Semi Finished Goods Setup'
                            onChange={() => togglePermissionFn(p.SFG_SETUP)}
                            checked={permissionIsPresent(p.SFG_SETUP)}
                            disabled={disabledCheckbox}
                        />
                    </div>
                    <div className='cell'>
                        <Checkbox
                            label='Finished Goods Setup'
                            onChange={() => togglePermissionFn(p.FG_SETUP)}
                            checked={permissionIsPresent(p.FG_SETUP)}
                            disabled={disabledCheckbox}
                        />
                    </div>
                </div>
            </div>

            {/* Inventory Section */}
            <div className='span-2-columns-container show-border-darker pad-around-10 m-bottom-10'>
                <div className='view-table-row bg-grey-l2 pad-around-10'>
                    <div className='cell'>
                        <span className='m-right-5 parent-labels'>
                            Inventory
                        </span>
                    </div>
                </div>

                <div className='view-table-row'>
                    <div className='cell'>
                        <Checkbox
                            label='Inventory Transfer Requester'
                            onChange={() =>
                                togglePermissionFn(p.INV_TRANSFER_REQUEST)
                            }
                            checked={permissionIsPresent(
                                p.INV_TRANSFER_REQUEST
                            )}
                            disabled={disabledCheckbox}
                        />
                    </div>
                    <div className='cell'>
                        <Checkbox
                            label='Inventory Transfer Approver'
                            onChange={() =>
                                togglePermissionFn(p.INV_TRANSFER_APPROVE)
                            }
                            checked={permissionIsPresent(
                                p.INV_TRANSFER_APPROVE
                            )}
                            disabled={disabledCheckbox}
                        />
                    </div>
                    <div className='cell'>
                        <Checkbox
                            label='Raw Materials Inventory'
                            onChange={() => togglePermissionFn(p.INV_RM)}
                            checked={permissionIsPresent(p.INV_RM)}
                            disabled={disabledCheckbox}
                        />
                    </div>
                    <div className='cell'>
                        <Checkbox
                            label='Semi Finished Goods Inventory'
                            onChange={() => togglePermissionFn(p.INV_SFG)}
                            checked={permissionIsPresent(p.INV_SFG)}
                            disabled={disabledCheckbox}
                        />
                    </div>
                    <div className='cell'>
                        <Checkbox
                            label='Finished Goods Inventory'
                            onChange={() => togglePermissionFn(p.INV_FG)}
                            checked={permissionIsPresent(p.INV_FG)}
                            disabled={disabledCheckbox}
                        />
                    </div>
                </div>
            </div>

            {/* Administration section */}
            <div className='span-2-columns-container show-border-darker pad-around-10 m-bottom-10'>
                <div className='view-table-row bg-grey-l2 pad-around-10'>
                    <div className='cell'>
                        <span className='m-right-5 parent-labels'>
                            Administration
                        </span>
                    </div>
                </div>
                <div className='view-table-row'>
                    <div className='cell'>
                        <Checkbox
                            label={'Admin'}
                            onChange={() => togglePermissionFn(p.APP_ADMIN)}
                            checked={permissionIsPresent(p.APP_ADMIN)}
                            disabled={disabledCheckbox}
                        />
                        {purpose === 'VIEW' || purpose === 'DELETE' ? null : (
                            <span className='m-left-15'>
                                <WarningTip
                                    content={tooltips.adminPermissionWarning}
                                />
                            </span>
                        )}
                    </div>
                    <div className='cell'>
                        <Checkbox
                            label={'View Reports'}
                            onChange={() => togglePermissionFn(p.VIEW_REPORTS)}
                            checked={permissionIsPresent(p.VIEW_REPORTS)}
                            disabled={disabledCheckbox}
                        />
                    </div>
                </div>
            </div>
        </section>
    );

    if (purpose === 'ADD') {
        buttonSection = (
            <UpdateItemButtons
                cancelFn={backCancelBtnFn}
                saveFn={saveBtnFn}
                saveText='Create'
            />
        );
    }

    if (purpose === 'EDIT') {
        buttonSection = (
            <UpdateItemButtons
                saveFn={saveBtnFn}
                cancelFn={cancelBtnFromEditViewFn}
            />
        );
    }

    if (purpose === 'DELETE') {
        buttonSection = (
            <DeleteItemButtons
                cancelFn={backCancelBtnFn}
                deleteFn={saveBtnFn}
                confirmeDeleteFn={(e) => setConfirmDeleteText(e.target.value)}
                confirmDeleteTextValue={confirmDeleteText}
                confirmDeleteTextPlaceHolder='Enter user email'
                uidValue={email}
            />
        );
    }

    if (purpose === 'VIEW') {
        buttonSection = (
            <SetupViewButtons
                backBtnFn={backCancelBtnFn}
                deleteBtnFn={gotoDeleteViewFn}
                editBtnFn={gotoEditViewFn}
                doNotShowDeleteBtn={currentDocument.permissions.includes(
                    p.OWNER
                )}
            />
        );
    }

    if (!authUser.subscriptionStatusIsActive) {
        buttonSection = null;
    }

    return (
        <div className='common-form'>
            <section className='form-title-section'>
                User Access Management Form
            </section>

            {mainContent}

            {buttonSection}

            <div className='watermark'>
                <span></span>
            </div>
        </div>
    );
};
