import { auth, firebase } from '../firebase_config/firebaseConfig';
import { responseFn } from '../lib/util';

export const loginFn = async (email, password) => {
    try {
        let user = await auth.signInWithEmailAndPassword(email, password);
        return responseFn(user, false, null, 'App: Logged in.');
    } catch (e) {
        return responseFn(null, true, e, 'App: Error logging in.');
    }
};

export const logoutFn = async () => {
    try {
        await auth.signOut();
        return responseFn(null, false, null, 'App: Logged out.');
    } catch (e) {
        return responseFn(null, true, e, 'App: Error logging out.');
    }
};

export const forgotPasswordFn = async (email) => {
    try {
        await auth.sendPasswordResetEmail(email);
        return responseFn(null, false, null, 'App: Password reset email sent.');
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            'App: Error sending password reset email.'
        );
    }
};

// export const sendEmailVerificaitonFn = async (user) => {
//     try {
//         await user.sendEmailVerificaiton();
//         return responseFn(null, false, null, 'App: Email verification sent.');
//     } catch (e) {
//         return responseFn(
//             null,
//             true,
//             e,
//             'App: Error sending email verification.'
//         );
//     }
// };

export const updateProfileFn = async (displayName) => {
    try {
        await auth.currentUser.updateProfile({ displayName: displayName });
        return responseFn(
            null,
            false,
            null,
            'Successfully updated display name'
        );
    } catch (e) {
        return responseFn(null, true, e, 'Error updating display name');
    }
};

export const changePasswordFn = async (currentPassword, newPassword) => {
    try {
        let creds = firebase.auth.EmailAuthProvider.credential(
            auth.currentUser.email,
            currentPassword
        );
        await auth.currentUser.reauthenticateWithCredential(creds);

        await auth.currentUser.updatePassword(newPassword);

        return responseFn(null, false, null, 'Successfully updated password');
    } catch (e) {
        return responseFn(null, true, e, 'Error updating password');
    }
};

export default {
    loginFn,
    forgotPasswordFn,
    // sendEmailVerificaitonFn,
    logoutFn,
    updateProfileFn,
    changePasswordFn,
};
