import { responseFn, clone } from '../lib/util';
import { userActions, regEx, lengths } from '../lib/constants';
import { generalMessage, appMessage } from '../lib/messages';
import { saveWithFiveDecimals } from '../lib/util';

export const prodOrders = (
    prodObj,
    purposeProp,
    masterInventoryRMsList,
    userActionPassed
) => {
    let prodCopy = {
        ...prodObj,
        sfgArr: clone(prodObj.sfgArr),
    };

    delete prodCopy.prodOrderDateError;
    delete prodCopy.noteError;
    delete prodCopy.productionDescriptionError;

    prodCopy.sfgArr.forEach((sfg) => {
        delete sfg.sfgOrderedQtyError;
        delete sfg.sfgProducedQtyError;
        delete sfg.sfgOverrideQtyError;
        sfg.sfgOrderedQty = saveWithFiveDecimals(sfg.sfgOrderedQty);
        sfg.sfgProducedQty = saveWithFiveDecimals(sfg.sfgProducedQty);
        sfg.sfgOverrideQty = saveWithFiveDecimals(sfg.sfgOverrideQty);
        sfg.sfgBalanceQty = saveWithFiveDecimals(sfg.sfgBalanceQty);

        sfg.rmArr.forEach((rm) => {
            delete rm.rmProjectedUseQtyError;
            delete rm.rmActualUsedQtyError;
            delete rm.rmUnequalRequiredAndProjectedQtyError;
            delete rm.rmOverrideQtyError;
            rm.rmProjectedUseQty = saveWithFiveDecimals(rm.rmProjectedUseQty);
            rm.rmActualUsedQty = saveWithFiveDecimals(rm.rmActualUsedQty);
            rm.rmBalanceQty = saveWithFiveDecimals(rm.rmBalanceQty);
            rm.rmOverrideQty = saveWithFiveDecimals(rm.rmOverrideQty);
        });
    });

    let hackError = '';
    let objError = false;
    let generalError = '';
    let rmError = false;

    if (prodCopy.prodOrderDate === '') {
        objError = true;
        prodCopy.prodOrderDateError = generalMessage.REQUIRED;
    }

    if (!regEx.date.test(prodCopy.prodOrderDate)) {
        objError = true;
        prodCopy.prodOrderDateError = generalMessage.INVALID_DATE;
    }

    if (prodCopy.note.length > lengths.noteLength) {
        objError = true;
        prodCopy.noteError = generalMessage.INVALID_LENGTH;
    }

    if (prodCopy.productionDescription.length > lengths.description) {
        objError = true;
        prodCopy.productionDescriptionError = generalMessage.INVALID_LENGTH;
    }

    if (prodCopy.sfgArr.length < 1) {
        generalError = 'Must have atleast one SFG';
    }

    if (prodCopy.sfgArr.length > lengths.prodOrderSfgArr) {
        hackError = appMessage.sourceCodeErrorMessage(
            'ProdOrders: sfgArr length error.'
        );
    }

    prodCopy.sfgArr.forEach((sfg) => {
        // validate orderedQty
        if (isNaN(sfg.sfgOrderedQty)) {
            objError = true;
            sfg.sfgOrderedQtyError = generalMessage.INVALID;
        }

        if (sfg.sfgOrderedQty < 1) {
            objError = true;
            sfg.sfgOrderedQtyError = generalMessage.GREATER_ZERO;
        }

        if (sfg.sfgOrderedQty > lengths.numberInputAmount) {
            objError = true;
            sfg.sfgOrderedQtyError = generalMessage.INVALID_AMOUNT;
        }

        // validate overrrideQty
        if (Number(sfg.sfgOverrideQty) + sfg.sfgBalanceQty < 0) {
            objError = true;
            sfg.sfgOverrideQtyError =
                'Cannot subtract more than the balance qty';
        }
        if (Number(sfg.sfgOverrideQty) > lengths.numberInputAmount) {
            objError = true;
            sfg.sfgOverrideQtyError = generalMessage.INVALID_AMOUNT;
        }

        if (isNaN(sfg.sfgOverrideQty)) {
            objError = true;
            sfg.sfgOverrideQtyError = generalMessage.INVALID;
        }
    });

    prodCopy.sfgArr.forEach((sfg) => {
        let rmMapRequiredQty = {};
        let rmMapGroupedUsedQty = {};

        sfg.rmArr.forEach((rm) => {
            if (rm.rmProjectedUseQty < 0) {
                rm.rmProjectedUseQtyError = generalMessage.INVALID;
                objError = true;
            }
            if (isNaN(rm.rmProjectedUseQty)) {
                rm.rmProjectedUseQtyError = generalMessage.INVALID;
                objError = true;
                rmError = true;
            }
            if (rm.rmProjectedUseQty > lengths.numberInputAmount) {
                rm.rmProjectedUseQtyError = generalMessage.INVALID_AMOUNT;
                objError = true;
                rmError = true;
            }

            //! for testing purposes of back end logic, use this...

            // if (
            //     userActionPassed !== userActions.REJECTED &&
            //     userActionPassed !== userActions.SENT_FOR_APPROVAL &&
            //     userActionPassed !== userActions.APPROVED
            // ) {
            //! ===============================================

            if (
                userActionPassed !== userActions.REJECTED &&
                userActionPassed !== userActions.OVERRIDDEN &&
                userActionPassed !== userActions.DELETED
            ) {
                // check if projectedUsedQty is > available rm projectedQty.
                let rmAvailableProjectedQty = masterInventoryRMsList.filter(
                    (rawMatInList) => rawMatInList.rmUID === rm.rmUID
                )[0].rmProjectedQty;

                if (
                    Number(rmAvailableProjectedQty) <
                    Number(rm.rmProjectedUseQty)
                ) {
                    rm.rmProjectedUseQtyError =
                        generalMessage.NOT_ENOUGH_INVENTORY;
                    objError = true;

                    if (
                        userActionPassed === userActions.APPROVED ||
                        userActionPassed === userActions.SENT_FOR_APPROVAL
                    ) {
                        generalError = generalMessage.RECENT_INVENTORY_CHANGES;
                    }
                }
            }
            // ===========================================================

            // ==========================================================
            // check balance of each rm.

            if (rm.rmOverrideQty + rm.rmBalanceQty < 0) {
                objError = true;
                rm.rmOverrideQtyError =
                    'Cannot subtract more than the balance qty';
            }
            if (rm.rmOverrideQty > lengths.numberInputAmount) {
                objError = true;
                rm.rmOverrideQtyError = generalMessage.INVALID_AMOUNT;
            }

            if (isNaN(rm.rmOverrideQty)) {
                objError = true;
                rm.rmOverrideQtyError = generalMessage.INVALID;
            }

            //==========================================================

            if (!rmError) {
                if (rmMapRequiredQty[rm.group] === undefined) {
                    rmMapRequiredQty[rm.group] = saveWithFiveDecimals(
                        saveWithFiveDecimals(rm.rmRequiredQty) *
                            saveWithFiveDecimals(sfg.sfgOrderedQty)
                    );
                    rmMapGroupedUsedQty[rm.group] = saveWithFiveDecimals(
                        rm.rmProjectedUseQty
                    );
                } else {
                    rmMapGroupedUsedQty[rm.group] += saveWithFiveDecimals(
                        rm.rmProjectedUseQty
                    );
                }
            }
        });

        if (!rmError) {
            for (let key in rmMapRequiredQty) {
                if (
                    saveWithFiveDecimals(rmMapRequiredQty[key]) !==
                    saveWithFiveDecimals(rmMapGroupedUsedQty[key])
                ) {
                    objError = true;
                    sfg.rmArr.forEach((rm) => {
                        if (
                            saveWithFiveDecimals(rm.group) ===
                            saveWithFiveDecimals(key)
                        ) {
                            rm.rmUnequalRequiredAndProjectedQtyError =
                                'Projected qty (numerator) not equal required qty (denominator)';
                        }
                    });
                }
            }
        }
    });

    if (hackError || objError || generalError) {
        let errorObj = {
            productionObj: prodCopy,
            hackError,
            generalError,
        };
        return responseFn(null, true, errorObj, 'Error detected');
    }

    let validSfgArr = prodCopy.sfgArr.map((sfg) => {
        let validRmArr = sfg.rmArr.map((rm) => {
            let rmBalance = saveWithFiveDecimals(rm.rmProjectedUseQty);
            if (purposeProp === 'OVERRIDE')
                rmBalance = saveWithFiveDecimals(rm.rmBalanceQty);

            return {
                rmUID: rm.rmUID,
                group: rm.group,
                rmRequiredQty: saveWithFiveDecimals(rm.rmRequiredQty),
                rmUnit: rm.rmUnit,
                rmProjectedUseQty: saveWithFiveDecimals(rm.rmProjectedUseQty),
                rmActualUsedQty: saveWithFiveDecimals(rm.rmActualUsedQty) || 0,
                rmBalanceQty: rmBalance,
                rmOverrideQty: saveWithFiveDecimals(rm.rmOverrideQty) || 0,
            };
        });

        let sfgBalance = saveWithFiveDecimals(sfg.sfgOrderedQty);
        if (purposeProp === 'OVERRIDE')
            sfgBalance = saveWithFiveDecimals(sfg.sfgBalanceQty);

        return {
            sfgUID: sfg.sfgUID,
            sfgOrderedQty: saveWithFiveDecimals(sfg.sfgOrderedQty),
            sfgProducedQty: saveWithFiveDecimals(sfg.sfgProducedQty) || 0,
            sfgOverrideQty: saveWithFiveDecimals(sfg.sfgOverrideQty) || 0,
            sfgBalanceQty: sfgBalance,
            sfgUnit: sfg.sfgUnit,
            rmArr: validRmArr,
        };
    });

    let valid = {
        ...prodCopy,
        productionUID: prodCopy.productionUID,
        productionNumber: prodCopy.productionUID,
        prodOrderDate: prodCopy.prodOrderDate,
        productionDescription: prodCopy.productionDescription,
        sfgArr: validSfgArr,
        note: prodCopy.note,
    };

    return responseFn(valid, false, {}, 'no error');
};
