export default {
    quote: 'aquamarine',
    created: 'turquoise',
    updated: 'lightskyblue',
    forApproval: 'deepskyblue',
    rejected: 'red',
    approved: 'blue',
    inVoucher: 'mediumblue',
    inCollection: 'mediumblue',
    sentTo: 'mediumblue',
    override: 'orange',
    fulfilled: 'navy',
    paymentSent: 'navy',
    paid: 'navy',

    sky: '#00BFFF',
    purple: '#8A2BE2',
    green: '#008000',
    lightgreen: '#90ee90',
    amber: '#FFA500',
};

// export default {
//     quote: 'lime',
//     created: 'limegreen',
//     updated: 'green',
//     forApproval: 'deepskyblue',
//     rejected: 'red',
//     approved: 'blue',
//     sentTo: 'blueviolet',
//     override: 'darkmagenta',
//     fulfilled: 'violet',
//     paymentSent: 'violet',

//     sky: '#00b0ff',
//     purple: '#d500f9',
//     green: '#00e676',
//     lime: '#76ff03',
//     amber: '#ffc107',
// };
