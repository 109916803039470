import { db, firebase } from '../firebase_config/firebaseConfig';
import { userActions, systemActions } from '../lib/constants';
import {
    responseFn,
    formatCustomErrorObj,
    formatAlertErrorObj,
    saveWithFiveDecimals,
} from '../lib/util';
import validate from '../validators';
import mListHelper from './mListHelper';
import dbopsGetDocument from './dbopsGetDocument';
import { docNames, appMessage } from '../lib/messages';
import operationObjToMListItemFn from '../lib/masterListConverters/operationObjToMListItemFn';
import {
    sfgInvObjToMListItemFn,
    rmInvObjToMListItemFn,
} from '../lib/masterListConverters';

import {
    PROD_ORDER_COL,
    MASTER_LISTS_COL,
    MASTER_PROD_ORDER_LIST_DOC,
    DELETED_PROD_ORDER_COL,
    PROD_ORDER_COUNTER_DOC,
    SG_NUMBERS_COL,
    SEMI_FGS_COL,
    RAW_MATERIALS_COL,
    MASTER_INVENTORY_RM_LIST_DOC,
    MASTER_INVENTORY_SFG_LIST_DOC,
} from '../lib/constants';

// ================================================
// function to fetch each Production Order document.
const getProdOrderFn = async (docID) =>
    dbopsGetDocument(PROD_ORDER_COL, docID, 'Production Order');

// ======================================================

const createProdOrderFn = async (userCreds, productionObj) => {
    // add metadata to productionObj to be created in firestore.
    productionObj.meta = {
        action: userActions.CREATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // add metaHistory
    productionObj.metaHistory = [];

    const transactionFn = async (transaction) => {
        let prodOrderCounterRef = db
            .collection(SG_NUMBERS_COL)
            .doc(PROD_ORDER_COUNTER_DOC);

        let prodOrderCounterDoc = await transaction.get(prodOrderCounterRef);
        let currentProdOrderNum = prodOrderCounterDoc
            .data()
            .ProductionOrderNumber.toString();

        // add server generated number to productionUID.
        productionObj.productionUID = currentProdOrderNum;
        productionObj.productionNumber = currentProdOrderNum;

        let newProdRef = db
            .collection(PROD_ORDER_COL)
            .doc(productionObj.productionUID);

        let productionListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_PROD_ORDER_LIST_DOC);

        // TRANSACTION GET OPERATIONS ================================
        // except ProductionOrderNumber. GET finished already for the
        // productionDoc.
        let productionDoc = await transaction.get(newProdRef);
        let productionListDoc = await transaction.get(productionListRef);

        // check exist error for new Production Order document
        if (productionDoc.exists) {
            return Promise.reject(
                formatCustomErrorObj(
                    1002,
                    appMessage.alreadyExist(docNames.PRODUCTION)
                )
            );
        }

        // START MANIPULATING DATA TO UPDATE DATABASE ================================

        // increment SGNumbers Collection > ProductionOrderCounter > ProductionOrderNumber
        await transaction.set(
            prodOrderCounterRef,
            {
                ProductionOrderNumber:
                    prodOrderCounterDoc.data().ProductionOrderNumber + 1,
            },
            { merge: true }
        );

        // create or update production order list document in (master lists collection)
        if (productionListDoc.exists) {
            let productionInfoObj = operationObjToMListItemFn(
                productionObj,
                'productionUID'
            );

            let newData = mListHelper.updateMasterList(
                productionListDoc,
                productionObj.productionUID,
                productionInfoObj
            );

            await transaction.set(productionListRef, newData);
        } else {
            let productionInfoObj = operationObjToMListItemFn(
                productionObj,
                'productionUID'
            );

            let newData = mListHelper.addMasterList(
                productionObj.productionUID,
                productionInfoObj
            );

            await transaction.set(productionListRef, newData);
        }

        // set new production order document.
        await transaction.set(newProdRef, productionObj);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyCreated(docNames.PRODUCTION)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorCreating(docNames.PRODUCTION)
        );
    }
};

// ===============================================
const updateProdOrdersFn = async (
    userCreds,
    productionObj,
    documentBasis,
    userActionPassed
) => {
    if (productionObj.metaHistory.length > 99)
        productionObj.metaHistory.shift();

    productionObj.metaHistory.push(productionObj.meta);

    let status = userActionPassed ? userActionPassed : userActions.UPDATED;

    // update metadata in productionObj.
    productionObj.meta = {
        action: status,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // use productionUID as docuid
    let productionRef = db
        .collection(PROD_ORDER_COL)
        .doc(productionObj.productionUID);

    let productionListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_PROD_ORDER_LIST_DOC);

    const transactionFn = async (transaction) => {
        let productionDoc = await transaction.get(productionRef);

        if (!productionDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.PRODUCTION)
                )
            );

        if (!validate.noChange(productionDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.PRODUCTION)
                )
            );
        }

        let productionListDoc = await transaction.get(productionListRef);

        let productionInfoObj = operationObjToMListItemFn(
            productionObj,
            'productionUID'
        );

        let newData = mListHelper.updateMasterList(
            productionListDoc,
            productionObj.productionUID,
            productionInfoObj
        );

        await transaction.set(productionListRef, newData);

        await transaction.set(productionRef, productionObj);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyUpdated(docNames.PRODUCTION)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorUpdating(docNames.PRODUCTION)
        );
    }
};

// ================================================================
const deleteProdOrderFn = async (userCreds, productionObj, documentBasis) => {
    if (productionObj.metaHistory.length > 99)
        productionObj.metaHistory.shift();

    productionObj.metaHistory.push(productionObj.meta);

    productionObj.meta = {
        action: userActions.DELETED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // use productionUID. see updateProdOrdersFn
    let productionRef = db
        .collection(PROD_ORDER_COL)
        .doc(productionObj.productionUID);

    let productionListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_PROD_ORDER_LIST_DOC);

    let deletedProductionRef = db.collection(DELETED_PROD_ORDER_COL).doc();

    const transactionFn = async (transaction) => {
        let productionDoc = await transaction.get(productionRef);

        if (!productionDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotDeleteBecauseDocNotExist(
                        docNames.PRODUCTION
                    )
                )
            );

        if (!validate.noChange(productionDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotDeleteBecauseDocChanged(
                        docNames.PRODUCTION
                    )
                )
            );
        }

        let productionListDoc = await transaction.get(productionListRef);
        await transaction.get(deletedProductionRef);

        let newData = mListHelper.deletePropertyFromMasterList(
            productionListDoc,
            productionObj.productionUID
        );

        await transaction.delete(productionRef);
        await transaction.set(productionListRef, newData);
        await transaction.set(deletedProductionRef, productionObj);
        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyDeleted(docNames.PRODUCTION)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorDeleting(docNames.PRODUCTION)
        );
    }
};

// ================================================================
const statusChangeFn = async (userCreds, productionObj, documentBasis) => {
    // move old metadate into metahistory. ensure meta data history length
    // is 100 or less. will be checked by security rules.
    if (productionObj.metaHistory.length > 99)
        productionObj.metaHistory.shift();

    productionObj.metaHistory.push(productionObj.meta);

    // update metadata in productionObj.
    productionObj.meta = {
        action: userCreds.action,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // get references.
    let productionRef = db
        .collection(PROD_ORDER_COL)
        .doc(productionObj.productionUID);
    let productionListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_PROD_ORDER_LIST_DOC);

    const transactionFn = async (transaction) => {
        let productionDoc = await transaction.get(productionRef);
        let productionListDoc = await transaction.get(productionListRef);

        if (!productionDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.PRODUCTION)
                )
            );
        // ! this does not check meta data.
        if (!validate.noChange(productionDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.PRODUCTION)
                )
            );
        }
        // ! meta data check if status of document changed.
        if (productionDoc.data().meta.action !== documentBasis.meta.action) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.PRODUCTION)
                )
            );
        }

        let productionInfoObj = operationObjToMListItemFn(
            productionObj,
            'productionUID'
        );

        let newData = mListHelper.updateMasterList(
            productionListDoc,
            productionObj.productionUID,
            productionInfoObj
        );

        // write to masterlist and pdpo document in firebase.
        await transaction.set(productionListRef, newData);
        await transaction.set(productionRef, productionObj);
        return Promise.resolve('TransactionFn Completed Successfully');
    };
    try {
        await db.runTransaction(transactionFn);
        return responseFn(null, false, null, appMessage.successfulUpdateStatus);
    } catch (e) {
        return responseFn(null, true, e, appMessage.errorUpdatingStatus);
    }
};

const approveProductionFn = async (userCreds, productionObj, documentBasis) => {
    if (productionObj.metaHistory.length > 99)
        productionObj.metaHistory.shift();

    productionObj.metaHistory.push(productionObj.meta);

    // add metadata to productionObj to be created in firestore.
    productionObj.meta = {
        action: userActions.APPROVED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let existError = false;
    let balanceError = false;

    let whatRM = [];
    let rmBalanceErrorMsg = '';

    const transactionFn = async (transaction) => {
        let productionRef = db
            .collection(PROD_ORDER_COL)
            .doc(productionObj.productionUID);

        let productionListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_PROD_ORDER_LIST_DOC);

        let rmInvListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_INVENTORY_RM_LIST_DOC);

        let sfgInvListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_INVENTORY_SFG_LIST_DOC);

        // get references for all sfgObjs
        let sfgArrRefs = productionObj.sfgArr.map((sfg) => {
            return db.collection(SEMI_FGS_COL).doc(sfg.sfgUID);
        });

        // create an rmMap.
        let rmMap = {};
        productionObj.sfgArr.forEach((sfg) => {
            sfg.rmArr.forEach((rm) => {
                rmMap[rm.rmUID] = { computedQty: 0, prodOrderNumbersArr: [] };
            });
        });

        // get references for all rmObjs
        let rmMapRefs = {};
        for (let key in rmMap) {
            rmMapRefs[key] = db.collection(RAW_MATERIALS_COL).doc(key);
        }

        // TRANSACTION GET OPERATIONS ================================

        let productionDoc = await transaction.get(productionRef);
        let productionListDoc = await transaction.get(productionListRef);

        // check exist error for new Production Order document
        // should never run.
        if (!productionDoc.exists) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.PRODUCTION)
                )
            );
        }
        if (!validate.noChange(productionDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.PRODUCTION)
                )
            );
        }

        // get sfg objects
        let sfgArrDocs = sfgArrRefs.map(
            async (sfgRef) => await transaction.get(sfgRef)
        );

        try {
            sfgArrDocs = await Promise.all(sfgArrDocs);
        } catch (e) {
            return Promise.reject(
                formatCustomErrorObj(
                    900,
                    appMessage.prommiseAllError('dbopsProdOrders', 'sfgArr')
                )
            );
        }

        // check exists error for sfg Objects. no need to check for changes because we
        // are just going to increment the sfgOrderQty.
        sfgArrDocs.forEach((sfgDoc) => {
            if (!sfgDoc.exists) existError = true;
        });

        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopsProdOrders',
                        docNames.PRODUCTION,
                        'SFG'
                    )
                )
            );
        }

        // get all rm docs
        let rmMapDocs = {};
        for (let key in rmMapRefs) {
            rmMapDocs[key] = await transaction.get(rmMapRefs[key]);
        }

        // check if the rmMapDocs exist.
        for (let key in rmMapDocs) {
            if (!rmMapDocs[key].exists) existError = true;
        }

        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopsProdOrders',
                        docNames.PRODUCTION,
                        'RM'
                    )
                )
            );
        }

        let rmInvListDoc = await transaction.get(rmInvListRef);
        let sfgInvListDoc = await transaction.get(sfgInvListRef);

        // END TRANSACTION GET OPERATIONS ============================================

        // START MANIPULATING DATA TO UPDATE DATABASE ================================

        // for sfg inventory process.
        let tempSfgInventoryList = sfgInvListDoc.data();

        // set sfg orderedQty and add productionUID to prodOrderNumbersArr
        productionObj.sfgArr.forEach(async (sfg, index) => {
            let sfgCopy = {
                ...sfgArrDocs[index].data(),
            };
            sfgCopy.sfgProjectedQty = saveWithFiveDecimals(
                sfgCopy.sfgProjectedQty + sfg.sfgOrderedQty
            );
            sfgCopy.prodOrderNumbersArr.push(productionObj.productionUID);

            let inventorySfgInfoObj = sfgInvObjToMListItemFn(sfgCopy);

            tempSfgInventoryList = mListHelper.updateTempMList(
                tempSfgInventoryList,
                sfgCopy.sfgUID,
                inventorySfgInfoObj
            );

            await transaction.set(sfgArrRefs[index], sfgCopy, { merge: true });
        });

        // calculate the rmProjectedQty that needs to be deducted.
        productionObj.sfgArr.forEach(async (sfg) => {
            sfg.rmArr.forEach((rm) => {
                rmMap[rm.rmUID].computedQty = saveWithFiveDecimals(
                    rmMap[rm.rmUID].computedQty + rm.rmProjectedUseQty
                );
            });
        });

        // for RM inventory process.
        let tempRmInventoryList = rmInvListDoc.data();

        // set rm rmProjectedQty

        for (let key in rmMap) {
            //! check if there is enough rmProjectedQty.
            let currentProjectedQty = rmMapDocs[key].data().rmProjectedQty;
            if (currentProjectedQty - rmMap[key].computedQty < 0) {
                balanceError = true;
                whatRM.push(key);
            }

            //! add productionUID into the Each RM.prodOrderNumbersArr
            let newProdOrderNumbersArr = [
                ...rmMapDocs[key].data().prodOrderNumbersArr,
            ];
            newProdOrderNumbersArr.push(productionObj.productionUID);

            let newProjectedQty = saveWithFiveDecimals(
                currentProjectedQty - rmMap[key].computedQty
            );
            let data = {
                rmProjectedQty: newProjectedQty,
                prodOrderNumbersArr: newProdOrderNumbersArr,
            };

            let rmCopy = rmMapDocs[key].data();
            rmCopy.rmProjectedQty = data.rmProjectedQty;
            let inventoryRmInfoObj = rmInvObjToMListItemFn(rmCopy);

            tempRmInventoryList = mListHelper.updateTempMList(
                tempRmInventoryList,
                rmCopy.rmUID,
                inventoryRmInfoObj
            );

            await transaction.set(rmMapRefs[key], data, { merge: true });
        }

        if (balanceError) {
            rmBalanceErrorMsg = 'Insufficient RM Projected Qty';
            return Promise.reject(
                formatCustomErrorObj(800, `Insufficient RM Projected Qty`)
            );
        }

        // create or update production order list document in (master lists collection)
        if (productionListDoc.exists) {
            let productionInfoObj = operationObjToMListItemFn(
                productionObj,
                'productionUID'
            );

            let newData = mListHelper.updateMasterList(
                productionListDoc,
                productionObj.productionUID,
                productionInfoObj
            );

            await transaction.set(productionListRef, newData);
        } else {
            let productionInfoObj = operationObjToMListItemFn(
                productionObj,
                'productionUID'
            );

            let newData = mListHelper.addMasterList(
                productionObj.productionUID,
                productionInfoObj
            );

            await transaction.set(productionListRef, newData);
        }

        // set new sfg inventory master list
        await transaction.set(sfgInvListRef, tempSfgInventoryList);

        // set new rm inventory master list
        await transaction.set(rmInvListRef, tempRmInventoryList);

        // set new production order document.
        await transaction.set(productionRef, productionObj);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(null, false, null, appMessage.successfulUpdateStatus);
    } catch (e) {
        let customErrorObj = rmBalanceErrorMsg
            ? formatAlertErrorObj(rmBalanceErrorMsg, whatRM)
            : null;
        return responseFn(
            customErrorObj,
            true,
            e,
            appMessage.errorUpdatingStatus
        );
    }
};

const overrideProductionFn = async (
    userCreds,
    productionObj,
    documentBasis
) => {
    if (productionObj.metaHistory.length > 99)
        productionObj.metaHistory.shift();

    productionObj.metaHistory.push(productionObj.meta);

    // add metadata to productionObj to be created in firestore.
    productionObj.meta = {
        action: userActions.OVERRIDDEN,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let existError = false;
    let balanceError = false;

    let whatRM = [];
    let rmBalanceErrorMsg = '';

    const transactionFn = async (transaction) => {
        let productionRef = db
            .collection(PROD_ORDER_COL)
            .doc(productionObj.productionUID);

        let productionListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_PROD_ORDER_LIST_DOC);

        let rmInvListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_INVENTORY_RM_LIST_DOC);

        let sfgInvListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_INVENTORY_SFG_LIST_DOC);

        // get references for all sfgObjs
        let sfgArrRefs = productionObj.sfgArr.map((sfg) => {
            return db.collection(SEMI_FGS_COL).doc(sfg.sfgUID);
        });

        // create an rmMap.
        let rmMap = {};
        productionObj.sfgArr.forEach((sfg) => {
            sfg.rmArr.forEach((rm) => {
                rmMap[rm.rmUID] = { computedQty: 0 };
            });
        });

        // get references for all rmObjs
        let rmMapRefs = {};
        for (let key in rmMap) {
            rmMapRefs[key] = db.collection(RAW_MATERIALS_COL).doc(key);
        }

        // TRANSACTION GET OPERATIONS ================================

        let productionDoc = await transaction.get(productionRef);
        let productionListDoc = await transaction.get(productionListRef);

        // check exist error for new Production Order document
        // should never run.
        if (!productionDoc.exists) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.PRODUCTION)
                )
            );
        }

        //! updated noChange validation to check for status changes.
        if (!validate.noChange(productionDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.PRODUCTION)
                )
            );
        }

        // get sfg objects
        let sfgArrDocs = sfgArrRefs.map(
            async (sfgRef) => await transaction.get(sfgRef)
        );

        try {
            sfgArrDocs = await Promise.all(sfgArrDocs);
        } catch (e) {
            return Promise.reject(
                formatCustomErrorObj(
                    900,
                    appMessage.prommiseAllError('dbopsProdOrders', 'sfgArr')
                )
            );
        }

        // check exists error for sfg Objects. no need to check for changes because we
        // are just going to increment the sfgOrderQty.
        sfgArrDocs.forEach((sfgDoc) => {
            if (!sfgDoc.exists) existError = true;
        });

        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopsProdOrders',
                        docNames.PRODUCTION,
                        'SFG'
                    )
                )
            );
        }

        // get all rm docs
        let rmMapDocs = {};
        for (let key in rmMapRefs) {
            rmMapDocs[key] = await transaction.get(rmMapRefs[key]);
        }

        // check if the rmMapDocs exist.
        for (let key in rmMapDocs) {
            if (!rmMapDocs[key].exists) existError = true;
        }

        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopsProdOrders',
                        docNames.PRODUCTION,
                        'RM'
                    )
                )
            );
        }

        let rmInvListDoc = await transaction.get(rmInvListRef);
        let sfgInvListDoc = await transaction.get(sfgInvListRef);

        // END TRANSACTION GET OPERATIONS ============================================

        // check if production status can be changed to 'FULFILLED'. ==================
        let allSfgsAndRmsDeliveredOrOverridden = [];
        productionObj.sfgArr.forEach((sfg) => {
            if (sfg.sfgBalanceQty + sfg.sfgOverrideQty === 0) {
                allSfgsAndRmsDeliveredOrOverridden.push(true);

                // if sfg balance is 0, check each rm if balance is also zero
                sfg.rmArr.forEach((rm) => {
                    if (rm.rmBalanceQty + rm.rmOverrideQty === 0) {
                        allSfgsAndRmsDeliveredOrOverridden.push(true);
                    } else {
                        allSfgsAndRmsDeliveredOrOverridden.push(false);
                    }
                });
            } else {
                allSfgsAndRmsDeliveredOrOverridden.push(false);
            }
        });

        // copy of sfgDocs so prodOrderNumbersArr can be manipulated.
        let sfgArrDocsCopy = sfgArrDocs.map((sfgDoc) => sfgDoc.data());
        let removeProductionUIDFromRmProdOrderNumbersArr = false;

        if (allSfgsAndRmsDeliveredOrOverridden.every((ans) => ans === true)) {
            //! remove productionUID from all the sfgs in the production.sfgArr
            sfgArrDocsCopy.forEach((sfgObj) => {
                let newArr = sfgObj.prodOrderNumbersArr.filter(
                    (productionNumber) => {
                        return productionNumber !== productionObj.productionUID;
                    }
                );
                sfgObj.prodOrderNumbersArr = newArr;
            });

            removeProductionUIDFromRmProdOrderNumbersArr = true;

            // update metadata in productionObj.
            productionObj.meta = {
                action: systemActions.FULFILLED,
                uid: systemActions.UID,
                email: systemActions.EMAIL,
                alert: {
                    note:
                        '"OVERRIDDEN" by user then system changed status to "FULFILLED"',
                    action: userActions.OVERRIDDEN,
                    uid: userCreds.uid,
                    email: userCreds.email,
                    timeStamp:
                        'Same time as system changed status of this document.',
                },
                timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
            };
        } else {
            // else... meta will be overridden and changed to status "OVERRIDDEN"
            // done in the first step of the function.

            //! add productionUID in all sfgs inside productionObj.sfgArr.
            sfgArrDocsCopy.forEach((sfgObj) => {
                let newArr = sfgObj.prodOrderNumbersArr.filter(
                    (productionNumber) => {
                        return productionNumber !== productionObj.productionUID;
                    }
                );
                newArr.push(productionObj.productionUID);
                sfgObj.prodOrderNumbersArr = newArr;
            });

            removeProductionUIDFromRmProdOrderNumbersArr = false;
        }

        // for sfg inventory process.
        let tempSfgInventoryList = sfgInvListDoc.data();

        // calculate all sfg projectedQty
        sfgArrDocs.forEach(async (sfgDoc, sfgIndex) => {
            let current = productionObj.sfgArr[sfgIndex].sfgOverrideQty;
            let previous = documentBasis.sfgArr[sfgIndex].sfgOverrideQty;
            let newProjected = saveWithFiveDecimals(
                sfgDoc.data().sfgProjectedQty + (current - previous)
            );

            let updateData = {
                sfgProjectedQty: newProjected,
                prodOrderNumbersArr:
                    sfgArrDocsCopy[sfgIndex].prodOrderNumbersArr,
            };

            let sfgCopy = sfgArrDocsCopy[sfgIndex];
            sfgCopy.sfgProjectedQty = updateData.sfgProjectedQty;
            let inventorySfgInfoObj = sfgInvObjToMListItemFn(sfgCopy);

            tempSfgInventoryList = mListHelper.updateTempMList(
                tempSfgInventoryList,
                sfgCopy.sfgUID,
                inventorySfgInfoObj
            );

            await transaction.set(sfgArrRefs[sfgIndex], updateData, {
                merge: true,
            });
        });

        //! this needs to be in a separate loop because of the async loop above.
        // sfgArrDocs.forEach((sfgDoc, sfgIndex) => {
        //     // calculate rmProjectedQty that needs to be deducted.
        //     sfgDoc.data().rmArr.forEach(async (rm, rmIndex) => {
        //         let currentRmOverrideQty =
        //             productionObj.sfgArr[sfgIndex].rmArr[rmIndex].rmOverrideQty;
        //         let previousRmOverrideQty =
        //             documentBasis.sfgArr[sfgIndex].rmArr[rmIndex].rmOverrideQty;

        //         rmMap[rm.rmUID].computedQty = saveWithFiveDecimals(
        //             rmMap[rm.rmUID].computedQty +
        //                 (currentRmOverrideQty - previousRmOverrideQty)
        //         );
        //     });
        // });

        //! this needs to be in a separate loop because of the async loop above.

        //! bug fix 2021-03-21. required items of sfg (the rms) is now going to reference
        //! whatever the components during the approval of this production order so any changes
        //! in the setup of the sfg will not produce an error when overriding prod order.
        productionObj.sfgArr.forEach((sfg, sfgIndex) => {
            // calculate rmProjectedQty that needs to be deducted.
            sfg.rmArr.forEach(async (rm, rmIndex) => {
                let currentRmOverrideQty =
                    productionObj.sfgArr[sfgIndex].rmArr[rmIndex].rmOverrideQty;
                let previousRmOverrideQty =
                    documentBasis.sfgArr[sfgIndex].rmArr[rmIndex].rmOverrideQty;

                rmMap[rm.rmUID].computedQty = saveWithFiveDecimals(
                    rmMap[rm.rmUID].computedQty +
                        (currentRmOverrideQty - previousRmOverrideQty)
                );
            });
        });

        let tempRmInventoryList = rmInvListDoc.data();

        for (let key in rmMap) {
            //! check if there is enough rmProjectedQty.
            let currentProjectedQty = rmMapDocs[key].data().rmProjectedQty;
            if (currentProjectedQty - rmMap[key].computedQty < 0) {
                balanceError = true;
                whatRM.push(key);
            }

            //! add or remove productionUID to and from each rm.prodOrderNumbersArr
            let newProdOrderNumbersArr = [
                ...rmMapDocs[key].data().prodOrderNumbersArr,
            ];
            if (removeProductionUIDFromRmProdOrderNumbersArr) {
                newProdOrderNumbersArr = newProdOrderNumbersArr.filter(
                    (prodUID) => {
                        return prodUID !== productionObj.productionUID;
                    }
                );
            } else {
                newProdOrderNumbersArr = newProdOrderNumbersArr.filter(
                    (prodUID) => {
                        return prodUID !== productionObj.productionUID;
                    }
                );
                // add the productionUID into the prodOrderNumbersArr.
                newProdOrderNumbersArr.push(productionObj.productionUID);
            }

            let newProjected = saveWithFiveDecimals(
                currentProjectedQty - rmMap[key].computedQty
            );

            let data = {
                rmProjectedQty: newProjected,
                prodOrderNumbersArr: newProdOrderNumbersArr,
            };

            let rmCopy = rmMapDocs[key].data();
            rmCopy.rmProjectedQty = data.rmProjectedQty;
            let inventoryRmInfoObj = rmInvObjToMListItemFn(rmCopy);

            tempRmInventoryList = mListHelper.updateTempMList(
                tempRmInventoryList,
                rmCopy.rmUID,
                inventoryRmInfoObj
            );

            await transaction.set(rmMapRefs[key], data, { merge: true });
        }

        if (balanceError) {
            rmBalanceErrorMsg = 'Insufficient RM Projected Qty';
            return Promise.reject(
                formatCustomErrorObj(800, `Insufficient RM Projected Qty`)
            );
        }

        // create or update production order list document in (master lists collection)
        if (productionListDoc.exists) {
            let productionInfoObj = operationObjToMListItemFn(
                productionObj,
                'productionUID'
            );

            let newData = mListHelper.updateMasterList(
                productionListDoc,
                productionObj.productionUID,
                productionInfoObj
            );

            await transaction.set(productionListRef, newData);
        } else {
            let productionInfoObj = operationObjToMListItemFn(
                productionObj,
                'productionUID'
            );

            let newData = mListHelper.addMasterList(
                productionObj.productionUID,
                productionInfoObj
            );

            await transaction.set(productionListRef, newData);
        }

        await transaction.set(productionRef, productionObj);
        await transaction.set(sfgInvListRef, tempSfgInventoryList);
        await transaction.set(rmInvListRef, tempRmInventoryList);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyUpdated(docNames.PRODUCTION)
        );
    } catch (e) {
        let customErrorObj = rmBalanceErrorMsg
            ? formatAlertErrorObj(rmBalanceErrorMsg, whatRM)
            : null;
        return responseFn(
            customErrorObj,
            true,
            e,
            appMessage.errorUpdating(docNames.PRODUCTION)
        );
    }
};

export default {
    getProdOrderFn,
    createProdOrderFn,
    updateProdOrdersFn,
    deleteProdOrderFn,
    statusChangeFn,
    approveProductionFn,
    overrideProductionFn,
};
