import React, { useState, useEffect, useContext } from 'react';
import {
    Datalist,
    AddButton,
    Input,
    CreateItemButtons,
    UpdateItemButtons,
    DeleteItemButtons,
    BackOnlyButton,
    BackSendEditDelButtons,
    BackWithMarkAsButtons,
    ApproveRejectButtons,
    TextArea,
    Watermark,
    SignatureLine,
} from '../../components';
import {
    AuthContext,
    MasterListContext,
    DataShareContext,
} from '../../contexts';
import {
    permissions,
    userActions,
    lengths,
    PDSIS_COL,
} from '../../lib/constants';
import {
    clone,
    displayDate,
    displayMoney,
    saveWithTwoDecimalOnly,
} from '../../lib/util';
import { dbopsGetDocument } from '../../firebase_dbops';

import validate from '../../validators';

import '../PagesCommon.css';

let defaultPdvr = {
    pdvrUID: '',
    pdvrNumber: '',
    supplierUID: '',
    pdsiArr: [],
    meta: { action: '' },
    note: '',
};

export default (props) => {
    let {
        purpose,
        currentDocument,

        backCancelBtnFn,
        gotoEditViewFn,
        gotoDeleteViewFn,

        createVrFn,
        deleteVrFn,
        updateVrFn,
        statusChangeFn,

        setIsLoading,
        setAlertErrorMsg,
    } = props;

    let { masterPDSalesInvoicesList, masterSuppliersList } =
        useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);
    let { serverDate } = useContext(DataShareContext);

    let [generalError, setGeneralError] = useState('');
    let [datalistDisplayResetCounter, setDatalistDisplayResetCounter] =
        useState(1);

    let [pdvrObj, setPdvrObj] = useState(defaultPdvr);
    let [pdvrUIDstate, setPdvrUIDstate] = useState('');
    let [tempSupplier, setTempSupplier] = useState('');
    let [supplierUIDstate, setSupplierUIDstate] = useState('');
    let [pdsiUIDinState, setPdsiUIDinState] = useState('');

    let [pdvrDateInState, setPdvrDateInState] = useState(serverDate);
    let [pdvrDiscountInState, setPdvrDiscountInState] = useState(0);
    let [noteInState, setNoteInState] = useState('');

    let [confirmDeleteText, setConfirmDeleteText] = useState('');
    let [preview, setPreview] = useState(false);

    // set pdvrObj from currentDocument if purpose !== 'ADD'
    useEffect(() => {
        if (purpose !== 'ADD') {
            //making a copy so no side effect will change currentDocument.
            let currentDocumentCopy = {
                ...currentDocument,
                pdsiArr: clone(currentDocument.pdsiArr),
            };

            setPdvrObj(currentDocumentCopy);
            setSupplierUIDstate(currentDocumentCopy.supplierUID);
            setPdvrDateInState(currentDocumentCopy.pdvrDate);
            setNoteInState(currentDocumentCopy.note || '');
            setPdvrUIDstate(currentDocumentCopy.pdvrUID);
            setPdvrDiscountInState(currentDocumentCopy.pdvrDiscount || 0);
        }
    }, []);

    const cancelBtnFromEditViewFn = () => {
        // 1. reset values.
        setPdvrObj(currentDocument);
        setSupplierUIDstate(currentDocument.supplierUID);
        setPdvrDateInState(currentDocument.pdvrDate);
        setNoteInState(currentDocument.note || '');
        setPdvrUIDstate(currentDocument.pdvrUID);
        setPdvrDiscountInState(currentDocument.pdvrDiscount || 0);

        // 2. reset errorMessages.
        setGeneralError('');

        // 3. go back to profile view.
        backCancelBtnFn();
    };

    // =====================================================

    const deleteErrors = (pdvrObjCopy) => {
        delete pdvrObjCopy.pdvrUIDError;
        delete pdvrObjCopy.pdsiArrError;
        delete pdvrObjCopy.supplierError;
        delete pdvrObjCopy.pdvrDateError;
        delete pdvrObjCopy.noteError;
        delete pdvrObjCopy.pdvrDiscountError;
        setGeneralError('');
    };

    const addToArrFn = async () => {
        let pdvrObjCopy = {
            ...pdvrObj,
            pdsiArr: clone(pdvrObj.pdsiArr),
        };

        deleteErrors(pdvrObjCopy);

        setIsLoading(true);

        let response = await dbopsGetDocument(PDSIS_COL, pdsiUIDinState);
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message || response.message);
            setIsLoading(false);
            return;
        }

        pdvrObjCopy.pdsiArr.push(response.data);

        setPdvrObj(pdvrObjCopy);
        setPdsiUIDinState('');
        setDatalistDisplayResetCounter(datalistDisplayResetCounter + 1);
        setIsLoading(false);
    };

    const removeFromArrFn = (index) => {
        let pdvrObjCopy = {
            ...pdvrObj,
            pdsiArr: clone(pdvrObj.pdsiArr),
        };

        deleteErrors(pdvrObjCopy);

        pdvrObjCopy.pdsiArr.splice(index, 1);
        setPdvrObj(pdvrObjCopy);
    };

    const previewVrBtnFn = () => {
        let pdvrObjCopy = { ...pdvrObj };
        pdvrObjCopy.pdvrUID = pdvrUIDstate;
        pdvrObjCopy.supplierUID = supplierUIDstate;
        pdvrObjCopy.pdvrDate = pdvrDateInState;
        pdvrObjCopy.pdvrDiscount = pdvrDiscountInState;
        pdvrObjCopy.note = noteInState;

        let response = validate.pdvr(pdvrObjCopy);
        if (response.error) {
            setPdvrObj(response.errorObj.pdvrObj);
            setGeneralError(
                response.errorObj.pdvrObj.supplierError ||
                    response.errorObj.pdvrObj.pdsiArrError
            );
            setAlertErrorMsg(response.errorObj.hackError);
        } else {
            setPdvrObj(response.data);
            setPreview(true);
        }
    };

    const saveSIFn = () => {
        let pdvrObjCopy = { ...pdvrObj };
        pdvrObjCopy.pdvrUID = pdvrUIDstate;
        pdvrObjCopy.supplierUID = supplierUIDstate;
        pdvrObjCopy.pdvrDate = pdvrDateInState;
        pdvrObjCopy.note = noteInState;
        pdvrObjCopy.pdvrDiscount = pdvrDiscountInState;

        // check if there is a change in the payload from currentDocument
        if (
            purpose === 'EDIT' &&
            validate.noChange(pdvrObjCopy, currentDocument)
        ) {
            backCancelBtnFn();
            return;
        }

        let response = validate.pdvr(pdvrObjCopy);
        if (response.error) {
            setPdvrObj(response.errorObj.pdvrObj);
            setGeneralError(
                response.errorObj.pdvrObj.supplierError ||
                    response.errorObj.pdvrObj.pdsiArrError
            );
            setAlertErrorMsg(response.errorObj.hackError);
            return;
        }
        setPdvrObj(response.data);

        let validPdvrObj = response.data;

        if (purpose === 'ADD') createVrFn(validPdvrObj);
        if (purpose === 'EDIT') updateVrFn(validPdvrObj, currentDocument);
        if (purpose === 'DELETE') deleteVrFn(validPdvrObj, currentDocument);
    };

    //! work around. fixes sequence of Edit without saving and then sending for approval.
    //! bug found in PDSI, SDSI, SDPO. All without statusChangeLocalFn.
    const statusChangeLocalFn = (userActionPassed) => {
        let pdvrObjCopy = { ...pdvrObj };
        pdvrObjCopy.pdvrUID = pdvrObjCopy.pdvrUID || pdvrUIDstate;
        pdvrObjCopy.supplierUID = pdvrObjCopy.supplierUID || supplierUIDstate;
        pdvrObjCopy.pdvrDate = pdvrDateInState;
        pdvrObjCopy.note = noteInState;
        pdvrObjCopy.pdvrDiscount = pdvrDiscountInState;

        let response = validate.pdvr(pdvrObjCopy);
        if (response.error) {
            setPdvrObj(response.errorObj.pdvrObj);
            setGeneralError(
                response.errorObj.pdvrObj.supplierError ||
                    response.errorObj.pdvrObj.pdsiArrError
            );
            setAlertErrorMsg(response.errorObj.hackError);
            return;
        }

        let validPdvrObj = response.data;

        statusChangeFn(userActionPassed, validPdvrObj, currentDocument);
    };

    // conditional render of contents  =============================

    let pdsiArrUI = [];
    let noteUI = null;

    let buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    let mainContent = null;
    let datalist = null;

    let itemLabel = (
        <div className='span-2-columns-container label-padding label-highlight'>
            <label className='parent-labels'>List of Supplier Invoices</label>
        </div>
    );

    let addBtn = <AddButton disabled />;

    // this section will be reused accross different purposes. =====================
    let total = 0;

    let pdsiItemsSection = null;
    let amountBox = null;

    if (pdvrObj) {
        pdsiArrUI = pdvrObj.pdsiArr.map((si, index) => {
            total = saveWithTwoDecimalOnly(total + si.pdsiTotalsNett);

            let trashIcon = (
                <i
                    className='ms-Icon ms-Icon--Delete clickable-icon'
                    aria-hidden='true'
                    onClick={() => removeFromArrFn(index)}></i>
            );

            if (purpose !== 'ADD' && purpose !== 'EDIT') {
                trashIcon = null;
            }

            return (
                <div className='span-2-columns-container' key={index}>
                    <div className='view-table-row grid-row-40-60'>
                        <div className='cell'>
                            <span className='mobile-label m-right-15'>
                                {`${index + 1}.`}
                            </span>
                            <span className='desktop-view m-lr-15'>
                                {`${index + 1}.`}
                            </span>
                            <span className=''> {si.pdsiUID}</span>
                        </div>

                        <div className='cell'>
                            <span className='width40'>
                                <span className='mobile-label'>
                                    Invoice Date
                                </span>{' '}
                                <span className=''>
                                    {displayDate(si.pdsiDate)}
                                </span>
                            </span>
                            <span className='width40'>
                                <span className='mobile-label'>Amount:</span>{' '}
                                <span className=''>
                                    {displayMoney(si.pdsiTotalsNett)}
                                </span>
                            </span>
                            <span className='width16'>{trashIcon}</span>
                        </div>
                    </div>
                </div>
            );
        });

        pdsiItemsSection = (
            <div className='span-2-columns-container'>
                <div className='view-table-heading grid-row-40-60'>
                    <div className='cell'>
                        <span className='m-left-15'>Supplier Invoice</span>
                    </div>

                    <div className='cell'>
                        <span className='width40'>Invoice Date</span>

                        <span className='width40'>Total Amount</span>

                        <span className='width16'></span>
                    </div>
                </div>

                {pdsiArrUI}
            </div>
        );

        let totalsNett = saveWithTwoDecimalOnly(total - pdvrDiscountInState);

        amountBox = (
            <>
                <div className='desktop-view'></div>
                <div className='amount-box'>
                    <div>Totals (Gross):</div>
                    <div>{displayMoney(total)}</div>
                    <div>Discount:</div>
                    <Input
                        type='number'
                        onChange={(e) => setPdvrDiscountInState(e.target.value)}
                        value={pdvrDiscountInState}
                        errorMessage={pdvrObj.pdvrDiscountError}></Input>
                    <div>Totals (Gross):</div>
                    <div className='text-bold'>{displayMoney(totalsNett)}</div>
                </div>
            </>
        );

        if (purpose !== 'EDIT' && purpose !== 'ADD') {
            amountBox = (
                <>
                    <div className='desktop-view'></div>
                    <div className='amount-box'>
                        <div>Totals (Gross):</div>
                        <div>{displayMoney(total)}</div>
                        <div>Discount:</div>
                        <div>{displayMoney(pdvrDiscountInState)}</div>

                        <div>Totals (Gross):</div>
                        <div className='text-bold'>
                            {displayMoney(totalsNett)}
                        </div>
                    </div>
                </>
            );
        }
    }

    // ==============================================================================

    if ((purpose === 'ADD' && !preview) || purpose === 'EDIT') {
        //! supplier list datalist process ====================================
        let supListOptions = masterSuppliersList.map((sup) => sup.supplierUID);

        let chooseSupBtn = (
            <button
                className='choose-sup-button'
                onClick={() => setSupplierUIDstate(tempSupplier)}>
                Choose Supplier
            </button>
        );

        let supplierDatalist = (
            <div className='get-item-from-db-container'>
                <div className='datalist-with-addbtn-container'>
                    <Datalist
                        list={supListOptions}
                        initialValue={''}
                        getInputValue={(val) => setTempSupplier(val)}
                        errorMessage={''}
                        resetCounter={datalistDisplayResetCounter}></Datalist>
                    {chooseSupBtn}
                </div>
            </div>
        );

        //! end supplier datalist process ========================================

        // pdsi datalist =================================================
        let datalistOptions = masterPDSalesInvoicesList
            .filter((item) => item.supplierUID === supplierUIDstate)
            .filter((item) => item.status === userActions.APPROVED)
            .map((item) => item.pdsiUID);

        let itemAlreadyInTheUI = pdvrObj.pdsiArr.map((si) => si.pdsiUID);

        if (
            datalistOptions.includes(pdsiUIDinState) &&
            !itemAlreadyInTheUI.includes(pdsiUIDinState)
        )
            addBtn = <AddButton onClick={addToArrFn} />;

        datalist = (
            <div className='get-item-from-db-container'>
                <div className='datalist-with-addbtn-container'>
                    <Datalist
                        label={`Add Supplier Invoice`}
                        list={datalistOptions}
                        initialValue={''}
                        getInputValue={(val) => setPdsiUIDinState(val)}
                        errorMessage={''}
                        resetCounter={datalistDisplayResetCounter}></Datalist>
                    {addBtn}
                </div>
            </div>
        );

        // end po or dr datalist ==========================================

        let vrUID = (
            <Input
                type='text'
                maxLength={lengths.uid}
                onChange={(e) => setPdvrUIDstate(e.target.value)}
                value={pdvrUIDstate}
                placeholder='Required'
                errorMessage={pdvrObj.pdvrUIDError}></Input>
        );

        if (purpose === 'EDIT') {
            vrUID = <p className='fpii-text'>{pdvrObj.pdvrUID || '---'}</p>;
        }

        // choosing supplier step.
        let chosenSupplier = masterSuppliersList.filter(
            (supplier) => supplier.supplierUID === supplierUIDstate
        );

        if (supplierUIDstate !== '' && chosenSupplier[0]) {
            supplierDatalist = null;
        } else datalist = null;

        if (pdsiArrUI === null || pdsiArrUI.length === 0) {
            itemLabel = null;
            pdsiItemsSection = null;
            amountBox = null;
        }

        noteUI = (
            <div className='span-2-columns-container'>
                <TextArea
                    label={<span className='parent-labels'>Notes</span>}
                    maxLength={lengths.noteLength}
                    value={noteInState}
                    onChange={(e) => setNoteInState(e.target.value)}
                    errorMessage={pdvrObj.noteError}
                />
            </div>
        );

        //! Max number of iitems in the list
        if (pdvrObj.pdsiArr.length >= lengths.pdvrPdsiArrLength)
            datalist = null;

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Voucher Receipt</p>
                    {vrUID}
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Status</p>
                    <p className='fpii-text'>{pdvrObj.meta.action || '---'}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Supplier</p>
                    <p className='fpii-text'>
                        {pdvrObj.supplierUID || supplierUIDstate || '---'}
                    </p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Date</p>
                    <Input
                        type='date'
                        maxLength='12'
                        onChange={(e) => setPdvrDateInState(e.target.value)}
                        value={pdvrDateInState}
                        errorMessage={pdvrObj.pdvrDateError}></Input>
                </div>

                {supplierDatalist}

                {itemLabel}
                {pdsiItemsSection}
                {amountBox}

                {datalist}
                {noteUI}
            </section>
        );
    }

    if (
        (purpose === 'ADD' && preview) ||
        purpose === 'VIEW' ||
        purpose === 'DELETE'
    ) {
        noteUI = (
            <div className='span-2-columns-container hide-on-print'>
                <TextArea
                    label={<span className='parent-labels'>Notes</span>}
                    disabled
                    value={noteInState}
                />
            </div>
        );

        if (
            (pdvrObj.meta.action === userActions.SENT_FOR_APPROVAL ||
                pdvrObj.meta.action === userActions.APPROVED) &&
            authUser.permissions.includes(permissions.PDVR_APPROVE)
        ) {
            noteUI = (
                <div className='span-2-columns-container hide-on-print'>
                    <TextArea
                        label={<span className='parent-labels'>Notes</span>}
                        maxLength={lengths.noteLength}
                        value={noteInState}
                        onChange={(e) => setNoteInState(e.target.value)}
                        errorMessage={pdvrObj.noteError}
                    />
                </div>
            );
        }

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Voucher Receipt</p>
                    {pdvrObj.pdvrUID}
                </div>

                <div className='form-profile-info-items hide-on-print'>
                    <p className='fpii-label'>Status</p>
                    <p className='fpii-text'>{pdvrObj.meta.action || '---'}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Supplier</p>
                    <p className='fpii-text'>
                        {pdvrObj.supplierUID || supplierUIDstate || '---'}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Date</p>
                    <p className='fpii-text'>
                        {pdvrObj.pdvrDate
                            ? displayDate(pdvrObj.pdvrDate)
                            : '---'}
                    </p>
                </div>

                {itemLabel}
                {pdsiItemsSection}
                {amountBox}
                {noteUI}

                <SignatureLine />
            </section>
        );
    }

    // =========================================================================
    // Button sections for users with PDSI Request permision ===================

    if (purpose === 'ADD') {
        buttonSection = (
            <CreateItemButtons
                createText='Create VR'
                previewText='Preview VR'
                cancelPreviewFn={() => setPreview(false)}
                cancelFn={backCancelBtnFn}
                createFn={saveSIFn}
                previewFn={previewVrBtnFn}
                isPreview={preview}
            />
        );
    }

    if (purpose === 'EDIT') {
        buttonSection = (
            <UpdateItemButtons
                saveFn={saveSIFn}
                cancelFn={cancelBtnFromEditViewFn}
            />
        );
    }

    if (purpose === 'DELETE') {
        buttonSection = (
            <DeleteItemButtons
                cancelFn={backCancelBtnFn}
                deleteFn={saveSIFn}
                confirmeDeleteFn={(e) => setConfirmDeleteText(e.target.value)}
                confirmDeleteTextValue={confirmDeleteText}
                confirmDeleteTextPlaceHolder='Enter VR Number'
                uidValue={pdvrObj.pdvrUID}
            />
        );
    }

    // ==================================================================
    // Button section for users with PDVR Request permission.
    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.PDVR_REQUEST) &&
        !authUser.permissions.includes(permissions.PDVR_APPROVE)
    ) {
        if (
            pdvrObj.meta.action === userActions.CREATED ||
            pdvrObj.meta.action === userActions.UPDATED ||
            pdvrObj.meta.action === userActions.REJECTED
        ) {
            buttonSection = (
                <BackSendEditDelButtons
                    backBtnFn={backCancelBtnFn}
                    sendForApprovalBtnFn={() =>
                        statusChangeLocalFn(userActions.SENT_FOR_APPROVAL)
                    }
                    editBtnFn={gotoEditViewFn}
                    deleteBtnFn={gotoDeleteViewFn}
                />
            );
        }
    }

    // =========================================================================
    // Button section for users with PDVR Approve permision ===================

    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.PDVR_APPROVE) &&
        !authUser.permissions.includes(permissions.PDVR_REQUEST)
    ) {
        // if document status is SENT_FOR_APPROVAL
        if (pdvrObj.meta.action === userActions.SENT_FOR_APPROVAL) {
            buttonSection = (
                <ApproveRejectButtons
                    backBtnFn={backCancelBtnFn}
                    approveBtnFn={() =>
                        statusChangeLocalFn(userActions.APPROVED)
                    }
                    rejectBtnFn={() =>
                        statusChangeLocalFn(userActions.REJECTED)
                    }
                />
            );
        }

        if (pdvrObj.meta.action === userActions.APPROVED) {
            buttonSection = (
                <BackWithMarkAsButtons
                    backBtnFn={backCancelBtnFn}
                    markAsBtnFn={() =>
                        statusChangeLocalFn(userActions.PAYMENT_SENT)
                    }
                    markAsBtnText='Mark as Paid'
                />
            );
        }
    }

    // =========================================================================
    //! button section for users with both PDVR Request and Approve permissions.
    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.PDVR_APPROVE) &&
        authUser.permissions.includes(permissions.PDVR_REQUEST)
    ) {
        // if document status is SENT_FOR_APPROVAL
        if (pdvrObj.meta.action === userActions.SENT_FOR_APPROVAL) {
            buttonSection = (
                <ApproveRejectButtons
                    backBtnFn={backCancelBtnFn}
                    approveBtnFn={() =>
                        statusChangeLocalFn(userActions.APPROVED)
                    }
                    rejectBtnFn={() =>
                        statusChangeLocalFn(userActions.REJECTED)
                    }
                />
            );
        }

        if (pdvrObj.meta.action === userActions.APPROVED) {
            buttonSection = (
                <BackWithMarkAsButtons
                    backBtnFn={backCancelBtnFn}
                    markAsBtnFn={() =>
                        statusChangeLocalFn(userActions.PAYMENT_SENT)
                    }
                    markAsBtnText='Mark as Paid'
                />
            );
        }

        if (
            pdvrObj.meta.action === userActions.CREATED ||
            pdvrObj.meta.action === userActions.UPDATED ||
            pdvrObj.meta.action === userActions.REJECTED
        ) {
            buttonSection = (
                <BackSendEditDelButtons
                    backBtnFn={backCancelBtnFn}
                    sendForApprovalBtnFn={() =>
                        statusChangeLocalFn(userActions.SENT_FOR_APPROVAL)
                    }
                    editBtnFn={gotoEditViewFn}
                    deleteBtnFn={gotoDeleteViewFn}
                />
            );
        }
    }

    if (!authUser.subscriptionStatusIsActive) {
        buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    }

    let generalErrorUI = (
        <div className='general-error-message'>{generalError}</div>
    );

    return (
        <div className='common-form'>
            <section className='form-title-section'>
                Voucher Receipt Form
            </section>

            {mainContent}

            {buttonSection}
            {generalErrorUI}

            <Watermark purpose={purpose} currentDocument={currentDocument} />
        </div>
    );
};
