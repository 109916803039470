import React from 'react';
import { displayDate } from '../../../lib/util';
import './InventoryTransferItem.css';
import '../CollectionItems.css';

export default (props) => {
    let { obj, serialNumber, onClick, heading } = props;

    let itemUID = 'Inventory Transfer Number';
    let subjectUID = 'Name';
    let serial = 'S/No.';
    let date = 'Date';
    let status = 'Status';
    let headingClass = 'text-bold list-table-heading';

    let serialLabel = serial + ':';
    let itemUIDLabel = itemUID + ':';
    let recepientUIDLabel = subjectUID + ':';
    let statuslabel = 'Status:';
    let dateLabel = date + ':';

    if (!heading) {
        serial = serialNumber;
        itemUID = obj.invTransferUID;
        date = displayDate(obj.invTransferDate);
        status = obj.status;
        subjectUID = obj.subjectUID;
        headingClass = '';
    }

    return (
        <div
            className={`inventory-transfer collection-item ${headingClass}`}
            onClick={onClick}>
            <div className='collection-item-entry serial'>
                {' '}
                <span className='entry-label'>{serialLabel}</span>
                {serial}
            </div>
            <div className='collection-item-entry item-uid'>
                {' '}
                <span className='entry-label'>{itemUIDLabel}</span>
                {itemUID}
            </div>
            <div className='collection-item-entry recepient-uid'>
                {' '}
                <span className='entry-label'>{recepientUIDLabel}</span>
                {subjectUID}
            </div>
            <div className='collection-item-entry date'>
                {' '}
                <span className='entry-label'>{dateLabel}</span>
                {date}
            </div>
            <div className='collection-item-entry status'>
                {' '}
                <span className='entry-label'>{statuslabel}</span>
                {status}
            </div>
        </div>
    );
};
