import React from 'react';
import {
    displayMoney,
    saveWithTwoDecimalOnly,
    totalsGrossCalc,
    taxAmountCalc,
} from '../../lib/util';
import { Input } from '../../components';

export default (props) => {
    let {
        pdsiObj,
        type,
        taxRate,
        setTaxRate,
        purpose,
        preview,
        expandedWithholdingTaxRate,
        setExpandedWithholdingTaxRate,
        setDiscount,
        discount,
    } = props;

    let itemTotal = 0;
    let taxAmount = 0;
    let subtotalAmount = 0;
    let expandedWithholdingAmount = 0;
    let totalsNet = 0;

    if (type === 'PO') {
        pdsiObj.pdpoArr.forEach((po) => {
            po.rmArr.forEach((rm) => {
                subtotalAmount += saveWithTwoDecimalOnly(
                    rm.rmCost * rm.rmOrderedQty
                );
            });
        });
    }
    if (type === 'DR') {
        pdsiObj.pddrArr.forEach((dr) => {
            dr.pdpoArr.forEach((po) => {
                po.rmArr.forEach((rm) => {
                    subtotalAmount += saveWithTwoDecimalOnly(
                        rm.rmCost * rm.drDeliveredQtyForRm
                    );
                });
            });
        });
    }

    // itemTotal = saveWithTwoDecimalOnly(
    //     (subtotalAmount * (100 - Number(taxRate))) / 100
    // );

    // console.log(pdsiObj);

    itemTotal = totalsGrossCalc(subtotalAmount, taxRate);

    expandedWithholdingAmount = saveWithTwoDecimalOnly(
        (itemTotal * Number(expandedWithholdingTaxRate)) / 100
    );

    totalsNet = displayMoney(
        saveWithTwoDecimalOnly(
            subtotalAmount - expandedWithholdingAmount - discount
        )
    );

    taxAmount = displayMoney(taxAmountCalc(subtotalAmount, taxRate));

    expandedWithholdingAmount = displayMoney(expandedWithholdingAmount);
    itemTotal = displayMoney(itemTotal);
    subtotalAmount = displayMoney(subtotalAmount);

    let taxInputDisplay = (
        <span className='width50'>
            <Input
                type='number'
                value={taxRate}
                onChange={(e) => setTaxRate(e.target.value)}
                errorMessage={pdsiObj.taxRateError}></Input>
        </span>
    );

    let expandedWithholdingTaxRateInputDisplay = (
        <span className='width50'>
            <Input
                type='number'
                value={expandedWithholdingTaxRate}
                onChange={(e) => setExpandedWithholdingTaxRate(e.target.value)}
                errorMessage={pdsiObj.expandedWithholdingTaxRateError}></Input>
        </span>
    );
    let discountAmountInputDisplay = (
        <div className='width50 flex-row'>
            <span className='m-right-5 pad-top-10'>₱</span>
            <Input
                type='number'
                value={discount}
                onChange={(e) => setDiscount(e.target.value)}
                errorMessage={pdsiObj.discountError}></Input>
        </div>
    );

    if (
        (purpose === 'ADD' && preview) ||
        purpose === 'VIEW' ||
        purpose === 'DELETE'
    ) {
        taxInputDisplay = <span>{taxRate}</span>;
        expandedWithholdingTaxRateInputDisplay = (
            <span>{expandedWithholdingTaxRate}</span>
        );
        discountAmountInputDisplay = <span>{displayMoney(discount)}</span>;
    }

    let amountBox = (
        <>
            <div className='desktop-view'></div>
            <div className='amount-box'>
                <div>Totals (Gross):</div>
                <div>{itemTotal}</div>
                <div className='rate-input'>
                    <span className='m-right-15 width66'>Tax:</span>
                    <span className='flex-row'>
                        {' '}
                        {taxInputDisplay} <span className='m-left-10'>%</span>
                    </span>
                </div>
                <div>{taxAmount}</div>
                <div>Subtotal:</div> <div>{subtotalAmount}</div>
                <div className='rate-input'>
                    <span className='m-right-15 width66'>
                        Expanded Withholding Tax:
                    </span>
                    <span className='flex-row'>
                        {' '}
                        {expandedWithholdingTaxRateInputDisplay}{' '}
                        <span className='m-left-10'>%</span>
                    </span>
                </div>
                <div>{expandedWithholdingAmount}</div>
                <div>Discount:</div>
                <div>{discountAmountInputDisplay}</div>
                <div>Totals (Nett):</div>{' '}
                <div className='text-bold'>{totalsNet}</div>
            </div>
        </>
    );

    return amountBox;
};
