import React, { useState } from 'react';
import { db } from '../../firebase_config/firebaseConfig';

import '../PagesCommon.css';

import SetupCCForm from './SetupCCForm';

//! stripe stuff =================

import { publicKey } from '../../firebase_config/firebaseConfig';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(publicKey);

//! ===============================

export default (props) => {
    let { setIsLoading, setAlertErrorMsg, setAlertMessage } = props;

    let [stripeCustomerDetails, setStripeCustomerDetails] = useState({});
    let [paymentMethodsArr, setPaymentMethodsArr] = useState([]);
    let [paymentMethodsArrBasis, setPaymentMethodsArrBasis] = useState([]);
    let [useAsDefaultCreditCard, setUseAsDefaultCreditCard] = useState(false);
    let [defaultCreditCardInList, setDefaultCreditCardInList] = useState(null);
    let [noError, setNoError] = useState(false);

    let [rerender, setRerender] = useState(false);

    const getSubscriptionDetails = async (initial) => {
        if (!initial) {
            setStripeCustomerDetails({});
            setPaymentMethodsArr([]);
            setPaymentMethodsArrBasis([]);
            setUseAsDefaultCreditCard(false);
            setDefaultCreditCardInList(null);
            setNoError(false);
            setRerender(true);
        }

        let blSubRef = db
            .collection('Subscription')
            .doc('BusinessLogicSubscription');

        let blSubDoc;

        try {
            blSubDoc = await blSubRef.get();
        } catch (e) {
            setAlertErrorMsg('Error retrieving subsription details');
            setIsLoading(false);

            return;
        }

        let docData = blSubDoc.data();

        //! this should never run
        //! these two is setup during app setup in backend through admin scripts.
        if (
            docData.stripeCustomerDetails.id === '' ||
            docData.stripeSubscriptionPriceID === ''
        ) {
            console.error('Error with customerID and/or priceID');
            setAlertErrorMsg('Error retrieving subsription details');
            setIsLoading(false);

            return;
        }

        setStripeCustomerDetails(docData.stripeCustomerDetails);
        setPaymentMethodsArr(docData.paymentMethodsArr);
        setPaymentMethodsArrBasis(docData.paymentMethodsArr);
        setDefaultCreditCardInList(
            docData.stripeCustomerDetails.invoice_settings
                .default_payment_method
        );
        setNoError(true);
        setIsLoading(false);
        if (!initial) setRerender(false);
    };

    let form = (
        <SetupCCForm
            setIsLoading={setIsLoading}
            setAlertErrorMsg={setAlertErrorMsg}
            setAlertMessage={setAlertMessage}
            getSubscriptionDetails={getSubscriptionDetails}
            stripeCustomerDetails={stripeCustomerDetails}
            setStripeCustomerDetails={setStripeCustomerDetails}
            paymentMethodsArr={paymentMethodsArr}
            setPaymentMethodsArr={setPaymentMethodsArr}
            paymentMethodsArrBasis={paymentMethodsArrBasis}
            setPaymentMethodsArrBasis={setPaymentMethodsArrBasis}
            useAsDefaultCreditCard={useAsDefaultCreditCard}
            setUseAsDefaultCreditCard={setUseAsDefaultCreditCard}
            defaultCreditCardInList={defaultCreditCardInList}
            setDefaultCreditCardInList={setDefaultCreditCardInList}
            noError={noError}
            setNoError={setNoError}
        />
    );

    if (rerender) form = null;

    return (
        <div className='content-layout'>
            <section className='main-section'>
                {' '}
                <Elements stripe={stripePromise}>{form}</Elements>
            </section>
        </div>
    );
};
