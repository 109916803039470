import React from 'react';
import './Select.css';

export default (props) => {
    let {
        disabled,
        value,
        id,
        onChange,
        errorMessage,
        placeholder,
        optionsArr,
        label,
        placeholderText,
    } = props;

    let options = optionsArr
        ? optionsArr.map((option) => {
              return (
                  <option key={option.value} value={option.value}>
                      {option.valueUI || option.value}
                  </option>
              );
          })
        : null;

    options.unshift(
        <option key='??select-default' value='??select-default' disabled>
            {placeholderText ? placeholderText : 'Select...'}
        </option>
    );

    return (
        <div className='select-wrapper'>
            <label htmlFor={id} className='select-label'>
                {label}
            </label>
            <select
                className='select-input'
                disabled={disabled}
                value={value || '??select-default'}
                id={id}
                onChange={onChange}
                placeholder={placeholder}
            >
                {options}
            </select>
            <span className='select-error'>{errorMessage}</span>
        </div>
    );
};
