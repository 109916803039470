import React from 'react';
import { sortReportItems } from '../../lib/util';
import ReportsForm from './ReportsForm';
import ReportItemsEach from './ReportItemsEach';

import tooltips from '../../lib/tooltips';

export default ({ backCancelBtnFn, fgReportObj }) => {
    let fgUI = [];
    let fgReport = [];
    let fgMap = fgReportObj.fgMap;

    for (let key in fgMap) {
        let itemObj = {};
        itemObj.itemUID = key;
        itemObj.total = fgMap[key];

        fgReport.push(itemObj);
    }

    sortReportItems(fgReport);

    fgReport.forEach((item, index) => {
        fgUI.push(
            <ReportItemsEach
                key={index}
                index={index}
                item={item}
                mobileLabel='FG Amount'
            />
        );
    });
    return (
        <ReportsForm
            helpText={tooltips.reportFG}
            uid={`Finished Goods - ${fgReportObj.reportMonth} ${fgReportObj.reportYear}`}
            nameLabel='Finished Goods'
            amountLabel='FG Inventory Amount'
            total={fgReportObj.total}
            list={fgUI}
            backCancelBtnFn={backCancelBtnFn}
        />
    );
};
