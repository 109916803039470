import React, { useState, useContext } from 'react';
import { dbopsPDDRs } from '../../firebase_dbops';
import { MasterListContext, AuthContext } from '../../contexts';
import { InputIcon, ListView2 } from '../../components';
import PDSDItem from '../../components/CollectionItems/PDSDItem/PDSDItem';
import { displayDate } from '../../lib/util';
import { permissions, lengths } from '../../lib/constants';
import { userActions } from '../../lib/constants';

import '../PagesCommon.css';
import './PDDRsPage.css';

import PDDRsForm from './PDDRsForm';

const view = {
    LIST_VIEW: 'listView',
    CREATE_DELIVERY_RECEIPT: 'creatDR',
    EDIT_DELIVERY_RECEIPT: 'editDR',
    DELETE_DELIVERY_RECEIPT: 'deleteDR',
    DELIVERY_RECEIPT_PROFILE: 'DrProfile',
};

export default (props) => {
    let { setIsLoading, setAlertErrorMsg, setAlertMessage } = props;

    let { masterDeliveryReceiptsList } = useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);

    let [formView, setFormView] = useState(view.LIST_VIEW);
    let [currentDocument, setCurrentDocument] = useState('');
    let [searchText, setSearchText] = useState('');

    // functions to change views.
    const createDeliveryReceiptBtnFn = () =>
        setFormView(view.CREATE_DELIVERY_RECEIPT);
    const gotoListViewFn = () => setFormView(view.LIST_VIEW);
    const gotoEditViewFn = () => setFormView(view.EDIT_DELIVERY_RECEIPT);
    const gotoDeleteViewFn = () => setFormView(view.DELETE_DELIVERY_RECEIPT);
    const gotoPoProfileViewFn = () =>
        setFormView(view.DELIVERY_RECEIPT_PROFILE);

    // function to get pddr document.
    const getDocumentFromDBFn = async (docID) => {
        setIsLoading(true);
        let response = await dbopsPDDRs.getDrFn(docID);
        if (response.error)
            setAlertErrorMsg(response.errorObj.message || response.message);
        else {
            setCurrentDocument(response.data);
            gotoPoProfileViewFn();
        }
        setIsLoading(false);
    };

    const createDeliveryReceiptFn = async (drObj) => {
        // spinner on.
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        // write to db.
        let response = await dbopsPDDRs.createDrFn(userCreds, drObj);
        if (response.error) setAlertErrorMsg(response.errorObj.message);
        else setAlertMessage(response.message);

        gotoListViewFn();

        // spinner off.
        setIsLoading(false);
    };

    const updateDeliveryReceiptFn = async (drObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsPDDRs.updateDrFn(
            userCreds,
            drObj,
            documentBasis
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);
            // if error is because document has already been deleted,
            // go back to list view.
            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
            // if error is because document has since changed, just continue
            // flow of the program.
        } else setAlertMessage(response.message);

        // this get document ensures state in FE state (currentDocument) is updated.
        gotoListViewFn();
        await getDocumentFromDBFn(drObj.pddrUID);

        setIsLoading(false);
    };

    const deleteDeliveryReceiptFn = async (drObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsPDDRs.deleteDrFn(
            userCreds,
            drObj,
            documentBasis
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);

            // if error is because document has changed before changes
            // could be applied, get latest version of pddr and
            // go back to pddr profile view.
            if (response.errorObj.code === 1001) {
                gotoListViewFn();
                await getDocumentFromDBFn(drObj.pddrUID);
                setIsLoading(false);
                return;
            }
            // all other errors can continue flow of program.
        } else setAlertMessage(response.message);

        gotoListViewFn();

        setIsLoading(false);
    };

    const statusChangeFn = async (
        userActionPassed,
        drObj,
        documentBasis,
        documentBasisPoArr
    ) => {
        setIsLoading(true);

        let userCreds = {
            action: userActionPassed,
            uid: authUser.uid,
            email: authUser.email,
        };

        let dbFn = dbopsPDDRs.statusChangeFn;
        if (userActionPassed === userActions.APPROVED) {
            dbFn = dbopsPDDRs.approvePddrFn;
        }

        let response = await dbFn(
            userCreds,
            drObj,
            documentBasis,
            documentBasisPoArr
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);

            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
            // if error is because document has since changed, just continue
            // flow of the program.
        } else setAlertMessage(response.message);

        // this get document ensures state in FE state (currentDocument) is updated.
        gotoListViewFn();
        await getDocumentFromDBFn(drObj.pddrUID);

        setIsLoading(false);
    };

    // filter function =====================================
    let filteredDeliveryReceiptList = masterDeliveryReceiptsList.filter(
        (pddr) => {
            return (
                pddr.pddrUID.toUpperCase().includes(searchText.toUpperCase()) ||
                displayDate(pddr.pddrDate)
                    .toUpperCase()
                    .includes(searchText.toUpperCase()) ||
                pddr.status.toUpperCase().includes(searchText.toUpperCase()) ||
                pddr.supplierUID
                    .toUpperCase()
                    .includes(searchText.toUpperCase())
            );
        }
    );

    let addPddrBtnFn = (
        <button
            className='btn btn-primary'
            onClick={createDeliveryReceiptBtnFn}>
            Create Goods Receipt
        </button>
    );

    if (
        !authUser.permissions.includes(permissions.PDDR_REQUEST) ||
        masterDeliveryReceiptsList.length >= lengths.masterPOSDeptsLimits ||
        !authUser.subscriptionStatusIsActive
    ) {
        addPddrBtnFn = null;
    }

    let topSectionContent = (
        <section className='top-section'>
            <InputIcon
                //! id='suppliers-search-input'
                type='text'
                placeholder='Search'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                icon={
                    <i
                        className='ms-Icon ms-Icon--Search'
                        aria-hidden='true'></i>
                }
            />
            {addPddrBtnFn}
        </section>
    );

    let mainSectionContent = (
        <ListView2
            getDocumentFromDBFn={getDocumentFromDBFn}
            list={filteredDeliveryReceiptList}
            keyProperty={'pddrUID'}
            CollectionItemComponent={PDSDItem}
        />
    );

    if (formView !== view.LIST_VIEW) topSectionContent = null;

    if (formView === view.CREATE_DELIVERY_RECEIPT) {
        mainSectionContent = (
            <PDDRsForm
                purpose='ADD'
                backCancelBtnFn={gotoListViewFn}
                createDrFn={createDeliveryReceiptFn}
                setIsLoading={setIsLoading}
                setAlertErrorMsg={setAlertErrorMsg}
            />
        );
    }

    if (formView === view.EDIT_DELIVERY_RECEIPT) {
        mainSectionContent = (
            <PDDRsForm
                purpose='EDIT'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoPoProfileViewFn}
                updateDrFn={updateDeliveryReceiptFn}
                setAlertErrorMsg={setAlertErrorMsg}
                setIsLoading={setIsLoading}
            />
        );
    }

    if (formView === view.DELETE_DELIVERY_RECEIPT) {
        mainSectionContent = (
            <PDDRsForm
                purpose='DELETE'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoPoProfileViewFn}
                deleteDrFn={deleteDeliveryReceiptFn}
                setAlertErrorMsg={setAlertErrorMsg}
                setIsLoading={setIsLoading}
            />
        );
    }

    if (formView === view.DELIVERY_RECEIPT_PROFILE) {
        mainSectionContent = (
            <PDDRsForm
                purpose='VIEW'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoListViewFn}
                gotoEditViewFn={gotoEditViewFn}
                gotoDeleteViewFn={gotoDeleteViewFn}
                setIsLoading={setIsLoading}
                statusChangeFn={statusChangeFn}
                setAlertErrorMsg={setAlertErrorMsg}
            />
        );
    }

    return (
        <div className='content-layout'>
            {topSectionContent}

            <section className='main-section'>{mainSectionContent}</section>
        </div>
    );
};
