// import px from './px';
import { supplier } from './suppliers';
import { customer } from './customers';
import { rawMaterial } from './rawMaterials';
import { semiFG } from './semiFGs';
import { finishedGood } from './finishedGoods';
import { pdpo } from './PDPOs';
import { noChange } from './noChange';
import { pddr, pddrNoChange } from './PDDRs';
import { pdsi } from './PDSIs';
import { prodOrders } from './prodOrders';
import { productionDelivered } from './productionDelivered';
import { assembly } from './assembly';
import { assemblyDelivered } from './assemblyDelivered';
import { sdpo } from './SDPOs';
import { sddr, sddrNoChange } from './SDDRs';
import { sdsi } from './SDSIs';
import { authUser, authUserPermissionsNoChange } from './authUser';
import { userProfile } from './userProfile';
import { appSettings } from './appSetting';
import { pdvr } from './PDVRs';
import { sdcr } from './SDCRs';
import { invTransfer } from './invTransfer';

export default {
    supplier,
    customer,
    rawMaterial,
    semiFG,
    finishedGood,
    pdpo,
    noChange,
    pddr,
    pddrNoChange,
    pdsi,
    prodOrders,
    assembly,
    sdpo,
    sddr,
    sddrNoChange,
    sdsi,
    authUser,
    authUserPermissionsNoChange,
    userProfile,
    appSettings,
    productionDelivered,
    assemblyDelivered,
    pdvr,
    sdcr,
    invTransfer,
};
