import { db } from '../firebase_config/firebaseConfig';
import { responseFn } from '../lib/util';

export default async (collection, docID, DocTypeString = 'Document') => {
    let docRef = db.collection(collection).doc(docID);
    try {
        let document = await docRef.get();
        if (document.exists)
            return responseFn(
                document.data(),
                false,
                null,
                `App: Get ${DocTypeString} Successful.`
            );
        else
            return responseFn(
                null,
                true,
                { message: `App: ${DocTypeString} does not exist.` },
                `App: ${DocTypeString} does not exist.`
            );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            `App: Error Getting ${DocTypeString}.`
        );
    }
};
