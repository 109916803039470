export default (sdObj) => {
    let prefix = null;
    if (sdObj.hasOwnProperty('sdpoUID')) prefix = 'sdpo';
    if (sdObj.hasOwnProperty('sddrUID')) prefix = 'sddr';
    if (sdObj.hasOwnProperty('sdsiUID')) prefix = 'sdsi';
    if (sdObj.hasOwnProperty('sdcrUID')) prefix = 'sdcr';

    let obj = {
        a: sdObj.customerUID,
        b: sdObj[`${prefix}Date`],
        c: sdObj.meta.action,
    };

    if (prefix === 'sdcr') {
        obj.d = sdObj.sdcrTotalsNett ? sdObj.sdcrTotalsNett : 0;
    }

    return obj;
};
