import React, { useState, useEffect } from 'react';

import { functions } from '../../firebase_config/firebaseConfig';
import { Checkbox } from '../../components';

import '../PagesCommon.css';
import './SetupCCPage.css';

//! stripe stuff ============================
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
//! =========================================

const CARD_ELEMENT_OPTIONS = {
    hidePostalCode: true,
    style: {
        base: {
            color: '#141414',
            fontFamily: '"RobotoHosted", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '14px',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
        },
    },
};

const views = {
    addCard: 'addCard',
    mainView: 'mainView',
    update: 'update',
};

export default (props) => {
    //! stripe stuff ============================
    const stripe = useStripe();
    const elements = useElements();
    //! =========================================

    let {
        setIsLoading,
        setAlertErrorMsg,
        setAlertMessage,

        stripeCustomerDetails,
        paymentMethodsArr,
        setPaymentMethodsArr,
        paymentMethodsArrBasis,
        useAsDefaultCreditCard,
        defaultCreditCardInList,
        setDefaultCreditCardInList,
        noError,
        setUseAsDefaultCreditCard,

        getSubscriptionDetails,
    } = props;

    let [view, setView] = useState(views.mainView);

    //! 1. Get paymentMethodsArr
    //! 2. Get customerID
    //! 3. Get priceID
    useEffect(() => {
        getSubscriptionDetails(true);
    }, []);

    const backBtnFn = () => {
        setPaymentMethodsArr(paymentMethodsArrBasis);
        setDefaultCreditCardInList(
            stripeCustomerDetails.invoice_settings.default_payment_method
        );
        setView(views.mainView);
    };

    const toggleUseAsDefaultCCFn = () =>
        setUseAsDefaultCreditCard(!useAsDefaultCreditCard);

    const updatePaymentMethodsFn = async () => {
        let payload = {};
        let deletedCards = [];
        let defaultCardId = null;

        // check for deleted card.
        if (paymentMethodsArr.length !== paymentMethodsArrBasis.length) {
            // create array of deleted card(s).
            let currentCards = paymentMethodsArr.map((pm) => pm.id);

            paymentMethodsArrBasis.forEach((pm) => {
                if (!currentCards.includes(pm.id)) deletedCards.push(pm.id);
            });
        }

        //check if default was changed.
        if (
            stripeCustomerDetails.invoice_settings.default_payment_method !==
            defaultCreditCardInList
        ) {
            defaultCardId = defaultCreditCardInList;
        }

        // form payload to be sent to cld function.
        payload.deletedCards = deletedCards;
        payload.defaultPaymentMethod = defaultCardId;
        payload.customerID = stripeCustomerDetails.id;

        // if no change, go back to mainView.
        if (
            payload.deletedCards.length === 0 &&
            payload.defaultPaymentMethod === null
        ) {
            setView(views.mainView);
            return;
        }

        setIsLoading(true);

        // pass data to cloud function.
        const updatePaymentMethodsCldFn = functions.httpsCallable(
            'updatePaymentMethodsCldFn'
        );

        let response = null;
        try {
            response = await updatePaymentMethodsCldFn(payload);
        } catch (e) {
            // catch https error
            console.error(e);
            setAlertErrorMsg(e.message);
            setIsLoading(false);
            backBtnFn(); //! reset everything and go back to main view.
            return;
        }

        //! firebase cloudfunctions has a different format in returning responses.
        //! it is always in a data property.
        if (response.data.error) {
            console.error(response.data.errorObj);
            setAlertErrorMsg(
                (response.data.errorObj.errorInfo &&
                    response.data.errorObj.errorInfo.message) ||
                    response.data.message
            );
            setIsLoading(false);
            setView(views.mainView);

            return;
        }

        setAlertMessage(response.data.message);
        setIsLoading(false);
        setView(views.mainView);
        getSubscriptionDetails();
    };

    const addCreditCardFn = async () => {
        setIsLoading(true);

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            setIsLoading(false);
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            console.error(error);
            setAlertErrorMsg(error.message || 'Error creating payment method');
            setIsLoading(false);
            return;
        }

        // if no error

        const data = {
            paymentMethodObj: paymentMethod,
            paymentMethodID: paymentMethod.id,
            customerID: stripeCustomerDetails.id,
            useCreditCardAsDefault: useAsDefaultCreditCard,
        };

        // pass data to cloud function.
        const addCreditCardCldFn = functions.httpsCallable(
            'addCreditCardCldFn'
        );

        let response = null;
        try {
            response = await addCreditCardCldFn(data);
        } catch (e) {
            // catch https error
            console.error(e);
            setAlertErrorMsg(e.message);
            setIsLoading(false);
            return;
        }

        //! firebase cloudfunctions has a different format in returning responses.
        //! it is always in a data property.
        if (response.data.error) {
            console.error(response.data.errorObj);
            setAlertErrorMsg(
                (response.data.errorObj.errorInfo &&
                    response.data.errorObj.errorInfo.message) ||
                    response.data.message
            );
            setIsLoading(false);

            setView(views.mainView);

            return;
        }

        setAlertMessage(response.data.message);
        setView(views.mainView);
        setIsLoading(false);
        getSubscriptionDetails();
    };

    const removeCardFn = (index) => {
        let paymentMethodsArrCopy = [...paymentMethodsArr];
        paymentMethodsArrCopy.splice(index, 1);

        setPaymentMethodsArr(paymentMethodsArrCopy);
    };

    //! Main content section ============================================

    let addCreditCardForm = (
        <div className='add-cc-container'>
            <div className='card-input-container'>
                <CardElement
                    options={CARD_ELEMENT_OPTIONS}
                    className='credit-card-input'></CardElement>
            </div>
            <Checkbox
                label='Use as default'
                checked={useAsDefaultCreditCard}
                onChange={toggleUseAsDefaultCCFn}
            />
        </div>
    );

    let creditCardList = null;
    if (view === views.mainView || view === views.update) {
        let onChangeFn = () => console.log('do not hack');
        let isDisabled = true;
        if (view === views.update) {
            onChangeFn = (pmID) => setDefaultCreditCardInList(pmID);
            isDisabled = false;
        }

        creditCardList = paymentMethodsArr.map((pm, index) => {
            let trashIcon = (
                <i
                    className='ms-Icon ms-Icon--Delete cursor-pointer font14'
                    aria-hidden='true'
                    onClick={() => removeCardFn(index)}></i>
            );

            if (view !== views.update || defaultCreditCardInList === pm.id)
                trashIcon = null;

            return (
                <div
                    key={pm.id}
                    className='view-table-row pad-around-10 cc-row'>
                    <div className='cell'>
                        <span className='width16 flex-row'>
                            <input
                                className='m-left-10'
                                type='radio'
                                value={pm.id}
                                id={pm.id}
                                checked={defaultCreditCardInList === pm.id}
                                onChange={() => onChangeFn(pm.id)}
                                disabled={isDisabled}></input>
                        </span>
                        <span className='width50'>
                            {`${pm.card.brand.toUpperCase()} ...${
                                pm.card.last4
                            }`}{' '}
                        </span>

                        <span className='width16'>
                            {pm.card.exp_month} / {pm.card.exp_year}
                        </span>
                        <span className='width16'>{trashIcon}</span>
                    </div>
                </div>
            );
        });
    }

    let mainContent = (
        <>
            <div className='label-padding label-highlight'>
                <label className='parent-labels'>Credit Cards List</label>
            </div>

            <div className='view-table-heading pad-around-10 cc-row'>
                <div className='cell'>
                    <span className='width16'>Default</span>
                    <span className='width50'>Card Number</span>

                    <span className='width16'>Exp</span>
                    <span className='width16'></span>
                </div>
            </div>

            {creditCardList}
        </>
    );

    let formTitle = 'Setup Credit Cards';

    if (view === views.addCard) {
        mainContent = addCreditCardForm;
        formTitle = 'Add Credit Card';
    }

    //! Buttons section ==================================================

    let addCCBtn = (
        <button className='btn' onClick={() => setView(views.addCard)}>
            Add Credit Card
        </button>
    );
    let updatePaymentMethodsBtn = (
        <button
            className='btn btn-primary m-left-10'
            onClick={() => setView(views.update)}>
            Edit
        </button>
    );

    let addCardSaveBtn = (
        <button className='btn btn-primary m-left-10' onClick={addCreditCardFn}>
            Save
        </button>
    );

    let backBtn = (
        <button className='goto-left btn' onClick={backBtnFn}>
            Back
        </button>
    );

    let updateDefaultCardSaveBtn = (
        <button
            className='btn btn-primary m-left-10'
            onClick={updatePaymentMethodsFn}>
            Save
        </button>
    );

    let middleBtn = addCCBtn;
    let rightBtn = updatePaymentMethodsBtn;

    if (view === views.addCard) {
        middleBtn = null;
        rightBtn = addCardSaveBtn;
    }

    if (view === views.update) {
        middleBtn = null;
        rightBtn = updateDefaultCardSaveBtn;
    }

    if (view === views.mainView) {
        backBtn = null;
        if (paymentMethodsArr.length >= 3) {
            middleBtn = null;
        }
    }

    let buttonSection = (
        <section className='form-button-section'>
            <div className='width50'>{backBtn}</div>
            <div className='width50 row mobile-m-top-2'>
                {middleBtn}

                {rightBtn}
            </div>
        </section>
    );

    if (!noError) buttonSection = null;

    return (
        <div className='common-form'>
            <section className='form-title-section'>{formTitle}</section>

            {mainContent}

            {buttonSection}

            <div className='watermark'></div>
        </div>
    );
};
