import React, { useState, useContext } from 'react';
import { dbopsFinishedGoods } from '../../firebase_dbops';
import { MasterListContext, AuthContext } from '../../contexts';
import { InputIcon, ListView2 } from '../../components';
import SetupSfgFgItem from '../../components/CollectionItems/SetupSfgFgItem/SetupSfgFgItem';

import { permissions, lengths } from '../../lib/constants';

import '../PagesCommon.css';
import './FGs.css';

import FGsForm from './FGsForm';

const view = {
    LIST_VIEW: 'listView',
    ADD_FG: 'addFG',
    EDIT_FG: 'editFG',
    DELETE_FG: 'deleteFG',
    FG_PROFILE: 'fgProfile',
};

export default (props) => {
    let { setIsLoading, setAlertErrorMsg, setAlertMessage } = props;

    let { masterFinishedGoodsList } = useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);

    let [formView, setFormView] = useState(view.LIST_VIEW);
    let [currentDocument, setCurrentDocument] = useState('');
    let [searchText, setSearchText] = useState('');

    // functions to change views.
    const addSemiFGBtnFn = () => setFormView(view.ADD_FG);
    const gotoListViewFn = () => setFormView(view.LIST_VIEW);
    const gotoEditViewFn = () => setFormView(view.EDIT_FG);
    const gotoDeleteViewFn = () => setFormView(view.DELETE_FG);
    const gotoSFGProfileViewFn = () => setFormView(view.FG_PROFILE);

    // function to get Finished Good document.
    const getDocumentFromDBFn = async (docID) => {
        setIsLoading(true);
        let response = await dbopsFinishedGoods.getFGFn(docID);
        if (response.error)
            setAlertErrorMsg(response.errorObj.message || response.message);
        else {
            setCurrentDocument(response.data);
            gotoSFGProfileViewFn();
        }
        setIsLoading(false);
    };

    // function to create Supplier
    const createFGFn = async (fgObj) => {
        // spinner on.
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        // write to db.
        let response = await dbopsFinishedGoods.createFGFn(userCreds, fgObj);
        if (response.error) setAlertErrorMsg(response.errorObj.message);
        else setAlertMessage(response.message);

        gotoListViewFn();

        // spinner off.
        setIsLoading(false);
    };

    // function to update Finished Good.
    const updateFGFn = async (fgObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsFinishedGoods.updateFGFn(
            userCreds,
            fgObj,
            documentBasis
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);
            // if error, it's because document has already been deleted,
            // go back to list view.
            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
            // if error is because document has since changed, just continue
            // flow of the program.
        } else setAlertMessage(response.message);

        // this get document ensures state in FE state (currentDocument) is updated.
        gotoListViewFn();
        await getDocumentFromDBFn(fgObj.fgUID);

        setIsLoading(false);
    };

    // function to delete Finished Good.
    const deleteFGFn = async (fgObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsFinishedGoods.deleteFGFn(
            userCreds,
            fgObj,
            documentBasis
        );

        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);

            // if error is because document has changed before changes
            // could be applied, get latest version of Finished Good and
            // go back to Finished Good profile view.
            if (response.errorObj.code === 1001) {
                gotoListViewFn();
                await getDocumentFromDBFn(fgObj.fgUID);
                setIsLoading(false); // redundant
                return;
            }
            // all other errors can continue flow of program.
        } else setAlertMessage(response.message);

        gotoListViewFn();

        setIsLoading(false);
    };

    // filter function =====================================
    let filteredFGList = masterFinishedGoodsList.filter((fg) => {
        return (
            fg.fgUID.toUpperCase().includes(searchText.toUpperCase()) ||
            fg.fgCode.toUpperCase().includes(searchText.toUpperCase()) ||
            fg.fgPrice
                .toString()
                .toUpperCase()
                .includes(searchText.toUpperCase())
        );
    });

    let addFGBtnUI = (
        <button className='btn btn-primary' onClick={addSemiFGBtnFn}>
            Create Finished Good
        </button>
    );
    if (
        !authUser.permissions.includes(permissions.FG_SETUP) ||
        masterFinishedGoodsList.length >= lengths.masterListLimit ||
        !authUser.subscriptionStatusIsActive
    ) {
        addFGBtnUI = null;
    }

    let topSectionContent = (
        <section className='top-section'>
            <InputIcon
                type='text'
                placeholder='Search'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                icon={
                    <i
                        className='ms-Icon ms-Icon--Search'
                        aria-hidden='true'
                    ></i>
                }
            />
            {addFGBtnUI}
        </section>
    );

    let mainSectionContent = (
        <ListView2
            getDocumentFromDBFn={getDocumentFromDBFn}
            list={filteredFGList}
            keyProperty={'fgUID'}
            CollectionItemComponent={SetupSfgFgItem}
        />
    );

    if (formView !== view.LIST_VIEW) topSectionContent = null;

    if (formView === view.ADD_FG) {
        mainSectionContent = (
            <FGsForm
                purpose='ADD'
                backCancelBtnFn={gotoListViewFn}
                createFGFn={createFGFn}
            />
        );
    }

    if (formView === view.EDIT_FG) {
        mainSectionContent = (
            <FGsForm
                purpose='EDIT'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoSFGProfileViewFn}
                updateFGFn={updateFGFn}
            />
        );
    }

    if (formView === view.DELETE_FG) {
        mainSectionContent = (
            <FGsForm
                purpose='DELETE'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoSFGProfileViewFn}
                deleteFGFn={deleteFGFn}
            />
        );
    }

    if (formView === view.FG_PROFILE) {
        mainSectionContent = (
            <FGsForm
                purpose='VIEW'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoListViewFn}
                gotoEditViewFn={gotoEditViewFn}
                gotoDeleteViewFn={gotoDeleteViewFn}
            />
        );
    }

    return (
        <div className='content-layout'>
            {topSectionContent}

            <section className='main-section'>{mainSectionContent}</section>
        </div>
    );
};
