import React from 'react';

export default (props) => {
    let { backBtnFn } = props;

    let buttonSection = (
        <section className='form-button-section'>
            <button
                className='left-button-control btn btn-primary'
                onClick={backBtnFn}
            >
                Back
            </button>
        </section>
    );

    return buttonSection;
};
