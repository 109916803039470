import { db, firebase } from '../firebase_config/firebaseConfig';
import { userActions, systemActions } from '../lib/constants';
import {
    responseFn,
    formatCustomErrorObj,
    saveWithFiveDecimals,
} from '../lib/util';
import validate from '../validators';
import mListHelper from './mListHelper';
import dbopsGetDocument from './dbopsGetDocument';
import { docNames, appMessage } from '../lib/messages';
import {
    operationObjToMListItemFn,
    sfgInvObjToMListItemFn,
    rmInvObjToMListItemFn,
} from '../lib/masterListConverters';

import {
    PRODUCTION_DELIVERED_COUNTER_DOC,
    SG_NUMBERS_COL,
    //
    PRODUCTION_DELIVERED_COL,
    DELETED_PRODUCTION_DELIVERED_COL,
    //
    PROD_ORDER_COL,
    SEMI_FGS_COL,
    RAW_MATERIALS_COL,
    //
    MASTER_LISTS_COL,
    MASTER_PRODUCTION_DELIVERED_LIST_DOC,
    MASTER_PROD_ORDER_LIST_DOC,
    MASTER_INVENTORY_RM_LIST_DOC,
    MASTER_INVENTORY_SFG_LIST_DOC,
} from '../lib/constants';

// ================================================
// function to fetch each SFG Produced document.
const getSfgProducedFn = async (docID) =>
    dbopsGetDocument(PRODUCTION_DELIVERED_COL, docID, 'Production Delivered');

// ======================================================

const createProductionDeliveredFn = async (
    userCreds,
    productionDeliveredObj
) => {
    // add metadata to productionDeliveredObj to be created in firestore.
    productionDeliveredObj.meta = {
        action: userActions.CREATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // add metaHistory
    productionDeliveredObj.metaHistory = [];

    const transactionFn = async (transaction) => {
        let productionDeliveredCounterRef = db
            .collection(SG_NUMBERS_COL)
            .doc(PRODUCTION_DELIVERED_COUNTER_DOC);

        let productionDeliveredCounterDoc = await transaction.get(
            productionDeliveredCounterRef
        );
        let currentProductionDeliveredNum = productionDeliveredCounterDoc
            .data()
            .ProductionDeliveredNumber.toString();

        // add server generated number to productionDeliveredUID.
        productionDeliveredObj.productionDeliveredUID = currentProductionDeliveredNum;
        productionDeliveredObj.productionDeliveredNumber = currentProductionDeliveredNum;

        let newProductionDeliveredRef = db
            .collection(PRODUCTION_DELIVERED_COL)
            .doc(productionDeliveredObj.productionDeliveredUID);

        let productionDeliveredListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_PRODUCTION_DELIVERED_LIST_DOC);

        let productionDeliveredDoc = await transaction.get(
            newProductionDeliveredRef
        );
        let productionDeliveredListDoc = await transaction.get(
            productionDeliveredListRef
        );

        // check exist error for new SFG Produced document
        if (productionDeliveredDoc.exists) {
            return Promise.reject(
                formatCustomErrorObj(
                    1002,
                    appMessage.alreadyExist(docNames.PRODUCTION_DELIVERED)
                )
            );
        }

        // START MANIPULATING DATA TO UPDATE DATABASE ================================

        // increment SGNumbers Collection > ProductionDeliveredCounter > productionDeliveredNumber
        await transaction.set(
            productionDeliveredCounterRef,
            {
                ProductionDeliveredNumber:
                    productionDeliveredCounterDoc.data()
                        .ProductionDeliveredNumber + 1,
            },
            { merge: true }
        );

        // create or update productionDelivered list document in (master lists collection)
        if (productionDeliveredListDoc.exists) {
            let productionDeliveredInfoObj = operationObjToMListItemFn(
                productionDeliveredObj,
                'productionDeliveredUID'
            );

            let newData = mListHelper.updateMasterList(
                productionDeliveredListDoc,
                productionDeliveredObj.productionDeliveredUID,
                productionDeliveredInfoObj
            );

            await transaction.set(productionDeliveredListRef, newData);
        } else {
            let productionDeliveredInfoObj = operationObjToMListItemFn(
                productionDeliveredObj,
                'productionDeliveredUID'
            );

            let newData = mListHelper.addMasterList(
                productionDeliveredObj.productionDeliveredUID,
                productionDeliveredInfoObj
            );

            await transaction.set(productionDeliveredListRef, newData);
        }

        // set new productionDelivered document.
        await transaction.set(
            newProductionDeliveredRef,
            productionDeliveredObj
        );

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyCreated(docNames.PRODUCTION_DELIVERED)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorCreating(docNames.PRODUCTION_DELIVERED)
        );
    }
};

// ===============================================
const updateProductionDeliveredFn = async (
    userCreds,
    productionDeliveredObj,
    documentBasis
) => {
    if (productionDeliveredObj.metaHistory.length > 99)
        productionDeliveredObj.metaHistory.shift();

    productionDeliveredObj.metaHistory.push(productionDeliveredObj.meta);

    // update metadata in productionDeliveredObj.
    productionDeliveredObj.meta = {
        action: userActions.UPDATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // use productionDeliveredUID as docuid
    let productionDeliveredRef = db
        .collection(PRODUCTION_DELIVERED_COL)
        .doc(productionDeliveredObj.productionDeliveredUID);

    let productionDeliveredListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_PRODUCTION_DELIVERED_LIST_DOC);

    const transactionFn = async (transaction) => {
        let productionDeliveredDoc = await transaction.get(
            productionDeliveredRef
        );

        if (!productionDeliveredDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(
                        docNames.PRODUCTION_DELIVERED
                    )
                )
            );

        if (!validate.noChange(productionDeliveredDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(
                        docNames.PRODUCTION_DELIVERED
                    )
                )
            );
        }

        let productionDeliveredListDoc = await transaction.get(
            productionDeliveredListRef
        );

        let productionDeliveredInfoObj = operationObjToMListItemFn(
            productionDeliveredObj,
            'productionDeliveredUID'
        );

        let newData = mListHelper.updateMasterList(
            productionDeliveredListDoc,
            productionDeliveredObj.productionDeliveredUID,
            productionDeliveredInfoObj
        );

        await transaction.set(productionDeliveredListRef, newData);

        await transaction.set(productionDeliveredRef, productionDeliveredObj);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyUpdated(docNames.PRODUCTION_DELIVERED)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorUpdating(docNames.PRODUCTION_DELIVERED)
        );
    }
};

// ================================================================
const deleteProductionDeliveredFn = async (
    userCreds,
    productionDeliveredObj,
    documentBasis
) => {
    if (productionDeliveredObj.metaHistory.length > 99)
        productionDeliveredObj.metaHistory.shift();

    productionDeliveredObj.metaHistory.push(productionDeliveredObj.meta);

    productionDeliveredObj.meta = {
        action: userActions.DELETED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // use productionDeliveredUID. see updateProductionDeliveredFn
    let productionDeliveredRef = db
        .collection(PRODUCTION_DELIVERED_COL)
        .doc(productionDeliveredObj.productionDeliveredUID);

    let productionDeliveredListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_PRODUCTION_DELIVERED_LIST_DOC);

    let deletedProductionDeliveredRef = db
        .collection(DELETED_PRODUCTION_DELIVERED_COL)
        .doc();

    const transactionFn = async (transaction) => {
        let productionDeliveredDoc = await transaction.get(
            productionDeliveredRef
        );

        if (!productionDeliveredDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotDeleteBecauseDocNotExist(
                        docNames.PRODUCTION_DELIVERED
                    )
                )
            );

        if (!validate.noChange(productionDeliveredDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotDeleteBecauseDocChanged(
                        docNames.PRODUCTION_DELIVERED
                    )
                )
            );
        }

        let productionDeliveredListDoc = await transaction.get(
            productionDeliveredListRef
        );
        await transaction.get(deletedProductionDeliveredRef);

        let newData = mListHelper.deletePropertyFromMasterList(
            productionDeliveredListDoc,
            productionDeliveredObj.productionDeliveredUID
        );

        await transaction.delete(productionDeliveredRef);
        await transaction.set(productionDeliveredListRef, newData);
        await transaction.set(
            deletedProductionDeliveredRef,
            productionDeliveredObj
        );

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyDeleted(docNames.PRODUCTION_DELIVERED)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorDeleting(docNames.PRODUCTION_DELIVERED)
        );
    }
};

// ================================================================
const statusChangeFn = async (
    userCreds,
    productionDeliveredObj,
    documentBasis
) => {
    // move old metadate into metahistory. ensure meta data history length
    // is 100 or less. will be checked by security rules.
    if (productionDeliveredObj.metaHistory.length > 99)
        productionDeliveredObj.metaHistory.shift();

    productionDeliveredObj.metaHistory.push(productionDeliveredObj.meta);

    // update metadata in productionDeliveredObj.
    productionDeliveredObj.meta = {
        action: userCreds.action,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // get references.
    let productionDeliveredRef = db
        .collection(PRODUCTION_DELIVERED_COL)
        .doc(productionDeliveredObj.productionDeliveredUID);
    let productionDeliveredListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_PRODUCTION_DELIVERED_LIST_DOC);

    const transactionFn = async (transaction) => {
        let productionDeliveredDoc = await transaction.get(
            productionDeliveredRef
        );
        let productionDeliveredListDoc = await transaction.get(
            productionDeliveredListRef
        );

        if (!productionDeliveredDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(
                        docNames.PRODUCTION_DELIVERED
                    )
                )
            );
        // ! this does not check meta data.
        if (!validate.noChange(productionDeliveredDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(
                        docNames.PRODUCTION_DELIVERED
                    )
                )
            );
        }
        // // ! meta data check if status of document changed.
        // if (productionDeliveredDoc.data().meta.action !== documentBasis.meta.action) {
        //     return Promise.reject(
        //         formatCustomErrorObj(
        //             1001,
        //             appMessage.cannotEditBecauseDocChanged(docNames.PRODUCTION_DELIVERED)
        //         )
        //     );
        // }

        let productionDeliveredInfoObj = operationObjToMListItemFn(
            productionDeliveredObj,
            'productionDeliveredUID'
        );

        let newData = mListHelper.updateMasterList(
            productionDeliveredListDoc,
            productionDeliveredObj.productionDeliveredUID,
            productionDeliveredInfoObj
        );

        // write to masterlist and pdpo document in firebase.
        await transaction.set(productionDeliveredListRef, newData);
        await transaction.set(productionDeliveredRef, productionDeliveredObj);
        return Promise.resolve('TransactionFn Completed Successfully');
    };
    try {
        await db.runTransaction(transactionFn);
        return responseFn(null, false, null, appMessage.successfulUpdateStatus);
    } catch (e) {
        return responseFn(null, true, e, appMessage.errorUpdatingStatus);
    }
};

const approveProducedSfgFn = async (
    userCreds,
    productionDeliveredObj,
    documentBasis,
    documentBasisProdOrderArr
) => {
    if (productionDeliveredObj.metaHistory.length > 99)
        productionDeliveredObj.metaHistory.shift();

    productionDeliveredObj.metaHistory.push(productionDeliveredObj.meta);

    // add metadata to productionDeliveredObj to be created in firestore.
    productionDeliveredObj.meta = {
        action: userActions.APPROVED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let existError = false;
    let balanceError = false;
    let editedError = false;

    // let whatRM = [];
    // let rmBalanceErrorMsg = '';

    const transactionFn = async (transaction) => {
        // master lists
        let productionDeliveredListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_PRODUCTION_DELIVERED_LIST_DOC);

        let rmInvListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_INVENTORY_RM_LIST_DOC);

        let sfgInvListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_INVENTORY_SFG_LIST_DOC);

        let prodOrderListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_PROD_ORDER_LIST_DOC);

        // individual docs
        let productionDeliveredRef = db
            .collection(PRODUCTION_DELIVERED_COL)
            .doc(productionDeliveredObj.productionDeliveredUID);

        // get references for all prod orders;
        let documentBasisProdOrderArrRefs = documentBasisProdOrderArr.map(
            (prodOrder) => {
                return db
                    .collection(PROD_ORDER_COL)
                    .doc(prodOrder.productionUID);
            }
        );

        // create sfgMap and rmMap.
        let sfgMap = {};
        let rmMap = {};

        productionDeliveredObj.prodOrderArr.forEach((prodOrder) => {
            prodOrder.sfgArr.forEach((sfg) => {
                sfgMap[sfg.sfgUID] = {
                    computedSfgProducedQty: 0,
                    newProdOrderNumbersArr: [],
                };

                sfg.rmArr.forEach((rm) => {
                    rmMap[rm.rmUID] = {
                        computedRmActualUsedQty: 0,
                        newProdOrderNumbersArr: [],
                    };
                });
            });
        });

        // get references for all sfgObjs
        let sfgMapRefs = {};
        for (let key in sfgMap) {
            sfgMapRefs[key] = db.collection(SEMI_FGS_COL).doc(key);
        }

        // get references for all rmObjs
        let rmMapRefs = {};
        for (let key in rmMap) {
            rmMapRefs[key] = db.collection(RAW_MATERIALS_COL).doc(key);
        }

        // TRANSACTION GET OPERATIONS ================================

        // get master lists
        let productionDeliveredListDoc = await transaction.get(
            productionDeliveredListRef
        );
        let rmInvListDoc = await transaction.get(rmInvListRef);
        let sfgInvListDoc = await transaction.get(sfgInvListRef);
        let prodOrderListDoc = await transaction.get(prodOrderListRef);

        // get individual docs and check for existence and changes.
        let productionDeliveredDoc = await transaction.get(
            productionDeliveredRef
        );

        //check exist error for new SFG Produced document
        // should never run.
        if (!productionDeliveredDoc.exists) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(
                        docNames.PRODUCTION_DELIVERED
                    )
                )
            );
        }
        if (!validate.noChange(productionDeliveredDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(
                        docNames.PRODUCTION_DELIVERED
                    )
                )
            );
        }

        // get prodOrder objects
        let documentBasisProdOrderArrDocs = documentBasisProdOrderArrRefs.map(
            async (prodOrderRef) => await transaction.get(prodOrderRef)
        );

        try {
            documentBasisProdOrderArrDocs = await Promise.all(
                documentBasisProdOrderArrDocs
            );
        } catch (e) {
            return Promise.reject(
                formatCustomErrorObj(
                    900,
                    appMessage.prommiseAllError(
                        'dbopsProductionDelivered',
                        'prodOrderArr'
                    )
                )
            );
        }

        // check for exist error and change error in each ProdOrder Objects.
        documentBasisProdOrderArrDocs.forEach((docBasisProdOrder, index) => {
            if (!docBasisProdOrder.exists) existError = true;
            if (
                !validate.noChange(
                    docBasisProdOrder.data(),
                    documentBasisProdOrderArr[index]
                )
            )
                editedError = true;
        });

        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopProductionDelivered',
                        docNames.PRODUCTION_DELIVERED,
                        'Production Order'
                    )
                )
            );
        }

        if (editedError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseOneItemInArrayChanged(
                        'dbopProductionDelivered',
                        docNames.PRODUCTION_DELIVERED,
                        'Production Order'
                    )
                )
            );
        }

        // get sfgObj
        let sfgMapDocs = {};
        for (let key in sfgMapRefs) {
            sfgMapDocs[key] = await transaction.get(sfgMapRefs[key]);
        }

        // check for existence. no need to check for changes because Actual qty will just
        // be incremented.
        for (let key in sfgMapDocs) {
            if (!sfgMapDocs[key].exists) existError = true;
        }
        //! this is an edge case scenario. should not happen but just in case.
        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopProductionDelivered',
                        docNames.PRODUCTION_DELIVERED,
                        'SFG'
                    )
                )
            );
        }

        // get all rm docs. no need to check for changes because Actual qty will just
        // be decremented.
        let rmMapDocs = {};
        for (let key in rmMapRefs) {
            rmMapDocs[key] = await transaction.get(rmMapRefs[key]);
        }

        // check if the rmMapDocs exist.
        for (let key in rmMapDocs) {
            if (!rmMapDocs[key].exists) existError = true;
        }

        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopProductionDelivered',
                        docNames.PRODUCTION_DELIVERED,
                        'RM'
                    )
                )
            );
        }

        // END TRANSACTION GET OPERATIONS ============================================

        // START MANIPULATING DATA TO UPDATE DATABASE ================================

        // copy all the prodOrderNumbersArr of sfgs from firebase to
        // sfgMap.newProdOrderNumbersArr.
        for (let key in sfgMapDocs) {
            sfgMap[key].newProdOrderNumbersArr = [
                ...sfgMapDocs[key].data().prodOrderNumbersArr,
            ];
        }

        // copy all the prodOrderNumbersArr of rms from firebase to rmMap.newProdOrderNumbersArr.
        for (let key in rmMapDocs) {
            rmMap[key].newProdOrderNumbersArr = [
                ...rmMapDocs[key].data().prodOrderNumbersArr,
            ];
        }

        // determine the correct sfgProducedQtyForThisProduction to be added  for each SFG.
        // determine the correct rmUsedQtyForThisSfgProduced to be deducted for each RM.
        productionDeliveredObj.prodOrderArr.forEach((prodOrder) => {
            prodOrder.sfgArr.forEach((sfg) => {
                // sfgMap should include the sfg.sfgUID as property.
                sfgMap[
                    sfg.sfgUID
                ].computedSfgProducedQty = saveWithFiveDecimals(
                    sfgMap[sfg.sfgUID].computedSfgProducedQty +
                        sfg.sfgProducedQtyForThisProduction
                );

                // should include the rmUID as property.
                sfg.rmArr.forEach((rm) => {
                    rmMap[
                        rm.rmUID
                    ].computedRmActualUsedQty = saveWithFiveDecimals(
                        rmMap[rm.rmUID].computedRmActualUsedQty +
                            rm.rmUsedQtyForThisSfgProduced
                    );
                });
            });
        });

        // create tempList to update status correctly.
        let tempProdOrderList = prodOrderListDoc.data();

        productionDeliveredObj.prodOrderArr.forEach(
            async (prodOrder, prodOrderIndex) => {
                // make copy of prodOrder and change sfgObjs and rmObjs that are nested
                // with correct sfgBalance/sfgProducedQty and rmBalance/rmActualUsedQty.
                // update firebase with this new objects.

                let prodOrderCopy = {
                    ...documentBasisProdOrderArrDocs[prodOrderIndex].data(),
                };

                let allSfgsProducedAndRmsUsed = [];

                //! check sfgArr
                prodOrder.sfgArr.forEach((sfg, sfgIndex) => {
                    let sfgObjFromProdOrder_producedQty =
                        prodOrderCopy.sfgArr[sfgIndex].sfgProducedQty;
                    let sfgObjFromProdOrder_balanceQty =
                        prodOrderCopy.sfgArr[sfgIndex].sfgBalanceQty;

                    // check for balance error.
                    if (
                        saveWithFiveDecimals(
                            saveWithFiveDecimals(
                                sfgObjFromProdOrder_balanceQty +
                                    prodOrderCopy.sfgArr[sfgIndex]
                                        .sfgOverrideQty
                            ) - sfg.sfgProducedQtyForThisProduction
                        ) < 0
                    ) {
                        balanceError = true;
                    }

                    prodOrderCopy.sfgArr[sfgIndex] = {
                        ...prodOrderCopy.sfgArr[sfgIndex],
                        sfgProducedQty: saveWithFiveDecimals(
                            sfgObjFromProdOrder_producedQty +
                                sfg.sfgProducedQtyForThisProduction
                        ),
                        sfgBalanceQty: saveWithFiveDecimals(
                            sfgObjFromProdOrder_balanceQty -
                                sfg.sfgProducedQtyForThisProduction
                        ),
                    };

                    // if balance of sfg is 0, push true to allSfgsProducedAndRmsUsed.
                    if (
                        saveWithFiveDecimals(
                            prodOrderCopy.sfgArr[sfgIndex].sfgBalanceQty +
                                prodOrderCopy.sfgArr[sfgIndex].sfgOverrideQty
                        ) === 0
                    ) {
                        allSfgsProducedAndRmsUsed.push(true);
                    } else allSfgsProducedAndRmsUsed.push(false);

                    //! rmArr inside sfgArr
                    sfg.rmArr.forEach((rm, rmIndex) => {
                        let rmObjFromSfgArrInProdOrder_actualUsedQty =
                            prodOrderCopy.sfgArr[sfgIndex].rmArr[rmIndex]
                                .rmActualUsedQty;
                        let rmObjFromSfgArrInProdOrder_balanceQty =
                            prodOrderCopy.sfgArr[sfgIndex].rmArr[rmIndex]
                                .rmBalanceQty;

                        // check for balance error.
                        if (
                            saveWithFiveDecimals(
                                saveWithFiveDecimals(
                                    rmObjFromSfgArrInProdOrder_balanceQty +
                                        prodOrderCopy.sfgArr[sfgIndex].rmArr[
                                            rmIndex
                                        ].rmOverrideQty
                                ) - rm.rmUsedQtyForThisSfgProduced
                            ) < 0
                        ) {
                            balanceError = true;
                        }

                        prodOrderCopy.sfgArr[sfgIndex].rmArr[rmIndex] = {
                            ...prodOrderCopy.sfgArr[sfgIndex].rmArr[rmIndex],
                            rmActualUsedQty: saveWithFiveDecimals(
                                rmObjFromSfgArrInProdOrder_actualUsedQty +
                                    rm.rmUsedQtyForThisSfgProduced
                            ),
                            rmBalanceQty: saveWithFiveDecimals(
                                rmObjFromSfgArrInProdOrder_balanceQty -
                                    rm.rmUsedQtyForThisSfgProduced
                            ),
                        };

                        // if balance of sfg is 0, push true to allSfgsProducedAndRmsUsed.
                        if (
                            saveWithFiveDecimals(
                                prodOrderCopy.sfgArr[sfgIndex].rmArr[rmIndex]
                                    .rmBalanceQty +
                                    prodOrderCopy.sfgArr[sfgIndex].rmArr[
                                        rmIndex
                                    ].rmOverrideQty
                            ) === 0
                        ) {
                            allSfgsProducedAndRmsUsed.push(true);
                        } else allSfgsProducedAndRmsUsed.push(false);
                    });
                });

                if (allSfgsProducedAndRmsUsed.every((ans) => ans === true)) {
                    // prodOrder is fulfilled so remove prodOrderNumber from
                    // all sfgs and rms.
                    prodOrderCopy.sfgArr.forEach((sfg) => {
                        let newArr = [
                            ...sfgMap[sfg.sfgUID].newProdOrderNumbersArr,
                        ];
                        newArr = newArr.filter(
                            (prodOrderNum) =>
                                prodOrderNum !== prodOrderCopy.productionUID
                        );
                        sfgMap[sfg.sfgUID].newProdOrderNumbersArr = newArr;

                        sfg.rmArr.forEach((rm) => {
                            let newArrInRm = [
                                ...rmMap[rm.rmUID].newProdOrderNumbersArr,
                            ];
                            newArrInRm = newArrInRm.filter(
                                (prodOrderNum) =>
                                    prodOrderNum !== prodOrderCopy.productionUID
                            );
                            rmMap[rm.rmUID].newProdOrderNumbersArr = newArrInRm;
                        });
                    });

                    // change status of prodOrder object
                    if (prodOrderCopy.metaHistory.length > 99)
                        prodOrderCopy.metaHistory.shift();

                    prodOrderCopy.metaHistory.push(prodOrderCopy.meta);

                    //update metadata in prodOrderCopy.
                    prodOrderCopy.meta = {
                        action: systemActions.FULFILLED,
                        uid: systemActions.UID,
                        email: systemActions.EMAIL,
                        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
                    };

                    // update temporary prodOrder master list
                    let prodOrderInfoObj = operationObjToMListItemFn(
                        prodOrderCopy,
                        'productionUID'
                    );

                    //use tempProdOrderList to update the master prod order list
                    tempProdOrderList = mListHelper.updateTempMList(
                        tempProdOrderList,
                        prodOrderCopy.productionUID,
                        prodOrderInfoObj
                    );
                }

                // update individual prod Order object
                await transaction.set(
                    documentBasisProdOrderArrRefs[prodOrderIndex],
                    prodOrderCopy,
                    { merge: true }
                );
            }
        );

        if (balanceError) {
            return Promise.reject(
                formatCustomErrorObj(
                    800,
                    appMessage.balanceError(
                        'Produced/ActualUsed Qty',
                        'Balance Qty',
                        'SFG/RM'
                    )
                )
            );
        }

        // update prodOrder master list.
        await transaction.set(prodOrderListRef, tempProdOrderList);

        //==============================================================
        let tempSfgInventoryList = sfgInvListDoc.data();

        // change actual qty of the sfgs
        for (let key in sfgMap) {
            let data = {
                sfgActualQty: saveWithFiveDecimals(
                    sfgMapDocs[key].data().sfgActualQty +
                        sfgMap[key].computedSfgProducedQty
                ),
                prodOrderNumbersArr: sfgMap[key].newProdOrderNumbersArr,
            };

            let sfgCopy = sfgMapDocs[key].data();
            sfgCopy.sfgActualQty = data.sfgActualQty;
            let inventorySfgInfoObj = sfgInvObjToMListItemFn(sfgCopy);

            tempSfgInventoryList = mListHelper.updateTempMList(
                tempSfgInventoryList,
                sfgCopy.sfgUID,
                inventorySfgInfoObj
            );

            await transaction.set(sfgMapRefs[key], data, { merge: true });
        }

        let tempRmInventoryList = rmInvListDoc.data();

        // change actual qty of the rmMap
        for (let key in rmMap) {
            let data = {
                rmActualQty: saveWithFiveDecimals(
                    rmMapDocs[key].data().rmActualQty -
                        rmMap[key].computedRmActualUsedQty
                ),
                prodOrderNumbersArr: rmMap[key].newProdOrderNumbersArr,
            };

            let rmCopy = rmMapDocs[key].data();
            rmCopy.rmActualQty = data.rmActualQty;
            let inventoryRmInfoObj = rmInvObjToMListItemFn(rmCopy);

            tempRmInventoryList = mListHelper.updateTempMList(
                tempRmInventoryList,
                rmCopy.rmUID,
                inventoryRmInfoObj
            );

            await transaction.set(rmMapRefs[key], data, { merge: true });
        }

        //update sfgInventory master list
        await transaction.set(sfgInvListRef, tempSfgInventoryList);

        //update rmInventory master list
        await transaction.set(rmInvListRef, tempRmInventoryList);

        // create or update productionDelivered list document in (master lists collection)
        if (productionDeliveredListDoc.exists) {
            let productionDeliveredInfoObj = operationObjToMListItemFn(
                productionDeliveredObj,
                'productionDeliveredUID'
            );

            let newData = mListHelper.updateMasterList(
                productionDeliveredListDoc,
                productionDeliveredObj.productionDeliveredUID,
                productionDeliveredInfoObj
            );

            await transaction.set(productionDeliveredListRef, newData);
        } else {
            let productionDeliveredInfoObj = operationObjToMListItemFn(
                productionDeliveredObj,
                'productionDeliveredUID'
            );

            let newData = mListHelper.addMasterList(
                productionDeliveredObj.productionDeliveredUID,
                productionDeliveredInfoObj
            );

            await transaction.set(productionDeliveredListRef, newData);
        }

        // set new productionDelivered document.
        await transaction.set(productionDeliveredRef, productionDeliveredObj);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(null, false, null, appMessage.successfulUpdateStatus);
    } catch (e) {
        // let customErrorObj = rmBalanceErrorMsg
        //     ? formatAlertErrorObj(rmBalanceErrorMsg, whatRM)
        //     : null;
        return responseFn(null, true, e, appMessage.errorUpdatingStatus);
    }
};

export default {
    getSfgProducedFn,
    createProductionDeliveredFn,
    updateProductionDeliveredFn,
    deleteProductionDeliveredFn,
    statusChangeFn,
    approveProducedSfgFn,
};
