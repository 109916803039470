import React, { useState, useEffect, useContext } from 'react';
import { db } from '../../firebase_config/firebaseConfig';
import {
    Datalist,
    AddButton,
    InputDS,
    Input,
    UpdateItemButtons,
    DeleteItemButtons,
    BackOnlyButton,
    ApproveRejectButtons,
    OverrideWithBackButtons,
    OverrideSaveButtons,
    Checkbox,
    HelpTip,
    TextArea,
    Watermark,
} from '../../components';
import {
    AuthContext,
    MasterListContext,
    DataShareContext,
} from '../../contexts';
import {
    permissions,
    userActions,
    systemActions,
    lengths,
    customerPriceList,
    CUSTOMERS_COL,
} from '../../lib/constants';
import { dbopsFinishedGoods, dbopsSemiFGs } from '../../firebase_dbops';

import AddSdpoItemForm from './AddSdpoItemForm';
import SdpoAmountBox from './SdpoAmountBox';
import validate from '../../validators';
import {
    clone,
    displayDate,
    saveWithTwoDecimalOnly,
    displayMoney,
    displayReadableNumber,
    saveWithFiveDecimals,
} from '../../lib/util';
import tooltips from '../../lib/tooltips';
import '../PagesCommon.css';
import './SDPOsPage.css';

let defaultSDPO = {
    sdpoUID: '',
    sdpoNumber: '',
    sdpoDate: '',
    customerUID: '',
    quoteUID: '',
    isQuote: false,
    fgArr: [],
    sfgArr: [],
    meta: { action: '' },
    note: '',
};

const sfgPricePropertyFn = (priceListNumber) => {
    let prop = '';
    switch (priceListNumber) {
        case customerPriceList.PRICE_LIST_1:
            prop = 'sfgPrice';
            break;
        case customerPriceList.PRICE_LIST_2:
            prop = 'sfgPrice2';
            break;
        case customerPriceList.PRICE_LIST_3:
            prop = 'sfgPrice3';
            break;
        case customerPriceList.PRICE_LIST_4:
            prop = 'sfgPrice4';
            break;
        case customerPriceList.PRICE_LIST_5:
            prop = 'sfgPrice5';
            break;
        default:
            prop = 'sfgPrice';
            break;
    }
    return prop;
};

const fgPricePropertyFn = (priceListNumber) => {
    let prop = '';
    switch (priceListNumber) {
        case customerPriceList.PRICE_LIST_1:
            prop = 'fgPrice';
            break;
        case customerPriceList.PRICE_LIST_2:
            prop = 'fgPrice2';
            break;
        case customerPriceList.PRICE_LIST_3:
            prop = 'fgPrice3';
            break;
        case customerPriceList.PRICE_LIST_4:
            prop = 'fgPrice4';
            break;
        case customerPriceList.PRICE_LIST_5:
            prop = 'fgPrice5';
            break;
        default:
            prop = 'fgPrice';
            break;
    }
    return prop;
};

const copySdpoObjAndCleanErrors = (sdpoObj) => {
    let sdpoObjCopy = {
        ...sdpoObj,
        fgArr: clone(sdpoObj.fgArr),
        sfgArr: clone(sdpoObj.sfgArr),
    };

    delete sdpoObjCopy.sdpoUIDError;
    delete sdpoObjCopy.customerUIDError;
    delete sdpoObjCopy.sdpoTaxRateError;
    delete sdpoObjCopy.sdpoDiscountRateError;
    delete sdpoObjCopy.itemArrError;
    delete sdpoObjCopy.sdpoDateError;
    delete sdpoObjCopy.noteError;

    // clear all error messages embeded in the objects.
    sdpoObjCopy.fgArr.forEach((fg) => {
        delete fg.fgOrderedQtyError;
        delete fg.fgOverrideQtyError;
    });
    sdpoObjCopy.sfgArr.forEach((sfg) => {
        delete sfg.sfgOrderedQtyError;
        delete sfg.sfgOverrideQtyError;
    });

    return sdpoObjCopy;
};

export default (props) => {
    let {
        purpose,
        currentDocument,

        backCancelBtnFn,
        gotoEditViewFn,
        gotoDeleteViewFn,
        gotoOverRideViewFn,

        createSdpoFn,
        deleteSdpoFn,
        updateSdpoFn,
        statusChangeFn,
        overRideSdpoFn,

        setIsLoading,
        setAlertErrorMsg,
    } = props;

    let {
        masterFinishedGoodsList,
        masterSemiFGsList,
        masterCustomersList,
    } = useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);
    let { defaultTaxRateFromDB, serverDate } = useContext(DataShareContext);

    // let [isLoading, setIsLoading] = useState(false);
    let [generalError, setGeneralError] = useState('');
    let [
        datalistDisplayResetCounter,
        setDatalistDisplayResetCounter,
    ] = useState(1);

    let [sdpoObj, setSDPOObj] = useState(defaultSDPO);
    let [sdpoUIDState, setSdpoUIDstate] = useState('');
    let [fgUIDstate, setFgUID] = useState('');
    let [sfgUIDstate, setSfgUID] = useState('');
    let [preview, setPreview] = useState(false);
    let [sdpoDateInState, setSdpoDateInState] = useState(serverDate);
    let [discountInState, setDiscountInState] = useState(0);
    let [taxInState, setTaxInState] = useState(defaultTaxRateFromDB);
    let [noteInState, setNoteInState] = useState('');
    let [customerUIDstate, setCustomerUIDstate] = useState('');
    let [tempCustomer, setTempCustomer] = useState('');
    let [
        continueProcessPastChoosingCustomer,
        setContinueProcessPastChoosingCustomer,
    ] = useState(false);
    let [priceListNumberInState, setPriceListNumberInState] = useState('');

    let [confirmDeleteText, setConfirmDeleteText] = useState('');

    // set sdpoObj from currentDocument if purpose !== 'ADD'

    useEffect(() => {
        if (purpose !== 'ADD') {
            // making a copy so no side effect will change currentDocument.
            let currentDocumentCopy = {
                // will copy meta and metaHistory as is.
                ...currentDocument,
                // copy fgArr and sfgArr and not a reference.
                fgArr: clone(currentDocument.fgArr),
                sfgArr: clone(currentDocument.sfgArr),
            };
            setSDPOObj(currentDocumentCopy);
            setSdpoDateInState(currentDocumentCopy.sdpoDate);
            setDiscountInState(currentDocumentCopy.sdpoDiscountRate);
            setTaxInState(currentDocumentCopy.sdpoTaxRate);
            setSdpoUIDstate(currentDocumentCopy.sdpoUID);
            setNoteInState(currentDocumentCopy.note || '');
            setCustomerUIDstate(currentDocumentCopy.customerUID);
            setContinueProcessPastChoosingCustomer(true);
            setPriceListNumberInState(currentDocumentCopy.priceListNum);
        }
    }, []);

    const cancelBtnFromEditViewFn = () => {
        // 1. reset values.
        setSDPOObj(currentDocument);

        // 2. reset errorMessages.
        setGeneralError('');

        // 3. go back to profile view.
        backCancelBtnFn();
    };

    // =====================================================

    const chooseCustomerFn = async (tempCustomerInState) => {
        let chosenCustomer = masterCustomersList.filter(
            (customer) => customer.customerUID === tempCustomerInState
        );

        if (chosenCustomer[0]) {
            setPriceListNumberInState(
                chosenCustomer[0].priceListNum || customerPriceList.PRICE_LIST_1
            );
            setCustomerUIDstate(chosenCustomer[0].customerUID);

            //! feature added. client request. Aug 18, 2020.
            //! additional call to firebase to get the discount property of customer

            try {
                let customerObj = await db
                    .collection(CUSTOMERS_COL)
                    .doc(chosenCustomer[0].customerUID)
                    .get();
                customerObj = customerObj.data();
                setDiscountInState(customerObj.discount || 0);
            } catch (e) {
                console.error('Error in retrieving customer discount');
                console.error(e);
                setDiscountInState(0);
            }

            //! ==========================================================
            setContinueProcessPastChoosingCustomer(true);
        }
    };

    const addToFgArrFn = async () => {
        let sdpoObjCopy = copySdpoObjAndCleanErrors(sdpoObj);

        setIsLoading(true);

        let response = await dbopsFinishedGoods.getFGFn(fgUIDstate);
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message || response.message);
            setIsLoading(false);
            return;
        }

        // proceed if no error.
        let fgObj = {
            ...response.data,
            fgOrderedQty: 0,
            fgBalanceQty: 0,
            fgOverrideQty: 0,
        };

        sdpoObjCopy.fgArr.push(fgObj);

        setSDPOObj(sdpoObjCopy);
        setGeneralError('');
        setDatalistDisplayResetCounter(datalistDisplayResetCounter + 1);
        setIsLoading(false);
    };

    const removeFgFromArrFn = (index) => {
        let sdpoObjCopy = copySdpoObjAndCleanErrors(sdpoObj);

        sdpoObjCopy.fgArr.splice(index, 1);
        setSDPOObj(sdpoObjCopy);
    };

    const addToSfgArrFn = async () => {
        let sdpoObjCopy = copySdpoObjAndCleanErrors(sdpoObj);

        setIsLoading(true);

        let response = await dbopsSemiFGs.getSFGFn(sfgUIDstate);
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message || response.message);
            setIsLoading(false);
            return;
        }

        // proceed if no error.
        let sfgObj = {
            ...response.data,
            sfgOrderedQty: 0,
            sfgBalanceQty: 0,
            sfgOverrideQty: 0,
        };

        sdpoObjCopy.sfgArr.push(sfgObj);

        setSDPOObj(sdpoObjCopy);
        setGeneralError('');
        setDatalistDisplayResetCounter(datalistDisplayResetCounter + 1);
        setIsLoading(false);
    };

    const removeSfgFromArrFn = (index) => {
        let sdpoObjCopy = copySdpoObjAndCleanErrors(sdpoObj);

        sdpoObjCopy.sfgArr.splice(index, 1);
        setSDPOObj(sdpoObjCopy);
    };

    const updateOrdereQtyProperty = (val, arrName, itemIndex, propertyName) => {
        let sdpoObjCopy = copySdpoObjAndCleanErrors(sdpoObj);

        sdpoObjCopy[arrName][itemIndex][propertyName] = val;

        setSDPOObj(sdpoObjCopy);
    };

    const toggleQuoteFn = () => {
        let sdpoObjCopy = copySdpoObjAndCleanErrors(sdpoObj);
        sdpoObjCopy.isQuote = sdpoObjCopy.isQuote === true ? false : true;

        if (sdpoObjCopy.isQuote) setSdpoUIDstate(sdpoObj.quoteUID || '');
        else setSdpoUIDstate('');

        setSDPOObj(sdpoObjCopy);
    };

    // for saving functionality
    const previewSDPOBtnFn = () => {
        let sdpoObjCopy = copySdpoObjAndCleanErrors(sdpoObj);

        sdpoObjCopy.customerUID = customerUIDstate;
        sdpoObjCopy.sdpoUID = sdpoUIDState;
        sdpoObjCopy.sdpoNumber = sdpoUIDState;
        sdpoObjCopy.sdpoTaxRate = taxInState;
        sdpoObjCopy.sdpoDiscountRate = discountInState;
        sdpoObjCopy.sdpoDate = sdpoDateInState;
        sdpoObjCopy.note = noteInState;
        sdpoObjCopy.priceListNum = priceListNumberInState;

        if (
            (purpose === 'EDIT' || purpose === 'OVERRIDE') &&
            validate.noChange(sdpoObjCopy, currentDocument)
        ) {
            backCancelBtnFn();
            return;
        }

        let priceSetterObj = {
            fgPriceListToUse: () => fgPricePropertyFn(priceListNumberInState),
            sfgPriceListToUse: () => sfgPricePropertyFn(priceListNumberInState),
        };

        let response = validate.sdpo(
            sdpoObjCopy,
            purpose,
            masterCustomersList,
            currentDocument,
            {}, // userActionsPassed
            priceSetterObj
        );
        if (response.error) {
            // console.log(response.errorObj.sdpoObj);
            setSDPOObj(response.errorObj.sdpoObj);
            setAlertErrorMsg(response.errorObj.hackError);
            return;
        }
        // no error
        setSDPOObj(response.data.sdpoObj);
        setPreview(true);
    };

    const saveSDPOFn = () => {
        let sdpoObjCopy = copySdpoObjAndCleanErrors(sdpoObj);

        sdpoObjCopy.customerUID = customerUIDstate;
        sdpoObjCopy.sdpoUID = sdpoUIDState;
        sdpoObjCopy.sdpoNumber = sdpoUIDState;
        sdpoObjCopy.sdpoTaxRate = taxInState;
        sdpoObjCopy.sdpoDiscountRate = discountInState;
        sdpoObjCopy.sdpoDate = sdpoDateInState;
        sdpoObjCopy.note = noteInState;
        sdpoObjCopy.priceListNum = priceListNumberInState;

        // check if there is a change in the payload from currentDocument
        if (
            (purpose === 'EDIT' || purpose === 'OVERRIDE') &&
            validate.noChange(sdpoObjCopy, currentDocument)
        ) {
            backCancelBtnFn();
            return;
        }

        let priceSetterObj = {
            fgPriceListToUse: () => fgPricePropertyFn(priceListNumberInState),
            sfgPriceListToUse: () => sfgPricePropertyFn(priceListNumberInState),
        };

        let response = validate.sdpo(
            sdpoObjCopy,
            purpose,
            masterCustomersList,
            currentDocument,
            {}, // userActionsPassed
            priceSetterObj
        );
        if (response.error) {
            // console.error(response.errorObj.sdpoObj);
            setSDPOObj(response.errorObj.sdpoObj);
            setAlertErrorMsg(response.errorObj.hackError);

            return;
        }
        setSDPOObj(response.data.sdpoObj);

        let validSdpoObj = response.data.sdpoObj;

        // console.log(validSdpoObj);

        if (purpose === 'ADD') createSdpoFn(validSdpoObj);

        if (
            purpose === 'EDIT' &&
            validSdpoObj.meta.action === userActions.QUOTE &&
            !validSdpoObj.isQuote
        ) {
            createSdpoFn(validSdpoObj, currentDocument);
        } else if (purpose === 'EDIT') {
            updateSdpoFn(validSdpoObj, currentDocument);
        }

        if (purpose === 'DELETE') deleteSdpoFn(validSdpoObj, currentDocument);
        if (purpose === 'OVERRIDE')
            overRideSdpoFn(validSdpoObj, currentDocument);
    };

    //! work around. fixes sequence of Edit without saving and then sending for approval.
    //! bug found in PDSI, SDSI, SDPO. All without statusChangeLocalFn.
    const statusChangeLocalFn = (userActionPassed) => {
        let sdpoObjCopy = copySdpoObjAndCleanErrors(sdpoObj);

        sdpoObjCopy.customerUID = customerUIDstate;
        sdpoObjCopy.sdpoUID = sdpoUIDState;
        sdpoObjCopy.sdpoNumber = sdpoUIDState;
        sdpoObjCopy.sdpoTaxRate = taxInState;
        sdpoObjCopy.sdpoDiscountRate = discountInState;
        sdpoObjCopy.sdpoDate = sdpoDateInState;
        sdpoObjCopy.note = noteInState;
        sdpoObjCopy.priceListNum = priceListNumberInState;

        let priceSetterObj = {
            fgPriceListToUse: () => fgPricePropertyFn(priceListNumberInState),
            sfgPriceListToUse: () => sfgPricePropertyFn(priceListNumberInState),
        };

        let response = validate.sdpo(
            sdpoObjCopy,
            purpose,
            masterCustomersList,
            currentDocument,
            userActionPassed,
            priceSetterObj
        );
        if (response.error) {
            setSDPOObj(response.errorObj.sdpoObj);
            setAlertErrorMsg(response.errorObj.hackError);
            return;
        }

        let validSdpoObj = response.data.sdpoObj;
        // console.log(validSdpoObj);
        statusChangeFn(userActionPassed, validSdpoObj, currentDocument);
    };

    // conditional render of contents  =============================
    let fgArrUI = [];
    let sfgArrUI = [];
    let noteUI = null;

    let priceSetterObj = {
        fgPriceListToUse: () => fgPricePropertyFn(priceListNumberInState),
        sfgPriceListToUse: () => sfgPricePropertyFn(priceListNumberInState),
    };

    let buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    let mainContent = null;
    let fgDatalist = null;
    let sfgDatalist = null;
    let amountBox = null;

    let fgAddBtn = <AddButton disabled />;
    let sfgAddBtn = <AddButton disabled />;

    let sfgSectionLabel = (
        <div className='span-2-columns-container label-padding label-highlight'>
            <label className='parent-labels'>SFG List</label>
        </div>
    );
    let fgSectionLabel = (
        <div className='span-2-columns-container label-padding label-highlight'>
            <label className='parent-labels'>FG List</label>
        </div>
    );

    if ((purpose === 'ADD' && !preview) || purpose === 'EDIT') {
        //! customer datalist process =======================================

        let customerListOptions = masterCustomersList.map(
            (customer) => customer.customerUID
        );

        let chooseCustomerBtn = (
            <button
                className='choose-sup-button'
                onClick={() => chooseCustomerFn(tempCustomer)}>
                Choose Customer
            </button>
        );

        let customerDatalist = (
            <div className='get-item-from-db-container'>
                <div className='datalist-with-addbtn-container'>
                    <Datalist
                        list={customerListOptions}
                        initialValue={''}
                        getInputValue={(val) => setTempCustomer(val)}
                        errorMessage={sdpoObj.customerUIDError}
                        resetCounter={datalistDisplayResetCounter}></Datalist>
                    {chooseCustomerBtn}
                </div>
            </div>
        );

        //! end choose customer process. ======================================

        let fgDatalistOptions = masterFinishedGoodsList.map((fg) => fg.fgUID);
        let fgAlreadyInTheUI = sdpoObj.fgArr.map((fg) => fg.fgUID);

        if (
            fgDatalistOptions.includes(fgUIDstate) &&
            !fgAlreadyInTheUI.includes(fgUIDstate)
        )
            fgAddBtn = <AddButton onClick={addToFgArrFn} />;

        let fgDatalistLabel = (
            <span>
                Add Finished Good
                <HelpTip content={tooltips.sdpoFgArr}></HelpTip>
            </span>
        );

        fgDatalist = (
            <div className='get-item-from-db-container'>
                <div className='datalist-with-addbtn-container'>
                    <Datalist
                        label={fgDatalistLabel}
                        list={fgDatalistOptions}
                        initialValue={''}
                        getInputValue={(val) => setFgUID(val)}
                        errorMessage={sdpoObj.itemArrError}
                        resetCounter={datalistDisplayResetCounter}></Datalist>
                    {fgAddBtn}
                </div>
            </div>
        );

        let sfgDatalistOptions = masterSemiFGsList.map((sfg) => sfg.sfgUID);
        let sfgAlreadyInTheUI = sdpoObj.sfgArr.map((sfg) => sfg.sfgUID);

        if (
            sfgDatalistOptions.includes(sfgUIDstate) &&
            !sfgAlreadyInTheUI.includes(sfgUIDstate)
        )
            sfgAddBtn = <AddButton onClick={addToSfgArrFn} />;

        let sfgDatalistLabel = (
            <span>
                Add Semi-Finished Good
                <HelpTip content={tooltips.sdpoSfgArr}></HelpTip>
            </span>
        );

        sfgDatalist = (
            <div className='get-item-from-db-container'>
                <div className='datalist-with-addbtn-container'>
                    <Datalist
                        label={sfgDatalistLabel}
                        list={sfgDatalistOptions}
                        initialValue={''}
                        getInputValue={(val) => setSfgUID(val)}
                        errorMessage={sdpoObj.itemArrError}
                        resetCounter={datalistDisplayResetCounter}></Datalist>
                    {sfgAddBtn}
                </div>
            </div>
        );

        for (let index = 0; index < sdpoObj.fgArr.length; index += 1) {
            let trashIcon = (
                <i
                    className='ms-Icon ms-Icon--Delete clickable-icon'
                    aria-hidden='true'
                    onClick={() => removeFgFromArrFn(index)}></i>
            );

            fgArrUI.push(
                <div className='span-2-columns-container' key={index}>
                    <AddSdpoItemForm
                        trashIcon={trashIcon}
                        itemIndex={index}
                        itemObj={sdpoObj.fgArr[index]}
                        arrName={'fgArr'}
                        uidProperty={'fgUID'}
                        priceProperty={fgPricePropertyFn(
                            priceListNumberInState
                        )}
                        unitProperty={'fgUnit'}
                        orderProperty={'fgOrderedQty'}
                        errorProperty={'fgOrderedQtyError'}
                        updateOrdereQtyProperty={updateOrdereQtyProperty}
                    />
                </div>
            );
        }

        for (let index = 0; index < sdpoObj.sfgArr.length; index += 1) {
            let trashIcon = (
                <i
                    className='ms-Icon ms-Icon--Delete clickable-icon'
                    aria-hidden='true'
                    onClick={() => removeSfgFromArrFn(index)}></i>
            );

            sfgArrUI.push(
                <div className='span-2-columns-container' key={index}>
                    <AddSdpoItemForm
                        trashIcon={trashIcon}
                        itemIndex={index}
                        itemObj={sdpoObj.sfgArr[index]}
                        arrName={'sfgArr'}
                        uidProperty={'sfgUID'}
                        priceProperty={sfgPricePropertyFn(
                            priceListNumberInState
                        )}
                        unitProperty={'sfgUnit'}
                        orderProperty={'sfgOrderedQty'}
                        errorProperty={'sfgOrderedQtyError'}
                        updateOrdereQtyProperty={updateOrdereQtyProperty}
                    />
                </div>
            );
        }

        let fgListLabel = (
            <div className='span-2-columns-container'>
                <div className='view-table-heading-si'>
                    <div className='cell'>
                        <span className='m-left-15'>FG Items</span>
                    </div>

                    <div className='cell'>
                        <span className='width25'>Order Qty</span>
                        <span className='width25'>Unit</span>
                        <span className='width25'>Unit Price</span>
                        <span className='width25'>Item Total</span>
                    </div>
                </div>
            </div>
        );

        let sfgListLabel = (
            <div className='span-2-columns-container'>
                <div className='view-table-heading-si'>
                    <div className='cell'>
                        <span className='m-left-15'>SFG Items</span>
                    </div>

                    <div className='cell'>
                        <span className='width25'>Order Qty</span>
                        <span className='width25'>Unit</span>
                        <span className='width25'>Unit Price</span>
                        <span className='width25'>Item Total</span>
                    </div>
                </div>
            </div>
        );

        if (sfgArrUI.length === 0) {
            sfgListLabel = null;
            sfgSectionLabel = null;
        }
        if (fgArrUI.length === 0) {
            fgListLabel = null;
            fgSectionLabel = null;
        }

        amountBox = (
            <SdpoAmountBox
                priceSetterObj={priceSetterObj}
                sdpoObj={sdpoObj}
                setTaxInState={setTaxInState}
                setDiscountInState={setDiscountInState}
                taxInState={taxInState}
                discountInState={discountInState}
                purpose={purpose}
            />
        );

        if (sdpoObj.fgArr.length === 0 && sdpoObj.sfgArr.length === 0) {
            amountBox = null;
        }

        let quoteCheckbox = (
            <span className='m-right-15'>
                <Checkbox
                    label='Quote'
                    checked={sdpoObj.isQuote}
                    onChange={toggleQuoteFn}
                />
            </span>
        );

        let requiredText = 'Required';
        if (sdpoObj.isQuote) requiredText = '';

        let spdoUIDInput = (
            <Input
                type='text'
                maxLength='30'
                onChange={(e) => setSdpoUIDstate(e.target.value)}
                value={sdpoUIDState}
                placeholder={requiredText}
                errorMessage={sdpoObj.sdpoUIDError}
                disabled={sdpoObj.isQuote}></Input>
        );

        if (purpose === 'EDIT' && sdpoObj.meta.action !== userActions.QUOTE) {
            quoteCheckbox = null;
            spdoUIDInput = (
                <Input
                    type='text'
                    maxLength='30'
                    // onChange={(e) => setSdpoUIDstate(e.target.value)}
                    value={sdpoUIDState}
                    errorMessage={sdpoObj.sdpoUIDError}
                    disabled></Input>
            );
        }

        noteUI = (
            <div className='span-2-columns-container'>
                <TextArea
                    label={<span className='parent-labels'>Notes</span>}
                    maxLength={lengths.noteLength}
                    value={noteInState}
                    onChange={(e) => setNoteInState(e.target.value)}
                    errorMessage={sdpoObj.noteError}
                />
            </div>
        );

        //! conditional to pass choosing customer ================================

        if (continueProcessPastChoosingCustomer) {
            customerDatalist = null;
        }

        let allComponentUIPastChoosingCustomer = continueProcessPastChoosingCustomer ? (
            <>
                {sfgSectionLabel}
                {sfgListLabel}
                {sfgArrUI}
                {sfgDatalist}

                <div className='span-2-columns-container'>
                    <div style={{ marginBottom: '30px' }}></div>
                    <hr></hr>
                    <div style={{ marginBottom: '30px' }}></div>
                </div>

                {fgSectionLabel}
                {fgListLabel}
                {fgArrUI}
                {fgDatalist}

                <div className='span-2-columns-container'>
                    <div style={{ marginBottom: '30px' }}></div>
                    <hr></hr>
                    <div style={{ marginBottom: '30px' }}></div>
                </div>

                {amountBox}
            </>
        ) : null;

        //! end -- conditional to pass choosing customer ============================

        //! Max number of items in the list
        if (sdpoObj.fgArr.length >= lengths.sdpoFgArrLength) fgDatalist = null;
        if (sdpoObj.sfgArr.length >= lengths.sdpoSfgArrLength)
            sfgDatalist = null;

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label flex-row'>
                        <span className='goto-left'>Sales Order</span>{' '}
                        {quoteCheckbox}
                    </p>
                    {spdoUIDInput}
                </div>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Status</p>
                    <p className='fpii-text'>{sdpoObj.meta.action || '---'}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Customer</p>
                    <p className='fpii-text'>
                        {sdpoObj.customerUID || customerUIDstate || '---'}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Date</p>
                    <Input
                        type='date'
                        maxLength='12'
                        onChange={(e) => setSdpoDateInState(e.target.value)}
                        value={sdpoDateInState}
                        errorMessage={sdpoObj.sdpoDateError}></Input>
                </div>

                {allComponentUIPastChoosingCustomer}
                {customerDatalist}
                {noteUI}
            </section>
        );
    }

    if (
        purpose === 'OVERRIDE' ||
        (purpose === 'ADD' && preview) ||
        (purpose === 'EDIT' && preview) ||
        purpose === 'VIEW' ||
        purpose === 'DELETE'
    ) {
        fgArrUI = sdpoObj.fgArr.map((fg, index) => {
            let override = (
                <div className='width15'>
                    {' '}
                    <span className='mobile-label'>Override Qty:</span>{' '}
                    <span>{displayReadableNumber(fg.fgOverrideQty)}</span>
                </div>
            );

            if (purpose === 'OVERRIDE') {
                override = (
                    <div className='width15'>
                        <span className='mobile-label'>Override Qty:</span>{' '}
                        <div className='m-right-10'>
                            <InputDS
                                keyID={`${index} ${fg.fgUID}`}
                                label=''
                                type='number'
                                initialValue={fg.fgOverrideQty}
                                getInputValue={(val) =>
                                    updateOrdereQtyProperty(
                                        val,
                                        'fgArr',
                                        index,
                                        'fgOverrideQty'
                                    )
                                }
                                errorMessage={fg.fgOverrideQtyError}></InputDS>
                        </div>
                    </div>
                );
            }

            return (
                <div className='span-2-columns-container' key={index}>
                    <div className='view-table-row-si'>
                        <div className='cell'>
                            <span className='mobile-label m-right-15'>
                                {`${index + 1}.`}
                            </span>
                            <span className='desktop-view m-lr-15'>
                                {`${index + 1}.`}
                            </span>
                            <span className=''> {fg.fgUID}</span>
                        </div>

                        <div className='cell'>
                            <span className='width15'>
                                <span className='mobile-label'>
                                    Delivered Qty:
                                </span>{' '}
                                <span className=''>
                                    {displayReadableNumber(fg.fgDeliveredQty)}
                                </span>
                            </span>
                            <span className='width15'>
                                <span className='mobile-label'>
                                    Ordered Qty:
                                </span>{' '}
                                <span className=''>
                                    {displayReadableNumber(fg.fgOrderedQty)}
                                </span>
                            </span>
                            <span className='width15'>
                                <span className='mobile-label'>
                                    Balance Qty:
                                </span>{' '}
                                <span className=''>
                                    {displayReadableNumber(
                                        saveWithFiveDecimals(
                                            Number(fg.fgBalanceQty) +
                                                Number(fg.fgOverrideQty)
                                        )
                                    )}
                                </span>
                            </span>
                            {override}

                            <span className='width15'>
                                <span className='mobile-label'>Unit:</span>{' '}
                                <span className=''>{fg.fgUnit}</span>
                            </span>

                            <span className='width15'>
                                <span className='mobile-label'>
                                    Unit Price:
                                </span>{' '}
                                <span className=''>
                                    {displayMoney(
                                        fg[priceSetterObj.fgPriceListToUse()]
                                    )}
                                </span>
                            </span>
                            <span className='width15'>
                                <span className='mobile-label'>
                                    Item Total:
                                </span>{' '}
                                <span className=''>
                                    {displayMoney(
                                        saveWithTwoDecimalOnly(
                                            (Number(fg.fgOverrideQty) +
                                                Number(fg.fgOrderedQty)) *
                                                Number(
                                                    fg[
                                                        priceSetterObj.fgPriceListToUse()
                                                    ]
                                                )
                                        )
                                    )}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            );
        });

        sfgArrUI = sdpoObj.sfgArr.map((sfg, index) => {
            let override = (
                <div className='width15'>
                    {' '}
                    <span className='mobile-label'>Override Qty:</span>{' '}
                    <span>{displayReadableNumber(sfg.sfgOverrideQty)}</span>
                </div>
            );

            if (purpose === 'OVERRIDE') {
                override = (
                    <div className='width15'>
                        <span className='mobile-label'>Override Qty:</span>{' '}
                        <div className='m-right-10'>
                            <InputDS
                                keyID={`${index} ${sfg.sfgUID}`}
                                label=''
                                type='number'
                                initialValue={sfg.sfgOverrideQty}
                                getInputValue={(val) =>
                                    updateOrdereQtyProperty(
                                        val,
                                        'sfgArr',
                                        index,
                                        'sfgOverrideQty'
                                    )
                                }
                                errorMessage={
                                    sfg.sfgOverrideQtyError
                                }></InputDS>
                        </div>
                    </div>
                );
            }

            return (
                <div className='span-2-columns-container' key={index}>
                    <div className='view-table-row-si'>
                        <div className='cell'>
                            <span className='mobile-label m-right-15'>
                                {`${index + 1}.`}
                            </span>
                            <span className='desktop-view m-lr-15'>
                                {`${index + 1}.`}
                            </span>
                            <span className=''> {sfg.sfgUID}</span>
                        </div>

                        <div className='cell'>
                            <span className='width15'>
                                <span className='mobile-label'>
                                    Delivered Qty:
                                </span>{' '}
                                <span className=''>
                                    {displayReadableNumber(sfg.sfgDeliveredQty)}
                                </span>
                            </span>
                            <span className='width15'>
                                <span className='mobile-label'>
                                    Ordered Qty:
                                </span>{' '}
                                <span className=''>
                                    {displayReadableNumber(sfg.sfgOrderedQty)}
                                </span>
                            </span>
                            <span className='width15'>
                                <span className='mobile-label'>
                                    Balance Qty:
                                </span>{' '}
                                <span className=''>
                                    {displayReadableNumber(
                                        saveWithFiveDecimals(
                                            Number(sfg.sfgBalanceQty) +
                                                Number(sfg.sfgOverrideQty)
                                        )
                                    )}
                                </span>
                            </span>
                            {override}
                            <span className='width15'>
                                <span className='mobile-label'>Unit:</span>{' '}
                                <span className=''>{sfg.sfgUnit}</span>
                            </span>
                            <span className='width15'>
                                <span className='mobile-label'>
                                    Unit Price:
                                </span>{' '}
                                <span className=''>
                                    {displayMoney(
                                        sfg[priceSetterObj.sfgPriceListToUse()]
                                    )}
                                </span>
                            </span>
                            <span className='width15'>
                                <span className='mobile-label'>
                                    Item Total:
                                </span>{' '}
                                <span className=''>
                                    {' '}
                                    {displayMoney(
                                        saveWithTwoDecimalOnly(
                                            (Number(sfg.sfgOverrideQty) +
                                                Number(sfg.sfgOrderedQty)) *
                                                Number(
                                                    sfg[
                                                        priceSetterObj.sfgPriceListToUse()
                                                    ]
                                                )
                                        )
                                    )}
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            );
        });

        amountBox = (
            <SdpoAmountBox
                priceSetterObj={priceSetterObj}
                sdpoObj={sdpoObj}
                setTaxInState={setTaxInState}
                setDiscountInState={setDiscountInState}
                taxInState={taxInState}
                discountInState={discountInState}
                purpose={purpose}
            />
        );

        let fgListLabel = (
            <div className='view-table-heading-si'>
                <div className='cell'>
                    <span className='m-left-15'>FG Items</span>
                </div>

                <div className='cell'>
                    <span className='width15'>Delivered Qty</span>
                    <span className='width15'>Ordered Qty</span>
                    <span className='width15'>Balance Qty</span>
                    <span className='width15'>Override Qty</span>
                    <span className='width15'>Unit</span>
                    <span className='width15'>Unit Price</span>
                    <span className='width15'>Item Total</span>
                </div>
            </div>
        );

        let sfgListLabel = (
            <div className='view-table-heading-si'>
                <div className='cell'>
                    <span className='m-left-15'>SFG Items</span>
                </div>

                <div className='cell'>
                    <span className='width15'>Delivered Qty</span>
                    <span className='width15'>Ordered Qty</span>
                    <span className='width15'>Balance Qty</span>
                    <span className='width15'>Override Qty</span>
                    <span className='width15'>Unit</span>
                    <span className='width15'>Unit Price</span>
                    <span className='width15'>Item Total</span>
                </div>
            </div>
        );

        if (sfgArrUI.length === 0) {
            sfgListLabel = null;
            sfgSectionLabel = null;
        }
        if (fgArrUI.length === 0) {
            fgListLabel = null;
            fgSectionLabel = null;
        }

        noteUI = (
            <div className='span-2-columns-container'>
                <TextArea
                    label={<span className='parent-labels'>Notes</span>}
                    disabled
                    maxLength={lengths.longTextLength}
                    value={noteInState}
                />
            </div>
        );

        if (
            (sdpoObj.meta.action === userActions.SENT_FOR_APPROVAL ||
                purpose === 'OVERRIDE') &&
            authUser.permissions.includes(permissions.SDPO_APPROVE)
        ) {
            noteUI = (
                <div className='span-2-columns-container'>
                    <TextArea
                        label={<span className='parent-labels'>Notes</span>}
                        maxLength={lengths.noteLength}
                        value={noteInState}
                        onChange={(e) => setNoteInState(e.target.value)}
                        errorMessage={sdpoObj.noteError}
                    />
                </div>
            );
        }

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Sales Order</p>
                    <p className='fpii-text'>
                        {sdpoObj.sdpoUID || `(Auto Generated)`}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Status</p>
                    <p className='fpii-text'>
                        {(sdpoObj.meta && sdpoObj.meta.action) || '---'}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Customer</p>
                    <p className='fpii-text'>{sdpoObj.customerUID || '---'}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Date</p>
                    <p className='fpii-text'>
                        {sdpoObj.sdpoDate
                            ? displayDate(sdpoObj.sdpoDate)
                            : '---'}
                    </p>
                </div>

                {sfgSectionLabel}

                <div className='span-2-columns-container'>
                    {sfgListLabel}
                    {sfgArrUI}
                </div>

                {fgSectionLabel}

                <div className='span-2-columns-container'>
                    {fgListLabel}
                    {fgArrUI}
                </div>

                {amountBox}

                {noteUI}
            </section>
        );
    }

    // =========================================================================
    // Button sections for users with SDPO Request permision ===================

    if (purpose === 'ADD') {
        let createBtnText = 'Create SO';
        let previewBtnText = 'Preview SO';
        if (sdpoObj.isQuote) {
            createBtnText = 'Create Quote';
            previewBtnText = 'Preview Quote';
        }

        let affirmativeBtn = (
            <button className='btn btn-primary' onClick={saveSDPOFn}>
                {createBtnText}
            </button>
        );
        let middleBtn = (
            <button className='m-lr-15 btn' onClick={previewSDPOBtnFn}>
                {previewBtnText}
            </button>
        );
        let negativeBtn = (
            <button className='goto-left btn' onClick={backCancelBtnFn}>
                Cancel
            </button>
        );
        if (preview) {
            affirmativeBtn = (
                <button
                    className='right-button-control btn btn-primary'
                    onClick={saveSDPOFn}>
                    {createBtnText}
                </button>
            );

            middleBtn = null;

            negativeBtn = (
                <button
                    className='goto-left btn'
                    onClick={() => setPreview(false)}>
                    Cancel
                </button>
            );
        }
        buttonSection = (
            <section className='form-button-section'>
                {negativeBtn}
                {middleBtn}
                {affirmativeBtn}
            </section>
        );
    }

    if (purpose === 'EDIT' && sdpoObj.meta.action === userActions.QUOTE) {
        let affirmativeBtn = (
            <button className='btn btn-primary' onClick={saveSDPOFn}>
                Create SO
            </button>
        );
        let middleBtn = (
            <button className='m-lr-15 btn' onClick={previewSDPOBtnFn}>
                Preview SO
            </button>
        );
        let negativeBtn = (
            <button className='goto-left btn' onClick={cancelBtnFromEditViewFn}>
                Cancel
            </button>
        );
        if (sdpoObj.isQuote) {
            affirmativeBtn = (
                <button
                    className='right-button-control btn btn-primary'
                    onClick={saveSDPOFn}>
                    Save Quote
                </button>
            );
            middleBtn = null;
        }

        if (preview) {
            affirmativeBtn = (
                <button
                    className='right-button-control btn btn-primary'
                    onClick={saveSDPOFn}>
                    Create SO
                </button>
            );

            middleBtn = null;

            negativeBtn = (
                <button
                    className='left-button-control btn'
                    onClick={() => setPreview(false)}>
                    Cancel
                </button>
            );
        }

        buttonSection = (
            <section className='form-button-section'>
                {negativeBtn}
                {middleBtn}
                {affirmativeBtn}
            </section>
        );
    } else if (purpose === 'EDIT') {
        buttonSection = (
            <UpdateItemButtons
                saveFn={saveSDPOFn}
                cancelFn={cancelBtnFromEditViewFn}
            />
        );
    }

    if (purpose === 'DELETE') {
        buttonSection = (
            <DeleteItemButtons
                cancelFn={backCancelBtnFn}
                deleteFn={saveSDPOFn}
                confirmeDeleteFn={(e) => setConfirmDeleteText(e.target.value)}
                confirmDeleteTextValue={confirmDeleteText}
                confirmDeleteTextPlaceHolder='Enter SO Number'
                uidValue={sdpoObj.sdpoUID}
            />
        );
    }

    // ==================================================================
    // Button section for users with SDPO Request permission.
    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.SDPO_REQUEST) &&
        !authUser.permissions.includes(permissions.SDPO_APPROVE)
    ) {
        // if document status is SENT_FOR_APPROVAL
        // if doucment status is APPROVED
        if (
            sdpoObj.meta.action === userActions.CREATED ||
            sdpoObj.meta.action === userActions.REJECTED ||
            sdpoObj.meta.action === userActions.UPDATED ||
            sdpoObj.meta.action === userActions.QUOTE
        ) {
            let statusBtn = (
                <button
                    className='m-right-15 btn btn-primary'
                    onClick={() =>
                        statusChangeLocalFn(userActions.SENT_FOR_APPROVAL)
                    }>
                    Send for Approval
                </button>
            );

            if (sdpoObj.meta.action === userActions.QUOTE) statusBtn = null;

            buttonSection = (
                <section className='form-button-section'>
                    <div className='width50'>
                        <button
                            className='goto-left btn'
                            onClick={backCancelBtnFn}>
                            Back
                        </button>
                    </div>

                    <div className='width50 row mobile-m-top-2'>
                        {statusBtn}
                        <button className='btn' onClick={gotoEditViewFn}>
                            Edit
                        </button>
                        <button
                            className='m-left-15 btn btn-danger'
                            onClick={gotoDeleteViewFn}>
                            Delete
                        </button>
                    </div>
                </section>
            );
        }
    }

    // =========================================================================
    // Button section for users with SDPO Approve permision ===================

    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.SDPO_APPROVE) &&
        !authUser.permissions.includes(permissions.SDPO_REQUEST)
    ) {
        // if document status is SENT_FOR_APPROVAL
        if (sdpoObj.meta.action === userActions.SENT_FOR_APPROVAL) {
            buttonSection = (
                <ApproveRejectButtons
                    backBtnFn={backCancelBtnFn}
                    approveBtnFn={() =>
                        statusChangeLocalFn(userActions.APPROVED)
                    }
                    rejectBtnFn={() =>
                        statusChangeLocalFn(userActions.REJECTED)
                    }
                />
            );
        }

        if (
            sdpoObj.meta.action === userActions.OVERRIDDEN ||
            sdpoObj.meta.action === systemActions.FULFILLED ||
            sdpoObj.meta.action === userActions.APPROVED
        ) {
            buttonSection = (
                <OverrideWithBackButtons
                    backBtnFn={backCancelBtnFn}
                    overrideBtnFn={gotoOverRideViewFn}
                />
            );
        }
    }

    // =========================================================================
    //! button section for users with both SDPO Request and Approve permissions.
    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.SDPO_APPROVE) &&
        authUser.permissions.includes(permissions.SDPO_REQUEST)
    ) {
        if (
            sdpoObj.meta.action === userActions.CREATED ||
            sdpoObj.meta.action === userActions.REJECTED ||
            sdpoObj.meta.action === userActions.UPDATED ||
            sdpoObj.meta.action === userActions.QUOTE
        ) {
            let statusBtn = (
                <button
                    className='m-right-15 btn btn-primary'
                    onClick={() =>
                        statusChangeLocalFn(userActions.SENT_FOR_APPROVAL)
                    }>
                    Send for Approval
                </button>
            );

            if (sdpoObj.meta.action === userActions.QUOTE) statusBtn = null;

            buttonSection = (
                <section className='form-button-section'>
                    <div className='width50'>
                        <button
                            className='goto-left btn'
                            onClick={backCancelBtnFn}>
                            Back
                        </button>
                    </div>

                    <div className='width50 row mobile-m-top-2'>
                        {statusBtn}
                        <button className='btn' onClick={gotoEditViewFn}>
                            Edit
                        </button>
                        <button
                            className='m-left-15 btn btn-danger'
                            onClick={gotoDeleteViewFn}>
                            Delete
                        </button>
                    </div>
                </section>
            );
        }

        // if document status is APPROVED
        if (
            sdpoObj.meta.action === userActions.OVERRIDDEN ||
            sdpoObj.meta.action === systemActions.FULFILLED ||
            sdpoObj.meta.action === userActions.APPROVED
        ) {
            buttonSection = (
                <OverrideWithBackButtons
                    backBtnFn={backCancelBtnFn}
                    overrideBtnFn={gotoOverRideViewFn}
                />
            );
        }

        // if document status is SENT_FOR_APPROVAL
        if (sdpoObj.meta.action === userActions.SENT_FOR_APPROVAL) {
            buttonSection = (
                <ApproveRejectButtons
                    backBtnFn={backCancelBtnFn}
                    approveBtnFn={() =>
                        statusChangeLocalFn(userActions.APPROVED)
                    }
                    rejectBtnFn={() =>
                        statusChangeLocalFn(userActions.REJECTED)
                    }
                />
            );
        }
    }

    if (
        purpose === 'OVERRIDE' &&
        authUser.permissions.includes(permissions.SDPO_APPROVE)
    ) {
        buttonSection = (
            <OverrideSaveButtons
                cancelFn={cancelBtnFromEditViewFn}
                saveFn={saveSDPOFn}
            />
        );
    }

    if (!authUser.subscriptionStatusIsActive) {
        buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    }

    let generalErrorUI = (
        <div className='general-error-message'>{generalError}</div>
    );

    return (
        <div className='common-form'>
            <section className='form-title-section'>Sales Order Form</section>

            {mainContent}

            {buttonSection}
            {generalErrorUI}

            <Watermark purpose={purpose} currentDocument={currentDocument} />
        </div>
    );
};
