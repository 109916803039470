import { db, firebase } from '../firebase_config/firebaseConfig';
import { userActions } from '../lib/constants';
import { responseFn, formatCustomErrorObj } from '../lib/util';
import validate from '../validators';
import mListHelper from './mListHelper';
import { docNames, appMessage } from '../lib/messages';
import dbopsGetDocument from './dbopsGetDocument';
import {
    rmInvObjToMListItemFn,
    rmObjToMListItemFn,
} from '../lib/masterListConverters';

import {
    RAW_MATERIALS_COL,
    MASTER_LISTS_COL,
    MASTER_RAW_MATERIALS_LIST_DOC,
    DELETED_RAW_MATERIALS_COL,
    MASTER_INVENTORY_RM_LIST_DOC,
} from '../lib/constants';

// ================================================
// function to fetch each Raw Material document.
const getRawMatFn = async (docID) =>
    dbopsGetDocument(RAW_MATERIALS_COL, docID, docNames.RM);

// ======================================================
const createRawMatFn = async (userCreds, payload) => {
    // add metadata to payload to be created in firestore.
    payload.meta = {
        action: userActions.CREATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // add metaHistory
    payload.metaHistory = [];

    let newRMRef = db.collection(RAW_MATERIALS_COL).doc(payload.rmUID);

    let rmsListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_RAW_MATERIALS_LIST_DOC);

    let inventoryRmListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_INVENTORY_RM_LIST_DOC);

    const transactionFn = async (transaction) => {
        let newRMDoc = await transaction.get(newRMRef);
        let rmsListDoc = await transaction.get(rmsListRef);
        let inventoryRmsListDoc = await transaction.get(inventoryRmListRef);

        // create new supplier document
        if (newRMDoc.exists) {
            return Promise.reject(
                formatCustomErrorObj(1002, appMessage.alreadyExist(docNames.RM))
            );
        }
        await transaction.set(newRMRef, payload);

        // create or update RM list document in (master lists collection)
        if (rmsListDoc.exists) {
            let rmInfoObj = rmObjToMListItemFn(payload);

            let newData = mListHelper.updateMasterList(
                rmsListDoc,
                payload.rmUID,
                rmInfoObj
            );

            await transaction.set(rmsListRef, newData);
        } else {
            let rmInfoObj = rmObjToMListItemFn(payload);

            let newData = mListHelper.addMasterList(payload.rmUID, rmInfoObj);

            await transaction.set(rmsListRef, newData);
        }

        // create or update inventoryRM list document in (master lists collection)
        if (inventoryRmsListDoc.exists) {
            let rmInventoryInfoObj = rmInvObjToMListItemFn(payload);

            let newData = mListHelper.updateMasterList(
                inventoryRmsListDoc,
                payload.rmUID,
                rmInventoryInfoObj
            );

            transaction.set(inventoryRmListRef, newData);
        } else {
            let rmInventoryInfoObj = rmInvObjToMListItemFn(payload);

            let newData = mListHelper.addMasterList(
                payload.rmUID,
                rmInventoryInfoObj
            );

            transaction.set(inventoryRmListRef, newData);
        }

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyCreated(docNames.RM)
        );
    } catch (e) {
        return responseFn(null, true, e, appMessage.errorDeleting(docNames.RM));
    }
};

// ===============================================
const updateRawMatFn = async (userCreds, payload, documentBasis) => {
    // move old metadate into metahistory. ensure meta data history length
    // is 100 or less. will be checked by security rules.
    if (payload.metaHistory.length > 99) payload.metaHistory.shift();

    payload.metaHistory.push(payload.meta);

    // update metadata in payload.
    payload.meta = {
        action: userActions.UPDATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let rmRef = db.collection(RAW_MATERIALS_COL).doc(payload.rmUID);

    let rmsListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_RAW_MATERIALS_LIST_DOC);

    let inventoryRmListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_INVENTORY_RM_LIST_DOC);

    const transactionFn = async (transaction) => {
        let rmDoc = await transaction.get(rmRef);
        let rmsListDoc = await transaction.get(rmsListRef);
        let inventoryRmsListDoc = await transaction.get(inventoryRmListRef);

        if (!rmDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.RM)
                )
            );

        // if there is a change in supplier document from the time
        // it was read prior to editting and now (transaction.get),
        // throw an error.

        if (!validate.noChange(rmDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.RM)
                )
            );
        }

        let rmInfoObj = rmObjToMListItemFn(payload);

        let newData = mListHelper.updateMasterList(
            rmsListDoc,
            payload.rmUID,
            rmInfoObj
        );

        let rmInventoryInfoObj = rmInvObjToMListItemFn(payload);

        let newInventoryData = mListHelper.updateMasterList(
            inventoryRmsListDoc,
            payload.rmUID,
            rmInventoryInfoObj
        );

        await transaction.set(inventoryRmListRef, newInventoryData);

        await transaction.set(rmsListRef, newData);

        await transaction.set(rmRef, payload);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyUpdated(docNames.RM)
        );
    } catch (e) {
        return responseFn(null, true, e, appMessage.errorUpdating(docNames.RM));
    }
};

// ================================================================
const deleteRawMatFn = async (userCreds, payload, documentBasis) => {
    if (payload.metaHistory.length > 99) payload.metaHistory.shift();

    payload.metaHistory.push(payload.meta);

    payload.meta = {
        action: userActions.DELETED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // use rmUID as docuid is ok. see updateRawMatFn
    let rmRef = db.collection(RAW_MATERIALS_COL).doc(payload.rmUID);

    let rmsListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_RAW_MATERIALS_LIST_DOC);

    let inventoryRmsListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_INVENTORY_RM_LIST_DOC);

    let deletedRawMatRef = db.collection(DELETED_RAW_MATERIALS_COL).doc();

    const transactionFn = async (transaction) => {
        let rmDoc = await transaction.get(rmRef);

        if (!rmDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotDeleteBecauseDocNotExist(docNames.RM)
                )
            );

        // if there is a change in supplier document from the time
        // it was read prior to deleting and now (transaction.get),
        // throw an error.
        if (!validate.noChange(rmDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotDeleteBecauseDocChanged(docNames.SFG)
                )
            );
        }

        let inventoryRmListDoc = await transaction.get(inventoryRmsListRef);
        let rmsListDoc = await transaction.get(rmsListRef);
        await transaction.get(deletedRawMatRef);

        let newData = mListHelper.deletePropertyFromMasterList(
            rmsListDoc,
            payload.rmUID
        );

        let newInventoryRmsData = mListHelper.deletePropertyFromMasterList(
            inventoryRmListDoc,
            payload.rmUID
        );

        await transaction.delete(rmRef);
        await transaction.set(inventoryRmsListRef, newInventoryRmsData);
        await transaction.set(rmsListRef, newData);
        await transaction.set(deletedRawMatRef, payload);
        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyDeleted(docNames.RM)
        );
    } catch (e) {
        return responseFn(null, true, e, appMessage.errorDeleting(docNames.RM));
    }
};

export default {
    getRawMatFn,
    createRawMatFn,
    updateRawMatFn,
    deleteRawMatFn,
};
