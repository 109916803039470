import React from 'react';
import './Pagination.css';

export default props => {
    let {
        gotoFirstPageFn,
        gotoPreviousPageFn,
        gotoNextPageFn,
        gotoLastPageFn,
        paginationInfo
    } = props;
    return (
        <section className='pagination-container'>
            <div className='pagination-btns'>
                <i
                    className='ms-Icon ms-Icon--DoubleChevronLeft go-first-page'
                    onClick={gotoFirstPageFn}
                ></i>
                <i
                    className='ms-Icon ms-Icon--ChevronLeft go-previous-page'
                    onClick={gotoPreviousPageFn}
                ></i>
                <span className='pagination-info'>{paginationInfo}</span>
                <i
                    className='ms-Icon ms-Icon--ChevronRight go-next-page'
                    onClick={gotoNextPageFn}
                ></i>
                <i
                    className='ms-Icon ms-Icon--DoubleChevronRight go-last-page'
                    onClick={gotoLastPageFn}
                ></i>
            </div>
        </section>
    );
};
