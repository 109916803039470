import React, { useState, useEffect, useContext } from 'react';
import {
    Input,
    InputDS,
    Datalist,
    AddButton,
    UpdateItemButtons,
    SetupViewButtons,
    BackOnlyButton,
    HelpTip,
    WarningTip,
    Watermark,
} from '../../components';
import tooltips from '../../lib/tooltips';

import { AuthContext, MasterListContext } from '../../contexts';
import { permissions, lengths } from '../../lib/constants';
import { displayMoney, displayReadableNumber } from '../../lib/util';

import validate from '../../validators';

import '../PagesCommon.css';
import './FGs.css';
import '../SemiFGsPage/SemiFGs.css';

const defaultRMObj = {
    rmUID: '',
    rmRequiredQty: '',
    rmUnit: '',
    group: '',
    groupErrorMessage: '',
    errorMessage: '',
    qtyErrorMessage: '',
};

const defaultSFGObj = {
    sfgUID: '',
    sfgRequiredQty: '',
    sfgUnit: '',
    group: '',
    groupErrorMessage: '',
    errorMessage: '',
    qtyErrorMessage: '',
};

export default (props) => {
    let {
        purpose,
        currentDocument,

        backCancelBtnFn,
        gotoEditViewFn,
        gotoDeleteViewFn,

        createFGFn,
        deleteFGFn,
        updateFGFn,
    } = props;

    let { masterRawMaterialsList, masterSemiFGsList } =
        useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);

    let [fgName, setFGName] = useState('');
    let [fgCode, setFGCode] = useState('');
    let [fgUnit, setFGUnit] = useState('');
    let [fgLowThreshold, setFGLowThreshold] = useState('');
    let [fgPrice, setFGPrice] = useState(0);
    let [fgPrice2, setFGPrice2] = useState(0);
    let [fgPrice3, setFGPrice3] = useState(0);
    let [fgPrice4, setFGPrice4] = useState(0);
    let [fgPrice5, setFGPrice5] = useState(0);

    let [rmArr, setRMArr] = useState([]);
    let [sfgArr, setSFGArr] = useState([]);
    let [confirmDeleteText, setConfirmDeleteText] = useState('');
    let [fgActualQty, setFgActualQty] = useState('');

    let [rmUnitMap, setRmUnitMap] = useState({});
    let [sfgUnitMap, setSfgUnitMap] = useState({});

    let [fgNameErr, setFGNameErr] = useState('');
    let [fgCodeErr, setFGCodeErr] = useState('');
    let [fgUnitErr, setFGUnitErr] = useState('');
    let [fgLowThresholdErr, setFGLowThresholdErr] = useState('');
    let [arraysErr, setArraysErr] = useState('');
    let [fgPriceErr, setFGPriceErr] = useState('');
    let [fgPriceErr2, setFGPriceErr2] = useState('');
    let [fgPriceErr3, setFGPriceErr3] = useState('');
    let [fgPriceErr4, setFGPriceErr4] = useState('');
    let [fgPriceErr5, setFGPriceErr5] = useState('');

    let [fgActualQtyErr, setFgActualQtyErr] = useState('');

    useEffect(() => {
        if (purpose !== 'ADD') {
            setFGName(currentDocument.fgName);
            setFGCode(currentDocument.fgCode);
            setFGUnit(currentDocument.fgUnit);
            setFGLowThreshold(currentDocument.fgLowThreshold);
            setRMArr(currentDocument.rmArr);
            setSFGArr(currentDocument.sfgArr);
            setFGPrice(currentDocument.fgPrice);
            setFGPrice2(currentDocument.fgPrice2 || 0);
            setFGPrice3(currentDocument.fgPrice3 || 0);
            setFGPrice4(currentDocument.fgPrice4 || 0);
            setFGPrice5(currentDocument.fgPrice5 || 0);
            setFgActualQty(currentDocument.fgActualQty);
        }
    }, []);

    // create a unit map for rms once only.
    useEffect(() => {
        let unitMap = {};
        masterRawMaterialsList.forEach((rm) => {
            unitMap[rm.rmUID] = rm.rmUnit;
        });

        setRmUnitMap(unitMap);
    }, [masterRawMaterialsList]);

    // create a unit map for sfgs once only.
    useEffect(() => {
        let unitMap = {};
        masterSemiFGsList.forEach((sfg) => {
            unitMap[sfg.sfgUID] = sfg.sfgUnit;
        });

        setSfgUnitMap(unitMap);
    }, [masterSemiFGsList]);

    // =====================================================
    /*
        1. From profile view, when you press edit, it goes to edit view.
        2. If you change something but dont save it, then go back to profile view,
            the changes stays on UI even if actual values are not changed in DB.
        3. Must reset values of (RM/Customer/supplier/sfg Form) before going back to 
            profile view.
    */

    const deleteErrors = () => {
        setFGNameErr('');
        setFGCodeErr('');
        setFGUnitErr('');
        setFGLowThresholdErr('');
        setArraysErr('');
        setFGPriceErr('');
        setFGPriceErr2('');
        setFGPriceErr3('');
        setFGPriceErr4('');
        setFGPriceErr5('');
        setFgActualQtyErr('');
    };

    const cancelBtnFromEditViewFn = () => {
        // 1. reset values.
        setFGName(currentDocument.fgName);
        setFGCode(currentDocument.fgCode);
        setFGUnit(currentDocument.fgUnit);

        // this should still be data type number coming from db.
        setFGLowThreshold(currentDocument.fgLowThreshold);
        setRMArr(currentDocument.rmArr);
        setFGPrice(currentDocument.fgPrice);
        setFGPrice2(currentDocument.fgPrice2);
        setFGPrice3(currentDocument.fgPrice3);
        setFGPrice4(currentDocument.fgPrice4);
        setFGPrice5(currentDocument.fgPrice5);

        setSFGArr(currentDocument.sfgArr);
        setFgActualQty(currentDocument.fgActualQty);

        // 2. reset errorMessages.
        deleteErrors();

        // 3. go back to profile view.
        backCancelBtnFn();
    };

    // =====================================================
    // rmArr functions.

    const updateRMArrFn = (index, propertyName, value) => {
        let rawMatArr = [...rmArr];
        let rmObj = {
            ...rawMatArr[index],
            [propertyName]: value,
        };

        //! get rmUnit
        if (propertyName === 'rmUID') {
            rmObj.rmUnit = rmUnitMap[value];
        }

        //! =========================

        rawMatArr[index] = rmObj;
        setRMArr(rawMatArr);
    };

    const addRMInputFn = () => {
        deleteErrors();
        let rawMatArr = [...rmArr];
        rawMatArr.push(defaultRMObj);
        rawMatArr.forEach((rm) => {
            delete rm.errorMessage;
            delete rm.qtyErrorMessage;
            delete rm.groupErrorMessage;
        });
        setRMArr(rawMatArr);
    };

    const removeRMFn = (index) => {
        deleteErrors();
        let newRMArr = [...rmArr];
        newRMArr.splice(index, 1);
        newRMArr.forEach((rm) => {
            delete rm.errorMessage;
            delete rm.qtyErrorMessage;
            delete rm.groupErrorMessage;
        });
        setRMArr(newRMArr);
    };

    // =======================================================
    // sfgArr functions.
    const updateSFGArrFn = (index, propertyName, value) => {
        let semiFGArr = [...sfgArr];
        let sfgObj = {
            ...semiFGArr[index],
            [propertyName]: value,
        };

        //! get sfgUnit
        if (propertyName === 'sfgUID') {
            sfgObj.sfgUnit = sfgUnitMap[value];
        }

        //! =========================

        semiFGArr[index] = sfgObj;
        setSFGArr(semiFGArr);
    };

    const addSFGInputFn = () => {
        deleteErrors();
        let semiFGArr = [...sfgArr];
        semiFGArr.push(defaultSFGObj);
        semiFGArr.forEach((sfg) => {
            delete sfg.errorMessage;
            delete sfg.qtyErrorMessage;
            delete sfg.groupErrorMessage;
        });
        setSFGArr(semiFGArr);
    };

    const removeSFGFn = (index) => {
        deleteErrors();
        let newSFGArr = [...sfgArr];
        newSFGArr.splice(index, 1);
        newSFGArr.forEach((sfg) => {
            delete sfg.errorMessage;
            delete sfg.qtyErrorMessage;
            delete sfg.groupErrorMessage;
        });
        setSFGArr(newSFGArr);
    };

    // ======================================================

    const saveBtnFn = () => {
        let fgObj = {
            // ensure that meta and metaHistory properties will be added in payload.
            ...currentDocument,
            // overwrite other properties
            fgName: fgName.trim(),
            fgCode: fgCode.trim(),
            fgUnit: fgUnit.trim(),
            //! do not trim. Number type will throw an error.
            fgLowThreshold,
            fgPrice,
            fgPrice2,
            fgPrice3,
            fgPrice4,
            fgPrice5,
            fgActualQty,

            // no need to trim. rmArr is an Array,
            // needs to be cleaned up, remove errorMessages before sending to parent element.
            sfgArr,
            rmArr,
        };

        // check if there is a change in the payload from currentDocument
        if (purpose === 'EDIT' && validate.noChange(fgObj, currentDocument)) {
            backCancelBtnFn();
            return;
        }

        // ========================================================================

        // validate fgObj
        let rmUIDsArr = masterRawMaterialsList.map((item) => item.rmUID);
        let semiFGNamesArr = masterSemiFGsList.map((item) => item.sfgUID);

        // pass the purpose of the form. needed so function will know if schema needs to be
        // completed during "ADD", or just to pass through other properties during
        // 'EDIT' and 'DELETE'.
        let response = validate.finishedGood(
            fgObj,
            rmUIDsArr,
            semiFGNamesArr,
            purpose,
            masterRawMaterialsList,
            masterSemiFGsList,
            currentDocument
        );

        // if response.error... must return so not to call dbops functions.
        if (response.error) {
            let e = response.errorObj;
            setFGNameErr(e.fgNameErr);
            setFGUnitErr(e.fgUnitErr);
            setFGCodeErr(e.fgCodeErr);
            setFGLowThresholdErr(e.fgLowThresholdErr);
            setArraysErr(e.arraysErr);
            setFGPriceErr(e.fgPriceErr);
            setFGPriceErr2(e.fgPriceErr2);
            setFGPriceErr3(e.fgPriceErr3);
            setFGPriceErr4(e.fgPriceErr4);
            setFGPriceErr5(e.fgPriceErr5);
            setFgActualQtyErr(e.fgActualQtyErr);

            // this must run to ensure errormessages in each item in the array
            // will reflect in the ui.
            setRMArr(e.rawMatArr);
            setSFGArr(e.semiFGArr);

            return;
        }

        let fullSchemaFGObj = response.data;

        if (purpose === 'ADD') createFGFn(fullSchemaFGObj);
        if (purpose === 'EDIT') updateFGFn(fullSchemaFGObj, currentDocument);
        if (purpose === 'DELETE') deleteFGFn(fullSchemaFGObj, currentDocument);
    };

    // conditional render of contents  =============================
    let rmArrUI = [];
    let sfgArrUI = [];

    let buttonSection = null;
    let mainContent = null;
    let disableChangingItems = false;

    let waringTipForEditingItems = (
        <span className='m-left-15'>
            <WarningTip content={tooltips.disableEditingItems} />
        </span>
    );

    let uidInput = (
        <Input
            label='FG Name'
            maxLength={lengths.uid}
            value={fgName}
            onChange={(e) => setFGName(e.target.value)}
            placeholder='Required'
            errorMessage={fgNameErr}
        />
    );
    if (purpose === 'EDIT') {
        uidInput = (
            <Input
                label='FG Name'
                value={fgName}
                errorMessage={fgNameErr}
                disabled
            />
        );
    }

    if (purpose === 'EDIT') {
        if (
            currentDocument &&
            (currentDocument.sdpoNumbersArr.length > 0 ||
                currentDocument.assemblyOrderNumbersArr.length > 0)
        ) {
            disableChangingItems = true;
        }
    }

    //! this will disable the feature of locking the rm/sfg list when there are existing
    //! orders for this FG.  Comment this out if feature is needed.
    disableChangingItems = false;
    //! =====================================================================

    if (purpose === 'ADD' || purpose === 'EDIT') {
        // datalist will be capped at 100 options element and will be dynamically
        // updated like the search/filter function.
        let datalistRMOptions = masterRawMaterialsList.map((rm) => rm.rmUID);
        let datalistSFGOptions = masterSemiFGsList.map((sfg) => sfg.sfgUID);

        for (let index = 0; index < rmArr.length; index += 1) {
            let initialValue = rmArr[index] ? rmArr[index].rmUID : '';
            let initialQty = rmArr[index] ? rmArr[index].rmRequiredQty : '';
            let initialGrp = rmArr[index] ? rmArr[index].group : '';

            let rmTrashIcon = (
                <i
                    className='ms-Icon ms-Icon--Delete'
                    aria-hidden='true'
                    onClick={() => removeRMFn(index)}
                ></i>
            );
            if (disableChangingItems) rmTrashIcon = null;

            rmArrUI.push(
                <div className='sfg-datalist-container' key={index}>
                    <div className='flex-row'>
                        <span className='desktop-view m-right-10'>
                            {index + 1}.{' '}
                        </span>
                        <Datalist
                            disabled={disableChangingItems}
                            list={datalistRMOptions}
                            initialValue={initialValue}
                            mobileLabel={`Raw Material ${index + 1}`}
                            getInputValue={
                                disableChangingItems
                                    ? () => {}
                                    : (val) =>
                                          updateRMArrFn(index, 'rmUID', val)
                            }
                            placeholder='Required'
                            errorMessage={rmArr[index].errorMessage}
                        ></Datalist>
                    </div>

                    <div className='required-qty-input'>
                        <div className='flex-row width66'>
                            <div className='width50'>
                                <InputDS
                                    disabled={disableChangingItems}
                                    mobileLabel='Required Qty'
                                    type='number'
                                    initialValue={initialQty}
                                    getInputValue={
                                        disableChangingItems
                                            ? () => {}
                                            : (val) =>
                                                  updateRMArrFn(
                                                      index,
                                                      'rmRequiredQty',
                                                      val
                                                  )
                                    }
                                    placeholder='Required'
                                    errorMessage={rmArr[index].qtyErrorMessage}
                                />
                            </div>
                            <div className='width50 m-left-5 pad-top-10'>
                                {rmArr[index].rmUnit}
                            </div>
                        </div>

                        <div className='width33'>
                            <InputDS
                                disabled={disableChangingItems}
                                mobileLabel='Group'
                                type='number'
                                maxLength={2}
                                initialValue={initialGrp}
                                getInputValue={
                                    disableChangingItems
                                        ? () => {}
                                        : (val) =>
                                              updateRMArrFn(index, 'group', val)
                                }
                                placeholder='Required'
                                errorMessage={rmArr[index].groupErrorMessage}
                            />
                        </div>

                        {rmTrashIcon}
                    </div>
                </div>
            );
        }

        for (let index = 0; index < sfgArr.length; index += 1) {
            let initialSFGValue = sfgArr[index] ? sfgArr[index].sfgUID : '';
            let initialSFGQty = sfgArr[index]
                ? sfgArr[index].sfgRequiredQty
                : '';
            let initialSFGGrp = sfgArr[index] ? sfgArr[index].group : '';

            let sfgTrashIcon = (
                <i
                    className='ms-Icon ms-Icon--Delete'
                    aria-hidden='true'
                    onClick={() => removeSFGFn(index)}
                ></i>
            );

            if (disableChangingItems) sfgTrashIcon = null;

            sfgArrUI.push(
                <div className='sfg-datalist-container' key={index}>
                    <div className='flex-row'>
                        <span className='desktop-view m-right-10'>
                            {index + 1}.{' '}
                        </span>
                        <Datalist
                            disabled={disableChangingItems}
                            list={datalistSFGOptions}
                            initialValue={initialSFGValue}
                            mobileLabel={`Semi FG ${index + 1}`}
                            getInputValue={
                                disableChangingItems
                                    ? () => {}
                                    : (val) =>
                                          updateSFGArrFn(index, 'sfgUID', val)
                            }
                            placeholder='Required'
                            errorMessage={sfgArr[index].errorMessage}
                        ></Datalist>
                    </div>

                    <div className='required-qty-input'>
                        <div className='flex-row width66'>
                            <div className='width50'>
                                <InputDS
                                    disabled={disableChangingItems}
                                    mobileLabel='Required Qty'
                                    type='number'
                                    initialValue={initialSFGQty}
                                    getInputValue={
                                        disableChangingItems
                                            ? () => {}
                                            : (val) =>
                                                  updateSFGArrFn(
                                                      index,
                                                      'sfgRequiredQty',
                                                      val
                                                  )
                                    }
                                    placeholder='Required'
                                    errorMessage={sfgArr[index].qtyErrorMessage}
                                />
                            </div>
                            <div className='width50 m-left-5 pad-top-10'>
                                {sfgArr[index].sfgUnit}
                            </div>
                        </div>

                        <div className='width33'>
                            <InputDS
                                disabled={disableChangingItems}
                                mobileLabel='Group'
                                type='number'
                                maxLength={2}
                                initialValue={initialSFGGrp}
                                getInputValue={
                                    disableChangingItems
                                        ? () => {}
                                        : (val) =>
                                              updateSFGArrFn(
                                                  index,
                                                  'group',
                                                  val
                                              )
                                }
                                placeholder='Required'
                                errorMessage={sfgArr[index].groupErrorMessage}
                            />
                        </div>

                        {sfgTrashIcon}
                    </div>
                </div>
            );
        }

        let showAddRMBtn = true;
        let showAddSFGBtn = true;

        rmArr.forEach((rm) => {
            if (!datalistRMOptions.includes(rm.rmUID) || rm.rmUID === '')
                showAddRMBtn = false;
        });

        sfgArr.forEach((sfg) => {
            if (!datalistSFGOptions.includes(sfg.sfgUID) || sfg.sfgUID === '')
                showAddSFGBtn = false;
        });

        if (rmArr.length >= lengths.fgSetupRmArrLength) showAddRMBtn = false;
        if (sfgArr.length >= lengths.fgSetupSfgArrLength) showAddSFGBtn = false;

        let addRawMatButton = showAddRMBtn ? (
            <AddButton onClick={addRMInputFn} />
        ) : null;

        let addSFGButton = showAddSFGBtn ? (
            <AddButton onClick={addSFGInputFn} />
        ) : null;

        if (disableChangingItems) {
            addRawMatButton = null;
            addSFGButton = null;
        }

        let rmHeading = (
            <div className='span-2-columns-container'>
                <div className='view-table-heading m-zero'>
                    <div className='cell'>
                        <span className='m-left-15'>RM Type - Description</span>
                    </div>

                    <div className='cell'>
                        <span className='width33 m-right-10'>
                            Required Qty
                            <HelpTip content={tooltips.fgSetupRmRequiredQty} />
                        </span>

                        <span className='width33 m-right-10'>Unit</span>

                        <span className='width33'>
                            Group <HelpTip content={tooltips.fgSetupRmGroup} />
                        </span>
                        <span style={{ width: '2.8rem' }}></span>
                    </div>
                </div>
            </div>
        );

        let sfgHeading = (
            <div className='span-2-columns-container'>
                <div className='view-table-heading m-zero'>
                    <div className='cell'>
                        <span className='m-left-15'>SFG Name</span>
                    </div>

                    <div className='cell'>
                        <span className='width33 m-right-10'>
                            Required Qty
                            <HelpTip content={tooltips.fgSetupSfgRequiredQty} />
                        </span>

                        <span className='width33 m-right-10'>Unit</span>

                        <span className='width33'>
                            Group <HelpTip content={tooltips.fgSetupSfgGroup} />
                        </span>
                        <span style={{ width: '2.8rem' }}></span>
                    </div>
                </div>
            </div>
        );

        if (rmArrUI.length === 0) rmHeading = null;
        if (sfgArrUI.length === 0) sfgHeading = null;

        // =================================================================
        // fgActualQty entry. during setup, requester and approver can input
        // fgActualQty without warning.
        // During edit, only admin can edit fgActualQty and with a warning.
        let fgActualQtyInputUI = (
            <div className='flex-row'>
                <div className='width100 text-warning-wrapper'>
                    <Input
                        label='FG Actual Qty'
                        type='number'
                        value={fgActualQty}
                        onChange={(e) => setFgActualQty(e.target.value)}
                        errorMessage={fgActualQtyErr}
                    />
                </div>

                <span className='m-left-15 m-top-20'>
                    <WarningTip content={tooltips.editingActualQty} />
                </span>
            </div>
        );

        if (
            purpose === 'EDIT' &&
            !authUser.permissions.includes(permissions.APP_ADMIN)
        ) {
            fgActualQtyInputUI = null;
        }

        if (purpose === 'ADD') {
            fgActualQtyInputUI = (
                <div className='flex-row'>
                    <div className='width100'>
                        <Input
                            label='FG Actual Qty'
                            type='number'
                            value={fgActualQty}
                            onChange={(e) => setFgActualQty(e.target.value)}
                            errorMessage={fgActualQtyErr}
                        />
                    </div>
                </div>
            );
        }
        // ====================================================

        mainContent = (
            <section className='form-main-section'>
                {uidInput}

                <Input
                    label='FG Code'
                    maxLength={lengths.code}
                    value={fgCode}
                    onChange={(e) => setFGCode(e.target.value)}
                    errorMessage={fgCodeErr}
                />
                <Input
                    label='FG Unit'
                    maxLength={lengths.unit}
                    value={fgUnit}
                    onChange={(e) => setFGUnit(e.target.value)}
                    placeholder='Required'
                    errorMessage={fgUnitErr}
                />
                <Input
                    label='FG Threshold'
                    type='number'
                    value={fgLowThreshold}
                    onChange={(e) => setFGLowThreshold(e.target.value)}
                    placeholder='Required'
                    errorMessage={fgLowThresholdErr}
                />
                <Input
                    label='FG Price 1'
                    type='number'
                    value={fgPrice}
                    onChange={(e) => setFGPrice(e.target.value)}
                    errorMessage={fgPriceErr}
                />
                <Input
                    label='FG Price 2'
                    type='number'
                    value={fgPrice2}
                    onChange={(e) => setFGPrice2(e.target.value)}
                    placeholder='Required'
                    errorMessage={fgPriceErr2}
                />
                <Input
                    label='FG Price 3'
                    type='number'
                    value={fgPrice3}
                    onChange={(e) => setFGPrice3(e.target.value)}
                    placeholder='Required'
                    errorMessage={fgPriceErr3}
                />
                <Input
                    label='FG Price 4'
                    type='number'
                    value={fgPrice4}
                    onChange={(e) => setFGPrice4(e.target.value)}
                    placeholder='Required'
                    errorMessage={fgPriceErr4}
                />
                <Input
                    label='FG Price 5'
                    type='number'
                    value={fgPrice5}
                    onChange={(e) => setFGPrice5(e.target.value)}
                    placeholder='Required'
                    errorMessage={fgPriceErr5}
                />

                {fgActualQtyInputUI}

                <div className='span-2-columns-container label-padding label-highlight height-35'>
                    <label className='parent-labels'>Raw Materials List</label>
                    {addRawMatButton}
                    {disableChangingItems ? (
                        waringTipForEditingItems
                    ) : (
                        <HelpTip content={tooltips.fgSetupRmArr} />
                    )}
                </div>

                {rmHeading}

                <div className='sfg-rm-section'>{rmArrUI}</div>

                <div className='span-2-columns-container label-padding label-highlight m-top-20 height-35'>
                    <label className='parent-labels'>
                        Semi-Finished Goods List
                    </label>
                    {addSFGButton}
                    {disableChangingItems ? (
                        waringTipForEditingItems
                    ) : (
                        <HelpTip content={tooltips.fgSetupSfgArr} />
                    )}
                </div>

                {sfgHeading}

                <div className='sfg-rm-section'>{sfgArrUI}</div>
            </section>
        );
    }

    if (purpose === 'VIEW' || purpose === 'DELETE') {
        rmArr.forEach((rm, index) => {
            rmArrUI.push(
                <div className='span-2-columns-container' key={index}>
                    <div className='view-table-row'>
                        <div className='cell'>
                            <span className='mobile-label m-right-15'>
                                {`${index + 1}.`}
                            </span>
                            <span className='desktop-view m-lr-15'>
                                {`${index + 1}.`}
                            </span>
                            <span className=''> {rm.rmUID}</span>
                        </div>

                        <div className='cell'>
                            <span className='width50'>
                                <span className='mobile-label'>
                                    Required Qty:
                                </span>{' '}
                                <span className=''>{rm.rmRequiredQty}</span>
                                <span className='font12'> {rm.rmUnit}</span>
                            </span>
                            <span className='width50'>
                                <span className='mobile-label'>Group:</span>{' '}
                                <span className=''>{rm.group}</span>
                            </span>
                        </div>
                    </div>
                </div>
            );
        });

        sfgArr.forEach((sfg, index) => {
            sfgArrUI.push(
                <div className='span-2-columns-container' key={index}>
                    <div className='view-table-row'>
                        <div className='cell'>
                            <span className='mobile-label m-right-15'>
                                {`${index + 1}.`}
                            </span>
                            <span className='desktop-view m-lr-15'>
                                {`${index + 1}.`}
                            </span>
                            <span className=''> {sfg.sfgUID}</span>
                        </div>

                        <div className='cell'>
                            <span className='width50'>
                                <span className='mobile-label'>
                                    Required Qty:
                                </span>{' '}
                                <span className=''>{sfg.sfgRequiredQty}</span>
                                <span className='font12'> {sfg.sfgUnit}</span>
                            </span>
                            <span className='width50'>
                                <span className='mobile-label'>Group:</span>{' '}
                                <span className=''>{sfg.group}</span>
                            </span>
                        </div>
                    </div>
                </div>
            );
        });

        let rmSection = (
            <>
                <div className='span-2-columns-container label-padding label-highlight'>
                    <label className='parent-labels'>Raw Materials List</label>
                </div>
                <div className='span-2-columns-container'>
                    <div className='view-table-heading'>
                        <div className='cell'>
                            <span className='m-left-15'>
                                RM Type - Description
                            </span>
                        </div>

                        <div className='cell'>
                            <span className='width50'>Required Qty</span>

                            <span className='width50'>Group</span>
                        </div>
                    </div>

                    {rmArrUI}
                </div>
            </>
        );

        let sfgSection = (
            <>
                <div className='span-2-columns-container label-padding label-highlight'>
                    <label className='parent-labels'>
                        Semi-Finished Goods List
                    </label>
                </div>

                <div className='span-2-columns-container'>
                    <div className='view-table-heading'>
                        <div className='cell'>
                            <span className='m-left-15'>SFG Name</span>
                        </div>

                        <div className='cell'>
                            <span className='width50'>Required Qty</span>

                            <span className='width50'>Group</span>
                        </div>
                    </div>

                    {sfgArrUI}
                </div>
            </>
        );

        if (sfgArrUI.length === 0) sfgSection = null;
        if (rmArrUI.length === 0) rmSection = null;

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='parent-labels'>FG Name</p>
                    <p className=''>{fgName}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='parent-labels'>FG Code</p>
                    <p className=''>{fgCode}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='parent-labels'>FG Unit</p>
                    <p className=''>{fgUnit}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='parent-labels'>FG Low Threshold</p>
                    <p className=''>{displayReadableNumber(fgLowThreshold)}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='parent-labels'>FG Price</p>
                    <p className=''>{displayMoney(fgPrice)}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='parent-labels'>FG Price 2</p>
                    <p className=''>{displayMoney(fgPrice2)}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='parent-labels'>FG Price 3</p>
                    <p className=''>{displayMoney(fgPrice3)}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='parent-labels'>FG Price 4</p>
                    <p className=''>{displayMoney(fgPrice4)}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='parent-labels'>FG Price 5</p>
                    <p className=''>{displayMoney(fgPrice5)}</p>
                </div>

                {rmSection}

                {sfgSection}
            </section>
        );
    }

    // button section
    if (purpose === 'ADD') {
        buttonSection = (
            <UpdateItemButtons saveFn={saveBtnFn} cancelFn={backCancelBtnFn} />
        );
    }

    if (purpose === 'EDIT') {
        buttonSection = (
            <UpdateItemButtons
                saveFn={saveBtnFn}
                cancelFn={cancelBtnFromEditViewFn}
            />
        );
    }

    if (purpose === 'DELETE') {
        let deleteBtn = (
            <button
                className='right-button-control btn btn-danger'
                onClick={saveBtnFn}
            >
                DELETE
            </button>
        );

        if (
            fgName !== confirmDeleteText ||
            (currentDocument &&
                (currentDocument.sdpoNumbersArr.length > 0 ||
                    currentDocument.assemblyOrderNumbersArr.length > 0))
        ) {
            deleteBtn = (
                <button className='right-button-control btn btn-disable'>
                    DELETE
                </button>
            );
        }

        let deleteBtnSection = (
            <>
                <div className='confirm-delete-container'>
                    <input
                        id='delete-input'
                        placeholder={'Enter FG Name'}
                        maxLength={50}
                        value={confirmDeleteText}
                        onChange={(e) => setConfirmDeleteText(e.target.value)}
                    />
                </div>
                {deleteBtn}
            </>
        );

        if (currentDocument && currentDocument.sdpoNumbersArr.length > 0) {
            deleteBtnSection = (
                <div>Existing active SD Purchase Order(s). Cannot Delete</div>
            );
        }
        if (
            currentDocument &&
            currentDocument.assemblyOrderNumbersArr.length > 0
        ) {
            deleteBtnSection = (
                <div>Existing active Assembly Order(s). Cannot Delete</div>
            );
        }

        buttonSection = (
            <section className='form-button-section'>
                <button
                    className='left-button-control btn'
                    onClick={backCancelBtnFn}
                >
                    Cancel
                </button>
                {deleteBtnSection}
            </section>
        );
    }

    if (purpose === 'VIEW') {
        buttonSection = (
            <SetupViewButtons
                backBtnFn={backCancelBtnFn}
                deleteBtnFn={gotoDeleteViewFn}
                editBtnFn={gotoEditViewFn}
            />
        );
    }

    let generalError = <div className='general-error-message'>{arraysErr}</div>;

    if (
        !authUser.permissions.includes(permissions.FG_SETUP) ||
        !authUser.subscriptionStatusIsActive
    ) {
        buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    }

    return (
        <div className='common-form'>
            <section className='form-title-section'>
                Finished Good Profile
            </section>

            {mainContent}

            {buttonSection}
            {generalError}

            <Watermark purpose={purpose} currentDocument={currentDocument} />
        </div>
    );
};
