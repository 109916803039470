import React from 'react';
import './AddButton.css';

export default (props) => {
    let { onClick, disabled } = props;
    let clsName = disabled ? 'add-btn add-btn-disable' : 'add-btn';

    let onClickFn = disabled ? () => {} : onClick;

    return (
        <span className={clsName} onClick={onClickFn}>
            <span className='ab-text'>Add</span>
        </span>
    );
};
