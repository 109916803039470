import React, { useState, useContext } from 'react';
import { dbopsSemiFGs } from '../../firebase_dbops';
import { MasterListContext, AuthContext } from '../../contexts';
import { InputIcon, ListView2 } from '../../components';
import SetupSfgFgItem from '../../components/CollectionItems/SetupSfgFgItem/SetupSfgFgItem';
import { permissions, lengths } from '../../lib/constants';

import '../PagesCommon.css';
import './SemiFGs.css';

import SemiFGsForm from './SemiFGsForm';

const view = {
    LIST_VIEW: 'listView',
    ADD_SFG: 'addSFG',
    EDIT_SFG: 'editSFG',
    DELETE_SFG: 'deleteSFG',
    SFG_PROFILE: 'sfgProfile',
};

export default (props) => {
    let { setIsLoading, setAlertErrorMsg, setAlertMessage } = props;

    let { masterSemiFGsList } = useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);

    let [formView, setFormView] = useState(view.LIST_VIEW);
    let [currentDocument, setCurrentDocument] = useState('');
    let [searchText, setSearchText] = useState('');

    // functions to change views.
    const addSemiFGBtnFn = () => setFormView(view.ADD_SFG);
    const gotoListViewFn = () => setFormView(view.LIST_VIEW);
    const gotoEditViewFn = () => setFormView(view.EDIT_SFG);
    const gotoDeleteViewFn = () => setFormView(view.DELETE_SFG);
    const gotoSFGProfileViewFn = () => setFormView(view.SFG_PROFILE);

    // function to get supplier document.
    const getDocumentFromDBFn = async (docID) => {
        setIsLoading(true);
        let response = await dbopsSemiFGs.getSFGFn(docID);
        if (response.error)
            setAlertErrorMsg(response.errorObj.message || response.message);
        else {
            setCurrentDocument(response.data);
            gotoSFGProfileViewFn();
        }
        setIsLoading(false);
    };

    // function to create Supplier
    const createSFGFn = async (sfgObj) => {
        // spinner on.
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        // write to db.
        let response = await dbopsSemiFGs.createSFGFn(userCreds, sfgObj);
        if (response.error) setAlertErrorMsg(response.errorObj.message);
        else setAlertMessage(response.message);

        gotoListViewFn();

        // spinner off.
        setIsLoading(false);
    };

    // function to update supplier.
    const updateSFGFn = async (sfgObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsSemiFGs.updateSFGFn(
            userCreds,
            sfgObj,
            documentBasis
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);
            // if error, it's because document has already been deleted,
            // go back to list view.
            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
            // if error is because document has since changed, just continue
            // flow of the program.
        } else setAlertMessage(response.message);

        // this get document ensures state in FE state (currentDocument) is updated.
        gotoListViewFn();
        await getDocumentFromDBFn(sfgObj.sfgUID);

        setIsLoading(false);
    };

    // function to delete supplier.
    const deleteSFGFn = async (sfgObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsSemiFGs.deleteSFGFn(
            userCreds,
            sfgObj,
            documentBasis
        );

        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);

            // if error is because document has changed before changes
            // could be applied, get latest version of supplier and
            // go back to supplier profile view.
            if (response.errorObj.code === 1001) {
                gotoListViewFn();
                await getDocumentFromDBFn(sfgObj.sfgUID);
                setIsLoading(false); // redundant
                return;
            }
            // all other errors can continue flow of program.
        } else setAlertMessage(response.message);

        gotoListViewFn();

        setIsLoading(false);
    };

    // filter function =====================================
    let filteredSFGList = masterSemiFGsList.filter((sfg) => {
        return (
            sfg.sfgUID.toUpperCase().includes(searchText.toUpperCase()) ||
            sfg.sfgCode.toUpperCase().includes(searchText.toUpperCase()) ||
            sfg.sfgPrice
                .toString()
                .toUpperCase()
                .includes(searchText.toUpperCase())
        );
    });

    let addSFGBtnUI = (
        <button className='btn btn-primary' onClick={addSemiFGBtnFn}>
            Create Semi-Finished Good
        </button>
    );
    if (
        !authUser.permissions.includes(permissions.SFG_SETUP) ||
        masterSemiFGsList.length >= lengths.masterListLimit ||
        !authUser.subscriptionStatusIsActive
    ) {
        addSFGBtnUI = null;
    }

    let topSectionContent = (
        <section className='top-section'>
            <InputIcon
                type='text'
                placeholder='Search'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                icon={
                    <i
                        className='ms-Icon ms-Icon--Search'
                        aria-hidden='true'
                    ></i>
                }
            />
            {addSFGBtnUI}
        </section>
    );

    let mainSectionContent = (
        <ListView2
            getDocumentFromDBFn={getDocumentFromDBFn}
            list={filteredSFGList}
            keyProperty={'sfgUID'}
            CollectionItemComponent={SetupSfgFgItem}
        />
    );

    if (formView !== view.LIST_VIEW) topSectionContent = null;

    if (formView === view.ADD_SFG) {
        mainSectionContent = (
            <SemiFGsForm
                purpose='ADD'
                backCancelBtnFn={gotoListViewFn}
                createSFGFn={createSFGFn}
            />
        );
    }

    if (formView === view.EDIT_SFG) {
        mainSectionContent = (
            <SemiFGsForm
                purpose='EDIT'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoSFGProfileViewFn}
                updateSFGFn={updateSFGFn}
            />
        );
    }

    if (formView === view.DELETE_SFG) {
        mainSectionContent = (
            <SemiFGsForm
                purpose='DELETE'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoSFGProfileViewFn}
                deleteSFGFn={deleteSFGFn}
            />
        );
    }

    if (formView === view.SFG_PROFILE) {
        mainSectionContent = (
            <SemiFGsForm
                purpose='VIEW'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoListViewFn}
                gotoEditViewFn={gotoEditViewFn}
                gotoDeleteViewFn={gotoDeleteViewFn}
            />
        );
    }

    return (
        <div className='content-layout'>
            {topSectionContent}

            <section className='main-section'>{mainSectionContent}</section>
        </div>
    );
};
