import React, { useState, useRef } from 'react';
import { InputIcon } from '../../components';

export default (props) => {
    let {
        sendPasswordResetFn,
        forgetPasswordErrMsg,
        redirectLinkFn,
        views,
    } = props;

    let [email, setEmail] = useState('');

    let resetBtnRef = useRef();

    const emailID = 'emailID-loginForm';

    const pressEnterFn = (e) => {
        if (e.keyCode === 13) resetBtnRef.current.click();
    };

    return (
        <div className='login-form'>
            <div className='lgf-heading'>Reset Password</div>
            <InputIcon
                id={emailID}
                type='text'
                placeholder='Email'
                icon={
                    <i className='ms-Icon ms-Icon--Mail' aria-hidden='true'></i>
                }
                onKeyDown={pressEnterFn}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
            />

            <div
                className='lgf-btn-ctrl btn btn-primary'
                ref={resetBtnRef}
                onKeyDown={pressEnterFn}
                onClick={() => sendPasswordResetFn(email)}
            >
                Send Password Reset
            </div>

            <div className='password-error-message'>{forgetPasswordErrMsg}</div>

            <div className='redirect-text'>
                Go back to
                <span
                    className='email-sent-redirect-link'
                    id={views.LOGIN}
                    onClick={redirectLinkFn}
                >
                    {' '}
                    Login{' '}
                </span>
                .
            </div>
        </div>
    );
};
