import React from 'react';
import { displayMoney } from '../../../lib/util';
import './SubscriptionInvoiceItem.css';
import '../CollectionItems.css';

export default (props) => {
    let { obj, serialNumber, onClick, heading } = props;

    let serial = 'S/No.';
    // let docID = 'Document ID';
    let invoiceNumber = 'Invoice Number';
    let billingCycle = 'Billing Cycle';
    let price = 'Total';
    let status = 'Status';
    let headingClass = 'text-bold list-table-heading';

    // let docIdLabel = docID + ':';
    let serialLabel = serial + ':';
    let invoiceNumberLable = invoiceNumber + ':';
    let billingCycleLabel = billingCycle + ':';
    let priceLabel = price + ':';
    let statusLabel = status + ':';

    if (!heading) {
        serial = serialNumber;
        // docID = obj.webhookObjUID;
        invoiceNumber = obj.subscriptionInvoiceUID || '--';
        billingCycle = obj.subscriptionInvoiceBillingCycle;
        price = displayMoney(obj.subscriptionInvoicePrice);
        status = obj.invoiceStatus;

        headingClass = '';
    }

    return (
        <div
            className={`setup-sup-cus-item collection-item ${headingClass}`}
            onClick={onClick}
        >
            <div className='collection-item-entry serial'>
                {' '}
                <span className='entry-label'>{serialLabel}</span>
                {serial}
            </div>
            {/* <div className='collection-item-entry inv-doc-id'>
                {' '}
                <span className='entry-label'>{docIdLabel}</span>
                {docID}
            </div> */}
            <div className='collection-item-entry inv-invoice-number'>
                {' '}
                <span className='entry-label'>{invoiceNumberLable}</span>
                {invoiceNumber}
            </div>
            <div className='collection-item-entry inv-billing-cycle'>
                {' '}
                <span className='entry-label'>{billingCycleLabel}</span>
                {billingCycle}
            </div>
            <div className='collection-item-entry inv-price'>
                {' '}
                <span className='entry-label'>{priceLabel}</span>
                {price}
            </div>
            <div className='collection-item-entry inv-status'>
                {' '}
                <span className='entry-label'>{statusLabel}</span>
                {status}
            </div>
        </div>
    );
};
