import {
    responseFn,
    saveWithTwoDecimalOnly,
    saveWithFiveDecimals,
    clone,
} from '../lib/util';
import { userActions, regEx, lengths } from '../lib/constants';
import { generalMessage, appMessage } from '../lib/messages';
// import { sfgObjToMListItemFn } from '../lib/masterListConverters';
import { validateUID } from '../lib/util';

export const sdpo = (
    sdpoObj,
    purpose,
    masterCustomerList,
    currentDocument,
    userActionPassed,
    utilFns = {}
) => {
    // make a copy of the fgArr

    let sdpoObjCopy = {
        ...sdpoObj,
        sdpoUID: sdpoObj.sdpoUID.trim(),
        sdpoNumber: sdpoObj.sdpoUID.trim(),
        sdpoDate: sdpoObj.sdpoDate.trim(),
        fgArr: clone(sdpoObj.fgArr),
        sfgArr: clone(sdpoObj.sfgArr),
    };

    delete sdpoObjCopy.sdpoUIDError;
    delete sdpoObjCopy.customerUIDError;
    delete sdpoObjCopy.sdpoTaxRateError;
    delete sdpoObjCopy.sdpoDiscountRateError;
    delete sdpoObjCopy.itemArrError;
    delete sdpoObjCopy.sdpoDateError;
    delete sdpoObjCopy.noteError;

    // clear all error messages embeded in the objects.
    sdpoObjCopy.fgArr.forEach((fg) => {
        delete fg.fgOrderedQtyError;
        delete fg.fgOverrideQtyError;
    });
    sdpoObjCopy.sfgArr.forEach((sfg) => {
        delete sfg.sfgOrderedQtyError;
        delete sfg.sfgOverrideQtyError;
    });

    let hackError = '';
    let objError = false;

    // validate fgArr length. if this goes over length.  source code was hacked.
    if (sdpoObjCopy.fgArr.length > lengths.sdpoFgArrLength) {
        hackError = appMessage.sourceCodeErrorMessage(
            'SDPO: sfgArr length error.'
        );
    }
    if (sdpoObjCopy.sfgArr.length > lengths.sdpoSfgArrLength) {
        hackError = appMessage.sourceCodeErrorMessage(
            'SDPO: sfgArr length error.'
        );
    }
    if (
        purpose === 'ADD' &&
        !sdpoObjCopy.isQuote &&
        sdpoObjCopy.sdpoUID === ''
    ) {
        objError = true;
        sdpoObjCopy.sdpoUIDError = generalMessage.REQUIRED;
    }
    if (
        purpose === 'EDIT' &&
        !sdpoObjCopy.meta.action === userActions.QUOTE &&
        sdpoObjCopy.sdpoUID !== currentDocument.sdpoUID
    ) {
        hackError = appMessage.sourceCodeErrorMessage('SDPO: sdpoUID error.');
    }

    if (!sdpoObjCopy.isQuote) {
        objError = validateUID(sdpoObjCopy.sdpoUID).error;
        sdpoObjCopy.sdpoUIDError = validateUID(
            sdpoObjCopy.sdpoUID
        ).errorMessage;
    }

    // if (!sdpoObjCopy.isQuote && sdpoObjCopy.sdpoUID === '') {
    //     objError = true;
    //     sdpoObjCopy.sdpoUIDError = generalMessage.REQUIRED;
    // }
    // if (!sdpoObjCopy.isQuote && sdpoObjCopy.sdpoUID.length > lengths.uid) {
    //     objError = true;
    //     sdpoObjCopy.sdpoUIDError = generalMessage.INVALID_LENGTH;
    // }

    if (sdpoObjCopy.sdpoDate === '') {
        objError = true;
        sdpoObjCopy.sdpoDateError = generalMessage.REQUIRED;
    }

    if (!regEx.date.test(sdpoObjCopy.sdpoDate)) {
        objError = true;
        sdpoObjCopy.sdpoDateError = generalMessage.INVALID_DATE;
    }

    if (sdpoObjCopy.note.length > lengths.noteLength) {
        sdpoObjCopy.noteError = generalMessage.INVALID_LENGTH;
    }

    if (
        Number(sdpoObjCopy.sdpoTaxRate) > 100 ||
        Number(sdpoObjCopy.sdpoTaxRate) < 0
    ) {
        objError = true;
        sdpoObjCopy.sdpoTaxRateError = generalMessage.INVALID_RATE;
    }
    if (
        Number(sdpoObjCopy.sdpoDiscountRate) > 100 ||
        Number(sdpoObjCopy.sdpoDiscountRate) < 0
    ) {
        objError = true;
        sdpoObjCopy.sdpoDiscountRateError = generalMessage.INVALID_RATE;
    }

    if (isNaN(sdpoObjCopy.sdpoDiscountRate)) {
        objError = true;
        sdpoObjCopy.sdpoDiscountRateError = generalMessage.INVALID;
    }

    if (isNaN(sdpoObjCopy.sdpoTaxRate)) {
        objError = true;
        sdpoObjCopy.sdpoTaxRateError = generalMessage.INVALID;
    }

    if (sdpoObjCopy.customerUID === '') {
        objError = true;
        sdpoObjCopy.customerUIDError = generalMessage.REQUIRED;
    }

    let customerList = masterCustomerList.map(
        (customer) => customer.customerUID
    );

    if (!customerList.includes(sdpoObjCopy.customerUID)) {
        objError = true;
        sdpoObjCopy.customerUIDError = 'Invalid Customer Name';
    }

    if (sdpoObjCopy.fgArr.length === 0 && sdpoObjCopy.sfgArr.length === 0) {
        objError = true;
        sdpoObjCopy.itemArrError = 'Must include at least one SFG or FG';
    }

    sdpoObjCopy.fgArr.forEach((fg) => {
        if (Number(fg.fgOrderedQty) < 1) {
            objError = true;
            fg.fgOrderedQtyError = generalMessage.GREATER_ZERO;
        }
        if (Number(fg.fgOrderedQty) > lengths.numberInputAmount) {
            objError = true;
            fg.fgOrderedQtyError = generalMessage.INVALID_AMOUNT;
        }
        if (isNaN(fg.fgOrderedQty)) {
            objError = true;
            fg.fgOrderedQtyError = generalMessage.INVALID;
        }

        if (
            saveWithFiveDecimals(Number(fg.fgOverrideQty) + fg.fgBalanceQty) < 0
        ) {
            objError = true;
            fg.fgOverrideQtyError = 'Cannot subtract more than the balance qty';
        }
        if (Number(fg.fgOverrideQty) > lengths.numberInputAmount) {
            objError = true;
            fg.fgOverrideQtyError = generalMessage.INVALID_AMOUNT;
        }
        if (isNaN(fg.fgOverrideQty)) {
            objError = true;
            fg.fgOverrideQtyError = generalMessage.INVALID;
        }
    });
    sdpoObjCopy.sfgArr.forEach((sfg) => {
        if (Number(sfg.sfgOrderedQty) < 1) {
            objError = true;
            sfg.sfgOrderedQtyError = generalMessage.GREATER_ZERO;
        }
        if (Number(sfg.sfgOrderedQty) > lengths.numberInputAmount) {
            objError = true;
            sfg.sfgOrderedQtyError = generalMessage.INVALID_AMOUNT;
        }
        if (isNaN(sfg.sfgOrderedQty)) {
            objError = true;
            sfg.sfgOrderedQtyError = generalMessage.INVALID;
        }

        if (
            saveWithFiveDecimals(
                Number(sfg.sfgOverrideQty) + sfg.sfgBalanceQty
            ) < 0
        ) {
            objError = true;
            sfg.sfgOverrideQtyError =
                'Cannot subtract more than the balance qty';
        }
        if (Number(sfg.sfgOverrideQty) > lengths.numberInputAmount) {
            objError = true;
            sfg.sfgOverrideQtyError = generalMessage.INVALID_AMOUNT;
        }
        if (isNaN(sfg.sfgOverrideQty)) {
            objError = true;
            sfg.sfgOverrideQtyError = generalMessage.INVALID;
        }
    });

    if (hackError || objError) {
        let errorObj = {
            sdpoObj: sdpoObjCopy,
            hackError,
        };
        return responseFn(null, true, errorObj, 'App: Error with sdpo object');
    }

    // if ther is no error

    let validFgArr = sdpoObjCopy.fgArr.map((fg) => {
        let fgBalance = saveWithFiveDecimals(fg.fgOrderedQty);
        if (purpose === 'OVERRIDE')
            fgBalance = saveWithFiveDecimals(fg.fgBalanceQty);

        let fgMapObj = {
            fgUID: fg.fgUID,
            fgPrice: fg.fgPrice,
            fgPrice2: fg.fgPrice2 || 0,
            fgPrice3: fg.fgPrice3 || 0,
            fgPrice4: fg.fgPrice4 || 0,
            fgPrice5: fg.fgPrice5 || 0,
            fgUnit: fg.fgUnit,
            fgOrderedQty: saveWithFiveDecimals(fg.fgOrderedQty),
            fgDeliveredQty: saveWithFiveDecimals(fg.fgDeliveredQty) || 0,
            fgBalanceQty: fgBalance,
            fgOverrideQty: saveWithFiveDecimals(fg.fgOverrideQty) || 0,
        };

        //! this will assign correct price list amount to fgPrice so no
        //! refactoring is needed in sdsi.
        if (userActionPassed && userActionPassed === userActions.APPROVED) {
            fgMapObj.fgPrice = fg[utilFns.fgPriceListToUse()];
        }

        return fgMapObj;
    });

    let validSfgArr = sdpoObjCopy.sfgArr.map((sfg) => {
        let sfgBalance = saveWithFiveDecimals(sfg.sfgOrderedQty);
        if (purpose === 'OVERRIDE')
            sfgBalance = saveWithFiveDecimals(sfg.sfgBalanceQty);

        let sfgMapObj = {
            sfgUID: sfg.sfgUID,
            sfgPrice: sfg.sfgPrice,
            sfgPrice2: sfg.sfgPrice2 || 0,
            sfgPrice3: sfg.sfgPrice3 || 0,
            sfgPrice4: sfg.sfgPrice4 || 0,
            sfgPrice5: sfg.sfgPrice5 || 0,
            sfgUnit: sfg.sfgUnit,
            sfgOrderedQty: saveWithFiveDecimals(sfg.sfgOrderedQty),
            sfgDeliveredQty: saveWithFiveDecimals(sfg.sfgDeliveredQty) || 0,
            sfgBalanceQty: sfgBalance,
            sfgOverrideQty: saveWithFiveDecimals(sfg.sfgOverrideQty) || 0,
        };

        //! this will assign correct price list amount to sfgPrice so no
        //! refactoring is needed in sdsi.
        if (userActionPassed && userActionPassed === userActions.APPROVED) {
            sfgMapObj.sfgPrice = sfg[utilFns.sfgPriceListToUse()];
        }

        return sfgMapObj;
    });

    // if isQuote, empty string the UID
    // This is redundant but just in case.
    if (sdpoObjCopy.isQuote && purpose === 'ADD') {
        sdpoObjCopy.sdpoUID = '';
        sdpoObjCopy.sdpoNumber = '';
    }

    let validSdpo = {
        ...sdpoObjCopy,
        sdpoUID: sdpoObjCopy.sdpoUID,
        sdpoNumber: sdpoObjCopy.sdpoNumber,
        quoteUID: sdpoObjCopy.quoteUID,
        isQuote: sdpoObjCopy.isQuote,
        sdpoDate: sdpoObjCopy.sdpoDate,
        customerUID: sdpoObjCopy.customerUID,
        priceListNum: sdpoObjCopy.priceListNum,
        sdpoTaxRate: saveWithTwoDecimalOnly(sdpoObjCopy.sdpoTaxRate),
        sdpoDiscountRate: saveWithTwoDecimalOnly(sdpoObjCopy.sdpoDiscountRate),
        fgArr: validFgArr,
        sfgArr: validSfgArr,
        note: sdpoObjCopy.note,
    };

    let data = {
        sdpoObj: validSdpo,
    };
    return responseFn(data, false, {}, 'App: Validation successful');
};
