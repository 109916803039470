import React from 'react';
import {
    displayMoney,
    applyDiscount,
    // applyTax,
    saveWithTwoDecimalOnly,
    displayReadableNumber,
} from '../../lib/util';

import '../PagesCommon.css';
import './SDSIsPage.css';

export default (props) => {
    let { sdpoObj, trashIcon, sdpoIndex } = props;

    let itemsTotal = 0;

    let sfgsOfPo = sdpoObj.sfgArr.map((sfg, sfgIndex) => {
        itemsTotal += saveWithTwoDecimalOnly(sfg.sfgOrderedQty * sfg.sfgPrice);

        return (
            <div
                className='span-2-columns-container'
                key={`${sfg.sfgUID} ${sdpoObj.sdpoUID}`}
            >
                <div className='view-table-row'>
                    <div className='cell'>
                        <span className='mobile-label m-right-15'>
                            {`${sfgIndex + 1}.`}
                        </span>
                        <span className='desktop-view m-lr-15'>
                            {`${sfgIndex + 1}.`}
                        </span>{' '}
                        <span>{sfg.sfgUID}</span>
                    </div>

                    <div className='cell'>
                        <div className='width33'>
                            <span className='mobile-label'>Ordered Qty:</span>{' '}
                            <span>
                                {displayReadableNumber(sfg.sfgOrderedQty)}
                            </span>
                        </div>
                        <div className='width33'>
                            <span className='mobile-label'>Cost:</span>{' '}
                            <span>{displayMoney(sfg.sfgPrice)}</span>
                        </div>
                        <div className='width33'>
                            <span className='mobile-label'>Subtotal:</span>{' '}
                            <span>
                                {displayMoney(
                                    saveWithTwoDecimalOnly(
                                        sfg.sfgOrderedQty * sfg.sfgPrice
                                    )
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    });
    let fgsOfPo = sdpoObj.fgArr.map((fg, fgIndex) => {
        itemsTotal += saveWithTwoDecimalOnly(fg.fgOrderedQty * fg.fgPrice);

        return (
            <div
                className='span-2-columns-container'
                key={`${fg.fgUID} ${sdpoObj.sdpoUID}`}
            >
                <div className='view-table-row'>
                    <div className='cell'>
                        <span className='mobile-label m-right-15'>
                            {`${fgIndex + 1}.`}
                        </span>
                        <span className='desktop-view m-lr-15'>
                            {`${fgIndex + 1}.`}
                        </span>{' '}
                        <span>{fg.fgUID}</span>
                    </div>

                    <div className='cell'>
                        <div className='width33'>
                            <span className='mobile-label'>Ordered Qty:</span>{' '}
                            <span>
                                {displayReadableNumber(fg.fgOrderedQty)}
                            </span>
                        </div>
                        <div className='width33'>
                            <span className='mobile-label'>Cost:</span>{' '}
                            <span>{displayMoney(fg.fgPrice)}</span>
                        </div>
                        <div className='width33'>
                            <span className='mobile-label'>Subtotal:</span>{' '}
                            <span>
                                {displayMoney(
                                    saveWithTwoDecimalOnly(
                                        fg.fgOrderedQty * fg.fgPrice
                                    )
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    let sfgSection = (
        <>
            <div className='parent-labels m-top-10 pad-around-5 bg-grey-l2'>
                SFG List
            </div>

            <div className='span-2-columns-container'>
                <div className='view-table-heading'>
                    <div className='cell'>
                        <span className='m-left-15'>SFG Name</span>
                    </div>
                    <div className='cell'>
                        <span className='width33'>Ordered Qty</span>
                        <span className='width33'>Unit Price</span>
                        <span className='width33'>Item Total</span>
                    </div>
                </div>
            </div>
        </>
    );

    let fgSection = (
        <>
            <div className='parent-labels m-top-10 pad-around-5 bg-grey-l2'>
                FG List
            </div>

            <div className='span-2-columns-container'>
                <div className='view-table-heading'>
                    <div className='cell'>
                        <span className='m-left-15'>FG Name</span>
                    </div>
                    <div className='cell'>
                        <span className='width33'>Ordered Qty</span>
                        <span className='width33'>Unit Price</span>
                        <span className='width33'>Item Total</span>
                    </div>
                </div>
            </div>
        </>
    );

    if (fgsOfPo.length === 0) fgSection = null;
    if (sfgsOfPo.length === 0) sfgSection = null;

    let totalAmountWithDiscount = applyDiscount(
        itemsTotal,
        sdpoObj.sdpoDiscountRate
    );
    // let totalAmountWithTax = applyTax(
    //     totalAmountWithDiscount,
    //     sdpoObj.sdpoTaxRate
    // );

    let totalBox = (
        <div className='view-table-row m-top-10'>
            <div className='desktop-view'></div>
            <div className='amount-box'>
                {/* <div>Items total:</div> <div>{displayMoney(itemsTotal)}</div> */}
                <div>{`Subtotal with ${sdpoObj.sdpoDiscountRate} % discount:`}</div>
                <div>{displayMoney(totalAmountWithDiscount)}</div>
                {/* <div>{`Total with ${sdpoObj.sdpoTaxRate} % tax:`}</div>
                <div>{displayMoney(totalAmountWithTax)}</div> */}
            </div>
        </div>
    );

    return (
        <div
            className='span-2-columns-container show-border pad-around-10 m-top-10'
            key={sdpoObj.sdpoUID}
        >
            <div className='span-2-columns-container'>
                <div className='space-between'>
                    <span>
                        <span className='parent-labels'>
                            PO {`${sdpoIndex + 1}:`}
                        </span>{' '}
                        {sdpoObj.sdpoUID}
                    </span>

                    <span className='icon-size'>{trashIcon}</span>
                </div>
            </div>

            {sfgSection}
            {sfgsOfPo}
            {fgSection}
            {fgsOfPo}
            {totalBox}
        </div>
    );
};
