import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts';
import { permissions, lengths } from '../../lib/constants';
import {
    Input,
    TextArea,
    UpdateItemButtons,
    DeleteItemButtons,
    SetupViewButtons,
    BackOnlyButton,
    Watermark,
} from '../../components';
import { getWaterMarkDateTime } from '../../lib/util';

import validate from '../../validators';

import '../PagesCommon.css';
import './SuppliersPage.css';

export default (props) => {
    let {
        purpose,
        backCancelBtnFn,
        createSupplierFn,
        gotoEditViewFn,
        gotoDeleteViewFn,
        currentDocument,
        deleteSupplierFn,
        updateSupplierFn,
    } = props;

    let { authUser } = useContext(AuthContext);

    let [supplierName, setSupplierName] = useState('');
    let [supplierCode, setSupplierCode] = useState('');
    let [contactPerson, setContactPerson] = useState('');
    let [faxNumber, setFaxNumber] = useState('');
    let [contactNumber1, setContactNumber1] = useState('');
    let [contactNumber2, setContactNumber2] = useState('');
    let [address, setAddress] = useState('');
    let [note, setNote] = useState('');
    let [confirmDeleteText, setConfirmDeleteText] = useState('');

    let [supplierNameErr, setSupplierNameErr] = useState('');
    let [contactNumber1Err, setContactNumber1Err] = useState('');
    let [contactNumber2Err, setContactNumber2Err] = useState('');
    let [faxNumberErr, setFaxNumberErr] = useState('');
    let [contactPersonErr, setContactPersonErr] = useState('');
    let [supplierCodeErr, setSupplierCodeErr] = useState('');
    let [addressErr, setAddressErr] = useState('');
    let [noteErr, setNoteErr] = useState('');

    useEffect(() => {
        if (purpose !== 'ADD') {
            setSupplierName(currentDocument.supplierName);
            setSupplierCode(currentDocument.supplierCode);
            setContactPerson(currentDocument.contactPerson);
            setFaxNumber(currentDocument.faxNumber);
            setContactNumber1(currentDocument.contactNumber1);
            setContactNumber2(currentDocument.contactNumber2);
            setAddress(currentDocument.address);
            setNote(currentDocument.note);
        }
    }, []);

    // =====================================================
    // Bug fix - 2020-03-25
    /*
        1. From profile view, when you press edit, it goes to edit view.
        2. If you change something but dont save it, then go back to profile view,
            the changes stays on UI even if actual values are not changed in DB.
        3. Must reset values of (RM/Customer/supplier Form) before going back to 
            profile view.
        4. 2020-03-28 - reset errorMessages also.
    */

    const cancelBtnFromEditViewFn = () => {
        // 1. reset values.
        setSupplierName(currentDocument.supplierName);
        setSupplierCode(currentDocument.supplierCode);
        setContactPerson(currentDocument.contactPerson);
        setFaxNumber(currentDocument.faxNumber);
        setContactNumber1(currentDocument.contactNumber1);
        setContactNumber2(currentDocument.contactNumber2);
        setAddress(currentDocument.address);
        setNote(currentDocument.note);

        // 2. reset errorMessages
        setSupplierNameErr('');
        setSupplierCodeErr('');
        setContactPersonErr('');
        setFaxNumberErr('');
        setContactNumber1Err('');
        setContactNumber2Err('');
        setAddressErr('');
        setNoteErr('');

        // 3. go back to profile view.
        backCancelBtnFn();
    };

    // =====================================================

    const saveBtnFn = () => {
        let supplierObj = {
            // ensure that meta and metaHistory properties will be added in payload.
            ...currentDocument,
            // overwrite other properties
            supplierName: supplierName.trim(),
            supplierCode: supplierCode.trim(),
            contactPerson: contactPerson.trim(),
            faxNumber: faxNumber.trim(),
            contactNumber1: contactNumber1.trim(),
            contactNumber2: contactNumber2.trim(),
            address: address.trim(),
            note: note.trim(),
        };

        // check if there is a change in the payload from currentDocument
        if (
            purpose === 'EDIT' &&
            validate.noChange(supplierObj, currentDocument)
        ) {
            backCancelBtnFn();
            return;
        }

        // validate
        let response = validate.supplier(supplierObj, purpose);
        if (response.error) {
            let e = response.errorObj;
            setSupplierNameErr(e.supplierNameErr);
            setContactNumber1Err(e.contactNumber1Err);
            setContactNumber2Err(e.contactNumber2Err);
            setFaxNumberErr(e.faxNumberErr);
            setContactPersonErr(e.contactPersonErr);
            setSupplierCodeErr(e.supplierCodeErr);
            setAddressErr(e.addressErr);
            setNoteErr(e.noteErr);
            return;
        }

        let completeSupObj = response.data;

        if (purpose === 'ADD') createSupplierFn(completeSupObj);
        if (purpose === 'EDIT')
            updateSupplierFn(completeSupObj, currentDocument);
        if (purpose === 'DELETE')
            deleteSupplierFn(completeSupObj, currentDocument);
    };

    // conditional render of contents  =============================
    let buttonSection = null;
    let mainContent = null;

    let uidInput = (
        <Input
            label='Supplier Name'
            maxLength={lengths.uid}
            value={supplierName}
            onChange={(e) => setSupplierName(e.target.value)}
            placeholder='Required'
            errorMessage={supplierNameErr}
        />
    );
    if (purpose === 'EDIT') {
        uidInput = (
            <Input
                label='Supplier Name'
                disabled
                value={supplierName}
                errorMessage={supplierNameErr}
            />
        );
    }

    if (purpose === 'ADD' || purpose === 'EDIT') {
        mainContent = (
            <section className='form-main-section'>
                {uidInput}
                <Input
                    label='Supplier Code'
                    maxLength={lengths.code}
                    value={supplierCode}
                    onChange={(e) => setSupplierCode(e.target.value)}
                    errorMessage={supplierCodeErr}
                />
                <Input
                    label='Contact Person'
                    maxLength={lengths.contactPerson}
                    value={contactPerson}
                    onChange={(e) => setContactPerson(e.target.value)}
                    errorMessage={contactPersonErr}
                />
                <Input
                    label='Phone Number 1'
                    maxLength={lengths.phone}
                    value={contactNumber1}
                    onChange={(e) => setContactNumber1(e.target.value)}
                    placeholder='Required'
                    errorMessage={contactNumber1Err}
                />
                <Input
                    label='Phone Number 2'
                    maxLength={lengths.phone}
                    value={contactNumber2}
                    onChange={(e) => setContactNumber2(e.target.value)}
                    errorMessage={contactNumber2Err}
                />
                <Input
                    label='Fax Number'
                    maxLength={lengths.phone}
                    value={faxNumber}
                    onChange={(e) => setFaxNumber(e.target.value)}
                    errorMessage={faxNumberErr}
                />
                <TextArea
                    label='Address'
                    maxLength={lengths.regTextLength}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    errorMessage={addressErr}
                />
                <TextArea
                    label='Note'
                    maxLength={lengths.longTextLength}
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    errorMessage={noteErr}
                />
            </section>
        );
    }

    if (purpose === 'VIEW' || purpose === 'DELETE') {
        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Supplier Name</p>
                    <p className='fpii-text'>{supplierName}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Supplier Code</p>
                    <p className='fpii-text'>{supplierCode}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Contact Person</p>
                    <p className='fpii-text'>{contactPerson}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Phone Number 1</p>
                    <p className='fpii-text'>{contactNumber1}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Phone Number 2</p>
                    <p className='fpii-text'>{contactNumber2}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Fax Number</p>
                    <p className='fpii-text'>{faxNumber}</p>
                </div>
                <div className='form-profile-info-items-tall'>
                    <p className='fpii-label'>Address</p>
                    <p className='fpii-text'>{address}</p>
                </div>
                <div className='form-profile-info-items-tall'>
                    <p className='fpii-label'>Note</p>
                    <p className='fpii-text'>{note}</p>
                </div>
            </section>
        );
    }

    if (purpose === 'ADD') {
        buttonSection = (
            <UpdateItemButtons saveFn={saveBtnFn} cancelFn={backCancelBtnFn} />
        );
    }

    if (purpose === 'EDIT') {
        buttonSection = (
            <UpdateItemButtons
                saveFn={saveBtnFn}
                cancelFn={cancelBtnFromEditViewFn}
            />
        );
    }

    if (purpose === 'DELETE') {
        buttonSection = (
            <DeleteItemButtons
                cancelFn={backCancelBtnFn}
                deleteFn={saveBtnFn}
                confirmeDeleteFn={(e) => setConfirmDeleteText(e.target.value)}
                confirmDeleteTextValue={confirmDeleteText}
                confirmDeleteTextPlaceHolder='Enter supplier name'
                uidValue={supplierName}
            />
        );
    }

    if (purpose === 'VIEW') {
        buttonSection = (
            <SetupViewButtons
                backBtnFn={backCancelBtnFn}
                deleteBtnFn={gotoDeleteViewFn}
                editBtnFn={gotoEditViewFn}
            />
        );
    }

    if (
        !authUser.permissions.includes(permissions.SUPPLIER_SETUP) ||
        !authUser.subscriptionStatusIsActive
    ) {
        buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    }

    return (
        <div className='common-form'>
            <section className='form-title-section'>Supplier Profile</section>

            {mainContent}

            {buttonSection}

            <Watermark purpose={purpose} currentDocument={currentDocument} />
        </div>
    );
};
