import React from 'react';

export default (props) => {
    let {
        isPreview,
        createText,
        previewText,
        cancelPreviewFn,
        cancelFn,
        createFn,
        previewFn,
    } = props;

    let affirmativeBtn = (
        <button className='btn btn-primary' onClick={createFn}>
            {createText}
        </button>
    );
    let middleBtn = (
        <button className='m-lr-15 btn' onClick={previewFn}>
            {previewText}
        </button>
    );
    let negativeBtn = (
        <button className='goto-left btn' onClick={cancelFn}>
            Cancel
        </button>
    );
    if (isPreview) {
        affirmativeBtn = (
            <button
                className='right-button-control btn btn-primary'
                onClick={createFn}
            >
                {createText}
            </button>
        );

        middleBtn = null;

        negativeBtn = (
            <button
                className='left-button-control btn'
                onClick={cancelPreviewFn}
            >
                Cancel
            </button>
        );
    }

    let buttonSection = (
        <section className='form-button-section'>
            {negativeBtn}
            {middleBtn}
            {affirmativeBtn}
        </section>
    );

    return buttonSection;
};
