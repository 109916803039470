import React from 'react';
import AssemblyOrderView from './AssemblyOrderView';

import '../PagesCommon.css';
import './AssemblyDeliveredPage.css';

export default (props) => {
    let { assemblyOrderObj, assemblyOrderIndex } = props;

    let listOfAssemblyOrders = assemblyOrderObj.fgArr.map((fgObj, fgIndex) => {
        return (
            <div key={fgObj.fgUID}>
                <AssemblyOrderView
                    assemblyOrderIndex={assemblyOrderIndex}
                    fgObj={fgObj}
                    fgIndex={fgIndex}
                />
            </div>
        );
    });

    return (
        <div className='subform'>
            <div className='subform-label-qtys-section'>
                <div className='label'>
                    {`${assemblyOrderIndex + 1}. ${
                        assemblyOrderObj.assemblyUID
                    }`}
                </div>
            </div>

            <div className='subform-list-section'>
                <div className='sls-label sls-item'>
                    <div className='cell'>FG List </div>
                    <div className='cell desktop-view'></div>
                </div>
                <div className='error-message'>
                    {assemblyOrderObj.fgErrorMessage}
                </div>

                {listOfAssemblyOrders}
            </div>
        </div>
    );
};
