import React from 'react';
import {
    displayMoney,
    saveWithTwoDecimalOnly,
    displayReadableNumber,
} from '../../lib/util';

import '../PagesCommon.css';
import './PDSIsPage.css';

export default (props) => {
    let { poObj, trashIcon, poIndex } = props;

    let rmsOfPo = poObj.rmArr.map((rm, rmIndex) => {
        return (
            <div
                className='span-2-columns-container'
                key={`${rm.rmUID} ${poObj.pdpoUID}`}
            >
                <div className='view-table-row'>
                    <div className='cell'>
                        <span className='mobile-label m-right-15'>
                            {`${rmIndex + 1}.`}
                        </span>
                        <span className='desktop-view m-lr-15'>
                            {`${rmIndex + 1}.`}
                        </span>{' '}
                        <span>{rm.rmUID}</span>
                    </div>

                    <div className='cell'>
                        <div className='width33'>
                            <span className='mobile-label'>Ordered Qty:</span>{' '}
                            <span>
                                {displayReadableNumber(rm.rmOrderedQty)}
                            </span>
                        </div>
                        <div className='width33'>
                            <span className='mobile-label'>Cost:</span>{' '}
                            <span>{displayMoney(rm.rmCost)}</span>
                        </div>
                        <div className='width33'>
                            <span className='mobile-label'>Subtotal:</span>{' '}
                            <span>
                                {displayMoney(
                                    saveWithTwoDecimalOnly(
                                        rm.rmOrderedQty * rm.rmCost
                                    )
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <div
            className='span-2-columns-container show-border pad-around-10 m-top-10'
            key={`${poObj.pdpoUID}`}
        >
            <div className='span-2-columns-container'>
                <div className='space-between'>
                    <span>
                        <span className='parent-labels'>
                            PO {`${poIndex + 1}:`}
                        </span>{' '}
                        {poObj.pdpoUID}
                    </span>

                    <span className='icon-size'>{trashIcon}</span>
                </div>
            </div>

            <div className='parent-labels m-top-10 pad-around-5 bg-grey-l2'>
                RM List
            </div>

            <div className='span-2-columns-container'>
                <div className='view-table-heading'>
                    <div className='cell'>
                        <span className='m-left-15'>RM Type - Description</span>
                    </div>
                    <div className='cell'>
                        <span className='width33'>Ordered Qty</span>
                        <span className='width33'>Cost</span>
                        <span className='width33'>Subtotal</span>
                    </div>
                </div>
            </div>

            {rmsOfPo}
        </div>
    );
};
