import React, { useState, useEffect, useContext } from 'react';
import {
    Datalist,
    AddButton,
    Input,
    CreateItemButtons,
    UpdateItemButtons,
    DeleteItemButtons,
    BackOnlyButton,
    BackSendEditDelButtons,
    ApproveRejectButtons,
    HelpTip,
    TextArea,
    Watermark,
} from '../../components';
import {
    AuthContext,
    MasterListContext,
    DataShareContext,
} from '../../contexts';
import { dbopsProdOrders } from '../../firebase_dbops';
import { permissions, userActions, lengths } from '../../lib/constants';
import { clone, displayDate, saveWithFiveDecimals } from '../../lib/util';

import AddedProdOrdersView from './AddedProdOrdersView';
import AddedProdOrder from './AddedProdOrder';

import validate from '../../validators';

import '../PagesCommon.css';
import './ProductionDeliveredPage.css';
import tooltips from '../../lib/tooltips';

let defaultProductionDelivered = {
    productionDeliveredUID: '',
    productionDeliveredNumber: '',
    prodOrderArr: [],
    meta: { action: '' },
    note: '',
};

const cloneProductionDeliveredObj = (productionDeliveredObj) => {
    return {
        ...productionDeliveredObj,
        prodOrderArr: clone(productionDeliveredObj.prodOrderArr),
    };
};

export default (props) => {
    let {
        purpose,
        currentDocument,

        backCancelBtnFn,
        gotoEditViewFn,
        gotoDeleteViewFn,

        createProductionDeliveredFn,
        deleteProductionDeliveredFn,
        updateProductionDeliveredFn,
        statusChangeFn,

        setIsLoading,
        setAlertErrorMsg,
    } = props;

    let { masterProdOrdersList } = useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);
    let { serverDate } = useContext(DataShareContext);

    let [generalError, setGeneralError] = useState('');
    let [datalistDisplayResetCounter, setDatalistDisplayResetCounter] =
        useState(1);

    let [productionDeliveredObj, setProductionDeliveredObj] = useState(
        defaultProductionDelivered
    );
    let [productionDeliveredUIDinState, setProductionDeliveredUIDinState] =
        useState('');
    let [productionOrderUIDinState, setProductionOrderUIDinState] =
        useState('');
    let [productionDeliveredDateInState, setProductionDeliveredDateInState] =
        useState(serverDate);

    let [noteInState, setNoteInState] = useState('');
    let [description, setDescription] = useState('');

    let [addedProdOrderError, setAddedProdOrderError] = useState('');

    let [preview, setPreview] = useState(false);
    let [confirmDeleteText, setConfirmDeleteText] = useState('');

    const getProdOrdersFn = async () => {
        setIsLoading(true);
        let tempProdOrderArr = currentDocument.prodOrderArr.map(
            async (prodOrder) => {
                return await dbopsProdOrders.getProdOrderFn(
                    prodOrder.productionUID
                );
            }
        );
        try {
            tempProdOrderArr = await Promise.all(tempProdOrderArr);
        } catch (e) {
            console.error(
                'Production - Orders have been Cleaned up by system already. Production Delivered was never APPROVED. Edge case.'
            );
            setAlertErrorMsg(e.message || 'Error getting document');
            setIsLoading(true);
            return;
        }
        tempProdOrderArr = tempProdOrderArr.map(
            (dbprodOrderObj) => dbprodOrderObj.data
        );

        // setCurrentProdOrderDocsArr(tempProdOrderArr);

        let productionDeliveredCopy = {
            ...currentDocument,
            prodOrderArr: clone(currentDocument.prodOrderArr),
        };

        productionDeliveredCopy.prodOrderArr.forEach((prodOrder, poIndex) => {
            prodOrder.sfgArr.forEach((sfg, sfgIndex) => {
                sfg.sfgOrderedQty =
                    tempProdOrderArr[poIndex].sfgArr[sfgIndex].sfgOrderedQty;
                sfg.sfgBalanceQty =
                    tempProdOrderArr[poIndex].sfgArr[sfgIndex].sfgBalanceQty;
                sfg.sfgOverrideQty =
                    tempProdOrderArr[poIndex].sfgArr[sfgIndex].sfgOverrideQty;

                // Each sfg has an rmArr.
                sfg.rmArr.forEach((rm, rmIndex) => {
                    rm.rmProjectedUseQty =
                        tempProdOrderArr[poIndex].sfgArr[sfgIndex].rmArr[
                            rmIndex
                        ].rmProjectedUseQty;
                    rm.rmBalanceQty =
                        tempProdOrderArr[poIndex].sfgArr[sfgIndex].rmArr[
                            rmIndex
                        ].rmBalanceQty;
                    rm.rmOverrideQty =
                        tempProdOrderArr[poIndex].sfgArr[sfgIndex].rmArr[
                            rmIndex
                        ].rmOverrideQty;
                });
            });
        });
        setProductionDeliveredObj(productionDeliveredCopy);
        setProductionDeliveredUIDinState(
            productionDeliveredCopy.productionDeliveredUID
        );
        setIsLoading(false);

        //! return tempProdOrderArr as the currentProdOrderArr to
        //! be used for APPROVED status change.
        return tempProdOrderArr;
    };

    // set productionDeliveredObj from currentDocument if purpose !== 'ADD'
    useEffect(() => {
        if (purpose === 'VIEW' || purpose === 'DELETE') {
            // making a copy so no side effect will change currentDocument.
            let currentDocumentCopy = {
                // will copy meta and metaHistory as is.
                ...currentDocument,
                // copy prodOrderArr and not a reference.
                prodOrderArr: clone(currentDocument.prodOrderArr),
            };
            setProductionDeliveredDateInState(
                currentDocumentCopy.productionDeliveredDate
            );
            setProductionDeliveredObj(currentDocumentCopy);
            setNoteInState(currentDocumentCopy.note || '');
            setDescription(
                currentDocumentCopy.productionDeliveredDescription || ''
            );
        }

        if (purpose === 'EDIT') getProdOrdersFn();
    }, [purpose]);

    const cancelBtnFromEditViewFn = () => {
        // 1. reset values.
        setProductionDeliveredObj(currentDocument);

        // 2. reset errorMessages.
        setGeneralError('');

        // 3. go back to profile view.
        backCancelBtnFn();
    };

    // =====================================================

    const deleteErrors = () => {
        delete productionDeliveredObj.prodOrderArrError;
        delete productionDeliveredObj.productionDeliveredDateError;
        delete productionDeliveredObj.noteError;
        delete productionDeliveredObj.productionDeliveredDescriptionError;

        productionDeliveredObj.prodOrderArr.forEach((prodOrder) => {
            prodOrder.sfgArr.forEach((sfg) => {
                delete sfg.sfgProducedQtyForThisProductionError;

                sfg.rmArr.forEach((rm) => {
                    delete rm.rmUsedQtyForThisSfgProducedError;
                });
            });
        });

        setGeneralError('');
    };

    const addProdOderToArrFn = async () => {
        deleteErrors();

        let productionDeliveredCopy = cloneProductionDeliveredObj(
            productionDeliveredObj
        );

        // get the productionOrder from firebase
        setIsLoading(true);

        let response = await dbopsProdOrders.getProdOrderFn(
            productionOrderUIDinState
        );
        if (response.error) {
            setAddedProdOrderError(
                response.errorObj.message || response.message
            );
            setIsLoading(false);
            return;
        }

        let prodOrderCopy = {
            ...response.data,
            sfgArr: clone(response.data.sfgArr),
        };

        prodOrderCopy.sfgArr.forEach((sfg) => {
            sfg.sfgProducedQtyForThisProduction = 0;

            sfg.rmArr.forEach((rm) => {
                rm.rmUsedQtyForThisSfgProduced = 0;
            });
        });

        productionDeliveredCopy.prodOrderArr.push(prodOrderCopy);

        setProductionDeliveredObj(productionDeliveredCopy);
        setAddedProdOrderError('');
        setDatalistDisplayResetCounter(datalistDisplayResetCounter + 1);
        setIsLoading(false);
    };

    const removeProdOrderFromArrFn = (prodOrderIndex) => {
        deleteErrors();
        let productionDeliveredCopy = cloneProductionDeliveredObj(
            productionDeliveredObj
        );

        productionDeliveredCopy.prodOrderArr.splice(prodOrderIndex, 1);
        setProductionDeliveredObj(productionDeliveredCopy);
        setAddedProdOrderError('');
    };

    const updateSfgProducedQtyFn = (val, prodOrderIndex, sfgIndex) => {
        let productionDeliveredCopy = cloneProductionDeliveredObj(
            productionDeliveredObj
        );

        productionDeliveredCopy.prodOrderArr[prodOrderIndex].sfgArr[
            sfgIndex
        ].sfgProducedQtyForThisProduction = val;

        //!  default value for rms that are alone in their group.
        let rmAloneMap = {};

        let ordered =
            productionDeliveredCopy.prodOrderArr[prodOrderIndex].sfgArr[
                sfgIndex
            ].sfgOrderedQty;

        productionDeliveredCopy.prodOrderArr[prodOrderIndex].sfgArr[
            sfgIndex
        ].rmArr.forEach((rm) => {
            rmAloneMap[rm.group] =
                rmAloneMap[rm.group] !== undefined ? false : true;
        });
        productionDeliveredCopy.prodOrderArr[prodOrderIndex].sfgArr[
            sfgIndex
        ].rmArr.forEach((rm) => {
            if (rmAloneMap[rm.group])
                rm.rmUsedQtyForThisSfgProduced = saveWithFiveDecimals(
                    (val / ordered) * rm.rmProjectedUseQty
                );
        });
        //! =====================================================

        setProductionDeliveredObj(productionDeliveredCopy);
    };

    const updateRmActualUsedQtyFn = (
        val,
        prodOrderIndex,
        sfgIndex,
        rmIndex
    ) => {
        let productionDeliveredCopy = cloneProductionDeliveredObj(
            productionDeliveredObj
        );

        productionDeliveredCopy.prodOrderArr[prodOrderIndex].sfgArr[
            sfgIndex
        ].rmArr[rmIndex].rmUsedQtyForThisSfgProduced = val;

        setProductionDeliveredObj(productionDeliveredCopy);
    };

    // for saving functionality
    const previewSfgProductionBtnFn = () => {
        let productionDeliveredCopy = cloneProductionDeliveredObj(
            productionDeliveredObj
        );
        productionDeliveredCopy.productionDeliveredDate =
            productionDeliveredDateInState;
        productionDeliveredCopy.productionDeliveredUID =
            productionDeliveredCopy.productionDeliveredUID ||
            productionDeliveredUIDinState;
        productionDeliveredCopy.note = noteInState;
        productionDeliveredCopy.productionDeliveredDescription = description;

        let response = validate.productionDelivered(productionDeliveredCopy);
        if (response.error) {
            setProductionDeliveredObj(response.errorObj.productionDeliveredObj);
            setAlertErrorMsg(response.errorObj.hackError);
            setGeneralError(response.errorObj.generalError);
        } else {
            // no error
            setProductionDeliveredObj(response.data.productionDeliveredObj);
            setPreview(true);
        }
    };

    const saveSfgProductionFn = () => {
        let productionDeliveredCopy = cloneProductionDeliveredObj(
            productionDeliveredObj
        );
        productionDeliveredCopy.productionDeliveredDate =
            productionDeliveredDateInState;
        productionDeliveredCopy.productionDeliveredUID =
            productionDeliveredCopy.productionDeliveredUID ||
            productionDeliveredUIDinState;
        productionDeliveredCopy.note = noteInState;
        productionDeliveredCopy.productionDeliveredDescription = description;

        // check if there is a change in the payload from currentDocument
        if (
            purpose === 'EDIT' &&
            validate.noChange(productionDeliveredCopy, currentDocument)
        ) {
            backCancelBtnFn();
            return;
        }

        let response = validate.productionDelivered(productionDeliveredCopy);
        if (response.error) {
            setProductionDeliveredObj(response.errorObj.productionDeliveredObj);
            setAlertErrorMsg(response.errorObj.hackError);
            setGeneralError(response.errorObj.generalError);
            return;
        }

        //! this will ensure no tempRmIndex or tempSfgIndex that the app puts into
        //! the productionDeliveredObj to display correctly.
        let validObj = response.data.validProductionDeliveredObj;
        setProductionDeliveredObj(response.data.productionDeliveredObj);

        if (purpose === 'ADD') createProductionDeliveredFn(validObj);
        if (purpose === 'EDIT')
            updateProductionDeliveredFn(validObj, currentDocument);
        if (purpose === 'DELETE')
            deleteProductionDeliveredFn(validObj, currentDocument);
    };

    const statusChangeLocalFn = async (userActionPassed) => {
        let productionDeliveredCopy = cloneProductionDeliveredObj(
            productionDeliveredObj
        );
        productionDeliveredCopy.productionDeliveredDate =
            productionDeliveredDateInState;
        productionDeliveredCopy.productionDeliveredUID =
            productionDeliveredCopy.productionDeliveredUID ||
            productionDeliveredUIDinState;
        productionDeliveredCopy.note = noteInState;
        productionDeliveredCopy.productionDeliveredDescription = description;

        let response = validate.productionDelivered(productionDeliveredCopy);
        if (response.error) {
            setProductionDeliveredObj(response.errorObj.productionDeliveredObj);
            setAlertErrorMsg(response.errorObj.hackError);
            setGeneralError(response.errorObj.generalError);
            return;
        }

        let validObj = response.data.validProductionDeliveredObj;

        if (userActionPassed === userActions.APPROVED) {
            let documentBasisProdOrderArr = await getProdOrdersFn();
            statusChangeFn(
                userActionPassed,
                validObj,
                currentDocument,
                documentBasisProdOrderArr
            );
        } else {
            statusChangeFn(userActionPassed, validObj, currentDocument);
        }
    };

    // conditional render of contents  =============================

    let prodOrderArrUI = [];
    let noteUI = null;

    let buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    let mainContent = null;
    let datalist = null;

    productionDeliveredObj = {
        ...productionDeliveredObj,
        prodOrderArr: clone(productionDeliveredObj.prodOrderArr),
    };

    let addBtn = <AddButton disabled />;

    let productionDeliveredDateDisplayUI = (
        <div className='form-profile-info-items'>
            <p className='fpii-label'>Production Date</p>
            <p className='fpii-text'>
                {productionDeliveredObj.productionDeliveredDate
                    ? displayDate(
                          productionDeliveredObj.productionDeliveredDate
                      )
                    : '---'}
            </p>
        </div>
    );

    let productionDeliveredDateInputUI = (
        <div className='form-profile-info-items'>
            <p className='fpii-label'>Production Date </p>
            <Input
                type='date'
                maxLength='12'
                onChange={(e) =>
                    setProductionDeliveredDateInState(e.target.value)
                }
                value={productionDeliveredDateInState}
                errorMessage={
                    productionDeliveredObj.productionDeliveredDateError
                }></Input>
        </div>
    );

    let prodOrderListLabel = (
        <div className='span-2-columns-container label-padding label-highlight'>
            <label className='parent-labels'>SFG Job Order List</label>
        </div>
    );

    if ((purpose === 'ADD' && !preview) || purpose === 'EDIT') {
        let datalistOptions = masterProdOrdersList
            .filter((prodOrder) => {
                return (
                    prodOrder.status === userActions.APPROVED ||
                    prodOrder.status === userActions.OVERRIDDEN
                );
            })
            .map((prodOrder) => prodOrder.productionUID);
        let prodOrderAlreadyInTheUI = productionDeliveredObj.prodOrderArr.map(
            (prodOrder) => prodOrder.productionUID
        );

        if (
            datalistOptions.includes(productionOrderUIDinState) &&
            !prodOrderAlreadyInTheUI.includes(productionOrderUIDinState)
        )
            addBtn = <AddButton onClick={addProdOderToArrFn} />;

        let datalistLabel = (
            <span>
                Add Production - Order
                <HelpTip content={tooltips.productionDeliveredArr}></HelpTip>
            </span>
        );

        datalist = (
            <div className='get-item-from-db-container'>
                <div className='datalist-with-addbtn-container'>
                    <Datalist
                        label={datalistLabel}
                        list={datalistOptions}
                        initialValue={''}
                        getInputValue={(val) =>
                            setProductionOrderUIDinState(val)
                        }
                        errorMessage={addedProdOrderError}
                        resetCounter={datalistDisplayResetCounter}></Datalist>
                    {addBtn}
                </div>
            </div>
        );

        for (
            let index = 0;
            index < productionDeliveredObj.prodOrderArr.length;
            index += 1
        ) {
            let trashIcon = (
                <i
                    className='ms-Icon ms-Icon--Delete clickable-icon'
                    aria-hidden='true'
                    onClick={() => removeProdOrderFromArrFn(index)}></i>
            );

            prodOrderArrUI.push(
                <div className='span-2-columns-container' key={index}>
                    <AddedProdOrder
                        trashIcon={trashIcon}
                        prodOrderIndex={index}
                        prodOrderObj={
                            productionDeliveredObj.prodOrderArr[index]
                        }
                        updateSfgProducedQtyFn={updateSfgProducedQtyFn}
                        updateRmActualUsedQtyFn={updateRmActualUsedQtyFn}
                    />
                </div>
            );
        }

        if (prodOrderArrUI.length === 0) prodOrderListLabel = null;

        noteUI = (
            <div className='span-2-columns-container'>
                <TextArea
                    label={<span className='parent-labels'>Notes</span>}
                    maxLength={lengths.noteLength}
                    value={noteInState}
                    onChange={(e) => setNoteInState(e.target.value)}
                    errorMessage={productionDeliveredObj.noteError}
                />
            </div>
        );

        //! Max number of items in the list
        if (
            productionDeliveredObj.prodOrderArr.length >=
            lengths.productionDeliveredArr
        )
            datalist = null;

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Production Number</p>
                    <p className='fpii-text'>
                        {productionDeliveredObj.productionDeliveredUID ||
                            `(Auto Generated)`}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Status</p>
                    <p className='fpii-text'>
                        {(productionDeliveredObj.meta &&
                            productionDeliveredObj.meta.action) ||
                            '---'}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Description</p>
                    <Input
                        type='text'
                        maxLength={lengths.description}
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                        errorMessage={
                            productionDeliveredObj.productionDeliveredDescriptionError
                        }></Input>
                </div>

                {productionDeliveredDateInputUI}

                {prodOrderListLabel}

                <div className='span-2-columns-container'>{prodOrderArrUI}</div>

                {datalist}

                {noteUI}
            </section>
        );
    }

    if (
        (purpose === 'ADD' && preview) ||
        purpose === 'VIEW' ||
        purpose === 'DELETE'
    ) {
        prodOrderArrUI = productionDeliveredObj.prodOrderArr.map(
            (prodOrder, index) => {
                return (
                    <div
                        className='span-2-columns-container'
                        key={prodOrder.productionUID}>
                        <AddedProdOrdersView
                            prodOrderObj={prodOrder}
                            prodOrderIndex={index}
                        />
                    </div>
                );
            }
        );

        noteUI = (
            <div className='span-2-columns-container'>
                <TextArea
                    label={<span className='parent-labels'>Notes</span>}
                    disabled
                    maxLength={lengths.longTextLength}
                    value={noteInState}
                />
            </div>
        );

        if (
            productionDeliveredObj.meta.action ===
                userActions.SENT_FOR_APPROVAL &&
            authUser.permissions.includes(
                permissions.PRODUCTION_DELIVERED_APPROVE
            )
        ) {
            noteUI = (
                <div className='span-2-columns-container'>
                    <TextArea
                        label={<span className='parent-labels'>Notes</span>}
                        maxLength={lengths.noteLength}
                        value={noteInState}
                        onChange={(e) => setNoteInState(e.target.value)}
                        errorMessage={productionDeliveredObj.noteError}
                    />
                </div>
            );
        }

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Production Number</p>
                    <p className='fpii-text'>
                        {productionDeliveredObj.productionDeliveredUID ||
                            `(Auto Generated)`}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Status</p>
                    <p className='fpii-text'>
                        {(productionDeliveredObj.meta &&
                            productionDeliveredObj.meta.action) ||
                            '---'}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Description</p>
                    <p className='fpii-text'>
                        {productionDeliveredObj.productionDeliveredDescription ||
                            ''}
                    </p>
                </div>

                {productionDeliveredDateDisplayUI}

                {prodOrderListLabel}

                <div className='span-2-columns-container'>{prodOrderArrUI}</div>

                {noteUI}
            </section>
        );
    }

    // =========================================================================
    // Button sections for users with Request permision ===================

    if (purpose === 'ADD') {
        buttonSection = (
            <CreateItemButtons
                createText='Create'
                previewText='Preview'
                cancelPreviewFn={() => setPreview(false)}
                cancelFn={backCancelBtnFn}
                createFn={saveSfgProductionFn}
                previewFn={previewSfgProductionBtnFn}
                isPreview={preview}
            />
        );
    }

    if (purpose === 'EDIT') {
        buttonSection = (
            <UpdateItemButtons
                saveFn={saveSfgProductionFn}
                cancelFn={cancelBtnFromEditViewFn}
            />
        );
    }

    if (purpose === 'DELETE') {
        buttonSection = (
            <DeleteItemButtons
                cancelFn={backCancelBtnFn}
                deleteFn={saveSfgProductionFn}
                confirmeDeleteFn={(e) => setConfirmDeleteText(e.target.value)}
                confirmDeleteTextValue={confirmDeleteText}
                confirmDeleteTextPlaceHolder='Enter Production Delivered Number'
                uidValue={productionDeliveredObj.productionDeliveredUID}
            />
        );
    }

    // ==================================================================
    // Button section for users with Request permission.
    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(
            permissions.PRODUCTION_DELIVERED_REQUEST
        ) &&
        !authUser.permissions.includes(permissions.PRODUCTION_DELIVERED_APPROVE)
    ) {
        if (
            productionDeliveredObj.meta.action === userActions.CREATED ||
            productionDeliveredObj.meta.action === userActions.UPDATED ||
            productionDeliveredObj.meta.action === userActions.REJECTED
        ) {
            buttonSection = (
                <BackSendEditDelButtons
                    backBtnFn={backCancelBtnFn}
                    sendForApprovalBtnFn={() =>
                        statusChangeLocalFn(userActions.SENT_FOR_APPROVAL)
                    }
                    editBtnFn={gotoEditViewFn}
                    deleteBtnFn={gotoDeleteViewFn}
                />
            );
        }
    }

    // =========================================================================
    // Button section for users with Approve permision ===================

    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(
            permissions.PRODUCTION_DELIVERED_APPROVE
        ) &&
        !authUser.permissions.includes(permissions.PRODUCTION_DELIVERED_REQUEST)
    ) {
        if (
            productionDeliveredObj.meta.action === userActions.SENT_FOR_APPROVAL
        ) {
            buttonSection = (
                <ApproveRejectButtons
                    backBtnFn={backCancelBtnFn}
                    approveBtnFn={() =>
                        statusChangeLocalFn(userActions.APPROVED)
                    }
                    rejectBtnFn={() =>
                        statusChangeLocalFn(userActions.REJECTED)
                    }
                />
            );
        }
    }

    // =========================================================================
    //! button section for users with both Request and Approve permissions.
    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(
            permissions.PRODUCTION_DELIVERED_APPROVE
        ) &&
        authUser.permissions.includes(permissions.PRODUCTION_DELIVERED_REQUEST)
    ) {
        if (
            productionDeliveredObj.meta.action === userActions.CREATED ||
            productionDeliveredObj.meta.action === userActions.UPDATED ||
            productionDeliveredObj.meta.action === userActions.REJECTED
        ) {
            buttonSection = (
                <BackSendEditDelButtons
                    backBtnFn={backCancelBtnFn}
                    sendForApprovalBtnFn={() =>
                        statusChangeLocalFn(userActions.SENT_FOR_APPROVAL)
                    }
                    editBtnFn={gotoEditViewFn}
                    deleteBtnFn={gotoDeleteViewFn}
                />
            );
        }

        if (
            productionDeliveredObj.meta.action === userActions.SENT_FOR_APPROVAL
        ) {
            buttonSection = (
                <ApproveRejectButtons
                    backBtnFn={backCancelBtnFn}
                    approveBtnFn={() =>
                        statusChangeLocalFn(userActions.APPROVED)
                    }
                    rejectBtnFn={() =>
                        statusChangeLocalFn(userActions.REJECTED)
                    }
                />
            );
        }
    }

    if (!authUser.subscriptionStatusIsActive) {
        buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    }

    let generalErrorUI = (
        <div className='general-error-message'>{generalError}</div>
    );

    return (
        <div className='common-form'>
            <section className='form-title-section'>Production Form</section>

            {mainContent}

            {buttonSection}
            {generalErrorUI}

            <Watermark purpose={purpose} currentDocument={currentDocument} />
        </div>
    );
};
