export default (rmMasterlistStringFormat) => {
    let newArr = [];
    let rmObjsInObjFormat = JSON.parse(rmMasterlistStringFormat);

    for (let key in rmObjsInObjFormat) {
        let [rmType, rmDescription] = key.split(' - ');

        newArr.push({
            rmUID: key,
            rmType,
            rmDescription,
            rmCode: rmObjsInObjFormat[key].a,
            rmCategory: rmObjsInObjFormat[key].b,
            rmUnit: rmObjsInObjFormat[key].c,
        });
    }

    return newArr;
};
