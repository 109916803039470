import React from 'react';
import AssemblyOrderForm from './AssemblyOrderForm';

import '../PagesCommon.css';
import './AssemblyDeliveredPage.css';

export default (props) => {
    let {
        assemblyOrderObj,
        assemblyOrderIndex,

        updateFgAssembledQtyFn,
        updateRmActualUsedQtyFn,
        updateSfgActualUsedQtyFn,

        trashIcon,
    } = props;

    let listOfAssemblyOrders = assemblyOrderObj.fgArr.map((fgObj, fgIndex) => {
        return (
            <div key={fgObj.fgUID}>
                <AssemblyOrderForm
                    assemblyOrderIndex={assemblyOrderIndex}
                    fgObj={fgObj}
                    fgIndex={fgIndex}
                    updateFgAssembledQtyFn={updateFgAssembledQtyFn}
                    updateRmActualUsedQtyFn={updateRmActualUsedQtyFn}
                    updateSfgActualUsedQtyFn={updateSfgActualUsedQtyFn}
                />
            </div>
        );
    });

    return (
        <div className='subform'>
            <div className='subform-label-qtys-section'>
                <div className='label'>
                    {`${assemblyOrderIndex + 1}. ${
                        assemblyOrderObj.assemblyUID
                    }`}
                </div>

                <div className='icon'>{trashIcon}</div>
            </div>

            <div className='subform-list-section'>
                <div className='sls-label sls-item'>
                    <div className='cell'>FG List </div>
                    <div className='cell desktop-view'></div>
                </div>
                <div className='error-message'>
                    {assemblyOrderObj.fgErrorMessage}
                </div>

                {listOfAssemblyOrders}
            </div>
        </div>
    );
};
