/**
 * c001:    Sept 1, 2020: feature request, no PO option. go automatically to DR.
 *          This will force the masterlist to be dr automatically after choosing
 *          the supplier. Logic to fork between PO and DR was not changed.
 *
 * c002:    Sept 2, 2020: status change button for "PAID" commented out. Status of
 *          SI "PAID" will be changed from Voucher page (to be implemented).
 *
 *
 *
 */

import React, { useState, useEffect, useContext } from 'react';
import {
    Datalist,
    AddButton,
    Input,
    CreateItemButtons,
    UpdateItemButtons,
    DeleteItemButtons,
    BackOnlyButton,
    BackSendEditDelButtons,
    ApproveRejectButtons,
    HelpTip,
    TextArea,
    Watermark,
} from '../../components';
import {
    AuthContext,
    MasterListContext,
    DataShareContext,
} from '../../contexts';
import {
    permissions,
    userActions,
    systemActions,
    PDPOS_COL,
    PDDRS_COL,
    lengths,
} from '../../lib/constants';
import { clone, displayDate } from '../../lib/util';
import { dbopsGetDocument } from '../../firebase_dbops';
import tooltips from '../../lib/tooltips';

import SIPOItem from './SIPOItem';
import SIDRItem from './SIDRItem';
import AmountBox from './AmountBox';
import validate from '../../validators';

import '../PagesCommon.css';
import './PDSIsPage.css';

let defaultPDSI = {
    pdsiUID: '',
    pdsiNumber: '',
    supplierUID: '',
    pdpoArr: [],
    pddrArr: [],
    meta: { action: '' },
    taxRate: 0, // will be overridden anyway.
    note: '',
};

const siStrs = {
    PO: 'PO',
    DR: 'DR',
    PDPO_ARR: 'pdpoArr',
    PDDR_ARR: 'pddrArr',
};

export default (props) => {
    let {
        purpose,
        currentDocument,

        backCancelBtnFn,
        gotoEditViewFn,
        gotoDeleteViewFn,

        createSIFn,
        deleteSIFn,
        updateSIFn,
        statusChangeFn,

        setIsLoading,
        setAlertErrorMsg,
    } = props;

    let {
        masterPurchaseOrdersList,
        masterDeliveryReceiptsList,
        masterSuppliersList,
    } = useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);
    let { defaultTaxRateFromDB, serverDate } = useContext(DataShareContext);

    let [generalError, setGeneralError] = useState('');
    let [datalistDisplayResetCounter, setDatalistDisplayResetCounter] =
        useState(1);

    let [pdsiObj, setPDSIObj] = useState(defaultPDSI);
    let [pdsiUIDstate, setPdsiUIDstate] = useState('');
    let [tempSupplier, setTempSupplier] = useState('');
    let [supplierUIDstate, setSupplierUIDstate] = useState('');
    let [itemUIDstate, setItemUIDstate] = useState('');
    let [taxRate, setTaxRate] = useState(defaultTaxRateFromDB);
    let [expandedWithholdingTaxRate, setExpandedWithholdingTaxRate] =
        useState(0);
    let [discount, setDiscount] = useState(0);
    let [poOrDr, setPoOrDr] = useState(null);

    let [pdsiDateInState, setPdsiDateInState] = useState(serverDate);
    let [noteInState, setNoteInState] = useState('');

    let [confirmDeleteText, setConfirmDeleteText] = useState('');
    let [preview, setPreview] = useState(false);

    // set pdsiObj from currentDocument if purpose !== 'ADD'
    useEffect(() => {
        if (purpose !== 'ADD') {
            //making a copy so no side effect will change currentDocument.
            let currentDocumentCopy = {
                ...currentDocument,
                pdpoArr: clone(currentDocument.pdpoArr),
                pddrArr: clone(currentDocument.pddrArr),
            };

            setPDSIObj(currentDocumentCopy);
            setTaxRate(currentDocumentCopy.taxRate);
            setExpandedWithholdingTaxRate(
                currentDocumentCopy.expandedWithholdingTaxRate || 0
            );
            setDiscount(currentDocumentCopy.discount);
            setSupplierUIDstate(currentDocumentCopy.supplierUID);
            setPdsiDateInState(currentDocumentCopy.pdsiDate);
            setNoteInState(currentDocumentCopy.note || '');
            setPdsiUIDstate(currentDocumentCopy.pdsiUID);

            if (currentDocumentCopy.pdpoArr.length) setPoOrDr(siStrs.PO);
            if (currentDocumentCopy.pddrArr.length) setPoOrDr(siStrs.DR);
        }
    }, []);

    const cancelBtnFromEditViewFn = () => {
        // 1. reset values.
        setPDSIObj(currentDocument);
        setTaxRate(currentDocument.taxRate);
        setExpandedWithholdingTaxRate(
            currentDocument.expandedWithholdingTaxRate || 0
        );
        setDiscount(currentDocument.discount);
        // console.log(currentDocument);

        // 2. reset errorMessages.
        setGeneralError('');

        // 3. go back to profile view.
        backCancelBtnFn();
    };

    // =====================================================

    //! c001: =============================================
    const upateSupplierUIDstateFn = (_supplierUID) => {
        setSupplierUIDstate(_supplierUID);

        // find if _supplierUID is a valid supplier
        let valid = false;
        masterSuppliersList.forEach((sup) => {
            if (sup.supplierUID === _supplierUID) valid = true;
        });
        if (valid) setPoOrDr(siStrs.DR);
    };
    //! c001 end: ========================================

    const deleteErrors = (pdsiObjCopy) => {
        delete pdsiObjCopy.pdsiUIDError;
        delete pdsiObjCopy.taxRateError;
        delete pdsiObjCopy.expandedWithholdingTaxRateError;
        delete pdsiObjCopy.discountError;
        delete pdsiObjCopy.arrError;
        delete pdsiObjCopy.supplierError;
        delete pdsiObjCopy.pdsiDateError;
        delete pdsiObjCopy.noteError;
        setGeneralError('');
    };

    const addToArrFn = async () => {
        let property = poOrDr === siStrs.PO ? siStrs.PDPO_ARR : siStrs.PDDR_ARR;
        let collection = poOrDr === siStrs.PO ? PDPOS_COL : PDDRS_COL;

        let pdsiObjCopy = {
            ...pdsiObj,
            [property]: clone(pdsiObj[property]),
        };

        deleteErrors(pdsiObjCopy);

        setIsLoading(true);

        let response = await dbopsGetDocument(collection, itemUIDstate);
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message || response.message);
            setIsLoading(false);
            return;
        }

        let itemObj = {};
        let doc = response.data;

        // form valid poObj

        if (poOrDr === siStrs.PO) {
            let validRmArr = doc.rmArr.map((rm) => {
                return {
                    rmUID: rm.rmUID,
                    rmCost: rm.rmCost,
                    // what will be saved is only the computed value of the
                    // rmOrderedQty and rmOverrideQty.  may increase or decrease
                    // depending on the overrideQty.
                    rmOrderedQty: rm.rmOrderedQty + rm.rmOverrideQty,
                    rmUnit: rm.rmUnit,
                };
            });
            itemObj = {
                pdpoUID: doc.pdpoUID,
                supplierUID: doc.supplierUID,
                rmArr: validRmArr,
            };
        } else {
            itemObj = {
                pddrUID: doc.pddrUID,
                supplierUID: doc.supplierUID,
                pdpoArr: clone(doc.pdpoArr),
            };
        }

        // form valid drObj
        pdsiObjCopy[property].push(itemObj);

        setPDSIObj(pdsiObjCopy);
        setDatalistDisplayResetCounter(datalistDisplayResetCounter + 1);
        setIsLoading(false);
    };

    const removeFromArrFn = (index) => {
        let property = poOrDr === siStrs.PO ? siStrs.PDPO_ARR : siStrs.PDDR_ARR;
        let pdsiObjCopy = {
            ...pdsiObj,
            [property]: clone(pdsiObj[property]),
        };

        deleteErrors(pdsiObjCopy);

        pdsiObjCopy[property].splice(index, 1);
        setPDSIObj(pdsiObjCopy);
    };

    const previewSiBtnFn = () => {
        let pdsiObjCopy = { ...pdsiObj };
        pdsiObjCopy.pdsiUID = pdsiUIDstate;
        pdsiObjCopy.taxRate = taxRate;
        pdsiObjCopy.expandedWithholdingTaxRate = expandedWithholdingTaxRate;
        pdsiObjCopy.discount = discount;
        pdsiObjCopy.supplierUID = supplierUIDstate;
        pdsiObjCopy.pdsiDate = pdsiDateInState;
        pdsiObjCopy.note = noteInState;

        let response = validate.pdsi(pdsiObjCopy);
        if (response.error) {
            setPDSIObj(response.errorObj.pdsiObj);
            setTaxRate(response.errorObj.pdsiObj.taxRate);
            setGeneralError(
                response.errorObj.pdsiObj.supplierError ||
                    response.errorObj.pdsiObj.arrError
            );
            setAlertErrorMsg(response.errorObj.hackError);
        } else {
            setPDSIObj(response.data.pdsiObj);
            setPreview(true);
        }
    };

    const saveSIFn = () => {
        let pdsiObjCopy = { ...pdsiObj };
        pdsiObjCopy.pdsiUID = pdsiUIDstate;
        pdsiObjCopy.taxRate = taxRate;
        pdsiObjCopy.expandedWithholdingTaxRate = expandedWithholdingTaxRate;
        pdsiObjCopy.discount = discount;
        pdsiObjCopy.supplierUID = supplierUIDstate;
        pdsiObjCopy.pdsiDate = pdsiDateInState;
        pdsiObjCopy.note = noteInState;

        // check if there is a change in the payload from currentDocument
        if (
            purpose === 'EDIT' &&
            validate.noChange(pdsiObjCopy, currentDocument)
        ) {
            backCancelBtnFn();
            return;
        }

        let response = validate.pdsi(pdsiObjCopy);
        if (response.error) {
            setPDSIObj(response.errorObj.pdsiObj);
            setTaxRate(response.errorObj.pdsiObj.taxRate);
            setGeneralError(
                response.errorObj.pdsiObj.supplierError ||
                    response.errorObj.pdsiObj.arrError
            );
            setAlertErrorMsg(response.errorObj.hackError);
            return;
        }
        setPDSIObj(response.data.pdsiObj);

        let validPdsiObj = response.data.pdsiObj;

        if (purpose === 'ADD') createSIFn(validPdsiObj);
        if (purpose === 'EDIT') updateSIFn(validPdsiObj, currentDocument);
        if (purpose === 'DELETE') deleteSIFn(validPdsiObj, currentDocument);
    };

    //! work around. fixes sequence of Edit without saving and then sending for approval.
    //! bug found in PDSI, SDSI, SDPO. All without statusChangeLocalFn.
    const statusChangeLocalFn = (userActionPassed) => {
        let pdsiObjCopy = { ...pdsiObj };
        pdsiObjCopy.pdsiUID = pdsiObjCopy.pdsiUID || pdsiUIDstate;
        pdsiObjCopy.taxRate = taxRate;
        pdsiObjCopy.expandedWithholdingTaxRate = expandedWithholdingTaxRate;
        pdsiObjCopy.discount = discount;
        pdsiObjCopy.supplierUID = pdsiObjCopy.supplierUID || supplierUIDstate;
        pdsiObjCopy.pdsiDate = pdsiDateInState;
        pdsiObjCopy.note = noteInState;

        let response = validate.pdsi(pdsiObjCopy);
        if (response.error) {
            setPDSIObj(response.errorObj.pdsiObj);
            setTaxRate(response.errorObj.pdsiObj.taxRate);
            setGeneralError(
                response.errorObj.pdsiObj.supplierError ||
                    response.errorObj.pdsiObj.arrError
            );
            setAlertErrorMsg(response.errorObj.hackError);
            return;
        }

        let validPdsiObj = response.data.pdsiObj;

        statusChangeFn(userActionPassed, validPdsiObj, currentDocument);
    };

    // conditional render of contents  =============================

    //! feature request, no PO option. go automatically to DR. 2020-08-30
    let masterlist =
        poOrDr === siStrs.PO
            ? masterPurchaseOrdersList
            : masterDeliveryReceiptsList;
    let itemUID = poOrDr === siStrs.PO ? 'pdpoUID' : 'pddrUID';
    let propertyArr = poOrDr === siStrs.PO ? 'pdpoArr' : 'pddrArr';

    let labelText =
        poOrDr === siStrs.PO ? (
            <span>
                Add Purchase Order
                <HelpTip content={tooltips.pdsiPoArr}></HelpTip>
            </span>
        ) : (
            <span>
                Add Goods Receipt
                <HelpTip content={tooltips.pdsiDrArr}></HelpTip>
            </span>
        );

    let firstLabel =
        poOrDr === siStrs.PO ? 'Purchase Orders' : 'Delivery Receipts';

    let itemArrUI = [];
    let noteUI = null;

    let buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    let mainContent = null;
    let datalist = null;
    let amountBox = (
        <AmountBox
            type={poOrDr}
            pdsiObj={pdsiObj}
            setTaxRate={setTaxRate}
            setExpandedWithholdingTaxRate={setExpandedWithholdingTaxRate}
            setDiscount={setDiscount}
            taxRate={taxRate}
            expandedWithholdingTaxRate={expandedWithholdingTaxRate}
            discount={discount}
            purpose={purpose}
            preview={preview}
        />
    );
    let itemLabel = (
        <div className='span-2-columns-container label-padding label-highlight'>
            <label className='parent-labels'>{`List of ${firstLabel}`} </label>
        </div>
    );

    let addBtn = <AddButton disabled />;

    if ((purpose === 'ADD' && !preview) || purpose === 'EDIT') {
        //! supplier list datalist process ====================================
        let supListOptions = masterSuppliersList.map((sup) => sup.supplierUID);

        let chooseSupBtn = (
            <button
                className='choose-sup-button'
                onClick={() => upateSupplierUIDstateFn(tempSupplier)}>
                {/* onClick={() => setSupplierUIDstate(tempSupplier)}> */}
                Choose Supplier
            </button>
        );

        let supplierDatalist = (
            <div className='get-item-from-db-container'>
                <div className='datalist-with-addbtn-container'>
                    <Datalist
                        list={supListOptions}
                        initialValue={''}
                        getInputValue={(val) => setTempSupplier(val)}
                        errorMessage={''}
                        resetCounter={datalistDisplayResetCounter}></Datalist>
                    {chooseSupBtn}
                </div>
            </div>
        );

        let choosePOorDRComponent = (
            <div className='get-item-from-db-container'>
                <div className='datalist-with-addbtn-container'>
                    <div className='span-2-always'>
                        <div className='space-evenly'>
                            <button
                                className='btn pdsi-po-or-dr-btn-control'
                                onClick={() => setPoOrDr(siStrs.PO)}>
                                PO
                            </button>
                            or
                            <button
                                className='btn pdsi-po-or-dr-btn-control'
                                onClick={() => setPoOrDr(siStrs.DR)}>
                                GR
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );

        //! end supplier datalist process ========================================

        // po or dr datalist =================================================
        let datalistOptions = masterlist
            .filter((item) => item.supplierUID === supplierUIDstate)
            .filter((item) => {
                if (poOrDr === siStrs.PO) {
                    return (
                        item.status === userActions.SENT_TO_SUPPLIER ||
                        item.status === userActions.OVERRIDDEN ||
                        item.status === systemActions.FULFILLED
                    );
                } else {
                    return item.status === userActions.APPROVED;
                }
            })
            .map((item) => item[itemUID]);

        let itemAlreadyInTheUI = pdsiObj[propertyArr].map(
            (item) => item[itemUID]
        );

        if (
            datalistOptions.includes(itemUIDstate) &&
            !itemAlreadyInTheUI.includes(itemUIDstate)
        )
            addBtn = <AddButton onClick={addToArrFn} />;

        // console.log(datalistOptions);

        datalist = (
            <div className='get-item-from-db-container'>
                <div className='datalist-with-addbtn-container'>
                    <Datalist
                        label={labelText}
                        list={datalistOptions}
                        initialValue={''}
                        getInputValue={(val) => setItemUIDstate(val)}
                        errorMessage={''}
                        resetCounter={datalistDisplayResetCounter}></Datalist>
                    {addBtn}
                </div>
            </div>
        );

        // end po or dr datalist ==========================================

        for (let index = 0; index < pdsiObj[propertyArr].length; index += 1) {
            let trashIcon = (
                <i
                    className='ms-Icon ms-Icon--Delete clickable-icon'
                    aria-hidden='true'
                    onClick={() => removeFromArrFn(index)}></i>
            );

            let displayComponent =
                poOrDr === siStrs.PO ? (
                    <div className='span-2-columns-container' key={index}>
                        <SIPOItem
                            trashIcon={trashIcon}
                            poIndex={index}
                            poObj={pdsiObj[propertyArr][index]}
                        />
                    </div>
                ) : (
                    <div className='span-2-columns-container' key={index}>
                        <SIDRItem
                            trashIcon={trashIcon}
                            drIndex={index}
                            drObj={pdsiObj[propertyArr][index]}
                        />
                    </div>
                );

            itemArrUI.push(displayComponent);
        }

        let siUID = (
            <Input
                type='text'
                maxLength={lengths.uid}
                onChange={(e) => setPdsiUIDstate(e.target.value)}
                value={pdsiUIDstate}
                placeholder='Required'
                errorMessage={pdsiObj.pdsiUIDError}></Input>
        );

        if (purpose === 'EDIT') {
            siUID = <p className='fpii-text'>{pdsiObj.pdsiUID || '---'}</p>;
        }

        // choosing supplier and PO or DR step.
        let chosenSupplier = masterSuppliersList.filter(
            (supplier) => supplier.supplierUID === supplierUIDstate
        );

        if (supplierUIDstate !== '' && chosenSupplier[0]) {
            supplierDatalist = null;
        } else choosePOorDRComponent = null;

        if (!poOrDr) {
            itemArrUI = null;
            itemLabel = null;
            datalist = null;
        } else choosePOorDRComponent = null;

        if (itemArrUI === null || itemArrUI.length === 0) {
            itemLabel = null;
            amountBox = null;
        }

        noteUI = (
            <div className='span-2-columns-container'>
                <TextArea
                    label={<span className='parent-labels'>Notes</span>}
                    maxLength={lengths.noteLength}
                    value={noteInState}
                    onChange={(e) => setNoteInState(e.target.value)}
                    errorMessage={pdsiObj.noteError}
                />
            </div>
        );

        //! Max number of iitems in the list
        if (pdsiObj[propertyArr].length >= lengths.pdsiFgOrSfgArrLength)
            datalist = null;

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Supplier Invoice</p>
                    {siUID}
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Status</p>
                    <p className='fpii-text'>{pdsiObj.meta.action || '---'}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Supplier</p>
                    <p className='fpii-text'>
                        {pdsiObj.supplierUID || supplierUIDstate || '---'}
                    </p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Date</p>
                    <Input
                        type='date'
                        maxLength='12'
                        onChange={(e) => setPdsiDateInState(e.target.value)}
                        value={pdsiDateInState}
                        errorMessage={pdsiObj.pdsiDateError}></Input>
                </div>

                {supplierDatalist}
                {choosePOorDRComponent}

                {itemLabel}
                {itemArrUI}
                {amountBox}

                {datalist}
                {noteUI}
            </section>
        );
    }

    if (
        (purpose === 'ADD' && preview) ||
        purpose === 'VIEW' ||
        purpose === 'DELETE'
    ) {
        for (let index = 0; index < pdsiObj[propertyArr].length; index += 1) {
            let displayComponent =
                poOrDr === siStrs.PO ? (
                    <div className='span-2-columns-container' key={index}>
                        <SIPOItem
                            poIndex={index}
                            poObj={pdsiObj[propertyArr][index]}
                        />
                    </div>
                ) : (
                    <div className='span-2-columns-container' key={index}>
                        <SIDRItem
                            drIndex={index}
                            drObj={pdsiObj[propertyArr][index]}
                        />
                    </div>
                );

            itemArrUI.push(displayComponent);
        }

        noteUI = (
            <div className='span-2-columns-container'>
                <TextArea
                    label={<span className='parent-labels'>Notes</span>}
                    disabled
                    maxLength={lengths.longTextLength}
                    value={noteInState}
                />
            </div>
        );

        if (
            pdsiObj.meta.action === userActions.SENT_FOR_APPROVAL &&
            authUser.permissions.includes(permissions.PDSI_APPROVE)
        ) {
            noteUI = (
                <div className='span-2-columns-container'>
                    <TextArea
                        label={<span className='parent-labels'>Notes</span>}
                        maxLength={lengths.noteLength}
                        value={noteInState}
                        onChange={(e) => setNoteInState(e.target.value)}
                        errorMessage={pdsiObj.noteError}
                    />
                </div>
            );
        }

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Supplier Invoice</p>
                    {pdsiObj.pdsiUID}
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Status</p>
                    <p className='fpii-text'>{pdsiObj.meta.action || '---'}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Supplier</p>
                    <p className='fpii-text'>
                        {pdsiObj.supplierUID || supplierUIDstate || '---'}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Date</p>
                    <p className='fpii-text'>
                        {pdsiObj.pdsiDate
                            ? displayDate(pdsiObj.pdsiDate)
                            : '---'}
                    </p>
                </div>

                {itemLabel}
                {itemArrUI}
                {amountBox}
                {noteUI}
            </section>
        );
    }

    // =========================================================================
    // Button sections for users with PDSI Request permision ===================

    if (purpose === 'ADD') {
        buttonSection = (
            <CreateItemButtons
                createText='Create SI'
                previewText='Preview SI'
                cancelPreviewFn={() => setPreview(false)}
                cancelFn={backCancelBtnFn}
                createFn={saveSIFn}
                previewFn={previewSiBtnFn}
                isPreview={preview}
            />
        );
    }

    if (purpose === 'EDIT') {
        buttonSection = (
            <UpdateItemButtons
                saveFn={saveSIFn}
                cancelFn={cancelBtnFromEditViewFn}
            />
        );
    }

    if (purpose === 'DELETE') {
        buttonSection = (
            <DeleteItemButtons
                cancelFn={backCancelBtnFn}
                deleteFn={saveSIFn}
                confirmeDeleteFn={(e) => setConfirmDeleteText(e.target.value)}
                confirmDeleteTextValue={confirmDeleteText}
                confirmDeleteTextPlaceHolder='Enter SI Number'
                uidValue={pdsiObj.pdsiUID}
            />
        );
    }

    // ==================================================================
    // Button section for users with PDPO Request permission.
    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.PDSI_REQUEST) &&
        !authUser.permissions.includes(permissions.PDSI_APPROVE)
    ) {
        if (
            pdsiObj.meta.action === userActions.CREATED ||
            pdsiObj.meta.action === userActions.UPDATED ||
            pdsiObj.meta.action === userActions.REJECTED
        ) {
            buttonSection = (
                <BackSendEditDelButtons
                    backBtnFn={backCancelBtnFn}
                    sendForApprovalBtnFn={() =>
                        statusChangeLocalFn(userActions.SENT_FOR_APPROVAL)
                    }
                    editBtnFn={gotoEditViewFn}
                    deleteBtnFn={gotoDeleteViewFn}
                />
            );
        }
    }

    // =========================================================================
    // Button section for users with PDPO Approve permision ===================

    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.PDSI_APPROVE) &&
        !authUser.permissions.includes(permissions.PDSI_REQUEST)
    ) {
        // if document status is SENT_FOR_APPROVAL
        if (pdsiObj.meta.action === userActions.SENT_FOR_APPROVAL) {
            buttonSection = (
                <ApproveRejectButtons
                    backBtnFn={backCancelBtnFn}
                    approveBtnFn={() =>
                        statusChangeLocalFn(userActions.APPROVED)
                    }
                    rejectBtnFn={() =>
                        statusChangeLocalFn(userActions.REJECTED)
                    }
                />
            );
        }
        //! c002: ===============================================
        // // if document status is APPROVED
        // if (pdsiObj.meta.action === userActions.APPROVED) {
        //     buttonSection = (
        //         <BackWithMarkAsButtons
        //             backBtnFn={backCancelBtnFn}
        //             markAsBtnFn={() =>
        //                 statusChangeLocalFn(userActions.PAYMENT_SENT)
        //             }
        //             markAsBtnText='Mark as Paid'
        //         />
        //     );
        // }
        //! c002 end: ===============================================
    }

    // =========================================================================
    //! button section for users with both PDPO Request and Approve permissions.
    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.PDSI_APPROVE) &&
        authUser.permissions.includes(permissions.PDSI_REQUEST)
    ) {
        // if document status is SENT_FOR_APPROVAL
        if (pdsiObj.meta.action === userActions.SENT_FOR_APPROVAL) {
            buttonSection = (
                <ApproveRejectButtons
                    backBtnFn={backCancelBtnFn}
                    approveBtnFn={() =>
                        statusChangeLocalFn(userActions.APPROVED)
                    }
                    rejectBtnFn={() =>
                        statusChangeLocalFn(userActions.REJECTED)
                    }
                />
            );
        }

        //! c002: ===============================================
        // // if document status is APPROVED
        // if (pdsiObj.meta.action === userActions.APPROVED) {
        //     buttonSection = (
        //         <BackWithMarkAsButtons
        //             backBtnFn={backCancelBtnFn}
        //             markAsBtnFn={() =>
        //                 statusChangeLocalFn(userActions.PAYMENT_SENT)
        //             }
        //             markAsBtnText='Mark as Paid'
        //         />
        //     );
        // }
        //! c002 end: ===============================================

        if (
            pdsiObj.meta.action === userActions.CREATED ||
            pdsiObj.meta.action === userActions.UPDATED ||
            pdsiObj.meta.action === userActions.REJECTED
        ) {
            buttonSection = (
                <BackSendEditDelButtons
                    backBtnFn={backCancelBtnFn}
                    sendForApprovalBtnFn={() =>
                        statusChangeLocalFn(userActions.SENT_FOR_APPROVAL)
                    }
                    editBtnFn={gotoEditViewFn}
                    deleteBtnFn={gotoDeleteViewFn}
                />
            );
        }
    }

    if (!authUser.subscriptionStatusIsActive) {
        buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    }

    let generalErrorUI = (
        <div className='general-error-message'>{generalError}</div>
    );

    return (
        <div className='common-form'>
            <section className='form-title-section'>
                Supplier Invoice Form
            </section>

            {mainContent}

            {buttonSection}
            {generalErrorUI}

            <Watermark purpose={purpose} currentDocument={currentDocument} />
        </div>
    );
};
