import React from 'react';
import { sortReportItemsByAmount } from '../../lib/util';
import ReportsForm from './ReportsForm';
import ReportItemsEach from './ReportItemsEach';
import tooltips from '../../lib/tooltips';

export default ({
    backCancelBtnFn,
    purchaseReportBySupplierObj,
    forPayable,
}) => {
    let amountText = forPayable ? 'Amount Payable' : 'Purchase Amount';
    let formLabelText = forPayable
        ? 'Accounts Payables'
        : 'Purchase Report by Supplier';

    let purchasaReportBySupplierUI = [];
    let supplierPurchasesArr = [];
    let purchasesBySupplierMap = purchaseReportBySupplierObj.supplierMap;
    for (let key in purchasesBySupplierMap) {
        let supplierObj = {};
        supplierObj.itemUID = key;
        supplierObj.total = purchasesBySupplierMap[key];

        supplierPurchasesArr.push(supplierObj);
    }

    sortReportItemsByAmount(supplierPurchasesArr);

    supplierPurchasesArr.forEach((item, index) => {
        purchasaReportBySupplierUI.push(
            <ReportItemsEach
                key={index}
                index={index}
                item={item}
                mobileLabel={amountText}
            />
        );
    });

    return (
        <ReportsForm
            helpText={tooltips.reportPurchaseSupplier}
            uid={`${formLabelText} - ${purchaseReportBySupplierObj.reportMonth} ${purchaseReportBySupplierObj.reportYear}`}
            nameLabel='Supplier'
            amountLabel={amountText}
            total={purchaseReportBySupplierObj.total}
            list={purchasaReportBySupplierUI}
            backCancelBtnFn={backCancelBtnFn}
        />
    );
};
