import React from 'react';
import { sortReportItemsByAmount } from '../../lib/util';
import ReportsForm from './ReportsForm';
import ReportItemsEach from './ReportItemsEach';
import tooltips from '../../lib/tooltips';

export default ({ backCancelBtnFn, salesReportByCustomerObj }) => {
    let salesReportByCustomerUI = [];
    let customerSalesArr = [];
    let salesByCustomerMap = salesReportByCustomerObj.customerMap;

    for (let key in salesByCustomerMap) {
        let customerObj = {};
        customerObj.itemUID = key;
        customerObj.total = salesByCustomerMap[key];

        customerSalesArr.push(customerObj);
    }

    sortReportItemsByAmount(customerSalesArr);

    customerSalesArr.forEach((item, index) => {
        salesReportByCustomerUI.push(
            <ReportItemsEach
                key={index}
                index={index}
                item={item}
                mobileLabel='Sales Amount'
            />
        );
    });

    return (
        <ReportsForm
            helpText={tooltips.reportSalesCustomer}
            uid={`Sales Report by Customer - ${salesReportByCustomerObj.reportMonth} ${salesReportByCustomerObj.reportYear}`}
            nameLabel='Customer'
            amountLabel='Sales Amount'
            total={salesReportByCustomerObj.total}
            list={salesReportByCustomerUI}
            backCancelBtnFn={backCancelBtnFn}
        />
    );
};
