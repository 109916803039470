import { lengths, regEx } from '../lib/constants';
import { generalMessage, appMessage } from '../lib/messages';
import { saveWithFiveDecimals, clone } from '../lib/util';

export const invTransfer = (
    originalInvTransferObj,
    masterCustomerListArg,
    masterSupplierListArg,
    masterListRMArg,
    masterListSFGArg,
    masterListFGArg,
    masterInventoryRMsList,
    masterInventorySFGsList,
    masterInventoryFGsList,
    documentBasis,
    purpose,
    userActionPassed
) => {
    let masterListRM = masterListRMArg.map((rm) => rm.rmUID);
    let masterListSFG = masterListSFGArg.map((sfg) => sfg.sfgUID);
    let masterListFG = masterListFGArg.map((fg) => fg.fgUID);
    let masterCustomerList = masterCustomerListArg.map((c) => c.customerUID);
    let masterSupplierList = masterSupplierListArg.map((s) => s.supplierUID);

    let rmActualQtyMap = masterInventoryRMsList.reduce((acc, current) => {
        return (acc = { ...acc, [current.rmUID]: current.rmActualQty });
    }, {});
    let sfgActualQtyMap = masterInventorySFGsList.reduce((acc, current) => {
        return (acc = { ...acc, [current.sfgUID]: current.sfgActualQty });
    }, {});
    let fgActualQtyMap = masterInventoryFGsList.reduce((acc, current) => {
        return (acc = { ...acc, [current.fgUID]: current.fgActualQty });
    }, {});

    let arraysErr = '';

    let rmArrWithError = false;
    let sfgArrWithError = false;
    let fgArrWithError = false;

    let notEnoughInventoryError = false;

    let { rmArr, sfgArr, fgArr } = originalInvTransferObj;

    // make a new copies of the arrays.
    rmArr = clone(rmArr);
    sfgArr = clone(sfgArr);
    fgArr = clone(fgArr);

    // make a new copy of Copy to ensure no side effect happens outside the function.
    let invTransferObjCopy = {
        ...originalInvTransferObj,
        rmArr,
        sfgArr,
        fgArr,
    };

    delete invTransferObjCopy.subjectUIDError;
    delete invTransferObjCopy.invTransferDateError;

    //! start validation of each property.

    //! bug fix 2021/05/30. no need to check customerUID or supplierUID if still in masterlist.
    // subjectUID
    // if (invTransferObjCopy.transferDirection === 'toCustomer') {
    //     if (!masterCustomerList.includes(invTransferObjCopy.subjectUID)) {
    //         invTransferObjCopy.subjectUIDError = 'Customer not found.';
    //     }
    // }
    // if (invTransferObjCopy.transferDirection === 'fromSupplier') {
    //     if (!masterSupplierList.includes(invTransferObjCopy.subjectUID)) {
    //         invTransferObjCopy.subjectUIDError = 'Supplier not found.';
    //     }
    // }

    // invTransferDate
    if (!regEx.date.test(invTransferObjCopy.invTransferDate)) {
        invTransferObjCopy.invTransferDateError = generalMessage.INVALID_DATE;
    }

    //! this should never run unless Source Code was hacked.
    // rmArr validation
    if (
        rmArr.length > lengths.invTransferItemsLength ||
        sfgArr.length > lengths.invTransferItemsLength ||
        fgArr.length > lengths.invTransferItemsLength
    )
        arraysErr = appMessage.sourceCodeErrorMessage(
            'FG: rmArr length error.'
        );
    //! ================================================

    // check if there is at least one rm or sfg in the arrays.
    if (rmArr.length === 0 && sfgArr.length === 0 && fgArr.length === 0)
        arraysErr = 'Must contain at least one RM, SFG, or FG.';

    //! rmArr validation ====================================================
    let noDuplicateRawMatNamesArr = [];
    let noDuplicateSfgNamesArr = [];
    let noDuplicateFgNamesArr = [];

    // reset all error messages inside rmArr.
    let rmObjArr = rmArr.map((rm) => {
        return {
            ...rm,
            errorMessage: '',
            transferQtyErrorMessage: '',
        };
    });
    // reset all error messages inside sfgArr.
    let sfgObjArr = sfgArr.map((sfg) => {
        return {
            ...sfg,
            errorMessage: '',
            transferQtyErrorMessage: '',
        };
    });
    // reset all error messages inside fgArr.
    let fgObjArr = fgArr.map((fg) => {
        return {
            ...fg,
            errorMessage: '',
            transferQtyErrorMessage: '',
        };
    });

    //! less checking during delete so deleted rm, sfg or fg components will not throw an error.
    if (purpose !== 'DELETE') {
        // check validity of rmUID and transferQty.
        rmObjArr.forEach((rm) => {
            if (!masterListRM.includes(rm.rmUID)) {
                rm.errorMessage = 'Raw Material not found.';
                rmArrWithError = true;
            }
            if (rm.rmUID.trim() === '') {
                rm.errorMessage = 'Delete if blank.';
                rmArrWithError = true;
            }
            if (noDuplicateRawMatNamesArr.includes(rm.rmUID)) {
                rm.errorMessage = 'Invalid entry. Duplicate Raw Material.';
                rmArrWithError = true;
            } else noDuplicateRawMatNamesArr.push(rm.rmUID);

            // conditions for transferQty
            if (Number(rm.transferQty) <= 0 || rm.transferQty === '') {
                rm.transferQtyErrorMessage = generalMessage.GREATER_ZERO;
                rmArrWithError = true;
            }
            if (Number(rm.transferQty) > lengths.numberInputAmount) {
                rm.transferQtyErrorMessage = generalMessage.INVALID_AMOUNT;
                rmArrWithError = true;
            }

            if (isNaN(rm.transferQty)) {
                rm.transferQtyErrorMessage = generalMessage.INVALID;
                rmArrWithError = true;
            }

            // check if there is enough actual qty client side.  There is another check in the backend logic.
            if (invTransferObjCopy.transferDirection === 'toCustomer') {
                if (Number(rm.transferQty) > rmActualQtyMap[rm.rmUID]) {
                    rm.transferQtyErrorMessage =
                        generalMessage.NOT_ENOUGH_INVENTORY;
                    notEnoughInventoryError = true;
                }
            }
        });

        // check validity of sfgUID and transferQty.
        sfgObjArr.forEach((sfg) => {
            if (!masterListSFG.includes(sfg.sfgUID)) {
                sfg.errorMessage = 'Semi FG not found.';
                sfgArrWithError = true;
            }
            if (sfg.sfgUID.trim() === '') {
                sfg.errorMessage = 'Delete if blank.';
                sfgArrWithError = true;
            }
            if (noDuplicateSfgNamesArr.includes(sfg.sfgUID)) {
                sfg.errorMessage = 'Invalid entry. Duplicate Semi FG.';
                sfgArrWithError = true;
            } else noDuplicateSfgNamesArr.push(sfg.sfgUID);

            // conditions for transferQty
            if (Number(sfg.transferQty) <= 0 || sfg.transferQty === '') {
                sfg.transferQtyErrorMessage = generalMessage.GREATER_ZERO;
                sfgArrWithError = true;
            }
            if (Number(sfg.transferQty) > lengths.numberInputAmount) {
                sfg.transferQtyErrorMessage = generalMessage.INVALID_AMOUNT;
                sfgArrWithError = true;
            }

            if (isNaN(sfg.transferQty)) {
                sfg.transferQtyErrorMessage = generalMessage.INVALID;
                sfgArrWithError = true;
            }

            // check if there is enough actual qty client side.  There is another check in the backend logic.
            if (invTransferObjCopy.transferDirection === 'toCustomer') {
                if (Number(sfg.transferQty) > sfgActualQtyMap[sfg.sfgUID]) {
                    sfg.transferQtyErrorMessage =
                        generalMessage.NOT_ENOUGH_INVENTORY;
                    notEnoughInventoryError = true;
                }
            }
        });

        // check validity of fgUID and transferQty.
        fgObjArr.forEach((fg) => {
            if (!masterListFG.includes(fg.fgUID)) {
                fg.errorMessage = 'Finished Good not found.';
                fgArrWithError = true;
            }
            if (fg.fgUID.trim() === '') {
                fg.errorMessage = 'Delete if blank.';
                fgArrWithError = true;
            }
            if (noDuplicateFgNamesArr.includes(fg.fgUID)) {
                fg.errorMessage = 'Invalid entry. Duplicate Finished Good.';
                fgArrWithError = true;
            } else noDuplicateFgNamesArr.push(fg.fgUID);

            // conditions for transferQty
            if (Number(fg.transferQty) <= 0 || fg.transferQty === '') {
                fg.transferQtyErrorMessage = generalMessage.GREATER_ZERO;
                fgArrWithError = true;
            }
            if (Number(fg.transferQty) > lengths.numberInputAmount) {
                fg.transferQtyErrorMessage = generalMessage.INVALID_AMOUNT;
                fgArrWithError = true;
            }

            if (isNaN(fg.transferQty)) {
                fg.transferQtyErrorMessage = generalMessage.INVALID;
                fgArrWithError = true;
            }

            // check if there is enough actual qty client side.  There is another check in the backend logic.
            if (invTransferObjCopy.transferDirection === 'toCustomer') {
                if (Number(fg.transferQty) > fgActualQtyMap[fg.fgUID]) {
                    fg.transferQtyErrorMessage =
                        generalMessage.NOT_ENOUGH_INVENTORY;

                    notEnoughInventoryError = true;
                }
            }
        });
    }

    // checking for enough quantity for all actions except REJECTED.
    if (userActionPassed && userActionPassed !== 'REJECTED') {
        if (notEnoughInventoryError && !arraysErr)
            arraysErr = generalMessage.NOT_ENOUGH_INVENTORY;
    }

    if (
        arraysErr ||
        rmArrWithError ||
        sfgArrWithError ||
        fgArrWithError ||
        invTransferObjCopy.subjectUIDError ||
        invTransferObjCopy.invTransferDateError
    ) {
        invTransferObjCopy.rmArr = rmObjArr;
        invTransferObjCopy.sfgArr = sfgObjArr;
        invTransferObjCopy.fgArr = fgObjArr;
        return {
            error: true,
            errorObj: {
                invTransferObjCopy,
                arraysErr,
            },
        };
    }

    // if there is no error

    // delete all errors

    //! less checking during delete so deleted sfg or rm components will not throw an error.
    if (purpose !== 'DELETE') {
        // 1. clean up rmArr, sfgArr, fgArr and use updated values from rmObjArr and semiFGArr
        //      before sending to parent element. ==========================

        let updatedRMArr = rmObjArr.map((item) => {
            delete item.errorMessage;
            delete item.transferQtyErrorMessage;

            let extractedUnit = masterListRMArg.filter(
                (rm) => rm.rmUID === item.rmUID
            )[0].rmUnit;

            let newItem = {
                ...item,
                rmUnit: extractedUnit,
            };

            return newItem;
        });

        let updatedSFGArr = sfgObjArr.map((item) => {
            delete item.errorMessage;
            delete item.transferQtyErrorMessage;

            let extractedUnit = masterListSFGArg.filter(
                (sfg) => sfg.sfgUID === item.sfgUID
            )[0].sfgUnit;

            let newItem = {
                ...item,
                sfgUnit: extractedUnit,
            };

            return newItem;
        });

        let updatedFGArr = fgObjArr.map((item) => {
            delete item.errorMessage;
            delete item.transferQtyErrorMessage;

            let extractedUnit = masterListFGArg.filter(
                (fg) => fg.fgUID === item.fgUID
            )[0].fgUnit;

            let newItem = {
                ...item,
                fgUnit: extractedUnit,
            };

            return newItem;
        });

        // 2. sort the rmArr by uid. ==============================
        let sorterRmFn = (a, b) => {
            if (a.rmUID > b.rmUID) return 1;
            if (a.rmUID < b.rmUID) return -1;
            return 0;
        };
        let sorterSfgFn = (a, b) => {
            if (a.sfgUID > b.sfgUID) return 1;
            if (a.sfgUID < b.sfgUID) return -1;
            return 0;
        };
        let sorterFgFn = (a, b) => {
            if (a.fgUID > b.fgUID) return 1;
            if (a.fgUID < b.fgUID) return -1;
            return 0;
        };

        let sortedRMArr = updatedRMArr.sort(sorterRmFn);
        let sortedSFGArr = updatedSFGArr.sort(sorterSfgFn);
        let sortedFGArr = updatedFGArr.sort(sorterFgFn);

        // ensure data type number of all properties if it is a quantity.

        invTransferObjCopy.rmArr = sortedRMArr.map((item) => {
            return {
                ...item,
                transferQty: saveWithFiveDecimals(item.transferQty),
            };
        });
        invTransferObjCopy.sfgArr = sortedSFGArr.map((item) => {
            return {
                ...item,
                transferQty: saveWithFiveDecimals(item.transferQty),
            };
        });
        invTransferObjCopy.fgArr = sortedFGArr.map((item) => {
            return {
                ...item,
                transferQty: saveWithFiveDecimals(item.transferQty),
            };
        });
    }

    return {
        error: false,
        errorObj: {},
        data: invTransferObjCopy,
    };
};
