import React, { useState, createContext } from 'react';

export const MasterListContext = createContext();

export default (props) => {
    const [masterSuppliersList, setMasterSuppliersList] = useState([]);
    const [masterCustomersList, setMasterCustomersList] = useState([]);
    const [masterRawMaterialsList, setMasterRawMaterialsList] = useState([]);
    const [masterSemiFGsList, setMasterSemiFGsList] = useState([]);
    const [masterFinishedGoodsList, setMasterFinishedGoodsList] = useState([]);
    const [masterInventoryRMsList, setMasterInventoryRMsList] = useState([]);
    const [masterInventorySFGsList, setMasterInventorySFGsList] = useState([]);
    const [masterInventoryFGsList, setMasterInventoryFGsList] = useState([]);

    // Purchasing
    const [masterPurchaseOrdersList, setMasterPurchaseOrdersList] = useState(
        []
    );
    const [
        masterDeliveryReceiptsList,
        setMasterDeliveryReceiptsList,
    ] = useState([]);

    const [masterPDSalesInvoicesList, setMasterPDSalesInvoicesList] = useState(
        []
    );
    const [masterPdvrList, setMasterPdvrList] = useState([]);

    // internal company process
    const [masterProdOrdersList, setMasterProdOrdersList] = useState([]);
    const [
        masterProductionDeliveredList,
        setMasterProductionDeliveredList,
    ] = useState([]);
    const [masterAssemblyList, setMasterAssemblyList] = useState([]);
    const [
        masterAssemblyDeliveredList,
        setMasterAssemblyDeliveredList,
    ] = useState([]);

    // Sales
    const [masterSdpoList, setMasterSdpoList] = useState([]);
    const [masterSddrList, setMasterSddrList] = useState([]);
    const [masterSdsiList, setMasterSdsiList] = useState([]);
    const [masterSdcrList, setMasterSdcrList] = useState([]);

    // Users
    const [masterUsersList, setMasterUsersList] = useState([]);

    // Subscription Invoices
    const [
        masterSubscriptionInvoicesList,
        setMasterSubscriptionInvoicesList,
    ] = useState([]);

    // Reports
    const [masterReportsList, setMasterReportsList] = useState([]);

    // Inventory Transfer
    const [masterInvTranferList, setMasterInvTransferList] = useState([]);

    return (
        <MasterListContext.Provider
            value={{
                masterSuppliersList,
                setMasterSuppliersList,
                masterCustomersList,
                setMasterCustomersList,
                masterRawMaterialsList,
                setMasterRawMaterialsList,
                masterSemiFGsList,
                setMasterSemiFGsList,
                masterFinishedGoodsList,
                setMasterFinishedGoodsList,
                masterInventoryRMsList,
                setMasterInventoryRMsList,
                masterInventorySFGsList,
                setMasterInventorySFGsList,
                masterInventoryFGsList,
                setMasterInventoryFGsList,
                masterPurchaseOrdersList,
                setMasterPurchaseOrdersList,
                masterDeliveryReceiptsList,
                setMasterDeliveryReceiptsList,
                masterPDSalesInvoicesList,
                setMasterPDSalesInvoicesList,
                masterProdOrdersList,
                setMasterProdOrdersList,
                masterAssemblyList,
                setMasterAssemblyList,
                masterSdpoList,
                setMasterSdpoList,
                masterSddrList,
                setMasterSddrList,
                masterSdsiList,
                setMasterSdsiList,
                masterUsersList,
                setMasterUsersList,
                masterProductionDeliveredList,
                setMasterProductionDeliveredList,
                masterAssemblyDeliveredList,
                setMasterAssemblyDeliveredList,
                masterSubscriptionInvoicesList,
                setMasterSubscriptionInvoicesList,
                masterPdvrList,
                setMasterPdvrList,
                masterSdcrList,
                setMasterSdcrList,
                masterReportsList,
                setMasterReportsList,
                masterInvTranferList,
                setMasterInvTransferList,
            }}>
            {props.children}
        </MasterListContext.Provider>
    );
};
