import React, { useState, useContext } from 'react';
import { dbopsPDVRs } from '../../firebase_dbops';
import { MasterListContext, AuthContext } from '../../contexts';
import { InputIcon, ListView2 } from '../../components';
import VRCRItem from '../../components/CollectionItems/VRCRItem/VRCRItem';
import { displayDate } from '../../lib/util';
import { permissions, lengths } from '../../lib/constants';
import { userActions } from '../../lib/constants';

import '../PagesCommon.css';
// import './PDDRsPage.css';

import PDVRsForm from './PDVRsForm';

const view = {
    LIST_VIEW: 'listView',
    CREATE_VOUCHER_RECEIPT: 'creatVR',
    EDIT_VOUCHER_RECEIPT: 'editVR',
    DELETE_VOUCHER_RECEIPT: 'deleteVR',
    VOUCHER_RECEIPT_PROFILE: 'vrProfile',
};

export default (props) => {
    let { setIsLoading, setAlertErrorMsg, setAlertMessage } = props;

    let { masterPdvrList } = useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);

    let [formView, setFormView] = useState(view.LIST_VIEW);
    let [currentDocument, setCurrentDocument] = useState('');
    let [searchText, setSearchText] = useState('');

    // functions to change views.
    const createVoucherReceiptBtnFn = () =>
        setFormView(view.CREATE_VOUCHER_RECEIPT);
    const gotoListViewFn = () => setFormView(view.LIST_VIEW);
    const gotoEditViewFn = () => setFormView(view.EDIT_VOUCHER_RECEIPT);
    const gotoDeleteViewFn = () => setFormView(view.DELETE_VOUCHER_RECEIPT);
    const gotoPoProfileViewFn = () => setFormView(view.VOUCHER_RECEIPT_PROFILE);

    // function to get pdvr document.
    const getDocumentFromDBFn = async (docID) => {
        setIsLoading(true);
        let response = await dbopsPDVRs.getVrFn(docID);
        if (response.error)
            setAlertErrorMsg(response.errorObj.message || response.message);
        else {
            setCurrentDocument(response.data);
            gotoPoProfileViewFn();
        }
        setIsLoading(false);
    };

    const createVoucherReceiptFn = async (vrObj) => {
        // spinner on.
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        // write to db.
        let response = await dbopsPDVRs.createVrFn(userCreds, vrObj);
        if (response.error) setAlertErrorMsg(response.errorObj.message);
        else setAlertMessage(response.message);

        gotoListViewFn();

        // spinner off.
        setIsLoading(false);
    };

    const updateVoucherReceiptFn = async (vrObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsPDVRs.updateVrFn(
            userCreds,
            vrObj,
            documentBasis
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);
            // if error is because document has already been deleted,
            // go back to list view.
            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
            // if error is because document has since changed, just continue
            // flow of the program.
        } else setAlertMessage(response.message);

        // this get document ensures state in FE state (currentDocument) is updated.
        gotoListViewFn();
        await getDocumentFromDBFn(vrObj.pdvrUID);

        setIsLoading(false);
    };

    const deleteVoucherReceiptFn = async (vrObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsPDVRs.deleteVrFn(
            userCreds,
            vrObj,
            documentBasis
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);

            // if error is because document has changed before changes
            // could be applied, get latest version of pdvr and
            // go back to pdvr profile view.
            if (response.errorObj.code === 1001) {
                gotoListViewFn();
                await getDocumentFromDBFn(vrObj.pdvrUID);
                setIsLoading(false);
                return;
            }
            // all other errors can continue flow of program.
        } else setAlertMessage(response.message);

        gotoListViewFn();

        setIsLoading(false);
    };

    const statusChangeFn = async (userActionPassed, vrObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            action: userActionPassed,
            uid: authUser.uid,
            email: authUser.email,
        };

        let dbFn = dbopsPDVRs.statusChangeFn;
        if (
            userActionPassed === userActions.APPROVED ||
            userActionPassed === userActions.MARK_AS_PAID
        ) {
            dbFn = dbopsPDVRs.approvePdvrFn;
        }

        let response = await dbFn(userCreds, vrObj, documentBasis);
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);

            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
            // if error is because document has since changed, just continue
            // flow of the program.
        } else setAlertMessage(response.message);

        // this get document ensures state in FE state (currentDocument) is updated.
        gotoListViewFn();
        await getDocumentFromDBFn(vrObj.pdvrUID);

        setIsLoading(false);
    };

    // filter function =====================================
    let filteredVoucherReceiptList = masterPdvrList.filter((pdvr) => {
        return (
            pdvr.pdvrUID.toUpperCase().includes(searchText.toUpperCase()) ||
            displayDate(pdvr.pdvrDate)
                .toUpperCase()
                .includes(searchText.toUpperCase()) ||
            pdvr.status.toUpperCase().includes(searchText.toUpperCase()) ||
            pdvr.supplierUID.toUpperCase().includes(searchText.toUpperCase())
        );
    });

    let addPdvrBtnFn = (
        <button className='btn btn-primary' onClick={createVoucherReceiptBtnFn}>
            Create Voucher Receipt
        </button>
    );

    if (
        !authUser.permissions.includes(permissions.PDVR_REQUEST) ||
        masterPdvrList.length >= lengths.masterPOSDeptsLimits ||
        !authUser.subscriptionStatusIsActive
    ) {
        addPdvrBtnFn = null;
    }

    let topSectionContent = (
        <section className='top-section'>
            <InputIcon
                //! id='suppliers-search-input'
                type='text'
                placeholder='Search'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                icon={
                    <i
                        className='ms-Icon ms-Icon--Search'
                        aria-hidden='true'></i>
                }
            />
            {addPdvrBtnFn}
        </section>
    );

    let mainSectionContent = (
        <ListView2
            getDocumentFromDBFn={getDocumentFromDBFn}
            list={filteredVoucherReceiptList}
            keyProperty={'pdvrUID'}
            CollectionItemComponent={VRCRItem}
        />
    );

    if (formView !== view.LIST_VIEW) topSectionContent = null;

    if (formView === view.CREATE_VOUCHER_RECEIPT) {
        mainSectionContent = (
            <PDVRsForm
                purpose='ADD'
                backCancelBtnFn={gotoListViewFn}
                createVrFn={createVoucherReceiptFn}
                setIsLoading={setIsLoading}
                setAlertErrorMsg={setAlertErrorMsg}
            />
        );
    }

    if (formView === view.EDIT_VOUCHER_RECEIPT) {
        mainSectionContent = (
            <PDVRsForm
                purpose='EDIT'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoPoProfileViewFn}
                updateVrFn={updateVoucherReceiptFn}
                setAlertErrorMsg={setAlertErrorMsg}
                setIsLoading={setIsLoading}
            />
        );
    }

    if (formView === view.DELETE_VOUCHER_RECEIPT) {
        mainSectionContent = (
            <PDVRsForm
                purpose='DELETE'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoPoProfileViewFn}
                deleteVrFn={deleteVoucherReceiptFn}
                setAlertErrorMsg={setAlertErrorMsg}
                setIsLoading={setIsLoading}
            />
        );
    }

    if (formView === view.VOUCHER_RECEIPT_PROFILE) {
        mainSectionContent = (
            <PDVRsForm
                purpose='VIEW'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoListViewFn}
                gotoEditViewFn={gotoEditViewFn}
                gotoDeleteViewFn={gotoDeleteViewFn}
                setIsLoading={setIsLoading}
                statusChangeFn={statusChangeFn}
                setAlertErrorMsg={setAlertErrorMsg}
            />
        );
    }

    return (
        <div className='content-layout'>
            {topSectionContent}

            <section className='main-section'>{mainSectionContent}</section>
        </div>
    );
};
