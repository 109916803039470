import React from 'react';

export default (props) => {
    let {
        deleteBtnFn,
        editBtnFn,
        backBtnFn,
        doNotShowDeleteBtn = false,
    } = props;

    let deleteBtn = doNotShowDeleteBtn ? null : (
        <button
            className='right-button-control btn btn-danger'
            onClick={deleteBtnFn}
        >
            Delete
        </button>
    );

    let buttonSection = (
        <section className='form-button-section'>
            <button
                className='left-button-control btn btn-primary'
                onClick={backBtnFn}
            >
                Back
            </button>
            <button className='middle-button-control btn' onClick={editBtnFn}>
                Edit
            </button>
            {deleteBtn}
        </section>
    );

    return buttonSection;
};
