import { db, firebase } from '../firebase_config/firebaseConfig';
import {
    responseFn,
    formatCustomErrorObj,
    formatAlertErrorObj,
    saveWithFiveDecimals,
} from '../lib/util';
import validate from '../validators';
import mListHelper from './mListHelper';
import dbopsGetDocument from './dbopsGetDocument';
import { docNames, appMessage } from '../lib/messages';
import {
    sdObjToMListItem,
    sfgInvObjToMListItemFn,
    fgInvObjToMListItemFn,
} from '../lib/masterListConverters';

import {
    userActions,
    systemActions,
    SG_NUMBERS_COL,
    SDPO_QUOTE_COUNTER_DOC,
    SDPOS_COL,
    MASTER_LISTS_COL,
    MASTER_SDPO_LIST_DOC,
    DELETED_SDPOS_COL,
    FINISHED_GOODS_COL,
    SEMI_FGS_COL,
    MASTER_INVENTORY_FG_LIST_DOC,
    MASTER_INVENTORY_SFG_LIST_DOC,
} from '../lib/constants';

// ================================================
const getSdpoFn = async (docID) => dbopsGetDocument(SDPOS_COL, docID, 'SDPO');

// Create Sdpo Quote
const createQuoteSdpoFn = async (userCreds, sdpoObj) => {
    sdpoObj.meta = {
        action: userActions.QUOTE,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // add metaHistory
    sdpoObj.metaHistory = [];

    const transactionFn = async (transaction) => {
        let counterRef = db
            .collection(SG_NUMBERS_COL)
            .doc(SDPO_QUOTE_COUNTER_DOC);
        let counterDoc = await transaction.get(counterRef);
        let currentSDPONumber = counterDoc.data().SdpoQuoteNumber.toString();

        currentSDPONumber = `Q-${currentSDPONumber}`;

        // add sdpoUID to sdpoObj.
        sdpoObj.sdpoUID = currentSDPONumber;
        sdpoObj.sdpoNumber = currentSDPONumber;
        sdpoObj.quoteUID = currentSDPONumber;

        // get a reference for SDPO document using currentSDPONumber
        let sdpoRef = db.collection(SDPOS_COL).doc(currentSDPONumber);
        let newSDPODoc = await transaction.get(sdpoRef);

        let sdpoListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_SDPO_LIST_DOC);
        let sdpoListDoc = await transaction.get(sdpoListRef);

        if (newSDPODoc.exists) {
            return Promise.reject(
                //! this should never run as PO number is server generated.
                formatCustomErrorObj(
                    1002,
                    'Purchase Order Quote Number already exists.'
                )
            );
        }
        await transaction.set(sdpoRef, sdpoObj);

        //create or update suppliers list document in (master lists collection)
        if (sdpoListDoc.exists) {
            let sdpoInfoObj = sdObjToMListItem(sdpoObj);

            let newData = mListHelper.updateMasterList(
                sdpoListDoc,
                sdpoObj.sdpoUID,
                sdpoInfoObj
            );

            await transaction.set(sdpoListRef, newData);
        } else {
            let sdpoInfoObj = sdObjToMListItem(sdpoObj);

            let newData = mListHelper.addMasterList(
                sdpoObj.sdpoUID,
                sdpoInfoObj
            );

            await transaction.set(sdpoListRef, newData);
        }

        // increment the SDPO number in the SGNumbers Collection Counters document.
        await transaction.set(
            counterRef,
            { SdpoQuoteNumber: counterDoc.data().SdpoQuoteNumber + 1 },
            { merge: true }
        );

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            'App: Successfully created SDPO Quote.'
        );
    } catch (e) {
        return responseFn(null, true, e, 'App: Error creating SDPO Quote.');
    }
};

// ======================================================
const createSdpoFn = async (userCreds, sdpoObj, quoteBasis) => {
    if (sdpoObj.metaHistory === undefined) {
        // if this is creation without quote.
        sdpoObj.meta = {
            action: userActions.CREATED,
            uid: userCreds.uid,
            email: userCreds.email,
            timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
        };

        sdpoObj.metaHistory = [];
    } else {
        if (sdpoObj.metaHistory.length > 99) sdpoObj.metaHistory.shift();

        sdpoObj.metaHistory.push(sdpoObj.meta);

        sdpoObj.meta = {
            action: userActions.CREATED,
            uid: userCreds.uid,
            email: userCreds.email,
            timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
        };
    }

    // Get references.
    let sdpoRef = db.collection(SDPOS_COL).doc(sdpoObj.sdpoUID);
    let sdpoListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_SDPO_LIST_DOC);

    let toBeDeletedSdpoQuoteRef = null;
    let deletedQuoteRef = null;
    if (quoteBasis) {
        toBeDeletedSdpoQuoteRef = db
            .collection(SDPOS_COL)
            .doc(sdpoObj.quoteUID);
        deletedQuoteRef = db.collection(DELETED_SDPOS_COL).doc();
    }

    const transactionFn = async (transaction) => {
        let newSDPODoc = await transaction.get(sdpoRef);
        let sdpoListDoc = await transaction.get(sdpoListRef);
        let toBeDeletedSdpoQuoteDoc = null;

        if (quoteBasis) {
            await transaction.get(deletedQuoteRef);
            toBeDeletedSdpoQuoteDoc = await transaction.get(
                toBeDeletedSdpoQuoteRef
            );

            if (
                !validate.noChange(toBeDeletedSdpoQuoteDoc.data(), quoteBasis)
            ) {
                return Promise.reject(
                    formatCustomErrorObj(
                        1001,
                        'SDPO Quote was edited before your changes could be applied. Try to create sdpo again.'
                    )
                );
            }
            await transaction.delete(toBeDeletedSdpoQuoteRef);
            await transaction.set(deletedQuoteRef, quoteBasis);
        }

        if (newSDPODoc.exists) {
            return Promise.reject(
                formatCustomErrorObj(
                    1002,
                    appMessage.alreadyExist(docNames.SDPO)
                )
            );
        }

        await transaction.set(sdpoRef, sdpoObj);

        //create or update suppliers list document in (master lists collection)
        // use tempSdpoListDoc as quote document might have been deleted.
        if (sdpoListDoc.exists) {
            let sdpoInfoObj = sdObjToMListItem(sdpoObj);

            let newData = mListHelper.updateMasterList(
                sdpoListDoc,
                sdpoObj.sdpoUID,
                sdpoInfoObj
            );

            // if creating sdpo from quote, delete the quote entry from
            // the masterlist.
            if (quoteBasis) {
                newData = mListHelper.deletePropertyFromTempMList(
                    newData,
                    quoteBasis.sdpoUID
                );
            }

            await transaction.set(sdpoListRef, newData);
        } else {
            // creating the first sdpo in the collection without the quote.
            let sdpoInfoObj = sdObjToMListItem(sdpoObj);

            let newData = mListHelper.addMasterList(
                sdpoObj.sdpoUID,
                sdpoInfoObj
            );

            await transaction.set(sdpoListRef, newData);
        }

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyCreated(docNames.SDPO)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorCreating(docNames.SDPO)
        );
    }
};

const updateSdpoFn = async (userCreds, sdpoObj, documentBasis) => {
    if (sdpoObj.metaHistory.length > 99) sdpoObj.metaHistory.shift();

    sdpoObj.metaHistory.push(sdpoObj.meta);

    let status =
        sdpoObj.meta.action === userActions.QUOTE
            ? userActions.QUOTE
            : userActions.UPDATED;

    sdpoObj.meta = {
        action: status,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // Get references.
    let sdpoRef = db.collection(SDPOS_COL).doc(sdpoObj.sdpoUID);
    let sdpoListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_SDPO_LIST_DOC);

    const transactionFn = async (transaction) => {
        let sdpoDoc = await transaction.get(sdpoRef);
        let sdpoListDoc = await transaction.get(sdpoListRef);

        if (!sdpoDoc.exists) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.SDPO)
                )
            );
        }

        if (!validate.noChange(sdpoDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.SDPO)
                )
            );
        }

        await transaction.set(sdpoRef, sdpoObj);

        //create or update suppliers list document in (master lists collection)
        if (sdpoListDoc.exists) {
            let sdpoInfoObj = sdObjToMListItem(sdpoObj);

            let newData = mListHelper.updateMasterList(
                sdpoListDoc,
                sdpoObj.sdpoUID,
                sdpoInfoObj
            );

            await transaction.set(sdpoListRef, newData);
        } else {
            let sdpoInfoObj = sdObjToMListItem(sdpoObj);

            let newData = mListHelper.addMasterList(
                sdpoObj.sdpoUID,
                sdpoInfoObj
            );

            await transaction.set(sdpoListRef, newData);
        }

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyUpdated(docNames.SDPO)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorUpdating(docNames.SDPO)
        );
    }
};

// ================================================================
const deleteSdpoFn = async (userCreds, sdpoObj, documentBasis) => {
    if (sdpoObj.metaHistory.length > 99) sdpoObj.metaHistory.shift();

    sdpoObj.metaHistory.push(sdpoObj.meta);

    sdpoObj.meta = {
        action: userActions.DELETED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // get all references.
    let sdpoRef = db.collection(SDPOS_COL).doc(sdpoObj.sdpoUID);
    let sdpoListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_SDPO_LIST_DOC);
    let deletedSdpoRef = db.collection(DELETED_SDPOS_COL).doc();

    const transactionFn = async (transaction) => {
        // Start: perform all transaction get ===========================
        let sdpoDoc = await transaction.get(sdpoRef);
        let sdpoListDoc = await transaction.get(sdpoListRef);

        // still need to perform transaction get but no need to do anything with it.
        await transaction.get(deletedSdpoRef);

        // End: perform all transaction get ===========================

        if (!sdpoDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotDeleteBecauseDocNotExist(docNames.SDPO)
                )
            );

        if (!validate.noChange(sdpoDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotDeleteBecauseDocChanged(docNames.SDPO)
                )
            );
        }

        let newData = mListHelper.deletePropertyFromMasterList(
            sdpoListDoc,
            sdpoObj.sdpoUID
        );

        await transaction.delete(sdpoRef);
        await transaction.set(sdpoListRef, newData);
        await transaction.set(deletedSdpoRef, sdpoObj);
        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyDeleted(docNames.SDPO)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorDeleting(docNames.SDPO)
        );
    }
};

// ================================================================
const statusChangeFn = async (userCreds, sdpoObj, documentBasis) => {
    if (sdpoObj.metaHistory.length > 99) sdpoObj.metaHistory.shift();

    sdpoObj.metaHistory.push(sdpoObj.meta);

    // update metadata in sdpoObj.
    sdpoObj.meta = {
        action: userCreds.action,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let sdpoRef = db.collection(SDPOS_COL).doc(sdpoObj.sdpoUID);
    let sdpoListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_SDPO_LIST_DOC);

    const transactionFn = async (transaction) => {
        //1. perform all transaction gets
        let sdpoDoc = await transaction.get(sdpoRef);
        let sdpoListDoc = await transaction.get(sdpoListRef);

        //2. check sdpoDoc against documentBasis if there is any change.
        if (!sdpoDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.SDPO)
                )
            );

        // ! this does not check meta data.
        if (!validate.noChange(sdpoDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.SDPO)
                )
            );
        }
        // ! meta data check if status of document changed.
        if (sdpoDoc.data().meta.action !== documentBasis.meta.action) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.SDPO)
                )
            );
        }

        let sdpoInfoObj = sdObjToMListItem(sdpoObj);

        let newData = mListHelper.updateMasterList(
            sdpoListDoc,
            sdpoObj.sdpoUID,
            sdpoInfoObj
        );
        // write to masterlist and sdpo document in firebase.
        await transaction.set(sdpoListRef, newData);
        await transaction.set(sdpoRef, sdpoObj);
        return Promise.resolve('TransactionFn Completed Successfully');
    };
    try {
        await db.runTransaction(transactionFn);
        return responseFn(null, false, null, appMessage.successfulUpdateStatus);
    } catch (e) {
        return responseFn(null, true, e, appMessage.errorUpdatingStatus);
    }
};

const approveSdpoFn = async (userCreds, sdpoObj, documentBasis) => {
    if (sdpoObj.metaHistory.length > 99) sdpoObj.metaHistory.shift();

    sdpoObj.metaHistory.push(sdpoObj.meta);

    // update metadata in sdpoObj.
    sdpoObj.meta = {
        action: userCreds.action,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let sdpoRef = db.collection(SDPOS_COL).doc(sdpoObj.sdpoUID);
    let sdpoListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_SDPO_LIST_DOC);

    // get all sfg Objects from sfgArr.
    let sfgArrRefs = sdpoObj.sfgArr.map((sfg) => {
        return db.collection(SEMI_FGS_COL).doc(sfg.sfgUID);
    });

    // get all fg Objects from fgArr.
    let fgArrRefs = sdpoObj.fgArr.map((fg) => {
        return db.collection(FINISHED_GOODS_COL).doc(fg.fgUID);
    });

    let sfgInvListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_INVENTORY_SFG_LIST_DOC);

    let fgInvListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_INVENTORY_FG_LIST_DOC);

    let existError = false;
    let balanceError = false;

    let whatSFG = [];
    let whatFG = [];
    let fgBalanceErrorMsg = '';
    let sfgBalanceErrorMsg = '';

    const transactionFn = async (transaction) => {
        // GET OPERATIONS ================================
        let sdpoDoc = await transaction.get(sdpoRef);
        let sdpoListDoc = await transaction.get(sdpoListRef);

        let sfgArrDocs = sfgArrRefs.map(
            async (sfgRef) => await transaction.get(sfgRef)
        );

        try {
            sfgArrDocs = await Promise.all(sfgArrDocs);
        } catch (e) {
            return Promise.reject(
                formatCustomErrorObj(
                    900,
                    appMessage.prommiseAllError('dbopsSDPOs', 'sfgArr')
                )
            );
        }

        let fgArrDocs = fgArrRefs.map(
            async (fgRef) => await transaction.get(fgRef)
        );

        try {
            fgArrDocs = await Promise.all(fgArrDocs);
        } catch (e) {
            return Promise.reject(
                formatCustomErrorObj(
                    900,
                    appMessage.prommiseAllError('dbopsSDPOs', 'fgArr')
                )
            );
        }

        // FINISH GET OPS ================================

        // check for changes and existence.
        if (!sdpoDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.SDPO)
                )
            );

        // ! this does not check meta data.
        if (!validate.noChange(sdpoDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.SDPO)
                )
            );
        }
        // ! meta data check if status of document changed.
        if (sdpoDoc.data().meta.action !== documentBasis.meta.action) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.SDPO)
                )
            );
        }

        sfgArrDocs.forEach((sfgDoc) => {
            if (!sfgDoc.exists) existError = true;
        });
        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopsSDPOs',
                        docNames.SDPO,
                        'SFG'
                    )
                )
            );
        }

        fgArrDocs.forEach((fgDoc) => {
            if (!fgDoc.exists) existError = true;
        });
        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopsSDPOs',
                        docNames.SDPO,
                        'FG'
                    )
                )
            );
        }

        let sfgInvListDoc = await transaction.get(sfgInvListRef);
        let fgInvListDoc = await transaction.get(fgInvListRef);

        // TRANSACTION SET ==================================

        // for SFG inventory process.
        let tempSfgInventoryList = sfgInvListDoc.data();

        sfgArrDocs.forEach(async (sfgDoc, index) => {
            let newArr = [...sfgDoc.data().sdpoNumbersArr];
            newArr.push(sdpoObj.sdpoUID);

            // check if there is enough projectedQty, else throw error.
            let newProjectedQty = saveWithFiveDecimals(
                sfgDoc.data().sfgProjectedQty -
                    sdpoObj.sfgArr[index].sfgOrderedQty
            );
            if (newProjectedQty < 0) {
                balanceError = true;
                whatSFG.push(sfgDoc.data().sfgUID);
                sfgBalanceErrorMsg = 'Insufficient SFG Projected Qty';
            }

            let updateData = {
                sfgProjectedQty: newProjectedQty,
                sdpoNumbersArr: newArr,
            };

            let sfgCopy = sfgDoc.data();
            sfgCopy.sfgProjectedQty = updateData.sfgProjectedQty;
            let inventorySfgInfoObj = sfgInvObjToMListItemFn(sfgCopy);

            tempSfgInventoryList = mListHelper.updateTempMList(
                tempSfgInventoryList,
                sfgCopy.sfgUID,
                inventorySfgInfoObj
            );

            await transaction.set(sfgArrRefs[index], updateData, {
                merge: true,
            });
        });

        // for FG inventory process.
        let tempFgInventoryList = fgInvListDoc.data();

        fgArrDocs.forEach(async (fgDoc, index) => {
            let newArr = [...fgDoc.data().sdpoNumbersArr];
            newArr.push(sdpoObj.sdpoUID);

            // check if there is enough projectedQty, else throw error.
            let newProjectedQty = saveWithFiveDecimals(
                fgDoc.data().fgProjectedQty - sdpoObj.fgArr[index].fgOrderedQty
            );
            if (newProjectedQty < 0) {
                balanceError = true;
                whatFG.push(fgDoc.data().fgUID);
                fgBalanceErrorMsg = 'Insufficient FG Projected Qty';
            }

            let updateData = {
                fgProjectedQty: newProjectedQty,
                sdpoNumbersArr: newArr,
            };

            let fgCopy = fgDoc.data();
            fgCopy.fgProjectedQty = updateData.fgProjectedQty;
            let inventoryFgInfoObj = fgInvObjToMListItemFn(fgCopy);

            tempFgInventoryList = mListHelper.updateTempMList(
                tempFgInventoryList,
                fgCopy.fgUID,
                inventoryFgInfoObj
            );

            await transaction.set(fgArrRefs[index], updateData, {
                merge: true,
            });
        });

        // balance error triggered from sfg or fg projected qty test above.
        if (balanceError) {
            return Promise.reject(
                formatCustomErrorObj(800, `Insufficient SFG/FG Projected Qty`)
            );
        }

        // set new sfg inventory master list
        await transaction.set(sfgInvListRef, tempSfgInventoryList);

        // set new fg inventory master list
        await transaction.set(fgInvListRef, tempFgInventoryList);

        await transaction.set(sdpoRef, sdpoObj);

        //create or update suppliers list document in (master lists collection)
        if (sdpoListDoc.exists) {
            let sdpoInfoObj = sdObjToMListItem(sdpoObj);

            let newData = mListHelper.updateMasterList(
                sdpoListDoc,
                sdpoObj.sdpoUID,
                sdpoInfoObj
            );

            await transaction.set(sdpoListRef, newData);
        } else {
            let sdpoInfoObj = sdObjToMListItem(sdpoObj);

            let newData = mListHelper.addMasterList(
                sdpoObj.sdpoUID,
                sdpoInfoObj
            );

            await transaction.set(sdpoListRef, newData);
        }

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(null, false, null, appMessage.successfulUpdateStatus);
    } catch (e) {
        let customErrorObj =
            sfgBalanceErrorMsg || fgBalanceErrorMsg
                ? formatAlertErrorObj(
                      sfgBalanceErrorMsg,
                      whatSFG,
                      fgBalanceErrorMsg,
                      whatFG
                  )
                : null;
        return responseFn(
            customErrorObj,
            true,
            e,
            appMessage.errorUpdatingStatus
        );
    }
};

const overRideSdpoFn = async (userCreds, sdpoObj, documentBasis) => {
    if (sdpoObj.metaHistory.length > 99) sdpoObj.metaHistory.shift();

    sdpoObj.metaHistory.push(sdpoObj.meta);

    // update metadata in sdpoObj.
    sdpoObj.meta = {
        action: userActions.OVERRIDDEN,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let sdpoRef = db.collection(SDPOS_COL).doc(sdpoObj.sdpoUID);
    let sdpoListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_SDPO_LIST_DOC);

    // get all sfg Objects from sfgArr.
    let sfgArrRefs = sdpoObj.sfgArr.map((sfg) => {
        return db.collection(SEMI_FGS_COL).doc(sfg.sfgUID);
    });

    // get all fg Objects from fgArr.
    let fgArrRefs = sdpoObj.fgArr.map((fg) => {
        return db.collection(FINISHED_GOODS_COL).doc(fg.fgUID);
    });

    let sfgInvListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_INVENTORY_SFG_LIST_DOC);

    let fgInvListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_INVENTORY_FG_LIST_DOC);

    let existError = false;
    let balanceError = false;

    let whatSFG = [];
    let whatFG = [];
    let fgBalanceErrorMsg = '';
    let sfgBalanceErrorMsg = '';

    const transactionFn = async (transaction) => {
        // GET OPERATIONS ================================
        let sdpoDoc = await transaction.get(sdpoRef);
        let sdpoListDoc = await transaction.get(sdpoListRef);

        let sfgArrDocs = sfgArrRefs.map(
            async (sfgRef) => await transaction.get(sfgRef)
        );

        try {
            sfgArrDocs = await Promise.all(sfgArrDocs);
        } catch (e) {
            return Promise.reject(
                formatCustomErrorObj(
                    900,
                    appMessage.prommiseAllError('dbopsSDPOs', 'sfgArr')
                )
            );
        }

        let fgArrDocs = fgArrRefs.map(
            async (fgRef) => await transaction.get(fgRef)
        );

        try {
            fgArrDocs = await Promise.all(fgArrDocs);
        } catch (e) {
            return Promise.reject(
                formatCustomErrorObj(
                    900,
                    appMessage.prommiseAllError('dbopsSDPOs', 'fgArr')
                )
            );
        }

        let sfgInvListDoc = await transaction.get(sfgInvListRef);
        let fgInvListDoc = await transaction.get(fgInvListRef);

        // FINISH GET OPS ================================

        // check for changes and existence.
        if (!sdpoDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.SDPO)
                )
            );

        // ! this does not check meta data.
        if (!validate.noChange(sdpoDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.SDPO)
                )
            );
        }
        // ! meta data check if status of document changed.
        if (sdpoDoc.data().meta.action !== documentBasis.meta.action) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.SDPO)
                )
            );
        }

        sfgArrDocs.forEach((sfgDoc) => {
            if (!sfgDoc.exists) existError = true;
        });
        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopsSDPOs',
                        docNames.SDPO,
                        'SFG'
                    )
                )
            );
        }

        fgArrDocs.forEach((fgDoc) => {
            if (!fgDoc.exists) existError = true;
        });
        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopsSDPOs',
                        docNames.SDPO,
                        'FG'
                    )
                )
            );
        }

        // START TRANSACTION SET ==================================

        // ========================================================================
        // FG check if po status can be changed to 'FULFILLED'. ==================
        let allFgsDeliveredOrOverridden = [];
        sdpoObj.fgArr.forEach((fg) => {
            if (fg.fgBalanceQty + fg.fgOverrideQty === 0) {
                allFgsDeliveredOrOverridden.push(true);
            } else {
                allFgsDeliveredOrOverridden.push(false);
            }
        });

        // copy of fgDocs so sdpoNumbersArr can be manipulated.
        let fgArrDocsCopy = fgArrDocs.map((fgDoc) => fgDoc.data());

        if (allFgsDeliveredOrOverridden.every((ans) => ans === true)) {
            //! remove sdpoUID from all the fg.sdpoNumbersArr.
            fgArrDocsCopy.forEach((fgObj) => {
                let newArr = fgObj.sdpoNumbersArr.filter(
                    (poNumber) => poNumber !== sdpoObj.sdpoUID
                );
                fgObj.sdpoNumbersArr = newArr;
            });
        } else {
            //! include sdpoUID in all fg.sdpoNumbersArr of this sdpo document.
            fgArrDocsCopy.forEach((fgObj) => {
                let newArr = fgObj.sdpoNumbersArr.filter(
                    (poNumber) => poNumber !== sdpoObj.sdpoUID
                );
                newArr.push(sdpoObj.sdpoUID);
                fgObj.sdpoNumbersArr = newArr;
            });
        }

        // for FG inventory process.
        let tempFgInventoryList = fgInvListDoc.data();

        // calculate all fg projectedQty ==================================
        fgArrDocs.forEach(async (fgDoc, index) => {
            let current = sdpoObj.fgArr[index].fgOverrideQty;
            let previous = documentBasis.fgArr[index].fgOverrideQty;
            let newProjected = saveWithFiveDecimals(
                fgDoc.data().fgProjectedQty - (current - previous)
            );

            if (newProjected < 0) {
                balanceError = true;
                whatFG.push(fgDoc.data().fgUID);
                fgBalanceErrorMsg = 'Insufficient FG Projected Qty';
            }

            let updateData = {
                fgProjectedQty: newProjected,
                sdpoNumbersArr: fgArrDocsCopy[index].sdpoNumbersArr,
            };

            let fgCopy = fgDoc.data();
            fgCopy.fgProjectedQty = updateData.fgProjectedQty;
            let inventoryFgInfoObj = fgInvObjToMListItemFn(fgCopy);

            tempFgInventoryList = mListHelper.updateTempMList(
                tempFgInventoryList,
                fgCopy.fgUID,
                inventoryFgInfoObj
            );

            // update all rms in firebase ========================================
            await transaction.set(fgArrRefs[index], updateData, {
                merge: true,
            });
        });

        // ========================================================================
        // SFG check if po status can be changed to 'FULFILLED'. ==================
        let allSfgsDeliveredOrOverridden = [];
        sdpoObj.sfgArr.forEach((sfg) => {
            if (sfg.sfgBalanceQty + sfg.sfgOverrideQty === 0) {
                allSfgsDeliveredOrOverridden.push(true);
            } else {
                allSfgsDeliveredOrOverridden.push(false);
            }
        });

        // copy of fgDocs so sdpoNumbersArr can be manipulated.
        let sfgArrDocsCopy = sfgArrDocs.map((sfgDoc) => sfgDoc.data());

        if (allSfgsDeliveredOrOverridden.every((ans) => ans === true)) {
            //! remove sdpoUID from all the sfg.sdpoNumbersArr.
            sfgArrDocsCopy.forEach((sfgObj) => {
                let newArr = sfgObj.sdpoNumbersArr.filter(
                    (poNumber) => poNumber !== sdpoObj.sdpoUID
                );
                sfgObj.sdpoNumbersArr = newArr;
            });
        } else {
            //! include sdpoUID in all fg.sdpoNumbersArr of this sdpo document.
            sfgArrDocsCopy.forEach((sfgObj) => {
                let newArr = sfgObj.sdpoNumbersArr.filter(
                    (poNumber) => poNumber !== sdpoObj.sdpoUID
                );
                newArr.push(sdpoObj.sdpoUID);
                sfgObj.sdpoNumbersArr = newArr;
            });
        }

        // for SFG inventory process.
        let tempSfgInventoryList = sfgInvListDoc.data();

        // calculate all sfg projectedQty ==================================
        sfgArrDocs.forEach(async (sfgDoc, index) => {
            let current = sdpoObj.sfgArr[index].sfgOverrideQty;
            let previous = documentBasis.sfgArr[index].sfgOverrideQty;
            let newProjected = saveWithFiveDecimals(
                sfgDoc.data().sfgProjectedQty - (current - previous)
            );

            if (newProjected < 0) {
                balanceError = true;
                whatSFG.push(sfgDoc.data().sfgUID);
                sfgBalanceErrorMsg = 'Insufficient SFG Projected Qty';
            }

            let updateData = {
                sfgProjectedQty: newProjected,
                sdpoNumbersArr: sfgArrDocsCopy[index].sdpoNumbersArr,
            };

            let sfgCopy = sfgDoc.data();
            sfgCopy.sfgProjectedQty = updateData.sfgProjectedQty;
            let inventorySfgInfoObj = sfgInvObjToMListItemFn(sfgCopy);

            tempSfgInventoryList = mListHelper.updateTempMList(
                tempSfgInventoryList,
                sfgCopy.sfgUID,
                inventorySfgInfoObj
            );

            // update all rms in firebase ========================================
            await transaction.set(sfgArrRefs[index], updateData, {
                merge: true,
            });
        });

        if (balanceError) {
            return Promise.reject(
                formatCustomErrorObj(800, `Insufficient SFG/FG Projected Qty`)
            );
        }

        //! Change status of sdpo depending on fgs and sfgs delivered.
        if (
            allFgsDeliveredOrOverridden.every((ans) => ans === true) &&
            allSfgsDeliveredOrOverridden.every((ans) => ans === true)
        ) {
            // update metadata in sdpoObj.
            sdpoObj.meta = {
                action: systemActions.FULFILLED,
                uid: systemActions.UID,
                email: systemActions.EMAIL,
                alert: {
                    note:
                        '"OVERRIDDEN" first by user then system changed status to "FULFILLED"',
                    action: userActions.OVERRIDDEN,
                    uid: userCreds.uid,
                    email: userCreds.email,
                    timeStamp:
                        'Same time as system changed status of this document.',
                },
                timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
            };
        }

        await transaction.set(sdpoRef, sdpoObj);

        // set new sfg inventory master list
        await transaction.set(sfgInvListRef, tempSfgInventoryList);

        // set new fg inventory master list
        await transaction.set(fgInvListRef, tempFgInventoryList);

        //update sdpo list document in (master lists collection)
        if (sdpoListDoc.exists) {
            let sdpoInfoObj = sdObjToMListItem(sdpoObj);

            let newData = mListHelper.updateMasterList(
                sdpoListDoc,
                sdpoObj.sdpoUID,
                sdpoInfoObj
            );

            await transaction.set(sdpoListRef, newData);
        } else {
            let sdpoInfoObj = sdObjToMListItem(sdpoObj);

            let newData = mListHelper.addMasterList(
                sdpoObj.sdpoUID,
                sdpoInfoObj
            );

            await transaction.set(sdpoListRef, newData);
        }

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(null, false, null, appMessage.successfulUpdateStatus);
    } catch (e) {
        let customErrorObj =
            sfgBalanceErrorMsg || fgBalanceErrorMsg
                ? formatAlertErrorObj(
                      sfgBalanceErrorMsg,
                      whatSFG,
                      fgBalanceErrorMsg,
                      whatFG
                  )
                : null;
        return responseFn(
            customErrorObj,
            true,
            e,
            appMessage.errorUpdatingStatus
        );
    }
};

export default {
    getSdpoFn,
    createQuoteSdpoFn,
    createSdpoFn,
    updateSdpoFn,
    deleteSdpoFn,
    statusChangeFn,
    approveSdpoFn,
    overRideSdpoFn,
};
