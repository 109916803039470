export default (userMasterlistStringFormat) => {
    let newArr = [];
    let userObjsInObjFormat = JSON.parse(userMasterlistStringFormat);

    for (let key in userObjsInObjFormat) {
        newArr.push({
            userUID: key,
            email: userObjsInObjFormat[key].a,
            displayName: userObjsInObjFormat[key].b,
        });
    }

    return newArr;
};
