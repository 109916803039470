import { lengths } from '../lib/constants';
import { generalMessage } from '../lib/messages';
import { saveWithFiveDecimals } from '../lib/util';
import { validateUID } from '../lib/util';

export const rawMaterial = (
    originalRMObj,
    categoryOptions,
    purpose,
    documentBasis = {}
) => {
    let rmCodeErr = '';
    let rmCategoryErr = '';
    let rmDescriptionErr = '';
    let rmLowThresholdErr = '';
    let rmTypeErr = '';
    let rmUnitErr = '';
    let rmActualQtyErr = '';
    let rmAltUnitErr = '';
    let rmAltUnitMultiplierErr = '';
    let hackError = '';

    let {
        rmCategory,
        rmCode,
        rmDescription,
        rmType,
        rmUnit,
        rmLowThreshold,
        rmActualQty,
        rmAltUnit,
        rmAltUnitMultiplier,
    } = originalRMObj;

    // make a new copy of rmObj to ensure no side effects happen outside the function.
    let rmObj = { ...originalRMObj };

    // rmDescription validation
    rmDescriptionErr = validateUID(rmDescription).errorMessage;

    // rmType validation !!
    rmTypeErr = validateUID(rmType).errorMessage;

    // rmCategory validation  !! REQUIRED and Must 'Packaging, Colorant, Resin'
    if (rmCategory === '' || rmCategory === ' ')
        rmCategoryErr = generalMessage.REQUIRED;
    else {
        let validOptions = categoryOptions.map((cat) => cat.value);

        if (!validOptions.includes(rmCategory))
            rmCategoryErr = generalMessage.INVALID;
    }

    // rmUnit validation  !! Required
    if (rmUnit === '' || rmUnit === ' ') rmUnitErr = generalMessage.REQUIRED;
    if (rmUnit.length > lengths.unit) rmUnitErr = generalMessage.INVALID_LENGTH;

    // rmLowThreshold validation !! Required
    if (rmLowThreshold === '' || rmLowThreshold === ' ')
        rmLowThresholdErr = generalMessage.REQUIRED;
    if (Number(rmLowThreshold) > lengths.numberInputAmount)
        rmLowThresholdErr = generalMessage.INVALID_AMOUNT;
    else {
        if (isNaN(rmLowThreshold)) {
            rmLowThresholdErr = generalMessage.INVALID;
        }
        if (!isNaN(rmLowThreshold) && Number(rmLowThreshold) < 0) {
            rmLowThresholdErr = generalMessage.INVALID;
        }
    }

    // feature added 6-17-20.  rmActual qty validation as admin is now allowed to enter the
    // rmActual qty.
    if (rmActualQty === '') rmActualQty = 0;

    if (Number(rmActualQty) > lengths.numberInputAmount)
        rmActualQtyErr = generalMessage.INVALID_AMOUNT;
    else {
        if (isNaN(rmActualQty)) {
            rmActualQtyErr = generalMessage.INVALID;
        }
        if (!isNaN(rmActualQty) && Number(rmActualQty) < 0) {
            rmActualQtyErr = generalMessage.INVALID;
        }
    }

    // rmCode validation
    if (rmCode.length > lengths.code) rmCodeErr = generalMessage.INVALID_LENGTH;

    // rmAltUnit validation
    if (rmAltUnit.length > lengths.unit)
        rmAltUnitErr = generalMessage.INVALID_LENGTH;

    // rmAltUnitMultiplier valiation
    if (rmAltUnitMultiplier !== '' || rmAltUnitMultiplier !== ' ') {
        if (Number(rmAltUnitMultiplier) > lengths.numberInputAmount)
            rmAltUnitMultiplierErr = generalMessage.INVALID_AMOUNT;
        else {
            if (isNaN(rmAltUnitMultiplier)) {
                rmAltUnitMultiplierErr = generalMessage.INVALID;
            }
            if (
                !isNaN(rmAltUnitMultiplier) &&
                Number(rmAltUnitMultiplier) < 0
            ) {
                rmAltUnitMultiplierErr = generalMessage.INVALID;
            }
        }
    }

    // if rmAltUnit is not blank, the multiplier must not be blank
    if (rmAltUnit !== '' && rmAltUnitMultiplier === '') {
        rmAltUnitMultiplierErr = 'Required if RM has Altenate Unit';
    }
    if (rmAltUnit === '' && rmAltUnitMultiplier !== '') {
        rmAltUnitErr = 'Required if RM has Altenate Unit Multiplier';
    }

    if (
        rmCodeErr ||
        rmCategoryErr ||
        rmDescriptionErr ||
        rmLowThresholdErr ||
        rmTypeErr ||
        rmUnitErr ||
        hackError ||
        rmAltUnitErr ||
        rmAltUnitMultiplierErr ||
        rmActualQtyErr
    ) {
        return {
            error: true,
            errorObj: {
                rmCodeErr,
                rmCategoryErr,
                rmDescriptionErr,
                rmLowThresholdErr,
                rmTypeErr,
                rmUnitErr,
                hackError,
                rmActualQtyErr,
                rmAltUnitErr,
                rmAltUnitMultiplierErr,
            },
        };
    }

    // if there is no Error,
    // set data type number
    rmObj.rmLowThreshold = saveWithFiveDecimals(rmLowThreshold);

    if (rmObj.rmAltUnitMultiplier !== '')
        rmObj.rmAltUnitMultiplier = saveWithFiveDecimals(rmAltUnitMultiplier);

    if (purpose === 'ADD') {
        rmObj.rmUID = `${rmObj.rmType} - ${rmObj.rmDescription}`;
        // complete the schema
        rmObj.poNumbersArr = [];
        rmObj.prodOrderNumbersArr = [];
        rmObj.assemblyOrderNumbersArr = [];

        // empty rmActualQty was converted to 0 already.
        if (rmActualQty === 0) {
            rmObj.rmActualQty = 0; // number
            rmObj.rmProjectedQty = 0; // number
        } else {
            rmObj.rmActualQty = saveWithFiveDecimals(rmActualQty);
            rmObj.rmProjectedQty = saveWithFiveDecimals(rmActualQty);
        }
    }

    // will change projected and actual qty automatically.
    if (
        purpose === 'EDIT' &&
        Number(rmActualQty) !== Number(documentBasis.rmActualQty)
    ) {
        let result = Number(rmActualQty) - Number(documentBasis.rmActualQty);
        rmObj.rmProjectedQty = saveWithFiveDecimals(
            documentBasis.rmProjectedQty + result
        );
        rmObj.rmActualQty = saveWithFiveDecimals(
            documentBasis.rmActualQty + result
        );
    }

    return {
        error: false,
        errorObj: {},
        data: rmObj,
    };
};
