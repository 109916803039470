import React from 'react';
import { displayMoney } from '../../../lib/util';
import './SetupSfgFgItem.css';
import '../CollectionItems.css';

export default (props) => {
    let { obj, serialNumber, onClick, heading, keyProperty } = props;

    let prefix = keyProperty === 'sfgUID' ? 'sfg' : 'fg';

    let serial = 'S/No.';
    let itemName = prefix === 'sfg' ? 'SFG Name' : 'FG Name';
    let code = 'Code';
    let price = 'Price';
    let headingClass = 'text-bold list-table-heading';

    let serialLabel = serial + ':';
    let itemNameLabel = itemName + ':';
    let codeLabel = 'Code:';
    let priceLabel = 'Price:';

    if (!heading) {
        serial = serialNumber;
        itemName = obj[`${prefix}UID`];
        code = obj[`${prefix}Code`];
        price = displayMoney(obj[`${prefix}Price`]);

        headingClass = '';
    }

    // let priceClass = !heading ? 'tj-right' : '';

    return (
        <div
            className={`setup-sfgfg-item collection-item ${headingClass}`}
            onClick={onClick}
        >
            <div className='collection-item-entry serial'>
                {' '}
                <span className='entry-label'>{serialLabel}</span>
                {serial}
            </div>
            <div className='collection-item-entry name'>
                {' '}
                <span className='entry-label'>{itemNameLabel}</span>
                {itemName}
            </div>
            <div className='collection-item-entry code'>
                {' '}
                <span className='entry-label'>{codeLabel}</span>
                {code}
            </div>
            <div className='collection-item-entry price'>
                {' '}
                <span className='entry-label'>{priceLabel}</span>
                {/* <span className={priceClass}>{price}</span> */}
                <span>{price}</span>
            </div>
        </div>
    );
};
