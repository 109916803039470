import React from 'react';

export default (props) => {
    let { backBtnFn, markAsBtnFn, markAsBtnText } = props;

    let buttonSection = (
        <section className='form-button-section'>
            <button className='goto-left btn' onClick={backBtnFn}>
                Back
            </button>

            <button className='btn btn-primary' onClick={markAsBtnFn}>
                {markAsBtnText}
            </button>
        </section>
    );

    return buttonSection;
};
