import { lengths, customerPriceList } from '../lib/constants';
import { generalMessage } from '../lib/messages';
import { validateUID, displayMoney } from '../lib/util';

export const customer = (customerObj, purpose) => {
    let customerNameErr = '';
    let contactNumber1Err = '';
    let contactNumber2Err = '';
    let faxNumberErr = '';
    let customerCodeErr = '';
    let contactPersonErr = '';
    let addressErr = '';
    let noteErr = '';
    let priceListNumError = '';
    let discountError = '';

    let {
        customerUID,
        customerName,
        contactNumber1,
        contactNumber2,
        faxNumber,
        contactPerson,
        customerCode,
        address,
        note,
        priceListNum,
        discount,
    } = customerObj;

    //! customerName validation  !! REQUIRED
    customerNameErr = validateUID(customerName).errorMessage;

    // customerCode validation
    if (customerCode.length > lengths.code)
        customerCodeErr = generalMessage.INVALID_LENGTH;

    //! contactNumber1 validation !! REQUIRED
    if (contactNumber1 === '') contactNumber1Err = generalMessage.REQUIRED;
    else {
        // if (!regEx.phone.test(contactNumber1))
        //     contactNumber1Err = generalMessage.INVALID_FORMAT;
        if (contactNumber1.length > lengths.phone)
            contactNumber1Err = generalMessage.INVALID_LENGTH;
    }

    // contactNumber2 validation
    // if (contactNumber2 !== '' && !regEx.phone.test(contactNumber2))
    //     contactNumber2Err = generalMessage.INVALID_FORMAT;
    if (contactNumber2.length > lengths.phone)
        contactNumber2Err = generalMessage.INVALID_LENGTH;

    // faxNumber validation
    // if (faxNumber !== '' && !regEx.phone.test(faxNumber))
    //     faxNumberErr = generalMessage.INVALID_FORMAT;
    if (faxNumber.length > lengths.phone)
        faxNumberErr = generalMessage.INVALID_LENGTH;

    // contactPerson validation
    if (contactPerson.length > lengths.contactPerson)
        contactPersonErr = generalMessage.INVALID_LENGTH;

    // priceListNum validation  !! REQUIRED and Must 'Packaging, Colorant, Resin'
    if (priceListNum === '' || priceListNum === ' ')
        priceListNumError = generalMessage.REQUIRED;
    else {
        let {
            PRICE_LIST_1,
            PRICE_LIST_2,
            PRICE_LIST_3,
            PRICE_LIST_4,
            PRICE_LIST_5,
        } = customerPriceList;
        if (
            ![
                PRICE_LIST_1,
                PRICE_LIST_2,
                PRICE_LIST_3,
                PRICE_LIST_4,
                PRICE_LIST_5,
            ].includes(priceListNum)
        )
            priceListNumError = generalMessage.INVALID;
    }

    // discount validation
    if (isNaN(discount)) {
        discountError = generalMessage.INVALID;
    } else {
        if (Number(discount) > 100 || Number(discount) < 0) {
            discountError = generalMessage.INVALID_AMOUNT;
        }
    }

    // address validation
    if (address.length > lengths.regTextLength)
        addressErr = generalMessage.INVALID_LENGTH;

    // note valiation
    if (note.length > lengths.longTextLength)
        noteErr = generalMessage.INVALID_LENGTH;

    if (
        customerNameErr ||
        contactNumber1Err ||
        contactNumber2Err ||
        faxNumberErr ||
        contactPersonErr ||
        customerCodeErr ||
        addressErr ||
        noteErr ||
        priceListNumError ||
        discountError
    ) {
        return {
            error: true,
            errorObj: {
                customerNameErr,
                contactNumber1Err,
                contactNumber2Err,
                faxNumberErr,
                contactPersonErr,
                addressErr,
                noteErr,
                customerCodeErr,
                priceListNumError,
                discountError,
            },
        };
    }

    // this will attempt to ensure customer UID and customerName are always equal.
    // unless UI is hacked.
    if (purpose === 'ADD') customerUID = customerName;
    else customerName = customerUID;

    let completeCustomerSchema = {
        ...customerObj, // this will copy the meta property and metaHistory property
        // the rest will be overwritten.
        customerUID,
        customerName,
        contactNumber1,
        contactNumber2,
        faxNumber,
        contactPerson,
        customerCode,
        address,
        note,
        priceListNum,
        discount,
    };
    return {
        error: false,
        errorObj: null,
        data: completeCustomerSchema,
    };
};
