import React, { useRef } from 'react';
import './Input.css';

export default React.forwardRef((props, ref) => {
    let {
        keyID,
        id,
        label,
        disabled,
        className,
        maxLength,
        minLength,
        required,
        placeholder,
        errorMessage,
        type,
        autoFocus,
        getInputValue,
        initialValue,
        onFocus,

        mobileLabel,
        desktopLabel,
    } = props;

    let selfRef = useRef();

    let reference = ref || selfRef;

    // feature added June-13-2020 for label to show on desktop view and mobile view only.
    // original cannot be deleted because app will break in so many places.
    // Solution is to add 2 more labels specific for desktop and mobile view.
    let originalLabelUI = (
        <label className='input-label' htmlFor={id}>
            {label}
        </label>
    );
    let desktopLabelUI = (
        <label className='desktop-view input-label' htmlFor={id}>
            {desktopLabel}
        </label>
    );
    let mobileLabelUI = (
        <label className='mobile-view input-label' htmlFor={id}>
            {mobileLabel}
        </label>
    );

    if (desktopLabel === undefined || desktopLabel === '')
        desktopLabelUI = null;
    if (mobileLabel === undefined || mobileLabel === '') mobileLabelUI = null;

    return (
        <div className='input-wrapper' key={keyID}>
            {originalLabelUI}
            {desktopLabelUI}
            {mobileLabelUI}
            <div className='input-and-datalist-container'>
                <input
                    ref={reference}
                    disabled={disabled}
                    type={type}
                    id={id}
                    className={className}
                    required={required}
                    onChange={(e) => getInputValue(e.target.value)}
                    placeholder={placeholder}
                    value={initialValue}
                    maxLength={maxLength}
                    minLength={minLength}
                    autoFocus={autoFocus}
                    onFocus={onFocus}
                    // onBlur={() => passValueToParent(val)}
                    // onKeyDown={(e) => keyDownFn(e, val)}
                ></input>
                <span className='input-error'>{errorMessage}</span>
            </div>
        </div>
    );
});

// import React, { useState, useEffect, useRef } from 'react';
// import './Input.css';

// export default React.forwardRef((props, ref) => {
//     let {
//         keyID,
//         id,
//         label,
//         disabled,
//         className,
//         maxLength,
//         minLength,
//         required,
//         placeholder,
//         errorMessage,
//         type,
//         autoFocus,
//         getInputValue,
//         initialValue,
//         onFocus,
//     } = props;

//     let selfRef = useRef();

//     let reference = ref || selfRef;

//     let [val, setVal] = useState('');

//     const passValueToParent = (value) => getInputValue(value);

//     const keyDownFn = (e, val) => {
//         if (e.keyCode === 13) {
//             reference.current.blur();
//             passValueToParent(val);
//         }
//     };

//     useEffect(() => {
//         setVal(initialValue);
//     }, [initialValue]);

//     return (
//         <div className='input-wrapper' key={keyID}>
//             <label className='input-label' htmlFor={id}>
//                 {label}
//             </label>
//             <input
//                 ref={reference}
//                 disabled={disabled}
//                 type={type}
//                 id={id}
//                 className={className}
//                 required={required}
//                 onChange={(e) => setVal(e.target.value)}
//                 placeholder={placeholder}
//                 value={val}
//                 maxLength={maxLength}
//                 minLength={minLength}
//                 autoFocus={autoFocus}
//                 onFocus={onFocus}
//                 onBlur={() => passValueToParent(val)}
//                 onKeyDown={(e) => keyDownFn(e, val)}
//             ></input>
//             <span className='input-error'>{errorMessage}</span>
//         </div>
//     );
// });
