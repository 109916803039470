import React from 'react';
import './SetupRmItem.css';
import '../CollectionItems.css';

export default (props) => {
    let { obj, serialNumber, onClick, heading } = props;

    let rmType = '';
    let rmDescription = '';
    let serial = 'S/No.';
    let type = 'Raw Material Type';
    let description = 'Raw Material Description';
    let code = 'Code';
    let category = 'Category';
    let headingClass = 'text-bold list-table-heading';

    let serialLabel = serial + ':';
    let typeLabel = 'RM Type:';
    let descriptionLabel = 'RM Description:';
    let codeLabel = 'Code:';
    let categoryLabel = 'Category:';

    if (!heading) {
        [rmType, rmDescription] = obj.rmUID.split(' - ');
        serial = serialNumber;
        type = rmType;
        description = rmDescription;
        code = obj.rmCode;
        category = obj.rmCategory;
        headingClass = '';
    }

    return (
        <div
            className={`setup-rm-item collection-item ${headingClass}`}
            onClick={onClick}
        >
            <div className='collection-item-entry serial'>
                {' '}
                <span className='entry-label'>{serialLabel}</span>
                {serial}
            </div>
            <div className='collection-item-entry rmtype'>
                {' '}
                <span className='entry-label'>{typeLabel}</span>
                {type}
            </div>
            <div className='collection-item-entry rmdescription'>
                {' '}
                <span className='entry-label'>{descriptionLabel}</span>
                {description}
            </div>
            <div className='collection-item-entry rmcode'>
                {' '}
                <span className='entry-label'>{codeLabel}</span>
                {code}
            </div>
            <div className='collection-item-entry rmcategory'>
                {' '}
                <span className='entry-label'>{categoryLabel}</span>
                {category}
            </div>
        </div>
    );
};
