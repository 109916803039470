import React from 'react';

export default (props) => {
    let {
        cancelFn,
        deleteFn,
        confirmeDeleteFn,
        confirmDeleteTextValue,
        confirmDeleteTextPlaceHolder,
        uidValue,
        deleteBtnText,
    } = props;

    let deleteText = deleteBtnText ? deleteBtnText : 'DELETE';

    let deleteBtn = (
        <button
            className='right-button-control btn btn-danger'
            onClick={deleteFn}
        >
            {deleteText}
        </button>
    );

    if (uidValue !== confirmDeleteTextValue) {
        deleteBtn = (
            <button className='right-button-control btn btn-disable'>
                {deleteText}
            </button>
        );
    }

    let buttonSection = (
        <section className='form-button-section'>
            <button className='left-button-control btn' onClick={cancelFn}>
                Cancel
            </button>

            <div className='confirm-delete-container'>
                <input
                    id='delete-input'
                    placeholder={confirmDeleteTextPlaceHolder}
                    maxLength={50}
                    value={confirmDeleteTextValue}
                    onChange={confirmeDeleteFn}
                />
            </div>
            {deleteBtn}
        </section>
    );

    return buttonSection;
};
