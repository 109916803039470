import { lengths } from '../lib/constants';
import { generalMessage, appMessage } from '../lib/messages';
import {
    saveWithTwoDecimalOnly,
    saveWithFiveDecimals,
    clone,
} from '../lib/util';
import { validateUID } from '../lib/util';

export const finishedGood = (
    originalfgObj,
    rmUIDsArr,
    sfgNamesArr,
    purpose,
    masterListRM,
    masterListSFG,
    documentBasis
) => {
    let fgCodeErr = '';
    let fgNameErr = '';
    let fgLowThresholdErr = '';
    let fgUnitErr = '';
    let arraysErr = '';
    let fgPriceErr = '';
    let fgPriceErr2 = '';
    let fgPriceErr3 = '';
    let fgPriceErr4 = '';
    let fgPriceErr5 = '';
    let fgActualQtyErr = '';

    let rmArrWithError = false;
    let sfgArrWithError = false;

    let {
        fgCode,
        fgName,
        fgUnit,
        fgLowThreshold,
        rmArr,
        sfgArr,
        fgPrice,
        fgPrice2,
        fgPrice3,
        fgPrice4,
        fgPrice5,
        fgActualQty,
    } = originalfgObj;

    // make a new copy of rmArr and sfgArr.
    rmArr = clone(rmArr);
    sfgArr = clone(sfgArr);

    // make a new copy of fgObj to ensure no side effect happens outside the function.
    let fgObj = {
        ...originalfgObj,
        rmArr,
        sfgArr,
    };

    //! start validation of each property.
    fgNameErr = validateUID(fgName).errorMessage;

    // fgUnit validation  !! Required
    if (fgUnit === '' || fgUnit === ' ') fgUnitErr = generalMessage.REQUIRED;
    if (fgUnit.length > lengths.unit) fgUnitErr = generalMessage.INVALID_LENGTH;

    // fgLowThreshold validation !! Required
    if (fgLowThreshold === '' || fgLowThreshold === ' ')
        fgLowThresholdErr = generalMessage.REQUIRED;
    if (Number(fgLowThreshold) > lengths.numberInputAmount)
        fgLowThresholdErr = generalMessage.INVALID_AMOUNT;
    else {
        if (isNaN(fgLowThreshold)) {
            fgLowThresholdErr = generalMessage.INVALID;
        }
        if (!isNaN(fgLowThreshold) && Number(fgLowThreshold) < 0) {
            fgLowThresholdErr = generalMessage.INVALID;
        }
    }

    // fgPrice validation
    if (fgPrice === '' || fgPrice === ' ') fgPriceErr = generalMessage.REQUIRED;
    if (Number(fgPrice) > lengths.numberInputAmount)
        fgPriceErr = generalMessage.INVALID_AMOUNT;
    else {
        if (isNaN(Number(fgPrice))) {
            fgPriceErr = generalMessage.INVALID;
        }
        if (!isNaN(fgPrice) && Number(fgPrice) < 0.01) {
            fgPriceErr = generalMessage.INVALID_AMOUNT;
        }
    }

    // fgPrice2 validation

    if (Number(fgPrice2) > lengths.numberInputAmount)
        fgPriceErr2 = generalMessage.INVALID_AMOUNT;
    else {
        if (isNaN(fgPrice2)) {
            fgPriceErr2 = generalMessage.INVALID;
        }
        if (!isNaN(fgPrice2) && Number(fgPrice2) < 0) {
            fgPriceErr2 = generalMessage.INVALID_AMOUNT;
        }
    }

    // fgPrice3 validation

    if (Number(fgPrice3) > lengths.numberInputAmount)
        fgPriceErr3 = generalMessage.INVALID_AMOUNT;
    else {
        if (isNaN(fgPrice3)) {
            fgPriceErr3 = generalMessage.INVALID;
        }
        if (!isNaN(fgPrice3) && Number(fgPrice3) < 0) {
            fgPriceErr3 = generalMessage.INVALID_AMOUNT;
        }
    }

    // fgPrice4 validation

    if (Number(fgPrice4) > lengths.numberInputAmount)
        fgPriceErr4 = generalMessage.INVALID_AMOUNT;
    else {
        if (isNaN(fgPrice4)) {
            fgPriceErr4 = generalMessage.INVALID;
        }
        if (!isNaN(fgPrice4) && Number(fgPrice4) < 0) {
            fgPriceErr4 = generalMessage.INVALID_AMOUNT;
        }
    }

    // fgPrice5 validation

    if (Number(fgPrice5) > lengths.numberInputAmount)
        fgPriceErr5 = generalMessage.INVALID_AMOUNT;
    else {
        if (isNaN(fgPrice5)) {
            fgPriceErr5 = generalMessage.INVALID;
        }
        if (!isNaN(fgPrice5) && Number(fgPrice5) < 0) {
            fgPriceErr5 = generalMessage.INVALID_AMOUNT;
        }
    }

    // feature added 6-17-20.  fgActual qty validation as admin is now allowed to enter the
    // fgActual qty.
    if (fgActualQty === '') fgActualQty = 0;

    if (Number(fgActualQty) > lengths.numberInputAmount)
        fgActualQtyErr = generalMessage.INVALID_AMOUNT;
    else {
        if (isNaN(fgActualQty)) {
            fgActualQtyErr = generalMessage.INVALID;
        }
        if (!isNaN(fgActualQty) && Number(fgActualQty) < 0) {
            fgActualQtyErr = generalMessage.INVALID;
        }
    }

    // fgCode validation
    if (fgCode.length > lengths.code) fgCodeErr = generalMessage.INVALID_LENGTH;

    //! this should never run unless Source Code was hacked.
    // rmArr validation
    if (rmArr.length > lengths.fgSetupRmArrLength)
        arraysErr = appMessage.sourceCodeErrorMessage(
            'FG: rmArr length error.'
        );

    // rmSuppliersArr valiation
    if (sfgArr.length > lengths.fgSetupSfgArrLength)
        arraysErr = appMessage.sourceCodeErrorMessage(
            'FG: sfgArr length error.'
        );
    //! ================================================

    // check if there is at least one rm or sfg in the arrays.
    if (rmArr.length === 0 && sfgArr.length === 0)
        arraysErr = 'Must contain at least one RM or one SFG.';

    //! rmArr validation ====================================================

    let noDuplicateRawMatNamesArr = [];

    // map for group and requiredQty
    let mapRMQtyPerGroup = {};

    // reset all error messages inside rmArr.
    let rawMatArr = rmArr.map((rm) => {
        return {
            ...rm,
            errorMessage: '',
            qtyErrorMessage: '',
            groupErrorMessage: '',
        };
    });

    //! less checking during delete so deleted sfg or rm components will not throw an error.
    if (purpose !== 'DELETE') {
        // check validity of rmUID and rmRequiredQty.
        rawMatArr.forEach((rm, index) => {
            if (!rmUIDsArr.includes(rm.rmUID)) {
                rm.errorMessage = 'Raw Material not found.';
                rmArrWithError = true;
            }
            if (rm.rmUID.trim() === '') {
                rm.errorMessage = 'Delete if blank.';
                rmArrWithError = true;
            }
            if (noDuplicateRawMatNamesArr.includes(rm.rmUID)) {
                rm.errorMessage = 'Invalid entry. Duplicate Raw Material.';
                rmArrWithError = true;
            } else noDuplicateRawMatNamesArr.push(rm.rmUID);

            // conditions for rmRequiredQty
            if (Number(rm.rmRequiredQty) <= 0 || rm.rmRequiredQty === '') {
                rm.qtyErrorMessage = generalMessage.GREATER_ZERO;
                rmArrWithError = true;
            }
            if (Number(rm.rmRequiredQty) > lengths.numberInputAmount) {
                rm.qtyErrorMessage = generalMessage.INVALID_AMOUNT;
                rmArrWithError = true;
            }

            if (isNaN(rm.rmRequiredQty)) {
                rm.qtyErrorMessage = generalMessage.INVALID;
                rmArrWithError = true;
            }

            // check all rmRequiredQty belonging to same group have the
            // same value.
            let idx = `a${rm.group}`;
            if (mapRMQtyPerGroup[idx]) {
                if (
                    Number(rm.rmRequiredQty) !== Number(mapRMQtyPerGroup[idx])
                ) {
                    rm.qtyErrorMessage =
                        'Grouped RMs must all have the same Required Qty.';
                    rmArrWithError = true;
                }
            } else mapRMQtyPerGroup[idx] = rm.rmRequiredQty;

            // check that group property is not empty.
            if (rm.group === '') {
                rm.groupErrorMessage = 'Must assign a group number.';
                rmArrWithError = true;
            }
            if (Number(rm.group) < 0 || Number(rm.group) > 20) {
                rm.groupErrorMessage = 'Must assign a group from 0 - 20.';
                rmArrWithError = true;
            }

            if (isNaN(rm.group)) {
                rm.groupErrorMessage = generalMessage.INVALID;
                rmArrWithError = true;
            }
        });
    }

    //! sfgArr validation ====================================================

    let noDuplicateSFGNamesArr = [];

    // map for group and requiredQty
    let mapSFGQtyPerGroup = {};

    // reset all error messages inside sfgArr.
    let semiFGArr = sfgArr.map((sfg) => {
        return {
            ...sfg,
            errorMessage: '',
            qtyErrorMessage: '',
            groupErrorMessage: '',
        };
    });

    //! less checking during delete so deleted sfg or rm components will not throw an error.
    if (purpose !== 'DELETE') {
        // check validity of sfgUID and rmRequiredQty.
        semiFGArr.forEach((sfg, index) => {
            if (!sfgNamesArr.includes(sfg.sfgUID)) {
                sfg.errorMessage = 'SFG not found.';
                sfgArrWithError = true;
            }
            if (sfg.sfgUID.trim() === '') {
                sfg.errorMessage =
                    index === 0 ? 'Required' : 'Delete if blank.';
                sfgArrWithError = true;
            }
            if (noDuplicateSFGNamesArr.includes(sfg.sfgUID)) {
                sfg.errorMessage = 'Invalid entry. Duplicate SFG.';
                sfgArrWithError = true;
            } else noDuplicateSFGNamesArr.push(sfg.sfgUID);

            // conditions for sfgRequiredQty
            if (Number(sfg.sfgRequiredQty) <= 0 || sfg.sfgRequiredQty === '') {
                sfg.qtyErrorMessage = generalMessage.GREATER_ZERO;
                sfgArrWithError = true;
            }
            if (Number(sfg.sfgRequiredQty) > lengths.numberInputAmount) {
                sfg.qtyErrorMessage = generalMessage.INVALID_AMOUNT;
                sfgArrWithError = true;
            }

            if (isNaN(sfg.sfgRequiredQty)) {
                sfg.qtyErrorMessage = generalMessage.INVALID;
                sfgArrWithError = true;
            }

            // check all sfgRequiredQty belonging to same group have the
            // same value.
            let idx = `a${sfg.group}`;
            if (mapSFGQtyPerGroup[idx]) {
                if (
                    Number(sfg.sfgRequiredQty) !==
                    Number(mapSFGQtyPerGroup[idx])
                ) {
                    sfg.qtyErrorMessage =
                        'Grouped SFGs must all have the same Required Qty.';
                    sfgArrWithError = true;
                }
            } else mapSFGQtyPerGroup[idx] = sfg.sfgRequiredQty;

            // check that group property is not empty.
            if (sfg.group === '') {
                sfg.groupErrorMessage = 'Must assign a group number.';
                sfgArrWithError = true;
            }
            if (Number(sfg.group) < 0 || Number(sfg.group) > 20) {
                sfg.groupErrorMessage = 'Must assign a group from 0 - 20.';
                sfgArrWithError = true;
            }

            if (isNaN(sfg.group)) {
                sfg.groupErrorMessage = generalMessage.INVALID;
                sfgArrWithError = true;
            }
        });
    }

    if (
        fgCodeErr ||
        fgNameErr ||
        fgLowThresholdErr ||
        fgUnitErr ||
        arraysErr ||
        rmArrWithError ||
        sfgArrWithError ||
        fgPriceErr ||
        fgPriceErr2 ||
        fgPriceErr3 ||
        fgPriceErr4 ||
        fgPriceErr5 ||
        fgActualQtyErr
    ) {
        return {
            error: true,
            errorObj: {
                fgCodeErr,
                fgNameErr,
                fgLowThresholdErr,
                fgUnitErr,
                arraysErr,
                rawMatArr,
                semiFGArr,
                fgPriceErr,
                fgPriceErr2,
                fgPriceErr3,
                fgPriceErr4,
                fgPriceErr5,
                fgActualQtyErr,
            },
        };
    }

    // if there is no error

    //! less checking during delete so deleted sfg or rm components will not throw an error.
    if (purpose !== 'DELETE') {
        // 1. clean up rmArr and sfgArr and use updated values from rawMatArr and semiFGArr
        //      before sending to parent element. ==========================

        let updatedRMArr = rawMatArr.map((item) => {
            delete item.errorMessage;
            delete item.qtyErrorMessage;
            delete item.groupErrorMessage;

            //feater added 5-10-2020 to get the rmUnit of each rm from masterlist.
            let extractedUnit = masterListRM.filter(
                (rm) => rm.rmUID === item.rmUID
            )[0].rmUnit;

            let newItem = {
                ...item,
                rmUnit: extractedUnit,
            };

            return newItem;
        });

        let updatedSFGArr = semiFGArr.map((item) => {
            delete item.errorMessage;
            delete item.qtyErrorMessage;
            delete item.groupErrorMessage;

            //feater added 5-10-2020 to get the sfgUnit of each sfg from masterlist.
            let extractedUnit = masterListSFG.filter(
                (sfg) => sfg.sfgUID === item.sfgUID
            )[0].sfgUnit;

            let newItem = {
                ...item,
                sfgUnit: extractedUnit,
            };

            return newItem;
        });

        // 2. sort the rmArr by group. ==============================
        let sorterRMFn = (a, b) => {
            // group is ensured to be a number by now.
            let aGroup =
                a.group.toString().length === 1
                    ? a.group.padStart(2, '0')
                    : a.group;
            let bGroup =
                b.group.toString().length === 1
                    ? b.group.padStart(2, '0')
                    : b.group;

            let itemA = `${aGroup}${a.rmUID}`;
            let itemB = `${bGroup}${b.rmUID}`;
            if (itemA > itemB) return 1;
            if (itemA < itemB) return -1;
            return 0;
        };

        let sorterSFGFn = (a, b) => {
            // group is ensured to be a number by now.
            let aGroup =
                a.group.toString().length === 1
                    ? a.group.padStart(2, '0')
                    : a.group;
            let bGroup =
                b.group.toString().length === 1
                    ? b.group.padStart(2, '0')
                    : b.group;

            let itemA = `${aGroup}${a.sfgUID}`;
            let itemB = `${bGroup}${b.sfgUID}`;
            if (itemA > itemB) return 1;
            if (itemA < itemB) return -1;
            return 0;
        };

        let sortedRMArr = updatedRMArr.sort(sorterRMFn);

        let sortedSFGArr = updatedSFGArr.sort(sorterSFGFn);

        // ensure data type number of all properties if it is a quantity.
        fgObj.fgLowThreshold = saveWithFiveDecimals(fgObj.fgLowThreshold);
        fgObj.rmArr = sortedRMArr.map((item) => {
            return {
                ...item,
                rmRequiredQty: saveWithFiveDecimals(item.rmRequiredQty),
            };
        });
        fgObj.sfgArr = sortedSFGArr.map((item) => {
            return {
                ...item,
                sfgRequiredQty: saveWithFiveDecimals(item.sfgRequiredQty),
            };
        });
        fgObj.fgPrice = saveWithTwoDecimalOnly(fgObj.fgPrice);
        fgObj.fgPrice2 =
            Number(fgObj.fgPrice2) !== 0
                ? saveWithTwoDecimalOnly(fgObj.fgPrice2)
                : saveWithTwoDecimalOnly(fgObj.fgPrice);
        fgObj.fgPrice3 =
            Number(fgObj.fgPrice3) !== 0
                ? saveWithTwoDecimalOnly(fgObj.fgPrice3)
                : saveWithTwoDecimalOnly(fgObj.fgPrice);
        fgObj.fgPrice4 =
            Number(fgObj.fgPrice4) !== 0
                ? saveWithTwoDecimalOnly(fgObj.fgPrice4)
                : saveWithTwoDecimalOnly(fgObj.fgPrice);
        fgObj.fgPrice5 =
            Number(fgObj.fgPrice5) !== 0
                ? saveWithTwoDecimalOnly(fgObj.fgPrice5)
                : saveWithTwoDecimalOnly(fgObj.fgPrice);
    }

    if (purpose === 'ADD') {
        // complete the schema of the fgObj.
        fgObj.fgUID = fgObj.fgName;
        fgObj.fgActualQty = 0; // number
        fgObj.fgProjectedQty = 0; // number
        fgObj.assemblyOrderNumbersArr = [];
        fgObj.sdpoNumbersArr = [];

        // empty fgActualQty was converted to 0 already.
        if (fgActualQty === 0) {
            fgObj.fgActualQty = 0; // number
            fgObj.fgProjectedQty = 0; // number
        } else {
            fgObj.fgActualQty = saveWithFiveDecimals(fgActualQty);
            fgObj.fgProjectedQty = saveWithFiveDecimals(fgActualQty);
        }
    }

    // will change projected and actual qty automatically.
    if (
        purpose === 'EDIT' &&
        Number(fgActualQty) !== Number(documentBasis.fgActualQty)
    ) {
        let result = Number(fgActualQty) - Number(documentBasis.fgActualQty);
        fgObj.fgProjectedQty = saveWithFiveDecimals(
            documentBasis.fgProjectedQty + result
        );
        fgObj.fgActualQty = saveWithFiveDecimals(
            documentBasis.fgActualQty + result
        );
    }

    return {
        error: false,
        errorObj: {},
        data: fgObj,
    };
};
