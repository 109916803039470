export default (masterlistStringFormat, keyProp) => {
    let newArr = [];
    let itemObjInJSObjectFormat = JSON.parse(masterlistStringFormat);

    let keyID = '';
    let dateProperty = '';
    let descriptionProperty = '';

    if (keyProp === 'productionUID') {
        keyID = 'productionUID';
        dateProperty = 'prodOrderDate';
        descriptionProperty = 'productionDescription';
    }
    if (keyProp === 'assemblyUID') {
        keyID = 'assemblyUID';
        dateProperty = 'assemblyOrderDate';
        descriptionProperty = 'assemblyDescription';
    }
    if (keyProp === 'productionDeliveredUID') {
        keyID = 'productionDeliveredUID';
        dateProperty = 'productionDeliveredDate';
        descriptionProperty = 'productionDeliveredDescription';
    }
    if (keyProp === 'assemblyDeliveredUID') {
        keyID = 'assemblyDeliveredUID';
        dateProperty = 'assemblyDeliveredDate';
        descriptionProperty = 'assemblyDeliveredDescription';
    }

    for (let key in itemObjInJSObjectFormat) {
        newArr.unshift({
            [keyID]: key,
            status: itemObjInJSObjectFormat[key].a,
            [dateProperty]: itemObjInJSObjectFormat[key].b,
            [descriptionProperty]: itemObjInJSObjectFormat[key].c,
        });
    }

    return newArr;
};
