import React, { useState, useEffect, useContext } from 'react';
import {
    Datalist,
    AddButton,
    Input,
    CreateItemButtons,
    UpdateItemButtons,
    DeleteItemButtons,
    BackOnlyButton,
    BackSendEditDelButtons,
    BackWithMarkAsButtons,
    ApproveRejectButtons,
    TextArea,
    SignatureLine,
    Watermark,
} from '../../components';
import {
    AuthContext,
    MasterListContext,
    DataShareContext,
} from '../../contexts';
import {
    permissions,
    userActions,
    lengths,
    SDSIS_COL,
} from '../../lib/constants';
import {
    clone,
    displayDate,
    displayMoney,
    saveWithTwoDecimalOnly,
} from '../../lib/util';
import { dbopsGetDocument } from '../../firebase_dbops';

import validate from '../../validators';

import '../PagesCommon.css';

let defaultSdcr = {
    sdcrUID: '',
    sdcrNumber: '',
    customerUID: '',
    sdsiArr: [],
    meta: { action: '' },
    note: '',
};

export default (props) => {
    let {
        purpose,
        currentDocument,

        backCancelBtnFn,
        gotoEditViewFn,
        gotoDeleteViewFn,

        createCrFn,
        deleteCrFn,
        updateCrFn,
        statusChangeFn,

        setIsLoading,
        setAlertErrorMsg,
    } = props;

    let { masterSdsiList, masterCustomersList } = useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);
    let { serverDate } = useContext(DataShareContext);

    let [generalError, setGeneralError] = useState('');
    let [
        datalistDisplayResetCounter,
        setDatalistDisplayResetCounter,
    ] = useState(1);

    let [sdcrObj, setSdcrObj] = useState(defaultSdcr);
    let [sdcrUIDstate, setSdcrUIDstate] = useState('');
    let [tempCustomer, setTempCustomer] = useState('');
    let [customerUIDstate, setCustomerUIDstate] = useState('');
    let [sdsiUIDinState, setSdsiUIDinState] = useState('');

    let [sdcrDateInState, setSdcrDateInState] = useState(serverDate);
    let [noteInState, setNoteInState] = useState('');

    let [confirmDeleteText, setConfirmDeleteText] = useState('');
    let [preview, setPreview] = useState(false);

    let [sdcrDiscountInState, setSdcrDiscountInState] = useState(0);

    // set sdcrObj from currentDocument if purpose !== 'ADD'
    useEffect(() => {
        if (purpose !== 'ADD') {
            //making a copy so no side effect will change currentDocument.
            let currentDocumentCopy = {
                ...currentDocument,
                sdsiArr: clone(currentDocument.sdsiArr),
            };

            setSdcrObj(currentDocumentCopy);
            setCustomerUIDstate(currentDocumentCopy.customerUID);
            setSdcrDateInState(currentDocumentCopy.sdcrDate);
            setNoteInState(currentDocumentCopy.note || '');
            setSdcrUIDstate(currentDocumentCopy.sdcrUID);
            setSdcrDiscountInState(currentDocumentCopy.sdcrDiscount || 0);
        }
    }, []);

    const cancelBtnFromEditViewFn = () => {
        // 1. reset values.
        setSdcrObj(currentDocument);
        setCustomerUIDstate(currentDocument.customerUID);
        setSdcrDateInState(currentDocument.sdcrDate);
        setNoteInState(currentDocument.note || '');
        setSdcrUIDstate(currentDocument.sdcrUID);
        setSdcrDiscountInState(currentDocument.sdcrDiscount || 0);

        // 2. reset errorMessages.
        setGeneralError('');

        // 3. go back to profile view.
        backCancelBtnFn();
    };

    // =====================================================

    const deleteErrors = (sdcrObjCopy) => {
        delete sdcrObjCopy.sdcrUIDError;
        delete sdcrObjCopy.sdsiArrError;
        delete sdcrObjCopy.customerError;
        delete sdcrObjCopy.sdcrDateError;
        delete sdcrObjCopy.noteError;
        delete sdcrObjCopy.sdcrDiscountError;
        setGeneralError('');
    };

    const addToArrFn = async () => {
        let sdcrObjCopy = {
            ...sdcrObj,
            sdsiArr: clone(sdcrObj.sdsiArr),
        };

        deleteErrors(sdcrObjCopy);

        setIsLoading(true);

        let response = await dbopsGetDocument(SDSIS_COL, sdsiUIDinState);
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message || response.message);
            setIsLoading(false);
            return;
        }

        sdcrObjCopy.sdsiArr.push(response.data);

        setSdcrObj(sdcrObjCopy);
        setSdsiUIDinState('');
        setDatalistDisplayResetCounter(datalistDisplayResetCounter + 1);
        setIsLoading(false);
    };

    const removeFromArrFn = (index) => {
        let sdcrObjCopy = {
            ...sdcrObj,
            sdsiArr: clone(sdcrObj.sdsiArr),
        };

        deleteErrors(sdcrObjCopy);

        sdcrObjCopy.sdsiArr.splice(index, 1);
        setSdcrObj(sdcrObjCopy);
    };

    const previewVrBtnFn = () => {
        let sdcrObjCopy = { ...sdcrObj };
        sdcrObjCopy.sdcrUID = sdcrUIDstate;
        sdcrObjCopy.customerUID = customerUIDstate;
        sdcrObjCopy.sdcrDate = sdcrDateInState;
        sdcrObjCopy.note = noteInState;
        sdcrObjCopy.sdcrDiscount = sdcrDiscountInState;

        let response = validate.sdcr(sdcrObjCopy);
        if (response.error) {
            setSdcrObj(response.errorObj.sdcrObj);
            setGeneralError(
                response.errorObj.sdcrObj.customerError ||
                    response.errorObj.sdcrObj.sdsiArrError
            );
            setAlertErrorMsg(response.errorObj.hackError);
        } else {
            setSdcrObj(response.data);
            setPreview(true);
        }
    };

    const saveSIFn = () => {
        let sdcrObjCopy = { ...sdcrObj };
        sdcrObjCopy.sdcrUID = sdcrUIDstate;
        sdcrObjCopy.customerUID = customerUIDstate;
        sdcrObjCopy.sdcrDate = sdcrDateInState;
        sdcrObjCopy.note = noteInState;
        sdcrObjCopy.sdcrDiscount = sdcrDiscountInState;

        // check if there is a change in the payload from currentDocument
        if (
            purpose === 'EDIT' &&
            validate.noChange(sdcrObjCopy, currentDocument)
        ) {
            backCancelBtnFn();
            return;
        }

        let response = validate.sdcr(sdcrObjCopy);
        if (response.error) {
            setSdcrObj(response.errorObj.sdcrObj);
            setGeneralError(
                response.errorObj.sdcrObj.customerError ||
                    response.errorObj.sdcrObj.sdsiArrError
            );
            setAlertErrorMsg(response.errorObj.hackError);
            return;
        }
        setSdcrObj(response.data);

        let validSdcrObj = response.data;

        // console.log(validSdcrObj);

        if (purpose === 'ADD') createCrFn(validSdcrObj);
        if (purpose === 'EDIT') updateCrFn(validSdcrObj, currentDocument);
        if (purpose === 'DELETE') deleteCrFn(validSdcrObj, currentDocument);
    };

    //! work around. fixes sequence of Edit without saving and then sending for approval.
    //! bug found in SDCR, SDSI, SDPO. All without statusChangeLocalFn.
    const statusChangeLocalFn = (userActionPassed) => {
        let sdcrObjCopy = { ...sdcrObj };
        sdcrObjCopy.sdcrUID = sdcrObjCopy.sdcrUID || sdcrUIDstate;
        sdcrObjCopy.customerUID = sdcrObjCopy.customerUID || customerUIDstate;
        sdcrObjCopy.sdcrDate = sdcrDateInState;
        sdcrObjCopy.note = noteInState;
        sdcrObjCopy.sdcrDiscount = sdcrDiscountInState;

        let response = validate.sdcr(sdcrObjCopy);
        if (response.error) {
            setSdcrObj(response.errorObj.sdcrObj);
            setGeneralError(
                response.errorObj.sdcrObj.customerError ||
                    response.errorObj.sdcrObj.sdsiArrError
            );
            setAlertErrorMsg(response.errorObj.hackError);
            return;
        }

        let validSdcrObj = response.data;

        statusChangeFn(userActionPassed, validSdcrObj, currentDocument);
    };

    // conditional render of contents  =============================

    let sdsiArrUI = [];
    let noteUI = null;

    let buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    let mainContent = null;
    let datalist = null;

    let itemLabel = (
        <div className='span-2-columns-container label-padding label-highlight'>
            <label className='parent-labels'>List of Sales Invoices</label>
        </div>
    );

    let addBtn = <AddButton disabled />;

    // this section will be reused accross different purposes. =====================
    let total = 0;

    let sdsiItemsSection = null;
    let amountBox = null;

    if (sdcrObj) {
        sdsiArrUI = sdcrObj.sdsiArr.map((si, index) => {
            total = saveWithTwoDecimalOnly(total + si.sdsiTotalsNett);

            let trashIcon = (
                <i
                    className='ms-Icon ms-Icon--Delete clickable-icon'
                    aria-hidden='true'
                    onClick={() => removeFromArrFn(index)}></i>
            );

            if (purpose !== 'ADD' && purpose !== 'EDIT') {
                trashIcon = null;
            }

            return (
                <div className='span-2-columns-container' key={index}>
                    <div className='view-table-row grid-row-40-60'>
                        <div className='cell'>
                            <span className='mobile-label m-right-15'>
                                {`${index + 1}.`}
                            </span>
                            <span className='desktop-view m-lr-15'>
                                {`${index + 1}.`}
                            </span>
                            <span className=''> {si.sdsiUID}</span>
                        </div>

                        <div className='cell'>
                            <span className='width40'>
                                <span className='mobile-label'>
                                    Invoice Date
                                </span>{' '}
                                <span className=''>
                                    {displayDate(si.sdsiDate)}
                                </span>
                            </span>
                            <span className='width40'>
                                <span className='mobile-label'>Amount:</span>{' '}
                                <span className=''>
                                    {displayMoney(si.sdsiTotalsNett)}
                                </span>
                            </span>
                            <span className='width16'>{trashIcon}</span>
                        </div>
                    </div>
                </div>
            );
        });

        sdsiItemsSection = (
            <div className='span-2-columns-container'>
                <div className='view-table-heading grid-row-40-60'>
                    <div className='cell'>
                        <span className='m-left-15'>Sales Invoice</span>
                    </div>

                    <div className='cell'>
                        <span className='width40'>Invoice Date</span>

                        <span className='width40'>Total Amount</span>

                        <span className='width16'></span>
                    </div>
                </div>

                {sdsiArrUI}
            </div>
        );

        let totalsNett = saveWithTwoDecimalOnly(total - sdcrDiscountInState);

        amountBox = (
            <>
                <div className='desktop-view'></div>
                <div className='amount-box'>
                    <div>Totals (Gross):</div>
                    <div>{displayMoney(total)}</div>
                    <div>Discount:</div>
                    <Input
                        type='number'
                        onChange={(e) => setSdcrDiscountInState(e.target.value)}
                        value={sdcrDiscountInState}
                        errorMessage={sdcrObj.sdcrDiscountError}></Input>
                    <div>Totals (Gross):</div>
                    <div className='text-bold'>{displayMoney(totalsNett)}</div>
                </div>
            </>
        );

        if (purpose !== 'EDIT' && purpose !== 'ADD') {
            amountBox = (
                <>
                    <div className='desktop-view'></div>
                    <div className='amount-box'>
                        <div>Totals (Gross):</div>
                        <div>{displayMoney(total)}</div>
                        <div>Discount:</div>
                        <div>{displayMoney(sdcrDiscountInState)}</div>

                        <div>Totals (Gross):</div>
                        <div className='text-bold'>
                            {displayMoney(totalsNett)}
                        </div>
                    </div>
                </>
            );
        }
    }

    // ==============================================================================

    if ((purpose === 'ADD' && !preview) || purpose === 'EDIT') {
        //! customer list datalist process ====================================
        let supListOptions = masterCustomersList.map((sup) => sup.customerUID);

        let chooseCustomerBtn = (
            <button
                className='choose-sup-button'
                onClick={() => setCustomerUIDstate(tempCustomer)}>
                Choose Customer
            </button>
        );

        let customerDatalist = (
            <div className='get-item-from-db-container'>
                <div className='datalist-with-addbtn-container'>
                    <Datalist
                        list={supListOptions}
                        initialValue={''}
                        getInputValue={(val) => setTempCustomer(val)}
                        errorMessage={''}
                        resetCounter={datalistDisplayResetCounter}></Datalist>
                    {chooseCustomerBtn}
                </div>
            </div>
        );

        //! end customer datalist process ========================================

        // pdsi datalist =================================================
        let datalistOptions = masterSdsiList
            .filter((item) => item.customerUID === customerUIDstate)
            .filter((item) => item.status === userActions.APPROVED)
            .map((item) => item.sdsiUID);

        let itemAlreadyInTheUI = sdcrObj.sdsiArr.map((si) => si.sdsiUID);

        if (
            datalistOptions.includes(sdsiUIDinState) &&
            !itemAlreadyInTheUI.includes(sdsiUIDinState)
        )
            addBtn = <AddButton onClick={addToArrFn} />;

        datalist = (
            <div className='get-item-from-db-container'>
                <div className='datalist-with-addbtn-container'>
                    <Datalist
                        label={`Add Sales Invoice`}
                        list={datalistOptions}
                        initialValue={''}
                        getInputValue={(val) => setSdsiUIDinState(val)}
                        errorMessage={''}
                        resetCounter={datalistDisplayResetCounter}></Datalist>
                    {addBtn}
                </div>
            </div>
        );

        // end po or dr datalist ==========================================

        let crUID = (
            <Input
                type='text'
                maxLength={lengths.uid}
                onChange={(e) => setSdcrUIDstate(e.target.value)}
                value={sdcrUIDstate}
                placeholder='Required'
                errorMessage={sdcrObj.sdcrUIDError}></Input>
        );

        if (purpose === 'EDIT') {
            crUID = <p className='fpii-text'>{sdcrObj.sdcrUID || '---'}</p>;
        }

        // choosing customer step.
        let chosenCustomer = masterCustomersList.filter(
            (customer) => customer.customerUID === customerUIDstate
        );

        if (customerUIDstate !== '' && chosenCustomer[0]) {
            customerDatalist = null;
        } else datalist = null;

        if (sdsiArrUI === null || sdsiArrUI.length === 0) {
            itemLabel = null;
            sdsiItemsSection = null;
            amountBox = null;
        }

        noteUI = (
            <div className='span-2-columns-container'>
                <TextArea
                    label={<span className='parent-labels'>Notes</span>}
                    maxLength={lengths.noteLength}
                    value={noteInState}
                    onChange={(e) => setNoteInState(e.target.value)}
                    errorMessage={sdcrObj.noteError}
                />
            </div>
        );

        //! Max number of iitems in the list
        if (sdcrObj.sdsiArr.length >= lengths.sdcrSdsiArrLength)
            datalist = null;

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Counter Receipt</p>
                    {crUID}
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Status</p>
                    <p className='fpii-text'>{sdcrObj.meta.action || '---'}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Customer</p>
                    <p className='fpii-text'>
                        {sdcrObj.customerUID || customerUIDstate || '---'}
                    </p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Date</p>
                    <Input
                        type='date'
                        maxLength='12'
                        onChange={(e) => setSdcrDateInState(e.target.value)}
                        value={sdcrDateInState}
                        errorMessage={sdcrObj.sdcrDateError}></Input>
                </div>

                {customerDatalist}

                {itemLabel}
                {sdsiItemsSection}
                {amountBox}

                {datalist}
                {noteUI}
            </section>
        );
    }

    if (
        (purpose === 'ADD' && preview) ||
        purpose === 'VIEW' ||
        purpose === 'DELETE'
    ) {
        noteUI = (
            <div className='span-2-columns-container hide-on-print'>
                <TextArea
                    label={<span className='parent-labels'>Notes</span>}
                    disabled
                    value={noteInState}
                />
            </div>
        );

        if (
            (sdcrObj.meta.action === userActions.SENT_FOR_APPROVAL ||
                sdcrObj.meta.action === userActions.APPROVED) &&
            authUser.permissions.includes(permissions.SDCR_APPROVE)
        ) {
            noteUI = (
                <div className='span-2-columns-container hide-on-print'>
                    <TextArea
                        label={<span className='parent-labels'>Notes</span>}
                        maxLength={lengths.noteLength}
                        value={noteInState}
                        onChange={(e) => setNoteInState(e.target.value)}
                        errorMessage={sdcrObj.noteError}
                    />
                </div>
            );
        }

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Counter Receipt</p>
                    {sdcrObj.sdcrUID}
                </div>

                <div className='form-profile-info-items hide-on-print'>
                    <p className='fpii-label'>Status</p>
                    <p className='fpii-text'>{sdcrObj.meta.action || '---'}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Customer</p>
                    <p className='fpii-text'>
                        {sdcrObj.customerUID || customerUIDstate || '---'}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Date</p>
                    <p className='fpii-text'>
                        {sdcrObj.sdcrDate
                            ? displayDate(sdcrObj.sdcrDate)
                            : '---'}
                    </p>
                </div>

                {itemLabel}
                {sdsiItemsSection}
                {amountBox}
                {noteUI}
                <SignatureLine />
            </section>
        );
    }

    // =========================================================================
    // Button sections for users with SDCR Request permision ===================

    if (purpose === 'ADD') {
        buttonSection = (
            <CreateItemButtons
                createText='Create CR'
                previewText='Preview CR'
                cancelPreviewFn={() => setPreview(false)}
                cancelFn={backCancelBtnFn}
                createFn={saveSIFn}
                previewFn={previewVrBtnFn}
                isPreview={preview}
            />
        );
    }

    if (purpose === 'EDIT') {
        buttonSection = (
            <UpdateItemButtons
                saveFn={saveSIFn}
                cancelFn={cancelBtnFromEditViewFn}
            />
        );
    }

    if (purpose === 'DELETE') {
        buttonSection = (
            <DeleteItemButtons
                cancelFn={backCancelBtnFn}
                deleteFn={saveSIFn}
                confirmeDeleteFn={(e) => setConfirmDeleteText(e.target.value)}
                confirmDeleteTextValue={confirmDeleteText}
                confirmDeleteTextPlaceHolder='Enter CR Number'
                uidValue={sdcrObj.sdcrUID}
            />
        );
    }

    // ==================================================================
    // Button section for users with SDCR equest permission.
    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.SDCR_REQUEST) &&
        !authUser.permissions.includes(permissions.SDCR_APPROVE)
    ) {
        if (
            sdcrObj.meta.action === userActions.CREATED ||
            sdcrObj.meta.action === userActions.UPDATED ||
            sdcrObj.meta.action === userActions.REJECTED
        ) {
            buttonSection = (
                <BackSendEditDelButtons
                    backBtnFn={backCancelBtnFn}
                    sendForApprovalBtnFn={() =>
                        statusChangeLocalFn(userActions.SENT_FOR_APPROVAL)
                    }
                    editBtnFn={gotoEditViewFn}
                    deleteBtnFn={gotoDeleteViewFn}
                />
            );
        }
    }

    // =========================================================================
    // Button section for users with SDCR pprove permision ===================

    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.SDCR_APPROVE) &&
        !authUser.permissions.includes(permissions.SDCR_REQUEST)
    ) {
        // if document status is SENT_FOR_APPROVAL
        if (sdcrObj.meta.action === userActions.SENT_FOR_APPROVAL) {
            buttonSection = (
                <ApproveRejectButtons
                    backBtnFn={backCancelBtnFn}
                    approveBtnFn={() =>
                        statusChangeLocalFn(userActions.APPROVED)
                    }
                    rejectBtnFn={() =>
                        statusChangeLocalFn(userActions.REJECTED)
                    }
                />
            );
        }

        if (sdcrObj.meta.action === userActions.APPROVED) {
            buttonSection = (
                <BackWithMarkAsButtons
                    backBtnFn={backCancelBtnFn}
                    markAsBtnFn={() =>
                        statusChangeLocalFn(userActions.MARK_AS_COLLECTED)
                    }
                    markAsBtnText='Mark as Collected'
                />
            );
        }
    }

    // =========================================================================
    //! button section for users with both SDCR equest and Approve permissions.
    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.SDCR_APPROVE) &&
        authUser.permissions.includes(permissions.SDCR_REQUEST)
    ) {
        // if document status is SENT_FOR_APPROVAL
        if (sdcrObj.meta.action === userActions.SENT_FOR_APPROVAL) {
            buttonSection = (
                <ApproveRejectButtons
                    backBtnFn={backCancelBtnFn}
                    approveBtnFn={() =>
                        statusChangeLocalFn(userActions.APPROVED)
                    }
                    rejectBtnFn={() =>
                        statusChangeLocalFn(userActions.REJECTED)
                    }
                />
            );
        }

        if (sdcrObj.meta.action === userActions.APPROVED) {
            buttonSection = (
                <BackWithMarkAsButtons
                    backBtnFn={backCancelBtnFn}
                    markAsBtnFn={() =>
                        statusChangeLocalFn(userActions.MARK_AS_COLLECTED)
                    }
                    markAsBtnText='Mark as Collected'
                />
            );
        }

        if (
            sdcrObj.meta.action === userActions.CREATED ||
            sdcrObj.meta.action === userActions.UPDATED ||
            sdcrObj.meta.action === userActions.REJECTED
        ) {
            buttonSection = (
                <BackSendEditDelButtons
                    backBtnFn={backCancelBtnFn}
                    sendForApprovalBtnFn={() =>
                        statusChangeLocalFn(userActions.SENT_FOR_APPROVAL)
                    }
                    editBtnFn={gotoEditViewFn}
                    deleteBtnFn={gotoDeleteViewFn}
                />
            );
        }
    }

    if (!authUser.subscriptionStatusIsActive) {
        buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    }

    let generalErrorUI = (
        <div className='general-error-message'>{generalError}</div>
    );

    return (
        <div className='common-form'>
            <section className='form-title-section'>
                Counter Receipt Form
            </section>

            {mainContent}

            {buttonSection}
            {generalErrorUI}

            <Watermark purpose={purpose} currentDocument={currentDocument} />
        </div>
    );
};
