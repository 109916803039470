import { responseFn } from '../lib/util';
import { lengths } from '../lib/constants';
import { generalMessage } from '../lib/messages';

export const userProfile = (userObj, purpose) => {
    // userObj already is a copy. no need to make a copy.

    let displayNameError = '';
    let currentPasswordError = '';
    let newPasswordError = '';

    if (purpose === 'change displayName') {
        userObj.displayName = userObj.displayName.trim();

        if (userObj.displayName.length > lengths.displayName)
            displayNameError = generalMessage.INVALID_LENGTH;
    }

    if (purpose === 'change password') {
        userObj.currentPassword = userObj.currentPassword.trim();
        userObj.newPassword = userObj.newPassword.trim();

        if (userObj.currentPassword.length > lengths.password)
            currentPasswordError = generalMessage.INVALID_LENGTH;
        if (userObj.currentPassword.length < 6)
            currentPasswordError = 'Must be at least 6 characters';
        if (userObj.currentPassword === '' || userObj.currentPassword === ' ')
            currentPasswordError = generalMessage.REQUIRED;

        if (userObj.newPassword.length > lengths.password)
            newPasswordError = generalMessage.INVALID_LENGTH;
        if (userObj.newPassword.length < 6)
            newPasswordError = 'Must be at least 6 characters';
        if (userObj.newPassword === '' || userObj.newPassword === ' ')
            newPasswordError = generalMessage.REQUIRED;
    }

    if (currentPasswordError || newPasswordError || displayNameError) {
        let errorObj = {
            currentPasswordError,
            displayNameError,
            newPasswordError,
        };
        return responseFn(null, true, errorObj, 'Error detected');
    }

    let valid = {};
    if (purpose === 'change displayName')
        valid = { displayName: userObj.displayName };
    if (purpose === 'change password') {
        valid = {
            currentPassword: userObj.currentPassword,
            newPassword: userObj.newPassword,
        };
    }

    return responseFn(valid, false, {}, 'no error');
};
