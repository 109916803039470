import { userActions } from '../../../lib/constants';
import colors from './chartColors';

let {
    CREATED,
    UPDATED,
    SENT_FOR_APPROVAL,
    REJECTED,
    APPROVED,
    PAYMENT_SENT,
} = userActions;

let { created, updated, forApproval, rejected, approved, paymentSent } = colors;

export default (list) => {
    const statusArr = [
        CREATED,
        UPDATED,
        'PENDING APPROVAL',
        APPROVED,
        PAYMENT_SENT,
        REJECTED,
    ];
    const dataValues = [0, 0, 0, 0, 0, 0];
    const colorsArr = [
        created,
        updated,
        forApproval,
        approved,
        paymentSent,
        rejected,
    ];

    list.forEach((item) => {
        if (item.status === CREATED) dataValues[0] += 1;
        if (item.status === UPDATED) dataValues[1] += 1;
        if (item.status === SENT_FOR_APPROVAL) dataValues[2] += 1;
        if (item.status === APPROVED) dataValues[3] += 1;
        if (item.status === PAYMENT_SENT) dataValues[4] += 1;
        if (item.status === REJECTED) dataValues[5] += 1;
    });

    return {
        dataLabels: statusArr,
        dataValues: dataValues,
        dataBackgroundColors: colorsArr,
    };
};
