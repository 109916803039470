import { db, firebase } from '../firebase_config/firebaseConfig';
import { userActions, systemActions } from '../lib/constants';
import {
    responseFn,
    formatCustomErrorObj,
    saveWithFiveDecimals,
} from '../lib/util';
import validate from '../validators';
import mListHelper from './mListHelper';
import dbopsGetDocument from './dbopsGetDocument';
import { docNames, appMessage } from '../lib/messages';
import {
    operationObjToMListItemFn,
    fgInvObjToMListItemFn,
    sfgInvObjToMListItemFn,
    rmInvObjToMListItemFn,
} from '../lib/masterListConverters';

import {
    ASSEMBLY_DELIVERED_COUNTER_DOC,
    SG_NUMBERS_COL,
    //
    ASSEMBLY_DELIVERED_COL,
    DELETED_ASSEMBLY_DELIVERED_COL,
    //
    ASSEMBLY_COL,
    FINISHED_GOODS_COL,
    SEMI_FGS_COL,
    RAW_MATERIALS_COL,
    //
    MASTER_LISTS_COL,
    MASTER_ASSEMBLY_DELIVERED_LIST_DOC,
    MASTER_ASSEMBLY_ORDER_LIST_DOC,
    MASTER_INVENTORY_FG_LIST_DOC,
    MASTER_INVENTORY_RM_LIST_DOC,
    MASTER_INVENTORY_SFG_LIST_DOC,
} from '../lib/constants';

// ================================================
// function to fetch each SFG Produced document.
const getAssemblyDeliveredFn = async (docID) =>
    dbopsGetDocument(ASSEMBLY_DELIVERED_COL, docID, 'Assembly Delivered');

// ======================================================

const createAssemblyDeliveredFn = async (userCreds, assemblyDeliveredObj) => {
    // add metadata to assemblyDeliveredObj to be created in firestore.
    assemblyDeliveredObj.meta = {
        action: userActions.CREATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // add metaHistory
    assemblyDeliveredObj.metaHistory = [];

    const transactionFn = async (transaction) => {
        let assemblyDeliveredCounterRef = db
            .collection(SG_NUMBERS_COL)
            .doc(ASSEMBLY_DELIVERED_COUNTER_DOC);

        let assemblyDeliveredCounterDoc = await transaction.get(
            assemblyDeliveredCounterRef
        );
        let currentAssemblyDeliveredNum = assemblyDeliveredCounterDoc
            .data()
            .AssemblyDeliveredNumber.toString();

        // add server generated number to assemblyDeliveredUID.
        assemblyDeliveredObj.assemblyDeliveredUID = currentAssemblyDeliveredNum;
        assemblyDeliveredObj.assemblyDeliveredNumber = currentAssemblyDeliveredNum;

        let newAssemblyDeliveredRef = db
            .collection(ASSEMBLY_DELIVERED_COL)
            .doc(assemblyDeliveredObj.assemblyDeliveredUID);

        let assemblyDeliveredListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_ASSEMBLY_DELIVERED_LIST_DOC);

        let assemblyDeliveredDoc = await transaction.get(
            newAssemblyDeliveredRef
        );
        let assemblyDeliveredListDoc = await transaction.get(
            assemblyDeliveredListRef
        );

        // check exist error for new Assembly Delivered document
        if (assemblyDeliveredDoc.exists) {
            return Promise.reject(
                formatCustomErrorObj(
                    1002,
                    appMessage.alreadyExist(docNames.ASSEMBLY_DELIVERED)
                )
            );
        }

        // START MANIPULATING DATA TO UPDATE DATABASE ================================

        // increment SGNumbers Collection > ProductionDeliveredCounter > assemblyDeliveredNumber
        await transaction.set(
            assemblyDeliveredCounterRef,
            {
                AssemblyDeliveredNumber:
                    assemblyDeliveredCounterDoc.data().AssemblyDeliveredNumber +
                    1,
            },
            { merge: true }
        );

        // create or update assemblyDelivered list document in (master lists collection)
        if (assemblyDeliveredListDoc.exists) {
            let assemblyDeliveredInfoObj = operationObjToMListItemFn(
                assemblyDeliveredObj,
                'assemblyDeliveredUID'
            );

            let newData = mListHelper.updateMasterList(
                assemblyDeliveredListDoc,
                assemblyDeliveredObj.assemblyDeliveredUID,
                assemblyDeliveredInfoObj
            );

            await transaction.set(assemblyDeliveredListRef, newData);
        } else {
            let assemblyDeliveredInfoObj = operationObjToMListItemFn(
                assemblyDeliveredObj,
                'assemblyDeliveredUID'
            );

            let newData = mListHelper.addMasterList(
                assemblyDeliveredObj.assemblyDeliveredUID,
                assemblyDeliveredInfoObj
            );

            await transaction.set(assemblyDeliveredListRef, newData);
        }

        // set new assemblyDelivered document.
        await transaction.set(newAssemblyDeliveredRef, assemblyDeliveredObj);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyCreated(docNames.ASSEMBLY_DELIVERED)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorCreating(docNames.ASSEMBLY_DELIVERED)
        );
    }
};

// ===============================================
const updateAssemblyDeliveredFn = async (
    userCreds,
    assemblyDeliveredObj,
    documentBasis,
    userActionPassed
) => {
    if (assemblyDeliveredObj.metaHistory.length > 99)
        assemblyDeliveredObj.metaHistory.shift();

    assemblyDeliveredObj.metaHistory.push(assemblyDeliveredObj.meta);

    // update metadata in assemblyDeliveredObj.
    assemblyDeliveredObj.meta = {
        action: userActions.UPDATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // use assemblyDeliveredUID as docuid
    let assemblyDeliveredRef = db
        .collection(ASSEMBLY_DELIVERED_COL)
        .doc(assemblyDeliveredObj.assemblyDeliveredUID);

    let assemblyDeliveredListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_ASSEMBLY_DELIVERED_LIST_DOC);

    const transactionFn = async (transaction) => {
        let assemblyDeliveredDoc = await transaction.get(assemblyDeliveredRef);

        if (!assemblyDeliveredDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(
                        docNames.ASSEMBLY_DELIVERED
                    )
                )
            );

        if (!validate.noChange(assemblyDeliveredDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(
                        docNames.ASSEMBLY_DELIVERED
                    )
                )
            );
        }

        let assemblyDeliveredListDoc = await transaction.get(
            assemblyDeliveredListRef
        );

        let assemblyDeliveredInfoObj = operationObjToMListItemFn(
            assemblyDeliveredObj,
            'assemblyDeliveredUID'
        );

        let newData = mListHelper.updateMasterList(
            assemblyDeliveredListDoc,
            assemblyDeliveredObj.assemblyDeliveredUID,
            assemblyDeliveredInfoObj
        );

        await transaction.set(assemblyDeliveredListRef, newData);

        await transaction.set(assemblyDeliveredRef, assemblyDeliveredObj);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyUpdated(docNames.ASSEMBLY_DELIVERED)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorUpdating(docNames.ASSEMBLY_DELIVERED)
        );
    }
};

// ================================================================
const deleteAssemblyDeliveredFn = async (
    userCreds,
    assemblyDeliveredObj,
    documentBasis
) => {
    if (assemblyDeliveredObj.metaHistory.length > 99)
        assemblyDeliveredObj.metaHistory.shift();

    assemblyDeliveredObj.metaHistory.push(assemblyDeliveredObj.meta);

    assemblyDeliveredObj.meta = {
        action: userActions.DELETED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // use assemblyDeliveredUID. see updateAssemblyDeliveredFn
    let assemblyDeliveredRef = db
        .collection(ASSEMBLY_DELIVERED_COL)
        .doc(assemblyDeliveredObj.assemblyDeliveredUID);

    let assemblyDeliveredListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_ASSEMBLY_DELIVERED_LIST_DOC);

    let deletedAssemblyDeliveredRef = db
        .collection(DELETED_ASSEMBLY_DELIVERED_COL)
        .doc();

    const transactionFn = async (transaction) => {
        let assemblyDeliveredDoc = await transaction.get(assemblyDeliveredRef);

        if (!assemblyDeliveredDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotDeleteBecauseDocNotExist(
                        docNames.ASSEMBLY_DELIVERED
                    )
                )
            );

        if (!validate.noChange(assemblyDeliveredDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotDeleteBecauseDocChanged(
                        docNames.ASSEMBLY_DELIVERED
                    )
                )
            );
        }

        let assemblyDeliveredListDoc = await transaction.get(
            assemblyDeliveredListRef
        );
        await transaction.get(deletedAssemblyDeliveredRef);

        let newData = mListHelper.deletePropertyFromMasterList(
            assemblyDeliveredListDoc,
            assemblyDeliveredObj.assemblyDeliveredUID
        );

        await transaction.delete(assemblyDeliveredRef);
        await transaction.set(assemblyDeliveredListRef, newData);
        await transaction.set(
            deletedAssemblyDeliveredRef,
            assemblyDeliveredObj
        );

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyDeleted(docNames.ASSEMBLY_DELIVERED)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorDeleting(docNames.ASSEMBLY_DELIVERED)
        );
    }
};

// ================================================================
const statusChangeFn = async (
    userCreds,
    assemblyDeliveredObj,
    documentBasis
) => {
    // move old metadate into metahistory. ensure meta data history length
    // is 100 or less. will be checked by security rules.
    if (assemblyDeliveredObj.metaHistory.length > 99)
        assemblyDeliveredObj.metaHistory.shift();

    assemblyDeliveredObj.metaHistory.push(assemblyDeliveredObj.meta);

    // update metadata in assemblyDeliveredObj.
    assemblyDeliveredObj.meta = {
        action: userCreds.action,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // get references.
    let assemblyDeliveredRef = db
        .collection(ASSEMBLY_DELIVERED_COL)
        .doc(assemblyDeliveredObj.assemblyDeliveredUID);
    let assemblyDeliveredListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_ASSEMBLY_DELIVERED_LIST_DOC);

    const transactionFn = async (transaction) => {
        let assemblyDeliveredDoc = await transaction.get(assemblyDeliveredRef);
        let assemblyDeliveredListDoc = await transaction.get(
            assemblyDeliveredListRef
        );

        if (!assemblyDeliveredDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(
                        docNames.ASSEMBLY_DELIVERED
                    )
                )
            );
        // ! this does not check meta data.
        if (!validate.noChange(assemblyDeliveredDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(
                        docNames.ASSEMBLY_DELIVERED
                    )
                )
            );
        }
        // // ! meta data check if status of document changed.
        // if (assemblyDeliveredDoc.data().meta.action !== documentBasis.meta.action) {
        //     return Promise.reject(
        //         formatCustomErrorObj(
        //             1001,
        //             appMessage.cannotEditBecauseDocChanged(docNames.ASSEMBLY_DELIVERED)
        //         )
        //     );
        // }

        let assemblyDeliveredInfoObj = operationObjToMListItemFn(
            assemblyDeliveredObj,
            'assemblyDeliveredUID'
        );

        let newData = mListHelper.updateMasterList(
            assemblyDeliveredListDoc,
            assemblyDeliveredObj.assemblyDeliveredUID,
            assemblyDeliveredInfoObj
        );

        // write to masterlist and pdpo document in firebase.
        await transaction.set(assemblyDeliveredListRef, newData);
        await transaction.set(assemblyDeliveredRef, assemblyDeliveredObj);
        return Promise.resolve('TransactionFn Completed Successfully');
    };
    try {
        await db.runTransaction(transactionFn);
        return responseFn(null, false, null, appMessage.successfulUpdateStatus);
    } catch (e) {
        return responseFn(null, true, e, appMessage.errorUpdatingStatus);
    }
};

const approveAssemblyDeliveredFn = async (
    userCreds,
    assemblyDeliveredObj,
    documentBasis,
    documentBasisAssemblyOrderArr
) => {
    if (assemblyDeliveredObj.metaHistory.length > 99)
        assemblyDeliveredObj.metaHistory.shift();

    assemblyDeliveredObj.metaHistory.push(assemblyDeliveredObj.meta);

    // add metadata to assemblyDeliveredObj to be created in firestore.
    assemblyDeliveredObj.meta = {
        action: userActions.APPROVED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let existError = false;
    let balanceError = false;
    let editedError = false;

    // let whatRM = [];
    // let rmBalanceErrorMsg = '';

    const transactionFn = async (transaction) => {
        // master lists
        let assemblyDeliveredListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_ASSEMBLY_DELIVERED_LIST_DOC);

        let rmInvListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_INVENTORY_RM_LIST_DOC);

        let sfgInvListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_INVENTORY_SFG_LIST_DOC);

        let fgInvListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_INVENTORY_FG_LIST_DOC);

        let assemblyOrderListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_ASSEMBLY_ORDER_LIST_DOC);

        // individual docs
        let assemblyDeliveredRef = db
            .collection(ASSEMBLY_DELIVERED_COL)
            .doc(assemblyDeliveredObj.assemblyDeliveredUID);

        // get references for all prod orders;
        let documentBasisAssemblyOrderArrRefs = documentBasisAssemblyOrderArr.map(
            (assemblyOrder) => {
                return db
                    .collection(ASSEMBLY_COL)
                    .doc(assemblyOrder.assemblyUID);
            }
        );

        // create fgMap, sfgMap and rmMap.
        let fgMap = {};
        let sfgMap = {};
        let rmMap = {};

        assemblyDeliveredObj.assemblyOrderArr.forEach((assemblyOrder) => {
            assemblyOrder.fgArr.forEach((fg) => {
                fgMap[fg.fgUID] = {
                    computedFgAssembledQty: 0,
                    newAssemblyOrderNumbersArr: [],
                };

                fg.rmArr.forEach((rm) => {
                    rmMap[rm.rmUID] = {
                        computedRmActualUsedQty: 0,
                        newAssemblyOrderNumbersArr: [],
                    };
                });

                fg.sfgArr.forEach((sfg) => {
                    sfgMap[sfg.sfgUID] = {
                        computedSfgActualUsedQty: 0,
                        newAssemblyOrderNumbersArr: [],
                    };
                });
            });
        });

        // get references for all fgObjs
        let fgMapRefs = {};
        for (let key in fgMap) {
            fgMapRefs[key] = db.collection(FINISHED_GOODS_COL).doc(key);
        }

        // get references for all rmObjs
        let rmMapRefs = {};
        for (let key in rmMap) {
            rmMapRefs[key] = db.collection(RAW_MATERIALS_COL).doc(key);
        }
        // get references for all sfgObjs
        let sfgMapRefs = {};
        for (let key in sfgMap) {
            sfgMapRefs[key] = db.collection(SEMI_FGS_COL).doc(key);
        }

        // TRANSACTION GET OPERATIONS ================================

        // get master lists
        let assemblyDeliveredListDoc = await transaction.get(
            assemblyDeliveredListRef
        );
        let fgInvListDoc = await transaction.get(fgInvListRef);
        let rmInvListDoc = await transaction.get(rmInvListRef);
        let sfgInvListDoc = await transaction.get(sfgInvListRef);
        let assemblyOrderListDoc = await transaction.get(assemblyOrderListRef);

        // get individual docs and check for existence and changes.
        let assemblyDeliveredDoc = await transaction.get(assemblyDeliveredRef);

        //check exist error for new Assembly Delivered document
        // should never run.
        if (!assemblyDeliveredDoc.exists) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(
                        docNames.ASSEMBLY_DELIVERED
                    )
                )
            );
        }
        if (!validate.noChange(assemblyDeliveredDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(
                        docNames.ASSEMBLY_DELIVERED
                    )
                )
            );
        }

        // get assemblyOrder objects
        let documentBasisAssemblyOrderArrDocs = documentBasisAssemblyOrderArrRefs.map(
            async (assemblyOrderRef) => await transaction.get(assemblyOrderRef)
        );

        try {
            documentBasisAssemblyOrderArrDocs = await Promise.all(
                documentBasisAssemblyOrderArrDocs
            );
        } catch (e) {
            return Promise.reject(
                formatCustomErrorObj(
                    900,
                    appMessage.prommiseAllError(
                        'dbopsAssemblyDelivered',
                        'assemblyOrderArr'
                    )
                )
            );
        }

        // check for exist error and change error in each ProdOrder Objects.
        documentBasisAssemblyOrderArrDocs.forEach(
            (docBasisAssemblyOrder, index) => {
                if (!docBasisAssemblyOrder.exists) existError = true;
                if (
                    !validate.noChange(
                        docBasisAssemblyOrder.data(),
                        documentBasisAssemblyOrderArr[index]
                    )
                )
                    editedError = true;
            }
        );

        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopAssemblyDelivered',
                        docNames.ASSEMBLY_DELIVERED,
                        'Assembly Order'
                    )
                )
            );
        }

        if (editedError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseOneItemInArrayChanged(
                        'dbopAssemblyDelivered',
                        docNames.ASSEMBLY_DELIVERED,
                        'Assembly Order'
                    )
                )
            );
        }

        // get fgObj
        let fgMapDocs = {};
        for (let key in fgMapRefs) {
            fgMapDocs[key] = await transaction.get(fgMapRefs[key]);
        }

        // check for existence. no need to check for changes because Actual qty will just
        // be incremented.
        for (let key in fgMapDocs) {
            if (!fgMapDocs[key].exists) existError = true;
        }
        //! this is an edge case scenario. should not happen but just in case.
        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopAssemblyDelivered',
                        docNames.ASSEMBLY_DELIVERED,
                        'FG'
                    )
                )
            );
        }

        // get sfgObj
        let sfgMapDocs = {};
        for (let key in sfgMapRefs) {
            sfgMapDocs[key] = await transaction.get(sfgMapRefs[key]);
        }

        // check for existence. no need to check for changes because Actual qty will just
        // be decremented.
        for (let key in sfgMapDocs) {
            if (!sfgMapDocs[key].exists) existError = true;
        }
        //! this is an edge case scenario. should not happen but just in case.
        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopAssemblyDelivered',
                        docNames.ASSEMBLY_DELIVERED,
                        'SFG'
                    )
                )
            );
        }

        // get all rm docs. no need to check for changes because Actual qty will just
        // be decremented.
        let rmMapDocs = {};
        for (let key in rmMapRefs) {
            rmMapDocs[key] = await transaction.get(rmMapRefs[key]);
        }

        // check if the rmMapDocs exist.
        for (let key in rmMapDocs) {
            if (!rmMapDocs[key].exists) existError = true;
        }

        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopAssemblyDelivered',
                        docNames.ASSEMBLY_DELIVERED,
                        'RM'
                    )
                )
            );
        }

        // END TRANSACTION GET OPERATIONS ============================================

        // START MANIPULATING DATA TO UPDATE DATABASE ================================

        // copy all the assemblyOrderNumbersArr of fgs from firebase to
        // fgMap.newAssemblyOrderNumbersArr.
        for (let key in fgMapDocs) {
            fgMap[key].newAssemblyOrderNumbersArr = [
                ...fgMapDocs[key].data().assemblyOrderNumbersArr,
            ];
        }

        // copy all the assemblyOrderNumbersArr of sfgs from firebase to sfgMap.
        // newAssemblyOrderNumbersArr.
        for (let key in sfgMapDocs) {
            sfgMap[key].newAssemblyOrderNumbersArr = [
                ...sfgMapDocs[key].data().assemblyOrderNumbersArr,
            ];
        }

        // copy all the assemblyOrderNumbersArr of rms from firebase to rmMap.
        // newAssemblyOrderNumbersArr.
        for (let key in rmMapDocs) {
            rmMap[key].newAssemblyOrderNumbersArr = [
                ...rmMapDocs[key].data().assemblyOrderNumbersArr,
            ];
        }

        // determine the correct fgAssembledQtyForThisAssembly to be added  for each FG.
        // determine the correct sfgUsedQtyForThisFgAssembled to be added  for each SFG.
        // determine the correct rmUsedQtyForThisFgAssembled to be deducted for each RM.
        assemblyDeliveredObj.assemblyOrderArr.forEach((assemblyOrder) => {
            assemblyOrder.fgArr.forEach((fg) => {
                // fgMap should include the fg.fgUID as property.
                fgMap[fg.fgUID].computedFgAssembledQty = saveWithFiveDecimals(
                    fgMap[fg.fgUID].computedFgAssembledQty +
                        fg.fgAssembledQtyForThisAssembly
                );

                // sfgMap
                fg.sfgArr.forEach((sfg) => {
                    sfgMap[
                        sfg.sfgUID
                    ].computedSfgActualUsedQty = saveWithFiveDecimals(
                        sfgMap[sfg.sfgUID].computedSfgActualUsedQty +
                            sfg.sfgUsedQtyForThisFgAssembled
                    );
                });

                // should include the rmUID as property.
                fg.rmArr.forEach((rm) => {
                    rmMap[
                        rm.rmUID
                    ].computedRmActualUsedQty = saveWithFiveDecimals(
                        rmMap[rm.rmUID].computedRmActualUsedQty +
                            rm.rmUsedQtyForThisFgAssembled
                    );
                });
            });
        });

        // create tempList to update status correctly.
        let tempAssemblyOrderList = assemblyOrderListDoc.data();

        assemblyDeliveredObj.assemblyOrderArr.forEach(
            async (assemblyOrder, assemblyOrderIndex) => {
                // make copy of assemblyOrder and change fgObjs, sfgObjs and rmObjs that are
                // nested with correct fgBalance/fgAssembled, sfgBalance/sfgActualUsedQty
                // and rmBalance/rmActualUsedQty. update firebase with this new objects.

                let assemblyOrderCopy = {
                    ...documentBasisAssemblyOrderArrDocs[
                        assemblyOrderIndex
                    ].data(),
                };

                let all_Fgs_Assembled_And_Sfgs_And_Rms_Used = [];

                //! check fgArr
                assemblyOrder.fgArr.forEach((fg, fgIndex) => {
                    let fgObjFromAssemblyOrder_AssembledQty =
                        assemblyOrderCopy.fgArr[fgIndex].fgAssembledQty;
                    let fgObjFromAssemblyOrder_balanceQty =
                        assemblyOrderCopy.fgArr[fgIndex].fgBalanceQty;

                    // check for balance error.
                    if (
                        saveWithFiveDecimals(
                            saveWithFiveDecimals(
                                fgObjFromAssemblyOrder_balanceQty +
                                    assemblyOrderCopy.fgArr[fgIndex]
                                        .fgOverrideQty
                            ) - fg.fgAssembledQtyForThisAssembly
                        ) < 0
                    ) {
                        balanceError = true;
                    }

                    assemblyOrderCopy.fgArr[fgIndex] = {
                        ...assemblyOrderCopy.fgArr[fgIndex],
                        fgAssembledQty: saveWithFiveDecimals(
                            fgObjFromAssemblyOrder_AssembledQty +
                                fg.fgAssembledQtyForThisAssembly
                        ),
                        fgBalanceQty: saveWithFiveDecimals(
                            fgObjFromAssemblyOrder_balanceQty -
                                fg.fgAssembledQtyForThisAssembly
                        ),
                    };

                    // if balance of fg is 0, push true to all_Fgs_Assembled_And_Sfgs_And_Rms_Used.
                    if (
                        saveWithFiveDecimals(
                            assemblyOrderCopy.fgArr[fgIndex].fgBalanceQty +
                                assemblyOrderCopy.fgArr[fgIndex].fgOverrideQty
                        ) === 0
                    ) {
                        all_Fgs_Assembled_And_Sfgs_And_Rms_Used.push(true);
                    } else all_Fgs_Assembled_And_Sfgs_And_Rms_Used.push(false);

                    //! sfgArr inside fgArr
                    fg.sfgArr.forEach((sfg, sfgIndex) => {
                        let sfgObjFromFgArrInAssemblyOrder_actualUsedQty =
                            assemblyOrderCopy.fgArr[fgIndex].sfgArr[sfgIndex]
                                .sfgActualUsedQty;
                        let sfgObjFromFgArrInAssemblyOrder_balanceQty =
                            assemblyOrderCopy.fgArr[fgIndex].sfgArr[sfgIndex]
                                .sfgBalanceQty;

                        // check for balance error.
                        if (
                            saveWithFiveDecimals(
                                saveWithFiveDecimals(
                                    sfgObjFromFgArrInAssemblyOrder_balanceQty +
                                        assemblyOrderCopy.fgArr[fgIndex].sfgArr[
                                            sfgIndex
                                        ].sfgOverrideQty
                                ) - sfg.sfgUsedQtyForThisFgAssembled
                            ) < 0
                        ) {
                            balanceError = true;
                        }

                        assemblyOrderCopy.fgArr[fgIndex].sfgArr[sfgIndex] = {
                            ...assemblyOrderCopy.fgArr[fgIndex].sfgArr[
                                sfgIndex
                            ],
                            sfgActualUsedQty: saveWithFiveDecimals(
                                sfgObjFromFgArrInAssemblyOrder_actualUsedQty +
                                    sfg.sfgUsedQtyForThisFgAssembled
                            ),
                            sfgBalanceQty: saveWithFiveDecimals(
                                sfgObjFromFgArrInAssemblyOrder_balanceQty -
                                    sfg.sfgUsedQtyForThisFgAssembled
                            ),
                        };

                        // if balance of sfg is 0, push true to all_Fgs_Assembled_And_Sfgs_And_Rms_Used.
                        if (
                            saveWithFiveDecimals(
                                assemblyOrderCopy.fgArr[fgIndex].sfgArr[
                                    sfgIndex
                                ].sfgBalanceQty +
                                    assemblyOrderCopy.fgArr[fgIndex].sfgArr[
                                        sfgIndex
                                    ].sfgOverrideQty
                            ) === 0
                        ) {
                            all_Fgs_Assembled_And_Sfgs_And_Rms_Used.push(true);
                        } else
                            all_Fgs_Assembled_And_Sfgs_And_Rms_Used.push(false);
                    });

                    //! rmArr inside fgArr
                    fg.rmArr.forEach((rm, rmIndex) => {
                        let rmObjFromFgArrInAssemblyOrder_actualUsedQty =
                            assemblyOrderCopy.fgArr[fgIndex].rmArr[rmIndex]
                                .rmActualUsedQty;
                        let rmObjFromFgArrInAssemblyOrder_balanceQty =
                            assemblyOrderCopy.fgArr[fgIndex].rmArr[rmIndex]
                                .rmBalanceQty;

                        // check for balance error.
                        if (
                            saveWithFiveDecimals(
                                saveWithFiveDecimals(
                                    rmObjFromFgArrInAssemblyOrder_balanceQty +
                                        assemblyOrderCopy.fgArr[fgIndex].rmArr[
                                            rmIndex
                                        ].rmOverrideQty
                                ) - rm.rmUsedQtyForThisFgAssembled
                            ) < 0
                        ) {
                            balanceError = true;
                        }

                        assemblyOrderCopy.fgArr[fgIndex].rmArr[rmIndex] = {
                            ...assemblyOrderCopy.fgArr[fgIndex].rmArr[rmIndex],
                            rmActualUsedQty: saveWithFiveDecimals(
                                rmObjFromFgArrInAssemblyOrder_actualUsedQty +
                                    rm.rmUsedQtyForThisFgAssembled
                            ),
                            rmBalanceQty: saveWithFiveDecimals(
                                rmObjFromFgArrInAssemblyOrder_balanceQty -
                                    rm.rmUsedQtyForThisFgAssembled
                            ),
                        };

                        // if balance of rm is 0, push true to all_Fgs_Assembled_And_Sfgs_And_Rms_Used.
                        if (
                            saveWithFiveDecimals(
                                assemblyOrderCopy.fgArr[fgIndex].rmArr[rmIndex]
                                    .rmBalanceQty +
                                    assemblyOrderCopy.fgArr[fgIndex].rmArr[
                                        rmIndex
                                    ].rmOverrideQty
                            ) === 0
                        ) {
                            all_Fgs_Assembled_And_Sfgs_And_Rms_Used.push(true);
                        } else
                            all_Fgs_Assembled_And_Sfgs_And_Rms_Used.push(false);
                    });
                });

                if (
                    all_Fgs_Assembled_And_Sfgs_And_Rms_Used.every(
                        (ans) => ans === true
                    )
                ) {
                    // assemblyOrder is fulfilled so remove assemblyOrderNumber from
                    // all fgs, sfgs and rms.
                    assemblyOrderCopy.fgArr.forEach((fg) => {
                        let newArr = [
                            ...fgMap[fg.fgUID].newAssemblyOrderNumbersArr,
                        ];
                        newArr = newArr.filter(
                            (assemlbyOrderNum) =>
                                assemlbyOrderNum !==
                                assemblyOrderCopy.assemblyUID
                        );
                        fgMap[fg.fgUID].newAssemblyOrderNumbersArr = newArr;

                        fg.sfgArr.forEach((sfg) => {
                            let newArrInSfg = [
                                ...sfgMap[sfg.sfgUID]
                                    .newAssemblyOrderNumbersArr,
                            ];
                            newArrInSfg = newArrInSfg.filter(
                                (assemlbyOrderNum) =>
                                    assemlbyOrderNum !==
                                    assemblyOrderCopy.assemblyUID
                            );
                            sfgMap[
                                sfg.sfgUID
                            ].newAssemblyOrderNumbersArr = newArrInSfg;
                        });

                        fg.rmArr.forEach((rm) => {
                            let newArrInRm = [
                                ...rmMap[rm.rmUID].newAssemblyOrderNumbersArr,
                            ];
                            newArrInRm = newArrInRm.filter(
                                (assemlbyOrderNum) =>
                                    assemlbyOrderNum !==
                                    assemblyOrderCopy.assemblyUID
                            );
                            rmMap[
                                rm.rmUID
                            ].newAssemblyOrderNumbersArr = newArrInRm;
                        });
                    });

                    // change status of assemblyOrder object
                    if (assemblyOrderCopy.metaHistory.length > 99)
                        assemblyOrderCopy.metaHistory.shift();

                    assemblyOrderCopy.metaHistory.push(assemblyOrderCopy.meta);

                    //update metadata in assemblyOrderCopy.
                    assemblyOrderCopy.meta = {
                        action: systemActions.FULFILLED,
                        uid: systemActions.UID,
                        email: systemActions.EMAIL,
                        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
                    };

                    // update temporary assemblyOrder master list
                    let assemblyOrderInfoObj = operationObjToMListItemFn(
                        assemblyOrderCopy,
                        'assemblyUID'
                    );

                    //use tempAssemblyOrderList to update the master prod order list
                    tempAssemblyOrderList = mListHelper.updateTempMList(
                        tempAssemblyOrderList,
                        assemblyOrderCopy.assemblyUID,
                        assemblyOrderInfoObj
                    );
                }

                // update individual Assembly Order object
                await transaction.set(
                    documentBasisAssemblyOrderArrRefs[assemblyOrderIndex],
                    assemblyOrderCopy,
                    { merge: true }
                );
            }
        );

        if (balanceError) {
            return Promise.reject(
                formatCustomErrorObj(
                    800,
                    appMessage.balanceError(
                        'Assembled/ActualUsed Qty',
                        'Balance Qty',
                        'FG/SFG/RM'
                    )
                )
            );
        }

        // update assemblyOrder master list.
        await transaction.set(assemblyOrderListRef, tempAssemblyOrderList);

        //==============================================================
        //! FG actual qty process.

        let tempFgInventoryList = fgInvListDoc.data();

        // change actual qty of the fgs
        for (let key in fgMap) {
            let data = {
                fgActualQty: saveWithFiveDecimals(
                    fgMapDocs[key].data().fgActualQty +
                        fgMap[key].computedFgAssembledQty
                ),
                assemblyOrderNumbersArr: fgMap[key].newAssemblyOrderNumbersArr,
            };

            let fgCopy = fgMapDocs[key].data();
            fgCopy.fgActualQty = data.fgActualQty;
            let inventoryFgInfoObj = fgInvObjToMListItemFn(fgCopy);

            tempFgInventoryList = mListHelper.updateTempMList(
                tempFgInventoryList,
                fgCopy.fgUID,
                inventoryFgInfoObj
            );

            await transaction.set(fgMapRefs[key], data, { merge: true });
        }

        //! SFG actual qty process.
        let tempSfgInventoryList = sfgInvListDoc.data();

        // change actual qty of the sfgMap
        for (let key in sfgMap) {
            let data = {
                sfgActualQty: saveWithFiveDecimals(
                    sfgMapDocs[key].data().sfgActualQty -
                        sfgMap[key].computedSfgActualUsedQty
                ),
                assemblyOrderNumbersArr: sfgMap[key].newAssemblyOrderNumbersArr,
            };

            let sfgCopy = sfgMapDocs[key].data();
            sfgCopy.sfgActualQty = data.sfgActualQty;
            let inventorySfgInfoObj = sfgInvObjToMListItemFn(sfgCopy);

            tempSfgInventoryList = mListHelper.updateTempMList(
                tempSfgInventoryList,
                sfgCopy.sfgUID,
                inventorySfgInfoObj
            );

            await transaction.set(sfgMapRefs[key], data, { merge: true });
        }

        //! RM actual qty process.
        let tempRmInventoryList = rmInvListDoc.data();

        // change actual qty of the rmMap
        for (let key in rmMap) {
            let data = {
                rmActualQty: saveWithFiveDecimals(
                    rmMapDocs[key].data().rmActualQty -
                        rmMap[key].computedRmActualUsedQty
                ),
                assemblyOrderNumbersArr: rmMap[key].newAssemblyOrderNumbersArr,
            };

            let rmCopy = rmMapDocs[key].data();
            rmCopy.rmActualQty = data.rmActualQty;
            let inventoryRmInfoObj = rmInvObjToMListItemFn(rmCopy);

            tempRmInventoryList = mListHelper.updateTempMList(
                tempRmInventoryList,
                rmCopy.rmUID,
                inventoryRmInfoObj
            );

            await transaction.set(rmMapRefs[key], data, { merge: true });
        }

        // update fgInventory master list
        await transaction.set(fgInvListRef, tempFgInventoryList);

        //update sfgInventory master list
        await transaction.set(sfgInvListRef, tempSfgInventoryList);

        //update rmInventory master list
        await transaction.set(rmInvListRef, tempRmInventoryList);

        // create or update assemblyDelivered list document in (master lists collection)
        if (assemblyDeliveredListDoc.exists) {
            let assemblyDeliveredInfoObj = operationObjToMListItemFn(
                assemblyDeliveredObj,
                'assemblyDeliveredUID'
            );

            let newData = mListHelper.updateMasterList(
                assemblyDeliveredListDoc,
                assemblyDeliveredObj.assemblyDeliveredUID,
                assemblyDeliveredInfoObj
            );

            await transaction.set(assemblyDeliveredListRef, newData);
        } else {
            let assemblyDeliveredInfoObj = operationObjToMListItemFn(
                assemblyDeliveredObj,
                'assemblyDeliveredUID'
            );

            let newData = mListHelper.addMasterList(
                assemblyDeliveredObj.assemblyDeliveredUID,
                assemblyDeliveredInfoObj
            );

            await transaction.set(assemblyDeliveredListRef, newData);
        }

        // set new assemblyDelivered document.
        await transaction.set(assemblyDeliveredRef, assemblyDeliveredObj);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(null, false, null, appMessage.successfulUpdateStatus);
    } catch (e) {
        return responseFn(null, true, e, appMessage.errorUpdatingStatus);
    }
};

export default {
    getAssemblyDeliveredFn,
    createAssemblyDeliveredFn,
    updateAssemblyDeliveredFn,
    deleteAssemblyDeliveredFn,
    statusChangeFn,
    approveAssemblyDeliveredFn,
};
