import { responseFn, titleCase, unique } from '../lib/util';
import { generalMessage } from '../lib/messages';

export const appSettings = (appSettingObj) => {
    // appSettingObj already is a copy. no need to make a copy.

    appSettingObj.taxRate = Number(appSettingObj.taxRate);
    let rmCats = appSettingObj.rawMatCategories;

    // check tax rate
    let taxRateError = '';

    if (
        Number(appSettingObj.taxRate) > 100 ||
        Number(appSettingObj.taxRate) < 0
    ) {
        taxRateError = generalMessage.INVALID_RATE;
    }

    if (isNaN(appSettingObj.taxRate)) {
        taxRateError = generalMessage.INVALID;
    }

    // no errors for rawMatCategories. free text.  just rearrange to occupy
    // indexes in the beginning.
    let values = [];

    for (let key in rmCats) {
        if (rmCats[key].trim()) {
            values.push(rmCats[key]);
        }
        // then delete the value in the object.
        rmCats[key] = '';
    }

    // title case all the categories entered
    values = values.map((category) => titleCase(category));

    // get only the unique values
    values = unique(values);

    // sort items
    values.sort();

    // form new rm category object
    for (let key in values) {
        rmCats[key] = values[key];
    }

    // check if there is an error
    if (taxRateError) {
        let errorObj = {
            taxRateError,
        };
        return responseFn(null, true, errorObj, 'Error detected');
    }

    let valid = { defaultTaxRate: appSettingObj.taxRate, rmCategories: rmCats };

    return responseFn(valid, false, {}, 'no error');
};
