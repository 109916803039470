import React, { useState, useContext } from 'react';
import { dbopsAssemblyDelivered } from '../../firebase_dbops';
import { MasterListContext, AuthContext } from '../../contexts';
import { InputIcon, ListView2 } from '../../components';
import OperationItem from '../../components/CollectionItems/OperationItem/OperationItem';
import { displayDate } from '../../lib/util';
import { permissions, userActions, lengths } from '../../lib/constants';
import '../PagesCommon.css';
import './AssemblyDeliveredPage.css';

import AssemblyDeliveredForm from './AssemblyDeliveredForm';

const view = {
    LIST_VIEW: 'listView',
    CREATE_ASSEMBLY_DELIVERED: 'addAssembly',
    EDIT_ASSEMBLY_DELIVERED: 'editAssembly',
    DELETE_ASSEMBLY_DELIVERED: 'deleteAssembly',
    ASSEMBLY_DELIVERED_PROFILE: 'assemblyProfile',
};

export default (props) => {
    let { setIsLoading, setAlertErrorMsg, setAlertMessage } = props;

    let { masterAssemblyDeliveredList } = useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);

    let [formView, setFormView] = useState(view.LIST_VIEW);
    let [currentDocument, setCurrentDocument] = useState('');
    let [searchText, setSearchText] = useState('');

    // functions to change views.
    const addAssemblyFn = () => setFormView(view.CREATE_ASSEMBLY_DELIVERED);
    const gotoListViewFn = () => setFormView(view.LIST_VIEW);
    const gotoEditViewFn = () => setFormView(view.EDIT_ASSEMBLY_DELIVERED);
    const gotoDeleteViewFn = () => setFormView(view.DELETE_ASSEMBLY_DELIVERED);
    const gotoAssemblyProfileViewFn = () =>
        setFormView(view.ASSEMBLY_DELIVERED_PROFILE);

    // function to get assembly document.
    const getDocumentFromDBFn = async (docID) => {
        setIsLoading(true);
        let response = await dbopsAssemblyDelivered.getAssemblyDeliveredFn(
            docID
        );
        if (response.error)
            setAlertErrorMsg(response.errorObj.message || response.message);
        else {
            setCurrentDocument(response.data);
            gotoAssemblyProfileViewFn();
        }
        setIsLoading(false);
    };

    // function to create Supplier
    const createAssemblyDeliveredFn = async (assemDelObj) => {
        // spinner on.
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        // write to db.
        let response = await dbopsAssemblyDelivered.createAssemblyDeliveredFn(
            userCreds,
            assemDelObj
        );
        if (response.error) setAlertErrorMsg(response.errorObj.message);
        else setAlertMessage(response.message);

        gotoListViewFn();

        // spinner off.
        setIsLoading(false);
    };

    // function to update assembly.
    const updateAssemblyDeliveredFn = async (assemDelObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsAssemblyDelivered.updateAssemblyDeliveredFn(
            userCreds,
            assemDelObj,
            documentBasis
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);

            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
        } else setAlertMessage(response.message);

        gotoListViewFn();
        await getDocumentFromDBFn(assemDelObj.assemblyDeliveredUID);

        setIsLoading(false);
    };

    // function to delete assembly.
    const deleteAssemblyDeliveredFn = async (assemDelObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsAssemblyDelivered.deleteAssemblyDeliveredFn(
            userCreds,
            assemDelObj,
            documentBasis
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);

            if (response.errorObj.code === 1001) {
                gotoListViewFn();
                await getDocumentFromDBFn(assemDelObj.assemblyDeliveredUID);
                setIsLoading(false);
                return;
            }
        } else setAlertMessage(response.message);

        gotoListViewFn();

        setIsLoading(false);
    };

    // function for status change of document.
    const statusChangeFn = async (
        userActionPassed,
        assemDelObj,
        documentBasis,
        documentBasisAssemblyOrder
    ) => {
        setIsLoading(true);

        let userCreds = {
            action: userActionPassed,
            uid: authUser.uid,
            email: authUser.email,
        };

        let dbFn = dbopsAssemblyDelivered.statusChangeFn;

        if (userActionPassed === userActions.APPROVED) {
            dbFn = dbopsAssemblyDelivered.approveAssemblyDeliveredFn;
        }

        let response = await dbFn(
            userCreds,
            assemDelObj,
            documentBasis,
            documentBasisAssemblyOrder
        );
        if (response.error) {
            // meaning: response.data contains detailed error obj.
            if (response.data) setAlertErrorMsg(response.data);
            else setAlertErrorMsg(response.errorObj.message);

            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
        } else setAlertMessage(response.message);

        gotoListViewFn();
        await getDocumentFromDBFn(assemDelObj.assemblyDeliveredUID);

        setIsLoading(false);
    };

    // filter function =====================================
    let filteredAssemblyDeliveredList = masterAssemblyDeliveredList.filter(
        (assembly) => {
            return (
                assembly.assemblyDeliveredUID
                    .toUpperCase()
                    .includes(searchText.toUpperCase()) ||
                displayDate(assembly.assemblyDeliveredDate)
                    .toUpperCase()
                    .includes(searchText.toUpperCase()) ||
                assembly.status
                    .toUpperCase()
                    .includes(searchText.toUpperCase()) ||
                assembly.assemblyDeliveredDescription
                    .toUpperCase()
                    .includes(searchText.toUpperCase())
            );
        }
    );

    let addAssemblyDeliveredBtnUI = (
        <button className='btn btn-primary' onClick={addAssemblyFn}>
            Create Assembly
        </button>
    );
    if (
        !authUser.permissions.includes(
            permissions.ASSEMBLY_DELIVERED_REQUEST
        ) ||
        masterAssemblyDeliveredList.length >= lengths.masterPOSDeptsLimits ||
        !authUser.subscriptionStatusIsActive
    ) {
        addAssemblyDeliveredBtnUI = null;
    }

    let topSectionContent = (
        <section className='top-section'>
            <InputIcon
                id='suppliers-search-input'
                type='text'
                placeholder='Search'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                icon={
                    <i
                        className='ms-Icon ms-Icon--Search'
                        aria-hidden='true'></i>
                }
            />
            {addAssemblyDeliveredBtnUI}
        </section>
    );

    let mainSectionContent = (
        <ListView2
            getDocumentFromDBFn={getDocumentFromDBFn}
            list={filteredAssemblyDeliveredList}
            keyProperty={'assemblyDeliveredUID'}
            CollectionItemComponent={OperationItem}
        />
    );

    if (formView !== view.LIST_VIEW) topSectionContent = null;

    if (formView === view.CREATE_ASSEMBLY_DELIVERED) {
        mainSectionContent = (
            <AssemblyDeliveredForm
                purpose='ADD'
                backCancelBtnFn={gotoListViewFn}
                createAssemblyDeliveredFn={createAssemblyDeliveredFn}
                setIsLoading={setIsLoading}
                setAlertErrorMsg={setAlertErrorMsg}
            />
        );
    }

    if (formView === view.EDIT_ASSEMBLY_DELIVERED) {
        mainSectionContent = (
            <AssemblyDeliveredForm
                purpose='EDIT'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoAssemblyProfileViewFn}
                updateAssemblyDeliveredFn={updateAssemblyDeliveredFn}
                setAlertErrorMsg={setAlertErrorMsg}
                setIsLoading={setIsLoading}
            />
        );
    }

    if (formView === view.DELETE_ASSEMBLY_DELIVERED) {
        mainSectionContent = (
            <AssemblyDeliveredForm
                purpose='DELETE'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoAssemblyProfileViewFn}
                deleteAssemblyDeliveredFn={deleteAssemblyDeliveredFn}
                setAlertErrorMsg={setAlertErrorMsg}
                setIsLoading={setIsLoading}
            />
        );
    }

    if (formView === view.ASSEMBLY_DELIVERED_PROFILE) {
        mainSectionContent = (
            <AssemblyDeliveredForm
                purpose='VIEW'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoListViewFn}
                gotoEditViewFn={gotoEditViewFn}
                gotoDeleteViewFn={gotoDeleteViewFn}
                setIsLoading={setIsLoading}
                setAlertErrorMsg={setAlertErrorMsg}
                statusChangeFn={statusChangeFn}
            />
        );
    }

    return (
        <div className='content-layout'>
            {topSectionContent}

            <section className='main-section'>{mainSectionContent}</section>
        </div>
    );
};
