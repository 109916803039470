import React, { useState, useEffect, useContext } from 'react';
import {
    DataShareContext,
    AuthContext,
    MasterListContext,
} from '../../contexts';
import { useHistory } from 'react-router-dom';
import { routes, permissions, lengths } from '../../lib/constants';
import { InventoryButtons, BackOnlyButton } from '../../components';

import '../PagesCommon.css';
import '../RawMaterialsPage/RawMaterials.css';
import { displayReadableNumber } from '../../lib/util';

export default (props) => {
    let {
        currentDocument,

        backCancelBtnFn,
    } = props;

    let { masterInventorySFGsList } = useContext(MasterListContext);

    let { dataShare, setDataShare } = useContext(DataShareContext);
    let { authUser } = useContext(AuthContext);

    const history = useHistory();

    let [sfgUID, setSfgUID] = useState('');
    let [sfgCode, setSfgCode] = useState('');
    let [sfgUnit, setSfgUnit] = useState('');
    let [sfgLowThreshold, setSfgLowThreshold] = useState('');
    let [rmArr, setRmArr] = useState([]);
    let [sfgActualQty, setSfgActualQty] = useState('');
    let [sfgProjectedQty, setSfgProjectedQty] = useState('');

    useEffect(() => {
        setSfgUID(currentDocument.sfgUID);
        setSfgCode(currentDocument.sfgCode);
        setSfgUnit(currentDocument.sfgUnit);
        setSfgLowThreshold(currentDocument.sfgLowThreshold);
        setRmArr(currentDocument.rmArr);
        setSfgActualQty(currentDocument.sfgActualQty);
        setSfgProjectedQty(currentDocument.sfgProjectedQty);
    }, []);

    useEffect(() => {
        if (dataShare.isActive) history.push(routes.PRODUCTION);
    }, [dataShare.isActive]);

    const createProductionOrderFn = () =>
        setDataShare({ isActive: true, data: sfgUID });

    // conditional render of contents  =============================

    let rmArrUI = [];

    rmArr.forEach((rm, index) => {
        rmArrUI.push(
            <div className='span-2-columns-container' key={index}>
                <div className='view-table-row'>
                    <div className='cell'>
                        <span className='mobile-label m-right-15'>
                            {`${index + 1}.`}
                        </span>
                        <span className='desktop-view m-lr-15'>
                            {`${index + 1}.`}
                        </span>
                        <span className=''> {rm.rmUID}</span>
                    </div>

                    <div className='cell'>
                        <span className='width50'>
                            <span className='mobile-label'>Required Qty:</span>{' '}
                            <span className=''>{rm.rmRequiredQty}</span>
                            <span className='font12'> {rm.rmUnit}</span>
                        </span>
                        <span className='width50'>
                            <span className='mobile-label'>Group:</span>{' '}
                            <span className=''>{rm.group}</span>
                        </span>
                    </div>
                </div>
            </div>
        );
    });

    let mainContent = (
        <section className='form-main-section'>
            <div className='form-profile-info-items'>
                <p className='parent-labels'>SFG Name</p>
                <p className=''>{sfgUID}</p>
            </div>
            <div className='form-profile-info-items'>
                <p className='parent-labels'>SFG Code</p>
                <p className=''>{sfgCode}</p>
            </div>
            <div className='form-profile-info-items'>
                <p className='parent-labels'>SFG Unit</p>
                <p className=''>{sfgUnit}</p>
            </div>
            <div className='form-profile-info-items'>
                <p className='parent-labels'>SFG Low Threshold</p>
                <p className=''>{displayReadableNumber(sfgLowThreshold)}</p>
            </div>
            <div className='form-profile-info-items'>
                <p className='parent-labels'>SFG Projected Qty</p>
                <p className=''>{displayReadableNumber(sfgProjectedQty)}</p>
            </div>
            <div className='form-profile-info-items'>
                <p className='parent-labels'>SFG Actual Qty</p>
                <p className=''>{displayReadableNumber(sfgActualQty)}</p>
            </div>

            <div className='span-2-columns-container label-padding label-highlight'>
                <label className='parent-labels'>Raw Materials List</label>
            </div>

            <div className='span-2-columns-container'>
                <div className='view-table-heading'>
                    <div className='cell'>
                        <span className='m-left-15'>RM Type - Description</span>
                    </div>

                    <div className='cell'>
                        <span className='width50'>Required Qty</span>

                        <span className='width50'>Group</span>
                    </div>
                </div>

                {rmArrUI}
            </div>
        </section>
    );

    let buttonSection = (
        <InventoryButtons
            backBtnFn={backCancelBtnFn}
            createBtnFn={createProductionOrderFn}
            createBtnText='Create Production Order'
        />
    );

    if (
        !authUser.permissions.includes(permissions.INV_SFG) ||
        masterInventorySFGsList.length >= lengths.masterListLimit ||
        !authUser.subscriptionStatusIsActive
    ) {
        buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    }

    return (
        <div className='common-form'>
            <section className='form-title-section'>
                Semi-Finished Good Profile
            </section>

            {mainContent}

            {buttonSection}

            <div className='general-error-message'></div>
            <div className='watermark'></div>
        </div>
    );
};
