import rmInvObjToMListItemFn from './rmInvObjToMListItemFn';

export default (rmInventoryMasterlistStringFormat) => {
    let newArr = [];
    let rmInventoryObj = JSON.parse(rmInventoryMasterlistStringFormat);

    for (let key in rmInventoryObj) {
        let [rmType, rmDescription] = key.split(' - ');

        newArr.push({
            rmUID: key,
            rmType,
            rmDescription,
            rmCode: rmInventoryObj[key].a,
            rmCategory: rmInventoryObj[key].b,
            rmUnit: rmInventoryObj[key].c,
            rmLowThreshold: rmInventoryObj[key].d,
            rmProjectedQty: rmInventoryObj[key].e,
            rmActualQty: rmInventoryObj[key].f,
            rmAltUnit: rmInventoryObj[key].g || '',
            rmAltUnitMultiplier: rmInventoryObj[key].h || '',
        });
    }

    return newArr;
};
