import { db } from '../firebase_config/firebaseConfig';
import { responseFn, formatCustomErrorObj } from '../lib/util';
import { docNames, appMessage } from '../lib/messages';
import validate from '../validators';
import mListHelper from './mListHelper';

import {
    USERS_COL,
    MASTER_LISTS_COL,
    MASTER_USERS_LIST_DOC,
} from '../lib/constants';

const updateUserFn = async (userObj, documentBasis) => {
    let userRef = db.collection(USERS_COL).doc(userObj.userUID);

    let masterUsersListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_USERS_LIST_DOC);

    const transactionFn = async (transaction) => {
        let userDoc = await transaction.get(userRef);

        if (!userDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.USER)
                )
            );

        // check changes in permissions and displayName.
        if (
            !validate.authUserPermissionsNoChange(
                userDoc.data().permissions,
                documentBasis.permissions
            ) &&
            userDoc.data().displayName === documentBasis.displayName
        ) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.USER)
                )
            );
        }

        let usersListDoc = await transaction.get(masterUsersListRef);

        let userInfoObj = {
            a: userObj.email,
            b: userObj.displayName,
        };

        // create or update users list document in (master lists collection)
        if (userDoc.exists) {
            let newData = mListHelper.updateMasterList(
                usersListDoc,
                userObj.userUID,
                userInfoObj
            );

            await transaction.set(masterUsersListRef, newData);
        } else {
            let newData = mListHelper.addMasterList(
                userObj.userUID,
                userInfoObj
            );

            await transaction.set(masterUsersListRef, newData);
        }

        await transaction.set(userRef, userObj);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyUpdated(docNames.USER)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorUpdating(docNames.USER)
        );
    }
};

export default {
    updateUserFn,
};
