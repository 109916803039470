import React, { useEffect, useState, useContext } from 'react';
import { InputDS, Datalist, AddButton } from '../../components';
import { lengths } from '../../lib/constants';
import { MasterListContext } from '../../contexts';

import '../PagesCommon.css';
import './PDPOsPage.css';

export default (props) => {
    let {
        rmObj,
        rmIndex,

        updateOrderQtyFn,
        updateSupplierArrFn,
        updateChosenSupplierFn,

        trashIcon,
        addToCanvasSupArrFn,
        removeFromCanvasSupArrFn,

        datalistDisplayResetCounter,
    } = props;

    let { masterSuppliersList } = useContext(MasterListContext);

    let [radioSelected, setRadioSelected] = useState('');
    let [supplierNameState, setSupplierNameState] = useState('');

    useEffect(() => {
        setRadioSelected(rmObj.chosenSupplier);
    }, []);

    const radioFn = (val) => {
        updateChosenSupplierFn(val, rmIndex);
        setRadioSelected(val);
    };

    let datalistOptions = masterSuppliersList.map((sup) => sup.supplierUID);
    let supplierAlreadyInTheUI = rmObj.canvasSupplierArr.map(
        (sup) => sup.supplierUID
    );

    let addBtn = <AddButton disabled />;

    if (
        datalistOptions.includes(supplierNameState) &&
        !supplierAlreadyInTheUI.includes(supplierNameState)
    )
        addBtn = (
            <AddButton
                onClick={() =>
                    addToCanvasSupArrFn(supplierNameState, rmObj.rmUID, rmIndex)
                }
            />
        );

    let supplierDatalist = (
        <div className='subform-2-column-section'>
            <div className='datalist-addbutton-container'>
                <Datalist
                    label='Add Supplier'
                    list={datalistOptions}
                    initialValue={''}
                    getInputValue={(val) => setSupplierNameState(val)}
                    errorMessage={rmObj.getSupplierFnError}
                    resetCounter={datalistDisplayResetCounter}></Datalist>

                {addBtn}
            </div>
        </div>
    );

    if (supplierAlreadyInTheUI.length > lengths.supplierCanvasLength)
        supplierDatalist = null;

    let listOfSuppliers = rmObj.canvasSupplierArr.map((sup, idx) => {
        return (
            <div className='sls-items' key={idx}>
                <div>
                    <label
                        className='slsi-label'
                        //! must include the rmIndex in the id and htmlFor to ensure each
                        //! supplierUID chosen is unique in the DOM.
                        htmlFor={`${sup.supplierUID}-${rmIndex}`}>
                        <input
                            type='radio'
                            value={sup.supplierUID}
                            id={`${sup.supplierUID}-${rmIndex}`}
                            checked={radioSelected === sup.supplierUID}
                            onChange={() => radioFn(sup.supplierUID)}></input>

                        <div>
                            <div>{sup.supplierUID}</div>

                            <div className='supplier-contact'>
                                <div className='m-right-15'>
                                    {sup.contactPerson}
                                </div>
                                <div>{sup.contactNumber1}</div>
                            </div>
                        </div>
                    </label>
                    <span
                        className='mobile-view remove-sup'
                        onClick={() => removeFromCanvasSupArrFn(rmIndex, idx)}>
                        X
                    </span>
                </div>

                <div className='slsi-inputs'>
                    <div className='flex-row'>
                        <InputDS
                            label='Price'
                            type='number'
                            initialValue={sup.cost}
                            getInputValue={(val) =>
                                updateSupplierArrFn(val, 'cost', rmIndex, idx)
                            }
                            errorMessage={sup.costError}></InputDS>
                    </div>

                    <div className='flex-row'>
                        <InputDS
                            label='Date'
                            type='date'
                            initialValue={sup.costDate}
                            getInputValue={(val) =>
                                updateSupplierArrFn(
                                    val,
                                    'costDate',
                                    rmIndex,
                                    idx
                                )
                            }
                            errorMessage={sup.costDateError}></InputDS>
                        <span
                            className='desktop-view remove-sup'
                            onClick={() =>
                                removeFromCanvasSupArrFn(rmIndex, idx)
                            }>
                            X
                        </span>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <div className='subform'>
            <div className='subform-label-qtys-section'>
                <div className='label'> {`${rmIndex + 1}. ${rmObj.rmUID}`}</div>

                <div className='icon'>{trashIcon}</div>
            </div>

            <div className='subform-2-column-section'>
                <InputDS
                    label='Order Quantity:'
                    type='number'
                    initialValue={rmObj.rmOrderedQty}
                    getInputValue={(val) => updateOrderQtyFn(val, rmIndex)}
                    errorMessage={rmObj.rmOrderedQtyError}></InputDS>
                <div className='m-top-10 m-left-5'>{rmObj.rmUnit}</div>
            </div>

            <div className='subform-list-section'>
                <div className='sls-label'>List of Suppliers </div>
                <div className='error-message'>{rmObj.chosenSupplierError}</div>
                {supplierDatalist}
                {listOfSuppliers}
            </div>
        </div>
    );
};
