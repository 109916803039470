import { lengths } from '../lib/constants';
import { generalMessage, appMessage } from '../lib/messages';
import { saveWithTwoDecimalOnly, saveWithFiveDecimals } from '../lib/util';
import { validateUID } from '../lib/util';

export const semiFG = (
    originalSFGObj,
    rmUIDsArr,
    purpose,
    masterListRM,
    documentBasis = {}
) => {
    let sfgCodeErr = '';
    let sfgNameErr = '';
    let sfgLowThresholdErr = '';
    let arraysErr = '';
    let sfgUnitErr = '';
    let sfgPriceErr = '';
    let sfgPriceErr2 = '';
    let sfgPriceErr3 = '';
    let sfgPriceErr4 = '';
    let sfgPriceErr5 = '';
    let sfgActualQtyErr = '';

    let rmArrWithError = false;

    let {
        sfgCode,
        sfgName,
        sfgUnit,
        sfgLowThreshold,
        rmArr,
        sfgPrice,
        sfgPrice2,
        sfgPrice3,
        sfgPrice4,
        sfgPrice5,
        sfgActualQty,
    } = originalSFGObj;

    // make a new copy of rmArr.
    rmArr = JSON.parse(JSON.stringify(rmArr));

    // console.log(sfgPrice);
    // console.log(sfgPrice2);
    // console.log(sfgPrice3);
    // console.log(sfgPrice4);
    // console.log(sfgPrice5);

    // make a new copy of fgObj to ensure no side effect happens outside the function.
    let sfgObj = {
        ...originalSFGObj,
        rmArr,
    };

    //! start validation of properties

    // sfgName validation !!

    sfgNameErr = validateUID(sfgName).errorMessage;

    // sfgUnit validation  !! Required
    if (sfgUnit === '' || sfgUnit === ' ') sfgUnitErr = generalMessage.REQUIRED;
    if (sfgUnit.length > lengths.unit)
        sfgUnitErr = generalMessage.INVALID_LENGTH;

    // sfgLowThreshold validation !! Required
    if (sfgLowThreshold === '' || sfgLowThreshold === ' ')
        sfgLowThresholdErr = generalMessage.REQUIRED;
    if (Number(sfgLowThreshold) > lengths.numberInputAmount)
        sfgLowThresholdErr = generalMessage.INVALID_AMOUNT;
    else {
        if (isNaN(sfgLowThreshold)) {
            sfgLowThresholdErr = generalMessage.INVALID;
        }
        if (!isNaN(sfgLowThreshold) && Number(sfgLowThreshold) < 0) {
            sfgLowThresholdErr = generalMessage.INVALID;
        }
    }

    // sfgCode validation
    if (sfgCode.length > lengths.code)
        sfgCodeErr = generalMessage.INVALID_LENGTH;

    // sfgPrice validation
    if (sfgPrice === '' || sfgPrice === ' ')
        sfgPriceErr = generalMessage.REQUIRED;
    if (Number(sfgPrice) > lengths.numberInputAmount)
        sfgPriceErr = generalMessage.INVALID_AMOUNT;
    else {
        if (isNaN(sfgPrice)) {
            sfgPriceErr = generalMessage.INVALID;
        }
        if (!isNaN(sfgPrice) && Number(sfgPrice) < 0.01) {
            sfgPriceErr = generalMessage.INVALID_AMOUNT;
        }
    }

    // sfgPrice2 validation

    if (Number(sfgPrice2) > lengths.numberInputAmount)
        sfgPriceErr2 = generalMessage.INVALID_AMOUNT;
    else {
        if (isNaN(sfgPrice2)) {
            sfgPriceErr2 = generalMessage.INVALID;
        }
        if (!isNaN(sfgPrice2) && Number(sfgPrice2) < 0) {
            sfgPriceErr2 = generalMessage.INVALID_AMOUNT;
        }
    }

    // sfgPrice3 validation

    if (Number(sfgPrice3) > lengths.numberInputAmount)
        sfgPriceErr3 = generalMessage.INVALID_AMOUNT;
    else {
        if (isNaN(sfgPrice3)) {
            sfgPriceErr3 = generalMessage.INVALID;
        }
        if (!isNaN(sfgPrice3) && Number(sfgPrice3) < 0) {
            sfgPriceErr3 = generalMessage.INVALID_AMOUNT;
        }
    }

    // sfgPrice4 validation

    if (Number(sfgPrice4) > lengths.numberInputAmount)
        sfgPriceErr4 = generalMessage.INVALID_AMOUNT;
    else {
        if (isNaN(sfgPrice4)) {
            sfgPriceErr4 = generalMessage.INVALID;
        }
        if (!isNaN(sfgPrice4) && Number(sfgPrice4) < 0) {
            sfgPriceErr4 = generalMessage.INVALID_AMOUNT;
        }
    }

    // sfgPrice5 validation

    if (Number(sfgPrice5) > lengths.numberInputAmount)
        sfgPriceErr5 = generalMessage.INVALID_AMOUNT;
    else {
        if (isNaN(sfgPrice5)) {
            sfgPriceErr5 = generalMessage.INVALID;
        }
        if (!isNaN(sfgPrice5) && Number(sfgPrice5) < 0) {
            sfgPriceErr5 = generalMessage.INVALID_AMOUNT;
        }
    }

    // feature added 6-17-20.  sfgActual qty validation as admin is now allowed to enter the
    // sfgActual qty.
    if (sfgActualQty === '') sfgActualQty = 0;

    if (Number(sfgActualQty) > lengths.numberInputAmount)
        sfgActualQtyErr = generalMessage.INVALID_AMOUNT;
    else {
        if (isNaN(sfgActualQty)) {
            sfgActualQtyErr = generalMessage.INVALID;
        }
        if (!isNaN(sfgActualQty) && Number(sfgActualQty) < 0) {
            sfgActualQtyErr = generalMessage.INVALID;
        }
    }

    //! this should never run unless source code was hacked.
    // rmSuppliersArr valiation !! Required
    if (rmArr.length > lengths.sfgSetupRmArrLength)
        arraysErr = appMessage.sourceCodeErrorMessage(
            'SFG: rmArr length error.'
        );

    // check if there is at least one rm or sfg in the arrays.
    if (rmArr.length === 0) arraysErr = 'Must contain at least one RM.';

    //! rmArr validation ====================================================

    let noDuplicateRawMatNamesArr = [];

    // map for group and requiredQty
    let mapRMQtyPerGroup = {};

    // reset all error messages inside rmArr.
    let rawMatArr = rmArr.map((rm) => {
        return {
            ...rm,
            errorMessage: '',
            qtyErrorMessage: '',
            groupErrorMessage: '',
        };
    });

    //! less checking during delete so deleted sfg or rm components will not throw an error.
    if (purpose !== 'DELETE') {
        // check validity of rmUID and rmRequiredQty.
        rawMatArr.forEach((rm, index) => {
            if (!rmUIDsArr.includes(rm.rmUID)) {
                rm.errorMessage = 'Raw Material not found.';
                rmArrWithError = true;
            }
            if (rm.rmUID.trim() === '') {
                rm.errorMessage =
                    index === 0 ? 'Required.' : 'Delete if blank.';
                rmArrWithError = true;
            }
            if (noDuplicateRawMatNamesArr.includes(rm.rmUID)) {
                rm.errorMessage = 'Invalid entry. Duplicate Raw Material.';
                rmArrWithError = true;
            } else noDuplicateRawMatNamesArr.push(rm.rmUID);

            // conditions for rmRequiredQty
            if (Number(rm.rmRequiredQty) <= 0 || rm.rmRequiredQty === '') {
                rm.qtyErrorMessage = generalMessage.GREATER_ZERO;
                rmArrWithError = true;
            }

            if (Number(rm.rmRequiredQty) > lengths.numberInputAmount) {
                rm.qtyErrorMessage = generalMessage.INVALID_AMOUNT;
                rmArrWithError = true;
            }

            if (isNaN(rm.rmRequiredQty)) {
                rm.qtyErrorMessage = generalMessage.INVALID;
                rmArrWithError = true;
            }

            // check all rmRequiredQty belonging to same group have the
            // same value.
            let idx = `a${rm.group}`;
            if (mapRMQtyPerGroup[idx]) {
                if (
                    Number(rm.rmRequiredQty) !== Number(mapRMQtyPerGroup[idx])
                ) {
                    rm.qtyErrorMessage =
                        'Grouped RMs must all have the same Required Qty.';
                    rmArrWithError = true;
                }
            } else mapRMQtyPerGroup[idx] = rm.rmRequiredQty;

            // check that group property is not empty.
            if (rm.group === '') {
                rm.groupErrorMessage = 'Must assign a group number.';
                rmArrWithError = true;
            }

            if (Number(rm.group) < 0 || Number(rm.group) > 20) {
                rm.groupErrorMessage = 'Must assign a group from 0 - 20.';
                rmArrWithError = true;
            }
            if (isNaN(rm.group)) {
                rm.groupErrorMessage = generalMessage.INVALID;
                rmArrWithError = true;
            }
        });
    }

    if (
        sfgCodeErr ||
        sfgNameErr ||
        sfgLowThresholdErr ||
        arraysErr ||
        sfgUnitErr ||
        rmArrWithError ||
        sfgPriceErr ||
        sfgPriceErr2 ||
        sfgPriceErr3 ||
        sfgPriceErr4 ||
        sfgPriceErr5 ||
        sfgActualQtyErr
    ) {
        return {
            error: true,
            errorObj: {
                sfgCodeErr,
                sfgNameErr,
                sfgLowThresholdErr,
                arraysErr,
                sfgUnitErr,
                rawMatArr,
                sfgPriceErr,
                sfgPriceErr2,
                sfgPriceErr3,
                sfgPriceErr4,
                sfgPriceErr5,
                sfgActualQtyErr,
            },
        };
    }

    // if there is no error,
    // 1. clean up rmArr before sending to parent element. ==========================

    //! less checking during delete so deleted sfg or rm components will not throw an error.
    if (purpose !== 'DELETE') {
        let updatedRMArr = rawMatArr.map((item) => {
            delete item.errorMessage;
            delete item.qtyErrorMessage;
            delete item.groupErrorMessage;

            //feater added 5-5-2020 to get the rmUnit of each rm from masterlist.
            let extractedUnit = masterListRM.filter(
                (rm) => rm.rmUID === item.rmUID
            )[0].rmUnit;

            let newItem = {
                ...item,
                rmUnit: extractedUnit,
            };

            return newItem;
        });

        // 2. sort the rmArr by group and Name. ==============================

        let sorterFn = (a, b) => {
            // group is ensured to be a number by now.
            let aGroup =
                a.group.toString().length === 1
                    ? a.group.padStart(2, '0')
                    : a.group;
            let bGroup =
                b.group.toString().length === 1
                    ? b.group.padStart(2, '0')
                    : b.group;

            let itemA = `${aGroup}${a.rmUID}`;
            let itemB = `${bGroup}${b.rmUID}`;
            if (itemA > itemB) return 1;
            if (itemA < itemB) return -1;
            return 0;
        };

        let sortedRMArr = updatedRMArr.sort(sorterFn);

        // ensure data type number of all properties if it is a quantity.
        sfgObj.sfgLowThreshold = saveWithFiveDecimals(sfgObj.sfgLowThreshold);
        sfgObj.rmArr = sortedRMArr.map((item) => {
            return {
                ...item,
                rmRequiredQty: saveWithFiveDecimals(item.rmRequiredQty),
            };
        });
        sfgObj.sfgPrice = saveWithTwoDecimalOnly(sfgObj.sfgPrice);
        sfgObj.sfgPrice2 =
            Number(sfgObj.sfgPrice2) !== 0
                ? saveWithTwoDecimalOnly(sfgObj.sfgPrice2)
                : saveWithTwoDecimalOnly(sfgObj.sfgPrice);
        sfgObj.sfgPrice3 =
            Number(sfgObj.sfgPrice3) !== 0
                ? saveWithTwoDecimalOnly(sfgObj.sfgPrice3)
                : saveWithTwoDecimalOnly(sfgObj.sfgPrice);
        sfgObj.sfgPrice4 =
            Number(sfgObj.sfgPrice4) !== 0
                ? saveWithTwoDecimalOnly(sfgObj.sfgPrice4)
                : saveWithTwoDecimalOnly(sfgObj.sfgPrice);
        sfgObj.sfgPrice5 =
            Number(sfgObj.sfgPrice5) !== 0
                ? saveWithTwoDecimalOnly(sfgObj.sfgPrice5)
                : saveWithTwoDecimalOnly(sfgObj.sfgPrice);
    }

    if (purpose === 'ADD') {
        // complete the schema of the sfgObj.
        sfgObj.sfgUID = sfgObj.sfgName;

        sfgObj.prodOrderNumbersArr = [];
        sfgObj.assemblyOrderNumbersArr = [];
        sfgObj.sdpoNumbersArr = [];

        // empty sfgActualQty was converted to 0 already.
        if (sfgActualQty === 0) {
            sfgObj.sfgActualQty = 0; // number
            sfgObj.sfgProjectedQty = 0; // number
        } else {
            sfgObj.sfgActualQty = saveWithFiveDecimals(sfgActualQty);
            sfgObj.sfgProjectedQty = saveWithFiveDecimals(sfgActualQty);
        }
    }
    // if purpose is not to create this sfgObj, the values are just going to be passed
    // through.

    // will change projected and actual qty automatically.
    if (
        purpose === 'EDIT' &&
        Number(sfgActualQty) !== Number(documentBasis.sfgActualQty)
    ) {
        let result = Number(sfgActualQty) - Number(documentBasis.sfgActualQty);
        sfgObj.sfgProjectedQty = saveWithFiveDecimals(
            documentBasis.sfgProjectedQty + result
        );
        sfgObj.sfgActualQty = saveWithFiveDecimals(
            documentBasis.sfgActualQty + result
        );
    }

    return {
        error: false,
        errorObj: {},
        data: sfgObj,
    };
};
