import React from 'react';
import { UpdateItemButtons } from '../../components';
import { lengths } from '../../lib/constants';
import { Input } from '../../components';

import '../PagesCommon.css';
import './ProfilePage.css';

export default (props) => {
    let {
        purpose,

        gotoChangeNameViewFn,
        gotoChangePasswordViewFn,
        backCancelBtnFn,
        view,

        authUser,

        changeNameFn,
        nameError,

        changePasswordFn,
        newPasswordError,
        currentPasswordError,

        newPassword,
        setNewPassword,
        currentPassword,
        setCurrentPassword,
        name,
        setName,
    } = props;

    // conditional render of contents  =============================
    let buttonSection = null;
    let mainContent = null;

    if (purpose === view.CHANGE_NAME_VIEW) {
        mainContent = (
            <section className='form-main-section'>
                <Input
                    label='Change Name:'
                    maxLength={lengths.displayName}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    errorMessage={nameError}
                />
                <div className='desktop-view form-profile-info-items'></div>
            </section>
        );

        buttonSection = (
            <UpdateItemButtons
                saveFn={() => changeNameFn(name)}
                cancelFn={backCancelBtnFn}
            />
        );
    }

    if (purpose === view.CHANGE_PASSWORD_VIEW) {
        mainContent = (
            <section className='form-main-section'>
                <Input
                    label='Current Password:'
                    type='password'
                    maxLength={lengths.password}
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    errorMessage={currentPasswordError}
                />
                <div className='desktop-view form-profile-info-items'></div>
                <Input
                    label='New Password:'
                    type='password'
                    maxLength={lengths.password}
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    errorMessage={newPasswordError}
                />
                <div className='desktop-view form-profile-info-items'></div>
            </section>
        );

        buttonSection = (
            <UpdateItemButtons
                saveFn={() => changePasswordFn(currentPassword, newPassword)}
                cancelFn={backCancelBtnFn}
            />
        );
    }

    let changeNameLink = (
        <span className='text-link' onClick={gotoChangeNameViewFn}>
            Change Name
        </span>
    );

    let changePasswordLink = (
        <span className='text-link' onClick={gotoChangePasswordViewFn}>
            Change Password
        </span>
    );

    if (!authUser.subscriptionStatusIsActive) {
        changeNameLink = null;
        changePasswordLink = null;
    }

    if (purpose === view.PROFILE_VIEW) {
        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Email</p>
                    <p className='fpii-text'>{authUser.email}</p>
                </div>
                <div className='desktop-view form-profile-info-items'></div>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Name</p>
                    <p className='fpii-text'>{authUser.displayName || '--'}</p>
                </div>
                <div className='form-profile-info-items'>{changeNameLink}</div>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Password</p>
                    <p className='fpii-text'>*******</p>
                </div>
                <div className='form-profile-info-items'>
                    {changePasswordLink}
                </div>
            </section>
        );
    }

    return (
        <div className='common-form'>
            <section className='form-title-section'>User Profile</section>

            {mainContent}

            {buttonSection}

            <div className='watermark'>
                <span></span>
            </div>
        </div>
    );
};
