import { db, firebase } from '../firebase_config/firebaseConfig';
import { userActions, systemActions } from '../lib/constants';
import { responseFn, formatCustomErrorObj } from '../lib/util';
import dbopsGetDocument from './dbopsGetDocument';
import validate from '../validators';
import mListHelper from './mListHelper';
import { docNames, appMessage } from '../lib/messages';
import sdObjToMListItem from '../lib/masterListConverters/sdObjToMListItem';

import {
    SDSIS_COL,
    MASTER_LISTS_COL,
    MASTER_SDSI_LIST_DOC,
    DELETED_SDSIS_COL,
    SDDRS_COL,
    MASTER_SDDR_LIST_DOC,
} from '../lib/constants';

// ================================================
// function to fetch each supplier document.
const getSiFn = async (docID) => dbopsGetDocument(SDSIS_COL, docID, 'SDSI');

// ======================================================
const createSdsiFn = async (userCreds, sdsiObj) => {
    // add metadata to sdsiObj to be created in firestore.
    sdsiObj.meta = {
        action: userActions.CREATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // add metaHistory
    sdsiObj.metaHistory = [];

    // get references
    let sdsiRef = db.collection(SDSIS_COL).doc(sdsiObj.sdsiUID);
    let sdsiListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_SDSI_LIST_DOC);

    const transactionFn = async (transaction) => {
        // get docs
        let sdsiDoc = await transaction.get(sdsiRef);
        let sdsiListDoc = await transaction.get(sdsiListRef);

        // exist checking.
        if (sdsiDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.alreadyExist(docNames.SDSI)
                )
            );

        await transaction.set(sdsiRef, sdsiObj);

        // create or update suppliers list document in (master lists collection)
        if (sdsiListDoc.exists) {
            let sdsiInfoObj = sdObjToMListItem(sdsiObj);

            let newData = mListHelper.updateMasterList(
                sdsiListDoc,
                sdsiObj.sdsiUID,
                sdsiInfoObj
            );

            await transaction.set(sdsiListRef, newData);
        } else {
            let sdsiInfoObj = sdObjToMListItem(sdsiObj);

            let newData = mListHelper.addMasterList(
                sdsiObj.sdsiUID,
                sdsiInfoObj
            );

            await transaction.set(sdsiListRef, newData);
        }

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyCreated(docNames.SDSI)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorCreating(docNames.SDSI)
        );
    }
};

// ===============================================
const updateSdsiFn = async (userCreds, sdsiObj, documentBasis) => {
    if (sdsiObj.metaHistory.length > 99) sdsiObj.metaHistory.shift();

    sdsiObj.metaHistory.push(sdsiObj.meta);
    // add metadata to sdsiObj to be created in firestore.
    sdsiObj.meta = {
        action: userActions.UPDATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // get references
    let sdsiRef = db.collection(SDSIS_COL).doc(sdsiObj.sdsiUID);
    let sdsiListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_SDSI_LIST_DOC);

    const transactionFn = async (transaction) => {
        // get docs
        let sdsiDoc = await transaction.get(sdsiRef);
        let sdsiListDoc = await transaction.get(sdsiListRef);

        // exist checking.
        if (!sdsiDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.SDSI)
                )
            );

        if (!validate.noChange(sdsiDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.SDPO)
                )
            );
        }

        let sdsiInfoObj = sdObjToMListItem(sdsiObj);

        let newData = mListHelper.updateMasterList(
            sdsiListDoc,
            sdsiObj.sdsiUID,
            sdsiInfoObj
        );

        await transaction.set(sdsiListRef, newData);

        await transaction.set(sdsiRef, sdsiObj);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyUpdated(docNames.SDSI)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorUpdating(docNames.SDSI)
        );
    }
};

// ================================================================
const deleteSdsiFn = async (userCreds, sdsiObj, documentBasis) => {
    if (sdsiObj.metaHistory.length > 99) sdsiObj.metaHistory.shift();

    sdsiObj.metaHistory.push(sdsiObj.meta);

    sdsiObj.meta = {
        action: userActions.DELETED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let sdsiRef = db.collection(SDSIS_COL).doc(sdsiObj.sdsiUID);
    let sdsiListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_SDSI_LIST_DOC);

    let deletedSdsiRef = db.collection(DELETED_SDSIS_COL).doc();

    const transactionFn = async (transaction) => {
        let sdsiDoc = await transaction.get(sdsiRef);

        if (!sdsiDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotDeleteBecauseDocNotExist(docNames.SDSI)
                )
            );

        if (!validate.noChange(sdsiDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotDeleteBecauseDocChanged(docNames.SDSI)
                )
            );
        }

        let sdsiListDoc = await transaction.get(sdsiListRef);
        await transaction.get(deletedSdsiRef);

        let newData = mListHelper.deletePropertyFromMasterList(
            sdsiListDoc,
            sdsiObj.sdsiUID
        );

        await transaction.delete(sdsiRef);
        await transaction.set(sdsiListRef, newData);
        await transaction.set(deletedSdsiRef, sdsiObj);
        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyDeleted(docNames.SDSI)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorDeleting(docNames.SDSI)
        );
    }
};

// ================================================================
const statusChangeFn = async (userCreds, sdsiObj, documentBasis) => {
    if (sdsiObj.metaHistory.length > 99) sdsiObj.metaHistory.shift();

    sdsiObj.metaHistory.push(sdsiObj.meta);

    // update metadata in sdsiObj.
    sdsiObj.meta = {
        action: userCreds.action,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let sdsiRef = db.collection(SDSIS_COL).doc(sdsiObj.sdsiUID);
    let sdsiListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_SDSI_LIST_DOC);

    const transactionFn = async (transaction) => {
        //1. perform all transaction gets
        let sdsiDoc = await transaction.get(sdsiRef);
        let sdsiListDoc = await transaction.get(sdsiListRef);

        //2. check sdsiDoc against documentBasis if there is any change.
        if (!sdsiDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.SDSI)
                )
            );

        // ! this does not check meta data.
        if (!validate.noChange(sdsiDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.SDSI)
                )
            );
        }
        // ! meta data check if status of document changed.
        if (sdsiDoc.data().meta.action !== documentBasis.meta.action) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.SDSI)
                )
            );
        }

        let sdsiInfoObj = sdObjToMListItem(sdsiObj);

        let newData = mListHelper.updateMasterList(
            sdsiListDoc,
            sdsiObj.sdsiUID,
            sdsiInfoObj
        );

        // write to masterlist and pdpo document in firebase.
        await transaction.set(sdsiListRef, newData);
        await transaction.set(sdsiRef, sdsiObj);
        return Promise.resolve('TransactionFn Completed Successfully');
    };
    try {
        await db.runTransaction(transactionFn);
        return responseFn(null, false, null, appMessage.successfulUpdateStatus);
    } catch (e) {
        return responseFn(null, true, e, appMessage.errorUpdatingStatus);
    }
};

const approveSdsiFn = async (userCreds, siObj, documentBasis) => {
    if (siObj.metaHistory.length > 99) siObj.metaHistory.shift();

    siObj.metaHistory.push(siObj.meta);

    // update metadata in siObj.
    siObj.meta = {
        action: userCreds.action,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // get references for documents needed. =========================
    let sdsiRef = db.collection(SDSIS_COL).doc(siObj.sdsiUID);
    let sdsiListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_SDSI_LIST_DOC);
    let sddrListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_SDDR_LIST_DOC);

    let documentBasisDrArrRefs = documentBasis.sddrArr.map((dr) =>
        db.collection(SDDRS_COL).doc(dr.sddrUID)
    );

    const transactionFn = async (transaction) => {
        let existError = false;
        let editedError = false;

        // =============================================================
        // START TRANSACTION GET =================================
        let sdsiDoc = await transaction.get(sdsiRef);
        let sdsiListDoc = await transaction.get(sdsiListRef);
        let sddrListDoc = await transaction.get(sddrListRef);

        let documentBasisDrArrDocs = documentBasisDrArrRefs.map(
            async (drRef) => {
                return await transaction.get(drRef);
            }
        );

        try {
            documentBasisDrArrDocs = await Promise.all(documentBasisDrArrDocs);
        } catch (e) {
            return Promise.reject(
                formatCustomErrorObj(
                    900,
                    appMessage.prommiseAllError(
                        'dbopsSDSIs',
                        'documentBasisDrArr'
                    )
                )
            );
        }

        // END TRANSACTION GET =============================================

        // START CHECK FOR CHANGE AND EXISTENCE =================================
        if (!sdsiDoc.exists) {
            return Promise.reject(
                //! this should never run.
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.SDSI)
                )
            );
        }

        // ! this does not check meta data.
        if (!validate.noChange(sdsiDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.SDSI)
                )
            );
        }
        // ! meta data check if status of document changed.
        if (sdsiDoc.data().meta.action !== documentBasis.meta.action) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.SDSI)
                )
            );
        }

        // check if there are any changes to the DRs involved. only proceed with transaction
        // if sddr status is still APPROVED.
        documentBasisDrArrDocs.forEach((docBasisDr) => {
            if (!docBasisDr.exists) existError = true;

            // ! meta data check if status of document changed. MUST be approved, if not,
            //! it means that dr was marked APPROVED - LOCKED by another SDSI.
            if (
                docBasisDr.data().meta.action !== userActions.MARK_AS_DELIVERED
            ) {
                editedError = true;
            }
        });

        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopsSDSIs',
                        docNames.SDSI,
                        'SDDR'
                    )
                )
            );
        }
        if (editedError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,

                    'One or more DRs in this Invoice was already locked'
                )
            );
        }

        // END CHECK FOR CHANGE AND EXISTENCE ==================================

        // START TRANSACTION SET ===============================================
        let tempMasterSddrList = sddrListDoc.data();

        documentBasisDrArrDocs.forEach(async (dr, drIndex) => {
            let drCopy = {
                ...dr.data(),
            };

            // change status of dr object.
            if (drCopy.metaHistory.length > 99) drCopy.metaHistory.shift();

            drCopy.metaHistory.push(drCopy.meta);

            // update metadata in drCopy.
            drCopy.meta = {
                action: systemActions.SDSI_LOCKED,
                uid: systemActions.UID,
                email: systemActions.EMAIL,
                timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
            };

            // update temporary pdpo master list
            let sddrInfoObj = sdObjToMListItem(drCopy);

            // this variable will be used outside the loop to update
            // the masterList ONCE.
            tempMasterSddrList = mListHelper.updateTempMList(
                tempMasterSddrList,
                drCopy.sddrUID,
                sddrInfoObj
            );

            await transaction.set(documentBasisDrArrRefs[drIndex], drCopy, {
                merge: true,
            });
        });

        // After updating all the PDDR's, update the master list of PDDRs
        await transaction.set(sddrListRef, tempMasterSddrList);

        await transaction.set(sdsiRef, siObj);

        let sdsiInfoObj = sdObjToMListItem(siObj);
        let newData = mListHelper.updateMasterList(
            sdsiListDoc,
            siObj.sdsiUID,
            sdsiInfoObj
        );
        await transaction.set(sdsiListRef, newData);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(null, false, null, appMessage.successfulUpdateStatus);
    } catch (e) {
        return responseFn(null, true, e, appMessage.errorUpdatingStatus);
    }
};

export default {
    getSiFn,
    createSdsiFn,
    updateSdsiFn,
    deleteSdsiFn,
    statusChangeFn,
    approveSdsiFn,
};
