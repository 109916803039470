import React from 'react';
import { db } from '../../firebase_config/firebaseConfig';
import { GLOBAL_VARIABLES_DOC, SG_NUMBERS_COL } from '../../lib/constants';

import '../PagesCommon.css';
import './AppSettingPage.css';
import AppSettingForm from './AppSettingForm';

export default (props) => {
    let { setIsLoading, setAlertErrorMsg, setAlertMessage } = props;

    const saveAppSettingFn = async (appSettingObj) => {
        setIsLoading(true);

        try {
            await db
                .collection(SG_NUMBERS_COL)
                .doc(GLOBAL_VARIABLES_DOC)
                .set(appSettingObj);

            setAlertMessage('Successfully updated application setting');
        } catch (e) {
            console.error(e);
            setAlertErrorMsg('Error updating application settings');
        }

        setIsLoading(false);
    };

    return (
        <div className='content-layout'>
            <section className='main-section'>
                {' '}
                <AppSettingForm saveAppSettingFn={saveAppSettingFn} />
            </section>
        </div>
    );
};
