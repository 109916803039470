export default (InvTransferMasterlistStringFormat) => {
    let newArr = [];
    let InvTransferObjsInObjFormat = JSON.parse(
        InvTransferMasterlistStringFormat
    );

    for (let key in InvTransferObjsInObjFormat) {
        newArr.unshift({
            invTransferUID: key,
            subjectUID: InvTransferObjsInObjFormat[key].a,
            invTransferDate: InvTransferObjsInObjFormat[key].b,
            status: InvTransferObjsInObjFormat[key].c,
            transferDirection: InvTransferObjsInObjFormat[key].d,
        });
    }

    return newArr;
};
