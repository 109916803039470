export default (rmObj) => {
    return {
        a: rmObj.rmCode,
        b: rmObj.rmCategory,
        c: rmObj.rmUnit,
        d: rmObj.rmLowThreshold,
        e: rmObj.rmProjectedQty,
        f: rmObj.rmActualQty,
        g: rmObj.rmAltUnit,
        h: rmObj.rmAltUnitMultiplier,
    };
};
