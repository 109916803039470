/**
 * Sept 1, 2020: Function approvePdvrFn created. this will change status of PDSI to
 * approve and change status of all related DR's (PO's no longer an option)
 * to 'APPROVED - LOCKED'.
 *
 *
 *
 */

import { db, firebase } from '../firebase_config/firebaseConfig';
import { userActions, systemActions } from '../lib/constants';
import { responseFn, formatCustomErrorObj } from '../lib/util';
import dbopsGetDocument from './dbopsGetDocument';
import validate from '../validators';
import mListHelper from './mListHelper';
import { docNames, appMessage } from '../lib/messages';
import pdObjToMListItem from '../lib/masterListConverters/pdObjToMListItem';

import {
    PDVRS_COL,
    MASTER_LISTS_COL,
    MASTER_PDVR_LIST_DOC,
    DELETED_PDVRS_COL,
    MASTER_PDSI_LIST_DOC,
    PDSIS_COL,
} from '../lib/constants';

// ================================================
// function to fetch each supplier document.
const getVrFn = async (docID) => dbopsGetDocument(PDVRS_COL, docID, 'PDSI');

// ======================================================
const createVrFn = async (userCreds, vrObj) => {
    // add metadata to vrObj to be created in firestore.
    vrObj.meta = {
        action: userActions.CREATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // add metaHistory
    vrObj.metaHistory = [];

    // get references
    let pdvrRef = db.collection(PDVRS_COL).doc(vrObj.pdvrUID);
    let pdvrListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_PDVR_LIST_DOC);

    const transactionFn = async (transaction) => {
        // get docs
        let pdvrDoc = await transaction.get(pdvrRef);
        let pdvrListDoc = await transaction.get(pdvrListRef);

        // exist checking.
        if (pdvrDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1002,
                    appMessage.alreadyExist(docNames.PDVR)
                )
            );

        await transaction.set(pdvrRef, vrObj);

        // create or update suppliers list document in (master lists collection)
        if (pdvrListDoc.exists) {
            let pdvrInfoObj = pdObjToMListItem(vrObj);

            let newData = mListHelper.updateMasterList(
                pdvrListDoc,
                vrObj.pdvrUID,
                pdvrInfoObj
            );

            await transaction.set(pdvrListRef, newData);
        } else {
            let pdvrInfoObj = pdObjToMListItem(vrObj);

            let newData = mListHelper.addMasterList(vrObj.pdvrUID, pdvrInfoObj);

            await transaction.set(pdvrListRef, newData);
        }

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyCreated(docNames.PDVR)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorCreating(docNames.PDVR)
        );
    }
};

// ===============================================
const updateVrFn = async (userCreds, vrObj, documentBasis) => {
    if (vrObj.metaHistory.length > 99) vrObj.metaHistory.shift();

    vrObj.metaHistory.push(vrObj.meta);
    // add metadata to vrObj to be created in firestore.
    vrObj.meta = {
        action: userActions.UPDATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // get references
    let pdvrRef = db.collection(PDVRS_COL).doc(vrObj.pdvrUID);
    let pdvrListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_PDVR_LIST_DOC);

    const transactionFn = async (transaction) => {
        // get docs
        let pdvrDoc = await transaction.get(pdvrRef);
        let pdvrListDoc = await transaction.get(pdvrListRef);

        // exist checking.
        if (!pdvrDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.PDVR)
                )
            );

        if (!validate.noChange(pdvrDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.PDVR)
                )
            );
        }

        let pdvrInfoObj = pdObjToMListItem(vrObj);

        let newData = mListHelper.updateMasterList(
            pdvrListDoc,
            vrObj.pdvrUID,
            pdvrInfoObj
        );

        await transaction.set(pdvrListRef, newData);

        await transaction.set(pdvrRef, vrObj);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyUpdated(docNames.PDVR)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorUpdating(docNames.PDVR)
        );
    }
};

// ================================================================
const deleteVrFn = async (userCreds, vrObj, documentBasis) => {
    if (vrObj.metaHistory.length > 99) vrObj.metaHistory.shift();

    vrObj.metaHistory.push(vrObj.meta);

    vrObj.meta = {
        action: userActions.DELETED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let pdvrRef = db.collection(PDVRS_COL).doc(vrObj.pdvrUID);
    let pdvrListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_PDVR_LIST_DOC);

    let deletedPdvrRef = db.collection(DELETED_PDVRS_COL).doc();

    const transactionFn = async (transaction) => {
        let pdvrDoc = await transaction.get(pdvrRef);

        if (!pdvrDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotDeleteBecauseDocNotExist(docNames.PDVR)
                )
            );

        if (!validate.noChange(pdvrDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotDeleteBecauseDocChanged(docNames.PDVR)
                )
            );
        }

        let pdvrListDoc = await transaction.get(pdvrListRef);
        await transaction.get(deletedPdvrRef);

        let newData = mListHelper.deletePropertyFromMasterList(
            pdvrListDoc,
            vrObj.pdvrUID
        );

        await transaction.delete(pdvrRef);
        await transaction.set(pdvrListRef, newData);
        await transaction.set(deletedPdvrRef, vrObj);
        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyDeleted(docNames.PDVR)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorDeleting(docNames.PDVR)
        );
    }
};

// ================================================================
const statusChangeFn = async (userCreds, vrObj, documentBasis) => {
    if (vrObj.metaHistory.length > 99) vrObj.metaHistory.shift();

    vrObj.metaHistory.push(vrObj.meta);

    // update metadata in vrObj.
    vrObj.meta = {
        action: userCreds.action,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let pdvrRef = db.collection(PDVRS_COL).doc(vrObj.pdvrUID);
    let pdvrListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_PDVR_LIST_DOC);

    const transactionFn = async (transaction) => {
        //1. perform all transaction gets
        let pdvrDoc = await transaction.get(pdvrRef);
        let pdvrListDoc = await transaction.get(pdvrListRef);

        //2. check pdvrDoc against documentBasis if there is any change.
        if (!pdvrDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.PDVR)
                )
            );

        // ! this does not check meta data.
        if (!validate.noChange(pdvrDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.PDVR)
                )
            );
        }
        // ! meta data check if status of document changed.
        if (pdvrDoc.data().meta.action !== documentBasis.meta.action) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.PDVR)
                )
            );
        }

        let pdvrInfoObj = pdObjToMListItem(vrObj);

        let newData = mListHelper.updateMasterList(
            pdvrListDoc,
            vrObj.pdvrUID,
            pdvrInfoObj
        );

        // write to masterlist and pdpo document in firebase.
        await transaction.set(pdvrListRef, newData);
        await transaction.set(pdvrRef, vrObj);
        return Promise.resolve('TransactionFn Completed Successfully');
    };
    try {
        await db.runTransaction(transactionFn);
        return responseFn(null, false, null, appMessage.successfulUpdateStatus);
    } catch (e) {
        return responseFn(null, true, e, appMessage.errorUpdatingStatus);
    }
};

const approvePdvrFn = async (userCreds, vrObj, documentBasis) => {
    if (vrObj.metaHistory.length > 99) vrObj.metaHistory.shift();

    vrObj.metaHistory.push(vrObj.meta);

    // update metadata in vrObj.
    vrObj.meta = {
        action: userCreds.action,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // get references for documents needed. =========================
    let pdvrRef = db.collection(PDVRS_COL).doc(vrObj.pdvrUID);
    let pdvrListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_PDVR_LIST_DOC);
    let pdsiListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_PDSI_LIST_DOC);

    let documentBasisSiArrRefs = documentBasis.pdsiArr.map((si) =>
        db.collection(PDSIS_COL).doc(si.pdsiUID)
    );

    const transactionFn = async (transaction) => {
        let existError = false;
        let editedError = false;

        // =============================================================
        // START TRANSACTION GET =================================
        let pdvrDoc = await transaction.get(pdvrRef);
        let pdvrListDoc = await transaction.get(pdvrListRef);
        let pdsiListDoc = await transaction.get(pdsiListRef);

        let documentBasisSiArrDocs = documentBasisSiArrRefs.map(
            async (siRef) => {
                return await transaction.get(siRef);
            }
        );

        try {
            documentBasisSiArrDocs = await Promise.all(documentBasisSiArrDocs);
        } catch (e) {
            return Promise.reject(
                formatCustomErrorObj(
                    900,
                    appMessage.prommiseAllError(
                        'dbopsPDVRs',
                        'documentBasisSiArr'
                    )
                )
            );
        }

        // END TRANSACTION GET =============================================

        // START CHECK FOR CHANGE AND EXISTENCE =================================
        if (!pdvrDoc.exists) {
            return Promise.reject(
                //! this should never run.
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.PDVR)
                )
            );
        }

        // ! this does not check meta data.
        if (!validate.noChange(pdvrDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.PDVR)
                )
            );
        }
        // ! meta data check if status of document changed.
        if (pdvrDoc.data().meta.action !== documentBasis.meta.action) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.PDVR)
                )
            );
        }

        // check if there are any changes to the SIs involved. only proceed with transaction
        // if pdsi status is still APPROVED.
        documentBasisSiArrDocs.forEach((docBasisDr) => {
            if (!docBasisDr.exists) existError = true;

            // ! meta data check if status of document changed. MUST be approved, if not,
            //! it means that si was marked APPROVED - LOCKED by another PDSI.

            if (vrObj.meta.action === userActions.APPROVED) {
                if (docBasisDr.data().meta.action !== userActions.APPROVED) {
                    editedError = true;
                }
            }
            //! this should not run.
            if (vrObj.meta.action === userActions.MARK_AS_PAID) {
                if (
                    docBasisDr.data().meta.action !== systemActions.IN_VOUCHER
                ) {
                    editedError = true;
                }
            }
        });

        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopsPDSIs',
                        docNames.PDVR,
                        'PDSI'
                    )
                )
            );
        }
        if (editedError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,

                    'One or more SIs in this Voucher Receipt was already locked'
                )
            );
        }

        // END CHECK FOR CHANGE AND EXISTENCE ==================================

        // START TRANSACTION SET ===============================================
        let tempMasterPdsiList = pdsiListDoc.data();

        documentBasisSiArrDocs.forEach(async (si, siIndex) => {
            let siCopy = {
                ...si.data(),
            };

            // change status of si object.
            if (siCopy.metaHistory.length > 99) siCopy.metaHistory.shift();

            siCopy.metaHistory.push(siCopy.meta);

            // update metadata in siCopy.

            let newStatus = systemActions.PAID;
            if (vrObj.meta.action === userActions.APPROVED)
                newStatus = systemActions.IN_VOUCHER;

            siCopy.meta = {
                action: newStatus,
                uid: systemActions.UID,
                email: systemActions.EMAIL,
                timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
            };

            // update temporary pdpo master list
            let pdsiInfoObj = pdObjToMListItem(siCopy);

            // this variable will be used outside the loop to update
            // the masterList ONCE.
            tempMasterPdsiList = mListHelper.updateTempMList(
                tempMasterPdsiList,
                siCopy.pdsiUID,
                pdsiInfoObj
            );

            await transaction.set(documentBasisSiArrRefs[siIndex], siCopy, {
                merge: true,
            });
        });

        // if (pdvrDoc.exists) {
        //     return Promise.reject(
        //         //! this should never run.
        //         formatCustomErrorObj(
        //             1000,
        //             appMessage.cannotEditBecauseDocNotExist(docNames.PDVR)
        //         )
        //     );
        // }

        // After updating all the PDSI's, update the master list of PDSIs
        await transaction.set(pdsiListRef, tempMasterPdsiList);

        await transaction.set(pdvrRef, vrObj);

        let pdvrInfoObj = pdObjToMListItem(vrObj);
        let newData = mListHelper.updateMasterList(
            pdvrListDoc,
            vrObj.pdvrUID,
            pdvrInfoObj
        );
        await transaction.set(pdvrListRef, newData);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(null, false, null, appMessage.successfulUpdateStatus);
    } catch (e) {
        return responseFn(null, true, e, appMessage.errorUpdatingStatus);
    }
};

export default {
    getVrFn,
    createVrFn,
    updateVrFn,
    deleteVrFn,
    statusChangeFn,
    approvePdvrFn,
};
