import React from 'react';
import './ReportItem.css';
import '../CollectionItems.css';
import { displayMoney } from '../../../lib/util';

const getDisplayName = (uid) => {
    let nameArr = uid.split('-');

    let category = '';
    let detail = '';
    if (uid.includes('Sales')) category = 'Sales';
    if (uid.includes('Purchase')) category = 'Purchase';
    if (uid.includes('Item')) detail = 'Item';
    if (uid.includes('Customer')) detail = 'Customer';
    if (uid.includes('Supplier')) detail = 'Supplier';

    // if
    if (uid.includes('Accounts Receivables')) return 'Accounts Receivables';
    if (uid.includes('Accounts Payables')) return 'Accounts Payables';

    if (uid.includes('Semi Finished'))
        return 'Inventory Report - Semi Finished Goods';
    //! this needs to be after semi finished goods
    if (uid.includes('Finished')) return 'Inventory Report - Finished Goods';
    if (uid.includes('Raw Material')) return 'Inventory Report - Raw Materials';

    // else
    return `${category} Report by ${detail}`;
};

export default (props) => {
    let { obj, serialNumber, onClick, heading } = props;

    let serial = 'S/No.';
    let itemName = 'Report Name';
    let month = 'Month';
    let year = 'Year';
    let total = 'Total';
    let headingClass = 'text-bold list-table-heading';

    let serialLabel = serial + ':';
    let itemNameLabel = itemName + ':';
    let monthLabel = month + ':';
    let yearLabel = year + ':';
    let totalLabel = total + ':';

    if (!heading) {
        //! uid name needs to be truncated for display
        let displayName = getDisplayName(obj.reportUID);

        serial = serialNumber;
        itemName = displayName;
        month = obj.reportMonth;
        year = obj.reportYear;
        total = displayMoney(obj.total);
        headingClass = '';
    }

    return (
        <div
            className={`setup-sup-cus-item collection-item ${headingClass}`}
            onClick={onClick}>
            <div className='collection-item-entry serial'>
                {' '}
                <span className='entry-label'>{serialLabel}</span>
                {serial}
            </div>
            <div className='collection-item-entry name'>
                {' '}
                <span className='entry-label'>{itemNameLabel}</span>
                {itemName}
            </div>
            <div className='collection-item-entry month'>
                {' '}
                <span className='entry-label'>{monthLabel}</span>
                {month}
            </div>
            <div className='collection-item-entry year'>
                {' '}
                <span className='entry-label'>{yearLabel}</span>
                {year}
            </div>
            <div className='collection-item-entry total'>
                {' '}
                <span className='entry-label'>{totalLabel}</span>
                {total}
            </div>
        </div>
    );
};
