import { responseFn, clone } from '../lib/util';
import { lengths, regEx } from '../lib/constants';
import { generalMessage, appMessage } from '../lib/messages';
import {
    saveWithTwoDecimalOnly,
    saveWithFiveDecimals,
    displayDate,
} from '../lib/util';

export const pdpo = (pdpoObj, purpose) => {
    // make a copy of the rmArr

    let pdpoObjCopy = {
        ...pdpoObj,
        rmArr: clone(pdpoObj.rmArr),
    };

    delete pdpoObjCopy.pdpoDateError;
    delete pdpoObjCopy.noteError;

    let rmArrCopy = pdpoObjCopy.rmArr;

    // clear all error messages embeded in the objects.
    rmArrCopy.forEach((rm) => {
        delete rm.chosenSupplierError;
        delete rm.rmOrderedQtyError;
        delete rm.rmOverrideQtyError;
        rm.canvasSupplierArr.forEach((supplier) => {
            delete supplier.costError;
            delete supplier.costDateError;
        });
    });

    if (pdpoObjCopy.pdpoDate === '') {
        pdpoObjCopy.pdpoDateError = generalMessage.REQUIRED;
    }

    if (!regEx.date.test(pdpoObjCopy.pdpoDate)) {
        pdpoObjCopy.pdpoDateError = generalMessage.INVALID_DATE;
    }

    if (pdpoObjCopy.note.length > lengths.noteLength) {
        pdpoObjCopy.noteError = generalMessage.INVALID_LENGTH;
    }

    // validate rmArr length. if this goes over 30.  UI was hacked.
    let hackError =
        rmArrCopy.length > lengths.pdpoRmArrLength
            ? appMessage.sourceCodeErrorMessage('PDPO: rmArr length error')
            : '';

    let supplierArrError = false;

    let supplierNameChosenArr = [];
    rmArrCopy.forEach((rm, index) => {
        // validate supplier chosen
        if (
            rm.chosenSupplier === undefined ||
            rm.chosenSupplier === '' ||
            rm.canvasSupplierArr.length === 0
        ) {
            supplierArrError = true;
            rm.chosenSupplierError = 'Must choose a supplier';
        }
        if (supplierNameChosenArr.length === 0 && rm.chosenSupplier)
            supplierNameChosenArr.push(rm.chosenSupplier);

        if (supplierNameChosenArr.length !== 0) {
            if (supplierNameChosenArr[0] !== rm.chosenSupplier) {
                supplierArrError = true;
                rm.chosenSupplierError =
                    'Suppliers chosen must all be the same';
            }
        }
        // validate rmOrderedQty
        if (Number(rm.rmOrderedQty) <= 0) {
            supplierArrError = true;
            rm.rmOrderedQtyError = generalMessage.GREATER_ZERO;
        }
        if (Number(rm.rmOrderedQty) > lengths.numberInputAmount) {
            supplierArrError = true;
            rm.rmOrderedQtyError = generalMessage.INVALID_AMOUNT;
        }
        if (isNaN(Number(rm.rmOrderedQty))) {
            supplierArrError = true;
            rm.rmOrderedQtyError = generalMessage.INVALID;
        }

        if (Number(rm.rmOverrideQty) + rm.rmBalanceQty < 0) {
            supplierArrError = true;
            rm.rmOverrideQtyError = 'Cannot subtract more than the balance qty';
        }
        if (Number(rm.rmOverrideQty) > lengths.numberInputAmount) {
            supplierArrError = true;
            rm.rmOverrideQtyError = generalMessage.INVALID_AMOUNT;
        }

        if (isNaN(Number(rm.rmOverrideQty))) {
            supplierArrError = true;
            rm.rmOverrideQtyError = generalMessage.INVALID;
        }
    });

    rmArrCopy.forEach((rm) => {
        rm.canvasSupplierArr.forEach((sup) => {
            // validate cost

            if (Number(sup.cost) <= 0) {
                supplierArrError = true;
                sup.costError = generalMessage.GREATER_ZERO;
            }

            if (Number(sup.cost) > lengths.numberInputAmount) {
                supplierArrError = true;
                sup.costError = generalMessage.INVALID_AMOUNT;
            }

            if (isNaN(sup.cost)) {
                supplierArrError = true;
                sup.costError = generalMessage.INVALID;
            }

            if (sup.costDate === '') {
                supplierArrError = true;
                sup.costDateError = generalMessage.REQUIRED;
            }

            if (!regEx.date.test(sup.costDate)) {
                supplierArrError = true;
                sup.costDateError = generalMessage.INVALID_DATE;
            }

            if (sup.tempPreviousCostDate !== '') {
                if (sup.costDate < sup.tempPreviousCostDate) {
                    supplierArrError = true;
                    sup.costDateError = `Canvas date cannot be before ${displayDate(
                        sup.tempPreviousCostDate
                    )}`;
                }
            }
        });
    });

    let generalError =
        rmArrCopy.length === 0 ? 'Must have at least 1 Raw Material' : '';

    if (
        supplierArrError ||
        hackError ||
        generalError ||
        pdpoObjCopy.pdpoDateError ||
        pdpoObjCopy.noteError
    ) {
        pdpoObjCopy.rmArr = rmArrCopy;
        let errorObj = {
            pdpoObj: pdpoObjCopy,
            hackError,
            generalError,
        };
        return responseFn(null, true, errorObj, 'Error detected');
    }

    // if no error. form proper schema for pdpoObj and rm Objects in the rmArr.

    // array for all the cost history objects
    let costHistArr = [];

    // will get the correct schema for the rmArr for the PDPO object.
    let rmArrCorrectSchema = rmArrCopy.map((rm) => {
        // use this variable to determine cost of the rm from chosen supplier.
        let chosenSupplierCost = 0;

        // fix canvasSupplierArr costHistoryArr and determine cost of rm
        // from supplier that was chosen. **
        rm.canvasSupplierArr.forEach((sup) => {
            sup.cost = saveWithTwoDecimalOnly(sup.cost);
            if (
                sup.costDate !== sup.costHistoryArr[0].costDate ||
                // just to make sure data type is the same.
                sup.cost !== saveWithTwoDecimalOnly(sup.costHistoryArr[0].cost)
            ) {
                let newCostHistoryObj = {
                    cost: sup.cost,
                    costDate: sup.costDate,
                };
                sup.costHistoryArr.unshift(newCostHistoryObj);
                if (sup.costHistoryArr.length > lengths.costHistoryArrLength)
                    sup.costHistoryArr.pop();
            }
            // ..from supplier that was chosen. **
            if (rm.chosenSupplier === sup.supplierUID)
                chosenSupplierCost = sup.cost;

            // delete the tempPreviousCostDate
            delete sup.tempPreviousCostDate;

            //! 1. result for correct costHistory object for costHistory collection in Firebase.
            // get all costHistory object and form proper schema
            let chObj = {
                // costHistory objs from db was added inside the supplier Obj
                // when the supplier was fetched, the costHistory obj was fetched too
                // with the corresponding rmUID - supplierUID (if it existed)
                chUID: sup.chUID,
                cost: saveWithTwoDecimalOnly(sup.cost),
                costDate: sup.costDate,
                costHistoryArr: sup.costHistoryArr,
            };
            //! push all the chObjs here to collect them.
            costHistArr.push(chObj);
        });

        // ensure that there is only 5 suppliers canvassed. if this is greater, UI was hacked.
        if (rm.canvasSupplierArr.length > lengths.supplierCanvasLength) {
            rm.canvasSupplierArr.splice(lengths.supplierCanvasLength);
        }

        //! 2. result for correct rmArr in pdpo object.
        let rmBalance = saveWithFiveDecimals(rm.rmOrderedQty);
        if (purpose === 'OVERRIDE')
            rmBalance = saveWithFiveDecimals(rm.rmBalanceQty);

        return {
            rmUID: rm.rmUID,
            rmOrderedQty: saveWithFiveDecimals(rm.rmOrderedQty),
            rmBalanceQty: rmBalance,
            rmCost: chosenSupplierCost,
            rmDeliveredQty: saveWithFiveDecimals(rm.rmDeliveredQty) || 0,
            rmOverrideQty: saveWithFiveDecimals(rm.rmOverrideQty) || 0,
            rmPaidQty: saveWithFiveDecimals(rm.rmPaidQty) || 0,
            chosenSupplier: rm.chosenSupplier,
            canvasSupplierArr: rm.canvasSupplierArr,
            rmUnit: rm.rmUnit,
        };
    });

    let pdpoObjCorrectSchema = {
        ...pdpoObjCopy,
        supplierUID: supplierNameChosenArr[0],
        rmArr: rmArrCorrectSchema,
    };

    let data = { pdpoObj: pdpoObjCorrectSchema, costHistArr };

    return responseFn(data, false, {}, 'No error');
};
