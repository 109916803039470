import React from 'react';
import { displayDate, displayMoney } from '../../../lib/util';
import './VRCRItem.css';
import '../CollectionItems.css';

export default (props) => {
    let { obj, serialNumber, onClick, heading, keyProperty } = props;

    let prefix = 'pdvr';
    let itemUID = 'Voucher Receipt';
    let supCusName = 'Supplier Name';
    let supCusUID = 'supplierUID';

    if (keyProperty === 'sdcrUID') {
        prefix = 'sdcr';
        itemUID = 'Counter Receipt';
        supCusName = 'Customer Name';
        supCusUID = 'customerUID';
    }

    let serial = 'S/No.';
    let date = 'Date';
    let status = 'Status';
    let totals = 'Totals';
    let headingClass = 'text-bold list-table-heading';

    let serialLabel = serial + ':';
    let itemUIDLabel = itemUID + ':';
    let supCusLabel = supCusName + ':';
    let statuslabel = 'Status:';
    let dateLabel = date + ':';
    let totalsLabel = 'Totals:';

    if (!heading) {
        serial = serialNumber;
        itemUID = obj[`${prefix}UID`];
        date = displayDate(obj[`${prefix}Date`]);
        status = obj.status;
        supCusName = obj[supCusUID];
        headingClass = '';
        totals = obj[`${prefix}TotalsNett`]
            ? displayMoney(obj[`${prefix}TotalsNett`])
            : displayMoney(0);
    }

    return (
        <div
            className={`pd-item collection-item ${headingClass}`}
            onClick={onClick}>
            <div className='collection-item-entry serial'>
                {' '}
                <span className='entry-label'>{serialLabel}</span>
                {serial}
            </div>
            <div className='collection-item-entry item-uid'>
                {' '}
                <span className='entry-label'>{itemUIDLabel}</span>
                {itemUID}
            </div>
            <div className='collection-item-entry sup-cus'>
                {' '}
                <span className='entry-label'>{supCusLabel}</span>
                {supCusName}
            </div>
            <div className='collection-item-entry date'>
                {' '}
                <span className='entry-label'>{dateLabel}</span>
                {date}
            </div>
            <div className='collection-item-entry status'>
                {' '}
                <span className='entry-label'>{statuslabel}</span>
                {status}
            </div>
            <div className='collection-item-entry totals'>
                {' '}
                <span className='entry-label'>{totalsLabel}</span>
                {totals}
            </div>
        </div>
    );
};
