import { generalMessage } from './messages';
import { lengths } from './constants';

export const displayTime = (timeDB) => {
    let ampm;
    let [hour, min] = timeDB.split(':');
    hour = parseInt(hour);

    ampm = hour > 11 ? 'PM' : 'AM';

    hour = hour > 12 ? (hour -= 12) : hour;
    hour = hour === 0 ? (hour = 12) : hour; // fix 0:00 or 12:00am (after setting ampm)

    hour = hour.toString();
    hour = hour.length === 1 ? (hour = hour.padStart(2, '0')) : hour;

    return `${hour}:${min} ${ampm}`;
};

export const displayDate = (dateDB) => {
    if (dateDB === '' || dateDB === undefined || dateDB === null) return '';
    let monthList = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    let [year, month, date] = dateDB.split('-'); // year, month, date

    return `${monthList[Number(month) - 1]} ${date}, ${year}`;
};

export const getCorrectJSDateObj = (dbDate) => {
    let [year, month, date] = dbDate.split('-');
    month = month - 1;
    return new Date(year, month, date);
};

export const getLastMonthDateISO = () => {
    let today = new Date();
    today.setHours(0, 0, 0, 0);

    today.setDate(today.getDate() - 30);

    let year = today.getFullYear();
    let month = (today.getMonth() + 1).toString().padStart(2, '0');
    let date = today.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${date}`;
};

export const appDateString = (dbDate) => {
    let months = [
        null,
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];
    let [year, month, date] = dbDate.split('-');
    return `${months[parseInt(month)]} ${date}, ${year}`;
};

export const jsDateObjToISODate = (dateObj) => {
    let manilaDate = dateObj.toLocaleString('en-US', {
        timeZone: 'Asia/Manila',
    });
    manilaDate = manilaDate.split(',')[0].split('/');
    let year = manilaDate[2];
    let day = manilaDate[1].padStart(2, '0');
    let month = manilaDate[0].padStart(2, '0');

    return `${year}-${month}-${day}`;
    // return dateObj.toISOString().split('T')[0];
};

export const appDateShort = (dateObj) => {
    let [day, month, date, year] = dateObj.toDateString().split(' ');
    return `${month} ${date}, ${year}`;
};

export const getDateObjLastSunday = () => {
    let today = new Date();
    return new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - today.getDay()
    );
};

export const responseFn = (data, error, errorObj, message) => {
    return { data, error, errorObj, message };
};

export const formatCustomErrorObj = (code, message) => ({ code, message });

export const formatAlertErrorObj = (
    main = '',
    detailArr1 = [],
    main2 = '',
    detailArr2 = []
) => {
    return {
        detailObj1: {
            mainMessage: main,
            detailsArr: detailArr1,
        },
        detailObj2: {
            mainMessage: main2,
            detailsArr: detailArr2,
        },
    };
};

export const buildPages = (arrLength, limit = 30) => {
    let pages = [];
    for (let i = 0; i < arrLength; i += limit) {
        let end = i + limit < arrLength ? i + limit : arrLength;
        pages.push([i, end]);
    }
    return pages;
    // [[0,20],[20,40],[40,55]] - return value should look like this.
};

export const clone = (obj) => JSON.parse(JSON.stringify(obj));

// export const displayMoney = (amount) => Number(amount).toFixed(2);

export const displayMoney = (num) => {
    let numFormat = Number(num);
    if (isNaN(numFormat)) return 0;

    let numString = numFormat.toString();

    let [whole, decimal] = numString.split('.');
    if (decimal === undefined) decimal = '00';

    let temp = [];
    let counter = 0;
    for (let i = whole.length - 1; i >= 0; i -= 1) {
        temp.push(whole[i]);
        counter += 1;
        if (counter === 3) {
            temp.push(',');
            counter = 0;
        }
    }

    if (temp[temp.length - 1] === ',') temp.pop();

    if (temp[temp.length - 1] === '-' && temp[temp.length - 2] === ',')
        temp.splice(temp.length - 2, 1);

    let newNum = '';
    for (let i = temp.length - 1; i >= 0; i -= 1) {
        newNum += temp[i];
    }

    if (decimal.length === 0) decimal = '00';
    if (decimal.length === 1) decimal += '0';

    return '₱ ' + newNum + '.' + decimal;
};

export const displayReadableNumber = (num) => {
    let numFormat = Number(num);
    if (isNaN(numFormat)) return 0;

    let numString = numFormat.toString();

    let [whole, decimal] = numString.split('.');
    if (decimal === undefined) decimal = '';

    let temp = [];
    let counter = 0;
    for (let i = whole.length - 1; i >= 0; i -= 1) {
        temp.push(whole[i]);
        counter += 1;
        if (counter === 3) {
            temp.push(',');
            counter = 0;
        }
    }
    if (temp[temp.length - 1] === ',') temp.pop();
    if (temp[temp.length - 1] === '-' && temp[temp.length - 2] === ',')
        temp.splice(temp.length - 2, 1);

    let newNum = '';
    for (let i = temp.length - 1; i >= 0; i -= 1) {
        newNum += temp[i];
    }

    if (decimal.length === 1) decimal += '0';

    return decimal.length === 0 ? newNum : newNum + '.' + decimal;
};

export const applyDiscount = (amount, discount) => {
    amount = Number(amount);
    discount = Number(discount);
    return (amount * (100 - discount)) / 100;
};
export const applyTax = (amount, tax) => {
    amount = Number(amount);
    tax = Number(tax);
    return (amount * tax) / 100 + amount;
};

export const saveWithTwoDecimalOnly = (number) => {
    let n = Number(number);
    let y = n.toFixed(2);

    return Number(y);
};

export const saveWithFiveDecimals = (number) => {
    let n = Number(number);
    let y = n.toFixed(5);

    return Number(y);
};

export const getWaterMarkDateTime = (dateObj, currentDocument) => {
    let requesterMessage = '';
    if (currentDocument && currentDocument.meta.action === 'PENDING APPROVAL') {
        requesterMessage = `Requester: ${currentDocument.meta.email}. `;
    }
    return `${requesterMessage}Last updated: ${appDateShort(dateObj)}`;
};

export const validateUID = (uid) => {
    let regex = /__.*__/;
    let error = false;
    let errorMessage = '';

    if (uid === '' || uid === ' ') {
        error = true;
        errorMessage = generalMessage.REQUIRED;
    }
    if (uid.length > lengths.uid) {
        error = true;
        errorMessage = generalMessage.INVALID_LENGTH;
    }
    if (uid.includes('/')) {
        error = true;
        errorMessage = generalMessage.INVALID_SLASH;
    }
    if (regex.test(uid) || uid === '.' || uid === '..') {
        error = true;
        errorMessage = generalMessage.INVALID_UID;
    }

    return { error, errorMessage };
};

export const generateBillingCycleFromWebhookObj = (invoiceObj) => {
    let start = new Date(invoiceObj.lines.data[0].period.start * 1000);
    let end = new Date(invoiceObj.lines.data[0].period.end * 1000);
    start = appDateShort(start);
    end = appDateShort(end);

    return `${start} - ${end}`;
};

export const serviceShouldStillBeActive = (
    endDate,
    serverDate,
    graceDays = 3
) => {
    const getEndDateObjPlusGraceDays = (jsDateObj, days) => {
        let newTime = jsDateObj.getTime() + 60 * 60 * 24 * 1000 * days;
        return new Date(newTime);
    };

    let endDateObjPlus3GraceDays = getEndDateObjPlusGraceDays(
        serverDate,
        graceDays
    );

    return serverDate <= endDate;
};

export const sortReportItems = (arr) => {
    //! Sort in place
    arr.sort((a, b) => {
        if (a.itemUID.toUpperCase() > b.itemUID.toUpperCase()) return 1;
        return -1;
    });
};

export const sortReportItemsByAmount = (arr) => {
    //! Sort in place
    arr.sort((a, b) => {
        if (Number(a.total) < Number(b.total)) return 1;
        return -1;
    });
};

export const titleCase = (str) => {
    return str
        .trim()
        .split(' ')
        .map((i) => i.toLowerCase())
        .filter((i) => i !== '')
        .map((word) => {
            let w = word.split('');
            w[0] = w[0].toUpperCase();
            return w.join('');
        })
        .join(' ');
};

export const unique = (list) => [...new Set(list)];

export const totalsGrossCalc = (subtotalAmount, taxRate) => {
    let taxTemp = saveWithTwoDecimalOnly(taxRate / 100);
    let divisor = saveWithTwoDecimalOnly(taxTemp + 1);
    return saveWithTwoDecimalOnly(subtotalAmount / divisor);
};

export const taxAmountCalc = (subtotalAmount, taxRate) =>
    saveWithTwoDecimalOnly(
        subtotalAmount - totalsGrossCalc(subtotalAmount, taxRate)
    );

const util = {
    displayTime,
    displayDate,
    buildPages,
    getCorrectJSDateObj,
    appDateString,
    appDateShort,
    getDateObjLastSunday,
    responseFn,
    getLastMonthDateISO,
    formatCustomErrorObj,
    clone,
    displayMoney,
    saveWithTwoDecimalOnly,
    saveWithFiveDecimals,
    applyDiscount,
    applyTax,
    getWaterMarkDateTime,
    formatAlertErrorObj,
    displayReadableNumber,
    validateUID,
    jsDateObjToISODate,
    generateBillingCycleFromWebhookObj,
    serviceShouldStillBeActive,
    sortReportItems,
    titleCase,
    unique,
    totalsGrossCalc,
    taxAmountCalc,
};
export default util;
