import React from 'react';
import {
    displayMoney,
    // applyTax,
    applyDiscount,
    saveWithTwoDecimalOnly,
    displayReadableNumber,
} from '../../lib/util';

import '../PagesCommon.css';
import './SDSIsPage.css';

export default (props) => {
    let { sddrObj, trashIcon, sddrIndex } = props;

    let sddrTotalAmount = 0;

    let posOfDr = sddrObj.sdpoArr.map((sdpoObj, sdpoIndex) => {
        let poItemsTotal = 0;

        let fgsOfPo = sdpoObj.fgArr.map((fg, sfgIndex) => {
            poItemsTotal += saveWithTwoDecimalOnly(
                fg.sddrDeliveredQtyForFg * fg.fgPrice
            );
            return (
                <div
                    className='span-2-columns-container'
                    key={`${fg.fgUID} ${sdpoObj.sdpoUID}`}>
                    <div className='view-table-row'>
                        <div className='cell'>
                            <span className='mobile-label m-right-15'>
                                {`${sfgIndex + 1}.`}
                            </span>
                            <span className='desktop-view m-lr-15'>
                                {`${sfgIndex + 1}.`}
                            </span>{' '}
                            <span>{fg.fgUID}</span>
                        </div>

                        <div className='cell'>
                            <div className='width25'>
                                <span className='mobile-label'>
                                    Delivered Qty:
                                </span>{' '}
                                <span>
                                    {displayReadableNumber(
                                        fg.sddrDeliveredQtyForFg
                                    )}
                                </span>
                            </div>
                            <div className='width25'>
                                <span className='mobile-label'>Unit:</span>{' '}
                                <span>{fg.fgUnit}</span>
                            </div>
                            <div className='width25'>
                                <span className='mobile-label'>Cost:</span>{' '}
                                <span>{displayMoney(fg.fgPrice)}</span>
                            </div>
                            <div className='width25'>
                                <span className='mobile-label'>Subtotal:</span>{' '}
                                <span>
                                    {displayMoney(
                                        saveWithTwoDecimalOnly(
                                            fg.sddrDeliveredQtyForFg *
                                                fg.fgPrice
                                        )
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        let sfgsOfPo = sdpoObj.sfgArr.map((sfg, sfgIndex) => {
            poItemsTotal += saveWithTwoDecimalOnly(
                sfg.sddrDeliveredQtyForSfg * sfg.sfgPrice
            );

            return (
                <div
                    className='span-2-columns-container'
                    key={`${sfg.sfgUID} ${sdpoObj.sdpoUID}`}>
                    <div className='view-table-row'>
                        <div className='cell'>
                            <span className='mobile-label m-right-15'>
                                {`${sfgIndex + 1}.`}
                            </span>
                            <span className='desktop-view m-lr-15'>
                                {`${sfgIndex + 1}.`}
                            </span>{' '}
                            <span>{sfg.sfgUID}</span>
                        </div>

                        <div className='cell'>
                            <div className='width25'>
                                <span className='mobile-label'>
                                    Delivered Qty:
                                </span>{' '}
                                <span>
                                    {displayReadableNumber(
                                        sfg.sddrDeliveredQtyForSfg
                                    )}
                                </span>
                            </div>
                            <div className='width25'>
                                <span className='mobile-label'>Unit:</span>{' '}
                                <span>{sfg.sfgUnit}</span>
                            </div>
                            <div className='width25'>
                                <span className='mobile-label'>Cost:</span>{' '}
                                <span>{displayMoney(sfg.sfgPrice)}</span>
                            </div>
                            <div className='width25'>
                                <span className='mobile-label'>Subtotal:</span>{' '}
                                <span>
                                    {displayMoney(
                                        saveWithTwoDecimalOnly(
                                            sfg.sddrDeliveredQtyForSfg *
                                                sfg.sfgPrice
                                        )
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        let sfgSection = (
            <>
                <div className='parent-labels m-top-10 pad-around-5 bg-grey-l2'>
                    SFG List
                </div>

                <div className='span-2-columns-container'>
                    <div className='view-table-heading'>
                        <div className='cell'>
                            <span className='m-left-15'>SFG Name</span>
                        </div>
                        <div className='cell'>
                            <span className='width25'>Delivered Qty</span>
                            <span className='width25'>Unit</span>
                            <span className='width25'>Unit Price</span>
                            <span className='width25'>Item Total</span>
                        </div>
                    </div>
                </div>
            </>
        );

        let fgSection = (
            <>
                <div className='parent-labels m-top-10 pad-around-5 bg-grey-l2'>
                    FG List
                </div>

                <div className='span-2-columns-container'>
                    <div className='view-table-heading'>
                        <div className='cell'>
                            <span className='m-left-15'>FG Name</span>
                        </div>
                        <div className='cell'>
                            <span className='width25'>Delivered Qty</span>
                            <span className='width25'>Unit</span>
                            <span className='width25'>Unit Price</span>
                            <span className='width25'>Item Total</span>
                        </div>
                    </div>
                </div>
            </>
        );

        if (fgsOfPo.length === 0) fgSection = null;
        if (sfgsOfPo.length === 0) sfgSection = null;

        let totalAmountWithDiscount = applyDiscount(
            poItemsTotal,
            sdpoObj.sdpoDiscountRate
        );
        // let totalAmountWithTax = applyTax(
        //     totalAmountWithDiscount,
        //     sdpoObj.sdpoTaxRate
        // );

        // total for each DR.
        sddrTotalAmount += saveWithTwoDecimalOnly(totalAmountWithDiscount);

        let totalBox = (
            <div className='view-table-row m-top-10'>
                <div className='desktop-view'></div>
                <div className='amount-box'>
                    <div>{`Subtotal with ${sdpoObj.sdpoDiscountRate} % discount:`}</div>
                    <div>
                        {displayMoney(
                            saveWithTwoDecimalOnly(totalAmountWithDiscount)
                        )}
                    </div>
                </div>
            </div>
        );

        return (
            <div
                className='span-2-columns-container show-border pad-around-10 m-top-10'
                key={sdpoObj.sdpoUID}>
                <div className='span-2-columns-container'>
                    <div className='space-between'>
                        <span>
                            <span className='parent-labels'>
                                PO {`${sdpoIndex + 1} :`}
                            </span>{' '}
                            {sdpoObj.sdpoUID}
                        </span>
                    </div>
                </div>

                {sfgSection}
                {sfgsOfPo}
                {fgSection}
                {fgsOfPo}
                {totalBox}
            </div>
        );
    });

    let sddrTotalBox = (
        <div className='view-table-row m-top-10'>
            <div className='desktop-view'></div>
            <div className='amount-box'>
                <div className='text-bold'>Total for Delivery Receipt:</div>{' '}
                <div className='text-bold'>{displayMoney(sddrTotalAmount)}</div>
            </div>
        </div>
    );

    return (
        <div
            className='span-2-columns-container show-border-darker pad-around-10 m-top-10'
            key={sddrObj.sddrUID}>
            <div className='span-2-columns-container'>
                <div className='space-between'>
                    <span>
                        <span className='parent-labels'>
                            DR {`${sddrIndex + 1}:`}
                        </span>{' '}
                        {sddrObj.sddrUID}
                    </span>

                    <span className='icon-size'>{trashIcon}</span>
                </div>
            </div>

            <div className='parent-labels m-top-10 pad-around-5 bg-grey-l1'>
                PO List
            </div>

            {posOfDr}
            {sddrTotalBox}
        </div>
    );
};
