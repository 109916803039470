import { db, firebase } from '../firebase_config/firebaseConfig';
import { userActions, systemActions } from '../lib/constants';
import {
    responseFn,
    formatCustomErrorObj,
    saveWithFiveDecimals,
} from '../lib/util';
import validate from '../validators';
import mListHelper from './mListHelper';
import dbopsGetDocument from './dbopsGetDocument';
import { docNames, appMessage } from '../lib/messages';
import {
    pdObjToMListItem,
    rmInvObjToMListItemFn,
} from '../lib/masterListConverters';

import {
    RAW_MATERIALS_COL,
    SG_NUMBERS_COL,
    PDPO_COUNTER_DOC,
    PDPOS_COL,
    MASTER_LISTS_COL,
    MASTER_PDPO_LIST_DOC,
    DELETED_PDPOS_COL,
    COST_HISTORY_COL,
    MASTER_INVENTORY_RM_LIST_DOC,
} from '../lib/constants';

// ================================================
// function to fetch each supplier document.
const getPoFn = async (docID) => dbopsGetDocument(PDPOS_COL, docID, 'PDPO');

// ======================================================
const createPoFn = async (userCreds, poObj) => {
    // add metadata to poObj to be created in firestore.
    poObj.meta = {
        action: userActions.CREATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // add metaHistory
    poObj.metaHistory = [];

    const transactionFn = async (transaction) => {
        // get a PDOP number from firebase.  (server generated number)
        let counterRef = db.collection(SG_NUMBERS_COL).doc(PDPO_COUNTER_DOC);
        let counterDoc = await transaction.get(counterRef);
        let currentPDPONumber = counterDoc.data().PDPONumber.toString();

        // add pdpoUID to poObj.
        poObj.pdpoUID = currentPDPONumber;
        poObj.pdpoNumber = currentPDPONumber;

        // get a reference for PDPO document using currentPDPONumber
        let pdpoRef = db.collection(PDPOS_COL).doc(currentPDPONumber);
        let newPDPODoc = await transaction.get(pdpoRef);

        // check for existence of newPDPODoc
        if (newPDPODoc.exists) {
            return Promise.reject(
                //! this should never run as PO number is server generated.
                formatCustomErrorObj(
                    1002,
                    appMessage.alreadyExist(docNames.PDPO)
                )
            );
        }

        // get pdpos list document reference
        let pdpoListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_PDPO_LIST_DOC);
        let pdpoListDoc = await transaction.get(pdpoListRef);

        // create new pdpo document
        await transaction.set(pdpoRef, poObj);

        // create or update PDPO list document in (master lists collection)
        if (pdpoListDoc.exists) {
            let pdpoInfoObj = pdObjToMListItem(poObj);

            let newData = mListHelper.updateMasterList(
                pdpoListDoc,
                poObj.pdpoUID,
                pdpoInfoObj
            );

            await transaction.set(pdpoListRef, newData);
        } else {
            let pdpoInfoObj = pdObjToMListItem(poObj);

            let newData = mListHelper.addMasterList(poObj.pdpoUID, pdpoInfoObj);

            await transaction.set(pdpoListRef, newData);
        }

        // 12. increment the PDPO number in the SGNumbers Collection Counters document.
        //! we are not using firebase increment because we need this to be unique for
        //! each document without race condition issues.
        await transaction.set(
            counterRef,
            { PDPONumber: counterDoc.data().PDPONumber + 1 },
            { merge: true }
        );

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyCreated(docNames.PDPO)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorCreating(docNames.PDPO)
        );
    }
};

// ===============================================
const updatePoFn = async (userCreds, poObj, documentBasis) => {
    if (poObj.metaHistory.length > 99) poObj.metaHistory.shift();

    poObj.metaHistory.push(poObj.meta);

    // update metadata in poObj.
    poObj.meta = {
        action: userActions.UPDATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let pdpoRef = db.collection(PDPOS_COL).doc(poObj.pdpoUID);
    let pdpoListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_PDPO_LIST_DOC);

    const transactionFn = async (transaction) => {
        // TRANSACTION GET =================================
        let pdpoDoc = await transaction.get(pdpoRef);
        let pdpoListDoc = await transaction.get(pdpoListRef);

        // CHECK FOR EXISTENCE AND CHANGE ============================
        if (!pdpoDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.PDPO)
                )
            );

        if (!validate.noChange(pdpoDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.PDPO)
                )
            );
        }

        // TRANSACTION SET =================================

        let pdpoInfoObj = pdObjToMListItem(poObj);

        let newData = mListHelper.updateMasterList(
            pdpoListDoc,
            poObj.pdpoUID,
            pdpoInfoObj
        );
        // write to masterlist and pdpo document in firebase.
        await transaction.set(pdpoListRef, newData);
        await transaction.set(pdpoRef, poObj);
        return Promise.resolve('TransactionFn Completed Successfully');
    };
    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyUpdated(docNames.PDPO)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorUpdating(docNames.PDPO)
        );
    }
};

// ================================================================
const deletePoFn = async (userCreds, poObj, documentBasis) => {
    if (poObj.metaHistory.length > 99) poObj.metaHistory.shift();

    poObj.metaHistory.push(poObj.meta);

    poObj.meta = {
        action: userActions.DELETED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // get all references.
    let pdpoRef = db.collection(PDPOS_COL).doc(poObj.pdpoUID);

    let pdpoListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_PDPO_LIST_DOC);

    let deletedPdpoRef = db.collection(DELETED_PDPOS_COL).doc();

    const transactionFn = async (transaction) => {
        // Start: perform all transaction get ===========================
        let pdpoDoc = await transaction.get(pdpoRef);
        let pdpoListDoc = await transaction.get(pdpoListRef);

        // still need to perform transaction get but no need to do anything with it.
        await transaction.get(deletedPdpoRef);

        // End: perform all transaction get ===========================

        if (!pdpoDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotDeleteBecauseDocNotExist(docNames.PDPO)
                )
            );

        if (!validate.noChange(pdpoDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotDeleteBecauseDocChanged(docNames.PDPO)
                )
            );
        }

        let newData = mListHelper.deletePropertyFromMasterList(
            pdpoListDoc,
            poObj.pdpoUID
        );

        await transaction.delete(pdpoRef);
        await transaction.set(pdpoListRef, newData);
        await transaction.set(deletedPdpoRef, poObj);
        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyDeleted(docNames.PDPO)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorDeleting(docNames.PDPO)
        );
    }
};

// ================================================================
const statusChangeFn = async (userCreds, poObj, documentBasis) => {
    // move old metadate into metahistory. ensure meta data history length
    // is 100 or less. will be checked by security rules.
    if (poObj.metaHistory.length > 99) poObj.metaHistory.shift();

    poObj.metaHistory.push(poObj.meta);

    // update metadata in poObj.
    poObj.meta = {
        action: userCreds.action,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let pdpoRef = db.collection(PDPOS_COL).doc(poObj.pdpoUID);
    let pdpoListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_PDPO_LIST_DOC);

    const transactionFn = async (transaction) => {
        //1. perform all transaction gets
        let pdpoDoc = await transaction.get(pdpoRef);

        let pdpoListDoc = await transaction.get(pdpoListRef);

        //2. check pdpoDoc against documentBasis if there is any change.
        if (!pdpoDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.PDPO)
                )
            );
        // if there is a change in pdpo document from the time
        // it was read prior to editting and now (transaction.get),
        // throw an error.
        // ! this does not check meta data.
        if (!validate.noChange(pdpoDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.PDPO)
                )
            );
        }
        // ! meta data check if status of document changed.
        if (pdpoDoc.data().meta.action !== documentBasis.meta.action) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.PDPO)
                )
            );
        }

        let pdpoInfoObj = pdObjToMListItem(poObj);

        let newData = mListHelper.updateMasterList(
            pdpoListDoc,
            poObj.pdpoUID,
            pdpoInfoObj
        );
        // write to masterlist and pdpo document in firebase.
        await transaction.set(pdpoListRef, newData);
        await transaction.set(pdpoRef, poObj);
        return Promise.resolve('TransactionFn Completed Successfully');
    };
    try {
        await db.runTransaction(transactionFn);
        return responseFn(null, false, null, appMessage.successfulUpdateStatus);
    } catch (e) {
        return responseFn(null, true, e, appMessage.errorUpdatingStatus);
    }
};

const approvePdpoFn = async (userCreds, poObj, documentBasis, costHistArr) => {
    if (poObj.metaHistory.length > 99) poObj.metaHistory.shift();

    poObj.metaHistory.push(poObj.meta);

    // add metadata to poObj to be created in firestore.
    poObj.meta = {
        action: userActions.APPROVED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let pdpoRef = db.collection(PDPOS_COL).doc(poObj.pdpoUID);
    let pdpoListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_PDPO_LIST_DOC);

    let rmInvListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_INVENTORY_RM_LIST_DOC);

    let chObjsRefArr = costHistArr.map((chObj) =>
        db.collection(COST_HISTORY_COL).doc(chObj.chUID)
    );

    let rmRefsArr = poObj.rmArr.map((rm) =>
        db.collection(RAW_MATERIALS_COL).doc(rm.rmUID)
    );

    let existError = false;

    const transactionFn = async (transaction) => {
        // START TRANSACTION GET =================================

        let pdpoDoc = await transaction.get(pdpoRef);
        let pdpoListDoc = await transaction.get(pdpoListRef);
        let rmInvListDoc = await transaction.get(rmInvListRef);

        let chObjsDocs = chObjsRefArr.map(
            async (chRef) => await transaction.get(chRef)
        );
        try {
            chObjsDocs = await Promise.all(chObjsDocs);
        } catch (e) {
            return Promise.reject(
                formatCustomErrorObj(
                    900,
                    appMessage.prommiseAllError('dbopsPDPOs', 'costHistoryArr')
                )
            );
        }

        let rmDocsArr = rmRefsArr.map(
            async (rmRef) => await transaction.get(rmRef)
        );

        try {
            rmDocsArr = await Promise.all(rmDocsArr);
        } catch (e) {
            return Promise.reject(
                formatCustomErrorObj(
                    900,
                    appMessage.prommiseAllError('dbopsPDPOs', 'rmArr')
                )
            );
        }

        // END TRANSACTION GET =============================================

        // START CHECK FOR CHANGE AND EXISTENCE =================================
        if (!pdpoDoc.exists) {
            return Promise.reject(
                //! this should never run as PO number is server generated.
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.PDPO)
                )
            );
        }
        // ! this does not check meta data.
        if (!validate.noChange(pdpoDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.PDPO)
                )
            );
        }
        // ! meta data check if status of document changed.
        if (pdpoDoc.data().meta.action !== documentBasis.meta.action) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.PDPO)
                )
            );
        }

        rmDocsArr.forEach((rmDoc) => {
            if (!rmDoc.exists) existError = true;
        });
        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopsPDPOs',
                        docNames.PDPO,
                        'RM'
                    )
                )
            );
        }

        // END CHECK FOR CHANGE AND EXISTENCE ==================================

        // START TRANSACTION SET ===============================================

        // update rm inventory
        let tempRmInventoryList = rmInvListDoc.data();

        // update rmProjectedQty an poNumbersArr on rmDocs and update firebase.
        //! no need to check what previous value of rmProjectedQty because
        //! just need to increment it.
        // use forEach so loop wont wait for transaction to finish.
        rmDocsArr.forEach(async (rmDoc, index) => {
            let newArr = [...rmDoc.data().poNumbersArr];
            newArr.push(poObj.pdpoUID);
            let updateData = {
                rmProjectedQty: saveWithFiveDecimals(
                    rmDoc.data().rmProjectedQty +
                        poObj.rmArr[index].rmOrderedQty
                ),
                //! add poNumber in the poNumbersArr to keep track how many
                //! active PO's this rm has.
                poNumbersArr: newArr,
            };

            let rmCopy = rmDoc.data();
            rmCopy.rmProjectedQty = updateData.rmProjectedQty;
            let inventoryRmInfoObj = rmInvObjToMListItemFn(rmCopy);

            tempRmInventoryList = mListHelper.updateTempMList(
                tempRmInventoryList,
                rmCopy.rmUID,
                inventoryRmInfoObj
            );

            await transaction.set(rmRefsArr[index], updateData, {
                merge: true,
            });
        });

        // check costDate of each cost history objects and choose the one with
        // most recent date.
        let mostRecentCostHistArr = costHistArr.map((chObj, index) => {
            if (
                chObjsDocs[index].exists &&
                chObjsDocs[index].data().costDate > chObj.costDate
            )
                return chObjsDocs[index].data();
            else return chObj;
        });

        // this will not wait for the transaction to finish.
        // create or update chObjs with transaction.set
        chObjsRefArr.forEach(async (ref, index) => {
            await transaction.set(ref, mostRecentCostHistArr[index]);
        });

        // set APPROVED pdpo document
        await transaction.set(pdpoRef, poObj);

        // set rmInventory document
        await transaction.set(rmInvListRef, tempRmInventoryList);

        //create or update PDPO list document in (master lists collection)
        if (pdpoListDoc.exists) {
            let pdpoInfoObj = pdObjToMListItem(poObj);

            let newData = mListHelper.updateMasterList(
                pdpoListDoc,
                poObj.pdpoUID,
                pdpoInfoObj
            );

            await transaction.set(pdpoListRef, newData);
        } else {
            let pdpoInfoObj = pdObjToMListItem(poObj);

            let newData = mListHelper.addMasterList(poObj.pdpoUID, pdpoInfoObj);

            await transaction.set(pdpoListRef, newData);
        }

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(null, false, null, appMessage.successfulUpdateStatus);
    } catch (e) {
        return responseFn(null, true, e, appMessage.errorUpdatingStatus);
    }
};

const overRidePoFn = async (userCreds, poObj, documentBasis) => {
    if (poObj.metaHistory.length > 99) poObj.metaHistory.shift();

    poObj.metaHistory.push(poObj.meta);

    // update metadata in poObj.
    poObj.meta = {
        action: userActions.OVERRIDDEN,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let pdpoRef = db.collection(PDPOS_COL).doc(poObj.pdpoUID);
    let pdpoListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_PDPO_LIST_DOC);

    let rmInvListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_INVENTORY_RM_LIST_DOC);

    let rmRefsArr = poObj.rmArr.map((rm) =>
        db.collection(RAW_MATERIALS_COL).doc(rm.rmUID)
    );

    let existError = false;
    const transactionFn = async (transaction) => {
        // Start: perform all transaction get ===========================
        let pdpoDoc = await transaction.get(pdpoRef);
        let pdpoListDoc = await transaction.get(pdpoListRef);
        let rmInvListDoc = await transaction.get(rmInvListRef);

        // get all rm Docs needed to be updated (rmProjectedQty).
        let rmDocsArr = rmRefsArr.map(
            async (rmRef) => await transaction.get(rmRef)
        );

        // ensure all promises / get operation for rm are done.
        try {
            rmDocsArr = await Promise.all(rmDocsArr);
        } catch (e) {
            return Promise.reject(
                formatCustomErrorObj(
                    900,
                    appMessage.prommiseAllError('dbopsPDPOs', 'rmArr')
                )
            );
        }

        // check if all rms exists
        rmDocsArr.forEach((rmDoc) => {
            if (!rmDoc.exists) existError = true;
        });
        //! this is an edge case scenario. should not really happen but is possible.
        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopsPDPOs',
                        docNames.PDPO,
                        'RM'
                    )
                )
            );
        }

        // End: perform all transaction get ===========================

        // check if there are changes to the pdpo, throw error if yes. ===================
        if (!pdpoDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.PDPO)
                )
            );

        // if there is a change in pdpo document from the time
        // it was read prior to overriding and now (transaction.get),
        // throw an error.
        if (!validate.noChange(pdpoDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.PDPO)
                )
            );
        }

        // check if po status can be changed to 'FULFILLED'. ==================
        let allRmsDeliveredOrOverridden = [];
        poObj.rmArr.forEach((rm) => {
            if (
                saveWithFiveDecimals(rm.rmBalanceQty + rm.rmOverrideQty) === 0
            ) {
                allRmsDeliveredOrOverridden.push(true);
            } else {
                allRmsDeliveredOrOverridden.push(false);
            }
        });

        // copy of rmDocs so poNumbersArr can be manipulated.
        let rmDocsArrCopy = rmDocsArr.map((rmDoc) => rmDoc.data());

        if (allRmsDeliveredOrOverridden.every((ans) => ans === true)) {
            //! remove pdpoUID from all the rm.poNumbersArr.
            rmDocsArrCopy.forEach((rmObj) => {
                let newArr = rmObj.poNumbersArr.filter(
                    (poNumber) => poNumber !== poObj.pdpoUID
                );
                rmObj.poNumbersArr = newArr;
            });

            // update metadata in poObj.
            poObj.meta = {
                action: systemActions.FULFILLED,
                uid: systemActions.UID,
                email: systemActions.EMAIL,
                alert: {
                    note:
                        '"OVERRIDDEN" by user then system changed status to "FULFILLED"',
                    action: userActions.OVERRIDDEN,
                    uid: userCreds.uid,
                    email: userCreds.email,
                    timeStamp:
                        'Same time as system changed status of this document.',
                },
                timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
            };
        } else {
            // else... meta will be overridden and changed to status "OVERRIDDEN"
            // done in the first step of the function.

            //! include pdpoUID in all rm.poNumbersArr of this pdpo document.
            rmDocsArrCopy.forEach((rmObj) => {
                let newArr = rmObj.poNumbersArr.filter(
                    (poNumber) => poNumber !== poObj.pdpoUID
                );
                newArr.push(poObj.pdpoUID);
                rmObj.poNumbersArr = newArr;
            });
        }

        let tempRmInventoryList = rmInvListDoc.data();

        // calculate all rm projectedQty ==================================
        rmDocsArr.forEach(async (rmDoc, index) => {
            let current = poObj.rmArr[index].rmOverrideQty;
            let previous = documentBasis.rmArr[index].rmOverrideQty;
            let newProjected = saveWithFiveDecimals(
                rmDoc.data().rmProjectedQty +
                    saveWithFiveDecimals(current - previous)
            );

            let updateData = {
                rmProjectedQty: newProjected,
                poNumbersArr: rmDocsArrCopy[index].poNumbersArr,
            };

            let rmCopy = rmDocsArrCopy[index];
            rmCopy.rmProjectedQty = updateData.rmProjectedQty;
            let inventoryRmInfoObj = rmInvObjToMListItemFn(rmCopy);

            tempRmInventoryList = mListHelper.updateTempMList(
                tempRmInventoryList,
                rmCopy.rmUID,
                inventoryRmInfoObj
            );

            // update all rms in firebase ========================================
            await transaction.set(rmRefsArr[index], updateData, {
                merge: true,
            });
        });

        await transaction.set(pdpoRef, poObj);

        await transaction.set(rmInvListRef, tempRmInventoryList);

        // update masterlist =====================================
        let pdpoInfoObj = pdObjToMListItem(poObj);

        let newData = mListHelper.updateMasterList(
            pdpoListDoc,
            poObj.pdpoUID,
            pdpoInfoObj
        );
        // write to masterlist of pdpo in firebase.
        await transaction.set(pdpoListRef, newData);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyUpdated(docNames.PDPO)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorUpdating(docNames.PDPO)
        );
    }
};

export default {
    getPoFn,
    createPoFn,
    updatePoFn,
    deletePoFn,
    statusChangeFn,
    approvePdpoFn,
    overRidePoFn,
};
