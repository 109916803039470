import React from 'react';
import { displayReadableNumber } from '../../../lib/util';
import './InventoryRmItem.css';
import '../CollectionItems.css';

export default (props) => {
    let { obj, serialNumber, onClick, heading } = props;

    let rmType = '';
    let rmDescription = '';
    let serial = 'S/No.';
    let type = 'Raw Material Type';
    let description = 'Raw Material Description';
    let unit = 'Unit';
    let category = 'Category';
    let lowThreshold = 'Low Threshold';
    let projectedQty = 'Projected Qty';
    let actualQty = 'Actual Qty';
    let headingClass = 'text-bold list-table-heading';

    let serialLabel = 'S/No.:';
    let rmTypeLabel = 'RM Type:';
    let rmDescLabel = 'RM Desc:';
    let rmUnitLabel = 'Unit:';
    let rmCategoryLabel = 'Category';
    let rmLowLabel = 'Low Threshold:';
    let rmProjQtyLabel = 'Projected Qty:';
    let rmActiveQtyLabel = 'Actual Qty:';

    if (!heading) {
        [rmType, rmDescription] = obj.rmUID.split(' - ');
        serial = serialNumber;
        type = rmType;
        description = rmDescription;
        unit = obj.rmUnit;
        category = obj.rmCategory;
        lowThreshold = obj.rmLowThreshold;
        projectedQty = obj.rmProjectedQty;
        actualQty = obj.rmActualQty;
        headingClass = '';
    }

    let lowThresholdRed = '';
    let actualRed = '';
    let projectedRed = '';

    if (lowThreshold > actualQty && !heading) {
        lowThresholdRed = 'text-warning';
        actualRed = 'text-warning';
    }
    if (lowThreshold > projectedQty && !heading) {
        lowThresholdRed = 'text-warning';
        projectedRed = 'text-warning';
    }

    // let lowThresholdRed = !heading ? 'tj-right' : '';
    // let actualRed = !heading ? 'tj-right' : '';
    // let projectedRed = !heading ? 'tj-right' : '';

    // if (lowThreshold > actualQty && !heading) {
    //     lowThresholdRed = 'text-warning tj-right';
    //     actualRed = 'text-warning tj-right';
    // }
    // if (lowThreshold > projectedQty && !heading) {
    //     lowThresholdRed = 'text-warning tj-right';
    //     projectedRed = 'text-warning tj-right';
    // }

    if (!heading) {
        lowThreshold = displayReadableNumber(lowThreshold);
        projectedQty = displayReadableNumber(projectedQty);
        actualQty = displayReadableNumber(actualQty);
    }

    return (
        <div
            className={`setup-rm-inventory-item collection-item ${headingClass}`}
            onClick={onClick}
        >
            <div className='collection-item-entry serial'>
                <span className='entry-label'>{serialLabel}</span>
                {serial}
            </div>
            <div className='collection-item-entry rmtype'>
                {' '}
                <span className='entry-label'>{rmTypeLabel}</span>
                {type}
            </div>
            <div className='collection-item-entry rmdescription'>
                {' '}
                <span className='entry-label'>{rmDescLabel}</span>
                {description}
            </div>
            <div className='collection-item-entry rmcategory'>
                {' '}
                <span className='entry-label'>{rmCategoryLabel}</span>
                {category}
            </div>
            <div className='collection-item-entry rmunit'>
                {' '}
                <span className='entry-label'>{rmUnitLabel}</span>
                {unit}
            </div>

            <div className='collection-item-entry rmlowthreshold'>
                {' '}
                <span className='entry-label'>{rmLowLabel}</span>
                <span className={lowThresholdRed}>{lowThreshold}</span>
            </div>
            <div className='collection-item-entry rmprojected-qty'>
                {' '}
                <span className='entry-label'>{rmProjQtyLabel}</span>
                <span className={projectedRed}>{projectedQty}</span>
            </div>
            <div className='collection-item-entry rmactual-qty'>
                {' '}
                <span className='entry-label'>{rmActiveQtyLabel}</span>
                <span className={actualRed}>{actualQty}</span>
            </div>
        </div>
    );
};
