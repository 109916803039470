import React from 'react';
import { InputDS } from '../../components';
import { saveWithFiveDecimals, displayReadableNumber } from '../../lib/util';

import '../PagesCommon.css';
import './AssemblyPage.css';

const GroupedSFG = (props) => {
    let {
        sfgArr,
        fgUID,
        fgOrderedQty,
        fgIndex,
        updateSfgValFn,
        masterInventorySFGsList,
    } = props;

    let requiredQty = 0;
    let totalUsed = 0;
    let sfgUnequalRequiredAndProjectedQtyError = '';

    let sfgs = sfgArr.map((sfg) => {
        requiredQty = saveWithFiveDecimals(
            saveWithFiveDecimals(sfg.sfgRequiredQty) *
                saveWithFiveDecimals(fgOrderedQty)
        );
        totalUsed += saveWithFiveDecimals(sfg.sfgProjectedUseQty);
        sfgUnequalRequiredAndProjectedQtyError =
            sfg.sfgUnequalRequiredAndProjectedQtyError;

        let sfgInList = masterInventorySFGsList.filter(
            (sfgLc) => sfgLc.sfgUID === sfg.sfgUID
        )[0];

        let availableQty = `${sfgInList.sfgProjectedQty} ${sfgInList.sfgUnit}`;

        let textWarningColor = '';
        let textWarningColorInput = '';
        if (
            Number(sfgInList.sfgProjectedQty) < Number(sfg.sfgProjectedUseQty)
        ) {
            textWarningColor = 'text-warning';
            textWarningColorInput = 'text-warning-wrapper';
        }

        return (
            <div
                className='span-2-columns-container'
                key={`${fgUID}${sfg.sfgUID}`}>
                <div className='view-table-row-operations'>
                    <div className='cell'>
                        <div className='width16'>
                            <span className='mobile-label'>Grp:</span>{' '}
                            <span className='desktop-view m-left-15'>
                                {' '}
                                {sfg.group}
                            </span>
                            <span className='mobile-label'> {sfg.group}</span>
                        </div>
                        <div className=''>
                            <span className='mobile-label'>SFG:</span>{' '}
                            <span> {sfg.sfgUID}</span>
                        </div>
                    </div>

                    <div className='cell'>
                        <div className='width33'>
                            {' '}
                            <span className='mobile-label'>
                                Available SFG Qty:
                            </span>{' '}
                            <span className={`${textWarningColor}`}>
                                {availableQty}
                            </span>
                        </div>

                        <div className='width33'>
                            <span className='mobile-label'>
                                Projected Qty (Planned):
                            </span>{' '}
                            <div className={`${textWarningColorInput}`}>
                                <InputDS
                                    type='number'
                                    initialValue={sfg.sfgProjectedUseQty}
                                    getInputValue={(val) =>
                                        updateSfgValFn(
                                            val,
                                            fgIndex,
                                            sfg.tempSfgIndex,
                                            'sfgProjectedUseQty'
                                        )
                                    }
                                    errorMessage={
                                        sfg.sfgProjectedUseQtyError
                                    }></InputDS>
                            </div>
                        </div>
                        <div className='width33 pad-left-15'>
                            {' '}
                            <span className='mobile-label'>Unit:</span>{' '}
                            {sfg.sfgUnit}
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    let redText = totalUsed === requiredQty ? '' : 'text-red';

    return (
        <div className='sfg-group'>
            {sfgs}
            <div className='view-table-row-operations'>
                <div className='cell'></div>
                <div className='cell'>
                    <span className='mobile-label m-right-5'>Grp Qty:</span>{' '}
                    <span className='desktop-view width33'></span>
                    <span className={`total-used m-right-5 ${redText}`}>
                        {displayReadableNumber(totalUsed)}
                    </span>
                    <span className={`denominator ${redText}`}>
                        / {displayReadableNumber(requiredQty)}
                    </span>
                </div>
            </div>
            <div className='view-table-row-operations'>
                <div className='cell'></div>
                <div className='cell'>
                    <span className='error-message'>
                        {sfgUnequalRequiredAndProjectedQtyError}
                    </span>
                </div>
            </div>
        </div>
    );
};

const GroupedRM = (props) => {
    let {
        rmArr,
        fgUID,
        fgOrderedQty,
        fgIndex,
        updateRmValFn,
        masterInventoryRMsList,
    } = props;

    let requiredQty = 0;
    let totalUsed = 0;
    let rmUnequalRequiredAndProjectedQtyError = '';

    let rms = rmArr.map((rm) => {
        requiredQty = saveWithFiveDecimals(
            saveWithFiveDecimals(rm.rmRequiredQty) *
                saveWithFiveDecimals(fgOrderedQty)
        );
        totalUsed += saveWithFiveDecimals(rm.rmProjectedUseQty);
        rmUnequalRequiredAndProjectedQtyError =
            rm.rmUnequalRequiredAndProjectedQtyError;

        let rawMatInList = masterInventoryRMsList.filter(
            (rawMat) => rawMat.rmUID === rm.rmUID
        )[0];

        let availableQty = `${rawMatInList.rmProjectedQty} ${rawMatInList.rmUnit}`;

        let textWarningColor = '';
        let textWarningColorInput = '';
        if (
            Number(rawMatInList.rmProjectedQty) < Number(rm.rmProjectedUseQty)
        ) {
            textWarningColor = 'text-warning';
            textWarningColorInput = 'text-warning-wrapper';
        }

        return (
            <div
                className='span-2-columns-container'
                key={`${fgUID}${rm.rmUID}`}>
                <div className='view-table-row-operations'>
                    <div className='cell'>
                        <div className='width16'>
                            <span className='mobile-label'>Grp:</span>{' '}
                            <span className='desktop-view m-left-15'>
                                {' '}
                                {rm.group}
                            </span>
                            <span className='mobile-label'> {rm.group}</span>
                        </div>
                        <div className=''>
                            <span className='mobile-label'>RM:</span>{' '}
                            <span> {rm.rmUID}</span>
                        </div>
                    </div>

                    <div className='cell'>
                        <div className='width33'>
                            {' '}
                            <span className='mobile-label'>
                                Available RM Qty:
                            </span>{' '}
                            <span className={`${textWarningColor}`}>
                                {availableQty}
                            </span>
                        </div>
                        <div className='width33'>
                            <span className='mobile-label'>
                                Projected Qty (Planned):
                            </span>{' '}
                            <div className={`${textWarningColorInput}`}>
                                <InputDS
                                    type='number'
                                    initialValue={rm.rmProjectedUseQty}
                                    getInputValue={(val) =>
                                        updateRmValFn(
                                            val,
                                            fgIndex,
                                            rm.tempRmIndex,
                                            'rmProjectedUseQty'
                                        )
                                    }
                                    errorMessage={
                                        rm.rmProjectedUseQtyError
                                    }></InputDS>
                            </div>
                        </div>
                        <div className='width33 pad-left-15'>
                            {' '}
                            <span className='mobile-label'>Unit:</span>{' '}
                            {rm.rmUnit}
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    let redText = totalUsed === requiredQty ? '' : 'text-red';

    return (
        <div className='sfg-group'>
            {rms}
            <div className='view-table-row-operations'>
                <div className='cell'></div>
                <div className='cell'>
                    <span className='mobile-label m-right-5'>Grp Qty:</span>{' '}
                    <span className='desktop-view width33'></span>
                    <span className={`total-used m-right-5 ${redText}`}>
                        {displayReadableNumber(totalUsed)}
                    </span>
                    <span className={`denominator ${redText}`}>
                        / {displayReadableNumber(requiredQty)}
                    </span>
                </div>
            </div>
            <div className='view-table-row-operations'>
                <div className='cell'></div>
                <div className='cell'>
                    <span className='error-message'>
                        {rmUnequalRequiredAndProjectedQtyError}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default (props) => {
    let {
        fgObj,
        fgIndex,

        updateSfgValFn,
        updateRmValFn,
        updateFgValFn,

        trashIcon,

        masterInventoryRMsList,
        masterInventorySFGsList,
    } = props;

    let groupedRmMap = {};
    let groupedSfgMap = {};

    fgObj.rmArr.forEach((rm, rmIndex) => {
        rm.tempRmIndex = rmIndex;
        if (groupedRmMap[rm.group] === undefined) {
            groupedRmMap[rm.group] = [];
            groupedRmMap[rm.group].push(rm);
        } else groupedRmMap[rm.group].push(rm);
    });

    fgObj.sfgArr.forEach((sfg, sfgIndex) => {
        sfg.tempSfgIndex = sfgIndex;
        if (groupedSfgMap[sfg.group] === undefined) {
            groupedSfgMap[sfg.group] = [];
            groupedSfgMap[sfg.group].push(sfg);
        } else groupedSfgMap[sfg.group].push(sfg);
    });

    let listOfRms = [];
    let listOfSfgs = [];

    for (let gkey in groupedRmMap) {
        let component = (
            <GroupedRM
                key={`${fgObj.fgUID}${gkey} `}
                fgIndex={fgIndex}
                rmArr={groupedRmMap[gkey]}
                updateRmValFn={updateRmValFn}
                fgUID={fgObj.fgUID}
                fgOrderedQty={fgObj.fgOrderedQty}
                masterInventoryRMsList={masterInventoryRMsList}
            />
        );
        listOfRms.push(component);
    }

    for (let gkey in groupedSfgMap) {
        let component = (
            <GroupedSFG
                key={`${fgObj.fgUID}${gkey} `}
                fgIndex={fgIndex}
                sfgArr={groupedSfgMap[gkey]}
                updateSfgValFn={updateSfgValFn}
                fgUID={fgObj.fgUID}
                fgOrderedQty={fgObj.fgOrderedQty}
                masterInventorySFGsList={masterInventorySFGsList}
            />
        );
        listOfSfgs.push(component);
    }

    let rmSection = (
        <>
            <div className='subform-list-section'>
                <div className='sls-label sls-item'>
                    <div className='cell'>RM List </div>
                    <div className='cell desktop-view'></div>
                </div>
            </div>

            <div className='span-2-columns-container'>
                <div className='view-table-heading-operations'>
                    <div className='cell'>
                        <span className='m-left-15 width16'>Grp</span>
                        <span className=''>RM Type - Description</span>
                    </div>
                    <div className='cell'>
                        <span className='width33'>Available RM Qty</span>
                        <span className='width33'>Projected Qty (Planned)</span>
                        <span className='width33 pad-left-15'>Unit</span>
                    </div>
                </div>
            </div>

            {listOfRms}
        </>
    );

    let sfgSection = (
        <>
            <div className='subform-list-section'>
                <div className='sls-label sls-item'>
                    <div className='cell'>SFG List </div>
                    <div className='cell desktop-view'></div>
                </div>
            </div>

            <div className='span-2-columns-container'>
                <div className='view-table-heading-operations'>
                    <div className='cell'>
                        <span className='m-left-15 width16'>Grp</span>
                        <span className=''>SFG Name</span>
                    </div>
                    <div className='cell'>
                        <span className='width33'>Available SFG Qty</span>
                        <span className='width33'>Projected Qty (Planned)</span>
                        <span className='width33 pad-left-15'>Unit</span>
                    </div>
                </div>
            </div>

            {listOfSfgs}
        </>
    );

    if (fgObj.sfgArr.length === 0) sfgSection = null;
    if (fgObj.rmArr.length === 0) rmSection = null;

    return (
        <div className='subform'>
            <div className='subform-label-qtys-section'>
                <div className='label'> {`${fgIndex + 1}. ${fgObj.fgUID}`}</div>

                <div className='icon'>{trashIcon}</div>
            </div>

            <div className='subform-2-column-section'>
                <div className='flex-row'>
                    <InputDS
                        label='Order Quantity'
                        type='number'
                        initialValue={fgObj.fgOrderedQty}
                        getInputValue={(val) =>
                            updateFgValFn(val, fgIndex, 'fgOrderedQty')
                        }
                        errorMessage={fgObj.fgOrderedQtyError}></InputDS>
                    <span className='m-left-10 pad-top-10'>{fgObj.fgUnit}</span>
                </div>
            </div>

            {rmSection}
            {sfgSection}
        </div>
    );
};
