import React, { useState, useContext } from 'react';
import { dbopsSuppliers } from '../../firebase_dbops';
import { MasterListContext, AuthContext } from '../../contexts';
import { InputIcon, ListView2 } from '../../components';
import SetupSupCusItem from '../../components/CollectionItems/SetupSupCusItem/SetupSupCusItem';

import { permissions, lengths } from '../../lib/constants';
import '../PagesCommon.css';
import './SuppliersPage.css';

import SuppliersForm from './SuppliersForm';

const view = {
    LIST_VIEW: 'listView',
    ADD_SUPPLIER: 'addSupplier',
    EDIT_SUPPLIER: 'editSupplier',
    DELETE_SUPPLIER: 'deleteSupplier',
    SUPPLIER_PROFILE: 'supplierProfile',
};

export default (props) => {
    let { setIsLoading, setAlertErrorMsg, setAlertMessage } = props;

    let { masterSuppliersList } = useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);

    let [formView, setFormView] = useState(view.LIST_VIEW);
    let [currentDocument, setCurrentDocument] = useState('');
    let [searchText, setSearchText] = useState('');

    // functions to change views.
    const addSupplierBtnFn = () => setFormView(view.ADD_SUPPLIER);
    const gotoListViewFn = () => setFormView(view.LIST_VIEW);
    const gotoEditViewFn = () => setFormView(view.EDIT_SUPPLIER);
    const gotoDeleteViewFn = () => setFormView(view.DELETE_SUPPLIER);
    const gotoSupplierProfileViewFn = () => setFormView(view.SUPPLIER_PROFILE);

    // function to get supplier document.
    const getDocumentFromDBFn = async (docID) => {
        setIsLoading(true);
        let response = await dbopsSuppliers.getSupplierFn(docID);
        if (response.error)
            setAlertErrorMsg(response.errorObj.message || response.message);
        else {
            setCurrentDocument(response.data);
            gotoSupplierProfileViewFn();
        }
        setIsLoading(false);
    };

    // function to create Supplier
    const createSupplierFn = async (supplierObj) => {
        // spinner on.
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        // write to db.
        let response = await dbopsSuppliers.createSupplierFn(
            userCreds,
            supplierObj
        );
        if (response.error) setAlertErrorMsg(response.errorObj.message);
        else setAlertMessage(response.message);

        gotoListViewFn();

        // spinner off.
        setIsLoading(false);
    };

    // function to update supplier.
    const updateSupplierFn = async (supplierObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsSuppliers.updateSupplierFn(
            userCreds,
            supplierObj,
            documentBasis
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);
            // if error is because document has already been deleted,
            // go back to list view.
            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
            // if error is because document has since changed, just continue
            // flow of the program.
        } else setAlertMessage(response.message);

        // this get document ensures state in FE state (currentDocument) is updated.
        gotoListViewFn();
        await getDocumentFromDBFn(supplierObj.supplierUID);

        setIsLoading(false);
    };

    // function to delete supplier.
    const deleteSupplierFn = async (supplierObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsSuppliers.deleteSupplierFn(
            userCreds,
            supplierObj,
            documentBasis
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);

            // if error is because document has changed before changes
            // could be applied, get latest version of supplier and
            // go back to supplier profile view.
            if (response.errorObj.code === 1001) {
                gotoListViewFn();
                await getDocumentFromDBFn(supplierObj.supplierUID);
                setIsLoading(false); // redundant
                return;
            }
            // all other errors can continue flow of program.
        } else setAlertMessage(response.message);

        gotoListViewFn();

        setIsLoading(false);
    };

    // filter function =====================================
    let filteredSuppliersList = masterSuppliersList.filter((supplier) => {
        return (
            supplier.supplierUID
                .toUpperCase()
                .includes(searchText.toUpperCase()) ||
            supplier.contactPerson
                .toUpperCase()
                .includes(searchText.toUpperCase()) ||
            supplier.contactNumber1
                .toUpperCase()
                .includes(searchText.toUpperCase())
        );
    });

    let addSupplierBtnUI = (
        <button className='btn btn-primary' onClick={addSupplierBtnFn}>
            Create Supplier
        </button>
    );
    if (
        !authUser.permissions.includes(permissions.SUPPLIER_SETUP) ||
        masterSuppliersList.length >= lengths.masterListLimit ||
        !authUser.subscriptionStatusIsActive
    ) {
        addSupplierBtnUI = null;
    }

    let topSectionContent = (
        <section className='top-section'>
            <InputIcon
                id='suppliers-search-input'
                type='text'
                placeholder='Search'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                icon={
                    <i
                        className='ms-Icon ms-Icon--Search'
                        aria-hidden='true'
                    ></i>
                }
            />
            {addSupplierBtnUI}
        </section>
    );

    let mainSectionContent = (
        <ListView2
            getDocumentFromDBFn={getDocumentFromDBFn}
            list={filteredSuppliersList}
            keyProperty={'supplierUID'}
            CollectionItemComponent={SetupSupCusItem}
        />
    );

    if (formView !== view.LIST_VIEW) topSectionContent = null;

    if (formView === view.ADD_SUPPLIER) {
        mainSectionContent = (
            <SuppliersForm
                purpose='ADD'
                backCancelBtnFn={gotoListViewFn}
                createSupplierFn={createSupplierFn}
            />
        );
    }

    if (formView === view.EDIT_SUPPLIER) {
        mainSectionContent = (
            <SuppliersForm
                purpose='EDIT'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoSupplierProfileViewFn}
                updateSupplierFn={updateSupplierFn}
            />
        );
    }

    if (formView === view.DELETE_SUPPLIER) {
        mainSectionContent = (
            <SuppliersForm
                purpose='DELETE'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoSupplierProfileViewFn}
                deleteSupplierFn={deleteSupplierFn}
            />
        );
    }

    if (formView === view.SUPPLIER_PROFILE) {
        mainSectionContent = (
            <SuppliersForm
                purpose='VIEW'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoListViewFn}
                gotoEditViewFn={gotoEditViewFn}
                gotoDeleteViewFn={gotoDeleteViewFn}
            />
        );
    }

    return (
        <div className='content-layout'>
            {topSectionContent}

            <section className='main-section'>{mainSectionContent}</section>
        </div>
    );
};
