import React, { useEffect, useState } from 'react';
import Chart from 'chart.js';
import './Chart.css';

export default (props) => {
    let {
        chartID,
        label,
        dataValues,
        dataLabels,
        dataBackgroundColors,
        reset,
        chartType,
    } = props;

    if (!chartID)
        throw Error('chartID must not be empty and it should be unique.');

    let [isScreenSmall, setIsScreenSmall] = useState(false);

    useEffect(() => {
        if (chartType !== undefined) generateChart();
    }, [reset]);

    useEffect(() => {
        if (window.innerWidth < 601) {
            setIsScreenSmall(true);
        }
    }, []);

    const generateChart = () => {
        // get chart element and put in data.
        let chartCtx = document.querySelector(`#${chartID}`).getContext('2d');

        new Chart(chartCtx, {
            type: chartType,
            data: {
                labels: dataLabels,
                datasets: [
                    {
                        label: label,
                        data: dataValues,
                        backgroundColor: dataBackgroundColors,
                        borderColor: dataBackgroundColors,
                        borderWidth: 0,
                    },
                ],
            },
            // options: {
            //     scales: {
            //         yAxes: [
            //             {
            //                 ticks: {
            //                     beginAtZero: true,
            //                 },
            //             },
            //         ],
            //     },
            // },

            options: {
                legend: {
                    display: !isScreenSmall,
                    position: 'right',
                },
            },
        });
    };

    let tableContainer = null;

    if (dataLabels) {
        let tableItemUI = dataLabels.map((item, index) => {
            let fontColor = '';
            if (isScreenSmall) {
                fontColor = `${dataBackgroundColors[index]}`;
            }
            return (
                <div
                    className='table-row table-items'
                    key={chartID + index}
                    style={{
                        color: fontColor,
                    }}
                >
                    <div>{item}</div>
                    <div>{dataValues[index]}</div>
                </div>
            );
        });

        tableContainer = (
            <div className='chart-table '>
                <div className='table-row table-heading'>
                    <div>Status</div>
                    <div>Count</div>
                </div>
                {tableItemUI}
            </div>
        );
    }

    return (
        <div className='chart-container'>
            <div className='label'>{label}</div>
            <canvas id={chartID}></canvas>
            {tableContainer}
        </div>
    );
};
