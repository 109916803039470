import { db, firebase } from '../firebase_config/firebaseConfig';
import { userActions } from '../lib/constants';
import {
    responseFn,
    formatCustomErrorObj,
    saveWithFiveDecimals,
    formatAlertErrorObj,
} from '../lib/util';
import validate from '../validators';
import mListHelper from './mListHelper';
import { docNames, appMessage } from '../lib/messages';
import dbopsGetDocument from './dbopsGetDocument';

import {
    invTransferObjToMListItemFn,
    sfgInvObjToMListItemFn,
    rmInvObjToMListItemFn,
    fgInvObjToMListItemFn,
} from '../lib/masterListConverters';

import {
    INVENTORY_TRANSFER_COL,
    DELETED_INVENTORY_TRANSFER_COL,
    MASTER_LISTS_COL,
    MASTER_INVENTORY_TRANSFER_LIST_DOC,
    MASTER_INVENTORY_RM_LIST_DOC,
    MASTER_INVENTORY_SFG_LIST_DOC,
    MASTER_INVENTORY_FG_LIST_DOC,
    SG_NUMBERS_COL,
    INV_TRANSFER_COUNTER_DOC,
    RAW_MATERIALS_COL,
    SEMI_FGS_COL,
    FINISHED_GOODS_COL,
} from '../lib/constants';

// ================================================
// function to fetch each sfg document.
const getInvTransferFn = async (docID) =>
    dbopsGetDocument(INVENTORY_TRANSFER_COL, docID, docNames.INV_TRANSFER);

// ======================================================
const createInvTransferFn = async (userCreds, payload) => {
    // add metadata to payload to be created in firestore.
    payload.meta = {
        action: userActions.CREATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // add metaHistory
    payload.metaHistory = [];

    const transactionFn = async (transaction) => {
        let counterRef = db
            .collection(SG_NUMBERS_COL)
            .doc(INV_TRANSFER_COUNTER_DOC);
        let counterDoc = await transaction.get(counterRef);
        let currentInvTransferNumber = counterDoc
            .data()
            .InvTransferNumber.toString();

        payload.invTransferUID = currentInvTransferNumber;
        payload.InvTransferNumber = currentInvTransferNumber;

        let newInvTransferRef = db
            .collection(INVENTORY_TRANSFER_COL)
            .doc(payload.invTransferUID);

        let invTransferListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_INVENTORY_TRANSFER_LIST_DOC);

        let newInvTransferDoc = await transaction.get(newInvTransferRef);
        let invTransferListDoc = await transaction.get(invTransferListRef);

        // create new sfg document
        if (newInvTransferDoc.exists) {
            return Promise.reject(
                formatCustomErrorObj(
                    1002,
                    appMessage.alreadyExist(docNames.INV_TRANSFER)
                )
            );
        }
        await transaction.set(newInvTransferRef, payload);

        // create or update invTransfer list document in (master lists collection)
        if (invTransferListDoc.exists) {
            let invTransferInfoObj = invTransferObjToMListItemFn(payload);

            let newData = mListHelper.updateMasterList(
                invTransferListDoc,
                payload.invTransferUID,
                invTransferInfoObj
            );

            await transaction.set(invTransferListRef, newData);
        } else {
            let invTransferInfoObj = invTransferObjToMListItemFn(payload);

            let newData = mListHelper.addMasterList(
                payload.invTransferUID,
                invTransferInfoObj
            );

            await transaction.set(invTransferListRef, newData);
        }

        await transaction.set(
            counterRef,
            { InvTransferNumber: counterDoc.data().InvTransferNumber + 1 },
            { merge: true }
        );

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyCreated(docNames.INV_TRANSFER)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorDeleting(docNames.INV_TRANSFER)
        );
    }
};

// ===============================================
const updateInvTransferFn = async (userCreds, payload, documentBasis) => {
    // move old metadate into metahistory. ensure meta data history length
    // is 100 or less. will be checked by security rules.
    if (payload.metaHistory.length > 99) payload.metaHistory.shift();

    payload.metaHistory.push(payload.meta);

    // update metadata in payload.
    payload.meta = {
        action: userActions.UPDATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let invTransferRef = db
        .collection(INVENTORY_TRANSFER_COL)
        .doc(payload.invTransferUID);

    let invTransferListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_INVENTORY_TRANSFER_LIST_DOC);

    const transactionFn = async (transaction) => {
        let invTransferListDoc = await transaction.get(invTransferListRef);
        let invTransferDoc = await transaction.get(invTransferRef);

        if (!invTransferDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(
                        docNames.INV_TRANSFER
                    )
                )
            );

        // if there is a change in sfg document from the time
        // it was read prior to editting and now (transaction.get),
        // throw an error.
        if (!validate.noChange(invTransferDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(
                        docNames.INV_TRANSFER
                    )
                )
            );
        }

        let invTransferInfoObj = invTransferObjToMListItemFn(payload);

        let newData = mListHelper.updateMasterList(
            invTransferListDoc,
            payload.invTransferUID,
            invTransferInfoObj
        );

        await transaction.set(invTransferListRef, newData);
        await transaction.set(invTransferRef, payload);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyUpdated(docNames.INV_TRANSFER)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorUpdating(docNames.INV_TRANSFER)
        );
    }
};

// ================================================================
const deleteInvTransferFn = async (userCreds, payload, documentBasis) => {
    if (payload.metaHistory.length > 99) payload.metaHistory.shift();

    payload.metaHistory.push(payload.meta);

    payload.meta = {
        action: userActions.DELETED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let invTransferRef = db
        .collection(INVENTORY_TRANSFER_COL)
        .doc(payload.invTransferUID);

    let invTransferListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_INVENTORY_TRANSFER_LIST_DOC);

    let deletedInvTransferRef = db
        .collection(DELETED_INVENTORY_TRANSFER_COL)
        .doc();

    const transactionFn = async (transaction) => {
        let invTransferDoc = await transaction.get(invTransferRef);

        if (!invTransferDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotDeleteBecauseDocNotExist(
                        docNames.INV_TRANSFER
                    )
                )
            );

        // if there is a change in sfg document from the time
        // it was read prior to deleting and now (transaction.get),
        // throw an error.
        if (!validate.noChange(invTransferDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotDeleteBecauseDocChanged(
                        docNames.INV_TRANSFER
                    )
                )
            );
        }

        let invTransferListDoc = await transaction.get(invTransferListRef);
        await transaction.get(deletedInvTransferRef);

        let newData = mListHelper.deletePropertyFromMasterList(
            invTransferListDoc,
            payload.invTransferUID
        );

        await transaction.delete(invTransferRef);
        await transaction.set(invTransferListRef, newData);
        await transaction.set(deletedInvTransferRef, payload);
        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyDeleted(docNames.INV_TRANSFER)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorDeleting(docNames.INV_TRANSFER)
        );
    }
};

// ================================================================
const statusChangeFn = async (userCreds, invTransferObj, documentBasis) => {
    if (invTransferObj.metaHistory.length > 99)
        invTransferObj.metaHistory.shift();

    invTransferObj.metaHistory.push(invTransferObj.meta);

    // update metadata in invTransferObj.
    invTransferObj.meta = {
        action: userCreds.action,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let invTransferRef = db
        .collection(INVENTORY_TRANSFER_COL)
        .doc(invTransferObj.invTransferUID);
    let invTransferListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_INVENTORY_TRANSFER_LIST_DOC);

    const transactionFn = async (transaction) => {
        //1. perform all transaction gets
        let invTransferDoc = await transaction.get(invTransferRef);
        let invTransferListDoc = await transaction.get(invTransferListRef);

        //2. check invTransferDoc against documentBasis if there is any change.
        if (!invTransferDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(
                        docNames.INV_TRANSFER
                    )
                )
            );

        // ! this does not check meta data.
        if (!validate.noChange(invTransferDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(
                        docNames.INV_TRANSFER
                    )
                )
            );
        }
        // ! meta data check if status of document changed.
        if (invTransferDoc.data().meta.action !== documentBasis.meta.action) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(
                        docNames.INV_TRANSFER
                    )
                )
            );
        }

        let invTransferInfoObj = invTransferObjToMListItemFn(invTransferObj);

        let newData = mListHelper.updateMasterList(
            invTransferListDoc,
            invTransferObj.invTransferUID,
            invTransferInfoObj
        );

        // write to masterlist and pdpo document in firebase.
        await transaction.set(invTransferListRef, newData);
        await transaction.set(invTransferRef, invTransferObj);
        return Promise.resolve('TransactionFn Completed Successfully');
    };
    try {
        await db.runTransaction(transactionFn);
        return responseFn(null, false, null, appMessage.successfulUpdateStatus);
    } catch (e) {
        return responseFn(null, true, e, appMessage.errorUpdatingStatus);
    }
};

const approveInvTransferFn = async (
    userCreds,
    invTransferObj,
    documentBasis
) => {
    if (invTransferObj.metaHistory.length > 99)
        invTransferObj.metaHistory.shift();

    invTransferObj.metaHistory.push(invTransferObj.meta);

    // update metadata in invTransferObj.
    invTransferObj.meta = {
        action: userCreds.action,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // map object for rm, sfg, fg transferQty
    let rmMapTransferQty = invTransferObj.rmArr.reduce((acc, rm) => {
        return (acc = { ...acc, [rm.rmUID]: rm.transferQty });
    }, {});

    let sfgMapTransferQty = invTransferObj.sfgArr.reduce((acc, sfg) => {
        return (acc = { ...acc, [sfg.sfgUID]: sfg.transferQty });
    }, {});
    let fgMapTransferQty = invTransferObj.fgArr.reduce((acc, fg) => {
        return (acc = { ...acc, [fg.fgUID]: fg.transferQty });
    }, {});

    // get references for documents needed. =========================
    let invTransferRef = db
        .collection(INVENTORY_TRANSFER_COL)
        .doc(invTransferObj.invTransferUID);

    // invTransfer master list
    let invTransferListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_INVENTORY_TRANSFER_LIST_DOC);

    // rm master list
    let rmInvListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_INVENTORY_RM_LIST_DOC);

    // sfg master list
    let sfgInvListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_INVENTORY_SFG_LIST_DOC);

    // fg master list
    let fgInvListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_INVENTORY_FG_LIST_DOC);

    // rmArr references
    let rmArrRef = invTransferObj.rmArr.map((rm) => {
        return db.collection(RAW_MATERIALS_COL).doc(rm.rmUID);
    });

    // sfgArr references
    let sfgArrRef = invTransferObj.sfgArr.map((sfg) => {
        return db.collection(SEMI_FGS_COL).doc(sfg.sfgUID);
    });

    // fgArr references
    let fgArrRef = invTransferObj.fgArr.map((fg) => {
        return db.collection(FINISHED_GOODS_COL).doc(fg.fgUID);
    });

    // variables for error handling
    let listOfInsufficientItems = [];
    let existError = false;
    let balanceError = false;

    const transactionFn = async (transaction) => {
        // =============================================================
        // START TRANSACTION GET =================================
        let invTransferDoc = await transaction.get(invTransferRef);
        let invTransferListDoc = await transaction.get(invTransferListRef);
        let rmInvListDoc = await transaction.get(rmInvListRef);
        let sfgInvListDoc = await transaction.get(sfgInvListRef);
        let fgInvListDoc = await transaction.get(fgInvListRef);

        let rmArrDocs = rmArrRef.map(
            async (rmRef) => await transaction.get(rmRef)
        );
        try {
            rmArrDocs = await Promise.all(rmArrDocs);
        } catch (e) {
            return Promise.reject(
                formatCustomErrorObj(
                    900,
                    appMessage.prommiseAllError('dbopsInvTransfers', 'rmArr')
                )
            );
        }

        let sfgArrDocs = sfgArrRef.map(
            async (sfgRef) => await transaction.get(sfgRef)
        );
        try {
            sfgArrDocs = await Promise.all(sfgArrDocs);
        } catch (e) {
            return Promise.reject(
                formatCustomErrorObj(
                    900,
                    appMessage.prommiseAllError('dbopsInvTransfers', 'sfgArr')
                )
            );
        }

        let fgArrDocs = fgArrRef.map(
            async (fgRef) => await transaction.get(fgRef)
        );
        try {
            fgArrDocs = await Promise.all(fgArrDocs);
        } catch (e) {
            return Promise.reject(
                formatCustomErrorObj(
                    900,
                    appMessage.prommiseAllError('dbopsInvTransfers', 'fgArr')
                )
            );
        }
        // END TRANSACTION GET =============================================

        // START CHECK FOR CHANGE AND EXISTENCE =================================
        if (!invTransferDoc.exists) {
            return Promise.reject(
                //! this should never run.
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(
                        docNames.INV_TRANSFER
                    )
                )
            );
        }
        // ! this does not check meta data.
        if (!validate.noChange(invTransferDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(
                        docNames.INV_TRANSFER
                    )
                )
            );
        }
        // ! meta data check if status of document changed.
        if (invTransferDoc.data().meta.action !== documentBasis.meta.action) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(
                        docNames.INV_TRANSFER
                    )
                )
            );
        }

        // check each rm, sfg, fg for existence.
        // low possibility but there may be a race condition that rm, sfg, fg may
        // have been deleted before transaction completes.

        rmArrDocs.forEach((rm) => {
            if (!rm.exists) existError = true;
        });
        sfgArrDocs.forEach((sfg) => {
            if (!sfg.exists) existError = true;
        });
        fgArrDocs.forEach((fg) => {
            if (!fg.exists) existError = true;
        });

        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopsInvTransfer',
                        docNames.INV_TRANSFER,
                        'RM, SFG, or FG'
                    )
                )
            );
        }
        // END CHECK FOR CHANGE AND EXISTENCE ==================================

        // CALCULATE NEW ACTUAL AND PROJECTED QTYS OF RM, SFG, FG. ===============

        // ====================================
        // if transferDirection = 'toCustomer', additional logic needed to subtract
        // transferQty instead of add.
        const transferQtyAddOrSubtractFn = (transferDirection, transferQty) => {
            if (transferDirection === 'toCustomer') return transferQty * -1;
            return transferQty;
        };

        // ====================================

        let rmMapNewQtys = {};
        let sfgMapNewQtys = {};
        let fgMapNewQtys = {};

        let updatedRmArrDocs = rmArrDocs.map((rm) => {
            let rawMat = rm.data();

            rmMapNewQtys = {
                ...rmMapNewQtys,
                [rawMat.rmUID]: { rmActualQty: 0, rmProjectedQty: 0 },
            };

            rmMapNewQtys[rawMat.rmUID].rmActualQty = saveWithFiveDecimals(
                rawMat.rmActualQty +
                    transferQtyAddOrSubtractFn(
                        invTransferObj.transferDirection,
                        rmMapTransferQty[rawMat.rmUID]
                    )
            );

            rmMapNewQtys[rawMat.rmUID].rmProjectedQty = saveWithFiveDecimals(
                rawMat.rmProjectedQty +
                    transferQtyAddOrSubtractFn(
                        invTransferObj.transferDirection,
                        rmMapTransferQty[rawMat.rmUID]
                    )
            );

            // set new actual and projected qty of each item.
            rawMat.rmActualQty = rmMapNewQtys[rawMat.rmUID].rmActualQty;
            rawMat.rmProjectedQty = rmMapNewQtys[rawMat.rmUID].rmProjectedQty;

            // check for enough actualQty
            if (rmMapNewQtys[rawMat.rmUID].rmActualQty < 0) {
                balanceError = true;
                listOfInsufficientItems.push(rawMat.rmUID);
            }

            return rawMat;
        });

        let updatedSfgArrDocs = sfgArrDocs.map((sfg) => {
            let semiFG = sfg.data();

            sfgMapNewQtys = {
                ...sfgMapNewQtys,
                [semiFG.sfgUID]: { sfgActualQty: 0, sfgProjectedQty: 0 },
            };

            sfgMapNewQtys[semiFG.sfgUID].sfgActualQty = saveWithFiveDecimals(
                semiFG.sfgActualQty +
                    transferQtyAddOrSubtractFn(
                        invTransferObj.transferDirection,
                        sfgMapTransferQty[semiFG.sfgUID]
                    )
            );

            sfgMapNewQtys[semiFG.sfgUID].sfgProjectedQty = saveWithFiveDecimals(
                semiFG.sfgProjectedQty +
                    transferQtyAddOrSubtractFn(
                        invTransferObj.transferDirection,
                        sfgMapTransferQty[semiFG.sfgUID]
                    )
            );

            // set new actual and projected qty of each item.
            semiFG.sfgActualQty = sfgMapNewQtys[semiFG.sfgUID].sfgActualQty;
            semiFG.sfgProjectedQty =
                sfgMapNewQtys[semiFG.sfgUID].sfgProjectedQty;

            // check for enough actualQty
            if (sfgMapNewQtys[semiFG.sfgUID].sfgActualQty < 0) {
                balanceError = true;
                listOfInsufficientItems.push(semiFG.sfgUID);
            }

            return semiFG;
        });

        let updatedFgArrDocs = fgArrDocs.map((fg) => {
            let finishedGood = fg.data();

            fgMapNewQtys = {
                ...fgMapNewQtys,
                [finishedGood.fgUID]: { fgActualQty: 0, fgProjectedQty: 0 },
            };

            fgMapNewQtys[finishedGood.fgUID].fgActualQty = saveWithFiveDecimals(
                finishedGood.fgActualQty +
                    transferQtyAddOrSubtractFn(
                        invTransferObj.transferDirection,
                        fgMapTransferQty[finishedGood.fgUID]
                    )
            );

            fgMapNewQtys[
                finishedGood.fgUID
            ].fgProjectedQty = saveWithFiveDecimals(
                finishedGood.fgProjectedQty +
                    transferQtyAddOrSubtractFn(
                        invTransferObj.transferDirection,
                        fgMapTransferQty[finishedGood.fgUID]
                    )
            );

            // set new actual and projected qty of each item.
            finishedGood.fgActualQty =
                fgMapNewQtys[finishedGood.fgUID].fgActualQty;
            finishedGood.fgProjectedQty =
                fgMapNewQtys[finishedGood.fgUID].fgProjectedQty;

            // check for enough actualQty
            if (fgMapNewQtys[finishedGood.fgUID].fgActualQty < 0) {
                balanceError = true;
                listOfInsufficientItems.push(finishedGood.fgUID);
            }

            return finishedGood;
        });

        if (balanceError) {
            return Promise.reject(
                formatCustomErrorObj(800, `Insufficient actual Qty.`)
            );
        }
        // END CALCULATE NEW ACTUAL AND PROJECTED QTYS OF RM, SFG, FG. ===============

        // START TRANSACTION SET ===============================================

        // set master list docs
        let tempRmInventoryList = rmInvListDoc.data();
        let tempSfgInventoryList = sfgInvListDoc.data();
        let tempFgInventoryList = fgInvListDoc.data();

        updatedRmArrDocs.forEach((rm) => {
            let rmInvInfoObj = rmInvObjToMListItemFn(rm);

            tempRmInventoryList = mListHelper.updateTempMList(
                tempRmInventoryList,
                rm.rmUID,
                rmInvInfoObj
            );
        });

        updatedSfgArrDocs.forEach((sfg) => {
            let sfgInvInfoObj = sfgInvObjToMListItemFn(sfg);

            tempSfgInventoryList = mListHelper.updateTempMList(
                tempSfgInventoryList,
                sfg.sfgUID,
                sfgInvInfoObj
            );
        });

        updatedFgArrDocs.forEach((fg) => {
            let fgInvInfoObj = fgInvObjToMListItemFn(fg);

            tempFgInventoryList = mListHelper.updateTempMList(
                tempFgInventoryList,
                fg.fgUID,
                fgInvInfoObj
            );
        });

        await transaction.set(rmInvListRef, tempRmInventoryList);
        await transaction.set(sfgInvListRef, tempSfgInventoryList);
        await transaction.set(fgInvListRef, tempFgInventoryList);

        let invTransferInfoObj = invTransferObjToMListItemFn(invTransferObj);

        let newData = mListHelper.updateMasterList(
            invTransferListDoc,
            invTransferObj.invTransferUID,
            invTransferInfoObj
        );

        await transaction.set(invTransferListRef, newData);

        // individual docs
        updatedRmArrDocs.forEach(async (rm, index) => {
            await transaction.set(rmArrRef[index], rm);
        });

        updatedSfgArrDocs.forEach(async (sfg, index) => {
            await transaction.set(sfgArrRef[index], sfg);
        });

        updatedFgArrDocs.forEach(async (fg, index) => {
            await transaction.set(fgArrRef[index], fg);
        });

        await transaction.set(invTransferRef, invTransferObj);

        return Promise.resolve('TransactionFn Completed Successfully');

        // END TRANSACTION SET =================================================
    };
    try {
        await db.runTransaction(transactionFn);
        return responseFn(null, false, null, appMessage.successfulUpdateStatus);
    } catch (e) {
        let customeErrorObj = balanceError
            ? formatAlertErrorObj(
                  'Insufficient Actual Qty',
                  listOfInsufficientItems
              )
            : null;

        return responseFn(
            customeErrorObj,
            true,
            e,
            appMessage.errorUpdatingStatus
        );
    }
};

export default {
    getInvTransferFn,
    createInvTransferFn,
    updateInvTransferFn,
    deleteInvTransferFn,
    statusChangeFn,
    approveInvTransferFn,
};
