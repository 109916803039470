import React, { useState, useContext } from 'react';
import { dbopsSDCRs } from '../../firebase_dbops';
import { MasterListContext, AuthContext } from '../../contexts';
import { InputIcon, ListView2 } from '../../components';
import VRCRItem from '../../components/CollectionItems/VRCRItem/VRCRItem';
import { displayDate } from '../../lib/util';
import { permissions, lengths } from '../../lib/constants';
import { userActions } from '../../lib/constants';

import '../PagesCommon.css';

import SDCRsForm from './SDCRsForm';

const view = {
    LIST_VIEW: 'listView',
    CREATE_COLLECTION_RECEIPT: 'creatCR',
    EDIT_COLLECTION_RECEIPT: 'editCR',
    DELETE_COLLECTION_RECEIPT: 'deleteCR',
    COLLECTION_RECEIPT_PROFILE: 'crProfile',
};

export default (props) => {
    let { setIsLoading, setAlertErrorMsg, setAlertMessage } = props;

    let { masterSdcrList } = useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);

    let [formView, setFormView] = useState(view.LIST_VIEW);
    let [currentDocument, setCurrentDocument] = useState('');
    let [searchText, setSearchText] = useState('');

    // functions to change views.
    const createCollectionReceiptBtnFn = () =>
        setFormView(view.CREATE_COLLECTION_RECEIPT);
    const gotoListViewFn = () => setFormView(view.LIST_VIEW);
    const gotoEditViewFn = () => setFormView(view.EDIT_COLLECTION_RECEIPT);
    const gotoDeleteViewFn = () => setFormView(view.DELETE_COLLECTION_RECEIPT);
    const gotoPoProfileViewFn = () =>
        setFormView(view.COLLECTION_RECEIPT_PROFILE);

    // function to get sdcr document.
    const getDocumentFromDBFn = async (docID) => {
        setIsLoading(true);
        let response = await dbopsSDCRs.getCrFn(docID);
        if (response.error)
            setAlertErrorMsg(response.errorObj.message || response.message);
        else {
            setCurrentDocument(response.data);
            gotoPoProfileViewFn();
        }
        setIsLoading(false);
    };

    const createCollectionReceiptFn = async (crObj) => {
        // spinner on.
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        // write to db.
        let response = await dbopsSDCRs.createCrFn(userCreds, crObj);
        if (response.error) setAlertErrorMsg(response.errorObj.message);
        else setAlertMessage(response.message);

        gotoListViewFn();

        // spinner off.
        setIsLoading(false);
    };

    const updateCollectionReceiptFn = async (crObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsSDCRs.updateCrFn(
            userCreds,
            crObj,
            documentBasis
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);
            // if error is because document has already been deleted,
            // go back to list view.
            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
            // if error is because document has since changed, just continue
            // flow of the program.
        } else setAlertMessage(response.message);

        // this get document ensures state in FE state (currentDocument) is updated.
        gotoListViewFn();
        await getDocumentFromDBFn(crObj.sdcrUID);

        setIsLoading(false);
    };

    const deleteCollectionReceiptFn = async (crObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsSDCRs.deleteCrFn(
            userCreds,
            crObj,
            documentBasis
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);

            // if error is because document has changed before changes
            // could be applied, get latest version of sdcr and
            // go back to sdcr profile view.
            if (response.errorObj.code === 1001) {
                gotoListViewFn();
                await getDocumentFromDBFn(crObj.sdcrUID);
                setIsLoading(false);
                return;
            }
            // all other errors can continue flow of program.
        } else setAlertMessage(response.message);

        gotoListViewFn();

        setIsLoading(false);
    };

    const statusChangeFn = async (userActionPassed, crObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            action: userActionPassed,
            uid: authUser.uid,
            email: authUser.email,
        };

        let dbFn = dbopsSDCRs.statusChangeFn;
        if (
            userActionPassed === userActions.APPROVED ||
            userActionPassed === userActions.MARK_AS_COLLECTED
        ) {
            dbFn = dbopsSDCRs.approveSdcrFn;
        }

        let response = await dbFn(userCreds, crObj, documentBasis);
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);

            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
            // if error is because document has since changed, just continue
            // flow of the program.
        } else setAlertMessage(response.message);

        // this get document ensures state in FE state (currentDocument) is updated.
        gotoListViewFn();
        await getDocumentFromDBFn(crObj.sdcrUID);

        setIsLoading(false);
    };

    // filter function =====================================
    let filteredVoucherReceiptList = masterSdcrList.filter((sdcr) => {
        return (
            sdcr.sdcrUID.toUpperCase().includes(searchText.toUpperCase()) ||
            displayDate(sdcr.pdvrDate)
                .toUpperCase()
                .includes(searchText.toUpperCase()) ||
            sdcr.status.toUpperCase().includes(searchText.toUpperCase()) ||
            sdcr.customerUID.toUpperCase().includes(searchText.toUpperCase())
        );
    });

    let addSdcrBtnFn = (
        <button
            className='btn btn-primary'
            onClick={createCollectionReceiptBtnFn}>
            Create Counter Receipt
        </button>
    );

    if (
        !authUser.permissions.includes(permissions.SDCR_REQUEST) ||
        masterSdcrList.length >= lengths.masterPOSDeptsLimits ||
        !authUser.subscriptionStatusIsActive
    ) {
        addSdcrBtnFn = null;
    }

    let topSectionContent = (
        <section className='top-section'>
            <InputIcon
                //! id='suppliers-search-input'
                type='text'
                placeholder='Search'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                icon={
                    <i
                        className='ms-Icon ms-Icon--Search'
                        aria-hidden='true'></i>
                }
            />
            {addSdcrBtnFn}
        </section>
    );

    let mainSectionContent = (
        <ListView2
            getDocumentFromDBFn={getDocumentFromDBFn}
            list={filteredVoucherReceiptList}
            keyProperty={'sdcrUID'}
            CollectionItemComponent={VRCRItem}
        />
    );

    if (formView !== view.LIST_VIEW) topSectionContent = null;

    if (formView === view.CREATE_COLLECTION_RECEIPT) {
        mainSectionContent = (
            <SDCRsForm
                purpose='ADD'
                backCancelBtnFn={gotoListViewFn}
                createCrFn={createCollectionReceiptFn}
                setIsLoading={setIsLoading}
                setAlertErrorMsg={setAlertErrorMsg}
            />
        );
    }

    if (formView === view.EDIT_COLLECTION_RECEIPT) {
        mainSectionContent = (
            <SDCRsForm
                purpose='EDIT'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoPoProfileViewFn}
                updateCrFn={updateCollectionReceiptFn}
                setAlertErrorMsg={setAlertErrorMsg}
                setIsLoading={setIsLoading}
            />
        );
    }

    if (formView === view.DELETE_COLLECTION_RECEIPT) {
        mainSectionContent = (
            <SDCRsForm
                purpose='DELETE'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoPoProfileViewFn}
                deleteCrFn={deleteCollectionReceiptFn}
                setAlertErrorMsg={setAlertErrorMsg}
                setIsLoading={setIsLoading}
            />
        );
    }

    if (formView === view.COLLECTION_RECEIPT_PROFILE) {
        mainSectionContent = (
            <SDCRsForm
                purpose='VIEW'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoListViewFn}
                gotoEditViewFn={gotoEditViewFn}
                gotoDeleteViewFn={gotoDeleteViewFn}
                setIsLoading={setIsLoading}
                statusChangeFn={statusChangeFn}
                setAlertErrorMsg={setAlertErrorMsg}
            />
        );
    }

    return (
        <div className='content-layout'>
            {topSectionContent}

            <section className='main-section'>{mainSectionContent}</section>
        </div>
    );
};
