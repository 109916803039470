import React from 'react';
import { InputDS } from '../../components';
import util, { saveWithTwoDecimalOnly } from '../../lib/util';

import '../PagesCommon.css';
import './SDPOsPage.css';

export default (props) => {
    let {
        itemObj,
        itemIndex,
        unitProperty,
        priceProperty,
        errorProperty,
        orderProperty,
        arrName,
        uidProperty,

        updateOrdereQtyProperty,

        trashIcon,
    } = props;

    // console.log(itemObj);

    return (
        <div className='span-2-columns-container' key={itemIndex}>
            <div className='view-table-row-si'>
                <div className='cell space-between'>
                    <div className='flex-row'>
                        <span className='mobile-label m-right-15'>
                            {`${itemIndex + 1}.`}
                        </span>
                        <span className='desktop-view m-lr-15'>
                            {`${itemIndex + 1}.`}
                        </span>
                        <span className=''> {itemObj[uidProperty]}</span>
                    </div>

                    <div className='mobile-view icon-size'>{trashIcon}</div>
                </div>

                <div className='cell'>
                    <div className='width25'>
                        <span className='mobile-label'>Ordered Qty:</span>{' '}
                        <div className='m-right-15'>
                            <InputDS
                                type='number'
                                initialValue={itemObj[orderProperty]}
                                getInputValue={(val) =>
                                    updateOrdereQtyProperty(
                                        val,
                                        arrName,
                                        itemIndex,
                                        orderProperty
                                    )
                                }
                                errorMessage={itemObj[errorProperty]}></InputDS>
                        </div>
                    </div>
                    <span className='width25'>
                        <span className='mobile-label'>Unit:</span>{' '}
                        <span className=''> {itemObj[unitProperty]}</span>
                    </span>
                    <span className='width25'>
                        <span className='mobile-label'>Unit Price:</span>{' '}
                        <span className=''>
                            {' '}
                            {util.displayMoney(itemObj[priceProperty])}
                        </span>
                    </span>
                    <span className='width25'>
                        <span className='mobile-label'>Item Total:</span>{' '}
                        <span className=''>
                            {' '}
                            {util.displayMoney(
                                saveWithTwoDecimalOnly(
                                    itemObj[orderProperty] *
                                        itemObj[priceProperty]
                                )
                            )}
                        </span>
                    </span>

                    <div className='desktop-view icon-size m-lr-5'>
                        {trashIcon}
                    </div>
                </div>
            </div>
        </div>
    );
};
