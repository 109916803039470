import React from 'react';

export default (props) => {
    let { cancelFn, saveFn } = props;

    let buttonSection = (
        <section className='form-button-section'>
            <button className='goto-left btn btn-primary' onClick={cancelFn}>
                Cancel
            </button>

            <button className='m-left-15 btn btn-danger' onClick={saveFn}>
                Save Override
            </button>
        </section>
    );

    return buttonSection;
};
