import React, { useState, useContext } from 'react';
import { dbopsSDPOs } from '../../firebase_dbops';
import { MasterListContext, AuthContext } from '../../contexts';
import { InputIcon, ListView2 } from '../../components';
import PDSDItem from '../../components/CollectionItems/PDSDItem/PDSDItem';
import { permissions, userActions, lengths } from '../../lib/constants';
import { displayDate } from '../../lib/util';

import '../PagesCommon.css';
import './SDPOsPage.css';

import SDPOsForm from './SDPOsForm';

const view = {
    LIST_VIEW: 'listView',
    CREATE_SDPO: 'createSDPO',
    EDIT_SDPO: 'editSDPO',
    DELETE_SDPO: 'deleteSDPO',
    SDPO_PROFILE: 'SdpoProfile',
    OVERRIDE_SDPO: 'overrideSDPO',
};

export default (props) => {
    let { setIsLoading, setAlertErrorMsg, setAlertMessage } = props;

    let { masterSdpoList } = useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);

    let [formView, setFormView] = useState(view.LIST_VIEW);
    let [currentDocument, setCurrentDocument] = useState('');
    let [searchText, setSearchText] = useState('');

    // functions to change views.
    const createSDPOBtnFn = () => setFormView(view.CREATE_SDPO);
    const gotoListViewFn = () => setFormView(view.LIST_VIEW);
    const gotoEditViewFn = () => setFormView(view.EDIT_SDPO);
    const gotoDeleteViewFn = () => setFormView(view.DELETE_SDPO);
    const gotoSDPOProfileViewFn = () => setFormView(view.SDPO_PROFILE);
    const gotoOverRideViewFn = () => setFormView(view.OVERRIDE_SDPO);

    // function to get sdpoObj document.
    const getDocumentFromDBFn = async (docID) => {
        setIsLoading(true);
        let response = await dbopsSDPOs.getSdpoFn(docID);
        if (response.error)
            setAlertErrorMsg(response.errorObj.message || response.message);
        else {
            setCurrentDocument(response.data);
            gotoSDPOProfileViewFn();
        }
        setIsLoading(false);
    };

    // function to create Supplier
    const createSdpoFn = async (sdpoObj, quoteBasis) => {
        // spinner on.
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        // write to db.
        let dbFn = dbopsSDPOs.createSdpoFn;
        if (sdpoObj.isQuote) dbFn = dbopsSDPOs.createQuoteSdpoFn;

        let response = await dbFn(userCreds, sdpoObj, quoteBasis);
        if (response.error) setAlertErrorMsg(response.errorObj.message);
        else setAlertMessage(response.message);

        gotoListViewFn();

        // spinner off.
        setIsLoading(false);
    };

    // function to update sdpoObj.
    const updateSdpoFn = async (sdpoObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsSDPOs.updateSdpoFn(
            userCreds,
            sdpoObj,
            documentBasis
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);
            // if error is because document has already been deleted,
            // go back to list view.
            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
            // if error is because document has since changed, just continue
            // flow of the program.
        } else setAlertMessage(response.message);

        // this get document ensures state in FE state (currentDocument) is updated.
        gotoListViewFn();
        await getDocumentFromDBFn(sdpoObj.sdpoUID);

        setIsLoading(false);
    };

    // function to delete sdpoObj.
    const deleteSdpoFn = async (sdpoObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsSDPOs.deleteSdpoFn(
            userCreds,
            sdpoObj,
            documentBasis
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);

            // if error is because document has changed before changes
            // could be applied, get latest version of sdpoObj and
            // go back to sdpoObj profile view.
            if (response.errorObj.code === 1001) {
                gotoListViewFn();
                await getDocumentFromDBFn(sdpoObj.sdpoUID);
                setIsLoading(false); // redundant
                return;
            }
            // all other errors can continue flow of program.
        } else setAlertMessage(response.message);

        gotoListViewFn();

        setIsLoading(false);
    };

    // function for status change of document.
    const statusChangeFn = async (userActionPassed, sdpoObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            action: userActionPassed,
            uid: authUser.uid,
            email: authUser.email,
        };

        let dbFn = dbopsSDPOs.statusChangeFn;
        if (userActionPassed === userActions.APPROVED) {
            dbFn = dbopsSDPOs.approveSdpoFn;
        }

        let response = await dbFn(userCreds, sdpoObj, documentBasis);
        if (response.error) {
            // meaning: response.data contains detailed error obj.
            if (response.data) setAlertErrorMsg(response.data);
            else setAlertErrorMsg(response.errorObj.message);

            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
        } else setAlertMessage(response.message);

        gotoListViewFn();
        await getDocumentFromDBFn(sdpoObj.sdpoUID);

        setIsLoading(false);
    };

    const overRideSdpoFn = async (sdpoObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsSDPOs.overRideSdpoFn(
            userCreds,
            sdpoObj,
            documentBasis
        );

        if (response.error) {
            // meaning: response.data contains detailed error obj.
            if (response.data) setAlertErrorMsg(response.data);
            else setAlertErrorMsg(response.errorObj.message);

            // if error is because document has already been deleted,
            // go back to list view.
            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
            // if error is because document has since changed, just continue
            // flow of the program.
        } else setAlertMessage(response.message);

        // this get document ensures state in FE state (currentDocument) is updated.
        gotoListViewFn();
        await getDocumentFromDBFn(sdpoObj.sdpoUID);

        setIsLoading(false);
    };

    // filter function =====================================
    let filteredSdpoList = masterSdpoList.filter((sdpoObj) => {
        return (
            sdpoObj.sdpoUID.toUpperCase().includes(searchText.toUpperCase()) ||
            displayDate(sdpoObj.sdpoDate)
                .toUpperCase()
                .includes(searchText.toUpperCase()) ||
            sdpoObj.status.toUpperCase().includes(searchText.toUpperCase()) ||
            sdpoObj.customerUID.toUpperCase().includes(searchText.toUpperCase())
        );
    });

    let addSdpoBtn = (
        <button className='btn btn-primary' onClick={createSDPOBtnFn}>
            Create Sales Order
        </button>
    );

    if (
        !authUser.permissions.includes(permissions.SDPO_REQUEST) ||
        masterSdpoList.length >= lengths.masterPOSDeptsLimits ||
        !authUser.subscriptionStatusIsActive
    ) {
        addSdpoBtn = null;
    }

    let topSectionContent = (
        <section className='top-section'>
            <InputIcon
                id='suppliers-search-input'
                type='text'
                placeholder='Search'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                icon={
                    <i
                        className='ms-Icon ms-Icon--Search'
                        aria-hidden='true'></i>
                }
            />
            {addSdpoBtn}
        </section>
    );

    // console.log(masterSdpoList);
    let mainSectionContent = (
        <ListView2
            getDocumentFromDBFn={getDocumentFromDBFn}
            list={filteredSdpoList}
            keyProperty={'sdpoUID'}
            CollectionItemComponent={PDSDItem}
        />
    );

    if (formView !== view.LIST_VIEW) topSectionContent = null;

    if (formView === view.CREATE_SDPO) {
        mainSectionContent = (
            <SDPOsForm
                purpose='ADD'
                backCancelBtnFn={gotoListViewFn}
                createSdpoFn={createSdpoFn}
                setIsLoading={setIsLoading}
                setAlertErrorMsg={setAlertErrorMsg}
            />
        );
    }

    if (formView === view.EDIT_SDPO) {
        mainSectionContent = (
            <SDPOsForm
                purpose='EDIT'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoSDPOProfileViewFn}
                updateSdpoFn={updateSdpoFn}
                createSdpoFn={createSdpoFn}
                setIsLoading={setIsLoading}
                setAlertErrorMsg={setAlertErrorMsg}
            />
        );
    }

    if (formView === view.DELETE_SDPO) {
        mainSectionContent = (
            <SDPOsForm
                purpose='DELETE'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoSDPOProfileViewFn}
                deleteSdpoFn={deleteSdpoFn}
                setIsLoading={setIsLoading}
                setAlertErrorMsg={setAlertErrorMsg}
            />
        );
    }

    if (formView === view.SDPO_PROFILE) {
        mainSectionContent = (
            <SDPOsForm
                purpose='VIEW'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoListViewFn}
                gotoEditViewFn={gotoEditViewFn}
                gotoDeleteViewFn={gotoDeleteViewFn}
                gotoOverRideViewFn={gotoOverRideViewFn}
                statusChangeFn={statusChangeFn}
            />
        );
    }

    if (formView === view.OVERRIDE_SDPO) {
        mainSectionContent = (
            <SDPOsForm
                purpose='OVERRIDE'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoSDPOProfileViewFn}
                overRideSdpoFn={overRideSdpoFn}
                setIsLoading={setIsLoading}
                setAlertErrorMsg={setAlertErrorMsg}
            />
        );
    }

    return (
        <div className='content-layout'>
            {topSectionContent}

            <section className='main-section'>{mainSectionContent}</section>
        </div>
    );
};
