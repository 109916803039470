import React from 'react';
import { InputDS, HelpTip } from '../../components';
import tooltips from '../../lib/tooltips';
import '../PagesCommon.css';
import './ProductionDeliveredPage.css';
import { displayReadableNumber } from '../../lib/util';

const GroupedRM = (props) => {
    let {
        rmArr,
        sfgUID,
        sfgIndex,
        updateRmActualUsedQtyFn,
        prodOrderIndex,
    } = props;

    let rms = rmArr.map((rm) => {
        let overrideInfo = '';
        if (rm.rmOverrideQty !== 0) {
            overrideInfo =
                rm.rmOverrideQty < 0
                    ? `(${displayReadableNumber(rm.rmOverrideQty)})`
                    : `(+${displayReadableNumber(rm.rmOverrideQty)})`;
        }

        return (
            <div
                className='span-2-columns-container'
                key={`${sfgUID}${rm.rmUID}`}
            >
                <div className='view-table-row grid-row-40-60'>
                    <div className='cell'>
                        <div className='width16'>
                            <span className='mobile-label'>Grp:</span>{' '}
                            <span className='desktop-view m-left-15'>
                                {' '}
                                {rm.group}
                            </span>
                            <span className='mobile-label'> {rm.group}</span>
                        </div>
                        <div className=''>
                            <span className='mobile-label'>RM:</span>{' '}
                            <span> {rm.rmUID}</span>
                        </div>
                    </div>
                    <div className='cell'>
                        <div className='width33'>
                            <span className='mobile-label'>
                                Projected Qty (Planned):
                            </span>{' '}
                            <span>
                                {' '}
                                {displayReadableNumber(rm.rmProjectedUseQty)}
                                <span className='m-left-15'>{rm.rmUnit}</span>
                            </span>
                        </div>
                        <div className='width33'>
                            <span className='mobile-label'>Balance Qty:</span>{' '}
                            <span>
                                {displayReadableNumber(rm.rmBalanceQty)}{' '}
                                {overrideInfo}
                                <span className='m-left-15'>{rm.rmUnit}</span>
                            </span>
                        </div>
                        <div className='width33'>
                            {' '}
                            <span className='mobile-label'>
                                Actual Qty (Used):
                            </span>{' '}
                            <div className='flex-row'>
                                <span className='width66'>
                                    <InputDS
                                        type='number'
                                        initialValue={
                                            rm.rmUsedQtyForThisSfgProduced
                                        }
                                        getInputValue={(val) =>
                                            updateRmActualUsedQtyFn(
                                                val,
                                                prodOrderIndex,
                                                sfgIndex,
                                                rm.tempRmIndex
                                            )
                                        }
                                        errorMessage={
                                            rm.rmUsedQtyForThisSfgProducedError
                                        }
                                    ></InputDS>
                                </span>
                                <span className='m-left-15 width33'>
                                    {rm.rmUnit}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    return <div className='span-2-columns-container'>{rms}</div>;
};

export default (props) => {
    let {
        prodOrderIndex,
        sfgObj,
        sfgIndex,
        updateRmActualUsedQtyFn,
        updateSfgProducedQtyFn,
    } = props;

    let groupedRmMap = {};

    sfgObj.rmArr.forEach((rm, rmIndex) => {
        rm.tempRmIndex = rmIndex;
        if (groupedRmMap[rm.group] === undefined) {
            groupedRmMap[rm.group] = [];
            groupedRmMap[rm.group].push(rm);
        } else groupedRmMap[rm.group].push(rm);
    });

    let listOfRms = [];

    for (let gkey in groupedRmMap) {
        let component = (
            <GroupedRM
                key={`${sfgObj.sfgUID}${gkey}`}
                sfgUID={sfgObj.sfgUID}
                sfgIndex={sfgIndex}
                rmArr={groupedRmMap[gkey]}
                updateRmActualUsedQtyFn={updateRmActualUsedQtyFn}
                prodOrderIndex={prodOrderIndex}
            />
        );
        listOfRms.push(component);
    }

    let overrideInfo = '';
    if (sfgObj.sfgOverrideQty !== 0) {
        overrideInfo =
            sfgObj.sfgOverrideQty < 0
                ? `(${displayReadableNumber(sfgObj.sfgOverrideQty)})`
                : `(+${displayReadableNumber(sfgObj.sfgOverrideQty)})`;
    }

    return (
        <div
            className='span-2-columns-container show-border pad-around-10 m-bottom-10'
            key={`${sfgObj.sfgUID}${sfgIndex}`}
        >
            <div className='view-table-row grid-row-40-60 bg-grey-l2 pad-around-10'>
                <div className='cell'>
                    <span className='m-right-5'>{`${sfgIndex + 1}. `}</span>
                    <span>{sfgObj.sfgUID}</span>
                </div>
                <div className='cell'>
                    <span className='width33 flex-column-to-row'>
                        <span className='font-size-13 text-bold m-right-5'>
                            Ordered Qty
                        </span>{' '}
                        <span>
                            {displayReadableNumber(sfgObj.sfgOrderedQty)}{' '}
                            <span className='font-size-13'>
                                {sfgObj.sfgUnit}
                            </span>
                        </span>
                    </span>
                    <span className='width33 flex-column-to-row'>
                        <span className='font-size-13 text-bold m-right-5'>
                            Balance Qty
                        </span>{' '}
                        <span>
                            {displayReadableNumber(sfgObj.sfgBalanceQty)}{' '}
                            {overrideInfo}{' '}
                            <span className='font-size-13'>
                                {sfgObj.sfgUnit}
                            </span>
                        </span>
                    </span>
                    <span className='width33 flex-column-to-row'>
                        <span className='font-size-13 text-bold m-right-5'>
                            Produced Qty
                            <HelpTip
                                content={tooltips.prodOrderSfgProducedQty}
                            />
                        </span>{' '}
                        <span className='flex-row'>
                            <span className='width66'>
                                <InputDS
                                    type='number'
                                    initialValue={
                                        sfgObj.sfgProducedQtyForThisProduction
                                    }
                                    getInputValue={(val) =>
                                        updateSfgProducedQtyFn(
                                            val,
                                            prodOrderIndex,
                                            sfgIndex
                                        )
                                    }
                                    errorMessage={
                                        sfgObj.sfgProducedQtyForThisProductionError
                                    }
                                ></InputDS>
                            </span>

                            <span className='font-size-13 m-left-5'>
                                {sfgObj.sfgUnit}
                            </span>
                        </span>
                    </span>
                </div>
            </div>

            <div className='span-2-columns-container'>
                <div className='view-table-heading grid-row-40-60'>
                    <div className='cell'>
                        <span className='width16'>
                            <span className='m-left-15'>Grp</span>
                        </span>
                        <span>RM Items</span>
                    </div>
                    <div className='cell'>
                        <span className='width33'>Projected Qty (Planned)</span>
                        <span className='width33'>Balance Qty</span>
                        <span className='width33'>
                            Actual Qty (Used){' '}
                            <HelpTip
                                content={tooltips.prodOrderRmActualUsedQty}
                            />
                        </span>
                    </div>
                </div>
                {listOfRms}
            </div>
        </div>
    );
};
