import React from 'react';

import '../PagesCommon.css';
import './PDSIsPage.css';
import { displayReadableNumber, displayMoney } from '../../lib/util';

export default (props) => {
    let { drObj, trashIcon, drIndex } = props;

    let posOfDr = drObj.pdpoArr.map((poObj, poIndex) => {
        let rmsOfPo = poObj.rmArr.map((rm, rmIndex) => {
            return (
                <div
                    className='span-2-columns-container'
                    key={`${rm.rmUID} ${poObj.pdpoUID}`}>
                    <div className='view-table-row'>
                        <div className='cell'>
                            <span className='mobile-label m-right-15'>
                                {`${rmIndex + 1}.`}
                            </span>
                            <span className='desktop-view m-lr-15'>
                                {`${rmIndex + 1}.`}
                            </span>{' '}
                            <span>{rm.rmUID}</span>
                        </div>

                        <div className='cell'>
                            <div className='width25'>
                                <span className='mobile-label'>
                                    Delivered Qty:
                                </span>{' '}
                                <span>
                                    {displayReadableNumber(
                                        rm.drDeliveredQtyForRm
                                    )}
                                </span>
                            </div>
                            <div className='width25'>
                                <span className='mobile-label'>Unit:</span>{' '}
                                <span>{rm.rmUnit}</span>
                            </div>
                            <div className='width25'>
                                <span className='mobile-label'>Cost:</span>{' '}
                                <span>{displayMoney(rm.rmCost)}</span>
                            </div>
                            <div className='width25'>
                                <span className='mobile-label'>Subtotal:</span>{' '}
                                <span>
                                    {displayMoney(
                                        rm.drDeliveredQtyForRm * rm.rmCost
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div
                className='span-2-columns-container show-border pad-around-10 m-top-10'
                key={`${poObj.pdpoUID}`}>
                <div className='span-2-columns-container'>
                    <div className='space-between'>
                        <span>
                            <span className='parent-labels'>
                                PO {`${poIndex + 1}:`}
                            </span>{' '}
                            {poObj.pdpoUID}
                        </span>
                    </div>
                </div>

                <div className='parent-labels m-top-10 pad-around-5 bg-grey-l2'>
                    RM List
                </div>

                <div className='span-2-columns-container'>
                    <div className='view-table-heading'>
                        <div className='cell'>
                            <span className='m-left-15'>
                                RM Type - Description
                            </span>
                        </div>
                        <div className='cell'>
                            <span className='width25'>Delivered Qty:</span>
                            <span className='width25'>Unit:</span>
                            <span className='width25'>Cost:</span>
                            <span className='width25'>Subtotal:</span>
                        </div>
                    </div>
                </div>

                {rmsOfPo}
            </div>
        );
    });

    return (
        <div
            className='span-2-columns-container show-border-darker pad-around-10 m-top-10'
            key={`${drObj.pddrUID}`}>
            <div className='span-2-columns-container'>
                <div className='space-between'>
                    <span>
                        <span className='parent-labels'>
                            GR {`${drIndex + 1}:`}
                        </span>{' '}
                        {drObj.pddrUID}
                    </span>

                    <span className='icon-size'>{trashIcon}</span>
                </div>
            </div>

            <div className='parent-labels m-top-10 pad-around-5 bg-grey-l1'>
                PO List
            </div>

            {posOfDr}
        </div>
    );
};
