/**
 * c001:    Sept 1, 2020: feature request, no PO option. go automatically to DR.
 *          This will force the masterlist to be dr automatically after choosing the customer.
 *          Logic to fork between PO and DR was not changed.
 *
 * c002:    Sept 2, 2020: status change button for "INVOICED" commented out. Status of
 *          SI "INVOICED" will be changed from Voucher page (to be implemented).
 *
 *
 */

import React, { useState, useEffect, useContext } from 'react';
import {
    Datalist,
    AddButton,
    Input,
    CreateItemButtons,
    UpdateItemButtons,
    DeleteItemButtons,
    BackOnlyButton,
    BackSendEditDelButtons,
    BackWithMarkAsButtons,
    ApproveRejectButtons,
    HelpTip,
    TextArea,
    Watermark,
} from '../../components';
import {
    AuthContext,
    MasterListContext,
    DataShareContext,
} from '../../contexts';
import {
    permissions,
    userActions,
    systemActions,
    SDPOS_COL,
    SDDRS_COL,
    lengths,
} from '../../lib/constants';
import { clone, displayDate } from '../../lib/util';
import { dbopsGetDocument } from '../../firebase_dbops';

import SdsiPOItem from './SdsiPOItem';
import SdsiDRItem from './SdsiDRItem';
import SDSIAmountBox from './SDSIAmountBox';
import validate from '../../validators';
import tooltips from '../../lib/tooltips';

import '../PagesCommon.css';
import './SDSIsPage.css';

let defaultSDSI = {
    sdsiUID: '',
    sdsiNumber: '',
    customerUID: '',
    sdpoArr: [],
    sddrArr: [],
    meta: { action: '' },
    sdsiTaxRate: 0, // will be overridden.
    note: '',
};

const siStrs = {
    PO: 'PO',
    DR: 'DR',
    SDPO_ARR: 'sdpoArr',
    SDDR_ARR: 'sddrArr',
};

export default (props) => {
    let {
        purpose,
        currentDocument,

        backCancelBtnFn,
        gotoEditViewFn,
        gotoDeleteViewFn,

        createSdsiFn,
        deleteSdsiFn,
        updateSdsiFn,
        statusChangeFn,

        setIsLoading,
        setAlertErrorMsg,
    } = props;

    let { masterSdpoList, masterSddrList, masterCustomersList } = useContext(
        MasterListContext
    );
    let { authUser } = useContext(AuthContext);
    let { defaultTaxRateFromDB, serverDate } = useContext(DataShareContext);

    let [generalError, setGeneralError] = useState('');
    let [
        datalistDisplayResetCounter,
        setDatalistDisplayResetCounter,
    ] = useState(1);

    let [sdsiObj, setSDSIObj] = useState(defaultSDSI);
    let [tempCustomer, setTempCustomer] = useState('');
    let [customerUIDstate, setCustomerUIDstate] = useState('');
    let [sdsiUIDinState, setSdsiUIDinState] = useState('');

    let [itemUIDstate, setItemUIDstate] = useState('');
    let [taxRateInState, setTaxRateInState] = useState(defaultTaxRateFromDB);
    let [poOrDr, setPoOrDr] = useState(null);
    let [dateInState, setDateInState] = useState(serverDate);
    let [noteInState, setNoteInState] = useState('');

    let [preview, setPreview] = useState(false);
    let [confirmDeleteText, setConfirmDeleteText] = useState('');

    // set sdsiObj from currentDocument if purpose !== 'ADD'
    useEffect(() => {
        if (purpose !== 'ADD') {
            // making a copy so no side effect will change currentDocument.
            let currentDocumentCopy = {
                ...currentDocument,
                sdpoArr: clone(currentDocument.sdpoArr),
                sddrArr: clone(currentDocument.sddrArr),
            };

            setSDSIObj(currentDocumentCopy);
            setTaxRateInState(currentDocumentCopy.sdsiTaxRate);
            setCustomerUIDstate(currentDocumentCopy.customerUID);
            setDateInState(currentDocument.sdsiDate);
            setNoteInState(currentDocumentCopy.note || '');
            setSdsiUIDinState(currentDocumentCopy.sdsiUID);

            if (currentDocumentCopy.sdpoArr.length) setPoOrDr(siStrs.PO);
            if (currentDocumentCopy.sddrArr.length) setPoOrDr(siStrs.DR);
        }
    }, []);

    const cancelBtnFromEditViewFn = () => {
        // 1. reset values.
        setSDSIObj(currentDocument);
        setTaxRateInState(currentDocument.sdsiTaxRate);

        // 2. reset errorMessages.
        setGeneralError('');

        // 3. go back to profile view.
        backCancelBtnFn();
    };

    // =====================================================

    //! c001: =================================================
    const updateCustomerUIDstateFn = (_customerUID) => {
        setCustomerUIDstate(_customerUID);

        // find if _customerUID is a valid supplier
        let valid = false;
        masterCustomersList.forEach((cus) => {
            if (cus.customerUID === _customerUID) valid = true;
        });
        if (valid) setPoOrDr(siStrs.DR);
    };
    //! c001 end: ==============================================

    const deleteErrors = (sdsi) => {
        delete sdsi.sdsiUIDError;
        delete sdsi.sdsiTaxRateError;
        delete sdsi.arrError;
        delete sdsi.customerError;
        delete sdsi.sdsiDateError;
        delete sdsi.noteError;
        setGeneralError('');
    };

    const addToArrFn = async () => {
        let property = poOrDr === siStrs.PO ? siStrs.SDPO_ARR : siStrs.SDDR_ARR;
        let collection = poOrDr === siStrs.PO ? SDPOS_COL : SDDRS_COL;

        let sdsiObjCopy = {
            ...sdsiObj,
            [property]: clone(sdsiObj[property]),
        };

        deleteErrors(sdsiObjCopy);

        setIsLoading(true);

        let response = await dbopsGetDocument(collection, itemUIDstate);
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message || response.message);
            setIsLoading(false);
            return;
        }

        let itemObj = {};
        let doc = response.data;

        // form valid poObj

        if (poOrDr === siStrs.PO) {
            let validFgArr = doc.fgArr.map((fg) => {
                return {
                    fgUID: fg.fgUID,
                    fgPrice: fg.fgPrice,
                    // what will be saved is only the computed value of the
                    // fgOrderedQty and fgOverrideQty.  may increase or decrease
                    // depending on the overrideQty.
                    fgOrderedQty: fg.fgOrderedQty + fg.fgOverrideQty,
                    fgUnit: fg.fgUnit,
                };
            });
            let validSfgArr = doc.sfgArr.map((sfg) => {
                return {
                    sfgUID: sfg.sfgUID,
                    sfgPrice: sfg.sfgPrice,
                    sfgOrderedQty: sfg.sfgOrderedQty + sfg.sfgOverrideQty,
                    sfgUnit: sfg.sfgUnit,
                };
            });
            itemObj = {
                sdpoUID: doc.sdpoUID,
                customerUID: doc.customerUID,
                fgArr: validFgArr,
                sfgArr: validSfgArr,
                sdpoDiscountRate: doc.sdpoDiscountRate,
                sdpoTaxRate: doc.sdpoTaxRate,
            };
        } else {
            itemObj = {
                sddrUID: doc.sddrUID,
                customerUID: doc.customerUID,
                sdpoArr: clone(doc.sdpoArr),
            };
        }

        // form valid drObj
        sdsiObjCopy[property].push(itemObj);

        setSDSIObj(sdsiObjCopy);
        setDatalistDisplayResetCounter(datalistDisplayResetCounter + 1);
        setIsLoading(false);
    };

    const removeFromArrFn = (index) => {
        let property = poOrDr === siStrs.PO ? siStrs.SDPO_ARR : siStrs.SDDR_ARR;
        let sdsiObjCopy = {
            ...sdsiObj,
            [property]: clone(sdsiObj[property]),
        };

        deleteErrors(sdsiObjCopy);

        sdsiObjCopy[property].splice(index, 1);
        setSDSIObj(sdsiObjCopy);
    };

    const previewSiBtnFn = () => {
        let sdsiObjCopy = { ...sdsiObj };
        sdsiObjCopy.sdsiUID = sdsiUIDinState;
        sdsiObjCopy.sdsiTaxRate = taxRateInState;
        sdsiObjCopy.customerUID = customerUIDstate;
        sdsiObjCopy.sdsiDate = dateInState;
        sdsiObjCopy.note = noteInState;

        let response = validate.sdsi(sdsiObjCopy);
        if (response.error) {
            setSDSIObj(response.errorObj.sdsiObj);
            setTaxRateInState(response.errorObj.sdsiObj.sdsiTaxRate);
            setGeneralError(
                response.errorObj.sdsiObj.customerError ||
                    response.errorObj.sdsiObj.arrError
            );
            setAlertErrorMsg(response.errorObj.hackError);
        } else {
            setSDSIObj(response.data.sdsiObj);
            setPreview(true);
        }
    };

    const saveSIFn = () => {
        let sdsiObjCopy = { ...sdsiObj };
        sdsiObjCopy.sdsiUID = sdsiUIDinState;
        sdsiObjCopy.sdsiTaxRate = taxRateInState;
        sdsiObjCopy.customerUID = customerUIDstate;
        sdsiObjCopy.sdsiDate = dateInState;
        sdsiObjCopy.note = noteInState;

        // check if there is a change in the payload from currentDocument
        if (
            purpose === 'EDIT' &&
            validate.noChange(sdsiObjCopy, currentDocument)
        ) {
            // console.log('no change');
            backCancelBtnFn();
            return;
        }

        let response = validate.sdsi(sdsiObjCopy);
        if (response.error) {
            setSDSIObj(response.errorObj.sdsiObj);
            setTaxRateInState(response.errorObj.sdsiObj.sdsiTaxRate);
            setGeneralError(
                response.errorObj.sdsiObj.customerError ||
                    response.errorObj.sdsiObj.arrError
            );
            setAlertErrorMsg(response.errorObj.hackError);
            return;
        }
        setSDSIObj(response.data.sdsiObj);

        let validSdsiObj = response.data.sdsiObj;

        if (purpose === 'ADD') createSdsiFn(validSdsiObj);
        if (purpose === 'EDIT') updateSdsiFn(validSdsiObj, currentDocument);
        if (purpose === 'DELETE') deleteSdsiFn(validSdsiObj, currentDocument);
    };

    //! work around. fixes sequence of Edit without saving and then sending for approval.
    //! bug found in PDSI, SDSI, SDPO. All without statusChangeLocalFn.
    const statusChangeLocalFn = (userActionPassed) => {
        let sdsiObjCopy = { ...sdsiObj };
        sdsiObjCopy.sdsiUID = sdsiObjCopy.sdsiUID || sdsiUIDinState;
        sdsiObjCopy.sdsiTaxRate = taxRateInState;
        sdsiObjCopy.customerUID = sdsiObjCopy.customerUID || customerUIDstate;
        sdsiObjCopy.sdsiDate = dateInState;
        sdsiObjCopy.note = noteInState;

        let response = validate.sdsi(sdsiObjCopy);
        if (response.error) {
            setSDSIObj(response.errorObj.sdsiObj);
            setTaxRateInState(response.errorObj.sdsiObj.sdsiTaxRate);
            setGeneralError(
                response.errorObj.sdsiObj.customerError ||
                    response.errorObj.sdsiObj.arrError
            );
            setAlertErrorMsg(response.errorObj.hackError);
            return;
        }

        let validSdsiObj = response.data.sdsiObj;

        statusChangeFn(userActionPassed, validSdsiObj, currentDocument);
    };

    // conditional render of contents  =============================

    let masterlist = poOrDr === siStrs.PO ? masterSdpoList : masterSddrList;
    let itemUID = poOrDr === siStrs.PO ? 'sdpoUID' : 'sddrUID';
    let propertyArr = poOrDr === siStrs.PO ? 'sdpoArr' : 'sddrArr';

    let labelText =
        poOrDr === siStrs.PO ? (
            <span>
                Add Purchase Order
                <HelpTip content={tooltips.pdsiPoArr}></HelpTip>
            </span>
        ) : (
            <span>
                Add Delivery Receipt
                <HelpTip content={tooltips.pdsiDrArr}></HelpTip>
            </span>
        );

    let firstLabel = poOrDr === siStrs.PO ? 'PO List' : 'DR List';

    let itemArrUI = [];
    let noteUI = null;

    let buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    let mainContent = null;
    let datalist = null;
    let amountBox = (
        <SDSIAmountBox
            type={poOrDr}
            sdsiObj={sdsiObj}
            setTaxRateInState={setTaxRateInState}
            taxRateInState={taxRateInState}
            purpose={purpose}
            preview={preview}
        />
    );
    let itemLabel = (
        <div className='span-2-columns-container label-padding label-highlight'>
            <label className='parent-labels'>{firstLabel}</label>
        </div>
    );

    let addBtn = <AddButton disabled />;

    if ((purpose === 'ADD' && !preview) || purpose === 'EDIT') {
        //! customer list datalist process ====================================
        let customerListOptions = masterCustomersList.map(
            (customer) => customer.customerUID
        );

        let chooseCustomerBtn = (
            <button
                className='choose-sup-button'
                onClick={() => updateCustomerUIDstateFn(tempCustomer)}>
                {/* onClick={() => setCustomerUIDstate(tempCustomer)}> */}
                Choose Customer
            </button>
        );

        let customerDatalist = (
            <div className='get-item-from-db-container'>
                <div className='datalist-with-addbtn-container'>
                    <Datalist
                        list={customerListOptions}
                        initialValue={''}
                        getInputValue={(val) => setTempCustomer(val)}
                        errorMessage={''}
                        resetCounter={datalistDisplayResetCounter}></Datalist>
                    {chooseCustomerBtn}
                </div>
            </div>
        );

        let choosePOorDRComponent = (
            <div className='get-item-from-db-container'>
                <div className='datalist-with-addbtn-container'>
                    <div className='span-2-always'>
                        <div className='space-evenly'>
                            <button
                                className='btn pdsi-po-or-dr-btn-control'
                                onClick={() => setPoOrDr(siStrs.PO)}>
                                PO
                            </button>
                            or
                            <button
                                className='btn pdsi-po-or-dr-btn-control'
                                onClick={() => setPoOrDr(siStrs.DR)}>
                                DR
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );

        //! end customer datalist process ========================================

        // po or dr datalist =================================================
        let datalistOptions = masterlist
            .filter((item) => item.customerUID === customerUIDstate)
            .filter((item) => {
                if (poOrDr === siStrs.PO) {
                    return (
                        item.status === userActions.OVERRIDDEN ||
                        item.status === systemActions.FULFILLED ||
                        item.status === userActions.APPROVED
                    );
                } else {
                    return item.status === userActions.MARK_AS_DELIVERED;
                }
            })
            .map((item) => item[itemUID]);

        let itemAlreadyInTheUI = sdsiObj[propertyArr].map(
            (item) => item[itemUID]
        );

        if (
            datalistOptions.includes(itemUIDstate) &&
            !itemAlreadyInTheUI.includes(itemUIDstate)
        )
            addBtn = <AddButton onClick={addToArrFn} />;

        datalist = (
            <div className='get-item-from-db-container'>
                <div className='datalist-with-addbtn-container'>
                    <Datalist
                        label={labelText}
                        list={datalistOptions}
                        initialValue={''}
                        getInputValue={(val) => setItemUIDstate(val)}
                        errorMessage={''}
                        resetCounter={datalistDisplayResetCounter}></Datalist>
                    {addBtn}
                </div>
            </div>
        );

        // end po or dr datalist ==========================================

        for (let index = 0; index < sdsiObj[propertyArr].length; index += 1) {
            let trashIcon = (
                <i
                    className='ms-Icon ms-Icon--Delete clickable-icon'
                    aria-hidden='true'
                    onClick={() => removeFromArrFn(index)}></i>
            );

            let displayComponent =
                poOrDr === siStrs.PO ? (
                    <div className='span-2-columns-container' key={index}>
                        <SdsiPOItem
                            trashIcon={trashIcon}
                            sdpoIndex={index}
                            sdpoObj={sdsiObj[propertyArr][index]}
                        />
                    </div>
                ) : (
                    <div className='span-2-columns-container' key={index}>
                        <SdsiDRItem
                            trashIcon={trashIcon}
                            sddrIndex={index}
                            sddrObj={sdsiObj[propertyArr][index]}
                        />
                    </div>
                );

            itemArrUI.push(displayComponent);
        }

        // choosing customer and PO or DR step.
        let chosenCustomer = masterCustomersList.filter(
            (customer) => customer.customerUID === customerUIDstate
        );

        if (customerUIDstate !== '' && chosenCustomer[0]) {
            customerDatalist = null;
        } else choosePOorDRComponent = null;

        if (!poOrDr) {
            itemArrUI = null;
            itemLabel = null;
            datalist = null;
        } else choosePOorDRComponent = null;

        if (itemArrUI === null || itemArrUI.length === 0) {
            itemLabel = null;
            amountBox = null;
        }

        let siUID = (
            <Input
                type='text'
                maxLength={lengths.uid}
                onChange={(e) => setSdsiUIDinState(e.target.value)}
                value={sdsiUIDinState}
                placeholder='Required'
                errorMessage={sdsiObj.sdsiUIDError}></Input>
        );
        if (purpose === 'EDIT')
            siUID = <p className='fpii-text'>{sdsiObj.sdsiUID || '---'}</p>;

        noteUI = (
            <div className='span-2-columns-container'>
                <TextArea
                    label={<span className='parent-labels'>Notes</span>}
                    maxLength={lengths.noteLength}
                    value={noteInState}
                    onChange={(e) => setNoteInState(e.target.value)}
                    errorMessage={sdsiObj.noteError}
                />
            </div>
        );

        //! Max number of iitems in the list
        if (sdsiObj[propertyArr].length >= lengths.sdsiFgOrSfgArrLength)
            datalist = null;

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Customer Invoice</p>
                    {siUID}
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Status</p>
                    <p className='fpii-text'>{sdsiObj.meta.action || '---'}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Customer</p>
                    <p className='fpii-text'>
                        {sdsiObj.customerUID || customerUIDstate || '---'}
                    </p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Date </p>
                    <Input
                        type='date'
                        maxLength='12'
                        onChange={(e) => setDateInState(e.target.value)}
                        value={dateInState}
                        errorMessage={sdsiObj.sdsiDateError}></Input>
                </div>

                {customerDatalist}
                {choosePOorDRComponent}

                {itemLabel}
                {itemArrUI}
                {amountBox}

                {datalist}

                {noteUI}
            </section>
        );
    }

    if (
        (purpose === 'ADD' && preview) ||
        purpose === 'VIEW' ||
        purpose === 'DELETE'
    ) {
        for (let index = 0; index < sdsiObj[propertyArr].length; index += 1) {
            let displayComponent =
                poOrDr === siStrs.PO ? (
                    <div className='span-2-columns-container' key={index}>
                        <SdsiPOItem
                            sdpoIndex={index}
                            sdpoObj={sdsiObj[propertyArr][index]}
                        />
                    </div>
                ) : (
                    <div className='span-2-columns-container' key={index}>
                        <SdsiDRItem
                            sddrIndex={index}
                            sddrObj={sdsiObj[propertyArr][index]}
                        />
                    </div>
                );

            itemArrUI.push(displayComponent);
        }

        noteUI = (
            <div className='span-2-columns-container'>
                <TextArea
                    label={<span className='parent-labels'>Notes</span>}
                    disabled
                    maxLength={lengths.longTextLength}
                    value={noteInState}
                />
            </div>
        );

        if (
            sdsiObj.meta.action === userActions.SENT_FOR_APPROVAL &&
            authUser.permissions.includes(permissions.SDSI_APPROVE)
        ) {
            noteUI = (
                <div className='span-2-columns-container'>
                    <TextArea
                        label={<span className='parent-labels'>Notes</span>}
                        maxLength={lengths.noteLength}
                        value={noteInState}
                        onChange={(e) => setNoteInState(e.target.value)}
                        errorMessage={sdsiObj.noteError}
                    />
                </div>
            );
        }

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Customer Invoice</p>
                    <p className='fpii-text'>{sdsiObj.sdsiUID}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Status</p>
                    <p className='fpii-text'>{sdsiObj.meta.action || '---'}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Customer</p>
                    <p className='fpii-text'>
                        {sdsiObj.customerUID || customerUIDstate || '---'}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Date</p>
                    <p className='fpii-text'>
                        {sdsiObj.sdsiDate
                            ? displayDate(sdsiObj.sdsiDate)
                            : '---'}
                    </p>
                </div>

                {itemLabel}
                {itemArrUI}
                {amountBox}
                {noteUI}
            </section>
        );
    }

    // =========================================================================
    // Button sections for users with Request permision ===================

    if (purpose === 'ADD') {
        buttonSection = (
            <CreateItemButtons
                createText='Create CI'
                previewText='Preview CI'
                cancelPreviewFn={() => setPreview(false)}
                cancelFn={backCancelBtnFn}
                createFn={saveSIFn}
                previewFn={previewSiBtnFn}
                isPreview={preview}
            />
        );
    }

    if (purpose === 'EDIT') {
        buttonSection = (
            <UpdateItemButtons
                saveFn={saveSIFn}
                cancelFn={cancelBtnFromEditViewFn}
            />
        );
    }

    if (purpose === 'DELETE') {
        buttonSection = (
            <DeleteItemButtons
                cancelFn={backCancelBtnFn}
                deleteFn={saveSIFn}
                confirmeDeleteFn={(e) => setConfirmDeleteText(e.target.value)}
                confirmDeleteTextValue={confirmDeleteText}
                confirmDeleteTextPlaceHolder='Enter CI Number'
                uidValue={sdsiObj.sdsiUID}
            />
        );
    }

    // ==================================================================
    // Button section for users with Request permission.
    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.SDSI_REQUEST) &&
        !authUser.permissions.includes(permissions.SDSI_APPROVE)
    ) {
        if (
            sdsiObj.meta.action === userActions.CREATED ||
            sdsiObj.meta.action === userActions.REJECTED ||
            sdsiObj.meta.action === userActions.UPDATED
        ) {
            buttonSection = (
                <BackSendEditDelButtons
                    backBtnFn={backCancelBtnFn}
                    sendForApprovalBtnFn={() =>
                        statusChangeLocalFn(userActions.SENT_FOR_APPROVAL)
                    }
                    editBtnFn={gotoEditViewFn}
                    deleteBtnFn={gotoDeleteViewFn}
                />
            );
        }

        //! c002: ===============================================
        // // if document status is APPROVED
        // if (sdsiObj.meta.action === userActions.APPROVED) {
        //     buttonSection = (
        //         <BackWithMarkAsButtons
        //             backBtnFn={backCancelBtnFn}
        //             markAsBtnFn={() =>
        //                 statusChangeLocalFn(userActions.MARK_AS_INVOICED)
        //             }
        //             markAsBtnText='Mark as Invoiced'
        //         />
        //     );
        // }
        //! c002 end: ===============================================
    }

    // =========================================================================
    // Button section for users with  Approve permision ===================

    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.SDSI_APPROVE) &&
        !authUser.permissions.includes(permissions.SDSI_REQUEST)
    ) {
        // if document status is SENT_FOR_APPROVAL
        if (sdsiObj.meta.action === userActions.SENT_FOR_APPROVAL) {
            buttonSection = (
                <ApproveRejectButtons
                    backBtnFn={backCancelBtnFn}
                    approveBtnFn={() =>
                        statusChangeLocalFn(userActions.APPROVED)
                    }
                    rejectBtnFn={() =>
                        statusChangeLocalFn(userActions.REJECTED)
                    }
                />
            );
        }
    }

    // =========================================================================
    //! button section for users with both PDPO Request and Approve permissions.
    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.SDSI_APPROVE) &&
        authUser.permissions.includes(permissions.SDSI_REQUEST)
    ) {
        if (
            sdsiObj.meta.action === userActions.CREATED ||
            sdsiObj.meta.action === userActions.REJECTED ||
            sdsiObj.meta.action === userActions.UPDATED
        ) {
            buttonSection = (
                <BackSendEditDelButtons
                    backBtnFn={backCancelBtnFn}
                    sendForApprovalBtnFn={() =>
                        statusChangeLocalFn(userActions.SENT_FOR_APPROVAL)
                    }
                    editBtnFn={gotoEditViewFn}
                    deleteBtnFn={gotoDeleteViewFn}
                />
            );
        }

        // if document status is SENT_FOR_APPROVAL
        if (sdsiObj.meta.action === userActions.SENT_FOR_APPROVAL) {
            buttonSection = (
                <ApproveRejectButtons
                    backBtnFn={backCancelBtnFn}
                    approveBtnFn={() =>
                        statusChangeLocalFn(userActions.APPROVED)
                    }
                    rejectBtnFn={() =>
                        statusChangeLocalFn(userActions.REJECTED)
                    }
                />
            );
        }

        //! c002: ===============================================
        // // if document status is APPROVED
        // if (sdsiObj.meta.action === userActions.APPROVED) {
        //     buttonSection = (
        //         <BackWithMarkAsButtons
        //             backBtnFn={backCancelBtnFn}
        //             markAsBtnFn={() =>
        //                 statusChangeLocalFn(userActions.MARK_AS_INVOICED)
        //             }
        //             markAsBtnText='Mark as Invoiced'
        //         />
        //     );
        // }
        //! c002 end: ===============================================
    }

    if (!authUser.subscriptionStatusIsActive) {
        buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    }

    let generalErrorUI = (
        <div className='general-error-message'>{generalError}</div>
    );

    return (
        <div className='common-form'>
            <section className='form-title-section'>
                Customer Invoice Form
            </section>

            {mainContent}

            {buttonSection}
            {generalErrorUI}

            <Watermark purpose={purpose} currentDocument={currentDocument} />
        </div>
    );
};
