import { responseFn, clone } from '../lib/util';
import { lengths, regEx } from '../lib/constants';
import { noChange } from './noChange';
import { generalMessage, appMessage } from '../lib/messages';
import { saveWithFiveDecimals } from '../lib/util';
import { validateUID } from '../lib/util';

export const pddrNoChange = (pddr, currentDocument) => {
    let formedCurrentDocument = {
        ...currentDocument,
        pdpoArr: clone(currentDocument.pdpoArr),
    };
    formedCurrentDocument.pdpoArr = formedCurrentDocument.pdpoArr.map((po) => {
        po.rmArr = po.rmArr.map((rm) => {
            return {
                rmUID: rm.rmUID,
                drDeliveredQtyForRm: saveWithFiveDecimals(
                    rm.drDeliveredQtyForRm
                ),
            };
        });
        return po;
    });

    let formedPddr = {
        ...pddr,
        pdpoArr: clone(pddr.pdpoArr),
    };

    formedPddr.pdpoArr = formedPddr.pdpoArr.map((po) => {
        po.rmArr = po.rmArr.map((rm) => {
            return {
                rmUID: rm.rmUID,
                drDeliveredQtyForRm: saveWithFiveDecimals(
                    rm.drDeliveredQtyForRm
                ),
            };
        });
        return po;
    });
    return noChange(formedPddr, formedCurrentDocument);
};

export const pddr = (pddr) => {
    // clear all errors

    let errorInObj = false;

    delete pddr.supplierUIDError;
    delete pddr.pddrUIDError;
    delete pddr.pdpoArrError;
    delete pddr.pddrDateError;
    delete pddr.noteError;

    let hackError = '';

    pddr.pddrUID = pddr.pddrUID.trim();

    pddr.pdpoArr.forEach((poObj) => {
        poObj.rmArr.forEach((rmObj) => {
            delete rmObj.drDeliveredQtyForRmError;
        });
    });

    // validate pddrUID
    errorInObj = validateUID(pddr.pddrUID).error;
    pddr.pddrUIDError = validateUID(pddr.pddrUID).errorMessage;

    //  validate if there is at least one PO.
    if (pddr.pdpoArr.length === 0) {
        errorInObj = true;
        pddr.pdpoArrError = 'Must have at least one PO';
    }

    if (pddr.pdpoArr.length > lengths.pddrPoArrLength) {
        errorInObj = true;
        hackError = appMessage.sourceCodeErrorMessage(
            'PDDR: pdpoArr length error.'
        );
    }

    if (pddr.pddrDate === '') {
        errorInObj = true;
        pddr.pddrDateError = generalMessage.REQUIRED;
    }

    if (!regEx.date.test(pddr.pddrDate)) {
        errorInObj = true;
        pddr.pddrDateError = generalMessage.INVALID_DATE;
    }

    if (pddr.note.length > lengths.noteLength) {
        errorInObj = true;
        pddr.noteError = generalMessage.INVALID_LENGTH;
    }

    pddr.pdpoArr.forEach((poObj) => {
        // validate same supplierUID for all pdpoObjs in pdpoArr
        // ! should never run unless UI was hacked.
        if (poObj.supplierUID !== pddr.supplierUID) {
            errorInObj = true;
            hackError = appMessage.sourceCodeErrorMessage(
                'PDDR: supplierUID not equal in pddrObj schema.'
            );
        }

        poObj.rmArr.forEach((rmObj) => {
            rmObj.drDeliveredQtyForRm = saveWithFiveDecimals(
                rmObj.drDeliveredQtyForRm
            );

            if (isNaN(rmObj.drDeliveredQtyForRm)) {
                errorInObj = true;
                rmObj.drDeliveredQtyForRmError = generalMessage.INVALID;
            }

            if (rmObj.drDeliveredQtyForRm < 0) {
                errorInObj = true;
                rmObj.drDeliveredQtyForRmError = generalMessage.POSITIVE_NUMBER;
            }
            if (
                saveWithFiveDecimals(rmObj.drDeliveredQtyForRm) >
                lengths.numberInputAmount
            ) {
                errorInObj = true;
                rmObj.drDeliveredQtyForRmError = generalMessage.INVALID_AMOUNT;
            }

            // validate deliveredQty. compare against rmBalanceQty.
            // drDeliveredQtyForRm must not go over rmbalanceQty.

            if (
                rmObj.drDeliveredQtyForRm >
                saveWithFiveDecimals(rmObj.rmBalanceQty + rmObj.rmOverrideQty)
            ) {
                errorInObj = true;
                rmObj.drDeliveredQtyForRmError =
                    'Delivered Qty cannot exceed the Balance Qty';
            }

            // if (purpose === 'EDIT') {
            //     console.log('edit section of validation')

            //     if (
            //         rmObj.drDeliveredQtyForRm >
            //         rmObj.rmBalanceQty +
            //             // rmObj.drPrevDeliveredQtyForRm +
            //             rmObj.rmOverrideQty
            //     ) {
            //         let msg =
            //             'Your changes cannot exceed the balance and the previous delivered qty .';
            //         // if (rmObj.drPrevDeliveredQtyForRm === 0)
            //         //     msg = 'Delivered Qty cannot exceed the Balance Qty.';
            //         errorInObj = true;
            //         rmObj.drDeliveredQtyForRmError = msg;
            //     }
            // } else {
            //     if (
            //         rmObj.drDeliveredQtyForRm >
            //         rmObj.rmBalanceQty + rmObj.rmOverrideQty
            //     ) {
            //         errorInObj = true;
            //         rmObj.drDeliveredQtyForRmError =
            //             'Delivered Qty cannot exceed the Balance Qty.';
            //     }
            // }
        });
    });

    if (errorInObj || hackError) {
        let errorObj = {
            pddrObj: pddr,
            hackError,
        };
        // console.log(errorObj);
        return responseFn(null, true, errorObj, 'App: Error with PDDR object.');
    }

    let correctSchemaPDPOArr = pddr.pdpoArr.map((po) => {
        let correctRM = po.rmArr.map((rm) => {
            return {
                rmUID: rm.rmUID,
                rmCost: rm.rmCost,
                rmUnit: rm.rmUnit,
                drDeliveredQtyForRm: rm.drDeliveredQtyForRm,
            };
        });
        return {
            pdpoUID: po.pdpoUID,
            supplierUID: po.supplierUID,
            rmArr: correctRM,
        };
    });
    // proper schema
    let correctSchema = {
        meta: pddr.meta || {},
        metaHistory: pddr.metaHistory || [],
        pddrUID: pddr.pddrUID,
        pddrNumber: pddr.pddrUID,
        pddrDate: pddr.pddrDate,
        supplierUID: pddr.supplierUID,
        note: pddr.note,
        pdpoArr: correctSchemaPDPOArr,
    };

    let data = {
        validPddrObj: correctSchema,
        pddrObj: pddr,
    };
    return responseFn(data, false, {}, 'App: Validation successful.');
};
