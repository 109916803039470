import React, { useState, useEffect, useContext } from 'react';
import { MasterListContext, AuthContext } from '../../contexts';
import { InputIcon, ListView2 } from '../../components';
import UsersItem from '../../components/CollectionItems/UsersItem/UsersItem';
import dbopsGetDocument from '../../firebase_dbops/dbopsGetDocument';
import { functions } from '../../firebase_config/firebaseConfig';
import { dbopsAdminAndProfile } from '../../firebase_dbops';

import { permissions, lengths, USERS_COL } from '../../lib/constants';
import '../PagesCommon.css';
import './UserManagementPage.css';

import UserManagementForm from './UserManagementForm';

const view = {
    LIST_VIEW: 'listView',
    ADD_USER: 'addUser',
    EDIT_USER: 'editUser',
    DELETE_USER: 'deleteUser',
    USER_PROFILE: 'userProfile',
};

export default (props) => {
    let { setIsLoading, setAlertErrorMsg, setAlertMessage } = props;

    let { masterUsersList } = useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);

    let [formView, setFormView] = useState(view.LIST_VIEW);
    let [currentDocument, setCurrentDocument] = useState('');
    let [searchText, setSearchText] = useState('');

    // functions to change views.
    const addSupplierBtnFn = () => setFormView(view.ADD_USER);
    const gotoListViewFn = () => setFormView(view.LIST_VIEW);
    const gotoEditViewFn = () => setFormView(view.EDIT_USER);
    const gotoDeleteViewFn = () => setFormView(view.DELETE_USER);
    const gotoUserProfileViewFn = () => setFormView(view.USER_PROFILE);

    // function to get user document.
    const getDocumentFromDBFn = async (docID) => {
        setIsLoading(true);

        let response = await dbopsGetDocument(USERS_COL, docID);
        if (response.error)
            setAlertErrorMsg(response.errorObj.message || response.message);
        else {
            setCurrentDocument(response.data);
            gotoUserProfileViewFn();
        }
        setIsLoading(false);
    };

    // function to create User
    const createUserFn = async (userObj) => {
        // spinner on.
        setIsLoading(true);

        const createUserCldFn = functions.httpsCallable('createUserCldFn');
        // write to db.
        let response = null;
        try {
            response = await createUserCldFn(userObj);
        } catch (e) {
            // catch https error
            setAlertErrorMsg(e.message);
            setIsLoading(false);
            return;
        }

        //! firebase cloudfunctions has a different format in returning responses.
        //! it is always in a data property.
        if (response.data.error) {
            console.error(response.data.errorObj);
            setAlertErrorMsg(
                (response.data.errorObj.errorInfo &&
                    response.data.errorObj.errorInfo.message) ||
                    response.data.message
            );
            setIsLoading(false);
            return;
        } else setAlertMessage(response.data.message);

        gotoListViewFn();
        // spinner off.
        setIsLoading(false);
    };

    // function to update user.
    const updateUserFn = async (userObj, documentBasis) => {
        setIsLoading(true);
        let response = await dbopsAdminAndProfile.updateUserFn(
            userObj,
            documentBasis
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);
            // if error is because document has already been deleted,
            // go back to list view.
            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
            // if error is because document has since changed, just continue
            // flow of the program.
        } else setAlertMessage(response.message);
        setIsLoading(false);
        gotoListViewFn();
        //! goto listview after saving the edit because UI is not updating properly consistently.
        // await getDocumentFromDBFn(userObj.userUID);
    };

    // function to delete user.
    const deleteUserFn = async (userObj, documentBasis) => {
        setIsLoading(true);

        const deleteUserCldFn = functions.httpsCallable('deleteUserCldFn');

        let response = null;
        try {
            response = await deleteUserCldFn(userObj, documentBasis);
        } catch (e) {
            // catch https error
            setAlertErrorMsg(e.message);
            setIsLoading(false);
            return;
        }

        //! firebase cloudfunctions has a different format in returning responses.
        //! it is always in a data property.
        if (response.data.error) {
            console.error(response.data.errorObj);

            if (response.data.errorObj.code === 1000) {
                setAlertErrorMsg(
                    response.data.errorObj.message || response.data.message
                );
                gotoListViewFn();
                setIsLoading(false);
                return;
            }

            // all other errors can continue flow of program.

            setAlertErrorMsg(
                (response.data.errorObj.errorInfo &&
                    response.data.errorObj.errorInfo.message) ||
                    response.data.message
            );
            setIsLoading(false);
            return;
        } else setAlertMessage(response.data.message);
        gotoListViewFn();
        setIsLoading(false);
    };

    // filter function =====================================
    let filteredUsersList = masterUsersList.filter((user) => {
        return user.email.toUpperCase().includes(searchText.toUpperCase());
    });

    let addUserBtnUI = (
        <button className='btn btn-primary' onClick={addSupplierBtnFn}>
            Create User
        </button>
    );
    if (
        !authUser.permissions.includes(permissions.APP_ADMIN) ||
        masterUsersList.length >= lengths.usersList ||
        !authUser.subscriptionStatusIsActive
    ) {
        addUserBtnUI = null;
    }

    let topSectionContent = (
        <section className='top-section'>
            <InputIcon
                id='suppliers-search-input'
                type='text'
                placeholder='Search'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                icon={
                    <i
                        className='ms-Icon ms-Icon--Search'
                        aria-hidden='true'></i>
                }
            />
            {addUserBtnUI}
        </section>
    );

    let mainSectionContent = (
        <ListView2
            getDocumentFromDBFn={getDocumentFromDBFn}
            list={filteredUsersList}
            keyProperty={'userUID'}
            CollectionItemComponent={UsersItem}
        />
    );

    if (formView !== view.LIST_VIEW) topSectionContent = null;

    if (formView === view.ADD_USER) {
        mainSectionContent = (
            <UserManagementForm
                purpose='ADD'
                backCancelBtnFn={gotoListViewFn}
                createUserFn={createUserFn}
            />
        );
    }

    if (formView === view.EDIT_USER) {
        mainSectionContent = (
            <UserManagementForm
                purpose='EDIT'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoUserProfileViewFn}
                updateUserFn={updateUserFn}
            />
        );
    }

    if (formView === view.DELETE_USER) {
        mainSectionContent = (
            <UserManagementForm
                purpose='DELETE'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoUserProfileViewFn}
                deleteUserFn={deleteUserFn}
            />
        );
    }

    if (formView === view.USER_PROFILE) {
        mainSectionContent = (
            <UserManagementForm
                purpose='VIEW'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoListViewFn}
                gotoEditViewFn={gotoEditViewFn}
                gotoDeleteViewFn={gotoDeleteViewFn}
            />
        );
    }

    return (
        <div className='content-layout'>
            {topSectionContent}

            <section className='main-section'>{mainSectionContent}</section>
        </div>
    );
};
