import React from 'react';
import { displayMoney } from '../../lib/util';

export default ({ index, item, mobileLabel }) => {
    return (
        <div className='span-2-columns-container report-items-alt-color'>
            <div className='view-table-row report-items'>
                <div className='cell'>
                    <span className='mobile-label m-right-15'>
                        {`${index + 1}.`}
                    </span>
                    <span className='desktop-view m-lr-15'>{`${
                        index + 1
                    }.`}</span>
                    <span className=''> {item.itemUID}</span>
                </div>

                <div className='cell tj-right pad-right-15'>
                    <span className='width100'>
                        <span className='mobile-label'>{mobileLabel}</span>{' '}
                        <span className=''>{displayMoney(item.total)}</span>
                    </span>
                </div>
            </div>
        </div>
    );
};
