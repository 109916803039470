import React from 'react';
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css';
import './HelpTip.css';

export default (props) => {
    let { content, addedClass } = props;

    return (
        <div className={`warning-tip ${addedClass}`}>
            <Tippy trigger='click' content={content}>
                <i className='ms-Icon ms-Icon--Warning' aria-hidden='true'></i>
            </Tippy>
        </div>
    );
};
