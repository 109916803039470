export default (pdObj) => {
    let prefix = null;
    if (pdObj.hasOwnProperty('pdpoUID')) prefix = 'pdpo';
    if (pdObj.hasOwnProperty('pddrUID')) prefix = 'pddr';
    if (pdObj.hasOwnProperty('pdsiUID')) prefix = 'pdsi';
    if (pdObj.hasOwnProperty('pdvrUID')) prefix = 'pdvr';

    let obj = {
        a: pdObj.supplierUID,
        b: pdObj[`${prefix}Date`],
        c: pdObj.meta.action,
    };

    if (prefix === 'pdvr') {
        obj.d = pdObj.pdvrTotalsNett ? pdObj.pdvrTotalsNett : 0;
    }

    return obj;
};
