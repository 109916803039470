import React, { useState, useEffect, useContext } from 'react';
import {
    Datalist,
    AddButton,
    Input,
    CreateItemButtons,
    UpdateItemButtons,
    DeleteItemButtons,
    BackOnlyButton,
    BackSendEditDelButtons,
    ApproveRejectButtons,
    HelpTip,
    TextArea,
    SignatureLine,
    Watermark,
    InputDS,
} from '../../components';
import {
    AuthContext,
    MasterListContext,
    DataShareContext,
} from '../../contexts';
import { permissions, userActions, lengths } from '../../lib/constants';

import validate from '../../validators';
import tooltips from '../../lib/tooltips';

import '../PagesCommon.css';
import './InventoryTransferPage.css';
import { displayDate } from '../../lib/util';

let defaultInvTransferObj = {
    invTransferUID: '',
    invTransferNumber: '',
    subjectUID: '',
    invTransferDate: [],
    meta: { action: '' },
    note: '',
    transferDirection: null,
    rmArr: [],
    sfgArr: [],
    fgArr: [],
};

const defaultRmObj = {
    rmUID: '',
    rmUnit: '',
    transferQty: 0,
    errorMessage: '',
    transferQtyErrorMessage: '',
};
const defaultSfgObj = {
    sfgUID: '',
    sfgUnit: '',
    transferQty: 0,
    errorMessage: '',
    transferQtyErrorMessage: '',
};
const defaultFgObj = {
    fgUID: '',
    fgUnit: '',
    transferQty: 0,
    errorMessage: '',
    transferQtyErrorMessage: '',
};

export default (props) => {
    let {
        purpose,
        currentDocument,

        backCancelBtnFn,
        gotoEditViewFn,
        gotoDeleteViewFn,

        createInvTransferFn,
        deleteInvTransferFn,
        updateInvTransferFn,
        statusChangeFn,
    } = props;

    let {
        masterSuppliersList,
        masterCustomersList,
        masterRawMaterialsList,
        masterSemiFGsList,
        masterFinishedGoodsList,

        masterInventoryRMsList,
        masterInventorySFGsList,
        masterInventoryFGsList,
    } = useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);
    let { serverDate } = useContext(DataShareContext);

    let [generalError, setGeneralError] = useState('');

    // state used for purpose === 'ADD'

    let [invTransferObj, setInvTransferObj] = useState(defaultInvTransferObj);

    let [invTransferDateInState, setInvTransferDateInState] =
        useState(serverDate);
    let [noteInState, setNoteInState] = useState('');
    let [rmArrInState, setRmArrInState] = useState([]);
    let [sfgArrInState, setSfgArrInState] = useState([]);
    let [fgArrInState, setFgArrInState] = useState([]);

    let [rmUnitMap, setRmUnitMap] = useState({});
    let [sfgUnitMap, setSfgUnitMap] = useState({});
    let [fgUnitMap, setFgUnitMap] = useState({});

    let [preview, setPreview] = useState(false);

    let [confirmDeleteText, setConfirmDeleteText] = useState('');

    // set invTransferObj from currentDocument if purpose !== 'ADD'
    useEffect(() => {
        if (purpose === 'VIEW' || purpose === 'DELETE') {
            // making a copy so no side effect will change currentDocument.
            let currentDocumentCopy = {
                // will copy meta and metaHistory as is.
                ...currentDocument,
            };
            setInvTransferObj(currentDocumentCopy);
            setInvTransferDateInState(currentDocumentCopy.invTransferDate);
            setRmArrInState(currentDocumentCopy.rmArr);
            setSfgArrInState(currentDocumentCopy.sfgArr);
            setFgArrInState(currentDocumentCopy.fgArr);
            setNoteInState(currentDocumentCopy.note || '');
        }
    }, []);

    // create a unit map for rms, sfgs, fgs once only.
    useEffect(() => {
        let rmUnitMap = {};
        let sfgUnitMap = {};
        let fgUnitMap = {};

        masterRawMaterialsList.forEach((rm) => {
            rmUnitMap[rm.rmUID] = rm.rmUnit;
        });

        masterSemiFGsList.forEach((sfg) => {
            sfgUnitMap[sfg.sfgUID] = sfg.sfgUnit;
        });

        masterFinishedGoodsList.forEach((fg) => {
            fgUnitMap[fg.fgUID] = fg.fgUnit;
        });

        setRmUnitMap(rmUnitMap);
        setSfgUnitMap(sfgUnitMap);
        setFgUnitMap(fgUnitMap);
    }, [masterRawMaterialsList, masterSemiFGsList, masterFinishedGoodsList]);

    const cancelBtnFromEditViewFn = () => {
        // 1. reset values.
        setInvTransferObj(currentDocument);

        // 2. reset errorMessages.
        setGeneralError('');

        // 3. go back to profile view.
        backCancelBtnFn();
    };

    // =====================================================

    // for saving functionality

    const commonSaveProcess = (userActionPassed) => {
        let invTransferObjCopy = { ...invTransferObj };
        invTransferObjCopy.invTransferDate = invTransferDateInState;
        invTransferObjCopy.note = noteInState;
        invTransferObjCopy.rmArr = rmArrInState;
        invTransferObjCopy.sfgArr = sfgArrInState;
        invTransferObjCopy.fgArr = fgArrInState;

        // check if there is a change in the payload from currentDocument
        if (
            purpose === 'EDIT' &&
            validate.noChange(invTransferObjCopy, currentDocument)
        ) {
            backCancelBtnFn();
            return;
        }

        let response = validate.invTransfer(
            invTransferObjCopy,
            masterCustomersList,
            masterSuppliersList,
            masterRawMaterialsList,
            masterSemiFGsList,
            masterFinishedGoodsList,
            masterInventoryRMsList,
            masterInventorySFGsList,
            masterInventoryFGsList,
            currentDocument,
            purpose,
            userActionPassed
        );
        if (response.error) {
            setInvTransferObj(response.errorObj.invTransferObjCopy);
            setRmArrInState(response.errorObj.invTransferObjCopy.rmArr);
            setSfgArrInState(response.errorObj.invTransferObjCopy.sfgArr);
            setFgArrInState(response.errorObj.invTransferObjCopy.fgArr);
            setGeneralError(response.errorObj.arraysErr);
            return 'error';
        }

        setInvTransferObj(response.data);
        setRmArrInState(response.data.rmArr);
        setSfgArrInState(response.data.sfgArr);
        setFgArrInState(response.data.fgArr);

        return response.data;
    };

    const previewInvTransferBtnFn = () => {
        let previewObj = commonSaveProcess();

        if (previewObj === 'error') return;
        setPreview(true);
    };

    const saveInvTransFn = () => {
        let validInvTransferObj = commonSaveProcess();

        if (validInvTransferObj === 'error') return;

        if (purpose === 'ADD') createInvTransferFn(validInvTransferObj);
        if (purpose === 'EDIT')
            updateInvTransferFn(validInvTransferObj, currentDocument);
        if (purpose === 'DELETE')
            deleteInvTransferFn(validInvTransferObj, currentDocument);
    };

    const statusChangeLocalFn = async (userActionPassed) => {
        let validInvTransferObj = commonSaveProcess(userActionPassed);

        if (validInvTransferObj === 'error') return;

        statusChangeFn(userActionPassed, validInvTransferObj, currentDocument);
    };

    const updateInvTransObjFn = (property, value) => {
        let invTransCopy = { ...invTransferObj };
        invTransCopy[property] = value;
        setInvTransferObj(invTransCopy);
    };

    const addArrayInputFieldFn = (arrName) => {
        let arrCopy = [];

        if (arrName === 'rmArr') {
            arrCopy = [...rmArrInState];
            arrCopy.push(defaultRmObj);
        }
        if (arrName === 'sfgArr') {
            arrCopy = [...sfgArrInState];
            arrCopy.push(defaultSfgObj);
        }
        if (arrName === 'fgArr') {
            arrCopy = [...fgArrInState];
            arrCopy.push(defaultFgObj);
        }

        arrCopy.forEach((item) => {
            delete item.errorMessage;
            delete item.transferQtyErrorMessage;
        });

        if (arrName === 'rmArr') setRmArrInState(arrCopy);
        if (arrName === 'sfgArr') setSfgArrInState(arrCopy);
        if (arrName === 'fgArr') setFgArrInState(arrCopy);
    };

    const removeArrayInputFieldFn = (arrName, index) => {
        let arrCopy = [];
        if (arrName === 'rmArr') arrCopy = [...rmArrInState];
        if (arrName === 'sfgArr') arrCopy = [...sfgArrInState];
        if (arrName === 'fgArr') arrCopy = [...fgArrInState];

        arrCopy.splice(index, 1);
        arrCopy.forEach((item) => {
            delete item.errorMessage;
            delete item.transferQtyErrorMessage;
        });
        if (arrName === 'rmArr') setRmArrInState(arrCopy);
        if (arrName === 'sfgArr') setSfgArrInState(arrCopy);
        if (arrName === 'fgArr') setFgArrInState(arrCopy);
    };

    const updateArrFn = (arrName, propName, index, value) => {
        let arrCopy = [];
        if (arrName === 'rmArr') arrCopy = [...rmArrInState];
        if (arrName === 'sfgArr') arrCopy = [...sfgArrInState];
        if (arrName === 'fgArr') arrCopy = [...fgArrInState];

        let itemObj = {
            ...arrCopy[index],
            [propName]: value,
        };

        // get unit
        if (propName !== 'transferQty') {
            if (arrName === 'rmArr') itemObj.rmUnit = rmUnitMap[value];
            if (arrName === 'sfgArr') itemObj.sfgUnit = sfgUnitMap[value];
            if (arrName === 'fgArr') itemObj.fgUnit = fgUnitMap[value];
        }

        arrCopy[index] = itemObj;

        if (arrName === 'rmArr') setRmArrInState(arrCopy);
        if (arrName === 'sfgArr') setSfgArrInState(arrCopy);
        if (arrName === 'fgArr') setFgArrInState(arrCopy);
    };

    // conditional render of contents  =============================

    let noteUI = null;
    let buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    let mainContent = null;
    let subjectDatalistOptions = null;

    let subjectLabel = '';
    let idProp = '';
    let masterList = [];

    let rmArrInputFields = [];
    let sfgArrInputFields = [];
    let fgArrInputFields = [];

    let rmHeading = (
        <div className='span-2-columns-container'>
            <div className='view-table-heading m-zero'>
                <div className='cell'>
                    <span className='m-left-15'>RM Type - Description</span>
                </div>

                <div className='cell'>
                    <div className='flex-row width66'>
                        <span className='width50 m-right-10'>Transfer Qty</span>

                        <span className='width50 m-right-10'>Unit</span>
                    </div>
                </div>
            </div>
        </div>
    );
    let sfgHeading = (
        <div className='span-2-columns-container'>
            <div className='view-table-heading m-zero'>
                <div className='cell'>
                    <span className='m-left-15'>Semi Finished Good</span>
                </div>

                <div className='cell'>
                    <div className='flex-row width66'>
                        <span className='width50 m-right-10'>Transfer Qty</span>

                        <span className='width50 m-right-10'>Unit</span>
                    </div>
                </div>
            </div>
        </div>
    );
    let fgHeading = (
        <div className='span-2-columns-container'>
            <div className='view-table-heading m-zero'>
                <div className='cell'>
                    <span className='m-left-15'>Finished Good</span>
                </div>

                <div className='cell'>
                    <div className='flex-row width66'>
                        <span className='width50 m-right-10'>Transfer Qty</span>

                        <span className='width50 m-right-10'>Unit</span>
                    </div>
                </div>
            </div>
        </div>
    );

    if (invTransferObj.transferDirection === null) {
        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <div
                        className='inv-transfer-choose-btn'
                        onClick={() =>
                            updateInvTransObjFn(
                                'transferDirection',
                                'toCustomer'
                            )
                        }>
                        ( - ) Inventory Out
                    </div>
                </div>

                <div className='form-profile-info-items'>
                    <div
                        className='inv-transfer-choose-btn'
                        onClick={() =>
                            updateInvTransObjFn(
                                'transferDirection',
                                'fromSupplier'
                            )
                        }>
                        ( + ) Inventory In
                    </div>
                </div>
            </section>
        );
    }

    if (
        ((purpose === 'ADD' && !preview) || purpose === 'EDIT') &&
        invTransferObj.transferDirection !== null
    ) {
        if (invTransferObj.transferDirection === 'fromSupplier') {
            idProp = 'supplierUID';
            subjectLabel = 'Supplier - Inventory In ( + )';
            masterList = masterSuppliersList;
        }
        if (invTransferObj.transferDirection === 'toCustomer') {
            subjectLabel = 'Customer - Inventory Out ( - )';
            masterList = masterCustomersList;
            idProp = 'customerUID';
        }

        let showRmAddBtn = true;
        let showSfgAddBtn = true;
        let showFgAddBtn = true;

        subjectDatalistOptions = masterList.map((item) => item[idProp]);
        let rmDatalistOptions = masterRawMaterialsList.map((rm) => rm.rmUID);
        let sfgDatalistOptions = masterSemiFGsList.map((sfg) => sfg.sfgUID);
        let fgDatalistOptions = masterFinishedGoodsList.map((fg) => fg.fgUID);

        rmArrInState.forEach((rm) => {
            if (!rmDatalistOptions.includes(rm.rmUID) || rm.rmUID === '')
                showRmAddBtn = false;
        });

        sfgArrInState.forEach((sfg) => {
            if (!sfgDatalistOptions.includes(sfg.sfgUID) || sfg.sfgUID === '')
                showSfgAddBtn = false;
        });

        fgArrInState.forEach((fg) => {
            if (!fgDatalistOptions.includes(fg.fgUID) || fg.fgUID === '')
                showFgAddBtn = false;
        });

        if (rmArrInState.length >= lengths.invTransferItemsLength)
            showRmAddBtn = false;
        if (sfgArrInState.length >= lengths.invTransferItemsLength)
            showSfgAddBtn = false;
        if (fgArrInState.length >= lengths.invTransferItemsLength)
            showFgAddBtn = false;

        let addRawMatButton = showRmAddBtn ? (
            <AddButton onClick={() => addArrayInputFieldFn('rmArr')} />
        ) : null;

        let addSfgButton = showSfgAddBtn ? (
            <AddButton onClick={() => addArrayInputFieldFn('sfgArr')} />
        ) : null;

        let addFgButton = showFgAddBtn ? (
            <AddButton onClick={() => addArrayInputFieldFn('fgArr')} />
        ) : null;

        noteUI = (
            <div className='span-2-columns-container'>
                <TextArea
                    label={<span className='parent-labels'>Notes</span>}
                    maxLength={lengths.noteLength}
                    value={noteInState}
                    onChange={(e) => setNoteInState(e.target.value)}
                    errorMessage={invTransferObj.noteError}
                />
            </div>
        );

        // =====================================================================
        // RM array
        for (let index = 0; index < rmArrInState.length; index += 1) {
            let initialValue = rmArrInState[index]
                ? rmArrInState[index].rmUID
                : '';
            let initialQty = rmArrInState[index]
                ? rmArrInState[index].transferQty
                : '';

            let trashIcon = (
                <i
                    className='ms-Icon ms-Icon--Delete'
                    aria-hidden='true'
                    onClick={() => removeArrayInputFieldFn('rmArr', index)}></i>
            );

            rmArrInputFields.push(
                <div className='sfg-datalist-container' key={index}>
                    <div className='flex-row'>
                        <span className='desktop-view m-right-10'>
                            {index + 1}.{' '}
                        </span>
                        <Datalist
                            list={rmDatalistOptions}
                            initialValue={initialValue}
                            mobileLabel={`Raw Material ${index + 1}`}
                            getInputValue={(val) =>
                                updateArrFn('rmArr', 'rmUID', index, val)
                            }
                            placeholder='Required'
                            errorMessage={
                                rmArrInState[index].errorMessage
                            }></Datalist>
                    </div>

                    <div className='required-qty-input'>
                        <div className='flex-row width66'>
                            <div className='width50'>
                                <InputDS
                                    mobileLabel='Required Qty'
                                    type='number'
                                    initialValue={initialQty}
                                    getInputValue={(val) =>
                                        updateArrFn(
                                            'rmArr',
                                            'transferQty',
                                            index,
                                            val
                                        )
                                    }
                                    placeholder='Required'
                                    errorMessage={
                                        rmArrInState[index]
                                            .transferQtyErrorMessage
                                    }
                                />
                            </div>
                            <div className='width50 m-left-5 pad-top-10'>
                                {rmArrInState[index].rmUnit}
                            </div>
                        </div>

                        {trashIcon}
                    </div>
                </div>
            );
        }

        // =====================================================================
        // SFG array
        for (let index = 0; index < sfgArrInState.length; index += 1) {
            let initialValue = sfgArrInState[index]
                ? sfgArrInState[index].sfgUID
                : '';
            let initialQty = sfgArrInState[index]
                ? sfgArrInState[index].transferQty
                : '';

            let trashIcon = (
                <i
                    className='ms-Icon ms-Icon--Delete'
                    aria-hidden='true'
                    onClick={() =>
                        removeArrayInputFieldFn('sfgArr', index)
                    }></i>
            );

            sfgArrInputFields.push(
                <div className='sfg-datalist-container' key={index}>
                    <div className='flex-row'>
                        <span className='desktop-view m-right-10'>
                            {index + 1}.{' '}
                        </span>
                        <Datalist
                            list={sfgDatalistOptions}
                            initialValue={initialValue}
                            mobileLabel={`Semi FG ${index + 1}`}
                            getInputValue={(val) =>
                                updateArrFn('sfgArr', 'sfgUID', index, val)
                            }
                            placeholder='Required'
                            errorMessage={
                                sfgArrInState[index].errorMessage
                            }></Datalist>
                    </div>

                    <div className='required-qty-input'>
                        <div className='flex-row width66'>
                            <div className='width50'>
                                <InputDS
                                    mobileLabel='Required Qty'
                                    type='number'
                                    initialValue={initialQty}
                                    getInputValue={(val) =>
                                        updateArrFn(
                                            'sfgArr',
                                            'transferQty',
                                            index,
                                            val
                                        )
                                    }
                                    placeholder='Required'
                                    errorMessage={
                                        sfgArrInState[index]
                                            .transferQtyErrorMessage
                                    }
                                />
                            </div>
                            <div className='width50 m-left-5 pad-top-10'>
                                {sfgArrInState[index].sfgUnit}
                            </div>
                        </div>

                        {trashIcon}
                    </div>
                </div>
            );
        }
        // =====================================================================
        // FG array
        for (let index = 0; index < fgArrInState.length; index += 1) {
            let initialValue = fgArrInState[index]
                ? fgArrInState[index].fgUID
                : '';
            let initialQty = fgArrInState[index]
                ? fgArrInState[index].transferQty
                : '';

            let trashIcon = (
                <i
                    className='ms-Icon ms-Icon--Delete'
                    aria-hidden='true'
                    onClick={() => removeArrayInputFieldFn('fgArr', index)}></i>
            );

            fgArrInputFields.push(
                <div className='sfg-datalist-container' key={index}>
                    <div className='flex-row'>
                        <span className='desktop-view m-right-10'>
                            {index + 1}.{' '}
                        </span>
                        <Datalist
                            list={fgDatalistOptions}
                            initialValue={initialValue}
                            mobileLabel={`FG ${index + 1}`}
                            getInputValue={(val) =>
                                updateArrFn('fgArr', 'fgUID', index, val)
                            }
                            placeholder='Required'
                            errorMessage={
                                fgArrInState[index].errorMessage
                            }></Datalist>
                    </div>

                    <div className='required-qty-input'>
                        <div className='flex-row width66'>
                            <div className='width50'>
                                <InputDS
                                    mobileLabel='Required Qty'
                                    type='number'
                                    initialValue={initialQty}
                                    getInputValue={(val) =>
                                        updateArrFn(
                                            'fgArr',
                                            'transferQty',
                                            index,
                                            val
                                        )
                                    }
                                    placeholder='Required'
                                    errorMessage={
                                        fgArrInState[index]
                                            .transferQtyErrorMessage
                                    }
                                />
                            </div>
                            <div className='width50 m-left-5 pad-top-10'>
                                {fgArrInState[index].fgUnit}
                            </div>
                        </div>

                        {trashIcon}
                    </div>
                </div>
            );
        }

        if (rmArrInputFields.length === 0) rmHeading = null;
        if (sfgArrInputFields.length === 0) sfgHeading = null;
        if (fgArrInputFields.length === 0) fgHeading = null;

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Inventory Transfer Number</p>
                    <p className='fpii-text'>
                        {invTransferObj.invTransferUID || '(Auto Generated)'}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Status</p>
                    <p className='fpii-text'>
                        {invTransferObj.meta.action || '--'}
                    </p>
                </div>

                <div
                    className='form-profile-info-items'
                    style={{ overflow: 'visible' }}>
                    <p className='fpii-label'>{subjectLabel}</p>

                    <Datalist
                        list={subjectDatalistOptions}
                        initialValue={invTransferObj.subjectUID || ''}
                        getInputValue={(val) =>
                            updateInvTransObjFn('subjectUID', val)
                        }
                        errorMessage={invTransferObj.subjectUIDError}
                        // resetCounter={datalistDisplayResetCounter}
                    ></Datalist>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Date</p>
                    <Input
                        type='date'
                        maxLength='12'
                        onChange={(e) =>
                            setInvTransferDateInState(e.target.value)
                        }
                        value={invTransferDateInState}
                        errorMessage={
                            invTransferObj.invTransferDateError
                        }></Input>
                </div>

                {/* RM array */}
                <div className='span-2-columns-container label-padding label-highlight height-35'>
                    <label className='parent-labels'>Raw Materials List</label>
                    {addRawMatButton}
                    <HelpTip content={tooltips.inventoryTransferArr} />
                </div>
                {rmHeading}
                <div className='sfg-rm-section'>{rmArrInputFields}</div>

                {/* SFG array */}

                <div className='span-2-columns-container label-padding label-highlight height-35'>
                    <label className='parent-labels'>
                        Semi Finished Goods List
                    </label>
                    {addSfgButton}
                    <HelpTip content={tooltips.inventoryTransferArr} />
                </div>

                {sfgHeading}
                <div className='sfg-rm-section'>{sfgArrInputFields}</div>

                {/* FG array */}

                <div className='span-2-columns-container label-padding label-highlight height-35'>
                    <label className='parent-labels'>Finished Goods List</label>
                    {addFgButton}
                    <HelpTip content={tooltips.inventoryTransferArr} />
                </div>

                {fgHeading}
                <div className='sfg-rm-section'>{fgArrInputFields}</div>

                {noteUI}
            </section>
        );
    }

    if (
        ((purpose === 'ADD' && preview) ||
            purpose === 'VIEW' ||
            purpose === 'DELETE') &&
        invTransferObj.transferDirection !== null
    ) {
        let subjectLabel =
            invTransferObj.transferDirection === 'toCustomer'
                ? 'Customer'
                : 'Supplier';

        let rmArrUI = rmArrInState.map((rm, index) => {
            return (
                <div className='span-2-columns-container' key={index}>
                    <div className='view-table-row'>
                        <div className='cell'>
                            <span className='mobile-label m-right-15'>
                                {`${index + 1}.`}
                            </span>
                            <span className='desktop-view m-lr-15'>
                                {`${index + 1}.`}
                            </span>
                            <span className=''> {rm.rmUID}</span>
                        </div>

                        <div className='cell'>
                            <span className='width50'>
                                <span className='mobile-label'>
                                    Transfer Qty:
                                </span>{' '}
                                <span className=''>{rm.transferQty}</span>
                                <span className='font12'> {rm.rmUnit}</span>
                            </span>
                        </div>
                    </div>
                </div>
            );
        });
        let sfgArrUI = sfgArrInState.map((sfg, index) => {
            return (
                <div className='span-2-columns-container' key={index}>
                    <div className='view-table-row'>
                        <div className='cell'>
                            <span className='mobile-label m-right-15'>
                                {`${index + 1}.`}
                            </span>
                            <span className='desktop-view m-lr-15'>
                                {`${index + 1}.`}
                            </span>
                            <span className=''> {sfg.sfgUID}</span>
                        </div>

                        <div className='cell'>
                            <span className='width50'>
                                <span className='mobile-label'>
                                    Transfer Qty:
                                </span>{' '}
                                <span className=''>{sfg.transferQty}</span>
                                <span className='font12'> {sfg.sfgUnit}</span>
                            </span>
                        </div>
                    </div>
                </div>
            );
        });
        let fgArrUI = fgArrInState.map((fg, index) => {
            return (
                <div className='span-2-columns-container' key={index}>
                    <div className='view-table-row'>
                        <div className='cell'>
                            <span className='mobile-label m-right-15'>
                                {`${index + 1}.`}
                            </span>
                            <span className='desktop-view m-lr-15'>
                                {`${index + 1}.`}
                            </span>
                            <span className=''> {fg.fgUID}</span>
                        </div>

                        <div className='cell'>
                            <span className='width50'>
                                <span className='mobile-label'>
                                    Transfer Qty:
                                </span>{' '}
                                <span className=''>{fg.transferQty}</span>
                                <span className='font12'> {fg.fgUnit}</span>
                            </span>
                        </div>
                    </div>
                </div>
            );
        });

        let rmCompleteHeading = (
            <>
                <div className='span-2-columns-container label-padding label-highlight height-35'>
                    <label className='parent-labels'>Raw Materials List</label>
                </div>

                <div className='span-2-columns-container'>
                    <div className='view-table-heading m-zero'>
                        <div className='cell'>
                            <span className='m-left-15'>
                                RM Type - Description
                            </span>
                        </div>

                        <div className='cell'>
                            <div className='flex-row width66'>
                                <span className='width50 m-right-10'>
                                    Transfer Qty
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
        let sfgCompleteHeading = (
            <>
                <div className='span-2-columns-container label-padding label-highlight height-35'>
                    <label className='parent-labels'>
                        Semi Finished Goods List
                    </label>
                </div>

                <div className='span-2-columns-container'>
                    <div className='view-table-heading m-zero'>
                        <div className='cell'>
                            <span className='m-left-15'>
                                Semi Finished Good
                            </span>
                        </div>

                        <div className='cell'>
                            <div className='flex-row width66'>
                                <span className='width50 m-right-10'>
                                    Transfer Qty
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
        let fgCompleteHeading = (
            <>
                <div className='span-2-columns-container label-padding label-highlight height-35'>
                    <label className='parent-labels'>Finished Goods List</label>
                </div>

                <div className='span-2-columns-container'>
                    <div className='view-table-heading m-zero'>
                        <div className='cell'>
                            <span className='m-left-15'>Finished Good</span>
                        </div>

                        <div className='cell'>
                            <div className='flex-row width66'>
                                <span className='width50 m-right-10'>
                                    Transfer Qty
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );

        if (rmArrUI.length === 0) rmCompleteHeading = null;
        if (sfgArrUI.length === 0) sfgCompleteHeading = null;
        if (fgArrUI.length === 0) fgCompleteHeading = null;

        noteUI = (
            <div className='span-2-columns-container hide-on-print'>
                <TextArea
                    label={<span className='parent-labels'>Notes</span>}
                    disabled
                    maxLength={lengths.longTextLength}
                    value={noteInState}
                />
            </div>
        );

        if (
            invTransferObj.meta.action === userActions.SENT_FOR_APPROVAL &&
            authUser.permissions.includes(permissions.INV_TRANSFER_APPROVE)
        ) {
            noteUI = (
                <div className='span-2-columns-container hide-on-print'>
                    <TextArea
                        label={<span className='parent-labels'>Notes</span>}
                        maxLength={lengths.noteLength}
                        value={noteInState}
                        onChange={(e) => setNoteInState(e.target.value)}
                        errorMessage={invTransferObj.noteError}
                    />
                </div>
            );
        }

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='parent-labels'>Inventory Transfer Number</p>
                    <p className=''>
                        {invTransferObj.invTransferUID || `(Auto Generated)`}
                    </p>
                </div>

                <div className='form-profile-info-items hide-on-print'>
                    <p className='fpii-label'>Status</p>
                    <p className='fpii-text'>
                        {invTransferObj.meta.action || '---'}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>{subjectLabel}</p>
                    <p className='fpii-text'>{invTransferObj.subjectUID}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Date</p>
                    <p className='fpii-text'>
                        {invTransferObj.invTransferDate
                            ? displayDate(invTransferObj.invTransferDate)
                            : '---'}
                    </p>
                </div>

                {rmCompleteHeading}
                {rmArrUI}

                {sfgCompleteHeading}
                {sfgArrUI}

                {fgCompleteHeading}
                {fgArrUI}

                {noteUI}

                <SignatureLine />
            </section>
        );
    }

    // =========================================================================
    // Button sections for users with Request permision ===================

    if (purpose === 'ADD' && invTransferObj.transferDirection !== null) {
        buttonSection = (
            <CreateItemButtons
                createText='Create Transfer'
                previewText='Preview Transfer'
                cancelPreviewFn={() => setPreview(false)}
                cancelFn={backCancelBtnFn}
                createFn={saveInvTransFn}
                previewFn={previewInvTransferBtnFn}
                isPreview={preview}
            />
        );
    }

    if (purpose === 'EDIT') {
        buttonSection = (
            <UpdateItemButtons
                saveFn={saveInvTransFn}
                cancelFn={cancelBtnFromEditViewFn}
            />
        );
    }

    if (purpose === 'DELETE') {
        buttonSection = (
            <DeleteItemButtons
                cancelFn={backCancelBtnFn}
                deleteFn={saveInvTransFn}
                confirmeDeleteFn={(e) => setConfirmDeleteText(e.target.value)}
                confirmDeleteTextValue={confirmDeleteText}
                confirmDeleteTextPlaceHolder='Enter Inventory Transfer Number'
                uidValue={invTransferObj.invTransferUID}
            />
        );
    }

    // ==================================================================
    // Button section for users with PDDR Request permission.
    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.INV_TRANSFER_REQUEST) &&
        !authUser.permissions.includes(permissions.INV_TRANSFER_APPROVE)
    ) {
        if (
            invTransferObj.meta.action === userActions.CREATED ||
            invTransferObj.meta.action === userActions.UPDATED ||
            invTransferObj.meta.action === userActions.REJECTED
        ) {
            buttonSection = (
                <BackSendEditDelButtons
                    backBtnFn={backCancelBtnFn}
                    sendForApprovalBtnFn={() =>
                        statusChangeLocalFn(userActions.SENT_FOR_APPROVAL)
                    }
                    editBtnFn={gotoEditViewFn}
                    deleteBtnFn={gotoDeleteViewFn}
                />
            );
        }
    }

    // =========================================================================
    // Button section for users with PDDR Approve permision ===================

    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.INV_TRANSFER_APPROVE) &&
        !authUser.permissions.includes(permissions.INV_TRANSFER_REQUEST)
    ) {
        // if document status is SENT_FOR_APPROVAL
        if (invTransferObj.meta.action === userActions.SENT_FOR_APPROVAL) {
            buttonSection = (
                <ApproveRejectButtons
                    backBtnFn={backCancelBtnFn}
                    approveBtnFn={() =>
                        statusChangeLocalFn(userActions.APPROVED)
                    }
                    rejectBtnFn={() =>
                        statusChangeLocalFn(userActions.REJECTED)
                    }
                />
            );
        }
    }

    // =========================================================================
    //! button section for users with both Inventory Transfer Request and Approve permissions.
    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.INV_TRANSFER_APPROVE) &&
        authUser.permissions.includes(permissions.INV_TRANSFER_REQUEST)
    ) {
        if (
            invTransferObj.meta.action === userActions.CREATED ||
            invTransferObj.meta.action === userActions.UPDATED ||
            invTransferObj.meta.action === userActions.REJECTED
        ) {
            buttonSection = (
                <BackSendEditDelButtons
                    backBtnFn={backCancelBtnFn}
                    sendForApprovalBtnFn={() =>
                        statusChangeLocalFn(userActions.SENT_FOR_APPROVAL)
                    }
                    editBtnFn={gotoEditViewFn}
                    deleteBtnFn={gotoDeleteViewFn}
                />
            );
        }

        // if document status is SENT_FOR_APPROVAL
        if (invTransferObj.meta.action === userActions.SENT_FOR_APPROVAL) {
            buttonSection = (
                <ApproveRejectButtons
                    backBtnFn={backCancelBtnFn}
                    approveBtnFn={() =>
                        statusChangeLocalFn(userActions.APPROVED)
                    }
                    rejectBtnFn={() =>
                        statusChangeLocalFn(userActions.REJECTED)
                    }
                />
            );
        }
    }

    if (!authUser.subscriptionStatusIsActive) {
        buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    }

    let generalErrorUI = (
        <div className='general-error-message'>{generalError}</div>
    );

    return (
        <div className='common-form'>
            <section className='form-title-section'>
                Inventory Transfer Form
            </section>

            {mainContent}

            {buttonSection}
            {generalErrorUI}

            <Watermark purpose={purpose} currentDocument={currentDocument} />
        </div>
    );
};
