import React from 'react';
import { sortReportItemsByAmount } from '../../lib/util';
import ReportsForm from './ReportsForm';
import ReportItemsEach from './ReportItemsEach';
import tooltips from '../../lib/tooltips';

export default ({ backCancelBtnFn, purchaseReportByItemObj }) => {
    let purchaseReportByItemUI = [];
    let itemPurchaseReport = [];
    let purchaseByItemMap = purchaseReportByItemObj.rmMap;

    for (let key in purchaseByItemMap) {
        let itemObj = {};
        itemObj.itemUID = key;
        itemObj.total = purchaseByItemMap[key];

        itemPurchaseReport.push(itemObj);
    }

    sortReportItemsByAmount(itemPurchaseReport);

    itemPurchaseReport.forEach((item, index) => {
        purchaseReportByItemUI.push(
            <ReportItemsEach
                key={index}
                index={index}
                item={item}
                mobileLabel='Purchase Amount'
            />
        );
    });
    return (
        <ReportsForm
            helpText={tooltips.reportPurchaseItem}
            uid={`Purchase Report by Item - ${purchaseReportByItemObj.reportMonth} ${purchaseReportByItemObj.reportYear}`}
            nameLabel='Raw Material'
            amountLabel='Purchase Amount'
            total={purchaseReportByItemObj.total}
            list={purchaseReportByItemUI}
            backCancelBtnFn={backCancelBtnFn}
        />
    );
};
