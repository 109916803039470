import React from 'react';
import { InputDS, HelpTip } from '../../components';
import tooltips from '../../lib/tooltips';
import '../PagesCommon.css';
import './AssemblyDeliveredPage.css';
import { displayReadableNumber } from '../../lib/util';

const GroupedRM = (props) => {
    let {
        rmArr,
        fgUID,
        fgIndex,
        updateRmActualUsedQtyFn,
        assemblyOrderIndex,
    } = props;

    let rms = rmArr.map((rm) => {
        let overrideInfo = '';
        if (rm.rmOverrideQty !== 0) {
            overrideInfo =
                rm.rmOverrideQty < 0
                    ? `(${displayReadableNumber(rm.rmOverrideQty)})`
                    : `(+${displayReadableNumber(rm.rmOverrideQty)})`;
        }

        return (
            <div
                className='span-2-columns-container'
                key={`${fgUID}${rm.rmUID}`}
            >
                <div className='view-table-row grid-row-40-60'>
                    <div className='cell'>
                        <div className='width16'>
                            <span className='mobile-label'>Grp:</span>{' '}
                            <span className='desktop-view m-left-15'>
                                {' '}
                                {rm.group}
                            </span>
                            <span className='mobile-label'> {rm.group}</span>
                        </div>
                        <div className=''>
                            <span className='mobile-label'>RM:</span>{' '}
                            <span> {rm.rmUID}</span>
                        </div>
                    </div>
                    <div className='cell'>
                        <div className='width33'>
                            <span className='mobile-label'>
                                Projected Qty (Planned):
                            </span>{' '}
                            <span>
                                {' '}
                                {displayReadableNumber(rm.rmProjectedUseQty)}
                                <span className='m-left-15'>{rm.rmUnit}</span>
                            </span>
                        </div>
                        <div className='width33'>
                            <span className='mobile-label'>Balance Qty:</span>{' '}
                            <span>
                                {displayReadableNumber(rm.rmBalanceQty)}{' '}
                                {overrideInfo}
                                <span className='m-left-15'>{rm.rmUnit}</span>
                            </span>
                        </div>
                        <div className='width33'>
                            {' '}
                            <span className='mobile-label'>
                                Actual Qty (Used):
                            </span>{' '}
                            <div className='flex-row'>
                                <span className='width66'>
                                    <InputDS
                                        type='number'
                                        initialValue={
                                            rm.rmUsedQtyForThisFgAssembled
                                        }
                                        getInputValue={(val) =>
                                            updateRmActualUsedQtyFn(
                                                val,
                                                assemblyOrderIndex,
                                                fgIndex,
                                                rm.tempRmIndex
                                            )
                                        }
                                        errorMessage={
                                            rm.rmUsedQtyForThisFgAssembledError
                                        }
                                    ></InputDS>
                                </span>
                                <span className='m-left-15 width33'>
                                    {rm.rmUnit}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    return <div className='span-2-columns-container'>{rms}</div>;
};

const GroupedSFG = (props) => {
    let {
        sfgArr,
        fgUID,
        fgIndex,
        updateSfgActualUsedQtyFn,
        assemblyOrderIndex,
    } = props;

    let sfgs = sfgArr.map((sfg) => {
        let overrideInfo = '';
        if (sfg.sfgOverrideQty !== 0) {
            overrideInfo =
                sfg.sfgOverrideQty < 0
                    ? `(${displayReadableNumber(sfg.sfgOverrideQty)})`
                    : `(+${displayReadableNumber(sfg.sfgOverrideQty)})`;
        }

        return (
            <div
                className='span-2-columns-container'
                key={`${fgUID}${sfg.sfgUID}`}
            >
                <div className='view-table-row grid-row-40-60'>
                    <div className='cell'>
                        <div className='width16'>
                            <span className='mobile-label'>Grp:</span>{' '}
                            <span className='desktop-view m-left-15'>
                                {' '}
                                {sfg.group}
                            </span>
                            <span className='mobile-label'> {sfg.group}</span>
                        </div>
                        <div className=''>
                            <span className='mobile-label'>SFG:</span>{' '}
                            <span> {sfg.sfgUID}</span>
                        </div>
                    </div>
                    <div className='cell'>
                        <div className='width33'>
                            <span className='mobile-label'>
                                Projected Qty (Planned):
                            </span>{' '}
                            <span>
                                {' '}
                                {displayReadableNumber(sfg.sfgProjectedUseQty)}
                                <span className='m-left-15'>{sfg.sfgUnit}</span>
                            </span>
                        </div>
                        <div className='width33'>
                            <span className='mobile-label'>Balance Qty:</span>{' '}
                            <span>
                                {displayReadableNumber(sfg.sfgBalanceQty)}{' '}
                                {overrideInfo}
                                <span className='m-left-15'>{sfg.sfgUnit}</span>
                            </span>
                        </div>
                        <div className='width33'>
                            {' '}
                            <span className='mobile-label'>
                                Actual Qty (Used):
                            </span>{' '}
                            <div className='flex-row'>
                                <span className='width66'>
                                    <InputDS
                                        type='number'
                                        initialValue={
                                            sfg.sfgUsedQtyForThisFgAssembled
                                        }
                                        getInputValue={(val) =>
                                            updateSfgActualUsedQtyFn(
                                                val,
                                                assemblyOrderIndex,
                                                fgIndex,
                                                sfg.tempSfgIndex
                                            )
                                        }
                                        errorMessage={
                                            sfg.sfgUsedQtyForThisFgAssembledError
                                        }
                                    ></InputDS>
                                </span>
                                <span className='m-left-15 width33'>
                                    {sfg.sfgUnit}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    return <div className='span-2-columns-container'>{sfgs}</div>;
};

export default (props) => {
    let {
        assemblyOrderIndex,
        fgObj,
        fgIndex,
        updateRmActualUsedQtyFn,
        updateSfgActualUsedQtyFn,
        updateFgAssembledQtyFn,
    } = props;

    let groupedRmMap = {};

    fgObj.rmArr.forEach((rm, rmIndex) => {
        rm.tempRmIndex = rmIndex;
        if (groupedRmMap[rm.group] === undefined) {
            groupedRmMap[rm.group] = [];
            groupedRmMap[rm.group].push(rm);
        } else groupedRmMap[rm.group].push(rm);
    });

    let listOfRms = [];

    for (let gkey in groupedRmMap) {
        let component = (
            <GroupedRM
                key={`${fgObj.fgUID}${gkey}`}
                fgUID={fgObj.fgUID}
                fgIndex={fgIndex}
                rmArr={groupedRmMap[gkey]}
                updateRmActualUsedQtyFn={updateRmActualUsedQtyFn}
                assemblyOrderIndex={assemblyOrderIndex}
            />
        );
        listOfRms.push(component);
    }

    let groupedSfgMap = {};

    fgObj.sfgArr.forEach((sfg, sfgIndex) => {
        sfg.tempSfgIndex = sfgIndex;
        if (groupedSfgMap[sfg.group] === undefined) {
            groupedSfgMap[sfg.group] = [];
            groupedSfgMap[sfg.group].push(sfg);
        } else groupedSfgMap[sfg.group].push(sfg);
    });

    let listOfSfgs = [];

    for (let gkey in groupedSfgMap) {
        let component = (
            <GroupedSFG
                key={`${fgObj.fgUID}${gkey}`}
                fgUID={fgObj.fgUID}
                fgIndex={fgIndex}
                sfgArr={groupedSfgMap[gkey]}
                updateSfgActualUsedQtyFn={updateSfgActualUsedQtyFn}
                assemblyOrderIndex={assemblyOrderIndex}
            />
        );
        listOfSfgs.push(component);
    }

    let overrideInfo = '';
    if (fgObj.fgOverrideQty !== 0) {
        overrideInfo =
            fgObj.fgOverrideQty < 0
                ? `(${displayReadableNumber(fgObj.fgOverrideQty)})`
                : `(+${displayReadableNumber(fgObj.fgOverrideQty)})`;
    }

    let rmSection = (
        <div className='span-2-columns-container'>
            <div className='view-table-heading grid-row-40-60'>
                <div className='cell'>
                    <span className='width16'>
                        <span className='m-left-15'>Grp</span>
                    </span>
                    <span>RM Items</span>
                </div>
                <div className='cell'>
                    <span className='width33'>Projected Qty (Planned)</span>
                    <span className='width33'>Balance Qty</span>
                    <span className='width33'>
                        Actual Qty (Used){' '}
                        <HelpTip content={tooltips.assemRmActualUsedQty} />
                    </span>
                </div>
            </div>
            {listOfRms}
        </div>
    );

    let sfgSection = (
        <div className='span-2-columns-container'>
            <div className='view-table-heading grid-row-40-60'>
                <div className='cell'>
                    <span className='width16'>
                        <span className='m-left-15'>Grp</span>
                    </span>
                    <span>SFG Items</span>
                </div>
                <div className='cell'>
                    <span className='width33'>Projected Qty (Planned)</span>
                    <span className='width33'>Balance Qty</span>
                    <span className='width33'>
                        Actual Qty (Used){' '}
                        <HelpTip content={tooltips.assemSfgActualUsedQty} />
                    </span>
                </div>
            </div>
            {listOfSfgs}
        </div>
    );

    if (listOfSfgs.length === 0) sfgSection = null;
    if (listOfRms.length === 0) rmSection = null;

    return (
        <div
            className='span-2-columns-container show-border pad-around-10 m-bottom-10'
            key={`${fgObj.fgUID}${fgIndex}`}
        >
            <div className='view-table-row grid-row-40-60 bg-grey-l2 pad-around-10'>
                <div className='cell'>
                    <span className='m-right-5'>{`${fgIndex + 1}. `}</span>
                    <span>{fgObj.fgUID}</span>
                </div>
                <div className='cell'>
                    <span className='width33 flex-column-to-row'>
                        <span className='font-size-13 text-bold m-right-5'>
                            Ordered Qty
                        </span>{' '}
                        <span>
                            {displayReadableNumber(fgObj.fgOrderedQty)}{' '}
                            <span className='font-size-13'>{fgObj.fgUnit}</span>
                        </span>
                    </span>
                    <span className='width33 flex-column-to-row'>
                        <span className='font-size-13 text-bold m-right-5'>
                            Balance Qty
                        </span>{' '}
                        <span>
                            {displayReadableNumber(fgObj.fgBalanceQty)}{' '}
                            {overrideInfo}{' '}
                            <span className='font-size-13'>{fgObj.fgUnit}</span>
                        </span>
                    </span>
                    <span className='width33 flex-column-to-row'>
                        <span className='font-size-13 text-bold m-right-5'>
                            Assembled Qty
                            <HelpTip content={tooltips.assemFgAssembledQty} />
                        </span>{' '}
                        <span className='flex-row'>
                            <span className='width66'>
                                <InputDS
                                    type='number'
                                    initialValue={
                                        fgObj.fgAssembledQtyForThisAssembly
                                    }
                                    getInputValue={(val) =>
                                        updateFgAssembledQtyFn(
                                            val,
                                            assemblyOrderIndex,
                                            fgIndex
                                        )
                                    }
                                    errorMessage={
                                        fgObj.fgAssembledQtyForThisAssemblyError
                                    }
                                ></InputDS>
                            </span>

                            <span className='font-size-13 m-left-5'>
                                {fgObj.fgUnit}
                            </span>
                        </span>
                    </span>
                </div>
            </div>

            {rmSection}
            {sfgSection}
        </div>
    );
};
