import React from 'react';

export default (props) => {
    let { backBtnFn, sendForApprovalBtnFn, editBtnFn, deleteBtnFn } = props;

    let buttonSection = (
        <section className='form-button-section'>
            <div className='width50'>
                <button className='goto-left btn' onClick={backBtnFn}>
                    Back
                </button>
            </div>
            <div className='width50 row mobile-m-top-2'>
                <button
                    className='m-right-15 btn btn-primary'
                    onClick={sendForApprovalBtnFn}
                >
                    Send for Approval
                </button>
                <button className='btn' onClick={editBtnFn}>
                    Edit
                </button>
                <button
                    className='m-left-15 btn btn-danger'
                    onClick={deleteBtnFn}
                >
                    Delete
                </button>
            </div>
        </section>
    );

    return buttonSection;
};
