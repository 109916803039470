export default (sfgMasterlistStringFormat) => {
    let newArr = [];
    let sfgObjsInObjFormat = JSON.parse(sfgMasterlistStringFormat);

    for (let key in sfgObjsInObjFormat) {
        newArr.push({
            sfgUID: key,
            sfgCode: sfgObjsInObjFormat[key].a,
            sfgPrice: sfgObjsInObjFormat[key].b,
            sfgUnit: sfgObjsInObjFormat[key].c,
        });
    }

    return newArr;
};
