import React, { useState, useEffect, useContext } from 'react';
import {
    Datalist,
    AddButton,
    Input,
    CreateItemButtons,
    UpdateItemButtons,
    DeleteItemButtons,
    BackOnlyButton,
    BackSendEditDelButtons,
    ApproveRejectButtons,
    HelpTip,
    TextArea,
    Watermark,
} from '../../components';
import tooltips from '../../lib/tooltips';

import {
    AuthContext,
    MasterListContext,
    DataShareContext,
} from '../../contexts';
import { dbopsAssembly } from '../../firebase_dbops';
import { permissions, userActions, lengths } from '../../lib/constants';
import { clone, displayDate, saveWithFiveDecimals } from '../../lib/util';

import AddedAssemblyOrders from './AddedAssemblyOrders';
import AddedAssemblyOrdersView from './AddedAssemblyOrdersView';

import validate from '../../validators';

import '../PagesCommon.css';
import './AssemblyDeliveredPage.css';

let defaultAssemblyDelivered = {
    assemblyDeliveredUID: '',
    assemblyDeliveredNumber: '',
    assemblyOrderArr: [],
    meta: { action: '' },
    note: '',
};

const cloneAssemblyDeliveredObj = (assemblyDeliveredObj) => {
    return {
        ...assemblyDeliveredObj,
        assemblyOrderArr: clone(assemblyDeliveredObj.assemblyOrderArr),
    };
};

export default (props) => {
    let {
        purpose,
        currentDocument,

        backCancelBtnFn,
        gotoEditViewFn,
        gotoDeleteViewFn,

        createAssemblyDeliveredFn,
        deleteAssemblyDeliveredFn,
        updateAssemblyDeliveredFn,
        statusChangeFn,

        setIsLoading,
        setAlertErrorMsg,
    } = props;

    let { masterAssemblyList } = useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);
    let { serverDate } = useContext(DataShareContext);

    let [generalError, setGeneralError] = useState('');
    let [
        datalistDisplayResetCounter,
        setDatalistDisplayResetCounter,
    ] = useState(1);

    let [assemblyDeliveredObj, setAssemblyDeliveredObj] = useState(
        defaultAssemblyDelivered
    );
    let [
        assemblyDeliveredUIDinState,
        setAssemlbyDeliveredUIDinState,
    ] = useState('');
    let [assemblyOrderUIDinState, setAssemblyOrderUIDinState] = useState('');
    let [
        assemblyDeliveredDateInState,
        setAssemblyDeliveredDateInState,
    ] = useState(serverDate);
    let [noteInState, setNoteInState] = useState('');
    let [description, setDescription] = useState('');

    let [addedAssemblyOrderError, setAddedAssemblyOrderError] = useState('');

    let [preview, setPreview] = useState(false);
    let [confirmDeleteText, setConfirmDeleteText] = useState('');

    const getAssemblyOrdersFn = async () => {
        setIsLoading(true);
        let tempAssemblyOrderArr = currentDocument.assemblyOrderArr.map(
            async (assemblyOrder) => {
                return await dbopsAssembly.getAssemblyFn(
                    assemblyOrder.assemblyUID
                );
            }
        );
        try {
            tempAssemblyOrderArr = await Promise.all(tempAssemblyOrderArr);
        } catch (e) {
            console.error(
                'Assembly - Orders have been Cleaned up by system already. Assembly - Delivered was never APPROVED. Edge case. '
            );
            setAlertErrorMsg(e.message || 'Error getting document');
            setIsLoading(true);
            return;
        }
        tempAssemblyOrderArr = tempAssemblyOrderArr.map(
            (dbAssemblyOrderObj) => dbAssemblyOrderObj.data
        );

        let assemblyDeliveredCopy = {
            ...currentDocument,
            assemblyOrderArr: clone(currentDocument.assemblyOrderArr),
        };

        assemblyDeliveredCopy.assemblyOrderArr.forEach(
            (assemblyOrder, aoIndex) => {
                assemblyOrder.fgArr.forEach((fg, fgIndex) => {
                    fg.fgOrderedQty =
                        tempAssemblyOrderArr[aoIndex].fgArr[
                            fgIndex
                        ].fgOrderedQty;
                    fg.fgBalanceQty =
                        tempAssemblyOrderArr[aoIndex].fgArr[
                            fgIndex
                        ].fgBalanceQty;
                    fg.fgOverrideQty =
                        tempAssemblyOrderArr[aoIndex].fgArr[
                            fgIndex
                        ].fgOverrideQty;

                    // Each fg has an rmArr.
                    fg.rmArr.forEach((rm, rmIndex) => {
                        rm.rmProjectedUseQty =
                            tempAssemblyOrderArr[aoIndex].fgArr[fgIndex].rmArr[
                                rmIndex
                            ].rmProjectedUseQty;
                        rm.rmBalanceQty =
                            tempAssemblyOrderArr[aoIndex].fgArr[fgIndex].rmArr[
                                rmIndex
                            ].rmBalanceQty;
                        rm.rmOverrideQty =
                            tempAssemblyOrderArr[aoIndex].fgArr[fgIndex].rmArr[
                                rmIndex
                            ].rmOverrideQty;
                    });

                    // Each fg has an sfgArr.
                    fg.sfgArr.forEach((sfg, sfgIndex) => {
                        sfg.sfgProjectedUseQty =
                            tempAssemblyOrderArr[aoIndex].fgArr[fgIndex].sfgArr[
                                sfgIndex
                            ].sfgProjectedUseQty;
                        sfg.sfgBalanceQty =
                            tempAssemblyOrderArr[aoIndex].fgArr[fgIndex].sfgArr[
                                sfgIndex
                            ].sfgBalanceQty;
                        sfg.sfgOverrideQty =
                            tempAssemblyOrderArr[aoIndex].fgArr[fgIndex].sfgArr[
                                sfgIndex
                            ].sfgOverrideQty;
                    });
                });
            }
        );
        setAssemblyDeliveredObj(assemblyDeliveredCopy);
        setAssemlbyDeliveredUIDinState(
            assemblyDeliveredCopy.assemblyDeliveredUID
        );
        setIsLoading(false);

        //! return tempAssemblyOrderArr as the currentProdOrderArr to
        //! be used for APPROVED status change.
        return tempAssemblyOrderArr;
    };

    // set assemblyDeliveredObj from currentDocument if purpose !== 'ADD'
    // set assemblyDeliveredObj from currentDocument if purpose !== 'ADD'
    useEffect(() => {
        if (purpose === 'VIEW' || purpose === 'DELETE') {
            // making a copy so no side effect will change currentDocument.
            let currentDocumentCopy = {
                // will copy meta and metaHistory as is.
                ...currentDocument,
                // copy sfgArr and not a reference.
                assemblyOrderArr: clone(currentDocument.assemblyOrderArr),
            };
            setAssemblyDeliveredDateInState(
                currentDocumentCopy.assemblyDeliveredDate
            );
            setAssemblyDeliveredObj(currentDocumentCopy);
            setNoteInState(currentDocumentCopy.note || '');
            setDescription(
                currentDocumentCopy.assemblyDeliveredDescription || ''
            );
        }

        if (purpose === 'EDIT') getAssemblyOrdersFn();
    }, [purpose]);

    const cancelBtnFromEditViewFn = () => {
        // 1. reset values.
        setAssemblyDeliveredObj(currentDocument);

        // 2. reset errorMessages.
        setGeneralError('');

        // 3. go back to profile view.
        backCancelBtnFn();
    };

    // =====================================================

    const deleteErrors = () => {
        delete assemblyDeliveredObj.assemblyOrderArrError;
        delete assemblyDeliveredObj.assemblyDeliveredDateError;
        delete assemblyDeliveredObj.noteError;
        delete assemblyDeliveredObj.assemblyDeliveredDescriptionError;

        assemblyDeliveredObj.assemblyOrderArr.forEach((assemblyOrder) => {
            assemblyOrder.fgArr.forEach((fg) => {
                delete fg.fgAssembledQtyForThisAssemblyError;

                fg.rmArr.forEach((rm) => {
                    delete rm.rmUsedQtyForThisFgAssembledError;
                });
                fg.sfgArr.forEach((sfg) => {
                    delete sfg.sfgUsedQtyForThisFgAssembledError;
                });
            });
        });
        setGeneralError('');
    };

    const addAssemblyOderToArrFn = async () => {
        deleteErrors();

        let assemblyDeliveredCopy = cloneAssemblyDeliveredObj(
            assemblyDeliveredObj
        );

        // get the assemblyOrder from firebase
        setIsLoading(true);

        let response = await dbopsAssembly.getAssemblyFn(
            assemblyOrderUIDinState
        );
        if (response.error) {
            setAddedAssemblyOrderError(
                response.errorObj.message || response.message
            );
            setIsLoading(false);
            return;
        }

        let assemblyOrderCopy = {
            ...response.data,
            fgArr: clone(response.data.fgArr),
        };

        assemblyOrderCopy.fgArr.forEach((fg) => {
            fg.fgAssembledQtyForThisAssembly = 0;

            fg.rmArr.forEach((rm) => {
                rm.rmUsedQtyForThisFgAssembled = 0;
            });
            fg.sfgArr.forEach((sfg) => {
                sfg.sfgUsedQtyForThisFgAssembled = 0;
            });
        });

        assemblyDeliveredCopy.assemblyOrderArr.push(assemblyOrderCopy);

        setAssemblyDeliveredObj(assemblyDeliveredCopy);
        setAddedAssemblyOrderError('');
        setDatalistDisplayResetCounter(datalistDisplayResetCounter + 1);
        setIsLoading(false);
    };

    const removeAssemblyOrderFromArrFn = (assemblyOrderIndex) => {
        deleteErrors();
        let assemblyDeliveredCopy = cloneAssemblyDeliveredObj(
            assemblyDeliveredObj
        );

        assemblyDeliveredCopy.assemblyOrderArr.splice(assemblyOrderIndex, 1);
        setAssemblyDeliveredObj(assemblyDeliveredCopy);
        setAddedAssemblyOrderError('');
    };

    const updateFgAssembledQtyFn = (val, assemblyOrderIndex, fgIndex) => {
        let assemblyDeliveredCopy = cloneAssemblyDeliveredObj(
            assemblyDeliveredObj
        );

        assemblyDeliveredCopy.assemblyOrderArr[assemblyOrderIndex].fgArr[
            fgIndex
        ].fgAssembledQtyForThisAssembly = val;

        //! =================================================
        let ordered =
            assemblyDeliveredCopy.assemblyOrderArr[assemblyOrderIndex].fgArr[
                fgIndex
            ].fgOrderedQty;

        //!  default value for rms that are alone in their group.
        let rmAloneMap = {};

        assemblyDeliveredCopy.assemblyOrderArr[assemblyOrderIndex].fgArr[
            fgIndex
        ].rmArr.forEach((rm) => {
            rmAloneMap[rm.group] =
                rmAloneMap[rm.group] !== undefined ? false : true;
        });
        assemblyDeliveredCopy.assemblyOrderArr[assemblyOrderIndex].fgArr[
            fgIndex
        ].rmArr.forEach((rm) => {
            if (rmAloneMap[rm.group])
                rm.rmUsedQtyForThisFgAssembled = saveWithFiveDecimals(
                    (val / ordered) * rm.rmProjectedUseQty
                );
        });
        //! =====================================================

        //!  default value for sfgs that are alone in their group.
        let sfgAloneMap = {};

        assemblyDeliveredCopy.assemblyOrderArr[assemblyOrderIndex].fgArr[
            fgIndex
        ].sfgArr.forEach((sfg) => {
            sfgAloneMap[sfg.group] =
                sfgAloneMap[sfg.group] !== undefined ? false : true;
        });
        assemblyDeliveredCopy.assemblyOrderArr[assemblyOrderIndex].fgArr[
            fgIndex
        ].sfgArr.forEach((sfg) => {
            if (sfgAloneMap[sfg.group])
                sfg.sfgUsedQtyForThisFgAssembled = saveWithFiveDecimals(
                    (val / ordered) * sfg.sfgProjectedUseQty
                );
        });
        //! =====================================================

        setAssemblyDeliveredObj(assemblyDeliveredCopy);
    };

    const updateRmActualUsedQtyFn = (
        val,
        assemblyOrderIndex,
        fgIndex,
        rmIndex
    ) => {
        let assemblyDeliveredCopy = cloneAssemblyDeliveredObj(
            assemblyDeliveredObj
        );

        assemblyDeliveredCopy.assemblyOrderArr[assemblyOrderIndex].fgArr[
            fgIndex
        ].rmArr[rmIndex].rmUsedQtyForThisFgAssembled = val;

        setAssemblyDeliveredObj(assemblyDeliveredCopy);
    };

    const updateSfgActualUsedQtyFn = (
        val,
        assemblyOrderIndex,
        fgIndex,
        sfgIndex
    ) => {
        let assemblyDeliveredCopy = cloneAssemblyDeliveredObj(
            assemblyDeliveredObj
        );

        assemblyDeliveredCopy.assemblyOrderArr[assemblyOrderIndex].fgArr[
            fgIndex
        ].sfgArr[sfgIndex].sfgUsedQtyForThisFgAssembled = val;

        setAssemblyDeliveredObj(assemblyDeliveredCopy);
    };

    // for saving functionality
    const previewAssemlbyDeliveredBtnFn = () => {
        let assemblyDeliveredCopy = cloneAssemblyDeliveredObj(
            assemblyDeliveredObj
        );
        assemblyDeliveredCopy.assemblyDeliveredDate = assemblyDeliveredDateInState;
        assemblyDeliveredCopy.assemblyDeliveredUID =
            assemblyDeliveredCopy.assemblyDeliveredUID ||
            assemblyDeliveredUIDinState;
        assemblyDeliveredCopy.note = noteInState;
        assemblyDeliveredCopy.assemblyDeliveredDescription = description;

        let response = validate.assemblyDelivered(assemblyDeliveredCopy);
        if (response.error) {
            setAssemblyDeliveredObj(response.errorObj.assemblyDeliveredObj);
            setAlertErrorMsg(response.errorObj.hackError);
            setGeneralError(response.errorObj.generalError);
        } else {
            // no error
            setAssemblyDeliveredObj(response.data.assemblyDeliveredObj);
            setPreview(true);
        }
    };

    const saveAssemblyDeliveredFn = () => {
        let assemblyDeliveredCopy = cloneAssemblyDeliveredObj(
            assemblyDeliveredObj
        );
        assemblyDeliveredCopy.assemblyDeliveredDate = assemblyDeliveredDateInState;
        assemblyDeliveredCopy.assemblyDeliveredUID =
            assemblyDeliveredCopy.assemblyDeliveredUID ||
            assemblyDeliveredUIDinState;
        assemblyDeliveredCopy.note = noteInState;
        assemblyDeliveredCopy.assemblyDeliveredDescription = description;

        // check if there is a change in the payload from currentDocument
        if (
            purpose === 'EDIT' &&
            validate.noChange(assemblyDeliveredCopy, currentDocument)
        ) {
            backCancelBtnFn();
            return;
        }

        let response = validate.assemblyDelivered(assemblyDeliveredCopy);
        if (response.error) {
            setAssemblyDeliveredObj(response.errorObj.assemblyDeliveredObj);
            setAlertErrorMsg(response.errorObj.hackError);
            setGeneralError(response.errorObj.generalError);
            return;
        }

        //! this will ensure no tempRmIndex or tempSfgIndex that the app puts into
        //! the assemblyDeliveredObj to display correctly.
        let validObj = response.data.validAssemblyDeliveredObj;
        setAssemblyDeliveredObj(response.data.assemblyDeliveredObj);

        // console.log(validObj);

        if (purpose === 'ADD') createAssemblyDeliveredFn(validObj);
        if (purpose === 'EDIT')
            updateAssemblyDeliveredFn(validObj, currentDocument);
        if (purpose === 'DELETE')
            deleteAssemblyDeliveredFn(validObj, currentDocument);
    };

    const statusChangeLocalFn = async (userActionPassed) => {
        let assemblyDeliveredCopy = cloneAssemblyDeliveredObj(
            assemblyDeliveredObj
        );
        assemblyDeliveredCopy.assemblyDeliveredDate = assemblyDeliveredDateInState;
        assemblyDeliveredCopy.assemblyDeliveredUID =
            assemblyDeliveredCopy.assemblyDeliveredUID ||
            assemblyDeliveredUIDinState;
        assemblyDeliveredCopy.note = noteInState;
        assemblyDeliveredCopy.assemblyDeliveredDescription = description;

        let response = validate.assemblyDelivered(assemblyDeliveredCopy);
        if (response.error) {
            setAssemblyDeliveredObj(response.errorObj.assemblyDeliveredObj);
            setAlertErrorMsg(response.errorObj.hackError);
            setGeneralError(response.errorObj.generalError);
            return;
        }

        let validObj = response.data.validAssemblyDeliveredObj;

        if (userActionPassed === userActions.APPROVED) {
            let documentBasisAssemblyOrderArr = await getAssemblyOrdersFn();
            statusChangeFn(
                userActionPassed,
                validObj,
                currentDocument,
                documentBasisAssemblyOrderArr
            );
        } else {
            statusChangeFn(userActionPassed, validObj, currentDocument);
        }
    };

    // conditional render of contents  =============================

    let assemblyOrderArrUI = [];
    let noteUI = null;

    let buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    let mainContent = null;
    let datalist = null;

    assemblyDeliveredObj = {
        ...assemblyDeliveredObj,
        assemblyOrderArr: clone(assemblyDeliveredObj.assemblyOrderArr),
    };

    let addBtn = <AddButton disabled />;

    let assemblyDeliveredDateDisplayUI = (
        <div className='form-profile-info-items'>
            <p className='fpii-label'>Assembly Date</p>
            <p className='fpii-text'>
                {assemblyDeliveredObj.assemblyDeliveredDate
                    ? displayDate(assemblyDeliveredObj.assemblyDeliveredDate)
                    : '---'}
            </p>
        </div>
    );

    let assemblyDeliveredDateInputUI = (
        <div className='form-profile-info-items'>
            <p className='fpii-label'>Assembly Date </p>
            <Input
                type='date'
                maxLength='12'
                onChange={(e) =>
                    setAssemblyDeliveredDateInState(e.target.value)
                }
                value={assemblyDeliveredDateInState}
                errorMessage={
                    assemblyDeliveredObj.assemblyDeliveredDateError
                }></Input>
        </div>
    );

    let assemblyOrderListLabel = (
        <div className='span-2-columns-container label-padding label-highlight'>
            <label className='parent-labels'>Assemlby Order List</label>
        </div>
    );

    if ((purpose === 'ADD' && !preview) || purpose === 'EDIT') {
        let datalistOptions = masterAssemblyList
            .filter((assemblyOrder) => {
                return (
                    assemblyOrder.status === userActions.APPROVED ||
                    assemblyOrder.status === userActions.OVERRIDDEN
                );
            })
            .map((assemblyOrder) => assemblyOrder.assemblyUID);
        let assemblyOrderAlreadyInTheUI = assemblyDeliveredObj.assemblyOrderArr.map(
            (assemblyOrder) => assemblyOrder.assemblyUID
        );

        if (
            datalistOptions.includes(assemblyOrderUIDinState) &&
            !assemblyOrderAlreadyInTheUI.includes(assemblyOrderUIDinState)
        )
            addBtn = <AddButton onClick={addAssemblyOderToArrFn} />;

        let datalistLabel = (
            <span>
                Add Assembly - Order
                <HelpTip content={tooltips.assemblyDeliveredArr}></HelpTip>
            </span>
        );

        datalist = (
            <div className='get-item-from-db-container'>
                <div className='datalist-with-addbtn-container'>
                    <Datalist
                        label={datalistLabel}
                        list={datalistOptions}
                        initialValue={''}
                        getInputValue={(val) => setAssemblyOrderUIDinState(val)}
                        errorMessage={addedAssemblyOrderError}
                        resetCounter={datalistDisplayResetCounter}></Datalist>
                    {addBtn}
                </div>
            </div>
        );

        for (
            let index = 0;
            index < assemblyDeliveredObj.assemblyOrderArr.length;
            index += 1
        ) {
            let trashIcon = (
                <i
                    className='ms-Icon ms-Icon--Delete clickable-icon'
                    aria-hidden='true'
                    onClick={() => removeAssemblyOrderFromArrFn(index)}></i>
            );

            assemblyOrderArrUI.push(
                <div className='span-2-columns-container' key={index}>
                    <AddedAssemblyOrders
                        trashIcon={trashIcon}
                        assemblyOrderIndex={index}
                        assemblyOrderObj={
                            assemblyDeliveredObj.assemblyOrderArr[index]
                        }
                        updateFgAssembledQtyFn={updateFgAssembledQtyFn}
                        updateRmActualUsedQtyFn={updateRmActualUsedQtyFn}
                        updateSfgActualUsedQtyFn={updateSfgActualUsedQtyFn}
                    />
                </div>
            );
        }

        if (assemblyOrderArrUI.length === 0) assemblyOrderListLabel = null;

        noteUI = (
            <div className='span-2-columns-container'>
                <TextArea
                    label={<span className='parent-labels'>Notes</span>}
                    maxLength={lengths.noteLength}
                    value={noteInState}
                    onChange={(e) => setNoteInState(e.target.value)}
                    errorMessage={assemblyDeliveredObj.noteError}
                />
            </div>
        );

        //! Max number of items in the list
        if (
            assemblyDeliveredObj.assemblyOrderArr.length >=
            lengths.assemblyDeliveredArr
        )
            datalist = null;

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Assembly Number</p>
                    <p className='fpii-text'>
                        {assemblyDeliveredObj.assemblyDeliveredUID ||
                            `(Auto Generated)`}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Status</p>
                    <p className='fpii-text'>
                        {(assemblyDeliveredObj.meta &&
                            assemblyDeliveredObj.meta.action) ||
                            '---'}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Description</p>
                    <Input
                        type='text'
                        maxLength={lengths.description}
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                        errorMessage={
                            assemblyDeliveredObj.assemblyDeliveredDescriptionError
                        }></Input>
                </div>

                {assemblyDeliveredDateInputUI}

                {assemblyOrderListLabel}

                <div className='span-2-columns-container'>
                    {assemblyOrderArrUI}
                </div>

                {datalist}

                {noteUI}
            </section>
        );
    }

    if (
        (purpose === 'ADD' && preview) ||
        purpose === 'VIEW' ||
        purpose === 'DELETE'
    ) {
        assemblyOrderArrUI = assemblyDeliveredObj.assemblyOrderArr.map(
            (assemblyOrder, index) => {
                return (
                    <div
                        className='span-2-columns-container'
                        key={assemblyOrder.assemblyUID}>
                        <AddedAssemblyOrdersView
                            assemblyOrderObj={assemblyOrder}
                            assemblyOrderIndex={index}
                        />
                    </div>
                );
            }
        );

        noteUI = (
            <div className='span-2-columns-container'>
                <TextArea
                    label={<span className='parent-labels'>Notes</span>}
                    disabled
                    maxLength={lengths.longTextLength}
                    value={noteInState}
                />
            </div>
        );

        if (
            assemblyDeliveredObj.meta.action ===
                userActions.SENT_FOR_APPROVAL &&
            authUser.permissions.includes(
                permissions.ASSEMBLY_DELIVERED_APPROVE
            )
        ) {
            noteUI = (
                <div className='span-2-columns-container'>
                    <TextArea
                        label={<span className='parent-labels'>Notes</span>}
                        maxLength={lengths.noteLength}
                        value={noteInState}
                        onChange={(e) => setNoteInState(e.target.value)}
                        errorMessage={assemblyDeliveredObj.noteError}
                    />
                </div>
            );
        }

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Assembly Number</p>
                    <p className='fpii-text'>
                        {assemblyDeliveredObj.assemblyDeliveredUID ||
                            `(Auto Generated)`}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Status</p>
                    <p className='fpii-text'>
                        {(assemblyDeliveredObj.meta &&
                            assemblyDeliveredObj.meta.action) ||
                            '---'}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Description</p>
                    <p className='fpii-text'>
                        {assemblyDeliveredObj.assemblyDeliveredDescription ||
                            ''}
                    </p>
                </div>

                {assemblyDeliveredDateDisplayUI}

                {assemblyOrderListLabel}

                <div className='span-2-columns-container'>
                    {assemblyOrderArrUI}
                </div>

                {noteUI}
            </section>
        );
    }

    // =========================================================================
    // Button sections for users with Request permision ===================

    if (purpose === 'ADD') {
        buttonSection = (
            <CreateItemButtons
                createText='Create'
                previewText='Preview'
                cancelPreviewFn={() => setPreview(false)}
                cancelFn={backCancelBtnFn}
                createFn={saveAssemblyDeliveredFn}
                previewFn={previewAssemlbyDeliveredBtnFn}
                isPreview={preview}
            />
        );
    }

    if (purpose === 'EDIT') {
        buttonSection = (
            <UpdateItemButtons
                saveFn={saveAssemblyDeliveredFn}
                cancelFn={cancelBtnFromEditViewFn}
            />
        );
    }

    if (purpose === 'DELETE') {
        buttonSection = (
            <DeleteItemButtons
                cancelFn={backCancelBtnFn}
                deleteFn={saveAssemblyDeliveredFn}
                confirmeDeleteFn={(e) => setConfirmDeleteText(e.target.value)}
                confirmDeleteTextValue={confirmDeleteText}
                confirmDeleteTextPlaceHolder='Enter Assembly Delivered Number'
                uidValue={assemblyDeliveredObj.assemblyDeliveredUID}
            />
        );
    }

    // ==================================================================
    // Button section for users with Request permission.
    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.ASSEMBLY_DELIVERED_REQUEST) &&
        !authUser.permissions.includes(permissions.ASSEMBLY_DELIVERED_APPROVE)
    ) {
        if (
            assemblyDeliveredObj.meta.action === userActions.CREATED ||
            assemblyDeliveredObj.meta.action === userActions.UPDATED ||
            assemblyDeliveredObj.meta.action === userActions.REJECTED
        ) {
            buttonSection = (
                <BackSendEditDelButtons
                    backBtnFn={backCancelBtnFn}
                    sendForApprovalBtnFn={() =>
                        statusChangeLocalFn(userActions.SENT_FOR_APPROVAL)
                    }
                    editBtnFn={gotoEditViewFn}
                    deleteBtnFn={gotoDeleteViewFn}
                />
            );
        }
    }

    // =========================================================================
    // Button section for users with Approve permision ===================

    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.ASSEMBLY_DELIVERED_APPROVE) &&
        !authUser.permissions.includes(permissions.ASSEMBLY_DELIVERED_REQUEST)
    ) {
        if (
            assemblyDeliveredObj.meta.action === userActions.SENT_FOR_APPROVAL
        ) {
            buttonSection = (
                <ApproveRejectButtons
                    backBtnFn={backCancelBtnFn}
                    approveBtnFn={() =>
                        statusChangeLocalFn(userActions.APPROVED)
                    }
                    rejectBtnFn={() =>
                        statusChangeLocalFn(userActions.REJECTED)
                    }
                />
            );
        }
    }

    // =========================================================================
    //! button section for users with both Request and Approve permissions.
    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.ASSEMBLY_DELIVERED_APPROVE) &&
        authUser.permissions.includes(permissions.ASSEMBLY_DELIVERED_REQUEST)
    ) {
        if (
            assemblyDeliveredObj.meta.action === userActions.CREATED ||
            assemblyDeliveredObj.meta.action === userActions.UPDATED ||
            assemblyDeliveredObj.meta.action === userActions.REJECTED
        ) {
            buttonSection = (
                <BackSendEditDelButtons
                    backBtnFn={backCancelBtnFn}
                    sendForApprovalBtnFn={() =>
                        statusChangeLocalFn(userActions.SENT_FOR_APPROVAL)
                    }
                    editBtnFn={gotoEditViewFn}
                    deleteBtnFn={gotoDeleteViewFn}
                />
            );
        }

        if (
            assemblyDeliveredObj.meta.action === userActions.SENT_FOR_APPROVAL
        ) {
            buttonSection = (
                <ApproveRejectButtons
                    backBtnFn={backCancelBtnFn}
                    approveBtnFn={() =>
                        statusChangeLocalFn(userActions.APPROVED)
                    }
                    rejectBtnFn={() =>
                        statusChangeLocalFn(userActions.REJECTED)
                    }
                />
            );
        }
    }

    if (!authUser.subscriptionStatusIsActive) {
        buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    }

    let generalErrorUI = (
        <div className='general-error-message'>{generalError}</div>
    );

    return (
        <div className='common-form'>
            <section className='form-title-section'>Assembly Form</section>

            {mainContent}

            {buttonSection}
            {generalErrorUI}

            <Watermark purpose={purpose} currentDocument={currentDocument} />
        </div>
    );
};
