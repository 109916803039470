import React, { useState, useRef } from 'react';
import { InputIcon } from '../../components';

export default (props) => {
    let { loginBtnFn, loginErrMsg, redirectLinkFn, views, purpose } = props;

    let [email, setEmail] = useState('');
    let [password, setPassword] = useState('');

    let passwordRef = useRef();
    let loginBtnRef = useRef();

    const emailID = 'emailID-loginForm';
    const passwordID = 'passwordID-loginForm';

    const pressEnterFn = (e) => {
        if (e.keyCode === 13 && e.target.id === emailID) {
            passwordRef.current.focus();
        }
        if (e.keyCode === 13 && e.target.id === passwordID) {
            loginBtnRef.current.click();
        }
    };

    let heading = '';
    let buttonText = '';
    let forgotLink = null;
    let redirectLink = null;

    if (purpose === 'login') {
        heading = 'Login To Your Account';
        buttonText = 'Login';
        forgotLink = (
            <div
                className='lgf-text-link'
                id={views.FORGET_PASSWORD}
                onClick={redirectLinkFn}
            >
                Forgot password
            </div>
        );
    }

    if (purpose === 'resendVerificationEmail') {
        heading = 'Resend Verification Email';
        buttonText = 'Resend';
        redirectLink = (
            <div className='redirect-text'>
                Go back to
                <span
                    className='email-sent-redirect-link'
                    id={views.LOGIN}
                    onClick={redirectLinkFn}
                >
                    Login
                </span>
                .
            </div>
        );
    }

    return (
        <div className='login-form'>
            <div className='lgf-heading'> {heading} </div>
            <InputIcon
                id={emailID}
                type='text'
                placeholder='Email'
                icon={
                    <i className='ms-Icon ms-Icon--Mail' aria-hidden='true'></i>
                }
                onKeyDown={pressEnterFn}
                onChange={(e) => setEmail(e.target.value)}
                value={email}
            />

            <InputIcon
                ref={passwordRef}
                id={passwordID}
                type='password'
                placeholder='Password'
                icon={
                    <i className='ms-Icon ms-Icon--Lock' aria-hidden='true'></i>
                }
                onKeyDown={pressEnterFn}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
            />

            {forgotLink}

            <div
                className='lgf-btn-ctrl btn btn-primary'
                ref={loginBtnRef}
                onKeyDown={pressEnterFn}
                onClick={() => loginBtnFn(email, password)}
            >
                {buttonText}
            </div>

            <div className='lgf-error-message'>{loginErrMsg}</div>

            {redirectLink}
        </div>
    );
};
