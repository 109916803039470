import React, { useState, useContext } from 'react';
import { dbopsRawMaterials } from '../../firebase_dbops';
import { MasterListContext } from '../../contexts';
import { InputIcon, ListView2, Checkbox, Select } from '../../components';
import InventoryRmItem from '../../components/CollectionItems/InventoryRmItem/InventoryRmItem';
import '../PagesCommon.css';
import '../RawMaterialsPage/RawMaterials.css';
import './InventoryRMs.css';

import InventoryRMsForm from './InventoryRMsForm';
import { clone, saveWithFiveDecimals } from '../../lib/util';

const view = {
    LIST_VIEW: 'listView',
    INV_RM: 'inventoryRmView',
};

const selectOptions = [
    { value: 'None' },
    { value: 'Type ⬆︎' },
    { value: 'Type ⬇︎' },
    { value: 'Description ⬆︎' },
    { value: 'Description ⬇︎' },
    { value: 'Category ⬆︎' },
    { value: 'Category ⬇︎' },
    { value: 'Unit ⬆︎' },
    { value: 'Unit ⬇︎' },
    { value: 'Low Threshold ⬆︎' },
    { value: 'Low Threshold ⬇︎' },
    { value: 'Projected Qty ⬆︎' },
    { value: 'Projected Qty ⬇︎' },
    { value: 'Actual Qty ⬆︎' },
    { value: 'Actual Qty ⬇︎' },
];

export default (props) => {
    let { setIsLoading, setAlertErrorMsg } = props;

    let { masterInventoryRMsList } = useContext(MasterListContext);

    let [formView, setFormView] = useState(view.LIST_VIEW);
    let [currentDocument, setCurrentDocument] = useState('');
    let [searchText, setSearchText] = useState('');
    let [showLowInventory, setShowLowInventory] = useState(false);

    let [sortValue, setSortValue] = useState('');

    // sorting functions =========================
    // Added 10-29-2023

    const sortFn = (sortVal, arrToSort) => {

        let propNameMap = {
            'Type ⬆︎': 'rmType',
            'Type ⬇︎': 'rmType',
            'Description ⬆︎': 'rmDescription',
            'Description ⬇︎': 'rmDescription',
            'Category ⬆︎': 'rmCategory',
            'Category ⬇︎': 'rmCategory',
            'Unit ⬆︎': 'rmUnit',
            'Unit ⬇︎': 'rmUnit',
            'Low Threshold ⬆︎': 'rmLowThreshold',
            'Low Threshold ⬇︎': 'rmLowThreshold',
            'Projected Qty ⬆︎': 'rmProjectedQty',
            'Projected Qty ⬇︎': 'rmProjectedQty',
            'Actual Qty ⬆︎': 'rmActualQty',
            'Actual Qty ⬇︎': 'rmActualQty',
        }

        let tempSortedArr = arrToSort.sort((a,b)=> {
            let propName = propNameMap[sortVal];
            let propA = a[propName];
            let propB = b[propName];

            if(sortVal.includes('⬆︎')) {
                if(propA > propB) return 1;
                if(propA < propB) return -1;
                return 0;
            } else {
                if(propA < propB) return 1;
                if(propA > propB) return -1;
                return 0;
            }
        });

        return tempSortedArr.map(item => item);
    }

    // End
    // sorting functions =========================


    // functions to change views.
    const gotoListViewFn = () => setFormView(view.LIST_VIEW);
    const gotoRawMaterialProfileViewFn = () => setFormView(view.INV_RM);

    // function to get supplier document.
    const getDocumentFromDBFn = async (docID) => {
        setIsLoading(true);
        let response = await dbopsRawMaterials.getRawMatFn(docID);
        if (response.error)
            setAlertErrorMsg(response.errorObj.message || response.message);
        else {
            setCurrentDocument(response.data);
            gotoRawMaterialProfileViewFn();
        }
        setIsLoading(false);
    };

    const toggleLowInventoryFn = () => {
        setShowLowInventory(!showLowInventory);
    };

    // filter function =====================================

    //! Alternate Unit feather added 2021-02-09.
    //! clone the masterInventoryRMsList to ensure that there will be
    //! no side effects in other pages.

    let masterInventoryRMListCopy = clone(masterInventoryRMsList);

    masterInventoryRMListCopy = masterInventoryRMListCopy.map((rm) => {
        if (rm.rmAltUnit) {
            rm.rmUnit = rm.rmAltUnit;
            rm.rmActualQty = saveWithFiveDecimals(
                rm.rmActualQty * rm.rmAltUnitMultiplier
            );
            rm.rmProjectedQty = saveWithFiveDecimals(
                rm.rmProjectedQty * rm.rmAltUnitMultiplier
            );
            rm.rmLowThreshold = saveWithFiveDecimals(
                rm.rmLowThreshold * rm.rmAltUnitMultiplier
            );
        }

        return rm;
    });

    //! check if copy is a reference or a copy.
    // console.log(masterInventoryRMListCopy);
    // console.log(masterInventoryRMsList);

    let filteredRawMatsList = masterInventoryRMListCopy.filter((rm) => {
        return (
            rm.rmUID.toUpperCase().includes(searchText.toUpperCase()) ||
            rm.rmCode.toUpperCase().includes(searchText.toUpperCase()) ||
            rm.rmCategory.toUpperCase().includes(searchText.toUpperCase()) ||
            rm.rmLowThreshold
                .toString()
                .toUpperCase()
                .includes(searchText.toUpperCase()) ||
            rm.rmProjectedQty
                .toString()
                .toUpperCase()
                .includes(searchText.toUpperCase()) ||
            rm.rmActualQty
                .toString()
                .toUpperCase()
                .includes(searchText.toUpperCase())
        );
    });

    if (showLowInventory) {
        filteredRawMatsList = filteredRawMatsList.filter((rm) => {
            return (
                rm.rmActualQty < rm.rmLowThreshold ||
                rm.rmProjectedQty < rm.rmLowThreshold
            );
        });
    }

    if(sortValue && sortValue !== 'None') {
        filteredRawMatsList = sortFn(sortValue, filteredRawMatsList)
    }
    // console.log('>>>>', filteredRawMatsList);


    let topSectionContent = (
        <section className='top-section'>
            <div className='flex-row-to-column'>
                <InputIcon
                    type='text'
                    placeholder='Search'
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    icon={
                        <i
                            className='ms-Icon ms-Icon--Search'
                            aria-hidden='true'></i>
                    }
                />

                <div className="sort-select">
                    <Select
                        label=''
                        optionsArr={selectOptions}
                        value={sortValue}
                        onChange={(e) => setSortValue(e.target.value)}
                        placeholder='Sort by'
                        errorMessage=''
                        placeholderText='Sort by...'
                    />
                </div>

                <span className='m-left-15'>
                    <Checkbox
                        label='Low Inventory'
                        onChange={toggleLowInventoryFn}
                    />
                </span>

            </div>
        </section>
    );

    let mainSectionContent = (
        <ListView2
            getDocumentFromDBFn={getDocumentFromDBFn}
            list={filteredRawMatsList}
            keyProperty={'rmUID'}
            CollectionItemComponent={InventoryRmItem}
        />
    );

    if (formView !== view.LIST_VIEW) topSectionContent = null;

    if (formView === view.INV_RM) {
        mainSectionContent = (
            <InventoryRMsForm
                currentDocument={currentDocument}
                backCancelBtnFn={gotoListViewFn}
            />
        );
    }

    return (
        <div className='content-layout'>
            {topSectionContent}

            <section className='main-section'>{mainSectionContent}</section>
        </div>
    );
};
