import React, { useState, useEffect, useContext } from 'react';
import { AuthContext, DataShareContext } from '../../contexts';
import {
    Input,
    Select,
    UpdateItemButtons,
    SetupViewButtons,
    BackOnlyButton,
    WarningTip,
    Watermark,
} from '../../components';
import tooltips from '../../lib/tooltips';
import { lengths, permissions } from '../../lib/constants';
import { displayReadableNumber } from '../../lib/util';

import validate from '../../validators';

import '../PagesCommon.css';
import './RawMaterials.css';

const createRmKeyFn = (prop1, prop2) => `${prop1} - ${prop2}`;

export default (props) => {
    let {
        purpose,
        currentDocument,

        backCancelBtnFn,
        gotoEditViewFn,
        gotoDeleteViewFn,

        createRawMatFn,
        deleteRawMatFn,
        updateRawMatFn,
    } = props;

    let { authUser } = useContext(AuthContext);

    let { rawMatCategoriesInDB } = useContext(DataShareContext);

    let [rmDescription, setRmDesciption] = useState('');
    let [rmCategory, setRmCategory] = useState('');
    let [rmCode, setRmCode] = useState('');
    let [rmType, setRmType] = useState('');
    let [rmUnit, setRmUnit] = useState('');
    let [rmLowThreshold, setRmLowThreshold] = useState('');
    let [confirmDeleteText, setConfirmDeleteText] = useState('');
    let [rmActualQty, setRmActualQty] = useState('');

    let [rmAltUnit, setRmAltUnit] = useState('');
    let [rmAltUnitMultiplier, setRmAltUnitMultiplier] = useState('');

    let [rmDescriptionErr, setRmDesciptionErr] = useState('');
    let [rmCategoryErr, setRmCategoryErr] = useState('');
    let [rmCodeErr, setRmCodeErr] = useState('');
    let [rmTypeErr, setRmTypeErr] = useState('');
    let [rmUnitErr, setRmUnitErr] = useState('');
    let [rmLowThresholdErr, setRmLowThresholdErr] = useState('');
    let [rmActualQtyErr, setRmActualQtyErr] = useState('');

    let [rmAltUnitErr, setRmAltUnitErr] = useState('');
    let [rmAltUnitMultiplierErr, setRmAltUnitMultiplierErr] = useState('');

    let [categoryOptions, setCategoryOptions] = useState([]);

    useEffect(() => {
        if (purpose !== 'ADD') {
            setRmDesciption(currentDocument.rmDescription);
            setRmCategory(currentDocument.rmCategory);
            setRmCode(currentDocument.rmCode);
            setRmType(currentDocument.rmType);
            setRmUnit(currentDocument.rmUnit);
            setRmLowThreshold(currentDocument.rmLowThreshold);
            setRmActualQty(currentDocument.rmActualQty);
            setRmAltUnit(currentDocument.rmAltUnit || '');
            setRmAltUnitMultiplier(currentDocument.rmAltUnitMultiplier || '');
        }
    }, []);

    useEffect(() => {
        let options = [];
        for (let key in rawMatCategoriesInDB) {
            if (rawMatCategoriesInDB[key])
                options.push({ value: rawMatCategoriesInDB[key] });
        }

        setCategoryOptions(options);
    }, [rawMatCategoriesInDB]);

    // =====================================================
    // Bug fix - 2020-03-25
    /*
        1. From profile view, when you press edit, it goes to edit view.
        2. If you change something but dont save it, then go back to profile view,
            the changes stays on UI even if actual values are not changed in DB.
        3. Must reset values of (RM/Customer/supplier Form) before going back to 
            profile view.
        4. 2020-03-28 - reset errorMessages also
    */

    const cancelBtnFromEditViewFn = () => {
        // 1. reset values.
        setRmDesciption(currentDocument.rmDescription);
        setRmCategory(currentDocument.rmCategory);
        setRmCode(currentDocument.rmCode);
        setRmType(currentDocument.rmType);
        setRmUnit(currentDocument.rmUnit);
        setRmLowThreshold(currentDocument.rmLowThreshold);
        setRmActualQty(currentDocument.rmActualQty);
        setRmAltUnit(currentDocument.rmAltUnit || '');
        setRmAltUnitMultiplier(currentDocument.rmAltUnitMultiplier || '');

        // 2. reset errorMessages
        setRmDesciptionErr('');
        setRmCategoryErr('');
        setRmCodeErr('');
        setRmTypeErr('');
        setRmUnitErr('');
        setRmLowThresholdErr('');
        setRmActualQtyErr('');
        setRmAltUnitMultiplierErr('');
        setRmAltUnitErr('');

        // 3. go back to profile view.
        backCancelBtnFn();
    };

    const saveBtnFn = () => {
        let rmObj = {
            // ensure that meta and metaHistory properties will be added in payload.
            ...currentDocument,
            // overwrite other properties
            rmDescription: rmDescription.trim(),
            rmCode: rmCode.trim(),
            rmType: rmType.trim(),
            rmUnit: rmUnit.trim(),
            rmAltUnit: rmAltUnit.trim(),

            // no need to trim. will be validated anyway inside validator function.
            rmLowThreshold,
            rmActualQty,
            rmAltUnitMultiplier,

            // no need to trim. suppliersArr is an Array, rmCategory coming from
            // select field.
            rmCategory,
        };

        // check if there is a change in the payload from currentDocument
        if (purpose === 'EDIT' && validate.noChange(rmObj, currentDocument)) {
            backCancelBtnFn();
            return;
        }

        // validate
        let response = validate.rawMaterial(
            rmObj,
            categoryOptions,
            purpose,
            currentDocument
        );

        // if response.error... must return so not to call dbops functions.
        if (response.error) {
            let e = response.errorObj;
            setRmDesciptionErr(e.rmDescriptionErr);
            setRmUnitErr(e.rmUnitErr);
            setRmTypeErr(e.rmTypeErr);
            setRmCodeErr(e.rmCodeErr);
            setRmLowThresholdErr(e.rmLowThresholdErr);
            setRmCategoryErr(e.rmCategoryErr);
            setRmActualQtyErr(e.rmActualQtyErr);
            setRmAltUnitErr(e.rmAltUnitErr);
            setRmAltUnitMultiplierErr(e.rmAltUnitMultiplierErr);

            return;
        }

        let fullSchemaRMObj = response.data;

        if (purpose === 'ADD') createRawMatFn(fullSchemaRMObj);
        if (purpose === 'EDIT')
            updateRawMatFn(fullSchemaRMObj, currentDocument);
        if (purpose === 'DELETE')
            deleteRawMatFn(fullSchemaRMObj, currentDocument);
    };

    // conditional render of contents  =============================
    let buttonSection = null;
    let mainContent = null;

    let descriptionInput = (
        <Input
            label='RM Description'
            maxLength={lengths.rmDesc}
            value={rmDescription}
            onChange={(e) => setRmDesciption(e.target.value)}
            placeholder='Required'
            errorMessage={rmDescriptionErr}
        />
    );
    let typeInput = (
        <Input
            label='RM Type'
            maxLength={lengths.rmType}
            value={rmType}
            onChange={(e) => setRmType(e.target.value)}
            placeholder='Required'
            errorMessage={rmTypeErr}
        />
    );
    if (purpose === 'EDIT') {
        descriptionInput = (
            <Input
                label='RM Description'
                value={rmDescription}
                errorMessage={rmDescriptionErr}
                disabled
            />
        );
        typeInput = (
            <Input
                label='RM Type'
                disabled
                value={rmType}
                errorMessage={rmTypeErr}
            />
        );
    }

    if (purpose === 'ADD' || purpose === 'EDIT') {
        // =================================================================
        // rmActual qty entry. during setup, requester and approver can input
        // rmActualQty without warning.
        // During edit, only admin can edit rmActualQty and with a warning.
        let rmActualQtyInputUI = (
            <div className='flex-row'>
                <div className='width100 text-warning-wrapper'>
                    <Input
                        label='RM Actual Qty'
                        type='number'
                        value={rmActualQty}
                        onChange={(e) => setRmActualQty(e.target.value)}
                        errorMessage={rmActualQtyErr}
                    />
                </div>

                <span className='m-left-15 m-top-20'>
                    <WarningTip content={tooltips.editingActualQty} />
                </span>
            </div>
        );

        if (
            purpose === 'EDIT' &&
            !authUser.permissions.includes(permissions.APP_ADMIN)
        ) {
            rmActualQtyInputUI = null;
        }

        if (purpose === 'ADD') {
            rmActualQtyInputUI = (
                <div className='flex-row'>
                    <div className='width100'>
                        <Input
                            label='RM Actual Qty'
                            type='number'
                            value={rmActualQty}
                            onChange={(e) => setRmActualQty(e.target.value)}
                            errorMessage={rmActualQtyErr}
                        />
                    </div>
                </div>
            );
        }

        // ===============================================================

        mainContent = (
            <section className='form-main-section'>
                {typeInput}
                {descriptionInput}
                <Select
                    label='RM Category'
                    optionsArr={categoryOptions}
                    value={rmCategory}
                    onChange={(e) => setRmCategory(e.target.value)}
                    placeholder='Required'
                    errorMessage={rmCategoryErr}
                />

                <Input
                    label='RM Code'
                    maxLength={lengths.code}
                    value={rmCode}
                    onChange={(e) => setRmCode(e.target.value)}
                    errorMessage={rmCodeErr}
                />
                <Input
                    label='RM Unit'
                    maxLength={lengths.unit}
                    value={rmUnit}
                    onChange={(e) => setRmUnit(e.target.value)}
                    placeholder='Required'
                    errorMessage={rmUnitErr}
                />
                <Input
                    label='RM Low Threshold'
                    type='number'
                    value={rmLowThreshold}
                    onChange={(e) => setRmLowThreshold(e.target.value)}
                    placeholder='Required'
                    errorMessage={rmLowThresholdErr}
                />
                <Input
                    label='RM Alternate Unit'
                    maxLength={lengths.unit}
                    value={rmAltUnit}
                    onChange={(e) => setRmAltUnit(e.target.value)}
                    errorMessage={rmAltUnitErr}
                />
                <Input
                    label='RM Alternate Unit Multiplier'
                    type='number'
                    value={rmAltUnitMultiplier}
                    onChange={(e) => setRmAltUnitMultiplier(e.target.value)}
                    errorMessage={rmAltUnitMultiplierErr}
                />
                {rmActualQtyInputUI}
            </section>
        );
    }

    if (purpose === 'VIEW' || purpose === 'DELETE') {
        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>RM Type</p>
                    <p className='fpii-text'>{rmType}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>RM Description</p>
                    <p className='fpii-text'>{rmDescription}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>RM Category</p>
                    <p className='fpii-text'>{rmCategory}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>RM Code</p>
                    <p className='fpii-text'>{rmCode}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>RM Unit</p>
                    <p className='fpii-text'>{rmUnit}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>RM Low Threshold</p>
                    <p className='fpii-text'>
                        {displayReadableNumber(rmLowThreshold)}
                    </p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>RM Alternate Unit</p>
                    <p className='fpii-text'>{rmAltUnit}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>RM Alternate Unit Multiplier</p>
                    <p className='fpii-text'>{rmAltUnitMultiplier}</p>
                </div>
            </section>
        );
    }

    if (purpose === 'ADD') {
        buttonSection = (
            <UpdateItemButtons saveFn={saveBtnFn} cancelFn={backCancelBtnFn} />
        );
    }

    if (purpose === 'EDIT') {
        buttonSection = (
            <UpdateItemButtons
                saveFn={saveBtnFn}
                cancelFn={cancelBtnFromEditViewFn}
            />
        );
    }

    if (purpose === 'DELETE') {
        let deleteBtn = (
            <button
                className='right-button-control btn btn-danger'
                onClick={saveBtnFn}>
                DELETE
            </button>
        );

        if (
            createRmKeyFn(rmType, rmDescription) !== confirmDeleteText ||
            (currentDocument &&
                (currentDocument.poNumbersArr.length > 0 ||
                    currentDocument.prodOrderNumbersArr.length > 0 ||
                    currentDocument.assemblyOrderNumbersArr.length > 0))
        ) {
            deleteBtn = (
                <button className='right-button-control btn btn-disable'>
                    DELETE
                </button>
            );
        }

        let deleteBtnSection = (
            <>
                <div className='confirm-delete-container'>
                    <input
                        id='delete-input'
                        placeholder={'Enter type - description'}
                        maxLength={50}
                        value={confirmDeleteText}
                        onChange={(e) => setConfirmDeleteText(e.target.value)}
                    />
                </div>
                {deleteBtn}
            </>
        );

        if (currentDocument && currentDocument.poNumbersArr.length > 0) {
            deleteBtnSection = (
                <div>Existing active Purchase Order(s). Cannot Delete</div>
            );
        }
        if (currentDocument && currentDocument.prodOrderNumbersArr.length > 0) {
            deleteBtnSection = (
                <div>Existing active Production Order(s). Cannot Delete</div>
            );
        }
        if (
            currentDocument &&
            currentDocument.assemblyOrderNumbersArr.length > 0
        ) {
            deleteBtnSection = (
                <div>Existing active Assembly Order(s). Cannot Delete</div>
            );
        }

        buttonSection = (
            <section className='form-button-section'>
                <button
                    className='left-button-control btn'
                    onClick={backCancelBtnFn}>
                    Cancel
                </button>
                {deleteBtnSection}
            </section>
        );
    }

    if (purpose === 'VIEW') {
        buttonSection = (
            <SetupViewButtons
                backBtnFn={backCancelBtnFn}
                deleteBtnFn={gotoDeleteViewFn}
                editBtnFn={gotoEditViewFn}
            />
        );
    }

    let generalError = <div className='general-error-message'></div>;

    if (
        !authUser.permissions.includes(permissions.RM_SETUP) ||
        !authUser.subscriptionStatusIsActive
    ) {
        buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    }

    return (
        <div className='common-form'>
            <section className='form-title-section'>
                Raw Material Profile
            </section>

            {mainContent}

            {buttonSection}
            {generalError}

            <Watermark purpose={purpose} currentDocument={currentDocument} />
        </div>
    );
};
