import React, { useState, useContext } from 'react';
import { dbopsCustomers } from '../../firebase_dbops';
import { MasterListContext, AuthContext } from '../../contexts';
import { InputIcon, ListView2 } from '../../components';
import CustomerItem from '../../components/CollectionItems/CustomerItem/CustomerItem';
import { permissions, lengths } from '../../lib/constants';

import '../PagesCommon.css';
import './CustomersPage.css';

import CustomersForm from './CustomersForm';

const view = {
    LIST_VIEW: 'listView',
    ADD_CUSTOMER: 'addCustomer',
    EDIT_CUSTOMER: 'editCustomer',
    DELETE_CUSTOMER: 'deleteCustomer',
    CUSTOMER_PROFILE: 'customerProfile',
};

export default (props) => {
    let { setIsLoading, setAlertErrorMsg, setAlertMessage } = props;

    let { masterCustomersList } = useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);

    let [formView, setFormView] = useState(view.LIST_VIEW);
    let [currentDocument, setCurrentDocument] = useState('');
    let [searchText, setSearchText] = useState('');

    // functions to change views.
    const addCustomerBtnFn = () => setFormView(view.ADD_CUSTOMER);
    const gotoListViewFn = () => setFormView(view.LIST_VIEW);
    const gotoEditViewFn = () => setFormView(view.EDIT_CUSTOMER);
    const gotoDeleteViewFn = () => setFormView(view.DELETE_CUSTOMER);
    const gotoCustomerProfileViewFn = () => setFormView(view.CUSTOMER_PROFILE);

    // function to get customer document.
    const getDocumentFromDBFn = async (docID) => {
        setIsLoading(true);
        let response = await dbopsCustomers.getCustomerFn(docID);
        if (response.error)
            setAlertErrorMsg(response.errorObj.message || response.message);
        else {
            setCurrentDocument(response.data);
            gotoCustomerProfileViewFn();
        }
        setIsLoading(false);
    };

    // function to create Customer
    const createCustomerFn = async (customerObj) => {
        // spinner on.
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        // write to db.
        let response = await dbopsCustomers.createCustomerFn(
            userCreds,
            customerObj
        );
        if (response.error) setAlertErrorMsg(response.errorObj.message);
        else setAlertMessage(response.message);

        gotoListViewFn();

        // spinner off.
        setIsLoading(false);
    };

    // =============================================
    const updateCustomerFn = async (customerObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsCustomers.updateCustomerFn(
            userCreds,
            customerObj,
            documentBasis
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);
            // if error is because document has already been deleted,
            // go back to list view.
            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
            // if error is because document has since changed, just continue
            // flow of the program.
        } else setAlertMessage(response.message);

        // this get document ensures state in FE state (currentDocument) is updated.
        gotoListViewFn();
        await getDocumentFromDBFn(customerObj.customerUID);

        setIsLoading(false);
    };

    // function to delete Customer.
    const deleteCustomerFn = async (customerObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsCustomers.deleteCustomerFn(
            userCreds,
            customerObj,
            documentBasis
        );

        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);

            // if error is because document has changed before changes
            // could be applied, get latest version of customer and
            // go back to customer profile view.
            if (response.errorObj.code === 1001) {
                gotoListViewFn();
                await getDocumentFromDBFn(customerObj.customerUID);
                setIsLoading(false); // redundant
                return;
            }
            // all other errors can continue flow of program.
        } else setAlertMessage(response.message);

        gotoListViewFn();

        setIsLoading(false);
    };

    // filter function =====================================
    let filteredCustomersList = masterCustomersList.filter((customer) => {
        return (
            customer.customerUID
                .toUpperCase()
                .includes(searchText.toUpperCase()) ||
            customer.contactPerson
                .toUpperCase()
                .includes(searchText.toUpperCase()) ||
            customer.contactNumber1
                .toUpperCase()
                .includes(searchText.toUpperCase()) ||
            customer.priceListNum
                .toUpperCase()
                .includes(searchText.toUpperCase())
        );
    });

    // Render conditionals ====================================
    // ========================================================

    let addCustomerBtnUI = (
        <button className='btn btn-primary' onClick={addCustomerBtnFn}>
            Create Customer
        </button>
    );
    if (
        !authUser.permissions.includes(permissions.CUSTOMER_SETUP) ||
        masterCustomersList.length >= lengths.masterListLimit ||
        !authUser.subscriptionStatusIsActive
    ) {
        addCustomerBtnUI = null;
    }

    let topSectionContent = (
        <section className='top-section'>
            <InputIcon
                // id='suppliers-search-input'
                type='text'
                placeholder='Search'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                icon={
                    <i
                        className='ms-Icon ms-Icon--Search'
                        aria-hidden='true'
                    ></i>
                }
            />

            {addCustomerBtnUI}
        </section>
    );

    let mainSectionContent = (
        <ListView2
            getDocumentFromDBFn={getDocumentFromDBFn}
            list={filteredCustomersList}
            keyProperty={'customerUID'}
            CollectionItemComponent={CustomerItem}
        />
    );

    if (formView !== view.LIST_VIEW) topSectionContent = null;

    if (formView === view.ADD_CUSTOMER) {
        mainSectionContent = (
            <CustomersForm
                purpose='ADD'
                backCancelBtnFn={gotoListViewFn}
                createCustomerFn={createCustomerFn}
            />
        );
    }

    if (formView === view.EDIT_CUSTOMER) {
        mainSectionContent = (
            <CustomersForm
                purpose='EDIT'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoCustomerProfileViewFn}
                updateCustomerFn={updateCustomerFn}
            />
        );
    }

    if (formView === view.DELETE_CUSTOMER) {
        mainSectionContent = (
            <CustomersForm
                purpose='DELETE'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoCustomerProfileViewFn}
                deleteCustomerFn={deleteCustomerFn}
            />
        );
    }

    if (formView === view.CUSTOMER_PROFILE) {
        mainSectionContent = (
            <CustomersForm
                purpose='VIEW'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoListViewFn}
                gotoEditViewFn={gotoEditViewFn}
                gotoDeleteViewFn={gotoDeleteViewFn}
            />
        );
    }

    return (
        <div className='content-layout'>
            {topSectionContent}

            <section className='main-section'>{mainSectionContent}</section>
        </div>
    );
};
