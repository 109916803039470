import React, { useState } from 'react';
import { getWaterMarkDateTime, appDateShort } from '../../lib/util';

import './Watermark.css';

export default ({ purpose, currentDocument }) => {
    let [showTrail, setShowTrail] = useState(false);

    let watermark = <div className='watermark'></div>;
    if (purpose === 'VIEW' && currentDocument.meta.timeStamp !== undefined) {
        let waterMarkText = getWaterMarkDateTime(
            currentDocument.meta.timeStamp.toDate()
        );
        watermark = (
            <div className='watermark'>
                <span onClick={() => setShowTrail(true)}>
                    <span>+</span>
                    {waterMarkText}
                </span>
            </div>
        );

        if (showTrail) {
            let list = currentDocument.metaHistory.map((history, index) => {
                return (
                    <div className='audit-trail-list' key={index}>
                        <span>{history.action}</span>
                        <span>{appDateShort(history.timeStamp.toDate())}</span>
                        <span>{history.email}</span>
                    </div>
                );
            });

            list.push(
                <div className='audit-trail-list' key='current'>
                    <span>{currentDocument.meta.action}</span>
                    <span>
                        {appDateShort(currentDocument.meta.timeStamp.toDate())}
                    </span>
                    <span>{currentDocument.meta.email}</span>
                </div>
            );

            list.reverse();

            watermark = (
                <div className='audit-trail'>
                    <div className='audit-cancel-button'>
                        <span onClick={() => setShowTrail(false)}>Close</span>
                    </div>
                    <div className='audit-trail-heading'>
                        <span>Status</span>
                        <span>Date</span>
                        <span>User</span>
                    </div>
                    {list}
                </div>
            );
        }
    }

    return watermark;
};
