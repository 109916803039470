import React, { useState, useContext } from 'react';
import { dbopsGetDocument } from '../../firebase_dbops';
import { MasterListContext, AuthContext } from '../../contexts';
import { InputIcon, ListView2 } from '../../components';
import ReportItem from '../../components/CollectionItems/ReportItem/ReportItem';
import { REPORTS_COL } from '../../lib/constants';
import '../PagesCommon.css';

import SalesReportByCustomer from './SalesReportByCustomer';
import SalesReportByItem from './SalesReportByItem';
import AccountsReceivables from './AccountsReceivables';

import PurchaseReportBySupplier from './PurchaseReportBySupplier';
import PurchaseReportByItem from './PurchaseReportByItem';

import SemiFgReportForm from './SemiFgReportForm';
import FgReportForm from './FgReportForm';
import RmReportForm from './RmReportForm';

const view = {
    LIST_VIEW: 'listView',
    SALES_REPORT_ITEM: 'salesReportItem',
    SALES_REPORT_CUSTOMER: 'salesReportCustomer',
    SALES_REPORT_AR: 'salesReportAR',
    PURCHASE_REPORT_ITEM: 'purchaseByItem',
    PURCHASE_REPORT_SUPPLIER: 'purchaseBySupplier',
    PURCHASE_REPORT_AP: 'purchaseReportAP',
    SEMIFG_REPORT: 'semiFGReport',
    FG_REPORT: 'fgReport',
    RM_REPORT: 'rmReport',
};

export default (props) => {
    let { setIsLoading, setAlertErrorMsg } = props;

    let { masterReportsList } = useContext(MasterListContext);

    let [formView, setFormView] = useState(view.LIST_VIEW);
    let [currentDocument, setCurrentDocument] = useState('');
    let [searchText, setSearchText] = useState('');

    // helper fn for setting view.
    const setViewFrom = (uid) => {
        if (uid.includes('Sales Report by Item'))
            setFormView(view.SALES_REPORT_ITEM);
        if (uid.includes('Sales Report by Customer'))
            setFormView(view.SALES_REPORT_CUSTOMER);
        if (uid.includes('Accounts Receivables'))
            setFormView(view.SALES_REPORT_AR);
        if (uid.includes('Purchase Report by Item'))
            setFormView(view.PURCHASE_REPORT_ITEM);
        if (uid.includes('Purchase Report by Supplier'))
            setFormView(view.PURCHASE_REPORT_SUPPLIER);
        if (uid.includes('Accounts Payables'))
            setFormView(view.PURCHASE_REPORT_AP);
        if (uid.includes('Semi')) setFormView(view.SEMIFG_REPORT);
        if (!uid.includes('Semi') && uid.includes('Finished'))
            setFormView(view.FG_REPORT);
        if (uid.includes('Raw Materials')) setFormView(view.RM_REPORT);
    };

    // function to get user document.
    const getDocumentFromDBFn = async (docID) => {
        setIsLoading(true);
        let response = await dbopsGetDocument(REPORTS_COL, docID);
        if (response.error)
            setAlertErrorMsg(response.errorObj.message || response.message);
        else {
            setCurrentDocument(response.data);
            // setState for which form to use.
            setViewFrom(response.data.reportUID);

            // go to that view.
            // gotoUserProfileViewFn();
        }
        setIsLoading(false);
    };

    // filter function =====================================
    let filteredReportsList = masterReportsList.filter((report) => {
        return (
            report.reportUID.toUpperCase().includes(searchText.toUpperCase()) ||
            report.total.toString().includes(searchText.toUpperCase())
        );
    });
    // default sorting to display
    filteredReportsList = filteredReportsList.sort((a, b) => {
        if (a.reportUID > b.reportUID) return -1;

        if (a.reportUID < b.reportUID) return 1;

        return 0;
    });

    let topSectionContent = (
        <section className='top-section'>
            <InputIcon
                type='text'
                placeholder='Search'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                icon={
                    <i
                        className='ms-Icon ms-Icon--Search'
                        aria-hidden='true'></i>
                }
            />
        </section>
    );

    let mainSectionContent = (
        <ListView2
            getDocumentFromDBFn={getDocumentFromDBFn}
            list={filteredReportsList}
            keyProperty={'reportUID'}
            CollectionItemComponent={ReportItem}
        />
    );

    if (formView !== view.LIST_VIEW) topSectionContent = null;

    if (formView === view.SALES_REPORT_CUSTOMER) {
        mainSectionContent = (
            <SalesReportByCustomer
                salesReportByCustomerObj={currentDocument}
                backCancelBtnFn={() => setFormView(view.LIST_VIEW)}
            />
        );
    }

    if (formView === view.SALES_REPORT_ITEM) {
        mainSectionContent = (
            <SalesReportByItem
                salesReportByItemObj={currentDocument}
                backCancelBtnFn={() => setFormView(view.LIST_VIEW)}
            />
        );
    }

    if (formView === view.SALES_REPORT_AR) {
        mainSectionContent = (
            <AccountsReceivables
                accountsReceivablesObj={currentDocument}
                backCancelBtnFn={() => setFormView(view.LIST_VIEW)}
            />
        );
    }
    if (formView === view.PURCHASE_REPORT_SUPPLIER) {
        mainSectionContent = (
            <PurchaseReportBySupplier
                purchaseReportBySupplierObj={currentDocument}
                backCancelBtnFn={() => setFormView(view.LIST_VIEW)}
            />
        );
    }
    if (formView === view.PURCHASE_REPORT_AP) {
        mainSectionContent = (
            <PurchaseReportBySupplier
                forPayable={true}
                purchaseReportBySupplierObj={currentDocument}
                backCancelBtnFn={() => setFormView(view.LIST_VIEW)}
            />
        );
    }
    if (formView === view.PURCHASE_REPORT_ITEM) {
        mainSectionContent = (
            <PurchaseReportByItem
                purchaseReportByItemObj={currentDocument}
                backCancelBtnFn={() => setFormView(view.LIST_VIEW)}
            />
        );
    }
    if (formView === view.SEMIFG_REPORT) {
        mainSectionContent = (
            <SemiFgReportForm
                semiFgReportObj={currentDocument}
                backCancelBtnFn={() => setFormView(view.LIST_VIEW)}
            />
        );
    }
    if (formView === view.FG_REPORT) {
        mainSectionContent = (
            <FgReportForm
                fgReportObj={currentDocument}
                backCancelBtnFn={() => setFormView(view.LIST_VIEW)}
            />
        );
    }
    if (formView === view.RM_REPORT) {
        mainSectionContent = (
            <RmReportForm
                rmReportObj={currentDocument}
                backCancelBtnFn={() => setFormView(view.LIST_VIEW)}
            />
        );
    }

    return (
        <div className='content-layout'>
            {topSectionContent}

            <section className='main-section'>{mainSectionContent}</section>
        </div>
    );
};
