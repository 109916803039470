import React, { useState, useContext } from 'react';
import { dbopsProductionDelivered } from '../../firebase_dbops';
import { MasterListContext, AuthContext } from '../../contexts';
import { InputIcon, ListView2 } from '../../components';
import OperationItem from '../../components/CollectionItems/OperationItem/OperationItem';
import { displayDate } from '../../lib/util';

import { permissions, userActions, lengths } from '../../lib/constants';
import '../PagesCommon.css';
import './ProductionDeliveredPage.css';

import ProductionDeliveredForm from './ProductionDeliveredForm';

const view = {
    LIST_VIEW: 'listView',
    CREATE_PRODUCTION_DELIVERED: 'createProductionDelivered',
    EDIT_PRODUCTION_DELIVERED: 'editProductionDelivered',
    DELETE_PRODUCTION_DELIVERED: 'deleteProductionDelivered',
    PRODUCTION_DELIVERED_PROFILE: 'productionDeliveredProfile',
};

export default (props) => {
    let { setIsLoading, setAlertErrorMsg, setAlertMessage } = props;

    let { masterProductionDeliveredList } = useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);

    let [formView, setFormView] = useState(view.LIST_VIEW);
    let [currentDocument, setCurrentDocument] = useState('');
    let [searchText, setSearchText] = useState('');

    // functions to change views.
    const addProductionDeliveredFn = () =>
        setFormView(view.CREATE_PRODUCTION_DELIVERED);
    const gotoListViewFn = () => setFormView(view.LIST_VIEW);
    const gotoEditViewFn = () => setFormView(view.EDIT_PRODUCTION_DELIVERED);
    const gotoDeleteViewFn = () =>
        setFormView(view.DELETE_PRODUCTION_DELIVERED);
    const gotoSfgProducedProfileViewFn = () =>
        setFormView(view.PRODUCTION_DELIVERED_PROFILE);

    // function to get productionDelivered document.
    const getDocumentFromDBFn = async (docID) => {
        setIsLoading(true);
        let response = await dbopsProductionDelivered.getSfgProducedFn(docID);
        if (response.error)
            setAlertErrorMsg(response.errorObj.message || response.message);
        else {
            setCurrentDocument(response.data);
            gotoSfgProducedProfileViewFn();
        }
        setIsLoading(false);
    };

    // function to create Supplier
    const createProductionDeliveredFn = async (productionDeliveredObj) => {
        // spinner on.
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        // write to db.
        let response = await dbopsProductionDelivered.createProductionDeliveredFn(
            userCreds,
            productionDeliveredObj
        );
        if (response.error) setAlertErrorMsg(response.errorObj.message);
        else setAlertMessage(response.message);

        gotoListViewFn();

        // spinner off.
        setIsLoading(false);
    };

    // function to update productionDelivered.
    const updateProductionDeliveredFn = async (
        productionDeliveredObj,
        documentBasis,
        userActionPassed
    ) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsProductionDelivered.updateProductionDeliveredFn(
            userCreds,
            productionDeliveredObj,
            documentBasis
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);
            // if error is because document has already been deleted,
            // go back to list view.
            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
            // if error is because document has since changed, just continue
            // flow of the program.
        } else setAlertMessage(response.message);

        // this get document ensures state in FE state (currentDocument) is updated.
        gotoListViewFn();
        await getDocumentFromDBFn(
            productionDeliveredObj.productionDeliveredUID
        );

        setIsLoading(false);
    };

    // function to delete productionDelivered.
    const deleteProductionDeliveredFn = async (
        productionDeliveredObj,
        documentBasis
    ) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsProductionDelivered.deleteProductionDeliveredFn(
            userCreds,
            productionDeliveredObj,
            documentBasis
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);

            // if error is because document has changed before changes
            // could be applied, get latest version of productionDelivered and
            // go back to productionDelivered profile view.
            if (response.errorObj.code === 1001) {
                gotoListViewFn();
                await getDocumentFromDBFn(
                    productionDeliveredObj.productionDeliveredUID
                );
                setIsLoading(false);
                return;
            }
            // all other errors can continue flow of program.
        } else setAlertMessage(response.message);

        gotoListViewFn();

        setIsLoading(false);
    };

    // function for status change of document.
    const statusChangeFn = async (
        userActionPassed,
        productionDeliveredObj,
        documentBasis,
        documentBasisProdOrderArr
    ) => {
        setIsLoading(true);

        let userCreds = {
            action: userActionPassed,
            uid: authUser.uid,
            email: authUser.email,
        };

        let dbFn = dbopsProductionDelivered.statusChangeFn;
        if (userActionPassed === userActions.APPROVED) {
            dbFn = dbopsProductionDelivered.approveProducedSfgFn;
        }

        let response = await dbFn(
            userCreds,
            productionDeliveredObj,
            documentBasis,
            documentBasisProdOrderArr
        );
        if (response.error) {
            // meaning: response.data contains detailed error obj.
            if (response.data) setAlertErrorMsg(response.data);
            else setAlertErrorMsg(response.errorObj.message);

            // if error is because document has already been deleted,
            // go back to list view.
            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
            // if error is because document has since changed, just continue
            // flow of the program.
        } else setAlertMessage(response.message);

        // this get document ensures state in FE state (currentDocument) is updated.
        gotoListViewFn();
        await getDocumentFromDBFn(
            productionDeliveredObj.productionDeliveredUID
        );

        setIsLoading(false);
    };

    // filter function =====================================
    let filteredProductionDeliveredList = masterProductionDeliveredList.filter(
        (productionDelivered) => {
            return (
                productionDelivered.productionDeliveredUID
                    .toUpperCase()
                    .includes(searchText.toUpperCase()) ||
                displayDate(productionDelivered.productionDeliveredDate)
                    .toUpperCase()
                    .includes(searchText.toUpperCase()) ||
                productionDelivered.status
                    .toUpperCase()
                    .includes(searchText.toUpperCase()) ||
                productionDelivered.productionDeliveredDescription
                    .toUpperCase()
                    .includes(searchText.toUpperCase())
            );
        }
    );

    let addProductionDeliveredBtnUI = (
        <button className='btn btn-primary' onClick={addProductionDeliveredFn}>
            Create Production
        </button>
    );
    if (
        !authUser.permissions.includes(
            permissions.PRODUCTION_DELIVERED_REQUEST
        ) ||
        masterProductionDeliveredList.length >= lengths.masterPOSDeptsLimits ||
        !authUser.subscriptionStatusIsActive
    ) {
        addProductionDeliveredBtnUI = null;
    }

    let topSectionContent = (
        <section className='top-section'>
            <InputIcon
                id='suppliers-search-input'
                type='text'
                placeholder='Search'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                icon={
                    <i
                        className='ms-Icon ms-Icon--Search'
                        aria-hidden='true'></i>
                }
            />
            {addProductionDeliveredBtnUI}
        </section>
    );

    let mainSectionContent = (
        <ListView2
            getDocumentFromDBFn={getDocumentFromDBFn}
            list={filteredProductionDeliveredList}
            keyProperty={'productionDeliveredUID'}
            CollectionItemComponent={OperationItem}
        />
    );

    if (formView !== view.LIST_VIEW) topSectionContent = null;

    if (formView === view.CREATE_PRODUCTION_DELIVERED) {
        mainSectionContent = (
            <ProductionDeliveredForm
                purpose='ADD'
                backCancelBtnFn={gotoListViewFn}
                createProductionDeliveredFn={createProductionDeliveredFn}
                setIsLoading={setIsLoading}
                setAlertErrorMsg={setAlertErrorMsg}
            />
        );
    }

    if (formView === view.EDIT_PRODUCTION_DELIVERED) {
        mainSectionContent = (
            <ProductionDeliveredForm
                purpose='EDIT'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoSfgProducedProfileViewFn}
                updateProductionDeliveredFn={updateProductionDeliveredFn}
                setAlertErrorMsg={setAlertErrorMsg}
                setIsLoading={setIsLoading}
            />
        );
    }

    if (formView === view.DELETE_PRODUCTION_DELIVERED) {
        mainSectionContent = (
            <ProductionDeliveredForm
                purpose='DELETE'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoSfgProducedProfileViewFn}
                deleteProductionDeliveredFn={deleteProductionDeliveredFn}
                setAlertErrorMsg={setAlertErrorMsg}
                setIsLoading={setIsLoading}
            />
        );
    }

    if (formView === view.PRODUCTION_DELIVERED_PROFILE) {
        mainSectionContent = (
            <ProductionDeliveredForm
                purpose='VIEW'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoListViewFn}
                gotoEditViewFn={gotoEditViewFn}
                gotoDeleteViewFn={gotoDeleteViewFn}
                setIsLoading={setIsLoading}
                statusChangeFn={statusChangeFn}
                setAlertErrorMsg={setAlertErrorMsg}
            />
        );
    }

    return (
        <div className='content-layout'>
            {topSectionContent}

            <section className='main-section'>{mainSectionContent}</section>
        </div>
    );
};
