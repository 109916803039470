export default (listStringFormat, prefix) => {
    let newArr = [];
    let pdObjInObjFormat = JSON.parse(listStringFormat);

    for (let key in pdObjInObjFormat) {
        let obj = {
            [`${prefix}UID`]: key,
            supplierUID: pdObjInObjFormat[key].a,
            [`${prefix}Date`]: pdObjInObjFormat[key].b,
            status: pdObjInObjFormat[key].c,
        };

        if (prefix === 'pdvr') {
            obj.pdvrTotalsNett = pdObjInObjFormat[key].d
                ? pdObjInObjFormat[key].d
                : '';
        }

        newArr.unshift(obj);
    }

    return newArr;
};
