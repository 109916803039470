import React from 'react';
import { displayDate } from '../../../lib/util';
import './PDSDItem.css';
import '../CollectionItems.css';

export default (props) => {
    let { obj, serialNumber, onClick, heading, keyProperty } = props;

    let prefix = 'pdpo';
    let itemUID = 'Purchase Order';
    let supCusName = 'Supplier Name';
    let supCusUID = 'supplierUID';
    if (keyProperty === 'pddrUID') {
        prefix = 'pddr';
        itemUID = 'Goods Receipt';
    }
    if (keyProperty === 'pdsiUID') {
        prefix = 'pdsi';
        itemUID = 'Supplier Invoice';
    }
    if (keyProperty === 'pdvrUID') {
        prefix = 'pdvr';
        itemUID = 'Voucher Receipt';
    }
    if (keyProperty === 'sdpoUID') {
        prefix = 'sdpo';
        itemUID = 'Sales Order';
        supCusName = 'Customer Name';
        supCusUID = 'customerUID';
    }
    if (keyProperty === 'sddrUID') {
        prefix = 'sddr';
        itemUID = 'Delivery Receipt';
        supCusName = 'Customer Name';
        supCusUID = 'customerUID';
    }
    if (keyProperty === 'sdsiUID') {
        prefix = 'sdsi';
        itemUID = 'Customer Invoice';
        supCusName = 'Customer Name';
        supCusUID = 'customerUID';
    }
    if (keyProperty === 'sdcrUID') {
        prefix = 'sdcr';
        itemUID = 'Counter Receipt';
        supCusName = 'Customer Name';
        supCusUID = 'customerUID';
    }

    let serial = 'S/No.';
    let date = 'Date';
    let status = 'Status';
    let headingClass = 'text-bold list-table-heading';

    let serialLabel = serial + ':';
    let itemUIDLabel = itemUID + ':';
    let supCusLabel = supCusName + ':';
    let statuslabel = 'Status:';
    let dateLabel = date;

    if (!heading) {
        serial = serialNumber;
        itemUID = obj[`${prefix}UID`];
        date = displayDate(obj[`${prefix}Date`]);
        status = obj.status;
        supCusName = obj[supCusUID];
        headingClass = '';
    }

    return (
        <div
            className={`pd-item collection-item ${headingClass}`}
            onClick={onClick}>
            <div className='collection-item-entry serial'>
                {' '}
                <span className='entry-label'>{serialLabel}</span>
                {serial}
            </div>
            <div className='collection-item-entry item-uid'>
                {' '}
                <span className='entry-label'>{itemUIDLabel}</span>
                {itemUID}
            </div>
            <div className='collection-item-entry sup-cus'>
                {' '}
                <span className='entry-label'>{supCusLabel}</span>
                {supCusName}
            </div>
            <div className='collection-item-entry date'>
                {' '}
                <span className='entry-label'>{dateLabel}</span>
                {date}
            </div>
            <div className='collection-item-entry status'>
                {' '}
                <span className='entry-label'>{statuslabel}</span>
                {status}
            </div>
        </div>
    );
};
