import React, { useState, useEffect, useContext } from 'react';
import {
    DataShareContext,
    AuthContext,
    MasterListContext,
} from '../../contexts';
import { useHistory } from 'react-router-dom';
import { routes, permissions, lengths } from '../../lib/constants';
import { InventoryButtons, BackOnlyButton } from '../../components';
import '../PagesCommon.css';
import '../RawMaterialsPage/RawMaterials.css';
import './InventoryFGs.css';
import { displayReadableNumber } from '../../lib/util';

export default (props) => {
    let { currentDocument, backCancelBtnFn } = props;

    let { masterInventoryFGsList } = useContext(MasterListContext);

    let { dataShare, setDataShare } = useContext(DataShareContext);
    let { authUser } = useContext(AuthContext);

    const history = useHistory();

    let [fgName, setFgName] = useState('');
    let [fgCode, setFgCode] = useState('');
    let [fgUnit, setFgUnit] = useState('');
    let [fgLowThreshold, setFgLowThreshold] = useState('');
    let [rmArr, setRmArr] = useState([]);
    let [sfgArr, setSfgArr] = useState([]);
    let [fgActualQty, setFgActualQty] = useState('');
    let [fgProjectedQty, setFgProjectedQty] = useState('');

    useEffect(() => {
        setFgName(currentDocument.fgName);
        setFgCode(currentDocument.fgCode);
        setFgUnit(currentDocument.fgUnit);
        setFgLowThreshold(currentDocument.fgLowThreshold);
        setRmArr(currentDocument.rmArr);
        setSfgArr(currentDocument.sfgArr);
        setFgActualQty(currentDocument.fgActualQty);
        setFgProjectedQty(currentDocument.fgProjectedQty);
    }, []);

    useEffect(() => {
        if (dataShare.isActive) history.push(routes.ASSEMBLY);
    }, [dataShare.isActive]);

    const createAssemblyOrderFn = () =>
        setDataShare({ isActive: true, data: fgName });

    // conditional render of contents  =============================

    let rmArrUI = [];
    let sfgArrUI = [];

    rmArr.forEach((rm, index) => {
        rmArrUI.push(
            <div className='span-2-columns-container' key={index}>
                <div className='view-table-row'>
                    <div className='cell'>
                        <span className='mobile-label m-right-15'>
                            {`${index + 1}.`}
                        </span>
                        <span className='desktop-view m-lr-15'>
                            {`${index + 1}.`}
                        </span>
                        <span className=''> {rm.rmUID}</span>
                    </div>

                    <div className='cell'>
                        <span className='width50'>
                            <span className='mobile-label'>Required Qty:</span>{' '}
                            <span className=''>{rm.rmRequiredQty}</span>
                            <span className='font12'> {rm.rmUnit}</span>
                        </span>
                        <span className='width50'>
                            <span className='mobile-label'>Group:</span>{' '}
                            <span className=''>{rm.group}</span>
                        </span>
                    </div>
                </div>
            </div>
        );
    });

    sfgArr.forEach((sfg, index) => {
        sfgArrUI.push(
            <div className='span-2-columns-container' key={index}>
                <div className='view-table-row'>
                    <div className='cell'>
                        <span className='mobile-label m-right-15'>
                            {`${index + 1}.`}
                        </span>
                        <span className='desktop-view m-lr-15'>
                            {`${index + 1}.`}
                        </span>
                        <span className=''> {sfg.sfgUID}</span>
                    </div>

                    <div className='cell'>
                        <span className='width50'>
                            <span className='mobile-label'>Required Qty:</span>{' '}
                            <span className=''>{sfg.sfgRequiredQty}</span>
                            <span className='font12'> {sfg.sfgUnit}</span>
                        </span>
                        <span className='width50'>
                            <span className='mobile-label'>Group:</span>{' '}
                            <span className=''>{sfg.group}</span>
                        </span>
                    </div>
                </div>
            </div>
        );
    });

    let rmSection = (
        <>
            <div className='span-2-columns-container label-padding label-highlight'>
                <label className='parent-labels'>Raw Materials List</label>
            </div>

            <div className='span-2-columns-container'>
                <div className='view-table-heading'>
                    <div className='cell'>
                        <span className='m-left-15'>RM Type - Description</span>
                    </div>

                    <div className='cell'>
                        <span className='width50'>Required Qty</span>

                        <span className='width50'>Group</span>
                    </div>
                </div>

                {rmArrUI}
            </div>
        </>
    );

    let sfgSection = (
        <>
            <div className='span-2-columns-container label-padding label-highlight'>
                <label className='parent-labels'>
                    Semi-Finished Goods List
                </label>
            </div>

            <div className='span-2-columns-container'>
                <div className='view-table-heading'>
                    <div className='cell'>
                        <span className='m-left-15'>SFG Name</span>
                    </div>

                    <div className='cell'>
                        <span className='width50'>Required Qty</span>

                        <span className='width50'>Group</span>
                    </div>
                </div>

                {sfgArrUI}
            </div>
        </>
    );

    if (rmArrUI.length === 0) rmSection = null;
    if (sfgArrUI.length === 0) sfgSection = null;

    let mainContent = (
        <section className='form-main-section'>
            <div className='form-profile-info-items'>
                <p className='parent-labels'>FG Name</p>
                <p className=''>{fgName}</p>
            </div>
            <div className='form-profile-info-items'>
                <p className='parent-labels'>FG Code</p>
                <p className=''>{fgCode}</p>
            </div>
            <div className='form-profile-info-items'>
                <p className='parent-labels'>FG Unit</p>
                <p className=''>{fgUnit}</p>
            </div>
            <div className='form-profile-info-items'>
                <p className='parent-labels'>FG Low Threshold</p>
                <p className=''>{displayReadableNumber(fgLowThreshold)}</p>
            </div>
            <div className='form-profile-info-items'>
                <p className='parent-labels'>FG Projected Qty</p>
                <p className=''>{displayReadableNumber(fgProjectedQty)}</p>
            </div>
            <div className='form-profile-info-items'>
                <p className='parent-labels'>FG Actual Qty</p>
                <p className=''>{displayReadableNumber(fgActualQty)}</p>
            </div>

            {rmSection}

            {sfgSection}
        </section>
    );

    let buttonSection = (
        <InventoryButtons
            backBtnFn={backCancelBtnFn}
            createBtnFn={createAssemblyOrderFn}
            createBtnText='Create Assembly Order'
        />
    );

    if (
        !authUser.permissions.includes(permissions.INV_FG) ||
        masterInventoryFGsList.length >= lengths.masterListLimit ||
        !authUser.subscriptionStatusIsActive
    ) {
        buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    }

    return (
        <div className='common-form'>
            <section className='form-title-section'>
                Finished Good Profile
            </section>
            {mainContent}
            {buttonSection}
            <div className='general-error-message'></div>
            <div className='watermark'></div>
        </div>
    );
};
