import React from 'react';
import ProdOrderForm from './ProdOrderForm';

import '../PagesCommon.css';
import './ProductionDeliveredPage.css';

export default (props) => {
    let {
        prodOrderObj,
        prodOrderIndex,

        updateSfgProducedQtyFn,
        updateRmActualUsedQtyFn,

        trashIcon,
    } = props;

    let listOfProdOrders = prodOrderObj.sfgArr.map((sfgObj, sfgIndex) => {
        return (
            <div key={sfgObj.sfgUID}>
                <ProdOrderForm
                    prodOrderIndex={prodOrderIndex}
                    sfgObj={sfgObj}
                    sfgIndex={sfgIndex}
                    updateRmActualUsedQtyFn={updateRmActualUsedQtyFn}
                    updateSfgProducedQtyFn={updateSfgProducedQtyFn}
                />
            </div>
        );
    });

    return (
        <div className='subform'>
            <div className='subform-label-qtys-section'>
                <div className='label'>
                    {`${prodOrderIndex + 1}. ${prodOrderObj.productionUID}`}
                </div>

                <div className='icon'>{trashIcon}</div>
            </div>

            <div className='subform-list-section'>
                <div className='sls-label sls-item'>
                    <div className='cell'>SFG List </div>
                    <div className='cell desktop-view'></div>
                </div>
                <div className='error-message'>
                    {prodOrderObj.sfgErrorMessage}
                </div>

                {listOfProdOrders}
            </div>
        </div>
    );
};
