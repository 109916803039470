import React from 'react';
import { sortReportItems } from '../../lib/util';
import ReportsForm from './ReportsForm';
import ReportItemsEach from './ReportItemsEach';
import tooltips from '../../lib/tooltips';

export default ({ backCancelBtnFn, rmReportObj }) => {
    let rmUI = [];
    let rmReport = [];
    let rmMap = rmReportObj.rmMap;

    for (let key in rmMap) {
        let itemObj = {};
        itemObj.itemUID = key;
        itemObj.total = rmMap[key];

        rmReport.push(itemObj);
    }

    sortReportItems(rmReport);

    rmReport.forEach((item, index) => {
        rmUI.push(
            <ReportItemsEach
                key={index}
                index={index}
                item={item}
                mobileLabel='RM Amount'
            />
        );
    });
    return (
        <ReportsForm
            helpText={tooltips.reportRM}
            uid={`Raw Materials - ${rmReportObj.reportMonth} ${rmReportObj.reportYear}`}
            nameLabel='Raw Materials'
            amountLabel='RM Inventory Amount'
            total={rmReportObj.total}
            list={rmUI}
            backCancelBtnFn={backCancelBtnFn}
        />
    );
};
