import { responseFn, clone } from '../lib/util';
import { userActions, regEx, lengths } from '../lib/constants';
import { generalMessage, appMessage } from '../lib/messages';
import { saveWithFiveDecimals } from '../lib/util';

export const assembly = (
    assemblyObj,
    purposeProp,
    masterInventorySFGsList,
    masterInventoryRMsList,
    userActionPassed
) => {
    let assemblyCopy = {
        ...assemblyObj,
        fgArr: clone(assemblyObj.fgArr),
    };

    // delete all error messages.

    delete assemblyCopy.assemblyOrderDateError;
    delete assemblyCopy.noteError;
    delete assemblyCopy.assemblyDescriptionError;

    assemblyCopy.fgArr.forEach((fg) => {
        delete fg.fgOrderedQtyError;
        delete fg.fgAssembledQtyError;
        delete fg.fgOverrideQtyError;
        fg.fgOrderedQty = saveWithFiveDecimals(fg.fgOrderedQty);
        fg.fgAssembledQty = saveWithFiveDecimals(fg.fgAssembledQty);
        fg.fgOverrideQty = saveWithFiveDecimals(fg.fgOverrideQty);
        fg.fgBalanceQty = saveWithFiveDecimals(fg.fgBalanceQty);

        fg.rmArr.forEach((rm) => {
            delete rm.rmProjectedUseQtyError;
            delete rm.rmActualUsedQtyError;
            delete rm.rmUnequalRequiredAndProjectedQtyError;
            delete rm.rmOverrideQtyError;
            rm.rmProjectedUseQty = saveWithFiveDecimals(rm.rmProjectedUseQty);
            rm.rmActualUsedQty = saveWithFiveDecimals(rm.rmActualUsedQty);
            rm.rmBalanceQty = saveWithFiveDecimals(rm.rmBalanceQty);
            rm.rmOverrideQty = saveWithFiveDecimals(rm.rmOverrideQty);
        });

        fg.sfgArr.forEach((sfg) => {
            delete sfg.sfgProjectedUseQtyError;
            delete sfg.sfgActualUsedQtyError;
            delete sfg.sfgUnequalRequiredAndProjectedQtyError;
            delete sfg.sfgOverrideQtyError;
            sfg.sfgProjectedUseQty = saveWithFiveDecimals(
                sfg.sfgProjectedUseQty
            );
            sfg.sfgActualUsedQty = saveWithFiveDecimals(sfg.sfgActualUsedQty);
            sfg.sfgBalanceQty = saveWithFiveDecimals(sfg.sfgBalanceQty);
            sfg.sfgOverrideQty = saveWithFiveDecimals(sfg.sfgOverrideQty);
        });
    });

    let hackError = '';
    let objError = false;
    let generalError = '';
    let rmError = false;
    let sfgError = false;

    if (assemblyCopy.assemblyOrderDate === '') {
        objError = true;
        assemblyCopy.assemblyOrderDateError = generalMessage.REQUIRED;
    }

    if (!regEx.date.test(assemblyCopy.assemblyOrderDate)) {
        objError = true;
        assemblyCopy.assemblyOrderDateError = generalMessage.INVALID_DATE;
    }

    if (assemblyCopy.note.length > lengths.noteLength) {
        objError = true;
        assemblyCopy.noteError = generalMessage.INVALID_LENGTH;
    }

    if (assemblyCopy.assemblyDescription.length > lengths.description) {
        objError = true;
        assemblyCopy.assemblyDescriptionError = generalMessage.INVALID_LENGTH;
    }

    if (assemblyCopy.fgArr.length < 1) {
        generalError = 'Must have atleast one Finished Good';
    }

    if (assemblyCopy.fgArr.length > lengths.assemblyFgArr) {
        hackError = appMessage.sourceCodeErrorMessage(
            'Assembly: fgArr length error.'
        );
    }

    assemblyCopy.fgArr.forEach((fg) => {
        // validate orderedQty
        if (isNaN(fg.fgOrderedQty)) {
            objError = true;
            fg.fgOrderedQtyError = generalMessage.INVALID;
        }

        if (fg.fgOrderedQty < 1) {
            objError = true;
            fg.fgOrderedQtyError = generalMessage.GREATER_ZERO;
        }

        if (fg.fgOrderedQty > lengths.numberInputAmount) {
            objError = true;
            fg.fgOrderedQtyError = generalMessage.INVALID_AMOUNT;
        }

        // validate overrrideQty
        if (Number(fg.fgOverrideQty) + fg.fgBalanceQty < 0) {
            objError = true;
            fg.fgOverrideQtyError = 'Cannot subtract more than the balance qty';
        }
        if (Number(fg.fgOverrideQty) > lengths.numberInputAmount) {
            objError = true;
            fg.fgOverrideQtyError = generalMessage.INVALID_AMOUNT;
        }

        if (isNaN(fg.fgOverrideQty)) {
            objError = true;
            fg.fgOverrideQtyError = generalMessage.INVALID;
        }
    });

    assemblyCopy.fgArr.forEach((fg) => {
        let rmMapRequiredQty = {};
        let rmMapGroupedUsedQty = {};

        fg.rmArr.forEach((rm) => {
            if (rm.rmProjectedUseQty < 0) {
                rm.rmProjectedUseQtyError = generalMessage.INVALID;
                objError = true;
            }
            if (isNaN(rm.rmProjectedUseQty)) {
                rm.rmProjectedUseQtyError = generalMessage.INVALID;
                objError = true;
                rmError = true;
            }
            if (rm.rmProjectedUseQty > lengths.numberInputAmount) {
                rm.rmProjectedUseQtyError = generalMessage.INVALID_AMOUNT;
                objError = true;
                rmError = true;
            }

            //! for testing purposes of back end logic, use this...

            // if (
            //     userActionPassed !== userActions.REJECTED &&
            //     userActionPassed !== userActions.SENT_FOR_APPROVAL &&
            //     userActionPassed !== userActions.APPROVED
            // ) {

            //! ===============================================

            if (
                userActionPassed !== userActions.REJECTED &&
                userActionPassed !== userActions.OVERRIDDEN &&
                userActionPassed !== userActions.DELETED
            ) {
                // check if projectedUsedQty is > available rm projectedQty.
                let rmAvailableProjectedQty = masterInventoryRMsList.filter(
                    (rawMatInList) => rawMatInList.rmUID === rm.rmUID
                )[0].rmProjectedQty;

                if (
                    Number(rmAvailableProjectedQty) <
                    Number(rm.rmProjectedUseQty)
                ) {
                    rm.rmProjectedUseQtyError =
                        generalMessage.NOT_ENOUGH_INVENTORY;
                    objError = true;

                    if (
                        userActionPassed === userActions.APPROVED ||
                        userActionPassed === userActions.SENT_FOR_APPROVAL
                    ) {
                        generalError = generalMessage.RECENT_INVENTORY_CHANGES;
                    }
                }
            }
            // ===========================================================

            // ==========================================================
            // check balance of each rm.

            if (rm.rmOverrideQty + rm.rmBalanceQty < 0) {
                objError = true;
                rm.rmOverrideQtyError =
                    'Cannot subtract more than the balance qty';
            }
            if (rm.rmOverrideQty > lengths.numberInputAmount) {
                objError = true;
                rm.rmOverrideQtyError = generalMessage.INVALID_AMOUNT;
            }

            if (isNaN(rm.rmOverrideQty)) {
                objError = true;
                rm.rmOverrideQtyError = generalMessage.INVALID;
            }

            //==========================================================

            if (!rmError) {
                if (rmMapRequiredQty[rm.group] === undefined) {
                    rmMapRequiredQty[rm.group] = saveWithFiveDecimals(
                        saveWithFiveDecimals(rm.rmRequiredQty) *
                            saveWithFiveDecimals(fg.fgOrderedQty)
                    );
                    rmMapGroupedUsedQty[rm.group] = saveWithFiveDecimals(
                        rm.rmProjectedUseQty
                    );
                } else {
                    rmMapGroupedUsedQty[rm.group] += saveWithFiveDecimals(
                        rm.rmProjectedUseQty
                    );
                }
            }
        });

        let sfgMapRequiredQty = {};
        let sfgMapGroupedUsedQty = {};

        fg.sfgArr.forEach((sfg) => {
            if (sfg.sfgProjectedUseQty < 0) {
                sfg.sfgProjectedUseQtyError = generalMessage.INVALID;
                objError = true;
            }
            if (isNaN(sfg.sfgProjectedUseQty)) {
                sfg.sfgProjectedUseQtyError = generalMessage.INVALID;
                objError = true;
                sfgError = true;
            }
            if (sfg.sfgProjectedUseQty > lengths.numberInputAmount) {
                sfg.sfgProjectedUseQtyError = generalMessage.INVALID_AMOUNT;
                objError = true;
                sfgError = true;
            }

            //! for testing purposes of back end logic, use this...

            // if (
            //     userActionPassed !== userActions.REJECTED &&
            //     userActionPassed !== userActions.SENT_FOR_APPROVAL &&
            //     userActionPassed !== userActions.APPROVED
            // ) {

            //! ===============================================

            if (
                userActionPassed !== userActions.REJECTED &&
                userActionPassed !== userActions.OVERRIDDEN &&
                userActionPassed !== userActions.DELETED
            ) {
                // check if projectedUsedQty is > available rm projectedQty.
                let sfgAvailableProjectedQty = masterInventorySFGsList.filter(
                    (sfgInList) => sfgInList.sfgUID === sfg.sfgUID
                )[0].sfgProjectedQty;

                if (
                    Number(sfgAvailableProjectedQty) <
                    Number(sfg.sfgProjectedUseQty)
                ) {
                    sfg.sfgProjectedUseQtyError =
                        generalMessage.NOT_ENOUGH_INVENTORY;
                    objError = true;

                    if (
                        userActionPassed === userActions.APPROVED ||
                        userActionPassed === userActions.SENT_FOR_APPROVAL
                    ) {
                        generalError = generalMessage.RECENT_INVENTORY_CHANGES;
                    }
                }
            }

            // ===========================================================

            // ==========================================================
            // check balance of each sfg.

            if (sfg.sfgOverrideQty + sfg.sfgBalanceQty < 0) {
                objError = true;
                sfg.sfgOverrideQtyError =
                    'Cannot subtract more than the balance qty';
            }
            if (sfg.sfgOverrideQty > lengths.numberInputAmount) {
                objError = true;
                sfg.sfgOverrideQtyError = generalMessage.INVALID_AMOUNT;
            }

            if (isNaN(sfg.sfgOverrideQty)) {
                objError = true;
                sfg.sfgOverrideQtyError = generalMessage.INVALID;
            }

            //==========================================================

            if (!sfgError) {
                if (sfgMapRequiredQty[sfg.group] === undefined) {
                    sfgMapRequiredQty[sfg.group] = saveWithFiveDecimals(
                        saveWithFiveDecimals(sfg.sfgRequiredQty) *
                            saveWithFiveDecimals(fg.fgOrderedQty)
                    );
                    sfgMapGroupedUsedQty[sfg.group] = saveWithFiveDecimals(
                        sfg.sfgProjectedUseQty
                    );
                } else {
                    sfgMapGroupedUsedQty[sfg.group] += saveWithFiveDecimals(
                        sfg.sfgProjectedUseQty
                    );
                }
            }
        });

        if (!rmError) {
            for (let key in rmMapRequiredQty) {
                if (
                    saveWithFiveDecimals(rmMapRequiredQty[key]) !==
                    saveWithFiveDecimals(rmMapGroupedUsedQty[key])
                ) {
                    objError = true;
                    fg.rmArr.forEach((rm) => {
                        if (
                            saveWithFiveDecimals(rm.group) ===
                            saveWithFiveDecimals(key)
                        ) {
                            rm.rmUnequalRequiredAndProjectedQtyError =
                                'Projected qty (numerator) not equal required qty (denominator)';
                        }
                    });
                }
            }
        }

        if (!sfgError) {
            for (let key in sfgMapRequiredQty) {
                if (
                    saveWithFiveDecimals(sfgMapRequiredQty[key]) !==
                    saveWithFiveDecimals(sfgMapGroupedUsedQty[key])
                ) {
                    objError = true;
                    fg.sfgArr.forEach((sfg) => {
                        if (
                            saveWithFiveDecimals(sfg.group) ===
                            saveWithFiveDecimals(key)
                        ) {
                            sfg.sfgUnequalRequiredAndProjectedQtyError =
                                'Projected qty (numerator) not equal required qty (denominator)';
                        }
                    });
                }
            }
        }
    });

    if (hackError || objError || generalError) {
        let errorObj = {
            assemblyObj: assemblyCopy,
            hackError,
            generalError,
        };
        return responseFn(null, true, errorObj, 'Error detected');
    }

    let validFgArr = assemblyCopy.fgArr.map((fg) => {
        let validRmArr = fg.rmArr.map((rm) => {
            let rmBalance = saveWithFiveDecimals(rm.rmProjectedUseQty);
            if (purposeProp === 'OVERRIDE')
                rmBalance = saveWithFiveDecimals(rm.rmBalanceQty);

            return {
                rmUID: rm.rmUID,
                group: rm.group,
                rmRequiredQty: saveWithFiveDecimals(rm.rmRequiredQty),
                rmUnit: rm.rmUnit,
                rmProjectedUseQty: saveWithFiveDecimals(rm.rmProjectedUseQty),
                rmActualUsedQty: saveWithFiveDecimals(rm.rmActualUsedQty) || 0,
                rmBalanceQty: rmBalance,
                rmOverrideQty: saveWithFiveDecimals(rm.rmOverrideQty) || 0,
            };
        });

        let validSfgArr = fg.sfgArr.map((sfg) => {
            let sfgBalance = saveWithFiveDecimals(sfg.sfgProjectedUseQty);
            if (purposeProp === 'OVERRIDE')
                sfgBalance = saveWithFiveDecimals(sfg.sfgBalanceQty);

            return {
                sfgUID: sfg.sfgUID,
                group: sfg.group,
                sfgRequiredQty: saveWithFiveDecimals(sfg.sfgRequiredQty),
                sfgUnit: sfg.sfgUnit,
                sfgProjectedUseQty: saveWithFiveDecimals(
                    sfg.sfgProjectedUseQty
                ),
                sfgActualUsedQty:
                    saveWithFiveDecimals(sfg.sfgActualUsedQty) || 0,
                sfgBalanceQty: sfgBalance,
                sfgOverrideQty: saveWithFiveDecimals(sfg.sfgOverrideQty) || 0,
            };
        });

        let fgBalance = saveWithFiveDecimals(fg.fgOrderedQty);
        if (purposeProp === 'OVERRIDE')
            fgBalance = saveWithFiveDecimals(fg.fgBalanceQty);

        return {
            fgUID: fg.fgUID,
            fgOrderedQty: saveWithFiveDecimals(fg.fgOrderedQty),
            fgAssembledQty: saveWithFiveDecimals(fg.fgAssembledQty) || 0,
            fgOverrideQty: saveWithFiveDecimals(fg.fgOverrideQty) || 0,
            fgBalanceQty: fgBalance,
            rmArr: validRmArr,
            sfgArr: validSfgArr,
            fgUnit: fg.fgUnit,
        };
    });

    let valid = {
        ...assemblyCopy,
        assemblyUID: assemblyCopy.assemblyUID,
        assemblyNumber: assemblyCopy.assemblyUID,
        assemblyOrderDate: assemblyCopy.assemblyOrderDate,
        fgArr: validFgArr,
        note: assemblyCopy.note,
        assemblyDescription: assemblyCopy.assemblyDescription,
    };

    return responseFn(valid, false, {}, 'no error');
};
