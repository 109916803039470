import { responseFn, clone, saveWithFiveDecimals } from '../lib/util';
import { regEx, lengths } from '../lib/constants';
import { noChange } from './noChange';
import { generalMessage, appMessage } from '../lib/messages';

export const sddrNoChange = (sddr, currentDocument) => {
    let formedCurrentDocument = {
        ...currentDocument,
        sdpoArr: clone(currentDocument.sdpoArr),
    };
    formedCurrentDocument.sdpoArr = formedCurrentDocument.sdpoArr.map(
        (sdpo) => {
            sdpo.fgArr = sdpo.fgArr.map((fg) => {
                return {
                    fgUID: fg.fgUID,
                    sddrDeliveredQtyForFg: saveWithFiveDecimals(
                        fg.sddrDeliveredQtyForFg
                    ),
                };
            });
            sdpo.sfgArr = sdpo.sfgArr.map((sfg) => {
                return {
                    sfgUID: sfg.sfgUID,
                    sddrDeliveredQtyForSfg: saveWithFiveDecimals(
                        sfg.sddrDeliveredQtyForSfg
                    ),
                };
            });
            return sdpo;
        }
    );

    let formedSddr = {
        ...sddr,
        sdpoArr: clone(sddr.sdpoArr),
    };

    formedSddr.sdpoArr = formedSddr.sdpoArr.map((sdpo) => {
        sdpo.fgArr = sdpo.fgArr.map((fg) => {
            return {
                fgUID: fg.fgUID,
                sddrDeliveredQtyForFg: saveWithFiveDecimals(
                    fg.sddrDeliveredQtyForFg
                ),
            };
        });
        sdpo.sfgArr = sdpo.sfgArr.map((sfg) => {
            return {
                sfgUID: sfg.sfgUID,
                sddrDeliveredQtyForSfg: saveWithFiveDecimals(
                    sfg.sddrDeliveredQtyForSfg
                ),
            };
        });
        return sdpo;
    });

    return noChange(formedSddr, formedCurrentDocument);
};

export const sddr = (sddr, purpose) => {
    // clear all errors

    let errorInObj = false;
    let hackError = '';

    delete sddr.customerUIDError;
    delete sddr.sddrUIDError;
    delete sddr.sdpoArrError;
    delete sddr.sddrDateError;
    delete sddr.noteError;

    sddr.sdpoArr.forEach((sdpoObj) => {
        sdpoObj.fgArr.forEach((fgObj) => {
            delete fgObj.sddrDeliveredQtyForFgError;
        });
    });

    // validate if there is at least one PO.
    if (sddr.sdpoArr.length === 0) {
        errorInObj = true;
        sddr.sdpoArrError = 'Must have at least one PO';
    }

    if (sddr.sdpoArr.length > lengths.sddrSdpoArrLength) {
        hackError = appMessage.sourceCodeErrorMessage(
            'SDDR: sdpoArr length error.'
        );
    }

    if (!regEx.date.test(sddr.sddrDate)) {
        errorInObj = true;
        sddr.sddrDateError = generalMessage.INVALID_DATE;
    }

    if (sddr.sddrDate === '') {
        errorInObj = true;
        sddr.sddrDateError = generalMessage.REQUIRED;
    }

    if (sddr.note.length > lengths.noteLength) {
        errorInObj = true;
        sddr.noteError = generalMessage.INVALID_LENGTH;
    }

    sddr.sdpoArr.forEach((sdpoObj) => {
        //3. validate same customerUID for all sdpoObjs in sdpoArr
        // ! should never run unless UI was hacked.
        if (sdpoObj.customerUID !== sddr.customerUID) {
            hackError = appMessage.sourceCodeErrorMessage(
                'SDDR: customerUID error.'
            );
        }

        sdpoObj.fgArr.forEach((fgObj) => {
            fgObj.sddrDeliveredQtyForFg = saveWithFiveDecimals(
                fgObj.sddrDeliveredQtyForFg
            );

            if (isNaN(fgObj.sddrDeliveredQtyForFg)) {
                errorInObj = true;
                fgObj.sddrDeliveredQtyForFgError = generalMessage.INVALID;
            }

            if (fgObj.sddrDeliveredQtyForFg < 0) {
                errorInObj = true;
                fgObj.sddrDeliveredQtyForFgError = generalMessage.GREATER_ZERO;
            }

            if (fgObj.sddrDeliveredQtyForFg > lengths.numberInputAmount) {
                errorInObj = true;
                fgObj.sddrDeliveredQtyForFgError =
                    generalMessage.INVALID_AMOUNT;
            }

            if (
                fgObj.sddrDeliveredQtyForFg >
                saveWithFiveDecimals(fgObj.fgBalanceQty + fgObj.fgOverrideQty)
            ) {
                errorInObj = true;
                fgObj.sddrDeliveredQtyForFgError =
                    'Delivered Qty cannot exceed the Balance Qty';
            }
        });

        sdpoObj.sfgArr.forEach((sfgObj) => {
            sfgObj.sddrDeliveredQtyForSfg = saveWithFiveDecimals(
                sfgObj.sddrDeliveredQtyForSfg
            );

            if (isNaN(sfgObj.sddrDeliveredQtyForSfg)) {
                errorInObj = true;
                sfgObj.sddrDeliveredQtyForSfgError = generalMessage.INVALID;
            }

            if (sfgObj.sddrDeliveredQtyForSfg < 0) {
                errorInObj = true;
                sfgObj.sddrDeliveredQtyForSfgError =
                    generalMessage.GREATER_ZERO;
            }
            if (sfgObj.sddrDeliveredQtyForSfg > lengths.numberInputAmount) {
                errorInObj = true;
                sfgObj.sddrDeliveredQtyForSfgError =
                    generalMessage.INVALID_AMOUNT;
            }

            if (
                sfgObj.sddrDeliveredQtyForSfg >
                saveWithFiveDecimals(
                    sfgObj.sfgBalanceQty + sfgObj.sfgOverrideQty
                )
            ) {
                errorInObj = true;
                sfgObj.sddrDeliveredQtyForSfgError =
                    'Delivered Qty cannot exceed the Balance Qty';
            }
        });
    });

    if (errorInObj || hackError) {
        let errorObj = {
            sddrObj: sddr,
            hackError,
        };
        return responseFn(null, true, errorObj, 'App: Error with SDDR object.');
    }

    let correctSchemaSDPOArr = sddr.sdpoArr.map((sdpo) => {
        let correctFgArr = sdpo.fgArr.map((fg) => {
            return {
                fgUID: fg.fgUID,
                fgPrice: fg.fgPrice,
                fgUnit: fg.fgUnit,
                sddrDeliveredQtyForFg: saveWithFiveDecimals(
                    fg.sddrDeliveredQtyForFg
                ),
            };
        });
        let correctSfgArr = sdpo.sfgArr.map((sfg) => {
            return {
                sfgUID: sfg.sfgUID,
                sfgPrice: sfg.sfgPrice,
                sfgUnit: sfg.sfgUnit,
                sddrDeliveredQtyForSfg: saveWithFiveDecimals(
                    sfg.sddrDeliveredQtyForSfg
                ),
            };
        });
        return {
            sdpoUID: sdpo.sdpoUID,
            customerUID: sdpo.customerUID,
            sdpoDiscountRate: sdpo.sdpoDiscountRate,
            sdpoTaxRate: sdpo.sdpoTaxRate,
            fgArr: correctFgArr,
            sfgArr: correctSfgArr,
        };
    });
    // proper schema
    let correctSchema = {
        meta: sddr.meta || {},
        metaHistory: sddr.metaHistory || [],
        sddrUID: sddr.sddrUID,
        sddrNumber: sddr.sddrUID,
        sddrDate: sddr.sddrDate,
        customerUID: sddr.customerUID,
        sdpoArr: correctSchemaSDPOArr,
        note: sddr.note,
    };

    let data = {
        validSddrObj: correctSchema,
        sddrObj: sddr,
    };
    return responseFn(data, false, {}, 'App: Validation successful');
};
