import React from 'react';
import { HelpTip } from '../../components';
import tooltips from '../../lib/tooltips';
import '../PagesCommon.css';
import './AssemblyDeliveredPage.css';
import { displayReadableNumber } from '../../lib/util';

const GroupedRM = (props) => {
    let { rmArr, fgUID } = props;

    let rms = rmArr.map((rm) => {
        return (
            <div
                className='span-2-columns-container'
                key={`${fgUID}${rm.rmUID}`}>
                <div className='view-table-row'>
                    <div className='cell'>
                        <div className='width16'>
                            <span className='mobile-label'>Grp:</span>{' '}
                            <span className='desktop-view m-left-15'>
                                {' '}
                                {rm.group}
                            </span>
                            <span className='mobile-label'> {rm.group}</span>
                        </div>
                        <div className=''>
                            <span className='mobile-label'>RM:</span>{' '}
                            <span> {rm.rmUID}</span>
                        </div>
                    </div>
                    <div className='cell'>
                        <div className='width50'>
                            {' '}
                            <span className='mobile-label'>
                                Actual Qty (Used):
                            </span>{' '}
                            <span>
                                {' '}
                                {displayReadableNumber(
                                    rm.rmUsedQtyForThisFgAssembled
                                )}
                            </span>
                        </div>
                        <div className='width50'>
                            {' '}
                            <span className='mobile-label'>Unit:</span>{' '}
                            <span>{rm.rmUnit}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    return <div className='span-2-columns-container'>{rms}</div>;
};

const GroupedSFG = (props) => {
    let { sfgArr, fgUID } = props;

    let sfgs = sfgArr.map((sfg) => {
        return (
            <div
                className='span-2-columns-container'
                key={`${fgUID}${sfg.sfgUID}`}>
                <div className='view-table-row'>
                    <div className='cell'>
                        <div className='width16'>
                            <span className='mobile-label'>Grp:</span>{' '}
                            <span className='desktop-view m-left-15'>
                                {' '}
                                {sfg.group}
                            </span>
                            <span className='mobile-label'> {sfg.group}</span>
                        </div>
                        <div className=''>
                            <span className='mobile-label'>SFG:</span>{' '}
                            <span> {sfg.sfgUID}</span>
                        </div>
                    </div>
                    <div className='cell'>
                        <div className='width50'>
                            {' '}
                            <span className='mobile-label'>
                                Actual Qty (Used):
                            </span>{' '}
                            <span>
                                {' '}
                                {displayReadableNumber(
                                    sfg.sfgUsedQtyForThisFgAssembled
                                )}
                            </span>
                        </div>
                        <div className='width50'>
                            {' '}
                            <span className='mobile-label'>Unit:</span>{' '}
                            <span>{sfg.sfgUnit}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    return <div className='span-2-columns-container'>{sfgs}</div>;
};

export default (props) => {
    let { fgObj, fgIndex } = props;

    let groupedRmMap = {};

    fgObj.rmArr.forEach((rm, rmIndex) => {
        rm.tempRmIndex = rmIndex;
        if (groupedRmMap[rm.group] === undefined) {
            groupedRmMap[rm.group] = [];
            groupedRmMap[rm.group].push(rm);
        } else groupedRmMap[rm.group].push(rm);
    });

    let listOfRms = [];

    for (let gkey in groupedRmMap) {
        let component = (
            <GroupedRM
                key={`${fgObj.fgUID}${gkey}`}
                fgUID={fgObj.fgUID}
                fgIndex={fgIndex}
                rmArr={groupedRmMap[gkey]}
            />
        );
        listOfRms.push(component);
    }

    let groupedSfgMap = {};

    fgObj.sfgArr.forEach((sfg, sfgIndex) => {
        sfg.tempSfgIndex = sfgIndex;
        if (groupedSfgMap[sfg.group] === undefined) {
            groupedSfgMap[sfg.group] = [];
            groupedSfgMap[sfg.group].push(sfg);
        } else groupedSfgMap[sfg.group].push(sfg);
    });

    let listOfSfgs = [];

    for (let gkey in groupedSfgMap) {
        let component = (
            <GroupedSFG
                key={`${fgObj.fgUID}${gkey}`}
                fgUID={fgObj.fgUID}
                fgIndex={fgIndex}
                sfgArr={groupedSfgMap[gkey]}
            />
        );
        listOfSfgs.push(component);
    }

    let rmSection = (
        <div className='span-2-columns-container show-border m-top-10 pad-around-10'>
            <div className='view-table-heading'>
                <div className='cell'>
                    <span className='width16'>
                        <span className='m-left-15'>Grp</span>
                    </span>
                    <span>RM Items</span>
                </div>
                <div className='cell'>
                    <span className='width50'>
                        Actual Qty (Used){' '}
                        <HelpTip content={tooltips.assemRmActualUsedQty} />
                    </span>
                    <span className='width50'>Unit</span>
                </div>
            </div>
            {listOfRms}
        </div>
    );

    let sfgSection = (
        <div className='span-2-columns-container show-border m-top-10 pad-around-10'>
            <div className='view-table-heading'>
                <div className='cell'>
                    <span className='width16'>
                        <span className='m-left-15'>Grp</span>
                    </span>
                    <span>SFG Items</span>
                </div>
                <div className='cell'>
                    <span className='width50'>
                        Actual Qty (Used){' '}
                        <HelpTip content={tooltips.assemSfgActualUsedQty} />
                    </span>
                    <span className='width50'>Unit</span>
                </div>
            </div>
            {listOfSfgs}
        </div>
    );

    if (listOfSfgs.length === 0) sfgSection = null;
    if (listOfRms.length === 0) rmSection = null;

    return (
        <div
            className='span-2-columns-container show-border-darker pad-around-10 m-bottom-10'
            key={`${fgObj.fgUID}${fgIndex}`}>
            <div className='view-table-row bg-grey-l2 pad-around-10'>
                <div className='cell'>
                    <span className='m-right-5'>{`${fgIndex + 1}. `}</span>
                    <span>{fgObj.fgUID}</span>
                </div>
                <div className='cell'>
                    <div className='width50'>
                        <span className='font-size-13 text-bold m-right-5'>
                            Assembled Qty
                        </span>{' '}
                        <span>
                            {displayReadableNumber(
                                fgObj.fgAssembledQtyForThisAssembly
                            )}{' '}
                            <HelpTip content={tooltips.assemFgAssembledQty} />
                        </span>
                    </div>
                    <div className='width50'>
                        <span className='font-size-13 text-bold m-right-5'>
                            Unit
                        </span>{' '}
                        <span>{fgObj.fgUnit}</span>
                    </div>
                </div>
            </div>

            {rmSection}
            {sfgSection}
        </div>
    );
};
