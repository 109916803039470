import React, { useState, useContext } from 'react';
import { dbopsSDDRs } from '../../firebase_dbops';
import { MasterListContext, AuthContext } from '../../contexts';
import { InputIcon, ListView2 } from '../../components';
import PDSDItem from '../../components/CollectionItems/PDSDItem/PDSDItem';
import { displayDate } from '../../lib/util';
import { permissions, userActions, lengths } from '../../lib/constants';

import '../PagesCommon.css';
import './SDDRsPage.css';

import SDDRsForm from './SDDRsForm';

const view = {
    LIST_VIEW: 'listView',
    CREATE_DELIVERY_RECEIPT: 'creatDR',
    EDIT_DELIVERY_RECEIPT: 'editDR',
    DELETE_DELIVERY_RECEIPT: 'deleteDR',
    DELIVERY_RECEIPT_PROFILE: 'drProfile',
};

export default (props) => {
    let { setIsLoading, setAlertErrorMsg, setAlertMessage } = props;

    let { masterSddrList } = useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);

    let [formView, setFormView] = useState(view.LIST_VIEW);
    let [currentDocument, setCurrentDocument] = useState('');
    let [searchText, setSearchText] = useState('');

    // functions to change views.
    const createDeliveryReceiptBtnFn = () =>
        setFormView(view.CREATE_DELIVERY_RECEIPT);
    const gotoListViewFn = () => setFormView(view.LIST_VIEW);
    const gotoEditViewFn = () => setFormView(view.EDIT_DELIVERY_RECEIPT);
    const gotoDeleteViewFn = () => setFormView(view.DELETE_DELIVERY_RECEIPT);
    const gotoPoProfileViewFn = () =>
        setFormView(view.DELIVERY_RECEIPT_PROFILE);

    // function to get sddr document.
    const getDocumentFromDBFn = async (docID) => {
        setIsLoading(true);
        let response = await dbopsSDDRs.getSddrFn(docID);
        if (response.error)
            setAlertErrorMsg(response.errorObj.message || response.message);
        else {
            setCurrentDocument(response.data);
            gotoPoProfileViewFn();
        }
        setIsLoading(false);
    };

    // function to create Supplier
    const createSddrFn = async (sddrObj) => {
        //! rmArr are rmObjects with complete schema from RawMaterials collection
        // rmArr needed to be updated in database for costHistory.

        // spinner on.
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        // write to db.
        let response = await dbopsSDDRs.createSddrFn(userCreds, sddrObj);
        if (response.error) setAlertErrorMsg(response.errorObj.message);
        else setAlertMessage(response.message);

        gotoListViewFn();

        // spinner off.
        setIsLoading(false);
    };

    // function to update sddr.
    const updateSddrFn = async (sddrObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsSDDRs.updateSddrFn(
            userCreds,
            sddrObj,
            documentBasis
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);
            // if error is because document has already been deleted,
            // go back to list view.
            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
            // if error is because document has since changed, just continue
            // flow of the program.
        } else setAlertMessage(response.message);

        // this get document ensures state in FE state (currentDocument) is updated.
        gotoListViewFn();
        await getDocumentFromDBFn(sddrObj.sddrUID);

        setIsLoading(false);
    };

    // function to delete sddr.
    const deleteSddrFn = async (sddrObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsSDDRs.deleteSddrFn(
            userCreds,
            sddrObj,
            documentBasis
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);

            if (response.errorObj.code === 1001) {
                gotoListViewFn();
                await getDocumentFromDBFn(sddrObj.sddrUID);
                setIsLoading(false);
                return;
            }
            // all other errors can continue flow of program.
        } else setAlertMessage(response.message);

        gotoListViewFn();

        setIsLoading(false);
    };

    // function for status change of document.
    const statusChangeFn = async (
        userActionPassed,
        sddrObj,
        documentBasis,
        documentBasisPoArr
    ) => {
        setIsLoading(true);

        let userCreds = {
            action: userActionPassed,
            uid: authUser.uid,
            email: authUser.email,
        };

        let dbFn = dbopsSDDRs.statusChangeFn;
        if (userActionPassed === userActions.APPROVED) {
            dbFn = dbopsSDDRs.approveSddrFn;
        }

        let response = await dbFn(
            userCreds,
            sddrObj,
            documentBasis,
            documentBasisPoArr
        );
        if (response.error) {
            // meaning: response.data contains detailed error obj.
            if (response.data) setAlertErrorMsg(response.data);
            else setAlertErrorMsg(response.errorObj.message);

            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
            // if error is because document has since changed, just continue
            // flow of the program.
        } else setAlertMessage(response.message);

        // this get document ensures state in FE state (currentDocument) is updated.
        gotoListViewFn();
        await getDocumentFromDBFn(sddrObj.sddrUID);

        setIsLoading(false);
    };

    // filter function =====================================
    let filteredSddrList = masterSddrList.filter((sddr) => {
        return (
            sddr.sddrUID.toUpperCase().includes(searchText.toUpperCase()) ||
            displayDate(sddr.sddrDate)
                .toUpperCase()
                .includes(searchText.toUpperCase()) ||
            sddr.status.toUpperCase().includes(searchText.toUpperCase()) ||
            sddr.customerUID.toUpperCase().includes(searchText.toUpperCase())
        );
    });

    let addSddrBtnFn = (
        <button
            className='btn btn-primary'
            onClick={createDeliveryReceiptBtnFn}
        >
            Create Delivery Receipt
        </button>
    );

    if (
        !authUser.permissions.includes(permissions.SDDR_REQUEST) ||
        masterSddrList.length >= lengths.masterPOSDeptsLimits ||
        !authUser.subscriptionStatusIsActive
    ) {
        addSddrBtnFn = null;
    }

    let topSectionContent = (
        <section className='top-section'>
            <InputIcon
                //! id='suppliers-search-input'
                type='text'
                placeholder='Search'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                icon={
                    <i
                        className='ms-Icon ms-Icon--Search'
                        aria-hidden='true'
                    ></i>
                }
            />
            {addSddrBtnFn}
        </section>
    );

    let mainSectionContent = (
        <ListView2
            getDocumentFromDBFn={getDocumentFromDBFn}
            list={filteredSddrList}
            keyProperty={'sddrUID'}
            CollectionItemComponent={PDSDItem}
        />
    );

    if (formView !== view.LIST_VIEW) topSectionContent = null;

    if (formView === view.CREATE_DELIVERY_RECEIPT) {
        mainSectionContent = (
            <SDDRsForm
                purpose='ADD'
                backCancelBtnFn={gotoListViewFn}
                createSddrFn={createSddrFn}
                setIsLoading={setIsLoading}
                setAlertErrorMsg={setAlertErrorMsg}
            />
        );
    }

    if (formView === view.EDIT_DELIVERY_RECEIPT) {
        mainSectionContent = (
            <SDDRsForm
                purpose='EDIT'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoPoProfileViewFn}
                updateSddrFn={updateSddrFn}
                setAlertErrorMsg={setAlertErrorMsg}
                setIsLoading={setIsLoading}
            />
        );
    }

    if (formView === view.DELETE_DELIVERY_RECEIPT) {
        mainSectionContent = (
            <SDDRsForm
                purpose='DELETE'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoPoProfileViewFn}
                deleteSddrFn={deleteSddrFn}
                setAlertErrorMsg={setAlertErrorMsg}
                setIsLoading={setIsLoading}
            />
        );
    }

    if (formView === view.DELIVERY_RECEIPT_PROFILE) {
        mainSectionContent = (
            <SDDRsForm
                purpose='VIEW'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoListViewFn}
                gotoEditViewFn={gotoEditViewFn}
                gotoDeleteViewFn={gotoDeleteViewFn}
                setIsLoading={setIsLoading}
                statusChangeFn={statusChangeFn}
                setAlertErrorMsg={setAlertErrorMsg}
            />
        );
    }

    return (
        <div className='content-layout'>
            {topSectionContent}

            <section className='main-section'>{mainSectionContent}</section>
        </div>
    );
};
