/**
 * c001: Sept 3, 2020: totalsNett only implemented for DRs as PO is no longer an
 *          option.
 *
 */

import {
    responseFn,
    clone,
    saveWithTwoDecimalOnly,
    saveWithFiveDecimals,
    applyDiscount,
} from '../lib/util';
import { regEx, lengths } from '../lib/constants';
import { generalMessage, appMessage } from '../lib/messages';
import { validateUID } from '../lib/util';

export const sdsi = (siObj) => {
    let sdsi = {
        ...siObj,
        customerUID: siObj.customerUID,
        sdpoArr: clone(siObj.sdpoArr),
        sddrArr: clone(siObj.sddrArr),
    };

    // clear all errors
    delete sdsi.sdsiUIDError;
    delete sdsi.sdsiTaxRateError;
    delete sdsi.arrError;
    delete sdsi.customerError;
    delete sdsi.sdsiDateError;
    delete sdsi.noteError;

    let objError = false;
    let hackError = '';

    sdsi.sdsiUID = sdsi.sdsiUID.trim();

    // validate sdsiUID
    objError = validateUID(sdsi.sdsiUID).error;
    sdsi.sdsiUIDError = validateUID(sdsi.sdsiUID).errorMessage;

    // if (sdsi.sdsiUID === '') {
    //     objError = true;
    //     sdsi.sdsiUIDError = generalMessage.REQUIRED;
    // }
    // if (sdsi.sdsiUID.length > lengths.uid) {
    //     objError = true;
    //     sdsi.sdsiUIDError = generalMessage.INVALID_LENGTH;
    // }

    // validate sdsiTaxRate
    if (
        saveWithTwoDecimalOnly(sdsi.sdsiTaxRate) > 100 ||
        saveWithTwoDecimalOnly(sdsi.sdsiTaxRate) < 0
    ) {
        objError = true;
        sdsi.sdsiTaxRateError = generalMessage.INVALID_RATE;
    }

    if (isNaN(sdsi.sdsiTaxRate)) {
        objError = true;
        sdsi.sdsiTaxRateError = generalMessage.INVALID;
    }

    if (sdsi.sddrArr.length === 0 && sdsi.sdpoArr.length === 0) {
        objError = true;
        sdsi.arrError = 'Must have at least one SDPO or SDDR';
    }

    // there should always be a customerUID.
    if (sdsi.customerUID === '') {
        objError = true;
        sdsi.customerError = 'Must choose a customer';
    }

    if (!regEx.date.test(sdsi.sdsiDate)) {
        objError = true;
        sdsi.sdsiDateError = generalMessage.INVALID_DATE;
    }

    if (sdsi.sdsiDate === '') {
        objError = true;
        sdsi.sdsiDateError = generalMessage.REQUIRED;
    }

    if (sdsi.note.length > lengths.noteLength) {
        objError = true;
        sdsi.noteError = generalMessage.INVALID_LENGTH;
    }

    //! this should not run. ==================
    if (sdsi.sdpoArr.length > 0 && sdsi.sddrArr.length > 0)
        hackError = appMessage.sourceCodeErrorMessage(
            'SDSI: Both SDDR and SDPO array has elements.'
        );
    if (sdsi.sddrArr.length > 0 && sdsi.sdpoArr.length > 0)
        hackError = appMessage.sourceCodeErrorMessage(
            'SDSI: Both SDDR and SDPO array has elements.'
        );

    if (
        sdsi.sddrArr.length > lengths.sdsiFgOrSfgArrLength ||
        sdsi.sdpoArr.length > lengths.sdsiFgOrSfgArrLength
    ) {
        hackError = appMessage.sourceCodeErrorMessage(
            'PDSI: sdpo or sddr array length error.'
        );
    }

    sdsi.sddrArr.forEach((sddr) => {
        if (sddr.customerUID !== sdsi.customerUID) {
            hackError = appMessage.sourceCodeErrorMessage(
                'SDSI: Not all suppliers in the SDDR documents equal the Sales Invoice customer.'
            );
        }
    });

    sdsi.sdpoArr.forEach((sdpo) => {
        if (sdpo.customerUID !== sdsi.customerUID) {
            hackError = appMessage.sourceCodeErrorMessage(
                'SDSI: Not all suppliers in the SDPO documents equal the Sales Invoice customer.'
            );
        }
    });

    //! ==================================

    // check customers of each sdpo and sddr.

    sdsi.sdsiTaxRate = saveWithTwoDecimalOnly(sdsi.sdsiTaxRate);

    if (objError || hackError) {
        let errorObj = {
            sdsiObj: sdsi,
            hackError: hackError,
        };
        return responseFn(null, true, errorObj, 'App: Error with sdsi object');
    }

    // if there is no error
    let validSdpoArr = sdsi.sdpoArr.map((sdpo) => {
        let validFgArr = sdpo.fgArr.map((fg) => {
            return {
                fgPrice: saveWithTwoDecimalOnly(fg.fgPrice),
                fgOrderedQty: saveWithFiveDecimals(fg.fgOrderedQty),
                fgUnit: fg.fgUnit,
                fgUID: fg.fgUID,
            };
        });
        let validSfgArr = sdpo.sfgArr.map((sfg) => {
            return {
                sfgPrice: saveWithTwoDecimalOnly(sfg.sfgPrice),
                sfgOrderedQty: saveWithFiveDecimals(sfg.sfgOrderedQty),
                sfgUnit: sfg.sfgUnit,
                sfgUID: sfg.sfgUID,
            };
        });
        return {
            sdpoUID: sdpo.sdpoUID,
            customerUID: sdpo.customerUID,
            sdpoDiscountRate: sdpo.sdpoDiscountRate,
            sdpoTaxRate: sdpo.sdpoTaxRate,
            fgArr: validFgArr,
            sfgArr: validSfgArr,
        };
    });

    // c001 ==============================================
    let totalsNett = 0;

    let validSddrArr = sdsi.sddrArr.map((sddr) => {
        let validPoArr = sddr.sdpoArr.map((sdpo) => {
            let validFgArr = sdpo.fgArr.map((fg) => {
                totalsNett += applyDiscount(
                    saveWithTwoDecimalOnly(
                        fg.fgPrice * fg.sddrDeliveredQtyForFg
                    ),
                    sdpo.sdpoDiscountRate
                );

                return {
                    fgPrice: saveWithTwoDecimalOnly(fg.fgPrice),
                    sddrDeliveredQtyForFg: saveWithFiveDecimals(
                        fg.sddrDeliveredQtyForFg
                    ),
                    fgUID: fg.fgUID,
                    fgUnit: fg.fgUnit,
                };
            });
            let validSfgArr = sdpo.sfgArr.map((sfg) => {
                totalsNett += applyDiscount(
                    saveWithTwoDecimalOnly(
                        sfg.sfgPrice * sfg.sddrDeliveredQtyForSfg
                    ),
                    sdpo.sdpoDiscountRate
                );

                return {
                    sfgPrice: saveWithTwoDecimalOnly(sfg.sfgPrice),
                    sddrDeliveredQtyForSfg: saveWithFiveDecimals(
                        sfg.sddrDeliveredQtyForSfg
                    ),
                    sfgUID: sfg.sfgUID,
                    sfgUnit: sfg.sfgUnit,
                };
            });
            return {
                sdpoUID: sdpo.sdpoUID,
                customerUID: sdpo.customerUID,
                sdpoDiscountRate: sdpo.sdpoDiscountRate,
                sdpoTaxRate: sdpo.sdpoTaxRate,
                fgArr: validFgArr,
                sfgArr: validSfgArr,
            };
        });
        return {
            sddrUID: sddr.sddrUID,
            customerUID: sddr.customerUID,
            sdpoArr: validPoArr,
        };
    });

    sdsi = {
        ...sdsi,
        sdsiUID: sdsi.sdsiUID,
        sdsiNumber: sdsi.sdsiUID,
        customerUID: sdsi.customerUID,
        sdpoArr: validSdpoArr,
        sddrArr: validSddrArr,
        sdsiTaxRate: saveWithTwoDecimalOnly(sdsi.sdsiTaxRate),
        note: sdsi.note,
        sdsiTotalsNett: totalsNett,
    };

    let data = {
        sdsiObj: sdsi,
    };
    return responseFn(data, false, {}, 'App: Validation successful.');
};
