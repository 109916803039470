import React from 'react';
import {
    displayMoney,
    applyDiscount,
    // applyTax,
    saveWithTwoDecimalOnly,
    taxAmountCalc,
    totalsGrossCalc,
} from '../../lib/util';
import { Input } from '../../components';

export default (props) => {
    let { sdsiObj, type, taxRateInState, setTaxRateInState, purpose, preview } =
        props;

    let allItemsTotal = 0;

    if (type === 'PO') {
        sdsiObj.sdpoArr.forEach((sdpo) => {
            sdpo.fgArr.forEach((fg) => {
                let itemSubTotal = applyDiscount(
                    saveWithTwoDecimalOnly(fg.fgPrice * fg.fgOrderedQty),
                    sdpo.sdpoDiscountRate
                );
                // itemSubTotal = saveWithTwoDecimalOnly(
                //     applyTax(itemSubTotal, sdpo.sdpoTaxRate)
                // );
                allItemsTotal += itemSubTotal;
            });
            sdpo.sfgArr.forEach((sfg) => {
                let itemSubTotal = applyDiscount(
                    saveWithTwoDecimalOnly(sfg.sfgPrice * sfg.sfgOrderedQty),
                    sdpo.sdpoDiscountRate
                );
                // itemSubTotal = saveWithTwoDecimalOnly(
                //     applyTax(itemSubTotal, sdpo.sdpoTaxRate)
                // );
                allItemsTotal += itemSubTotal;
            });
        });
    }
    if (type === 'DR') {
        sdsiObj.sddrArr.forEach((dr) => {
            dr.sdpoArr.forEach((sdpo) => {
                sdpo.fgArr.forEach((fg) => {
                    let itemSubTotal = applyDiscount(
                        saveWithTwoDecimalOnly(
                            fg.fgPrice * fg.sddrDeliveredQtyForFg
                        ),
                        sdpo.sdpoDiscountRate
                    );
                    // itemSubTotal = saveWithTwoDecimalOnly(
                    //     applyTax(itemSubTotal, sdpo.sdpoTaxRate)
                    // );
                    allItemsTotal += itemSubTotal;
                });
                sdpo.sfgArr.forEach((sfg) => {
                    let itemSubTotal = applyDiscount(
                        saveWithTwoDecimalOnly(
                            sfg.sfgPrice * sfg.sddrDeliveredQtyForSfg
                        ),
                        sdpo.sdpoDiscountRate
                    );
                    // itemSubTotal = saveWithTwoDecimalOnly(
                    //     applyTax(itemSubTotal, sdpo.sdpoTaxRate)
                    // );
                    allItemsTotal += saveWithTwoDecimalOnly(itemSubTotal);
                });
            });
        });
    }

    let itemTotal = totalsGrossCalc(allItemsTotal, Number(taxRateInState));
    itemTotal = displayMoney(itemTotal);
    let taxAmount = displayMoney(
        taxAmountCalc(allItemsTotal, Number(taxRateInState))
    );
    let totalAmount = displayMoney(saveWithTwoDecimalOnly(allItemsTotal));

    let inputDisplay = (
        <Input
            type='number'
            value={taxRateInState}
            onChange={(e) => setTaxRateInState(e.target.value)}
            errorMessage={sdsiObj.sdsiTaxRateError}></Input>
    );

    if (
        (purpose === 'ADD' && preview) ||
        purpose === 'VIEW' ||
        purpose === 'DELETE'
    )
        inputDisplay = <span>{taxRateInState}</span>;

    let amountBox = (
        <>
            <div className='desktop-view'></div>
            <div className='amount-box'>
                <div>Totals (Gross):</div>
                <div>{itemTotal}</div>
                <div className='rate-input'>
                    <span className='m-right-15'>Tax:</span>
                    <span className='flex-row'>
                        {' '}
                        {inputDisplay} <span className='m-left-10'>%</span>
                    </span>
                </div>
                <div>{taxAmount}</div>
                <div>Totals (Nett):</div>{' '}
                <div className='text-bold'>{totalAmount}</div>
            </div>
        </>
    );

    return amountBox;
};
