import React from 'react';
import { displayMoney } from '../../lib/util';
import { BackOnlyButton, HelpTip } from '../../components';

import './ReportPage.css';

export default ({
    backCancelBtnFn,
    nameLabel,
    list,
    uid,
    amountLabel,
    total,
    helpText,
}) => {
    let helptip = helpText ? (
        <HelpTip content={helpText} placement='left' />
    ) : null;

    return (
        <section className='form-main-section'>
            <div className='common-form'>
                <section className='form-title-section'>
                    <div className='report-title'>
                        <span>{uid}</span> {helptip}
                    </div>
                </section>

                <div className='span-2-columns-container'>
                    <div className='view-table-heading label-padding label-highlight m-bottom-10 m-top-20'>
                        <div className='cell'>
                            <span className='m-left-15'>{nameLabel}</span>
                        </div>

                        <div className='cell tj-right pad-right-15'>
                            <span className='width100'>
                                <span className=''>{amountLabel}</span>
                            </span>
                        </div>
                    </div>
                    {list}
                    <div
                        className='span-2-columns-container'
                        key='accountsReceivableTotal'>
                        <div className='view-table-row'>
                            <div className='cell'></div>

                            <div className='cell show-border pad-around-10 tj-right pad-right-15'>
                                <span className='width50'>
                                    <span className='parent-labels'>
                                        Total:
                                    </span>
                                </span>
                                <span className='width50'>
                                    <span className='parent-labels'>
                                        {displayMoney(total)}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <BackOnlyButton backBtnFn={backCancelBtnFn} />

                <div className='watermark'></div>
            </div>
        </section>
    );
};
