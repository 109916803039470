import React from 'react';
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css';
import './HelpTip.css';

export default (props) => {
    let { content, addedClass } = props;

    return (
        <div className={`info-tip ${addedClass}`}>
            <Tippy trigger='click' content={content}>
                <span className='info-mark'>i</span>
            </Tippy>
        </div>
    );
};
