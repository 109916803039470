export default (reportsMasterlistStringFormat) => {
    let newArr = [];
    let reportObjsInObjFormat = JSON.parse(reportsMasterlistStringFormat);

    for (let key in reportObjsInObjFormat) {
        newArr.push({
            reportUID: key,
            reportMonth: reportObjsInObjFormat[key].a,
            reportYear: reportObjsInObjFormat[key].b,
            total: reportObjsInObjFormat[key].c,
        });
    }

    return newArr;
};
