import React from 'react';
import './Overlay.css';

export default (props)=> {

  return(
    <div className="overlay" onClick={props.onClickFn}>
        {props.children}
    </div>
  );
}