import React from 'react';
import Tippy from '@tippy.js/react';
import 'tippy.js/dist/tippy.css';
import './HelpTip.css';

export default (props) => {
    let { content, addedClass, placement = 'auto' } = props;

    return (
        <div className={`help-tip ${addedClass}`}>
            <Tippy trigger='click' content={content} placement={placement}>
                <span className='ht-mark'>?</span>
            </Tippy>
        </div>
    );
};
