export default (operationsObj, keyProp) => {
    let dateProperty = '';
    let descriptionProperty = '';
    if (keyProp === 'productionUID') {
        dateProperty = 'prodOrderDate';
        descriptionProperty = 'productionDescription';
    }
    if (keyProp === 'assemblyUID') {
        dateProperty = 'assemblyOrderDate';
        descriptionProperty = 'assemblyDescription';
    }
    if (keyProp === 'productionDeliveredUID') {
        dateProperty = 'productionDeliveredDate';
        descriptionProperty = 'productionDeliveredDescription';
    }
    if (keyProp === 'assemblyDeliveredUID') {
        dateProperty = 'assemblyDeliveredDate';
        descriptionProperty = 'assemblyDeliveredDescription';
    }

    return {
        a: operationsObj.meta.action,
        b: operationsObj[dateProperty],
        c: operationsObj[descriptionProperty],
    };
};
