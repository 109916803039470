import React from 'react';
import './SetupSupCusItem.css';
import '../CollectionItems.css';

export default (props) => {
    let { obj, serialNumber, onClick, heading, keyProperty } = props;

    let prefix = keyProperty === 'supplierUID' ? 'supplier' : 'customer';

    let serial = 'S/No.';
    let itemName = prefix === 'supplier' ? 'Supplier Name' : 'Customer Name';
    let contactPerson = 'Contact Person';
    let contactNumber = 'Contact Number';
    let headingClass = 'text-bold list-table-heading';

    let serialLabel = serial + ':';
    let itemNameLabel = itemName + ':';
    let personLabel = contactPerson + ':';
    let numberLabel = contactNumber + ':';

    if (!heading) {
        serial = serialNumber;
        itemName = obj[`${prefix}UID`];
        contactPerson = obj.contactPerson;
        contactNumber = obj.contactNumber1;

        headingClass = '';
    }

    return (
        <div
            className={`setup-sup-cus-item collection-item ${headingClass}`}
            onClick={onClick}
        >
            <div className='collection-item-entry serial'>
                {' '}
                <span className='entry-label'>{serialLabel}</span>
                {serial}
            </div>
            <div className='collection-item-entry name'>
                {' '}
                <span className='entry-label'>{itemNameLabel}</span>
                {itemName}
            </div>
            <div className='collection-item-entry contact-person'>
                {' '}
                <span className='entry-label'>{personLabel}</span>
                {contactPerson}
            </div>
            <div className='collection-item-entry contact-number'>
                {' '}
                <span className='entry-label'>{numberLabel}</span>
                {contactNumber}
            </div>
        </div>
    );
};
