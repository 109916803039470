import React, { useContext, useEffect, useState } from 'react';
import { DashCard, Chart } from '../../components';
import { MasterListContext } from '../../contexts';
import {
    getPDPOChartData,
    getPDDRChartData,
    getPDSIChartData,
    getSDPOChartData,
    getSDDRChartData,
    getSDSIChartData,
    getOperationOrderProcessChartData,
    getOperationDeliveredProcessChartData,
    getPDVRChartData,
    getSDCRChartData,
} from './getChartDataFns';

import color from './getChartDataFns/chartColors';

import './Dashboard.css';

const defaultChartValues = {
    dataBackgroundColors: [],
    dataLabels: [],
    dataValues: [],
};

export default () => {
    let {
        masterSuppliersList,
        masterCustomersList,
        masterRawMaterialsList,
        masterSemiFGsList,
        masterFinishedGoodsList,
        masterPurchaseOrdersList,
        masterDeliveryReceiptsList,
        masterPDSalesInvoicesList,
        masterProdOrdersList,
        masterProductionDeliveredList,
        masterAssemblyList,
        masterAssemblyDeliveredList,
        masterSdpoList,
        masterSddrList,
        masterSdsiList,
        masterPdvrList,
        masterSdcrList,
        masterInvTranferList,
    } = useContext(MasterListContext);

    let [reset, setReset] = useState(0);

    let [pdpoData, setPdpoData] = useState(defaultChartValues);
    let [pddrData, setPddrData] = useState(defaultChartValues);
    let [pdsiData, setPdsiData] = useState(defaultChartValues);
    let [pdvrData, setPdvrData] = useState(defaultChartValues);
    let [sdpoData, setSdpoData] = useState(defaultChartValues);
    let [sddrData, setSddrData] = useState(defaultChartValues);
    let [sdsiData, setSdsiData] = useState(defaultChartValues);
    let [sdcrData, setSdcrData] = useState(defaultChartValues);
    let [prodOrderOrderProcess, setProdOrderOrderProcess] = useState(
        defaultChartValues
    );
    let [prodOrderProduceProcess, setProdOrderProduceProcess] = useState(
        defaultChartValues
    );
    let [assemblyOrderProcess, setAssemblyOrderProcess] = useState(
        defaultChartValues
    );
    let [assemblyAssembledProcess, setAssemblyAssembledProcess] = useState(
        defaultChartValues
    );
    let [inventoryTransferData, setInventoryTransferData] = useState(
        defaultChartValues
    );

    useEffect(() => {
        let items = getPDPOChartData(masterPurchaseOrdersList);
        setPdpoData(items);
        setReset(reset + 1);
    }, [masterPurchaseOrdersList]);

    useEffect(() => {
        let items = getPDDRChartData(masterDeliveryReceiptsList);
        setPddrData(items);
        setReset(reset + 1);
    }, [masterDeliveryReceiptsList]);

    useEffect(() => {
        let items = getPDSIChartData(masterPDSalesInvoicesList);
        setPdsiData(items);
        setReset(reset + 1);
    }, [masterPDSalesInvoicesList]);

    useEffect(() => {
        let items = getPDVRChartData(masterPdvrList);
        setPdvrData(items);
        setReset(reset + 1);
    }, [masterPdvrList]);

    useEffect(() => {
        let items = getSDPOChartData(masterSdpoList);
        setSdpoData(items);
        setReset(reset + 1);
    }, [masterSdpoList]);

    useEffect(() => {
        let items = getSDDRChartData(masterSddrList);
        setSddrData(items);
        setReset(reset + 1);
    }, [masterSddrList]);

    useEffect(() => {
        let items = getSDSIChartData(masterSdsiList);
        setSdsiData(items);
        setReset(reset + 1);
    }, [masterSdsiList]);

    useEffect(() => {
        let items = getSDCRChartData(masterSdcrList);
        setSdcrData(items);
        setReset(reset + 1);
    }, [masterSdcrList]);

    useEffect(() => {
        let orders = getOperationOrderProcessChartData(masterProdOrdersList);
        setProdOrderOrderProcess(orders);
        setReset(reset + 1);
    }, [masterProdOrdersList]);

    useEffect(() => {
        let produced = getOperationDeliveredProcessChartData(
            masterProductionDeliveredList
        );
        setProdOrderProduceProcess(produced);

        setReset(reset + 1);
    }, [masterProductionDeliveredList]);

    useEffect(() => {
        let orders = getOperationOrderProcessChartData(masterAssemblyList);
        setAssemblyOrderProcess(orders);

        setReset(reset + 1);
    }, [masterAssemblyList]);

    useEffect(() => {
        let assembled = getOperationDeliveredProcessChartData(
            masterAssemblyDeliveredList
        );
        setAssemblyAssembledProcess(assembled);

        setReset(reset + 1);
    }, [masterAssemblyDeliveredList]);

    useEffect(() => {
        let invTransfer = getOperationDeliveredProcessChartData(
            masterInvTranferList
        );
        setInventoryTransferData(invTransfer);

        setReset(reset + 1);
    }, [masterInvTranferList]);

    let rmLength = masterRawMaterialsList.length;
    let sfgLength = masterSemiFGsList.length;
    let fgLength = masterFinishedGoodsList.length;
    let suppliersLength = masterSuppliersList.length;
    let customersLength = masterCustomersList.length;

    return (
        <div className='content-layout'>
            <div className='dashboard-card-section'>
                <DashCard
                    mainHeading='Raw Materials'
                    mainText={rmLength}
                    mainIcon={
                        <i
                            className='ms-Icon ms-Icon--Sections'
                            aria-hidden='true'></i>
                    }
                    mainIconColor={color.green}
                />
                <DashCard
                    mainHeading='Semi-Finished Goods'
                    mainText={sfgLength}
                    mainIcon={
                        <i
                            className='ms-Icon ms-Icon--ProductVariant'
                            aria-hidden='true'></i>
                    }
                    mainIconColor={color.sky}
                />
                <DashCard
                    mainHeading='Finished Goods'
                    mainText={fgLength}
                    mainIcon={
                        <i
                            className='ms-Icon ms-Icon--Packages'
                            aria-hidden='true'></i>
                    }
                    mainIconColor={color.purple}
                />
                <DashCard
                    mainHeading='Suppliers'
                    mainText={suppliersLength}
                    mainIcon={
                        <i
                            className='ms-Icon ms-Icon--DeliveryTruck'
                            aria-hidden='true'></i>
                    }
                    mainIconColor={color.lightgreen}
                />
                <DashCard
                    mainHeading='Customers'
                    mainText={customersLength}
                    mainIcon={
                        <i
                            className='ms-Icon ms-Icon--Teamwork'
                            aria-hidden='true'></i>
                    }
                    mainIconColor={color.amber}
                />
            </div>

            <div className='dashboard-chart-section'>
                <Chart
                    chartID='pdpo'
                    chartType='pie'
                    label='Purchase Dept: Purchase Orders'
                    dataValues={pdpoData.dataValues}
                    dataLabels={pdpoData.dataLabels}
                    dataBackgroundColors={pdpoData.dataBackgroundColors}
                    reset={reset}></Chart>

                <Chart
                    chartID='pddr'
                    chartType='pie'
                    label='Purchase Dept: Delivery Receipt'
                    dataValues={pddrData.dataValues}
                    dataLabels={pddrData.dataLabels}
                    dataBackgroundColors={pddrData.dataBackgroundColors}
                    reset={reset}></Chart>

                <Chart
                    chartID='pdsi'
                    chartType='pie'
                    label='Purchase Dept: Supplier Invoice'
                    dataValues={pdsiData.dataValues}
                    dataLabels={pdsiData.dataLabels}
                    dataBackgroundColors={pdsiData.dataBackgroundColors}
                    reset={reset}></Chart>

                <Chart
                    chartID='pdvr'
                    chartType='pie'
                    label='Purchase Dept: Voucher Receipt'
                    dataValues={pdvrData.dataValues}
                    dataLabels={pdvrData.dataLabels}
                    dataBackgroundColors={pdvrData.dataBackgroundColors}
                    reset={reset}></Chart>

                <Chart
                    chartID='sdpo'
                    chartType='pie'
                    label='Sales Dept: Purchase Order'
                    dataValues={sdpoData.dataValues}
                    dataLabels={sdpoData.dataLabels}
                    dataBackgroundColors={sdpoData.dataBackgroundColors}
                    reset={reset}></Chart>

                <Chart
                    chartID='sddr'
                    chartType='pie'
                    label='Sales Dept: Delivery Receipt'
                    dataValues={sddrData.dataValues}
                    dataLabels={sddrData.dataLabels}
                    dataBackgroundColors={sddrData.dataBackgroundColors}
                    reset={reset}></Chart>

                <Chart
                    chartID='sdsi'
                    chartType='pie'
                    label='Sales Dept: Sales Invoice'
                    dataValues={sdsiData.dataValues}
                    dataLabels={sdsiData.dataLabels}
                    dataBackgroundColors={sdsiData.dataBackgroundColors}
                    reset={reset}></Chart>

                <Chart
                    chartID='sdcr'
                    chartType='pie'
                    label='Sales Dept: Collection Receipt'
                    dataValues={sdcrData.dataValues}
                    dataLabels={sdcrData.dataLabels}
                    dataBackgroundColors={sdcrData.dataBackgroundColors}
                    reset={reset}></Chart>

                <Chart
                    chartID='prodOrderOrderProcess'
                    chartType='pie'
                    label='Production Order: Order Process'
                    dataValues={prodOrderOrderProcess.dataValues}
                    dataLabels={prodOrderOrderProcess.dataLabels}
                    dataBackgroundColors={
                        prodOrderOrderProcess.dataBackgroundColors
                    }
                    reset={reset}></Chart>

                <Chart
                    chartID='prodOrderProduceProcess'
                    chartType='pie'
                    label='Production Order: Production Process'
                    dataValues={prodOrderProduceProcess.dataValues}
                    dataLabels={prodOrderProduceProcess.dataLabels}
                    dataBackgroundColors={
                        prodOrderProduceProcess.dataBackgroundColors
                    }
                    reset={reset}></Chart>

                <Chart
                    chartID='assemblyOrderProcess'
                    chartType='pie'
                    label='Assembly Order: Order Process'
                    dataValues={assemblyOrderProcess.dataValues}
                    dataLabels={assemblyOrderProcess.dataLabels}
                    dataBackgroundColors={
                        assemblyOrderProcess.dataBackgroundColors
                    }
                    reset={reset}></Chart>

                <Chart
                    chartID='assemblyAssembledProcess'
                    chartType='pie'
                    label='Assembly Order: Assembly Process'
                    dataValues={assemblyAssembledProcess.dataValues}
                    dataLabels={assemblyAssembledProcess.dataLabels}
                    dataBackgroundColors={
                        assemblyAssembledProcess.dataBackgroundColors
                    }
                    reset={reset}></Chart>

                <Chart
                    chartID='invTransfer'
                    chartType='pie'
                    label='Inventory Transfer'
                    dataValues={inventoryTransferData.dataValues}
                    dataLabels={inventoryTransferData.dataLabels}
                    dataBackgroundColors={
                        inventoryTransferData.dataBackgroundColors
                    }
                    reset={reset}></Chart>
            </div>
        </div>
    );
};
