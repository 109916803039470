/**
 * Sept 1, 2020: Function approveSdcrFn created. this will change status of PDSI to
 * approve and change status of all related DR's (PO's no longer an option)
 * to 'APPROVED - LOCKED'.
 *
 *
 *
 */

import { db, firebase } from '../firebase_config/firebaseConfig';
import { userActions, systemActions } from '../lib/constants';
import { responseFn, formatCustomErrorObj } from '../lib/util';
import dbopsGetDocument from './dbopsGetDocument';
import validate from '../validators';
import mListHelper from './mListHelper';
import { docNames, appMessage } from '../lib/messages';
import sdObjToMListItem from '../lib/masterListConverters/sdObjToMListItem';

import {
    SDCRS_COL,
    MASTER_LISTS_COL,
    MASTER_SDCR_LIST_DOC,
    DELETED_SDCRS_COL,
    MASTER_SDSI_LIST_DOC,
    SDSIS_COL,
} from '../lib/constants';

// ================================================
// function to fetch each supplier document.
const getCrFn = async (docID) => dbopsGetDocument(SDCRS_COL, docID, 'PDSI');

// ======================================================
const createCrFn = async (userCreds, crObj) => {
    // add metadata to crObj to be created in firestore.
    crObj.meta = {
        action: userActions.CREATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // add metaHistory
    crObj.metaHistory = [];

    // get references
    let sdcrRef = db.collection(SDCRS_COL).doc(crObj.sdcrUID);
    let sdcrListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_SDCR_LIST_DOC);

    const transactionFn = async (transaction) => {
        // get docs
        let sdcrDoc = await transaction.get(sdcrRef);
        let sdcrListDoc = await transaction.get(sdcrListRef);

        // exist checking.
        if (sdcrDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1002,
                    appMessage.alreadyExist(docNames.SDCR)
                )
            );

        await transaction.set(sdcrRef, crObj);

        // create or update suppliers list document in (master lists collection)
        if (sdcrListDoc.exists) {
            let sdcrInfoObj = sdObjToMListItem(crObj);

            let newData = mListHelper.updateMasterList(
                sdcrListDoc,
                crObj.sdcrUID,
                sdcrInfoObj
            );

            await transaction.set(sdcrListRef, newData);
        } else {
            let sdcrInfoObj = sdObjToMListItem(crObj);

            let newData = mListHelper.addMasterList(crObj.sdcrUID, sdcrInfoObj);

            await transaction.set(sdcrListRef, newData);
        }

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyCreated(docNames.SDCR)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorCreating(docNames.SDCR)
        );
    }
};

// ===============================================
const updateCrFn = async (userCreds, crObj, documentBasis) => {
    if (crObj.metaHistory.length > 99) crObj.metaHistory.shift();

    crObj.metaHistory.push(crObj.meta);
    // add metadata to crObj to be created in firestore.
    crObj.meta = {
        action: userActions.UPDATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // get references
    let sdcrRef = db.collection(SDCRS_COL).doc(crObj.sdcrUID);
    let sdcrListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_SDCR_LIST_DOC);

    const transactionFn = async (transaction) => {
        // get docs
        let sdcrDoc = await transaction.get(sdcrRef);
        let sdcrListDoc = await transaction.get(sdcrListRef);

        // exist checking.
        if (!sdcrDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.SDCR)
                )
            );

        if (!validate.noChange(sdcrDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.SDCR)
                )
            );
        }

        let sdcrInfoObj = sdObjToMListItem(crObj);

        let newData = mListHelper.updateMasterList(
            sdcrListDoc,
            crObj.sdcrUID,
            sdcrInfoObj
        );

        await transaction.set(sdcrListRef, newData);

        await transaction.set(sdcrRef, crObj);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyUpdated(docNames.SDCR)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorUpdating(docNames.SDCR)
        );
    }
};

// ================================================================
const deleteCrFn = async (userCreds, crObj, documentBasis) => {
    if (crObj.metaHistory.length > 99) crObj.metaHistory.shift();

    crObj.metaHistory.push(crObj.meta);

    crObj.meta = {
        action: userActions.DELETED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let sdcrRef = db.collection(SDCRS_COL).doc(crObj.sdcrUID);
    let sdcrListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_SDCR_LIST_DOC);

    let deletedSdcrRef = db.collection(DELETED_SDCRS_COL).doc();

    const transactionFn = async (transaction) => {
        let sdcrDoc = await transaction.get(sdcrRef);

        if (!sdcrDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotDeleteBecauseDocNotExist(docNames.SDCR)
                )
            );

        if (!validate.noChange(sdcrDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotDeleteBecauseDocChanged(docNames.SDCR)
                )
            );
        }

        let sdcrListDoc = await transaction.get(sdcrListRef);
        await transaction.get(deletedSdcrRef);

        let newData = mListHelper.deletePropertyFromMasterList(
            sdcrListDoc,
            crObj.sdcrUID
        );

        await transaction.delete(sdcrRef);
        await transaction.set(sdcrListRef, newData);
        await transaction.set(deletedSdcrRef, crObj);
        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyDeleted(docNames.SDCR)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorDeleting(docNames.SDCR)
        );
    }
};

// ================================================================
const statusChangeFn = async (userCreds, crObj, documentBasis) => {
    if (crObj.metaHistory.length > 99) crObj.metaHistory.shift();

    crObj.metaHistory.push(crObj.meta);

    // update metadata in crObj.
    crObj.meta = {
        action: userCreds.action,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let sdcrRef = db.collection(SDCRS_COL).doc(crObj.sdcrUID);
    let sdcrListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_SDCR_LIST_DOC);

    const transactionFn = async (transaction) => {
        //1. perform all transaction gets
        let sdcrDoc = await transaction.get(sdcrRef);
        let sdcrListDoc = await transaction.get(sdcrListRef);

        //2. check sdcrDoc against documentBasis if there is any change.
        if (!sdcrDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.SDCR)
                )
            );

        // ! this does not check meta data.
        if (!validate.noChange(sdcrDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.SDCR)
                )
            );
        }
        // ! meta data check if status of document changed.
        if (sdcrDoc.data().meta.action !== documentBasis.meta.action) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.SDCR)
                )
            );
        }

        let sdcrInfoObj = sdObjToMListItem(crObj);

        let newData = mListHelper.updateMasterList(
            sdcrListDoc,
            crObj.sdcrUID,
            sdcrInfoObj
        );

        // write to masterlist and pdpo document in firebase.
        await transaction.set(sdcrListRef, newData);
        await transaction.set(sdcrRef, crObj);
        return Promise.resolve('TransactionFn Completed Successfully');
    };
    try {
        await db.runTransaction(transactionFn);
        return responseFn(null, false, null, appMessage.successfulUpdateStatus);
    } catch (e) {
        return responseFn(null, true, e, appMessage.errorUpdatingStatus);
    }
};

const approveSdcrFn = async (userCreds, crObj, documentBasis) => {
    if (crObj.metaHistory.length > 99) crObj.metaHistory.shift();

    crObj.metaHistory.push(crObj.meta);

    // update metadata in crObj.
    crObj.meta = {
        action: userCreds.action,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // get references for documents needed. =========================
    let sdcrRef = db.collection(SDCRS_COL).doc(crObj.sdcrUID);
    let sdcrListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_SDCR_LIST_DOC);
    let sdsiListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_SDSI_LIST_DOC);

    let documentBasisSiArrRefs = documentBasis.sdsiArr.map((si) =>
        db.collection(SDSIS_COL).doc(si.sdsiUID)
    );

    const transactionFn = async (transaction) => {
        let existError = false;
        let editedError = false;

        // =============================================================
        // START TRANSACTION GET =================================
        let sdcrDoc = await transaction.get(sdcrRef);
        let sdcrListDoc = await transaction.get(sdcrListRef);
        let sdsiListDoc = await transaction.get(sdsiListRef);

        let documentBasisSiArrDocs = documentBasisSiArrRefs.map(
            async (siRef) => {
                return await transaction.get(siRef);
            }
        );

        try {
            documentBasisSiArrDocs = await Promise.all(documentBasisSiArrDocs);
        } catch (e) {
            return Promise.reject(
                formatCustomErrorObj(
                    900,
                    appMessage.prommiseAllError(
                        'dbopsSDCRs',
                        'documentBasisSiArr'
                    )
                )
            );
        }

        // END TRANSACTION GET =============================================

        // START CHECK FOR CHANGE AND EXISTENCE =================================
        if (!sdcrDoc.exists) {
            return Promise.reject(
                //! this should never run.
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.SDCR)
                )
            );
        }

        // ! this does not check meta data.
        if (!validate.noChange(sdcrDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.SDCR)
                )
            );
        }
        // ! meta data check if status of document changed.
        if (sdcrDoc.data().meta.action !== documentBasis.meta.action) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.SDCR)
                )
            );
        }

        // check if there are any changes to the SIs involved. only proceed with transaction
        // if pdsi status is still APPROVED.
        documentBasisSiArrDocs.forEach((docBasisDr) => {
            if (!docBasisDr.exists) existError = true;

            // ! meta data check if status of document changed. MUST be approved, if not,
            //! it means that si was marked APPROVED - LOCKED by another PDSI.

            if (crObj.meta.action === userActions.APPROVED) {
                if (docBasisDr.data().meta.action !== userActions.APPROVED) {
                    editedError = true;
                }
            }
            //! this should not run.
            if (crObj.meta.action === userActions.MARK_AS_COLLECTED) {
                if (
                    docBasisDr.data().meta.action !==
                    systemActions.IN_COLLECTION
                ) {
                    editedError = true;
                }
            }
        });

        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopsPDSIs',
                        docNames.SDCR,
                        'PDSI'
                    )
                )
            );
        }
        if (editedError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,

                    'One or more SIs in this Collection receipt was already locked'
                )
            );
        }

        // END CHECK FOR CHANGE AND EXISTENCE ==================================

        // START TRANSACTION SET ===============================================
        let tempMasterSdsiList = sdsiListDoc.data();

        documentBasisSiArrDocs.forEach(async (si, siIndex) => {
            let siCopy = {
                ...si.data(),
            };

            // change status of si object.
            if (siCopy.metaHistory.length > 99) siCopy.metaHistory.shift();

            siCopy.metaHistory.push(siCopy.meta);

            // update metadata in siCopy.

            let newStatus = systemActions.PAID;
            if (crObj.meta.action === userActions.APPROVED)
                newStatus = systemActions.IN_COLLECTION;

            siCopy.meta = {
                action: newStatus,
                uid: systemActions.UID,
                email: systemActions.EMAIL,
                timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
            };

            // update temporary pdpo master list
            let sdsiInfoObj = sdObjToMListItem(siCopy);

            // this variable will be used outside the loop to update
            // the masterList ONCE.
            tempMasterSdsiList = mListHelper.updateTempMList(
                tempMasterSdsiList,
                siCopy.sdsiUID,
                sdsiInfoObj
            );

            await transaction.set(documentBasisSiArrRefs[siIndex], siCopy, {
                merge: true,
            });
        });

        // if (sdcrDoc.exists) {
        //     return Promise.reject(
        //         //! this should never run.
        //         formatCustomErrorObj(
        //             1000,
        //             appMessage.cannotEditBecauseDocNotExist(docNames.SDCR)
        //         )
        //     );
        // }

        // After updating all the PDSI's, update the master list of PDSIs
        await transaction.set(sdsiListRef, tempMasterSdsiList);

        await transaction.set(sdcrRef, crObj);

        let sdcrInfoObj = sdObjToMListItem(crObj);
        let newData = mListHelper.updateMasterList(
            sdcrListDoc,
            crObj.sdcrUID,
            sdcrInfoObj
        );
        await transaction.set(sdcrListRef, newData);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(null, false, null, appMessage.successfulUpdateStatus);
    } catch (e) {
        return responseFn(null, true, e, appMessage.errorUpdatingStatus);
    }
};

export default {
    getCrFn,
    createCrFn,
    updateCrFn,
    deleteCrFn,
    statusChangeFn,
    approveSdcrFn,
};
