export default (fgMasterlistStringFormat) => {
    let newArr = [];
    let fgObjsInObjFormat = JSON.parse(fgMasterlistStringFormat);

    for (let key in fgObjsInObjFormat) {
        newArr.push({
            fgUID: key,
            fgCode: fgObjsInObjFormat[key].a,
            fgPrice: fgObjsInObjFormat[key].b,
            fgUnit: fgObjsInObjFormat[key].c,
        });
    }

    return newArr;
};
