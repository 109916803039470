import React from 'react';
import { HelpTip, InputDS } from '../../components';
import tooltips from '../../lib/tooltips';
import '../PagesCommon.css';
import './AssemblyPage.css';
import { displayReadableNumber, saveWithFiveDecimals } from '../../lib/util';

// import { userActions } from '../../lib/constants';

const GroupedRM = (props) => {
    let { rmArr, fgUID, fgIndex, purpose, updateRmValFn } = props;

    let rms = rmArr.map((rm) => {
        let overrideUI = (
            <div className='width20'>
                <span className='mobile-label'>Override Qty:</span>{' '}
                <span> {displayReadableNumber(rm.rmOverrideQty)}</span>
            </div>
        );

        if (purpose === 'OVERRIDE') {
            overrideUI = (
                <div className='width20'>
                    <span className='mobile-label'>Override Qty:</span>{' '}
                    <span>
                        <InputDS
                            label=''
                            type='number'
                            initialValue={rm.rmOverrideQty}
                            getInputValue={(val) =>
                                updateRmValFn(
                                    val,
                                    fgIndex,
                                    rm.tempRmIndex,
                                    'rmOverrideQty'
                                )
                            }
                            errorMessage={rm.rmOverrideQtyError}></InputDS>
                    </span>
                </div>
            );
        }

        return (
            <div
                className='span-2-columns-container'
                key={`${fgUID}${rm.rmUID}`}>
                <div className='view-table-row grid-row-40-60'>
                    <div className='cell'>
                        <div className='width16'>
                            <span className='mobile-label'>Grp:</span>{' '}
                            <span className='desktop-view m-left-15'>
                                {' '}
                                {rm.group}
                            </span>
                            <span className='mobile-label'> {rm.group}</span>
                        </div>
                        <div className=''>
                            <span className='mobile-label'>RM:</span>{' '}
                            <span> {rm.rmUID}</span>
                        </div>
                    </div>

                    <div className='cell'>
                        <div className='width20'>
                            {' '}
                            <span className='mobile-label'>
                                Actual Qty (Used):
                            </span>{' '}
                            <span>
                                {' '}
                                {displayReadableNumber(rm.rmActualUsedQty)}
                            </span>
                        </div>
                        <div className='width20'>
                            <span className='mobile-label'>
                                Projected Qty (Planned):
                            </span>{' '}
                            <span>
                                {' '}
                                {displayReadableNumber(rm.rmProjectedUseQty)}
                            </span>
                        </div>
                        <div className='width20'>
                            <span className='mobile-label'>Balance Qty:</span>{' '}
                            <span>
                                {' '}
                                {displayReadableNumber(
                                    saveWithFiveDecimals(
                                        Number(rm.rmBalanceQty) +
                                            Number(rm.rmOverrideQty)
                                    )
                                )}
                            </span>
                        </div>
                        {overrideUI}
                        <div className='width20'>
                            <span className='mobile-label'>Unit:</span>{' '}
                            <span>{rm.rmUnit}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    return <div className='span-2-columns-container'>{rms}</div>;
};

const GroupedSFG = (props) => {
    let { sfgArr, fgUID, fgIndex, purpose, updateSfgValFn } = props;

    let sfg = sfgArr.map((sfg) => {
        let overrideUI = (
            <div className='width20'>
                <span className='mobile-label'>Override Qty:</span>{' '}
                <span> {displayReadableNumber(sfg.sfgOverrideQty)}</span>
            </div>
        );

        if (purpose === 'OVERRIDE') {
            overrideUI = (
                <div className='width20'>
                    <span className='mobile-label'>Override Qty:</span>{' '}
                    <span>
                        <InputDS
                            label=''
                            type='number'
                            initialValue={sfg.sfgOverrideQty}
                            getInputValue={(val) =>
                                updateSfgValFn(
                                    val,
                                    fgIndex,
                                    sfg.tempSfgIndex,
                                    'sfgOverrideQty'
                                )
                            }
                            errorMessage={sfg.sfgOverrideQtyError}></InputDS>
                    </span>
                </div>
            );
        }

        return (
            <div
                className='span-2-columns-container'
                key={`${fgUID}${sfg.sfgUID}`}>
                <div className='view-table-row grid-row-40-60'>
                    <div className='cell'>
                        <div className='width16'>
                            <span className='mobile-label'>Grp:</span>{' '}
                            <span className='desktop-view m-left-15'>
                                {' '}
                                {sfg.group}
                            </span>
                        </div>
                        <div className=''>
                            <span className='mobile-label'>SFG:</span>{' '}
                            <span> {sfg.sfgUID}</span>
                        </div>
                    </div>
                    <div className='cell'>
                        <div className='width20'>
                            {' '}
                            <span className='mobile-label'>
                                Actual Qty (Used):
                            </span>{' '}
                            <span>
                                {' '}
                                {displayReadableNumber(sfg.sfgActualUsedQty)}
                            </span>
                        </div>
                        <div className='width20'>
                            <span className='mobile-label'>
                                Projected Qty (Planned):
                            </span>{' '}
                            <span>
                                {' '}
                                {displayReadableNumber(sfg.sfgProjectedUseQty)}
                            </span>
                        </div>
                        <div className='width20'>
                            <span className='mobile-label'>Balance Qty:</span>{' '}
                            <span>
                                {' '}
                                {displayReadableNumber(
                                    saveWithFiveDecimals(
                                        Number(sfg.sfgBalanceQty) +
                                            Number(sfg.sfgOverrideQty)
                                    )
                                )}
                            </span>
                        </div>
                        {overrideUI}
                        <div className='width20'>
                            <span className='mobile-label'>Unit:</span>{' '}
                            <span>{sfg.sfgUnit}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    return <div className='span-2-columns-container'>{sfg}</div>;
};

export default (props) => {
    let {
        fgObj,
        fgIndex,
        purpose,
        updateFgValFn,
        updateRmValFn,
        updateSfgValFn,
    } = props;

    let groupedRmMap = {};
    let groupedSfgMap = {};

    fgObj.rmArr.forEach((rm, rmIndex) => {
        rm.tempRmIndex = rmIndex;
        if (groupedRmMap[rm.group] === undefined) {
            groupedRmMap[rm.group] = [];
            groupedRmMap[rm.group].push(rm);
        } else groupedRmMap[rm.group].push(rm);
    });

    fgObj.sfgArr.forEach((sfg, sfgIndex) => {
        sfg.tempSfgIndex = sfgIndex;
        if (groupedSfgMap[sfg.group] === undefined) {
            groupedSfgMap[sfg.group] = [];
            groupedSfgMap[sfg.group].push(sfg);
        } else groupedSfgMap[sfg.group].push(sfg);
    });

    let listOfRms = [];
    let listOfSfgs = [];

    for (let gkey in groupedRmMap) {
        let component = (
            <GroupedRM
                key={`${fgObj.fgUID}${gkey}`}
                fgUID={fgObj.fgUID}
                fgIndex={fgIndex}
                rmArr={groupedRmMap[gkey]}
                purpose={purpose}
                updateRmValFn={updateRmValFn}
            />
        );
        listOfRms.push(component);
    }

    for (let key in groupedSfgMap) {
        let component = (
            <GroupedSFG
                key={`${fgObj.fgUID}${key}`}
                fgUID={fgObj.fgUID}
                fgIndex={fgIndex}
                sfgArr={groupedSfgMap[key]}
                purpose={purpose}
                updateSfgValFn={updateSfgValFn}
            />
        );
        listOfSfgs.push(component);
    }

    let fgOverrideUI = (
        <span className='width20 flex-column-to-row'>
            <span className='font-size-13 text-bold m-right-5'>
                Override Qty
            </span>{' '}
            <span>
                <span>{displayReadableNumber(fgObj.fgOverrideQty)} </span>
            </span>
        </span>
    );

    if (purpose === 'OVERRIDE') {
        fgOverrideUI = (
            <span className='width20 flex-column-to-row'>
                <span className='font-size-13 text-bold m-right-5'>
                    Override Qty
                </span>{' '}
                <span>
                    <span>
                        <InputDS
                            label=''
                            type='number'
                            initialValue={fgObj.fgOverrideQty}
                            getInputValue={(val) =>
                                updateFgValFn(val, fgIndex, 'fgOverrideQty')
                            }
                            errorMessage={fgObj.fgOverrideQtyError}></InputDS>
                    </span>
                </span>
            </span>
        );
    }

    let rmSection = (
        <div className='span-2-columns-container show-border pad-around-10'>
            <div className='view-table-heading grid-row-40-60'>
                <div className='cell'>
                    <span className='width16'>
                        <span className='m-left-15'>Grp</span>
                    </span>
                    <span>RM Items</span>
                </div>
                <div className='cell'>
                    <span className='width20'>
                        Actual Qty (Used){' '}
                        <HelpTip content={tooltips.assemRmActualUsedQty} />
                    </span>
                    <span className='width20'>Projected Qty (Planned)</span>
                    <span className='width20'>Balance Qty</span>
                    <span className='width20'>Override Qty</span>
                    <span className='width20'>Unit</span>
                </div>
            </div>
            {listOfRms}
        </div>
    );

    let sfgSection = (
        <div className='span-2-columns-container show-border pad-around-10 m-top-10'>
            <div className='view-table-heading grid-row-40-60'>
                <div className='cell'>
                    <span className='width16'>
                        <span className='m-left-15'>Grp</span>
                    </span>
                    <span>SFG Items</span>
                </div>
                <div className='cell'>
                    <span className='width20'>
                        Actual Qty (Used){' '}
                        <HelpTip content={tooltips.assemSfgActualUsedQty} />
                    </span>
                    <span className='width20'>Projected Qty (Planned)</span>
                    <span className='width20'>Balance Qty</span>
                    <span className='width20'>Override Qty</span>
                    <span className='width20'>Unit</span>
                </div>
            </div>
            {listOfSfgs}
        </div>
    );

    if (listOfRms.length === 0) rmSection = null;
    if (listOfSfgs.length === 0) sfgSection = null;

    return (
        <div
            className='span-2-columns-container show-border-darker pad-around-10 m-top-10'
            key={`${fgObj.fgUID}${fgIndex}`}>
            <div className='view-table-row grid-row-40-60 bg-grey-l2 pad-around-10'>
                <div className='cell'>
                    <span className='m-right-5'>{`${fgIndex + 1}. `}</span>
                    <span>{fgObj.fgUID}</span>
                </div>
                <div className='cell'>
                    <span className='width20 flex-column-to-row'>
                        <span className='font-size-13 text-bold m-right-5'>
                            Assembled Qty{' '}
                            <HelpTip content={tooltips.assemFgAssembledQty} />
                        </span>{' '}
                        <span>
                            {displayReadableNumber(fgObj.fgAssembledQty)}{' '}
                        </span>
                    </span>

                    <span className='width20 flex-column-to-row'>
                        <span className='font-size-13 text-bold m-right-5'>
                            Ordered Qty
                        </span>{' '}
                        <span>
                            {displayReadableNumber(fgObj.fgOrderedQty)}{' '}
                        </span>
                    </span>

                    <span className='width20 flex-column-to-row'>
                        <span className='font-size-13 text-bold m-right-5'>
                            Balance Qty
                        </span>{' '}
                        <span>
                            {displayReadableNumber(
                                saveWithFiveDecimals(
                                    Number(fgObj.fgBalanceQty) +
                                        Number(fgObj.fgOverrideQty)
                                )
                            )}{' '}
                        </span>
                    </span>
                    {fgOverrideUI}

                    <span className='width20 flex-column-to-row'>
                        <span className='font-size-13 text-bold m-right-5'>
                            Unit
                        </span>{' '}
                        <span>{fgObj.fgUnit}</span>
                    </span>
                </div>
            </div>

            {rmSection}
            {sfgSection}
        </div>
    );
};
