/*
comment1 - sept 15, 2020; added feature of getting rmUnit from firebase. requested by client.

*/

import React, { useState, useEffect, useContext } from 'react';
import {
    Datalist,
    AddButton,
    InputDS,
    Input,
    CreateItemButtons,
    UpdateItemButtons,
    DeleteItemButtons,
    BackOnlyButton,
    BackSendEditDelButtons,
    BackWithMarkAsButtons,
    ApproveRejectButtons,
    OverrideWithBackButtons,
    OverrideSaveButtons,
    HelpTip,
    TextArea,
    Watermark,
} from '../../components';
import {
    AuthContext,
    MasterListContext,
    DataShareContext,
} from '../../contexts';
import {
    permissions,
    userActions,
    systemActions,
    lengths,
} from '../../lib/constants';
import { dbopsCostHistory, dbopsRawMaterials } from '../../firebase_dbops';
import {
    clone,
    displayDate,
    displayReadableNumber,
    displayMoney,
    saveWithFiveDecimals,
    saveWithTwoDecimalOnly,
} from '../../lib/util';
import AddRmFormInPo from './AddRmFormInPo';
import PdpoAmountBox from './PdpoAmountBox';

import validate from '../../validators';

import '../PagesCommon.css';
import './PDPOsPage.css';
import tooltips from '../../lib/tooltips';

let defaultPDPO = {
    pdpoUID: '',
    pdpoNumber: '',
    supplierUID: '',
    rmArr: [],
    meta: { action: '' },
    note: '',
};

const SuppliersCanvassedList = (props) => {
    let { rm } = props;

    let canvasSuplist = rm.canvasSupplierArr.map((canvasSup, csIndex) => {
        let bgHighlight =
            canvasSup.supplierUID === rm.chosenSupplier ? 'bg-grey-l2' : '';

        return (
            <div
                className={`flex-row-to-column width100 pad-around-5 ${bgHighlight}`}
                key={`${canvasSup.supplierUID} - ${csIndex}`}
            >
                <div className='width50'>
                    {' '}
                    <span className='mobile-label'>Supplier Name:</span>{' '}
                    {canvasSup.supplierUID}
                </div>
                <div className='width25'>
                    {' '}
                    <span className='mobile-label'>Cost:</span>{' '}
                    {displayMoney(canvasSup.cost)}
                </div>
                <div className='width25'>
                    <span className='mobile-label'>Date:</span>{' '}
                    <span>{canvasSup.costDate}</span>
                </div>
            </div>
        );
    });

    return (
        <div className='view-table-row grid-row-40-60' key={rm.rmUID}>
            {' '}
            <div className='cell desktop-view'></div>{' '}
            <div className='cell flex-column show-border'>
                <div className='flex-row width100  bg-grey-l1 pad-around-5'>
                    <span className='font12 m-right-5'>
                        Suppliers list used for
                    </span>{' '}
                    <span className='text-bold font12'>{rm.rmUID}</span>
                </div>
                <div className='flex-row width100 pad-around-5 desktop-view'>
                    <div className='width50 parent-labels'>Supplier Name</div>
                    <div className='width25 parent-labels'>Cost</div>
                    <div className='width25 parent-labels'>Date</div>
                </div>
                {canvasSuplist}
            </div>
        </div>
    );
};

export default (props) => {
    let {
        purpose,
        currentDocument,

        backCancelBtnFn,
        gotoEditViewFn,
        gotoDeleteViewFn,
        gotoOverRideViewFn,

        createPOFn,
        deletePOFn,
        updatePOFn,
        statusChangeFn,
        overridePOFn,

        setIsLoading,
        setAlertErrorMsg,
    } = props;

    let { masterRawMaterialsList, masterSuppliersList } =
        useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);
    let { dataShare, setDataShare, defaultDataShare, serverDate } =
        useContext(DataShareContext);

    let [generalError, setGeneralError] = useState('');
    let [datalistDisplayResetCounter, setDatalistDisplayResetCounter] =
        useState(1);

    let [pdpoObj, setPDPOObj] = useState(defaultPDPO);
    let [rmUIDstate, setRmUID] = useState('');
    let [noteInState, setNoteInState] = useState('');
    let [pdpoDateInState, setPdpoDateInState] = useState(serverDate);

    let [preview, setPreview] = useState(false);
    let [confirmDeleteText, setConfirmDeleteText] = useState('');

    // set pdpoObj from currentDocument if purpose !== 'ADD'
    useEffect(() => {
        if (purpose !== 'ADD') {
            // making a copy so no side effect will change currentDocument.
            let currentDocumentCopy = {
                // will copy meta and metaHistory as is.
                ...currentDocument,
                // copy rmArr and not a reference.
                rmArr: JSON.parse(JSON.stringify(currentDocument.rmArr)),
            };
            setPdpoDateInState(currentDocument.pdpoDate);
            setNoteInState(currentDocumentCopy.note || '');
            setPDPOObj(currentDocumentCopy);
        }
    }, []);

    //! ==========================================================
    //? Code to handle creating PO from inventory RM.

    useEffect(() => {
        if (dataShare.isActive) {
            setRmUID(dataShare.data);
        }
    }, []);

    useEffect(() => {
        if (rmUIDstate && dataShare.isActive) {
            //! reset dataShare to default values
            setDataShare(defaultDataShare);

            addToRmArrFn();
        }
    }, [rmUIDstate]);
    //! ==========================================================

    const deleteErrors = (pdpoObj) => {
        delete pdpoObj.pdpoDateError;
        delete pdpoObj.noteError;

        let rmArrCopy = pdpoObj.rmArr;

        // clear all error messages embeded in the objects.
        rmArrCopy.forEach((rm) => {
            delete rm.chosenSupplierError;
            delete rm.rmOrderedQtyError;
            delete rm.rmOverrideQtyError;
            rm.canvasSupplierArr.forEach((supplier) => {
                delete supplier.costError;
                delete supplier.costDateError;
            });
        });
    };

    const cancelBtnFromEditViewFn = () => {
        // 1. reset values.
        setPDPOObj(currentDocument);

        // 2. reset errorMessages.
        setGeneralError('');

        // 3. go back to profile view.
        backCancelBtnFn();
    };

    // =====================================================

    const addToRmArrFn = async () => {
        let pdpoObjCopy = {
            ...pdpoObj,
            rmArr: clone(pdpoObj.rmArr),
        };

        deleteErrors(pdpoObjCopy);

        let rmObj = {
            // rmUIDstate in state already at this time.
            rmUID: rmUIDstate,
            rmOrderedQty: 0,
            rmBalanceQty: 0,
            rmOverrideQty: 0,
            chosenSupplier: '',
            canvasSupplierArr: [],
            rmUnit: '',
        };

        // comment1 =======================================

        setIsLoading(true);
        let response = await dbopsRawMaterials.getRawMatFn(rmUIDstate);
        if (!response.error) {
            // if no error, get the rmUnit.
            rmObj.rmUnit = response.data.rmUnit;
        }

        setIsLoading(false);

        // comment1 end ====================================

        pdpoObjCopy.rmArr.push(rmObj);

        setPDPOObj(pdpoObjCopy);
        setGeneralError('');
        setDatalistDisplayResetCounter(datalistDisplayResetCounter + 1);
    };

    const removeRMFn = (index) => {
        let pdpoObjCopy = {
            ...pdpoObj,
            rmArr: clone(pdpoObj.rmArr),
        };

        deleteErrors(pdpoObjCopy);

        pdpoObjCopy.rmArr.splice(index, 1);
        setPDPOObj(pdpoObjCopy);
    };

    //! get supplier document from firebase and add to SupArr in rmObjs
    const addToCanvasSupArrFn = async (supplierName, rmUID, rmIndex) => {
        let pdpoObjCopy = {
            ...pdpoObj,
            rmArr: clone(pdpoObj.rmArr),
        };

        let rmObj = { ...pdpoObj.rmArr[rmIndex] };
        let supplierObj = masterSuppliersList.filter(
            (sup) => sup.supplierUID === supplierName
        )[0];
        // just in case supplier got deleted (race condition) in the master list.
        if (!supplierObj) rmObj.getSupplierError = 'Error Getting Supplier.';
        else {
            // if no error. form default supplierObj.
            supplierObj = {
                supplierUID: supplierObj.supplierUID,
                contactNumber1: supplierObj.contactNumber1,
                contactPerson: supplierObj.contactPerson,
                chUID: `${rmUID} - ${supplierName}`,
                cost: 0,
                costDate: '',
                costHistoryArr: [
                    {
                        cost: 0,
                        costDate: '',
                    },
                ],
                tempPreviousCostDate: '',
            };
            // fetch cost history object for rm -  supplier combination.
            setIsLoading(true);
            let ch_response = await dbopsCostHistory.getCostHistoryDocFn(
                `${rmUID} - ${supplierName}`
            );
            if (ch_response.error) {
                // no handling of  expected error of
                // code 1000 (document does not exist).

                if (!ch_response.errorObj.code === 1000) {
                    // we want error that is code 1000 meaning the document does not
                    // exist. we can just continue with the execution of program.
                    // supplierObj already have the default values for cost history object.

                    //! conditional handles error that is something else.
                    //!  discontinue to stop creation of PO to prevent

                    // unexpected behavior.
                    rmObj.chosenSupplierError =
                        ch_response.errorObj.message || ch_response.message;
                    // update the rmArr State
                    pdpoObjCopy.rmArr[rmIndex] = rmObj;
                    setPDPOObj(pdpoObjCopy);
                    // spinner off.
                    setIsLoading(false);
                    return;
                }
            } else {
                // no error getting ch_history object.
                supplierObj = {
                    ...supplierObj,
                    ...ch_response.data,
                    tempPreviousCostDate: ch_response.data.costDate,
                };
            }

            // delete rmObj.chosenSupplierError
            rmObj.chosenSupplierError = '';
            // delete getSupplierError
            rmObj.getSupplierError = '';
            // put supplier document from firebase to rmObj.canvasSupplierArr
            rmObj.canvasSupplierArr.push(supplierObj);
        }

        pdpoObjCopy.rmArr[rmIndex] = rmObj;
        deleteErrors(pdpoObjCopy);
        setPDPOObj(pdpoObjCopy);

        setDatalistDisplayResetCounter(datalistDisplayResetCounter + 1);

        // spinner off.
        setIsLoading(false);
    };

    const removeFromCanvasSupArrFn = async (rmIndex, supIndex) => {
        let pdpoObjCopy = {
            ...pdpoObj,
            rmArr: clone(pdpoObj.rmArr),
        };

        deleteErrors(pdpoObjCopy);

        let rmObj = pdpoObjCopy.rmArr[rmIndex];
        rmObj.canvasSupplierArr.splice(supIndex, 1);
        setPDPOObj(pdpoObjCopy);
    };

    const updateRmObjProperty = (val, rmIndex, propertyName) => {
        let pdpoObjCopy = {
            ...pdpoObj,
            rmArr: clone(pdpoObj.rmArr),
        };
        let rmObj = {
            ...pdpoObjCopy.rmArr[rmIndex],
            [propertyName]: val,
        };
        pdpoObjCopy.rmArr[rmIndex] = rmObj;
        setPDPOObj(pdpoObjCopy);
    };

    const updateChosenSupplierFn = (val, rmIndex) =>
        updateRmObjProperty(val, rmIndex, 'chosenSupplier');

    const updateOrderQtyFn = (val, rmIndex) =>
        updateRmObjProperty(val, rmIndex, 'rmOrderedQty');

    const updateOverrideQtyFn = (val, rmIndex) =>
        updateRmObjProperty(val, rmIndex, 'rmOverrideQty');

    const updateSupplierArrFn = (val, propertyName, rmIndex, supplierIndex) => {
        let pdpoObjCopy = {
            ...pdpoObj,
            rmArr: clone(pdpoObj.rmArr),
        };
        pdpoObjCopy.rmArr[rmIndex].canvasSupplierArr[supplierIndex][
            propertyName
        ] = val;
        setPDPOObj(pdpoObjCopy);
    };

    // for saving functionality
    const previewPOBtnFn = () => {
        let pdpoObjCopy = {
            ...pdpoObj,
        };
        pdpoObjCopy.pdpoDate = pdpoDateInState;
        pdpoObjCopy.note = noteInState;

        let response = validate.pdpo(pdpoObjCopy);
        if (response.error) {
            setPDPOObj(response.errorObj.pdpoObj);
            setAlertErrorMsg(response.errorObj.hackError);
            setGeneralError(response.errorObj.generalError);
        } else {
            // no error
            setPDPOObj(response.data.pdpoObj);
            setPreview(true);
        }
    };

    const savePOFn = (purposeProp) => {
        let pdpoObjCopy = {
            ...pdpoObj,
        };
        pdpoObjCopy.pdpoDate = pdpoDateInState;
        pdpoObjCopy.note = noteInState;

        // check if there is a change in the payload from currentDocument
        if (
            (purpose === 'EDIT' || purpose === 'OVERRIDE') &&
            validate.noChange(pdpoObjCopy, currentDocument)
        ) {
            backCancelBtnFn();
            return;
        }

        let response = validate.pdpo(pdpoObjCopy, purposeProp);
        if (response.error) {
            setPDPOObj(response.errorObj.pdpoObj);
            setAlertErrorMsg(response.errorObj.hackError);
            setGeneralError(response.errorObj.generalError);
            return;
        }
        setPDPOObj(response.data.pdpoObj);

        let validPdpoObj = response.data.pdpoObj;
        let costHistArr = response.data.costHistArr;

        if (purpose === 'ADD') createPOFn(validPdpoObj, costHistArr);
        if (purpose === 'EDIT')
            updatePOFn(validPdpoObj, currentDocument, costHistArr);
        if (purpose === 'DELETE') deletePOFn(validPdpoObj, currentDocument);
        if (purpose === 'OVERRIDE') overridePOFn(validPdpoObj, currentDocument);
    };

    const statusChangeLocalFn = (userActionPassed, purposeProp) => {
        let pdpoObjCopy = {
            ...pdpoObj,
        };
        pdpoObjCopy.pdpoDate = pdpoDateInState;
        pdpoObjCopy.note = noteInState;

        // validate method forms the costHistory array so we need
        // to run the function to get the costHistory array that
        // statusChangeLocalFn need for APPROVED action.
        let response = validate.pdpo(pdpoObjCopy, purposeProp);
        if (response.error) {
            setPDPOObj(response.errorObj.pdpoObj);
            setGeneralError(response.errorObj.generalError);
            return;
        }
        setPDPOObj(response.data.pdpoObj);

        let validPdpoObj = response.data.pdpoObj;
        let costHistArr = response.data.costHistArr;

        statusChangeFn(
            userActionPassed,
            validPdpoObj,
            currentDocument,
            costHistArr
        );
    };

    // conditional render of contents  =============================

    let rmArrUI = [];
    let noteUI = null;
    let buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    let mainContent = null;
    let datalist = null;

    let forApprovalBorder = null;

    let addBtn = <AddButton disabled />;

    if ((purpose === 'ADD' && !preview) || purpose === 'EDIT') {
        let datalistOptions = masterRawMaterialsList.map((rm) => rm.rmUID);
        let rmAlreadyInTheUI = pdpoObj.rmArr.map((rm) => rm.rmUID);

        if (
            datalistOptions.includes(rmUIDstate) &&
            !rmAlreadyInTheUI.includes(rmUIDstate)
        )
            addBtn = <AddButton onClick={addToRmArrFn} />;

        let datalistLabel = (
            <span>
                Add Raw Material<HelpTip content={tooltips.pdpoRmArr}></HelpTip>
            </span>
        );

        datalist = (
            <div className='get-item-from-db-container'>
                <div className='datalist-with-addbtn-container'>
                    <Datalist
                        label={datalistLabel}
                        list={datalistOptions}
                        initialValue={''}
                        getInputValue={(val) => setRmUID(val)}
                        errorMessage={''}
                        resetCounter={datalistDisplayResetCounter}
                    ></Datalist>
                    {addBtn}
                </div>
            </div>
        );

        for (let index = 0; index < pdpoObj.rmArr.length; index += 1) {
            let trashIcon = (
                <i
                    className='ms-Icon ms-Icon--Delete clickable-icon'
                    aria-hidden='true'
                    onClick={() => removeRMFn(index)}
                ></i>
            );

            rmArrUI.push(
                <div className='span-2-columns-container' key={index}>
                    <AddRmFormInPo
                        trashIcon={trashIcon}
                        rmIndex={index}
                        rmObj={pdpoObj.rmArr[index]}
                        updateOrderQtyFn={updateOrderQtyFn}
                        updateSupplierArrFn={updateSupplierArrFn}
                        updateChosenSupplierFn={updateChosenSupplierFn}
                        addToCanvasSupArrFn={addToCanvasSupArrFn}
                        removeFromCanvasSupArrFn={removeFromCanvasSupArrFn}
                        datalistDisplayResetCounter={
                            datalistDisplayResetCounter
                        }
                    />
                </div>
            );
        }

        noteUI = (
            <div className='span-2-columns-container'>
                <TextArea
                    label={<span className='parent-labels'>Notes</span>}
                    maxLength={lengths.noteLength}
                    value={noteInState}
                    onChange={(e) => setNoteInState(e.target.value)}
                    errorMessage={pdpoObj.noteError}
                />
            </div>
        );

        //! Max number of rms in the list
        if (pdpoObj.rmArr.length >= lengths.pdpoRmArrLength) datalist = null;

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Purchase Order</p>
                    <p className='fpii-text'>
                        {pdpoObj.pdpoUID || '(Auto Generated)'}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Status</p>
                    <p className='fpii-text'>{pdpoObj.meta.action || '--'}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Supplier</p>
                    <p className='fpii-text'>{pdpoObj.supplierUID || '--'}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Date </p>
                    <Input
                        type='date'
                        onChange={(e) => setPdpoDateInState(e.target.value)}
                        value={pdpoDateInState}
                        errorMessage={pdpoObj.pdpoDateError}
                    ></Input>
                </div>

                {rmArrUI}

                {datalist}

                {noteUI}
            </section>
        );
    }

    if (
        purpose === 'OVERRIDE' ||
        (purpose === 'ADD' && preview) ||
        purpose === 'VIEW' ||
        purpose === 'DELETE'
    ) {
        let amountBox = <PdpoAmountBox rmArr={pdpoObj.rmArr} />;

        rmArrUI = pdpoObj.rmArr.map((rm, index) => {
            let suppliersCanvassedListUI = null;

            if (
                pdpoObj.meta.action === userActions.SENT_FOR_APPROVAL &&
                authUser.permissions.includes(permissions.PDPO_APPROVE)
            ) {
                suppliersCanvassedListUI = <SuppliersCanvassedList rm={rm} />;
                forApprovalBorder = <hr />;
            }

            let override = (
                <div className='width15'>
                    {' '}
                    <span className='mobile-label'>Override Qty:</span>{' '}
                    <span>{displayReadableNumber(rm.rmOverrideQty)}</span>
                </div>
            );
            if (purpose === 'OVERRIDE') {
                override = (
                    <div className='width15'>
                        <span className='mobile-label'>Override Qty:</span>{' '}
                        <div className='m-right-10'>
                            <InputDS
                                keyID={`${index} ${rm.rmUID}`}
                                label=''
                                type='number'
                                initialValue={rm.rmOverrideQty}
                                getInputValue={(val) =>
                                    updateOverrideQtyFn(val, index)
                                }
                                errorMessage={rm.rmOverrideQtyError}
                            ></InputDS>
                        </div>
                    </div>
                );
            }

            return (
                <div
                    className='span-2-columns-container m-bottom-10'
                    key={index}
                >
                    <div className='view-table-row-si'>
                        <div className='cell'>
                            <span className='mobile-label m-right-15'>
                                {`${index + 1}.`}
                            </span>
                            <span className='desktop-view m-lr-15'>
                                {`${index + 1}.`}
                            </span>{' '}
                            <span>{rm.rmUID}</span>
                        </div>
                        <div className='cell'>
                            <div className='width15'>
                                <span className='mobile-label'>
                                    Delivered Qty:
                                </span>{' '}
                                <span>
                                    {displayReadableNumber(rm.rmDeliveredQty)}
                                </span>
                            </div>
                            <div className='width15'>
                                {' '}
                                <span className='mobile-label'>
                                    Ordered Qty:
                                </span>{' '}
                                <span>
                                    {displayReadableNumber(rm.rmOrderedQty)}
                                </span>
                            </div>
                            <div className='width15'>
                                <span className='mobile-label'>
                                    Balance Qty:
                                </span>{' '}
                                <span>
                                    {displayReadableNumber(
                                        saveWithFiveDecimals(
                                            Number(rm.rmBalanceQty) +
                                                Number(rm.rmOverrideQty)
                                        )
                                    )}
                                </span>
                            </div>
                            {override}
                            <div className='width15'>
                                {' '}
                                <span className='mobile-label'>Unit:</span>{' '}
                                <span>{rm.rmUnit}</span>
                            </div>

                            <div className='width15'>
                                <span className='mobile-label'>
                                    Unit Price:
                                </span>{' '}
                                <span className=''>
                                    {displayMoney(rm.rmCost)}
                                </span>
                            </div>
                            <div className='width15'>
                                <span className='mobile-label'>
                                    Item Total:
                                </span>{' '}
                                <span className=''>
                                    {displayMoney(
                                        saveWithTwoDecimalOnly(
                                            (Number(rm.rmOverrideQty) +
                                                Number(rm.rmOrderedQty)) *
                                                Number(rm.rmCost)
                                        )
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>

                    {suppliersCanvassedListUI}
                    {forApprovalBorder}
                </div>
            );
        });

        noteUI = (
            <div className='span-2-columns-container'>
                <TextArea
                    label={<span className='parent-labels'>Notes</span>}
                    disabled
                    maxLength={lengths.longTextLength}
                    value={noteInState}
                />
            </div>
        );

        if (
            (pdpoObj.meta.action === userActions.SENT_FOR_APPROVAL ||
                purpose === 'OVERRIDE') &&
            authUser.permissions.includes(permissions.PDPO_APPROVE)
        ) {
            noteUI = (
                <div className='span-2-columns-container'>
                    <TextArea
                        label={<span className='parent-labels'>Notes</span>}
                        maxLength={lengths.noteLength}
                        value={noteInState}
                        onChange={(e) => setNoteInState(e.target.value)}
                        errorMessage={pdpoObj.noteError}
                    />
                </div>
            );
        }

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='parent-labels'>Purchase Order</p>
                    <p className=''>{pdpoObj.pdpoUID || `(Auto Generated)`}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='parent-labels'>Status</p>
                    <p className=''>
                        {(pdpoObj.meta && pdpoObj.meta.action) || '---'}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='parent-labels'>Supplier</p>
                    <p className=''>{pdpoObj.supplierUID || '---'}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='parent-labels'>Date</p>
                    <p className=''>
                        {pdpoObj.pdpoDate
                            ? displayDate(pdpoObj.pdpoDate)
                            : '---'}
                    </p>
                </div>

                <div className='span-2-columns-container label-padding label-highlight'>
                    <label className='parent-labels'>Raw Materials List</label>
                </div>

                <div className='span-2-columns-container'>
                    <div className='view-table-heading-si'>
                        <div className='cell'>
                            <span className='m-left-15'>RM Items</span>
                        </div>
                        <div className='cell'>
                            <span className='width20'>Delivered</span>
                            <span className='width20'>Ordered</span>
                            <span className='width20'>Balance</span>
                            <span className='width20'>Override Qty</span>
                            <span className='width20'>Unit</span>
                            <span className='width20'>Unit Price</span>
                            <span className='width20'>Item Total</span>
                        </div>
                    </div>
                    {rmArrUI}
                </div>

                {amountBox}

                {noteUI}
            </section>
        );
    }

    // =========================================================================
    // Button sections for users with PDPO Request permision ===================

    if (purpose === 'ADD') {
        buttonSection = (
            <CreateItemButtons
                createText='Create PO'
                previewText='Preview PO'
                cancelPreviewFn={() => setPreview(false)}
                cancelFn={backCancelBtnFn}
                createFn={savePOFn}
                previewFn={previewPOBtnFn}
                isPreview={preview}
            />
        );
    }

    if (purpose === 'EDIT') {
        buttonSection = (
            <UpdateItemButtons
                saveFn={savePOFn}
                cancelFn={cancelBtnFromEditViewFn}
            />
        );
    }

    if (purpose === 'DELETE') {
        buttonSection = (
            <DeleteItemButtons
                cancelFn={backCancelBtnFn}
                deleteFn={savePOFn}
                confirmeDeleteFn={(e) => setConfirmDeleteText(e.target.value)}
                confirmDeleteTextValue={confirmDeleteText}
                confirmDeleteTextPlaceHolder='Enter PO Number'
                uidValue={pdpoObj.pdpoUID}
            />
        );
    }

    // ==================================================================
    // Button section for users with PDPO Request permission.
    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.PDPO_REQUEST) &&
        !authUser.permissions.includes(permissions.PDPO_APPROVE)
    ) {
        if (
            pdpoObj.meta.action === userActions.CREATED ||
            pdpoObj.meta.action === userActions.UPDATED ||
            pdpoObj.meta.action === userActions.REJECTED
        ) {
            buttonSection = (
                <BackSendEditDelButtons
                    backBtnFn={backCancelBtnFn}
                    sendForApprovalBtnFn={() =>
                        statusChangeLocalFn(userActions.SENT_FOR_APPROVAL)
                    }
                    editBtnFn={gotoEditViewFn}
                    deleteBtnFn={gotoDeleteViewFn}
                />
            );
        }

        // if document status is APPROVED
        if (pdpoObj.meta.action === userActions.APPROVED) {
            buttonSection = (
                <BackWithMarkAsButtons
                    backBtnFn={backCancelBtnFn}
                    markAsBtnFn={() =>
                        statusChangeLocalFn(userActions.SENT_TO_SUPPLIER)
                    }
                    markAsBtnText='Mark as Sent to Supplier'
                />
            );
        }
    }

    // =========================================================================
    // Button section for users with PDPO Approve permision ===================

    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.PDPO_APPROVE) &&
        !authUser.permissions.includes(permissions.PDPO_REQUEST)
    ) {
        // if document status is SENT_FOR_APPROVAL
        if (pdpoObj.meta.action === userActions.SENT_FOR_APPROVAL) {
            buttonSection = (
                <ApproveRejectButtons
                    backBtnFn={backCancelBtnFn}
                    approveBtnFn={() =>
                        statusChangeLocalFn(userActions.APPROVED)
                    }
                    rejectBtnFn={() =>
                        statusChangeLocalFn(userActions.REJECTED)
                    }
                />
            );
        }

        if (
            pdpoObj.meta.action === userActions.SENT_TO_SUPPLIER ||
            pdpoObj.meta.action === userActions.OVERRIDDEN ||
            pdpoObj.meta.action === systemActions.FULFILLED
        ) {
            buttonSection = (
                <OverrideWithBackButtons
                    backBtnFn={backCancelBtnFn}
                    overrideBtnFn={gotoOverRideViewFn}
                />
            );
        }
    }

    // =========================================================================
    //! button section for users with both PDPO Request and Approve permissions.
    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.PDPO_APPROVE) &&
        authUser.permissions.includes(permissions.PDPO_REQUEST)
    ) {
        if (
            pdpoObj.meta.action === userActions.CREATED ||
            pdpoObj.meta.action === userActions.UPDATED ||
            pdpoObj.meta.action === userActions.REJECTED
        ) {
            buttonSection = (
                <BackSendEditDelButtons
                    backBtnFn={backCancelBtnFn}
                    sendForApprovalBtnFn={() =>
                        statusChangeLocalFn(userActions.SENT_FOR_APPROVAL)
                    }
                    editBtnFn={gotoEditViewFn}
                    deleteBtnFn={gotoDeleteViewFn}
                />
            );
        }

        // if doucment status is SENT_TO_SUPPLIER
        if (
            pdpoObj.meta.action === userActions.SENT_TO_SUPPLIER ||
            pdpoObj.meta.action === userActions.OVERRIDDEN ||
            pdpoObj.meta.action === systemActions.FULFILLED
        ) {
            buttonSection = (
                <OverrideWithBackButtons
                    backBtnFn={backCancelBtnFn}
                    overrideBtnFn={gotoOverRideViewFn}
                />
            );
        }

        // if document status is SENT_FOR_APPROVAL
        if (pdpoObj.meta.action === userActions.SENT_FOR_APPROVAL) {
            buttonSection = (
                <ApproveRejectButtons
                    backBtnFn={backCancelBtnFn}
                    approveBtnFn={() =>
                        statusChangeLocalFn(userActions.APPROVED)
                    }
                    rejectBtnFn={() =>
                        statusChangeLocalFn(userActions.REJECTED)
                    }
                />
            );
        }

        // if document status is APPROVED
        if (pdpoObj.meta.action === userActions.APPROVED) {
            buttonSection = (
                <BackWithMarkAsButtons
                    backBtnFn={backCancelBtnFn}
                    markAsBtnFn={() =>
                        statusChangeLocalFn(userActions.SENT_TO_SUPPLIER)
                    }
                    markAsBtnText='Mark as Sent to Supplier'
                />
            );
        }
    }

    if (
        purpose === 'OVERRIDE' &&
        authUser.permissions.includes(permissions.PDPO_APPROVE)
    ) {
        buttonSection = (
            <OverrideSaveButtons
                cancelFn={cancelBtnFromEditViewFn}
                saveFn={() => savePOFn(purpose)}
            />
        );
    }

    if (!authUser.subscriptionStatusIsActive) {
        buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    }

    let generalErrorUI = (
        <div className='general-error-message'>{generalError}</div>
    );

    return (
        <div className='common-form'>
            <section className='form-title-section'>
                Purchase Order Form
            </section>

            {mainContent}

            {buttonSection}
            {generalErrorUI}

            <Watermark purpose={purpose} currentDocument={currentDocument} />
        </div>
    );
};
