import React, { useState, useEffect } from 'react';
import { Pagination } from '../../components';
import { buildPages } from '../../lib/util';
import './Input.css';

export default React.forwardRef((props, ref) => {
    let {
        keyID,
        id,
        label,
        disabled,
        className,
        maxLength,
        minLength,
        required,
        placeholder,
        errorMessage,
        type,
        autoFocus,
        list,
        getInputValue,
        initialValue,
        resetCounter,

        mobileLabel,
        desktopLabel,
    } = props;

    let [val, setVal] = useState('');

    let [showDatalist, setShowDatalist] = useState(false);
    let [listToRender, setListToRender] = useState([]);

    const passValueToParent = (value) => getInputValue(value);

    const onKeyDownFn = (e) => {
        if (e.keyCode === 13 || e.keyCode === 9) {
            e.target.blur();
            passValueToParent(val);
            setShowDatalist(false);
        }
    };
    const optionClickFn = () => {
        passValueToParent(val);
        setShowDatalist(false);
    };

    useEffect(() => {
        setVal(initialValue);
    }, [initialValue, resetCounter]);

    //! boilerplate for pagination in datalist
    //! =========================================================
    //! PAGINATION state and functions

    //! State
    const [pagesArr, setPagesArr] = useState([]);
    const [page, setPage] = useState(0);

    //! Initialize pagesArr with useEffect
    useEffect(() => {
        let filteredList = list.filter((sup) => {
            return sup.toUpperCase().includes(val.toUpperCase());
        });

        setListToRender(filteredList);
        setPagesArr(buildPages(filteredList.length, 50));
        setPage(0);
    }, [val]);

    //! functions for pagination
    const gotoFirstPageFn = () => {
        setPage(0);
        setShowDatalist(true);
    };

    const gotoPreviousPageFn = () => {
        let prevPage = page - 1 < 0 ? 0 : page - 1;
        setPage(prevPage);
    };

    const gotoNextPageFn = () => {
        let nextPage =
            page + 1 > pagesArr.length - 1 ? pagesArr.length - 1 : page + 1;
        setPage(nextPage);
    };

    const gotoLastPageFn = () => setPage(pagesArr.length - 1);

    //! array for pagination
    let finalListToBeRendered = [];
    if (Array.isArray(pagesArr[page])) {
        finalListToBeRendered = listToRender.slice(
            pagesArr[page][0],
            pagesArr[page][1]
        );
    }

    //! end of pagination section
    //! ===============================================================

    //! pagination buttons =====================================
    let paginationButtons = null;
    if (pagesArr.length > 1) {
        let info = `Page ${page + 1} of ${pagesArr.length}`;
        paginationButtons = (
            <Pagination
                gotoFirstPageFn={gotoFirstPageFn}
                gotoPreviousPageFn={gotoPreviousPageFn}
                gotoNextPageFn={gotoNextPageFn}
                gotoLastPageFn={gotoLastPageFn}
                paginationInfo={info}
            ></Pagination>
        );
    }
    //! ==========================================================

    // set options inside datalist
    let options = finalListToBeRendered.map((optionName) => {
        return (
            <div
                key={optionName}
                className='option'
                onClick={() => {
                    setVal(optionName);
                    passValueToParent(optionName);
                    setShowDatalist(false);
                }}
            >
                {optionName}
            </div>
        );
    });

    let datalist = null;

    let noMatchError =
        listToRender.length === 0 ? (
            <div className='no-match-found'>No Match Found</div>
        ) : null;

    // if (!matchFound && showDatalist) {
    if (showDatalist) {
        datalist = (
            <>
                <div className='cover' onClick={optionClickFn}></div>
                <div className='datalist'>
                    {options}
                    {paginationButtons}
                    {noMatchError}
                </div>
            </>
        );
    }

    // feature added June-13-2020 for label to show on desktop view and mobile view only.
    // original cannot be deleted because app will break in so many places.
    // Solution is to add 2 more labels specific for desktop and mobile view.
    let originalLabelUI = (
        <label className='input-label' htmlFor={id}>
            {label}
        </label>
    );
    let desktopLabelUI = (
        <label className='desktop-view input-label' htmlFor={id}>
            {desktopLabel}
        </label>
    );
    let mobileLabelUI = (
        <label className='mobile-view input-label' htmlFor={id}>
            {mobileLabel}
        </label>
    );

    if (desktopLabel === undefined || desktopLabel === '')
        desktopLabelUI = null;
    if (mobileLabel === undefined || mobileLabel === '') mobileLabelUI = null;

    return (
        <div className='input-wrapper' key={keyID}>
            {originalLabelUI}
            {desktopLabelUI}
            {mobileLabelUI}
            <div className='input-and-datalist-container'>
                <input
                    ref={ref}
                    disabled={disabled}
                    type={type}
                    id={id}
                    className={className}
                    required={required}
                    onChange={(e) => setVal(e.target.value)}
                    placeholder={placeholder}
                    value={val}
                    maxLength={maxLength}
                    minLength={minLength}
                    autoFocus={autoFocus}
                    onKeyDown={onKeyDownFn}
                    onFocus={() => setShowDatalist(true)}
                    // onBlur={() => passValueToParent(val)}
                ></input>
                <span className='input-error'>{errorMessage}</span>
                {datalist}
            </div>
        </div>
    );
});
