import React, { useState, useContext, useEffect } from 'react';
import { dbopsProdOrders } from '../../firebase_dbops';
import {
    MasterListContext,
    AuthContext,
    DataShareContext,
} from '../../contexts';
import { InputIcon, ListView2 } from '../../components';
import OperationItem from '../../components/CollectionItems/OperationItem/OperationItem';
import { displayDate } from '../../lib/util';

import { permissions, userActions, lengths } from '../../lib/constants';
import '../PagesCommon.css';
import './ProdOrdersPage.css';

import ProdOrdersForm from './ProdOrdersForm';

const view = {
    LIST_VIEW: 'listView',
    CREATE_PRODUCTION: 'addProduction',
    EDIT_PRODUCTION: 'editProduction',
    DELETE_PRODUCTION: 'deleteProduction',
    PRODUCTION_PROFILE: 'productionOrdersProfile',
    OVERRIDE_PRODUCTION_ORDER: 'overrideProduction',
};

export default (props) => {
    let { setIsLoading, setAlertErrorMsg, setAlertMessage } = props;

    let { masterProdOrdersList } = useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);
    let { dataShare } = useContext(DataShareContext);

    let [formView, setFormView] = useState(view.LIST_VIEW);
    let [currentDocument, setCurrentDocument] = useState('');
    let [searchText, setSearchText] = useState('');

    //! ==========================================================
    //? Code to handle creating PO from inventory SFG.
    useEffect(() => {
        if (dataShare.isActive) {
            setFormView(view.CREATE_PRODUCTION);
        }
    }, []);
    //! ==========================================================

    // functions to change views.
    const addProductionFn = () => setFormView(view.CREATE_PRODUCTION);
    const gotoListViewFn = () => setFormView(view.LIST_VIEW);
    const gotoEditViewFn = () => setFormView(view.EDIT_PRODUCTION);
    const gotoDeleteViewFn = () => setFormView(view.DELETE_PRODUCTION);
    const gotoOverrideViewFn = () =>
        setFormView(view.OVERRIDE_PRODUCTION_ORDER);
    const gotoProductionProfileViewFn = () =>
        setFormView(view.PRODUCTION_PROFILE);

    // function to get production document.
    const getDocumentFromDBFn = async (docID) => {
        setIsLoading(true);
        let response = await dbopsProdOrders.getProdOrderFn(docID);
        if (response.error)
            setAlertErrorMsg(response.errorObj.message || response.message);
        else {
            setCurrentDocument(response.data);
            gotoProductionProfileViewFn();
        }
        setIsLoading(false);
    };

    // function to create Supplier
    const createProdOrderFn = async (productionObj) => {
        // spinner on.
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        // write to db.
        let response = await dbopsProdOrders.createProdOrderFn(
            userCreds,
            productionObj
        );
        if (response.error) setAlertErrorMsg(response.errorObj.message);
        else setAlertMessage(response.message);

        gotoListViewFn();

        // spinner off.
        setIsLoading(false);
    };

    // function to update production.
    const updateProdOrdersFn = async (
        productionObj,
        documentBasis,
        userActionPassed
    ) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsProdOrders.updateProdOrdersFn(
            userCreds,
            productionObj,
            documentBasis,
            userActionPassed
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);
            // if error is because document has already been deleted,
            // go back to list view.
            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
            // if error is because document has since changed, just continue
            // flow of the program.
        } else setAlertMessage(response.message);

        // this get document ensures state in FE state (currentDocument) is updated.
        gotoListViewFn();
        await getDocumentFromDBFn(productionObj.productionUID);

        setIsLoading(false);
    };

    // function to delete production.
    const deleteProdOrderFn = async (productionObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsProdOrders.deleteProdOrderFn(
            userCreds,
            productionObj,
            documentBasis
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);

            // if error is because document has changed before changes
            // could be applied, get latest version of production and
            // go back to production profile view.
            if (response.errorObj.code === 1001) {
                gotoListViewFn();
                await getDocumentFromDBFn(productionObj.productionUID);
                setIsLoading(false); // redundant
                return;
            }
            // all other errors can continue flow of program.
        } else setAlertMessage(response.message);

        gotoListViewFn();

        setIsLoading(false);
    };

    // function for status change of document.
    const statusChangeFn = async (
        userActionPassed,
        productionObj,
        documentBasis
    ) => {
        setIsLoading(true);

        let userCreds = {
            action: userActionPassed,
            uid: authUser.uid,
            email: authUser.email,
        };

        let dbFn = dbopsProdOrders.statusChangeFn;
        if (userActionPassed === userActions.APPROVED) {
            dbFn = dbopsProdOrders.approveProductionFn;
        }

        let response = await dbFn(userCreds, productionObj, documentBasis);
        if (response.error) {
            // meaning: response.data contains detailed error obj.
            if (response.data) setAlertErrorMsg(response.data);
            else setAlertErrorMsg(response.errorObj.message);

            // if error is because document has already been deleted,
            // go back to list view.
            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
            // if error is because document has since changed, just continue
            // flow of the program.
        } else setAlertMessage(response.message);

        // this get document ensures state in FE state (currentDocument) is updated.
        gotoListViewFn();
        await getDocumentFromDBFn(productionObj.productionUID);

        setIsLoading(false);
    };

    const overrideProductionFn = async (productionObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsProdOrders.overrideProductionFn(
            userCreds,
            productionObj,
            documentBasis
        );

        if (response.error) {
            // meaning: response.data contains detailed error obj.
            if (response.data) setAlertErrorMsg(response.data);
            else setAlertErrorMsg(response.errorObj.message);

            // if error is because document has already been deleted,
            // go back to list view.
            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
            // if error is because document has since changed, just continue
            // flow of the program.
        } else setAlertMessage(response.message);

        // this get document ensures state in FE state (currentDocument) is updated.
        gotoListViewFn();
        await getDocumentFromDBFn(productionObj.productionUID);

        setIsLoading(false);
    };

    // filter function =====================================
    let filteredProductionList = masterProdOrdersList.filter((production) => {
        return (
            production.productionUID
                .toUpperCase()
                .includes(searchText.toUpperCase()) ||
            displayDate(production.prodOrderDate)
                .toUpperCase()
                .includes(searchText.toUpperCase()) ||
            production.status
                .toUpperCase()
                .includes(searchText.toUpperCase()) ||
            production.productionDescription
                .toUpperCase()
                .includes(searchText.toUpperCase())
        );
    });

    let addProductionBtnUI = (
        <button className='btn btn-primary' onClick={addProductionFn}>
            Create SFG Job Order
        </button>
    );
    if (
        !authUser.permissions.includes(permissions.PROD_ORDER_REQUEST) ||
        masterProdOrdersList.length >= lengths.masterPOSDeptsLimits ||
        !authUser.subscriptionStatusIsActive
    ) {
        addProductionBtnUI = null;
    }

    let topSectionContent = (
        <section className='top-section'>
            <InputIcon
                id='suppliers-search-input'
                type='text'
                placeholder='Search'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                icon={
                    <i
                        className='ms-Icon ms-Icon--Search'
                        aria-hidden='true'></i>
                }
            />
            {addProductionBtnUI}
        </section>
    );

    let mainSectionContent = (
        <ListView2
            getDocumentFromDBFn={getDocumentFromDBFn}
            list={filteredProductionList}
            keyProperty={'productionUID'}
            CollectionItemComponent={OperationItem}
        />
    );

    if (formView !== view.LIST_VIEW) topSectionContent = null;

    if (formView === view.CREATE_PRODUCTION) {
        mainSectionContent = (
            <ProdOrdersForm
                purpose='ADD'
                backCancelBtnFn={gotoListViewFn}
                createProdOrderFn={createProdOrderFn}
                setIsLoading={setIsLoading}
                setAlertErrorMsg={setAlertErrorMsg}
            />
        );
    }

    if (formView === view.EDIT_PRODUCTION) {
        mainSectionContent = (
            <ProdOrdersForm
                purpose='EDIT'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoProductionProfileViewFn}
                updateProdOrdersFn={updateProdOrdersFn}
                setAlertErrorMsg={setAlertErrorMsg}
                setIsLoading={setIsLoading}
            />
        );
    }

    if (formView === view.DELETE_PRODUCTION) {
        mainSectionContent = (
            <ProdOrdersForm
                purpose='DELETE'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoProductionProfileViewFn}
                deleteProdOrderFn={deleteProdOrderFn}
                setAlertErrorMsg={setAlertErrorMsg}
                setIsLoading={setIsLoading}
            />
        );
    }

    if (formView === view.PRODUCTION_PROFILE) {
        mainSectionContent = (
            <ProdOrdersForm
                purpose='VIEW'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoListViewFn}
                gotoEditViewFn={gotoEditViewFn}
                gotoDeleteViewFn={gotoDeleteViewFn}
                gotoOverrideViewFn={gotoOverrideViewFn}
                statusChangeFn={statusChangeFn}
                setIsLoading={setIsLoading}
                setAlertErrorMsg={setAlertErrorMsg}
            />
        );
    }

    if (formView === view.OVERRIDE_PRODUCTION_ORDER) {
        mainSectionContent = (
            <ProdOrdersForm
                purpose='OVERRIDE'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoProductionProfileViewFn}
                overrideProductionFn={overrideProductionFn}
                setIsLoading={setIsLoading}
                setAlertErrorMsg={setAlertErrorMsg}
            />
        );
    }

    return (
        <div className='content-layout'>
            {topSectionContent}

            <section className='main-section'>{mainSectionContent}</section>
        </div>
    );
};
