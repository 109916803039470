import React, { useState, useEffect, useContext } from 'react';
import {
    Input,
    InputDS,
    Datalist,
    AddButton,
    UpdateItemButtons,
    SetupViewButtons,
    BackOnlyButton,
    HelpTip,
    WarningTip,
    Watermark,
} from '../../components';
import tooltips from '../../lib/tooltips';

import { AuthContext, MasterListContext } from '../../contexts';
import { permissions, lengths } from '../../lib/constants';
import { displayMoney, displayReadableNumber } from '../../lib/util';

import validate from '../../validators';

import '../PagesCommon.css';
import './SemiFGs.css';

const defaultRMObj = {
    rmUID: '',
    rmRequiredQty: '',
    rmUnit: '', // feature added 5/5/2020. just add value in validation function.
    group: '',
    groupErrorMessage: '',
    errorMessage: '',
    qtyErrorMessage: '',
};

export default (props) => {
    let {
        purpose,
        currentDocument,

        backCancelBtnFn,
        gotoEditViewFn,
        gotoDeleteViewFn,

        createSFGFn,
        deleteSFGFn,
        updateSFGFn,
    } = props;

    let { masterRawMaterialsList } = useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);

    let [sfgName, setSFGName] = useState('');
    let [sfgCode, setSFGCode] = useState('');
    let [sfgUnit, setSFGUnit] = useState('');
    let [sfgLowThreshold, setSFGLowThreshold] = useState('');
    // client request of pricelist 1-5.
    // sfgPrice would be price list 1.
    let [sfgPrice, setSFGPrice] = useState(0);
    let [sfgPrice2, setSFGPrice2] = useState(0);
    let [sfgPrice3, setSFGPrice3] = useState(0);
    let [sfgPrice4, setSFGPrice4] = useState(0);
    let [sfgPrice5, setSFGPrice5] = useState(0);

    let [rmArr, setRMArr] = useState([]);
    let [confirmDeleteText, setConfirmDeleteText] = useState('');
    let [sfgActualQty, setSfgActualQty] = useState('');

    let [rmUnitMap, setRmUnitMap] = useState({});

    let [sfgNameErr, setSFGNameErr] = useState('');
    let [sfgCodeErr, setSFGCodeErr] = useState('');
    let [sfgUnitErr, setSFGUnitErr] = useState('');
    let [sfgLowThresholdErr, setSFGLowThresholdErr] = useState('');
    let [arraysErr, setArraysErr] = useState('');
    let [sfgActualQtyErr, setSfgActualQtyErr] = useState('');

    let [sfgPriceErr, setSFGPriceErr] = useState('');
    let [sfgPriceErr2, setSFGPriceErr2] = useState('');
    let [sfgPriceErr3, setSFGPriceErr3] = useState('');
    let [sfgPriceErr4, setSFGPriceErr4] = useState('');
    let [sfgPriceErr5, setSFGPriceErr5] = useState('');

    useEffect(() => {
        if (purpose !== 'ADD') {
            setSFGName(currentDocument.sfgName);
            setSFGCode(currentDocument.sfgCode);
            setSFGUnit(currentDocument.sfgUnit);
            setSFGLowThreshold(currentDocument.sfgLowThreshold);
            setRMArr(currentDocument.rmArr);
            setSFGPrice(currentDocument.sfgPrice);
            setSFGPrice2(currentDocument.sfgPrice2 || 0);
            setSFGPrice3(currentDocument.sfgPrice3 || 0);
            setSFGPrice4(currentDocument.sfgPrice4 || 0);
            setSFGPrice5(currentDocument.sfgPrice5 || 0);
            setSfgActualQty(currentDocument.sfgActualQty);
        }
    }, []);

    // create a unit map for rms once only.
    useEffect(() => {
        let unitMap = {};
        masterRawMaterialsList.forEach((rm) => {
            unitMap[rm.rmUID] = rm.rmUnit;
        });

        setRmUnitMap(unitMap);
    }, [masterRawMaterialsList]);

    // =====================================================
    /*
        1. From profile view, when you press edit, it goes to edit view.
        2. If you change something but dont save it, then go back to profile view,
            the changes stays on UI even if actual values are not changed in DB.
        3. Must reset values of (RM/Customer/supplier/sfg Form) before going back to 
            profile view.
    */

    const deleteErrors = () => {
        setSFGNameErr('');
        setSFGCodeErr('');
        setSFGUnitErr('');
        setSFGLowThresholdErr('');
        setSFGPriceErr('');
        setSFGPriceErr2('');
        setSFGPriceErr3('');
        setSFGPriceErr4('');
        setSFGPriceErr5('');
        setArraysErr('');
        setSfgActualQtyErr('');
    };

    const cancelBtnFromEditViewFn = () => {
        // 1. reset values.
        setSFGName(currentDocument.sfgName);
        setSFGCode(currentDocument.sfgCode);
        setSFGUnit(currentDocument.sfgUnit);
        setSFGLowThreshold(currentDocument.sfgLowThreshold);
        setSFGPrice(currentDocument.sfgPrice);
        setSFGPrice2(currentDocument.sfgPrice2);
        setSFGPrice3(currentDocument.sfgPrice3);
        setSFGPrice4(currentDocument.sfgPrice4);
        setSFGPrice5(currentDocument.sfgPrice5);
        setRMArr(currentDocument.rmArr);
        setSfgActualQty(currentDocument.sfgActualQty);

        // 2. reset errorMessages.
        deleteErrors();

        // 3. go back to profile view.
        backCancelBtnFn();
    };

    // =====================================================

    const updateRMArrFn = (index, propertyName, value) => {
        let rawMatArr = [...rmArr];
        let rmObj = {
            ...rawMatArr[index],
            [propertyName]: value,
        };

        //! get rmUnit
        if (propertyName === 'rmUID') {
            rmObj.rmUnit = rmUnitMap[value];
        }

        //! =========================

        rawMatArr[index] = rmObj;
        setRMArr(rawMatArr);
    };

    const addRMInputFn = () => {
        deleteErrors();
        let rawMatArr = [...rmArr];
        rawMatArr.push(defaultRMObj);
        rawMatArr.forEach((rm) => {
            delete rm.errorMessage;
            delete rm.qtyErrorMessage;
            delete rm.groupErrorMessage;
        });
        setRMArr(rawMatArr);
    };

    const removeRMFn = (index) => {
        deleteErrors();

        let newRMArr = [...rmArr];
        newRMArr.splice(index, 1);
        newRMArr.forEach((rm) => {
            delete rm.errorMessage;
            delete rm.qtyErrorMessage;
            delete rm.groupErrorMessage;
        });
        setRMArr(newRMArr);
    };

    const saveBtnFn = () => {
        let sfgObj = {
            // ensure that meta and metaHistory properties will be added in payload.
            ...currentDocument,
            // overwrite other properties
            sfgName: sfgName.trim(),
            sfgCode: sfgCode.trim(),
            sfgUnit: sfgUnit.trim(),

            //! do not trim as type numbers during edit will throw an error.
            sfgLowThreshold,
            sfgActualQty,
            sfgPrice,
            sfgPrice2,
            sfgPrice3,
            sfgPrice4,
            sfgPrice5,

            // cannnot trim. rmArr is an Array,
            rmArr, // needs to be cleaned up, remove errorMessages before sending to parent element.
        };

        // check if there is a change in the payload from currentDocument
        if (purpose === 'EDIT' && validate.noChange(sfgObj, currentDocument)) {
            backCancelBtnFn();
            return;
        }

        // validate sfgObj
        let rmUIDsArr = masterRawMaterialsList.map((item) => item.rmUID);

        // added feature 5-5-2020, include masterlistRM to get rmUnit of each rm chosen.
        let response = validate.semiFG(
            sfgObj,
            rmUIDsArr,
            purpose,
            masterRawMaterialsList,
            currentDocument
        );

        // if response.error... must return so not to call dbops functions.
        if (response.error) {
            let e = response.errorObj;
            setSFGNameErr(e.sfgNameErr);
            setSFGUnitErr(e.sfgUnitErr);
            setSFGCodeErr(e.sfgCodeErr);
            setSFGLowThresholdErr(e.sfgLowThresholdErr);
            setArraysErr(e.arraysErr);
            setRMArr(e.rawMatArr);
            setSFGPriceErr(e.sfgPriceErr);
            setSFGPriceErr2(e.sfgPriceErr2);
            setSFGPriceErr3(e.sfgPriceErr3);
            setSFGPriceErr4(e.sfgPriceErr4);
            setSFGPriceErr5(e.sfgPriceErr5);
            setSfgActualQtyErr(e.sfgActualQtyErr);

            return;
        }

        let fullSchemaSFGObj = response.data;

        if (purpose === 'ADD') createSFGFn(fullSchemaSFGObj);
        if (purpose === 'EDIT') updateSFGFn(fullSchemaSFGObj, currentDocument);
        if (purpose === 'DELETE')
            deleteSFGFn(fullSchemaSFGObj, currentDocument);
    };

    // conditional render of contents  =============================
    let rmArrUI = [];
    let showAddBtn = true;
    let buttonSection = null;
    let mainContent = null;
    let disableChangingItems = false;

    let uidInput = (
        <Input
            label='SFG Name'
            maxLength={lengths.uid}
            value={sfgName}
            onChange={(e) => setSFGName(e.target.value)}
            placeholder='Required'
            errorMessage={sfgNameErr}
        />
    );
    if (purpose === 'EDIT') {
        uidInput = (
            <Input
                label='SFG Name'
                value={sfgName}
                errorMessage={sfgNameErr}
                disabled
            />
        );
    }

    if (purpose === 'ADD' || purpose === 'EDIT') {
        // datalist will be capped at 100 options element and will be dynamically
        // updated like the search/filter function.
        let datalistOptions = masterRawMaterialsList.map((rm) => rm.rmUID);

        //! bug fix. 2021-03-18. changing rm list while there are existing orders for sfg produces error in calculating inventory.
        if (purpose === 'EDIT') {
            if (
                currentDocument &&
                (currentDocument.sdpoNumbersArr.length > 0 ||
                    currentDocument.prodOrderNumbersArr.length > 0 ||
                    currentDocument.assemblyOrderNumbersArr.length > 0)
            ) {
                disableChangingItems = true;
                // showAddBtn = false;
            }
        }
        //! ========================

        //! this will disable the feature of locking the rm list when there are existing
        //! orders for this SFG.  Comment this out if feature is needed.
        disableChangingItems = false;
        //! =====================================================================

        let waringTipForEditingItems = (
            <span className='m-left-15'>
                <WarningTip content={tooltips.disableEditingItems} />
            </span>
        );

        for (let index = 0; index < rmArr.length; index += 1) {
            let initialValue = rmArr[index] ? rmArr[index].rmUID : '';
            let initialQty = rmArr[index] ? rmArr[index].rmRequiredQty : '';
            let initialGrp = rmArr[index] ? rmArr[index].group : '';

            let trashIcon = (
                <i
                    className='ms-Icon ms-Icon--Delete'
                    aria-hidden='true'
                    onClick={() => removeRMFn(index)}></i>
            );

            if (disableChangingItems) trashIcon = null;

            rmArrUI.push(
                <div className='sfg-datalist-container' key={index}>
                    <div className='flex-row'>
                        <span className='desktop-view m-right-10'>
                            {index + 1}.{' '}
                        </span>
                        <Datalist
                            disabled={disableChangingItems}
                            list={datalistOptions}
                            initialValue={initialValue}
                            mobileLabel={`Raw Material ${index + 1}`}
                            getInputValue={
                                disableChangingItems
                                    ? () => {}
                                    : (val) =>
                                          updateRMArrFn(index, 'rmUID', val)
                            }
                            placeholder='Required'
                            errorMessage={rmArr[index].errorMessage}></Datalist>
                    </div>

                    <div className='required-qty-input'>
                        <div className='flex-row width66'>
                            <div className='width50'>
                                <InputDS
                                    disabled={disableChangingItems}
                                    mobileLabel='Required Qty'
                                    type='number'
                                    initialValue={initialQty}
                                    getInputValue={
                                        disableChangingItems
                                            ? () => {}
                                            : (val) =>
                                                  updateRMArrFn(
                                                      index,
                                                      'rmRequiredQty',
                                                      val
                                                  )
                                    }
                                    placeholder='Required'
                                    errorMessage={rmArr[index].qtyErrorMessage}
                                />
                            </div>
                            <div className='width50 m-left-5 pad-top-10'>
                                {rmArr[index].rmUnit}
                            </div>
                        </div>

                        <div className='width33'>
                            <InputDS
                                disabled={disableChangingItems}
                                mobileLabel='Group'
                                type='number'
                                maxLength={2}
                                initialValue={initialGrp}
                                getInputValue={
                                    disableChangingItems
                                        ? () => {}
                                        : (val) =>
                                              updateRMArrFn(index, 'group', val)
                                }
                                placeholder='Required'
                                errorMessage={rmArr[index].groupErrorMessage}
                            />
                        </div>

                        {trashIcon}
                    </div>
                </div>
            );
        }

        rmArr.forEach((rm) => {
            if (!datalistOptions.includes(rm.rmUID) || rm.rmUID === '')
                showAddBtn = false;
        });

        if (rmArr.length >= lengths.sfgSetupRmArrLength) showAddBtn = false;

        let addRawMatButton = showAddBtn ? (
            <AddButton onClick={addRMInputFn} />
        ) : null;

        let rmHeading = (
            <div className='span-2-columns-container'>
                <div className='view-table-heading m-zero'>
                    <div className='cell'>
                        <span className='m-left-15'>RM Type - Description</span>
                    </div>

                    <div className='cell'>
                        <span className='width33 m-right-10'>
                            Required Qty
                            <HelpTip content={tooltips.sfgSetupRmRequiredQty} />
                        </span>

                        <span className='width33 m-right-10'>Unit</span>

                        <span className='width33'>
                            Group <HelpTip content={tooltips.sfgSetupRmGroup} />
                        </span>
                        <span style={{ width: '2.8rem' }}></span>
                    </div>
                </div>
            </div>
        );

        if (rmArrUI.length === 0) rmHeading = null;

        // =================================================================
        // sfgActualQty entry. during setup, requester and approver can input
        // sfgActualQty without warning.
        // During edit, only admin can edit sfgActualQty and with a warning.
        let sfgActualQtyInputUI = (
            <div className='flex-row'>
                <div className='width100 text-warning-wrapper'>
                    <Input
                        label='SFG Actual Qty'
                        type='number'
                        value={sfgActualQty}
                        onChange={(e) => setSfgActualQty(e.target.value)}
                        errorMessage={sfgActualQtyErr}
                    />
                </div>

                <span className='m-left-15 m-top-20'>
                    <WarningTip content={tooltips.editingActualQty} />
                </span>
            </div>
        );

        if (
            purpose === 'EDIT' &&
            !authUser.permissions.includes(permissions.APP_ADMIN)
        ) {
            sfgActualQtyInputUI = null;
        }

        if (purpose === 'ADD') {
            sfgActualQtyInputUI = (
                <div className='flex-row'>
                    <div className='width100'>
                        <Input
                            label='SFG Actual Qty'
                            type='number'
                            value={sfgActualQty}
                            onChange={(e) => setSfgActualQty(e.target.value)}
                            errorMessage={sfgActualQtyErr}
                        />
                    </div>
                </div>
            );
        }
        // ====================================================

        mainContent = (
            <section className='form-main-section'>
                {uidInput}

                <Input
                    label='SFG Code'
                    maxLength={lengths.code}
                    value={sfgCode}
                    onChange={(e) => setSFGCode(e.target.value)}
                    errorMessage={sfgCodeErr}
                />
                <Input
                    label='SFG Unit'
                    maxLength={lengths.unit}
                    value={sfgUnit}
                    onChange={(e) => setSFGUnit(e.target.value)}
                    placeholder='Required'
                    errorMessage={sfgUnitErr}
                />
                <Input
                    label='SFG Low Threshold'
                    type='number'
                    value={sfgLowThreshold}
                    onChange={(e) => setSFGLowThreshold(e.target.value)}
                    placeholder='Required'
                    errorMessage={sfgLowThresholdErr}
                />
                <Input
                    label='SFG Price 1'
                    type='number'
                    value={sfgPrice}
                    onChange={(e) => setSFGPrice(e.target.value)}
                    placeholder='Required'
                    errorMessage={sfgPriceErr}
                />
                <Input
                    label='SFG Price 2'
                    type='number'
                    value={sfgPrice2}
                    onChange={(e) => setSFGPrice2(e.target.value)}
                    // placeholder='Required'
                    errorMessage={sfgPriceErr2}
                />
                <Input
                    label='SFG Price 3'
                    type='number'
                    value={sfgPrice3}
                    onChange={(e) => setSFGPrice3(e.target.value)}
                    // placeholder='Required'
                    errorMessage={sfgPriceErr3}
                />
                <Input
                    label='SFG Price 4'
                    type='number'
                    value={sfgPrice4}
                    onChange={(e) => setSFGPrice4(e.target.value)}
                    // placeholder='Required'
                    errorMessage={sfgPriceErr4}
                />
                <Input
                    label='SFG Price 5'
                    type='number'
                    value={sfgPrice5}
                    onChange={(e) => setSFGPrice5(e.target.value)}
                    // placeholder='Required'
                    errorMessage={sfgPriceErr5}
                />

                {sfgActualQtyInputUI}

                <div className='span-2-columns-container label-padding label-highlight height-35'>
                    <label className='parent-labels'>Raw Materials List</label>
                    {addRawMatButton}
                    {disableChangingItems ? (
                        waringTipForEditingItems
                    ) : (
                        <HelpTip content={tooltips.sfgSetupRmArr} />
                    )}
                </div>

                {rmHeading}

                <div className='sfg-rm-section'>{rmArrUI}</div>
            </section>
        );
    }

    if (purpose === 'VIEW' || purpose === 'DELETE') {
        rmArr.forEach((rm, index) => {
            rmArrUI.push(
                <div className='span-2-columns-container' key={index}>
                    <div className='view-table-row'>
                        <div className='cell'>
                            <span className='mobile-label m-right-15'>
                                {`${index + 1}.`}
                            </span>
                            <span className='desktop-view m-lr-15'>
                                {`${index + 1}.`}
                            </span>
                            <span className=''> {rm.rmUID}</span>
                        </div>

                        <div className='cell'>
                            <span className='width50'>
                                <span className='mobile-label'>
                                    Required Qty:
                                </span>{' '}
                                <span className=''>{rm.rmRequiredQty}</span>
                                <span className='font12'> {rm.rmUnit}</span>
                            </span>
                            <span className='width50'>
                                <span className='mobile-label'>Group:</span>{' '}
                                <span className=''>{rm.group}</span>
                            </span>
                        </div>
                    </div>
                </div>
            );
        });

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='parent-labels'>SFG Name</p>
                    <p className=''>{sfgName}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='parent-labels'>SFG Code</p>
                    <p className=''>{sfgCode}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='parent-labels'>SFG Unit</p>
                    <p className=''>{sfgUnit}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='parent-labels'>SFG Low Threshold</p>
                    <p className=''>{displayReadableNumber(sfgLowThreshold)}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='parent-labels'>SFG Price 1</p>
                    <p className=''>{displayMoney(sfgPrice)}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='parent-labels'>SFG Price 2</p>
                    <p className=''>{displayMoney(sfgPrice2)}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='parent-labels'>SFG Price 3</p>
                    <p className=''>{displayMoney(sfgPrice3)}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='parent-labels'>SFG Price 4</p>
                    <p className=''>{displayMoney(sfgPrice4)}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='parent-labels'>SFG Price 5</p>
                    <p className=''>{displayMoney(sfgPrice5)}</p>
                </div>

                <div className='span-2-columns-container label-padding label-highlight'>
                    <label className='parent-labels'>Raw Materials List</label>
                </div>

                <div className='span-2-columns-container'>
                    <div className='view-table-heading'>
                        <div className='cell'>
                            <span className='m-left-15'>
                                RM Type - Description
                            </span>
                        </div>

                        <div className='cell'>
                            <span className='width50'>Required Qty</span>

                            <span className='width50'>Group</span>
                        </div>
                    </div>

                    {rmArrUI}
                </div>
            </section>
        );
    }

    if (purpose === 'ADD') {
        buttonSection = (
            <UpdateItemButtons saveFn={saveBtnFn} cancelFn={backCancelBtnFn} />
        );
    }

    if (purpose === 'EDIT') {
        buttonSection = (
            <UpdateItemButtons
                saveFn={saveBtnFn}
                cancelFn={cancelBtnFromEditViewFn}
            />
        );
    }

    if (purpose === 'DELETE') {
        let deleteBtn = (
            <button
                className='right-button-control btn btn-danger'
                onClick={saveBtnFn}>
                DELETE
            </button>
        );

        if (
            sfgName !== confirmDeleteText ||
            (currentDocument &&
                (currentDocument.sdpoNumbersArr.length > 0 ||
                    currentDocument.prodOrderNumbersArr.length > 0 ||
                    currentDocument.assemblyOrderNumbersArr.length > 0))
        ) {
            deleteBtn = (
                <button className='right-button-control btn btn-disable'>
                    DELETE
                </button>
            );
        }

        let deleteBtnSection = (
            <>
                <div className='confirm-delete-container'>
                    <input
                        id='delete-input'
                        placeholder={'Enter SFG Name'}
                        maxLength={50}
                        value={confirmDeleteText}
                        onChange={(e) => setConfirmDeleteText(e.target.value)}
                    />
                </div>
                {deleteBtn}
            </>
        );

        if (currentDocument && currentDocument.sdpoNumbersArr.length > 0) {
            deleteBtnSection = (
                <div>Existing active SD Purchase Order(s). Cannot Delete</div>
            );
        }
        if (currentDocument && currentDocument.prodOrderNumbersArr.length > 0) {
            deleteBtnSection = (
                <div>Existing active Production Order(s). Cannot Delete</div>
            );
        }
        if (
            currentDocument &&
            currentDocument.assemblyOrderNumbersArr.length > 0
        ) {
            deleteBtnSection = (
                <div>Existing active Assembly Order(s). Cannot Delete</div>
            );
        }

        buttonSection = (
            <section className='form-button-section'>
                <button
                    className='left-button-control btn'
                    onClick={backCancelBtnFn}>
                    Cancel
                </button>
                {deleteBtnSection}
            </section>
        );
    }

    if (purpose === 'VIEW') {
        buttonSection = (
            <SetupViewButtons
                backBtnFn={backCancelBtnFn}
                deleteBtnFn={gotoDeleteViewFn}
                editBtnFn={gotoEditViewFn}
            />
        );
    }

    let generalError = <div className='general-error-message'>{arraysErr}</div>;

    if (
        !authUser.permissions.includes(permissions.SFG_SETUP) ||
        !authUser.subscriptionStatusIsActive
    ) {
        buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    }

    return (
        <div className='common-form'>
            <section className='form-title-section'>
                Semi-Finished Good Profile
            </section>

            {mainContent}

            {buttonSection}
            {generalError}

            <Watermark purpose={purpose} currentDocument={currentDocument} />
        </div>
    );
};
