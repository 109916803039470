import React from 'react';
import './Input.css';

export default React.forwardRef((props, ref) => {
    let {
        key,
        id,
        label,
        disabled,
        className,
        maxLength,
        minLength,
        required,
        onChange,
        placeholder,
        value,
        errorMessage,
        type,
        onKeyDown,
        autoFocus,
        onFocus,
        onBlur,

        mobileLabel,
        desktopLabel,
    } = props;

    // feature added June-13-2020 for label to show on desktop view and mobile view only.
    // original cannot be deleted because app will break in so many places.
    // Solution is to add 2 more labels specific for desktop and mobile view.
    let originalLabelUI = (
        <label className='input-label' htmlFor={id}>
            {label}
        </label>
    );
    let desktopLabelUI = (
        <label className='desktop-view input-label' htmlFor={id}>
            {desktopLabel}
        </label>
    );
    let mobileLabelUI = (
        <label className='mobile-view input-label' htmlFor={id}>
            {mobileLabel}
        </label>
    );

    if (desktopLabel === undefined || desktopLabel === '')
        desktopLabelUI = null;
    if (mobileLabel === undefined || mobileLabel === '') mobileLabelUI = null;

    return (
        <div className='input-wrapper' key={key}>
            {originalLabelUI}
            {desktopLabelUI}
            {mobileLabelUI}
            <div className='input-and-datalist-container'>
                <input
                    ref={ref}
                    disabled={disabled}
                    type={type}
                    id={id}
                    className={className}
                    required={required}
                    onChange={onChange}
                    placeholder={placeholder}
                    value={value}
                    maxLength={maxLength}
                    minLength={minLength}
                    autoFocus={autoFocus}
                    onKeyDown={onKeyDown}
                    onFocus={onFocus}
                    onBlur={onBlur}
                ></input>
                <span className='input-error'>{errorMessage}</span>
            </div>
        </div>
    );
});
