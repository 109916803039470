import { db, firebase } from '../firebase_config/firebaseConfig';
import { userActions, systemActions } from '../lib/constants';
import {
    responseFn,
    formatCustomErrorObj,
    formatAlertErrorObj,
    saveWithFiveDecimals,
} from '../lib/util';
import validate from '../validators';
import mListHelper from './mListHelper';
import dbopsGetDocument from './dbopsGetDocument';
import { docNames, appMessage } from '../lib/messages';
import {
    operationObjToMListItemFn,
    sfgInvObjToMListItemFn,
    rmInvObjToMListItemFn,
    fgInvObjToMListItemFn,
} from '../lib/masterListConverters';

import {
    FINISHED_GOODS_COL,
    ASSEMBLY_COL,
    MASTER_LISTS_COL,
    MASTER_ASSEMBLY_ORDER_LIST_DOC,
    DELETED_ASSEMBLY_COL,
    ASSEMBLY_COUNTER_DOC,
    SG_NUMBERS_COL,
    SEMI_FGS_COL,
    RAW_MATERIALS_COL,
    MASTER_INVENTORY_FG_LIST_DOC,
    MASTER_INVENTORY_RM_LIST_DOC,
    MASTER_INVENTORY_SFG_LIST_DOC,
} from '../lib/constants';

// ================================================
// function to fetch each Assembly Order document.
const getAssemblyFn = async (docID) =>
    dbopsGetDocument(ASSEMBLY_COL, docID, 'Assembly Order');

// ======================================================

const createAssemblyFn = async (userCreds, assemblyObj) => {
    // add metadata to assemblyObj to be created in firestore.
    assemblyObj.meta = {
        action: userActions.CREATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // add metaHistory
    assemblyObj.metaHistory = [];

    const transactionFn = async (transaction) => {
        let assemblyCounterRef = db
            .collection(SG_NUMBERS_COL)
            .doc(ASSEMBLY_COUNTER_DOC);

        let assemblyCounterDoc = await transaction.get(assemblyCounterRef);
        let currentAssemblyNum = assemblyCounterDoc
            .data()
            .AssemblyOrderNumber.toString();

        // add server generated number to assemblyUID.
        assemblyObj.assemblyUID = currentAssemblyNum;
        assemblyObj.assemblyNumber = currentAssemblyNum;

        let newAssemblyRef = db
            .collection(ASSEMBLY_COL)
            .doc(assemblyObj.assemblyUID);

        let assemblyListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_ASSEMBLY_ORDER_LIST_DOC);

        // TRANSACTION GET OPERATIONS ================================
        // except AssemblyOrderNumber. GET finished already for the
        // assemblyDoc.
        let assemblyDoc = await transaction.get(newAssemblyRef);
        let assemblyListDoc = await transaction.get(assemblyListRef);

        // check exist error for new Assembly Order document
        if (assemblyDoc.exists) {
            return Promise.reject(
                formatCustomErrorObj(
                    1002,
                    appMessage.alreadyExist(docNames.ASSEMBLY)
                )
            );
        }

        // START MANIPULATING DATA TO UPDATE DATABASE ================================

        // increment SGNumbers Collection > AssemblyOrderCounter > AssemblyOrderNumber
        await transaction.set(
            assemblyCounterRef,
            {
                AssemblyOrderNumber:
                    assemblyCounterDoc.data().AssemblyOrderNumber + 1,
            },
            { merge: true }
        );

        // create or update production order list document in (master lists collection)
        if (assemblyListDoc.exists) {
            let assemblyInfoObj = operationObjToMListItemFn(
                assemblyObj,
                'assemblyUID'
            );

            let newData = mListHelper.updateMasterList(
                assemblyListDoc,
                assemblyObj.assemblyUID,
                assemblyInfoObj
            );

            await transaction.set(assemblyListRef, newData);
        } else {
            let assemblyInfoObj = operationObjToMListItemFn(
                assemblyObj,
                'assemblyUID'
            );

            let newData = mListHelper.addMasterList(
                assemblyObj.assemblyUID,
                assemblyInfoObj
            );

            await transaction.set(assemblyListRef, newData);
        }

        // set new production order document.
        await transaction.set(newAssemblyRef, assemblyObj);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyCreated(docNames.ASSEMBLY)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorCreating(docNames.ASSEMBLY)
        );
    }
};

// ===============================================
const updateAssemblyFn = async (
    userCreds,
    assemblyObj,
    documentBasis,
    userActionPassed
) => {
    if (assemblyObj.metaHistory.length > 99) assemblyObj.metaHistory.shift();

    assemblyObj.metaHistory.push(assemblyObj.meta);

    let status = userActionPassed ? userActionPassed : userActions.UPDATED;

    // update metadata in assemblyObj.
    assemblyObj.meta = {
        action: status,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // use assemblyUID as docuid
    let assemblyRef = db.collection(ASSEMBLY_COL).doc(assemblyObj.assemblyUID);

    let assemblyListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_ASSEMBLY_ORDER_LIST_DOC);

    const transactionFn = async (transaction) => {
        let assemblyDoc = await transaction.get(assemblyRef);

        if (!assemblyDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.ASSEMBLY)
                )
            );

        if (!validate.noChange(assemblyDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.ASSEMBLY)
                )
            );
        }

        let assemblyListDoc = await transaction.get(assemblyListRef);

        let assemblyInfoObj = operationObjToMListItemFn(
            assemblyObj,
            'assemblyUID'
        );

        let newData = mListHelper.updateMasterList(
            assemblyListDoc,
            assemblyObj.assemblyUID,
            assemblyInfoObj
        );

        await transaction.set(assemblyListRef, newData);

        await transaction.set(assemblyRef, assemblyObj);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyUpdated(docNames.ASSEMBLY)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorUpdating(docNames.ASSEMBLY)
        );
    }
};

// ================================================================
const deleteAssemblyFn = async (userCreds, assemblyObj, documentBasis) => {
    if (assemblyObj.metaHistory.length > 99) assemblyObj.metaHistory.shift();

    assemblyObj.metaHistory.push(assemblyObj.meta);

    assemblyObj.meta = {
        action: userActions.DELETED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // use assemblyUID. see updateAssemblyFn
    let assemblyRef = db.collection(ASSEMBLY_COL).doc(assemblyObj.assemblyUID);

    let assemblyListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_ASSEMBLY_ORDER_LIST_DOC);

    let deletedAssemblyRef = db.collection(DELETED_ASSEMBLY_COL).doc();

    const transactionFn = async (transaction) => {
        let assemblyDoc = await transaction.get(assemblyRef);

        if (!assemblyDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotDeleteBecauseDocNotExist(docNames.ASSEMBLY)
                )
            );

        if (!validate.noChange(assemblyDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotDeleteBecauseDocChanged(docNames.ASSEMBLY)
                )
            );
        }

        let assemblyListDoc = await transaction.get(assemblyListRef);
        await transaction.get(deletedAssemblyRef);

        let newData = mListHelper.deletePropertyFromMasterList(
            assemblyListDoc,
            assemblyObj.assemblyUID
        );

        await transaction.delete(assemblyRef);
        await transaction.set(assemblyListRef, newData);
        await transaction.set(deletedAssemblyRef, assemblyObj);
        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyDeleted(docNames.ASSEMBLY)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorDeleting(docNames.ASSEMBLY)
        );
    }
};

// ================================================================
const statusChangeFn = async (userCreds, assemblyObj, documentBasis) => {
    // move old metadate into metahistory. ensure meta data history length
    // is 100 or less. will be checked by security rules.
    if (assemblyObj.metaHistory.length > 99) assemblyObj.metaHistory.shift();

    assemblyObj.metaHistory.push(assemblyObj.meta);

    // update metadata in assemblyObj.
    assemblyObj.meta = {
        action: userCreds.action,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // get references.
    let assemblyRef = db.collection(ASSEMBLY_COL).doc(assemblyObj.assemblyUID);
    let assemblyListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_ASSEMBLY_ORDER_LIST_DOC);

    const transactionFn = async (transaction) => {
        let assemblyDoc = await transaction.get(assemblyRef);
        let assemblyListDoc = await transaction.get(assemblyListRef);

        if (!assemblyDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.ASSEMBLY)
                )
            );
        // ! this does not check meta data.
        if (!validate.noChange(assemblyDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.ASSEMBLY)
                )
            );
        }
        // ! meta data check if status of document changed.
        if (assemblyDoc.data().meta.action !== documentBasis.meta.action) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.ASSEMBLY)
                )
            );
        }

        let assemblyInfoObj = operationObjToMListItemFn(
            assemblyObj,
            'assemblyUID'
        );

        let newData = mListHelper.updateMasterList(
            assemblyListDoc,
            assemblyObj.assemblyUID,
            assemblyInfoObj
        );

        // write to masterlist and pdpo document in firebase.
        await transaction.set(assemblyListRef, newData);
        await transaction.set(assemblyRef, assemblyObj);
        return Promise.resolve('TransactionFn Completed Successfully');
    };
    try {
        await db.runTransaction(transactionFn);
        return responseFn(null, false, null, appMessage.successfulUpdateStatus);
    } catch (e) {
        return responseFn(null, true, e, appMessage.errorUpdatingStatus);
    }
};

const approveAssemblyOrderFn = async (
    userCreds,
    assemblyObj,
    documentBasis
) => {
    if (assemblyObj.metaHistory.length > 99) assemblyObj.metaHistory.shift();

    assemblyObj.metaHistory.push(assemblyObj.meta);

    // add metadata to assemblyObj to be created in firestore.
    assemblyObj.meta = {
        action: userActions.APPROVED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let existError = false;
    let balanceError = false;
    let rmBalanceErrorMsg = '';
    let whatRM = [];
    let sfgBalanceErrorMsg = '';
    let whatSFG = [];

    const transactionFn = async (transaction) => {
        let assemblyRef = db
            .collection(ASSEMBLY_COL)
            .doc(assemblyObj.assemblyUID);

        let assemblyListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_ASSEMBLY_ORDER_LIST_DOC);

        // get references for all sfgObjs
        let fgArrRefs = assemblyObj.fgArr.map((fg) => {
            return db.collection(FINISHED_GOODS_COL).doc(fg.fgUID);
        });

        // create an rmMap.
        let rmMap = {};
        assemblyObj.fgArr.forEach((fg) => {
            fg.rmArr.forEach((rm) => {
                rmMap[rm.rmUID] = { computedQty: 0 };
            });
        });

        // get references for all rmObjs
        let rmMapRefs = {};
        for (let key in rmMap) {
            rmMapRefs[key] = db.collection(RAW_MATERIALS_COL).doc(key);
        }

        // create an sfgMap
        let sfgMap = {};
        assemblyObj.fgArr.forEach((fg) => {
            fg.sfgArr.forEach((sfg) => {
                sfgMap[sfg.sfgUID] = { computedQty: 0 };
            });
        });

        // get references for all rmObjs
        let sfgMapRefs = {};
        for (let key in sfgMap) {
            sfgMapRefs[key] = db.collection(SEMI_FGS_COL).doc(key);
        }

        let rmInvListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_INVENTORY_RM_LIST_DOC);

        let sfgInvListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_INVENTORY_SFG_LIST_DOC);

        let fgInvListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_INVENTORY_FG_LIST_DOC);

        // TRANSACTION GET OPERATIONS ================================

        let assemblyDoc = await transaction.get(assemblyRef);
        let assemblyListDoc = await transaction.get(assemblyListRef);

        //check exist error for new Assembly Order document
        // should never run.
        if (!assemblyDoc.exists) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.ASSEMBLY)
                )
            );
        }
        if (!validate.noChange(assemblyDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.ASSEMBLY)
                )
            );
        }

        // ! meta data check if status of document changed.
        if (assemblyDoc.data().meta.action !== documentBasis.meta.action) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.ASSEMBLY)
                )
            );
        }

        // get fg objects
        let fgArrDocs = fgArrRefs.map(
            async (fgRef) => await transaction.get(fgRef)
        );

        try {
            fgArrDocs = await Promise.all(fgArrDocs);
        } catch (e) {
            return Promise.reject(
                formatCustomErrorObj(
                    900,
                    appMessage.prommiseAllError('dbopsAssembly', 'fgArr')
                )
            );
        }

        // check exists error for fg Objects. no need to check for changes because we
        // are just going to increment the sfgOrderQty.
        fgArrDocs.forEach((fgDoc) => {
            if (!fgDoc.exists) existError = true;
        });

        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopsAssembly',
                        docNames.ASSEMBLY,
                        'FG'
                    )
                )
            );
        }

        // get all rm docs
        let rmMapDocs = {};
        for (let key in rmMapRefs) {
            rmMapDocs[key] = await transaction.get(rmMapRefs[key]);
        }

        // check if the rmMapDocs exist.
        for (let key in rmMapDocs) {
            if (!rmMapDocs[key].exists) existError = true;
        }

        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopsAssembly',
                        docNames.ASSEMBLY,
                        'RM'
                    )
                )
            );
        }

        // get all sfg docs
        let sfgMapDocs = {};
        for (let key in sfgMapRefs) {
            sfgMapDocs[key] = await transaction.get(sfgMapRefs[key]);
        }

        // check if the sfgMapDocs exist.
        for (let key in sfgMapDocs) {
            if (!sfgMapDocs[key].exists) existError = true;
        }

        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopsAssembly',
                        docNames.ASSEMBLY,
                        'SFG'
                    )
                )
            );
        }

        let rmInvListDoc = await transaction.get(rmInvListRef);
        let sfgInvListDoc = await transaction.get(sfgInvListRef);
        let fgInvListDoc = await transaction.get(fgInvListRef);

        // END TRANSACTION GET OPERATIONS ============================================

        // START MANIPULATING DATA TO UPDATE DATABASE ================================

        // for FG inventory process.
        let tempFgInventoryList = fgInvListDoc.data();

        // set fg orderedQty and add assemblyUID to assemblyOrderNumbersArr
        assemblyObj.fgArr.forEach(async (fg, index) => {
            let fgCopy = {
                ...fgArrDocs[index].data(),
            };
            fgCopy.fgProjectedQty = saveWithFiveDecimals(
                fgCopy.fgProjectedQty + fg.fgOrderedQty
            );
            fgCopy.assemblyOrderNumbersArr.push(assemblyObj.assemblyUID);

            let inventoryFgInfoObj = fgInvObjToMListItemFn(fgCopy);

            tempFgInventoryList = mListHelper.updateTempMList(
                tempFgInventoryList,
                fgCopy.fgUID,
                inventoryFgInfoObj
            );

            await transaction.set(fgArrRefs[index], fgCopy, { merge: true });
        });

        assemblyObj.fgArr.forEach(async (fg) => {
            // calculate the rmProjectedQty that needs to be deducted.
            fg.rmArr.forEach((rm) => {
                rmMap[rm.rmUID].computedQty = saveWithFiveDecimals(
                    rmMap[rm.rmUID].computedQty + rm.rmProjectedUseQty
                );
            });
            // calculate the sfgProjectedQty that needs to be deducted.
            fg.sfgArr.forEach((sfg) => {
                sfgMap[sfg.sfgUID].computedQty = saveWithFiveDecimals(
                    sfgMap[sfg.sfgUID].computedQty + sfg.sfgProjectedUseQty
                );
            });
        });

        // for RM inventory process.
        let tempRmInventoryList = rmInvListDoc.data();

        for (let key in rmMap) {
            //! check if there is enough rmProjectedQty.
            let currentProjectedQty = rmMapDocs[key].data().rmProjectedQty;
            if (currentProjectedQty - rmMap[key].computedQty < 0) {
                balanceError = true;
                whatRM.push(key);
                rmBalanceErrorMsg = `Insufficient RM Projected Qty`;
            }

            let newProjectedQty = saveWithFiveDecimals(
                currentProjectedQty - rmMap[key].computedQty
            );

            //! Add the assemblyUID to each RM.assemblyOrderNumbersArr
            let newAssemblyNumbersArr = [
                ...rmMapDocs[key].data().assemblyOrderNumbersArr,
            ];
            newAssemblyNumbersArr.push(assemblyObj.assemblyUID);

            let data = {
                rmProjectedQty: newProjectedQty,
                assemblyOrderNumbersArr: newAssemblyNumbersArr,
            };

            let rmCopy = rmMapDocs[key].data();
            rmCopy.rmProjectedQty = data.rmProjectedQty;
            let inventoryRmInfoObj = rmInvObjToMListItemFn(rmCopy);

            tempRmInventoryList = mListHelper.updateTempMList(
                tempRmInventoryList,
                rmCopy.rmUID,
                inventoryRmInfoObj
            );

            await transaction.set(rmMapRefs[key], data, { merge: true });
        }

        // for SFG inventory process.
        let tempSfgInventoryList = sfgInvListDoc.data();

        for (let key in sfgMap) {
            //! check if there is enough sfgProjectedQty.
            let currentProjectedQty = sfgMapDocs[key].data().sfgProjectedQty;
            if (currentProjectedQty - sfgMap[key].computedQty < 0) {
                balanceError = true;
                whatSFG.push(key);
                sfgBalanceErrorMsg = `Insufficient SFG Projected Qty`;
            }

            let newProjectedQty = saveWithFiveDecimals(
                currentProjectedQty - sfgMap[key].computedQty
            );

            //! Add the assemblyUID to each SFG.assemblyOrderNumbersArr
            let newAssemblyNumbersArr = [
                ...sfgMapDocs[key].data().assemblyOrderNumbersArr,
            ];
            newAssemblyNumbersArr.push(assemblyObj.assemblyUID);

            let data = {
                sfgProjectedQty: newProjectedQty,
                assemblyOrderNumbersArr: newAssemblyNumbersArr,
            };

            let sfgCopy = sfgMapDocs[key].data();
            sfgCopy.sfgProjectedQty = data.sfgProjectedQty;
            let inventorySfgInfoObj = sfgInvObjToMListItemFn(sfgCopy);

            tempSfgInventoryList = mListHelper.updateTempMList(
                tempSfgInventoryList,
                sfgCopy.sfgUID,
                inventorySfgInfoObj
            );

            await transaction.set(sfgMapRefs[key], data, { merge: true });
        }

        if (balanceError) {
            return Promise.reject(
                formatCustomErrorObj(800, `Insufficient SFG/RM Projected Qty`)
            );
        }

        // create or update production order list document in (master lists collection)
        if (assemblyListDoc.exists) {
            let assemblyInfoObj = operationObjToMListItemFn(
                assemblyObj,
                'assemblyUID'
            );

            let newData = mListHelper.updateMasterList(
                assemblyListDoc,
                assemblyObj.assemblyUID,
                assemblyInfoObj
            );

            await transaction.set(assemblyListRef, newData);
        } else {
            let assemblyInfoObj = operationObjToMListItemFn(
                assemblyObj,
                'assemblyUID'
            );

            let newData = mListHelper.addMasterList(
                assemblyObj.assemblyUID,
                assemblyInfoObj
            );

            await transaction.set(assemblyListRef, newData);
        }

        // set new sfg inventory master list
        await transaction.set(sfgInvListRef, tempSfgInventoryList);

        // set new rm inventory master list
        await transaction.set(rmInvListRef, tempRmInventoryList);

        // set new fg inventory master list
        await transaction.set(fgInvListRef, tempFgInventoryList);

        // set new production order document.
        await transaction.set(assemblyRef, assemblyObj);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(null, false, null, appMessage.successfulUpdateStatus);
    } catch (e) {
        let customErrorObj =
            rmBalanceErrorMsg || sfgBalanceErrorMsg
                ? formatAlertErrorObj(
                      rmBalanceErrorMsg,
                      whatRM,
                      sfgBalanceErrorMsg,
                      whatSFG
                  )
                : null;
        return responseFn(
            customErrorObj,
            true,
            e,
            appMessage.errorUpdatingStatus
        );
    }
};

const overrideAssemblyOrderFn = async (
    userCreds,
    assemblyObj,
    documentBasis
) => {
    if (assemblyObj.metaHistory.length > 99) assemblyObj.metaHistory.shift();

    assemblyObj.metaHistory.push(assemblyObj.meta);

    // add metadata to assemblyObj to be created in firestore.
    assemblyObj.meta = {
        action: userActions.OVERRIDDEN,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let existError = false;
    let balanceError = false;
    let rmBalanceErrorMsg = '';
    let whatRM = [];
    let sfgBalanceErrorMsg = '';
    let whatSFG = [];

    const transactionFn = async (transaction) => {
        let assemblyListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_ASSEMBLY_ORDER_LIST_DOC);

        let fgInvListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_INVENTORY_FG_LIST_DOC);

        let rmInvListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_INVENTORY_RM_LIST_DOC);

        let sfgInvListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_INVENTORY_SFG_LIST_DOC);

        // get individual refs of docs
        let assemblyRef = db
            .collection(ASSEMBLY_COL)
            .doc(assemblyObj.assemblyUID);

        // get references for all sfgObjs
        let fgArrRefs = assemblyObj.fgArr.map((fg) => {
            return db.collection(FINISHED_GOODS_COL).doc(fg.fgUID);
        });

        // create an sfgMap.
        let sfgMap = {};
        assemblyObj.fgArr.forEach((fg) => {
            fg.sfgArr.forEach((sfg) => {
                sfgMap[sfg.sfgUID] = { computedQty: 0 };
            });
        });

        // get references for all rmObjs
        let sfgMapRefs = {};
        for (let key in sfgMap) {
            sfgMapRefs[key] = db.collection(SEMI_FGS_COL).doc(key);
        }

        // create an rmMap.
        let rmMap = {};
        assemblyObj.fgArr.forEach((fg) => {
            fg.rmArr.forEach((rm) => {
                rmMap[rm.rmUID] = { computedQty: 0 };
            });
        });

        // get references for all rmObjs
        let rmMapRefs = {};
        for (let key in rmMap) {
            rmMapRefs[key] = db.collection(RAW_MATERIALS_COL).doc(key);
        }

        // TRANSACTION GET OPERATIONS ================================

        let assemblyDoc = await transaction.get(assemblyRef);
        let assemblyListDoc = await transaction.get(assemblyListRef);

        // check exist error for new Assembly Order document.
        // should never run.
        if (!assemblyDoc.exists) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.ASSEMBLY)
                )
            );
        }

        //! updated noChange validation to check for status changes.
        if (!validate.noChange(assemblyDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.ASSEMBLY)
                )
            );
        }

        // get sfg objects
        let fgArrDocs = fgArrRefs.map(
            async (fgRef) => await transaction.get(fgRef)
        );

        try {
            fgArrDocs = await Promise.all(fgArrDocs);
        } catch (e) {
            return Promise.reject(
                formatCustomErrorObj(
                    900,
                    appMessage.prommiseAllError('dbopsAssembly', 'fgArr')
                )
            );
        }

        // check exists error for fg Objects. no need to check for changes because we
        // are just going to increment the fgOrderQty.
        fgArrDocs.forEach((fgDoc) => {
            if (!fgDoc.exists) existError = true;
        });

        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopsAssembly',
                        docNames.ASSEMBLY,
                        'FG'
                    )
                )
            );
        }

        // get all sfg docs
        let sfgMapDocs = {};
        for (let key in sfgMapRefs) {
            sfgMapDocs[key] = await transaction.get(sfgMapRefs[key]);
        }

        // check if the sfgMapDocs exist.
        for (let key in sfgMapDocs) {
            if (!sfgMapDocs[key].exists) existError = true;
        }

        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopsAssembly',
                        docNames.ASSEMBLY,
                        'SFG'
                    )
                )
            );
        }

        // get all rm docs
        let rmMapDocs = {};
        for (let key in rmMapRefs) {
            rmMapDocs[key] = await transaction.get(rmMapRefs[key]);
        }

        // check if the rmMapDocs exist.
        for (let key in rmMapDocs) {
            if (!rmMapDocs[key].exists) existError = true;
        }

        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopsAssembly',
                        docNames.ASSEMBLY,
                        'RM'
                    )
                )
            );
        }

        let fgInvListDoc = await transaction.get(fgInvListRef);
        let rmInvListDoc = await transaction.get(rmInvListRef);
        let sfgInvListDoc = await transaction.get(sfgInvListRef);

        // END TRANSACTION GET OPERATIONS ============================================

        // check if assembly status can be changed to 'FULFILLED'. ==================
        let allFgsAndSfgsAndRmsDeliveredOrOverridden = [];
        assemblyObj.fgArr.forEach((fg) => {
            if (fg.fgBalanceQty + fg.fgOverrideQty === 0) {
                allFgsAndSfgsAndRmsDeliveredOrOverridden.push(true);

                // if fg balance is 0, check each sfg if balance is also zero
                fg.sfgArr.forEach((sfg) => {
                    if (sfg.sfgBalanceQty + sfg.sfgOverrideQty === 0) {
                        allFgsAndSfgsAndRmsDeliveredOrOverridden.push(true);
                    } else {
                        allFgsAndSfgsAndRmsDeliveredOrOverridden.push(false);
                    }
                });

                // if fg balance is 0, check each rm if balance is also zero
                fg.rmArr.forEach((rm) => {
                    if (rm.rmBalanceQty + rm.rmOverrideQty === 0) {
                        allFgsAndSfgsAndRmsDeliveredOrOverridden.push(true);
                    } else {
                        allFgsAndSfgsAndRmsDeliveredOrOverridden.push(false);
                    }
                });
            } else {
                allFgsAndSfgsAndRmsDeliveredOrOverridden.push(false);
            }
        });

        // copy of fgDocs so assemblyOrderNumbersArr can be manipulated.
        let fgArrDocsCopy = fgArrDocs.map((fgDoc) => fgDoc.data());
        let removeAssemblyUIDFromRmAssemblyOrderNumbersArr = false;
        let removeAssemblyUIDFromSfgAssemblyOrderNumbersArr = false;

        if (
            allFgsAndSfgsAndRmsDeliveredOrOverridden.every(
                (ans) => ans === true
            )
        ) {
            //! remove assemblyUID from all the fgs in the assembly.fgArr
            fgArrDocsCopy.forEach((fgObj) => {
                let newArr = fgObj.assemblyOrderNumbersArr.filter(
                    (productionNumber) => {
                        return productionNumber !== assemblyObj.assemblyUID;
                    }
                );
                fgObj.assemblyOrderNumbersArr = newArr;
            });

            removeAssemblyUIDFromRmAssemblyOrderNumbersArr = true;
            removeAssemblyUIDFromSfgAssemblyOrderNumbersArr = true;

            // update metadata in assemblyObj.
            assemblyObj.meta = {
                action: systemActions.FULFILLED,
                uid: systemActions.UID,
                email: systemActions.EMAIL,
                alert: {
                    note:
                        '"OVERRIDDEN" by user then system changed status to "FULFILLED"',
                    action: userActions.OVERRIDDEN,
                    uid: userCreds.uid,
                    email: userCreds.email,
                    timeStamp:
                        'Same time as system changed status of this document.',
                },
                timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
            };
        } else {
            // else... meta will be overridden and changed to status "OVERRIDDEN"
            // done in the first step of the function.

            //! add assemblyUID in all fgs inside assemblyObj.fgArr.
            fgArrDocsCopy.forEach((fgObj) => {
                let newArr = fgObj.assemblyOrderNumbersArr.filter(
                    (assemblyNumber) => {
                        return assemblyNumber !== assemblyObj.assemblyUID;
                    }
                );
                newArr.push(assemblyObj.assemblyUID);
                fgObj.assemblyOrderNumbersArr = newArr;
            });

            removeAssemblyUIDFromRmAssemblyOrderNumbersArr = false;
            removeAssemblyUIDFromSfgAssemblyOrderNumbersArr = false;
        }

        // for fg inventory process.
        let tempFgInventoryList = fgInvListDoc.data();

        // calculate all sfg projectedQty
        fgArrDocs.forEach(async (fgDoc, fgIndex) => {
            let current = assemblyObj.fgArr[fgIndex].fgOverrideQty;
            let previous = documentBasis.fgArr[fgIndex].fgOverrideQty;
            let newProjected = saveWithFiveDecimals(
                fgDoc.data().fgProjectedQty + (current - previous)
            );

            let updateData = {
                fgProjectedQty: newProjected,
                assemblyOrderNumbersArr:
                    fgArrDocsCopy[fgIndex].assemblyOrderNumbersArr,
            };

            let fgCopy = fgArrDocsCopy[fgIndex];
            fgCopy.fgProjectedQty = updateData.fgProjectedQty;
            let inventoryFgInfoObj = fgInvObjToMListItemFn(fgCopy);

            tempFgInventoryList = mListHelper.updateTempMList(
                tempFgInventoryList,
                fgCopy.fgUID,
                inventoryFgInfoObj
            );

            await transaction.set(fgArrRefs[fgIndex], updateData, {
                merge: true,
            });
        });

        //! this needs to be in a separate loop because of the async loop above.
        // fgArrDocs.forEach((fgDoc, fgIndex) => {
        //     // calculate sfgProjectedQty that needs to be deducted.
        //     fgDoc.data().sfgArr.forEach(async (sfg, sfgIndex) => {
        //         let currentSfgOverrideQty =
        //             assemblyObj.fgArr[fgIndex].sfgArr[sfgIndex].sfgOverrideQty;
        //         let previousSfgOverrideQty =
        //             documentBasis.fgArr[fgIndex].sfgArr[sfgIndex]
        //                 .sfgOverrideQty;

        //         sfgMap[sfg.sfgUID].computedQty = saveWithFiveDecimals(
        //             sfgMap[sfg.sfgUID].computedQty +
        //                 (currentSfgOverrideQty - previousSfgOverrideQty)
        //         );
        //     });
        //     // calculate rmProjectedQty that needs to be deducted.
        //     fgDoc.data().rmArr.forEach(async (rm, rmIndex) => {
        //         let currentRmOverrideQty =
        //             assemblyObj.fgArr[fgIndex].rmArr[rmIndex].rmOverrideQty;
        //         let previousRmOverrideQty =
        //             documentBasis.fgArr[fgIndex].rmArr[rmIndex].rmOverrideQty;

        //         rmMap[rm.rmUID].computedQty = saveWithFiveDecimals(
        //             rmMap[rm.rmUID].computedQty +
        //                 (currentRmOverrideQty - previousRmOverrideQty)
        //         );
        //     });
        // });

        //! this needs to be in a separate loop because of the async loop above.

        //! bug fix 2021-03-21. required items of fg (sfgs and rms) is now going to reference
        //! whatever the components during the approval of this assembly order so any changes
        //! in the setup of the fg will not produce an error when overriding assembly order.
        assemblyObj.fgArr.forEach((fg, fgIndex) => {
            // calculate sfgProjectedQty that needs to be deducted.
            fg.sfgArr.forEach(async (sfg, sfgIndex) => {
                let currentSfgOverrideQty =
                    assemblyObj.fgArr[fgIndex].sfgArr[sfgIndex].sfgOverrideQty;
                let previousSfgOverrideQty =
                    documentBasis.fgArr[fgIndex].sfgArr[sfgIndex]
                        .sfgOverrideQty;

                sfgMap[sfg.sfgUID].computedQty = saveWithFiveDecimals(
                    sfgMap[sfg.sfgUID].computedQty +
                        (currentSfgOverrideQty - previousSfgOverrideQty)
                );
            });
            // calculate rmProjectedQty that needs to be deducted.
            fg.rmArr.forEach(async (rm, rmIndex) => {
                let currentRmOverrideQty =
                    assemblyObj.fgArr[fgIndex].rmArr[rmIndex].rmOverrideQty;
                let previousRmOverrideQty =
                    documentBasis.fgArr[fgIndex].rmArr[rmIndex].rmOverrideQty;

                rmMap[rm.rmUID].computedQty = saveWithFiveDecimals(
                    rmMap[rm.rmUID].computedQty +
                        (currentRmOverrideQty - previousRmOverrideQty)
                );
            });
        });

        let tempSfgInventoryList = sfgInvListDoc.data();

        for (let key in sfgMap) {
            //! check if there is enough sfgProjectedQty.
            let currentProjectedQty = sfgMapDocs[key].data().sfgProjectedQty;
            if (currentProjectedQty - sfgMap[key].computedQty < 0) {
                balanceError = true;
                whatSFG.push(key);
                sfgBalanceErrorMsg = 'Insufficient SFG  Projected Qty';
            }

            //! add or remove assemblyUID to and from each sfg.assemblyOrderNumbersArr
            let newAssemblyOrderNumbersArr = [
                ...sfgMapDocs[key].data().assemblyOrderNumbersArr,
            ];
            if (removeAssemblyUIDFromSfgAssemblyOrderNumbersArr) {
                newAssemblyOrderNumbersArr = newAssemblyOrderNumbersArr.filter(
                    (assemblyUID) => {
                        return assemblyUID !== assemblyObj.assemblyUID;
                    }
                );
            } else {
                newAssemblyOrderNumbersArr = newAssemblyOrderNumbersArr.filter(
                    (assemblyUID) => {
                        return assemblyUID !== assemblyObj.assemblyUID;
                    }
                );
                // add the assemblyUID into the assemblyOrderNumbersArr.
                newAssemblyOrderNumbersArr.push(assemblyObj.assemblyUID);
            }

            let newProjected = saveWithFiveDecimals(
                currentProjectedQty - sfgMap[key].computedQty
            );

            let data = {
                sfgProjectedQty: newProjected,
                assemblyOrderNumbersArr: newAssemblyOrderNumbersArr,
            };

            let sfgCopy = sfgMapDocs[key].data();
            sfgCopy.sfgProjectedQty = data.sfgProjectedQty;
            let inventorySfgInfoObj = sfgInvObjToMListItemFn(sfgCopy);

            tempSfgInventoryList = mListHelper.updateTempMList(
                tempSfgInventoryList,
                sfgCopy.sfgUID,
                inventorySfgInfoObj
            );

            await transaction.set(sfgMapRefs[key], data, { merge: true });
        }

        let tempRmInventoryList = rmInvListDoc.data();

        for (let key in rmMap) {
            //! check if there is enough rmProjectedQty.
            let currentProjectedQty = rmMapDocs[key].data().rmProjectedQty;
            if (currentProjectedQty - rmMap[key].computedQty < 0) {
                balanceError = true;
                whatRM.push(key);
                rmBalanceErrorMsg = 'Insufficient RM Projected Qty';
            }

            //! add or remove assemblyUID to and from each rm.assemblyOrderNumbersArr
            let newAssemblyOrderNumbersArr = [
                ...rmMapDocs[key].data().assemblyOrderNumbersArr,
            ];
            if (removeAssemblyUIDFromRmAssemblyOrderNumbersArr) {
                newAssemblyOrderNumbersArr = newAssemblyOrderNumbersArr.filter(
                    (assemblyUID) => {
                        return assemblyUID !== assemblyObj.assemblyUID;
                    }
                );
            } else {
                newAssemblyOrderNumbersArr = newAssemblyOrderNumbersArr.filter(
                    (assemblyUID) => {
                        return assemblyUID !== assemblyObj.assemblyUID;
                    }
                );
                // add the assemblyUID into the assemblyOrderNumbersArr.
                newAssemblyOrderNumbersArr.push(assemblyObj.assemblyUID);
            }

            let newProjected = saveWithFiveDecimals(
                currentProjectedQty - rmMap[key].computedQty
            );

            let data = {
                rmProjectedQty: newProjected,
                assemblyOrderNumbersArr: newAssemblyOrderNumbersArr,
            };

            let rmCopy = rmMapDocs[key].data();
            rmCopy.rmProjectedQty = data.rmProjectedQty;
            let inventoryRmInfoObj = rmInvObjToMListItemFn(rmCopy);

            tempRmInventoryList = mListHelper.updateTempMList(
                tempRmInventoryList,
                rmCopy.rmUID,
                inventoryRmInfoObj
            );

            await transaction.set(rmMapRefs[key], data, { merge: true });
        }

        if (balanceError) {
            return Promise.reject(
                formatCustomErrorObj(800, `Insufficient SFG/RM Projected Qty`)
            );
        }

        // create or update production order list document in (master lists collection)
        if (assemblyListDoc.exists) {
            let assemblyInfoObj = operationObjToMListItemFn(
                assemblyObj,
                'assemblyUID'
            );

            let newData = mListHelper.updateMasterList(
                assemblyListDoc,
                assemblyObj.assemblyUID,
                assemblyInfoObj
            );

            await transaction.set(assemblyListRef, newData);
        } else {
            let assemblyInfoObj = operationObjToMListItemFn(
                assemblyObj,
                'assemblyUID'
            );

            let newData = mListHelper.addMasterList(
                assemblyObj.assemblyUID,
                assemblyInfoObj
            );

            await transaction.set(assemblyListRef, newData);
        }

        await transaction.set(assemblyRef, assemblyObj);
        await transaction.set(fgInvListRef, tempFgInventoryList);
        await transaction.set(sfgInvListRef, tempSfgInventoryList);
        await transaction.set(rmInvListRef, tempRmInventoryList);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyUpdated(docNames.ASSEMBLY)
        );
    } catch (e) {
        let customErrorObj =
            rmBalanceErrorMsg || sfgBalanceErrorMsg
                ? formatAlertErrorObj(
                      rmBalanceErrorMsg,
                      whatRM,
                      sfgBalanceErrorMsg,
                      whatSFG
                  )
                : null;

        return responseFn(
            customErrorObj,
            true,
            e,
            appMessage.errorUpdating(docNames.ASSEMBLY)
        );
    }
};

export default {
    getAssemblyFn,
    createAssemblyFn,
    updateAssemblyFn,
    deleteAssemblyFn,
    statusChangeFn,
    approveAssemblyOrderFn,
    overrideAssemblyOrderFn,
};
