export const noChange = (obj1, obj2) => {
    // check status. if not the same, return false
    if (obj1.meta.action !== obj2.meta.action) return false;

    let obj1NoMeta = {
        ...obj1,
    };
    delete obj1NoMeta.meta;
    delete obj1NoMeta.metaHistory;

    let obj2NoMeta = {
        ...obj2,
    };
    delete obj2NoMeta.meta;
    delete obj2NoMeta.metaHistory;

    const isSameValue = (x, y) => {
        let sameValue = true;
        const crawl = (x, y) => {
            if (sameValue === false) return;

            if (
                typeof x === typeof y &&
                Array.isArray(x) === Array.isArray(y)
            ) {
                if (typeof x === 'object' && x !== null) {
                    for (let key in x) {
                        //? ==================================================
                        // if key === 'meta' or 'metaHistory', SKIP.
                        if (key === 'meta' || key === 'metaHistory') continue;
                        //? ==================================================

                        if (key in y) {
                            crawl(x[key], y[key]);
                        } else {
                            // console.log(key);
                            sameValue = false;
                        }
                    }
                }

                if (
                    (typeof x === 'string' || typeof x === 'number') &&
                    x !== y
                ) {
                    // console.log(x, y);
                    sameValue = false;
                }
            } else {
                // console.log(x, y);
                sameValue = false;
            }
        };

        crawl(x, y);
        if (sameValue) crawl(y, x);

        return sameValue;
    };

    return isSameValue(obj1NoMeta, obj2NoMeta);
};
