import React from 'react';
import { displayDate } from '../../../lib/util';
import './OperationItem.css';
import '../CollectionItems.css';

export default (props) => {
    let { obj, serialNumber, onClick, heading, keyProperty } = props;

    let itemUID = 'SFG Job Order Number';
    let itemUIDLabel = 'SFG Job Order Number:';
    let descriptionLabel = 'Description:';
    let descriptionProp = 'productionDescription';
    let descriptionUI = 'Description';
    let prefix = 'production';
    let date = 'prodOrderDate';
    let dateLabel = 'SFG Job Order Date:';

    let dateUI = 'SFG Job Order Date';

    if (keyProperty === 'assemblyUID') {
        prefix = 'assembly';
        itemUID = 'Assembly - Order Number';
        itemUIDLabel = 'Assembly Order Number:';
        date = 'assemblyOrderDate';
        dateUI = 'Assembly - Order Date';
        dateLabel = 'Assembly - Order Date:';
        descriptionProp = 'assemblyDescription';
    }

    if (keyProperty === 'productionDeliveredUID') {
        prefix = 'productionDelivered';
        itemUID = 'Production Number';
        itemUIDLabel = 'Production Number:';
        date = 'productionDeliveredDate';
        dateUI = 'Production Date';
        dateLabel = 'Production Date:';
        descriptionProp = 'productionDeliveredDescription';
    }

    if (keyProperty === 'assemblyDeliveredUID') {
        prefix = 'assemblyDelivered';
        itemUID = 'Assembly Number';
        itemUIDLabel = 'Assembly Number:';
        date = 'assemblyDeliveredDate';
        dateUI = 'Assembly Date';
        dateLabel = 'Assembly Date:';
        descriptionProp = 'assemblyDeliveredDescription';
    }

    let serial = 'S/No.';
    let status = 'Status';
    let headingClass = 'text-bold list-table-heading';

    let serialLabel = serial + ':';
    let statusLabel = status + ':';

    if (!heading) {
        serial = serialNumber;
        itemUID = obj[`${prefix}UID`];
        dateUI = displayDate(obj[date]);
        descriptionUI = obj[descriptionProp];
        status = obj.status;
        headingClass = '';
    }

    return (
        <div
            className={`operation-item collection-item ${headingClass}`}
            onClick={onClick}>
            <div className='collection-item-entry serial'>
                {' '}
                <span className='entry-label'>{serialLabel}</span>
                {serial}
            </div>
            <div className='collection-item-entry item-uid'>
                {' '}
                <span className='entry-label'>{itemUIDLabel}</span>
                {itemUID}
            </div>
            <div className='collection-item-entry description'>
                {' '}
                <span className='entry-label'>{descriptionLabel}</span>
                {descriptionUI}
            </div>
            <div className='collection-item-entry order-date'>
                {' '}
                <span className='entry-label'>{dateLabel}</span>
                {dateUI}
            </div>
            <div className='collection-item-entry status'>
                {' '}
                <span className='entry-label'>{statusLabel}</span>
                {status}
            </div>
        </div>
    );
};
