/*
    Helper functions ==================================
    Master Suppliers List schema:
    {
        data: {
            [supplierName]: {i: phoneNumber: a: false/true}
        }
    }

    1. data value is stringified object.
    2. helper functions parses and stringifies the data property.
*/

export const updateMasterList = (masterListDoc, propertyName, obj) => {
    let docContent = masterListDoc.data();
    docContent.data = JSON.parse(docContent.data);
    docContent.data[propertyName] = obj;
    docContent.data = JSON.stringify(docContent.data);
    return docContent;
};

export const addMasterList = (propertyName, supplierInfoObj) => {
    let newDocContent = {};
    newDocContent.data = {
        [propertyName]: supplierInfoObj,
    };
    newDocContent.data = JSON.stringify(newDocContent.data);
    return newDocContent;
};

export const deletePropertyFromMasterList = (masterListDoc, propertyName) => {
    let docContent = masterListDoc.data();
    docContent.data = JSON.parse(docContent.data);
    delete docContent.data[propertyName];
    docContent.data = JSON.stringify(docContent.data);
    return docContent;
};

export const updateTempMList = (document, propertyName, obj) => {
    // document has data property that is a string.
    let docContent = {};
    docContent.data = JSON.parse(document.data);
    docContent.data[propertyName] = obj;
    docContent.data = JSON.stringify(docContent.data);
    return docContent;
};

export const deletePropertyFromTempMList = (document, propertyName) => {
    let docContent = {};
    docContent.data = JSON.parse(document.data);
    delete docContent.data[propertyName];
    docContent.data = JSON.stringify(docContent.data);
    return docContent;
};

export default {
    addMasterList,
    deletePropertyFromMasterList,
    updateMasterList,
    updateTempMList,
    deletePropertyFromTempMList,
};
