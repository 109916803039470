import React from 'react';
import {
    displayMoney,
    applyDiscount,
    saveWithTwoDecimalOnly,
} from '../../lib/util';
import { Input } from '../../components';

export default (props) => {
    let {
        priceSetterObj,

        sdpoObj,
        setTaxInState,
        taxInState,
        setDiscountInState,
        discountInState,
        purpose,
    } = props;

    let itemsTotal = sdpoObj.fgArr.reduce((acc, fg) => {
        let currentAmount = saveWithTwoDecimalOnly(
            Number(fg.fgOrderedQty) *
                Number(fg[priceSetterObj.fgPriceListToUse()])
        );

        currentAmount = saveWithTwoDecimalOnly(
            currentAmount +
                Number(fg.fgOverrideQty) *
                    Number(fg[priceSetterObj.fgPriceListToUse()])
        );

        return (acc += currentAmount);
    }, 0);
    itemsTotal = sdpoObj.sfgArr.reduce((acc, sfg) => {
        let currentAmount = saveWithTwoDecimalOnly(
            Number(sfg.sfgOrderedQty) *
                Number(sfg[priceSetterObj.sfgPriceListToUse()])
        );

        currentAmount = saveWithTwoDecimalOnly(
            currentAmount +
                Number(sfg.sfgOverrideQty) *
                    Number(sfg[priceSetterObj.sfgPriceListToUse()])
        );

        return (acc = saveWithTwoDecimalOnly(acc + currentAmount));
    }, itemsTotal);

    let withDiscountSubtotal = saveWithTwoDecimalOnly(
        applyDiscount(itemsTotal, discountInState)
    );
    // let total = saveWithTwoDecimalOnly(
    //     applyTax(withDiscountSubtotal, taxInState)
    // );

    // let taxAmount = saveWithTwoDecimalOnly(
    //     (withDiscountSubtotal * Number(taxInState)) / 100
    // );
    let discountAmount = saveWithTwoDecimalOnly(
        (itemsTotal * discountInState) / 100
    );

    let taxRateDisplay = (
        <Input
            type='number'
            value={taxInState}
            onChange={(e) => setTaxInState(e.target.value)}
            errorMessage={sdpoObj.sdpoTaxRateError}></Input>
    );

    let discountRateDisplay = (
        <Input
            type='number'
            value={discountInState}
            onChange={(e) => setDiscountInState(e.target.value)}
            errorMessage={sdpoObj.sdpoDiscountRateError}></Input>
    );

    if (purpose === 'VIEW' || purpose === 'DELETE') {
        taxRateDisplay = <span>{taxInState} </span>;
        discountRateDisplay = <span>{discountInState} </span>;
    }

    let amountBox = (
        <>
            <div className='desktop-view'></div>
            <div className='amount-box'>
                <div>Totals (Gross):</div>
                <div>{displayMoney(itemsTotal)}</div>
                <div className='rate-input'>
                    <span className='m-right-15'>Discount:</span>
                    <span>{discountRateDisplay}</span>
                    <span className='m-left-10'>%</span>
                </div>
                <div>{displayMoney(discountAmount)}</div>
                <div>Totals (Net):</div>
                <div>{displayMoney(withDiscountSubtotal)}</div>
                {/* <div className='rate-input'>
                    <span className='m-right-15'>Tax:</span>
                    <span>{taxRateDisplay}</span>
                    <span className='m-left-10'>%</span>
                </div>
                <div>{displayMoney(taxAmount)}</div>
                <div>Total with Tax:</div>{' '}
                <div className='text-bold'>{displayMoney(total)}</div> */}
            </div>
        </>
    );

    return amountBox;
};
