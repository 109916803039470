import React from 'react';
import './Spinner.css';

export default (props)=> {

  return(
      <div className="spinner-wrapper">
        <div className="spinner"></div>
      </div>

  );
}