import React, { useState } from 'react';
import './Nav.css';

const Nav = (props) => {
    let { authUser, logoutFn, profileFn } = props;

    let [showDropdown, setShowDropdown] = useState(false);

    const toggleDropDownFn = () => {
        setShowDropdown(!showDropdown);
    };

    const profileLocalFn = () => {
        toggleDropDownFn();
        profileFn();
    };
    const logoutLocalFn = () => {
        toggleDropDownFn();
        logoutFn();
    };

    let cover = (
        <div className='profile-overlay' onClick={toggleDropDownFn}></div>
    );

    let dropDown = (
        <div className='drop-menu'>
            <div className='drop-items' onClick={profileLocalFn}>
                <i
                    className='m-lr-15 ms-Icon ms-Icon--Contact'
                    aria-hidden='true'
                ></i>
                <span>Profile</span>
            </div>
            <div className='drop-items' onClick={logoutLocalFn}>
                <i
                    className='m-lr-15 ms-Icon ms-Icon--SignOut'
                    aria-hidden='true'
                ></i>
                <span>Logout</span>
            </div>
        </div>
    );

    if (!showDropdown) {
        cover = null;
        dropDown = null;
    }

    return (
        <div className='navbar'>
            <div className='page-title'>{props.title}</div>

            <div
                className='profile-button-container'
                onClick={toggleDropDownFn}
            >
                <div className='profile-button' onClick={toggleDropDownFn}>
                    Hello, {authUser.displayName || authUser.email}
                </div>

                {dropDown}
            </div>
            {cover}

            <div className='hamburger' onClick={props.toggleSideNavFn}>
                <i
                    className='ms-Icon ms-Icon--GlobalNavButton'
                    aria-hidden='true'
                ></i>
            </div>
        </div>
    );
};

export default Nav;
