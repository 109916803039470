import React from 'react';
import { HelpTip } from '../../components';
import tooltips from '../../lib/tooltips';
import '../PagesCommon.css';
import './ProductionDeliveredPage.css';
import { displayReadableNumber } from '../../lib/util';

const GroupedRM = (props) => {
    let { rmArr, sfgUID } = props;

    let rms = rmArr.map((rm) => {
        return (
            <div
                className='span-2-columns-container'
                key={`${sfgUID}${rm.rmUID}`}>
                <div className='view-table-row'>
                    <div className='cell'>
                        <div className='width16'>
                            <span className='mobile-label'>Grp:</span>{' '}
                            <span className='desktop-view m-left-15'>
                                {' '}
                                {rm.group}
                            </span>
                            <span className='mobile-label'> {rm.group}</span>
                        </div>
                        <div className=''>
                            <span className='mobile-label'>RM:</span>{' '}
                            <span> {rm.rmUID}</span>
                        </div>
                    </div>
                    <div className='cell'>
                        <div className='width50'>
                            {' '}
                            <span className='mobile-label'>
                                Actual Qty (Used):
                            </span>{' '}
                            <span>
                                {' '}
                                {displayReadableNumber(
                                    rm.rmUsedQtyForThisSfgProduced
                                )}
                            </span>
                        </div>
                        <div className='width50'>
                            {' '}
                            <span className='mobile-label'>Unit:</span>{' '}
                            <span>{rm.rmUnit}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    return <div className='span-2-columns-container'>{rms}</div>;
};

export default (props) => {
    let { sfgObj, sfgIndex } = props;

    let groupedRmMap = {};

    sfgObj.rmArr.forEach((rm, rmIndex) => {
        rm.tempRmIndex = rmIndex;
        if (groupedRmMap[rm.group] === undefined) {
            groupedRmMap[rm.group] = [];
            groupedRmMap[rm.group].push(rm);
        } else groupedRmMap[rm.group].push(rm);
    });

    let listOfRms = [];

    for (let gkey in groupedRmMap) {
        let component = (
            <GroupedRM
                key={`${sfgObj.sfgUID}${gkey}`}
                sfgUID={sfgObj.sfgUID}
                sfgIndex={sfgIndex}
                rmArr={groupedRmMap[gkey]}
            />
        );
        listOfRms.push(component);
    }

    return (
        <div
            className='span-2-columns-container show-border-darker pad-around-10 m-bottom-10'
            key={`${sfgObj.sfgUID}${sfgIndex}`}>
            <div className='view-table-row bg-grey-l2 pad-around-10'>
                <div className='cell'>
                    <span className='m-right-5'>{`${sfgIndex + 1}. `}</span>
                    <span>{sfgObj.sfgUID}</span>
                </div>
                <div className='cell'>
                    <div className='width50'>
                        <span className='font-size-13 text-bold m-right-5'>
                            Produced Qty:
                        </span>{' '}
                        <span>
                            <span>
                                {displayReadableNumber(
                                    sfgObj.sfgProducedQtyForThisProduction
                                )}{' '}
                            </span>
                            <HelpTip
                                content={tooltips.prodOrderSfgProducedQty}
                            />
                        </span>
                    </div>
                    <div className='width50'>
                        <span className='font-size-13 text-bold m-right-5'>
                            Unit:
                        </span>{' '}
                        <span>
                            <span>{sfgObj.sfgUnit}</span>
                        </span>
                    </div>
                </div>
            </div>

            <div className='span-2-columns-container show-border m-top-10 pad-around-10'>
                <div className='view-table-heading'>
                    <div className='cell'>
                        <span className='width16'>
                            <span className='m-left-15'>Grp</span>
                        </span>
                        <span>RM Items</span>
                    </div>
                    <div className='cell'>
                        <span className='width50'>
                            Actual Qty (Used){' '}
                            <HelpTip
                                content={tooltips.prodOrderRmActualUsedQty}
                            />
                        </span>
                        <span className='width50'>Unit</span>
                    </div>
                </div>
                {listOfRms}
            </div>
        </div>
    );
};
