import { db, firebase } from '../firebase_config/firebaseConfig';
import { userActions } from '../lib/constants';
import { responseFn, formatCustomErrorObj } from '../lib/util';
import { docNames, appMessage } from '../lib/messages';
import validate from '../validators';
import mListHelper from './mListHelper';
import dbopsGetDocument from './dbopsGetDocument';
import supplierObjToMListItem from '../lib/masterListConverters/supplierObjToMListItem';

import {
    SUPPLIERS_COL,
    MASTER_LISTS_COL,
    MASTER_SUPPLIERS_LIST_DOC,
    DELETED_SUPPLIER_COL,
} from '../lib/constants';

// ================================================
// function to fetch each supplier document.
const getSupplierFn = async (docID) =>
    dbopsGetDocument(SUPPLIERS_COL, docID, docNames.SUPPLIER);

// ======================================================
const createSupplierFn = async (userCreds, payload) => {
    // add metadata to payload to be created in firestore.
    payload.meta = {
        action: userActions.CREATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // add metaHistory
    payload.metaHistory = [];

    let newSupplierRef = db.collection(SUPPLIERS_COL).doc(payload.supplierUID);

    let suppliersListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_SUPPLIERS_LIST_DOC);

    const transactionFn = async (transaction) => {
        let newSupplierDoc = await transaction.get(newSupplierRef);
        let suppliersListDoc = await transaction.get(suppliersListRef);

        // create new supplier document
        if (newSupplierDoc.exists) {
            return Promise.reject(
                formatCustomErrorObj(
                    1002,
                    appMessage.alreadyExist(docNames.SUPPLIER)
                )
            );
        }
        await transaction.set(newSupplierRef, payload);

        // create or update suppliers list document in (master lists collection)
        if (suppliersListDoc.exists) {
            let supplierInfoObj = supplierObjToMListItem(payload);

            let newData = mListHelper.updateMasterList(
                suppliersListDoc,
                payload.supplierUID,
                supplierInfoObj
            );

            await transaction.set(suppliersListRef, newData);
        } else {
            let supplierInfoObj = supplierObjToMListItem(payload);

            let newData = mListHelper.addMasterList(
                payload.supplierUID,
                supplierInfoObj
            );

            await transaction.set(suppliersListRef, newData);
        }
        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyCreated(docNames.SUPPLIER)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorCreating(docNames.SUPPLIER)
        );
    }
};

// ===============================================
const updateSupplierFn = async (userCreds, payload, documentBasis) => {
    // move old metadate into metahistory. ensure meta data history length
    // is 100 or less. will be checked by security rules.
    if (payload.metaHistory.length > 99) payload.metaHistory.shift();

    payload.metaHistory.push(payload.meta);

    // update metadata in payload.
    payload.meta = {
        action: userActions.UPDATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // use supplierUID as docuid
    let supplierRef = db.collection(SUPPLIERS_COL).doc(payload.supplierUID);

    let suppliersListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_SUPPLIERS_LIST_DOC);

    const transactionFn = async (transaction) => {
        let supplierDoc = await transaction.get(supplierRef);

        if (!supplierDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.SUPPLIER)
                )
            );

        // if there is a change in supplier document from the time
        // it was read prior to editting and now (transaction.get),
        // throw an error.
        if (!validate.noChange(supplierDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.SUPPLIER)
                )
            );
        }

        let suppliersListDoc = await transaction.get(suppliersListRef);

        let supplierInfoObj = supplierObjToMListItem(payload);

        let newData = mListHelper.updateMasterList(
            suppliersListDoc,
            payload.supplierUID,
            supplierInfoObj
        );

        await transaction.set(suppliersListRef, newData);

        await transaction.set(supplierRef, payload);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyUpdated(docNames.SUPPLIER)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorUpdating(docNames.SUPPLIER)
        );
    }
};

// ================================================================
const deleteSupplierFn = async (userCreds, payload, documentBasis) => {
    if (payload.metaHistory.length > 99) payload.metaHistory.shift();

    payload.metaHistory.push(payload.meta);

    payload.meta = {
        action: userActions.DELETED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // use supplierUID. see updateSupplierFn
    let supplierRef = db.collection(SUPPLIERS_COL).doc(payload.supplierUID);

    let suppliersListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_SUPPLIERS_LIST_DOC);

    let deletedSupplierRef = db.collection(DELETED_SUPPLIER_COL).doc();

    const transactionFn = async (transaction) => {
        let supplierDoc = await transaction.get(supplierRef);

        if (!supplierDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotDeleteBecauseDocNotExist(docNames.SUPPLIER)
                )
            );

        // if there is a change in supplier document from the time
        // it was read prior to deleting and now (transaction.get),
        // throw an error.
        if (!validate.noChange(supplierDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotDeleteBecauseDocChanged(docNames.SUPPLIER)
                )
            );
        }

        let suppliersListDoc = await transaction.get(suppliersListRef);
        await transaction.get(deletedSupplierRef);

        let newData = mListHelper.deletePropertyFromMasterList(
            suppliersListDoc,
            payload.supplierUID
        );

        await transaction.delete(supplierRef);
        await transaction.set(suppliersListRef, newData);
        await transaction.set(deletedSupplierRef, payload);
        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyDeleted(docNames.SUPPLIER)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorDeleting(docNames.SUPPLIER)
        );
    }
};

export default {
    getSupplierFn,
    createSupplierFn,
    updateSupplierFn,
    deleteSupplierFn,
};
