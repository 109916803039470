import React, { useState, createContext } from 'react';

export const AuthContext = createContext();

export default (props) => {
    const [authUser, setAuthUser] = useState({
        permissions: [],
        initializeApp: false,
    });

    return (
        <AuthContext.Provider value={{ authUser, setAuthUser }}>
            {props.children}
        </AuthContext.Provider>
    );
};
