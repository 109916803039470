import React, { useState, useEffect, useContext } from 'react';
import {
    Datalist,
    AddButton,
    Input,
    CreateItemButtons,
    UpdateItemButtons,
    DeleteItemButtons,
    BackOnlyButton,
    BackSendEditDelButtons,
    ApproveRejectButtons,
    HelpTip,
    TextArea,
    SignatureLine,
    Watermark,
} from '../../components';
import {
    AuthContext,
    MasterListContext,
    DataShareContext,
} from '../../contexts';
import { permissions, userActions, lengths } from '../../lib/constants';
import { dbopsPDPOs } from '../../firebase_dbops';
import { clone, displayDate, displayReadableNumber } from '../../lib/util';
import AddedPOForm from './AddedPOForm';

import validate from '../../validators';
import tooltips from '../../lib/tooltips';

import '../PagesCommon.css';
import './PDDRsPage.css';

let defaultPDDR = {
    pddrUID: '',
    pddrNumber: '',
    supplierUID: '',
    pdpoArr: [],
    meta: { action: '' },
    note: '',
};

export default (props) => {
    let {
        purpose,
        currentDocument,

        backCancelBtnFn,
        gotoEditViewFn,
        gotoDeleteViewFn,

        createDrFn,
        deleteDrFn,
        updateDrFn,
        statusChangeFn,

        setIsLoading,
        setAlertErrorMsg,
    } = props;

    let { masterPurchaseOrdersList } = useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);
    let { serverDate } = useContext(DataShareContext);

    let [generalError, setGeneralError] = useState('');

    let [
        datalistDisplayResetCounter,
        setDatalistDisplayResetCounter,
    ] = useState(1);

    // state used for purpose === 'ADD'
    let [pddrUIDinState, setPddrUIDinState] = useState('');
    let [pddrObj, setPDDRObj] = useState(defaultPDDR);
    let [pdpoUIDinState, setPdpoUIDinState] = useState('');
    let [pddrDateInState, setPddrDateInState] = useState(serverDate);
    let [noteInState, setNoteInState] = useState('');

    let [preview, setPreview] = useState(false);
    let [addPOError, setAddPOError] = useState('');

    // // let [currentPdpoDocsArr, setCurrentPdpoDocsArr] = useState([]);

    // // //! use of this state is a bug fix for deleting PO during EDIT.
    // // let [deletedPdpoDocsArr, setDeletedPdpoDocsArr] = useState([]);

    let [confirmDeleteText, setConfirmDeleteText] = useState('');

    // using the currentDocument.  fetch from firebase all the PO documents
    // and integrate with a COPY of currentDocument before setting as pddrObj.
    // Important info is in the poObjects in the pdpoArr.

    //! This needs to be set setCurrentPdpoDocsArr
    const getPos = async () => {
        setIsLoading(true);
        let tempPoArr = currentDocument.pdpoArr.map(async (po) => {
            return await dbopsPDPOs.getPoFn(po.pdpoUID);
        });
        try {
            tempPoArr = await Promise.all(tempPoArr);
        } catch (e) {
            console.error(
                'POs have been Cleaned up by system already. DR was never APPROVED. Edge case. Action for this DR is only to change status.  Cannot edit or delete.'
            );
            setAlertErrorMsg(e.message || 'Error getting document');
            setIsLoading(true);
            return;
        }
        tempPoArr = tempPoArr.map((dbpoObj) => dbpoObj.data);

        // // setCurrentPdpoDocsArr(tempPoArr);

        let pddrCopy = {
            ...currentDocument,
            pdpoArr: clone(currentDocument.pdpoArr),
        };

        pddrCopy.pdpoArr.forEach((po, poIndex) => {
            po.rmArr.forEach((rm, rmIndex) => {
                rm.rmOrderedQty =
                    tempPoArr[poIndex].rmArr[rmIndex].rmOrderedQty;
                rm.rmBalanceQty =
                    tempPoArr[poIndex].rmArr[rmIndex].rmBalanceQty;
                rm.rmOverrideQty =
                    tempPoArr[poIndex].rmArr[rmIndex].rmOverrideQty;
            });
        });
        setPDDRObj(pddrCopy);
        setPddrUIDinState(pddrCopy.pddrUID);
        setIsLoading(false);

        //! return tempPoArr as the currentPdpoDocsArr to
        //! be used for APPROVED status change.
        return tempPoArr;
    };

    // set pddrObj from currentDocument if purpose !== 'ADD'
    useEffect(() => {
        if (purpose === 'VIEW' || purpose === 'DELETE') {
            // making a copy so no side effect will change currentDocument.
            let currentDocumentCopy = {
                // will copy meta and metaHistory as is.
                ...currentDocument,
                // copy pdpoArr and not a reference.
                pdpoArr: JSON.parse(JSON.stringify(currentDocument.pdpoArr)),
            };
            setPDDRObj(currentDocumentCopy);
            setPddrUIDinState(currentDocumentCopy.pddrUID);
            setPddrDateInState(currentDocumentCopy.pddrDate);
            setNoteInState(currentDocumentCopy.note || '');
        }

        if (purpose === 'EDIT') {
            getPos();
        }
    }, [purpose]);

    const cancelBtnFromEditViewFn = () => {
        // 1. reset values.
        setPDDRObj(currentDocument);

        // 2. reset errorMessages.
        setGeneralError('');

        // 3. go back to profile view.
        backCancelBtnFn();
    };

    // =====================================================

    const addToPdpoArrFn = async () => {
        // clean all error messages
        delete pddrObj.supplierUIDError;
        delete pddrObj.pddrUIDError;
        delete pddrObj.pdpoArrError;
        delete pddrObj.noteError;

        pddrObj.pdpoArr.forEach((poObj) => {
            poObj.rmArr.forEach((rmObj) => {
                delete rmObj.drDeliveredQtyForRmError;
            });
        });
        setGeneralError('');

        //! every time we add pdpo docs in this pddr, we need to form
        //! a currentPdpoDocsArr that will serve as document Basis in transactions
        //! to check for any changes (data integrity) before applying any changes
        //! to the balanceQty and deliveredQty in the rmArr of the PDPO documents.

        let pddrObjCopy = {
            ...pddrObj,
            pdpoArr: JSON.parse(JSON.stringify(pddrObj.pdpoArr)),
        };

        // get the supplier of the pdpo from master list.
        let supplierChosen = masterPurchaseOrdersList.filter((po) => {
            return po.pdpoUID === pdpoUIDinState;
        })[0].supplierUID;

        // determine which supplier the dr came from using the first pdpo
        // being attempted to be added.
        if (pddrObjCopy.pdpoArr.length === 0)
            pddrObjCopy.supplierUID = supplierChosen;
        // pddrObj already has a supplierUID;
        else {
            // if pdpo being added into the pdpoArr does
            // not have the same supplier as the first pdpoObj already in the
            // pdpoArr, do not allow it to be added.
            // // //! this should never run as datalist options will be restricted to show only
            // // //! pdpos from the same supplier.
            if (supplierChosen !== pddrObjCopy.supplierUID) {
                setAddPOError(
                    'The PO you are attempting to add does not have the same supplier already in this DR.'
                );
                return;
            }
        }

        //! need to get from firebase.
        setIsLoading(true);

        let response = await dbopsPDPOs.getPoFn(pdpoUIDinState);
        if (response.error) {
            setAddPOError(response.errorObj.message || response.message);
            setIsLoading(false);

            return;
        }

        // ===========================================================================
        // make a copy to ensure it is not referencing the same objects in the
        // currentPdpoDocsArr during editing. meta will be copied but it is ok, it will
        // not be edited.
        let pdpoCopy = {
            ...response.data,
            rmArr: clone(response.data.rmArr),
        };

        // // let rmMap = {};
        // // if (purpose === 'EDIT') {
        // //     //! if poObj was removed from array and then added again, check currentDocument for
        // //     //! the original values and integrate into pdpoArr. without this logic, drDeliveredQty
        // //     //! will be set to zero.
        // //     let pdpoUIDsInOrigPddr = currentDocument.pdpoArr.map(
        // //         (po) => po.pdpoUID
        // //     );

        // //     if (pdpoUIDsInOrigPddr.includes(pdpoCopy.pdpoUID)) {
        // //         let poIndex = pdpoUIDsInOrigPddr.indexOf(pdpoCopy.pdpoUID);
        // //         let poObj = currentDocument.pdpoArr[poIndex];
        // //         poObj.rmArr.forEach((rm) => {
        // //             rmMap[rm.rmUID] = rm;
        // //         });
        // //     }

        // //     //! =============================================================================
        // // }

        pdpoCopy.rmArr.forEach((rm) => {
            // each rmObj in rmArr must have drDeliveredQtyForRm. this property is used
            // by the HTML input element as value. get existing values if present. if not
            // set to zero just like in creating a new DR.
            // // if (purpose === 'ADD') {
            rm.drDeliveredQtyForRm = 0;
            rm.drPrevDeliveredQtyForRm = 0;
            // // }

            // // if (purpose === 'EDIT') {
            // //     rm.drDeliveredQtyForRm =
            // //         (rmMap[rm.rmUID] && rmMap[rm.rmUID].drDeliveredQtyForRm) ||
            // //         0;
            // //     rm.drPrevDeliveredQtyForRm = rm.drDeliveredQtyForRm;
            // // }
        });

        pddrObjCopy.pdpoArr.push(pdpoCopy);

        // // let pdpoCopyForArr = {
        // //     ...response.data,
        // //     rmArr: clone(response.data.rmArr),
        // // };
        // // let arrCopy = [...currentPdpoDocsArr];
        // // arrCopy.push(pdpoCopyForArr);

        // // //! regardless if purpose is EDIT or ADD, find po in deletedPoArrDocs and remove
        // // //! if present. // this deletedPdpoDocsArrCopy is irrelevant for Creating Pddr
        // // //! but is crucial for EDIT of pddr. needs to be sent to dbopsPddr during EDIT.
        // // let deletedPdpoDocsArrCopy = deletedPdpoDocsArr.filter((po) => {
        // //     return po.pdpoUID !== response.data.pdpoUID;
        // // });
        // // // test deletedPdpoDocsArrCopy if it is being removed during Adding of PO.
        // // // console.log(deletedPdpoDocsArrCopy);
        // // //!===========================================================================

        // // // Testing if copies are not referencing each other.
        // // // pdpoCopyForArr.rmArr[0].rmUID = 'changed';
        // // // console.log(pdpoCopy);
        // // // console.log(pdpoCopyForArr);

        // // // ============================================================================

        // // setDeletedPdpoDocsArr(deletedPdpoDocsArrCopy);
        // // setCurrentPdpoDocsArr(arrCopy);
        setPDDRObj(pddrObjCopy);

        setAddPOError('');
        setDatalistDisplayResetCounter(datalistDisplayResetCounter + 1);
        setIsLoading(false);
    };

    const removePdpoFn = (index) => {
        // clean all error messages
        delete pddrObj.supplierUIDError;
        delete pddrObj.pddrUIDError;
        delete pddrObj.pdpoArrError;
        delete pddrObj.noteError;

        pddrObj.pdpoArr.forEach((poObj) => {
            poObj.rmArr.forEach((rmObj) => {
                delete rmObj.drDeliveredQtyForRmError;
            });
        });
        setGeneralError('');

        let pddrObjCopy = {
            ...pddrObj,
            pdpoArr: JSON.parse(JSON.stringify(pddrObj.pdpoArr)),
        };
        // // let currentPdpoDocsArrCopy = [...currentPdpoDocsArr];

        // // let deletedPdpoDocsArrCopy = [...deletedPdpoDocsArr];
        pddrObjCopy.pdpoArr.splice(index, 1);
        // // let deletedPdpo = currentPdpoDocsArrCopy.splice(index, 1)[0];
        // // deletedPdpoDocsArrCopy.push(deletedPdpo);

        // // // Test to see if same pdpoObjs are removed from the pddrObj.pdpoArr
        // // // and currentPdpoDocsArr
        // // // console.log(pddrObjCopy.pdpoArr);
        // // // console.log(currentPdpoDocsArrCopy);
        // // // then added in deletedPdpoDocsArr
        // // // console.log(deletedPdpoDocsArrCopy);
        // // //=====================================================================

        if (pddrObjCopy.pdpoArr.length === 0) pddrObjCopy.supplierUID = '';
        setPDDRObj(pddrObjCopy);
        // // setCurrentPdpoDocsArr(currentPdpoDocsArrCopy);
        // // setDeletedPdpoDocsArr(deletedPdpoDocsArrCopy);

        setAddPOError('');
    };

    const updateDeliveredQtyFn = (val, poIndex, rmIndex) => {
        let pddrObjCopy = {
            ...pddrObj,
            pdpoArr: JSON.parse(JSON.stringify(pddrObj.pdpoArr)),
        };
        let rmObj = {
            ...pddrObjCopy.pdpoArr[poIndex].rmArr[rmIndex],
            drDeliveredQtyForRm: val,
        };
        pddrObjCopy.pdpoArr[poIndex].rmArr[rmIndex] = rmObj;
        setPDDRObj(pddrObjCopy);
    };

    // for saving functionality
    const previewDrBtnFn = () => {
        let pddrObjCopy = { ...pddrObj };
        pddrObjCopy.pddrUID = pddrObjCopy.pddrUID || pddrUIDinState;
        pddrObjCopy.pddrDate = pddrDateInState;
        pddrObjCopy.note = noteInState;

        let response = validate.pddr(pddrObjCopy, purpose);
        if (response.error) {
            setPDDRObj(response.errorObj.pddrObj);
            setGeneralError(response.errorObj.pddrObj.supplierUIDError);
            setGeneralError(response.errorObj.pddrObj.pdpoArrError);
            setAlertErrorMsg(response.errorObj.hackError);
        } else {
            // no error
            setPDDRObj(response.data.pddrObj);
            setPreview(true);
        }
    };

    const saveDrFn = () => {
        let pddrObjCopy = { ...pddrObj };
        pddrObjCopy.pddrUID = pddrUIDinState;
        pddrObjCopy.pddrDate = pddrDateInState;
        pddrObjCopy.note = noteInState;

        // check if there is a change in the payload from currentDocument
        if (
            purpose === 'EDIT' &&
            validate.pddrNoChange(pddrObjCopy, currentDocument)
        ) {
            backCancelBtnFn();
            return;
        }

        let response = validate.pddr(pddrObjCopy, purpose);

        if (response.error) {
            setPDDRObj(response.errorObj.pddrObj);
            setGeneralError(response.errorObj.pddrObj.supplierUIDError);
            setGeneralError(response.errorObj.pddrObj.pdpoArrError);
            setAlertErrorMsg(response.errorObj.hackError);
            return;
        }

        setPDDRObj(response.data.pddrObj);

        let validPddrObj = response.data.validPddrObj;

        if (purpose === 'ADD') createDrFn(validPddrObj);
        if (purpose === 'EDIT') updateDrFn(validPddrObj, currentDocument);
        if (purpose === 'DELETE') deleteDrFn(validPddrObj, currentDocument);
    };

    const statusChangeLocalFn = async (userActionPassed) => {
        let pddrObjCopy = { ...pddrObj };
        pddrObjCopy.pddrUID = pddrUIDinState;
        pddrObjCopy.pddrDate = pddrDateInState;
        pddrObjCopy.note = noteInState;

        let response = validate.pddr(pddrObjCopy, purpose);

        if (response.error) {
            setPDDRObj(response.errorObj.pddrObj);
            setGeneralError(response.errorObj.pddrObj.supplierUIDError);
            setGeneralError(response.errorObj.pddrObj.pdpoArrError);
            setAlertErrorMsg(response.errorObj.hackError);
            return;
        }

        let validPddrObj = response.data.validPddrObj;

        if (userActionPassed === userActions.APPROVED) {
            let documentBasisPoArr = await getPos();
            statusChangeFn(
                userActionPassed,
                validPddrObj,
                currentDocument,
                documentBasisPoArr
            );
        } else {
            statusChangeFn(userActionPassed, validPddrObj, currentDocument);
        }
    };

    // conditional render of contents  =============================

    let pdpoArrUI = [];
    let noteUI = null;
    let buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    let mainContent = null;
    let datalist = null;

    let addBtn = <AddButton disabled />;

    if ((purpose === 'ADD' && !preview) || purpose === 'EDIT') {
        let datalistOptions = masterPurchaseOrdersList
            .filter((po) => {
                return (
                    po.status === userActions.SENT_TO_SUPPLIER ||
                    po.status === userActions.OVERRIDDEN
                );
            })
            .map((po) => po.pdpoUID); // just get the pdpoUID (string) and put into the list

        let poAlreadyInTheUI = pddrObj.pdpoArr.map((po) => po.pdpoUID);

        if (
            datalistOptions.includes(pdpoUIDinState) &&
            !poAlreadyInTheUI.includes(pdpoUIDinState)
        )
            addBtn = <AddButton onClick={addToPdpoArrFn} />;

        let datalistLabel = (
            <span>
                Add Purchase Order
                <HelpTip content={tooltips.pddrPoArr}></HelpTip>
            </span>
        );

        datalist = (
            <div className='get-item-from-db-container'>
                <div className='datalist-with-addbtn-container'>
                    <Datalist
                        label={datalistLabel}
                        list={datalistOptions}
                        initialValue={''}
                        getInputValue={(val) => setPdpoUIDinState(val)}
                        errorMessage={addPOError}
                        resetCounter={datalistDisplayResetCounter}></Datalist>
                    {addBtn}
                </div>
            </div>
        );

        for (let index = 0; index < pddrObj.pdpoArr.length; index += 1) {
            let trashIcon = (
                <i
                    className='ms-Icon ms-Icon--Delete clickable-icon'
                    aria-hidden='true'
                    onClick={() => removePdpoFn(index)}></i>
            );

            pdpoArrUI.push(
                <div className='span-2-columns-container' key={index}>
                    <AddedPOForm
                        trashIcon={trashIcon}
                        poIndex={index}
                        pdpoObj={pddrObj.pdpoArr[index]}
                        updateDeliveredQtyFn={updateDeliveredQtyFn}
                    />
                </div>
            );
        }

        let drUID = (
            <Input
                type='text'
                maxLength={lengths.uid}
                onChange={(e) => setPddrUIDinState(e.target.value)}
                value={pddrUIDinState}
                placeholder='Required'
                errorMessage={pddrObj.pddrUIDError}></Input>
        );
        if (purpose === 'EDIT')
            drUID = <p className='fpii-text'>{pddrObj.pddrUID || '---'}</p>;

        noteUI = (
            <div className='span-2-columns-container'>
                <TextArea
                    label={<span className='parent-labels'>Notes</span>}
                    maxLength={lengths.noteLength}
                    value={noteInState}
                    onChange={(e) => setNoteInState(e.target.value)}
                    errorMessage={pddrObj.noteError}
                />
            </div>
        );

        //! Max number of Po in the list
        if (pddrObj.pdpoArr.length >= lengths.pddrPoArrLength) datalist = null;

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Goods Receipt</p>
                    {drUID}
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Status</p>
                    <p className='fpii-text'>{pddrObj.meta.action || '--'}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Supplier</p>
                    <p className='fpii-text'>{pddrObj.supplierUID || '--'}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Date</p>
                    <Input
                        type='date'
                        maxLength='12'
                        onChange={(e) => setPddrDateInState(e.target.value)}
                        value={pddrDateInState}
                        errorMessage={pddrObj.pddrDateError}></Input>
                </div>

                {pdpoArrUI}

                {datalist}

                {noteUI}
            </section>
        );
    }

    if (
        (purpose === 'ADD' && preview) ||
        purpose === 'VIEW' ||
        purpose === 'DELETE'
    ) {
        let rmsOfPo = [];
        let pdpoArrUI = pddrObj.pdpoArr.map((po, index) => {
            rmsOfPo = po.rmArr.map((rm, rmIndex) => {
                return (
                    <div
                        className='span-2-columns-container'
                        key={`${rm.rmUID} ${po.pdpoUID}`}>
                        <div className='view-table-row'>
                            <div className='cell'>
                                <span className='mobile-label m-right-15'>
                                    {`${rmIndex + 1}.`}
                                </span>
                                <span className='desktop-view m-lr-15'>
                                    {`${rmIndex + 1}.`}
                                </span>{' '}
                                <span>{rm.rmUID}</span>
                            </div>

                            <div className='cell'>
                                <div className='width50'>
                                    <span className='mobile-label'>
                                        Delivered Qty:
                                    </span>{' '}
                                    <span>
                                        {displayReadableNumber(
                                            rm.drDeliveredQtyForRm
                                        )}
                                    </span>
                                </div>
                                <div className='width50'>
                                    <span className='mobile-label'>Unit:</span>{' '}
                                    <span>{rm.rmUnit}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });

            return (
                <div
                    className='span-2-columns-container show-border pad-around-10'
                    key={`${po.pdpoUID}`}>
                    <div className='span-2-columns-container'>
                        <span>
                            <span className='parent-labels'>
                                PO {`${index + 1}:`}
                            </span>{' '}
                            {po.pdpoUID}
                        </span>
                    </div>

                    <div className='parent-labels m-top-10 pad-around-5 bg-grey-l2'>
                        RM List
                    </div>

                    <div className='span-2-columns-container'>
                        <div className='view-table-heading'>
                            <div className='cell'>
                                <span className='m-left-15'>
                                    RM Type - Description
                                </span>
                            </div>
                            <div className='cell'>
                                <span className='width50'>Delivered Qty</span>
                                <span className='width50'>Unit</span>
                            </div>
                        </div>
                    </div>

                    {rmsOfPo}
                </div>
            );
        });

        noteUI = (
            <div className='span-2-columns-container hide-on-print'>
                <TextArea
                    label={<span className='parent-labels'>Notes</span>}
                    disabled
                    maxLength={lengths.longTextLength}
                    value={noteInState}
                />
            </div>
        );

        if (
            pddrObj.meta.action === userActions.SENT_FOR_APPROVAL &&
            authUser.permissions.includes(permissions.PDDR_APPROVE)
        ) {
            noteUI = (
                <div className='span-2-columns-container hide-on-print'>
                    <TextArea
                        label={<span className='parent-labels'>Notes</span>}
                        maxLength={lengths.noteLength}
                        value={noteInState}
                        onChange={(e) => setNoteInState(e.target.value)}
                        errorMessage={pddrObj.noteError}
                    />
                </div>
            );
        }

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Goods Receipt</p>
                    <p className='fpii-text'>{pddrObj.pddrUID}</p>
                </div>

                <div className='form-profile-info-items hide-on-print'>
                    <p className='fpii-label'>Status</p>
                    <p className='fpii-text'>{pddrObj.meta.action || '---'}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Supplier</p>
                    <p className='fpii-text'>{pddrObj.supplierUID}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Date</p>
                    <p className='fpii-text'>
                        {pddrObj.pddrDate
                            ? displayDate(pddrObj.pddrDate)
                            : '---'}
                    </p>
                </div>

                <div className='span-2-columns-container label-padding label-highlight'>
                    <label className='parent-labels'>
                        List of Purchase Orders
                    </label>
                </div>

                {pdpoArrUI}

                {noteUI}

                <SignatureLine />
            </section>
        );
    }

    // =========================================================================
    // Button sections for users with PDDR Request permision ===================

    if (purpose === 'ADD') {
        buttonSection = (
            <CreateItemButtons
                createText='Create GR'
                previewText='Preview GR'
                cancelPreviewFn={() => setPreview(false)}
                cancelFn={backCancelBtnFn}
                createFn={saveDrFn}
                previewFn={previewDrBtnFn}
                isPreview={preview}
            />
        );
    }

    if (purpose === 'EDIT') {
        buttonSection = (
            <UpdateItemButtons
                saveFn={saveDrFn}
                cancelFn={cancelBtnFromEditViewFn}
            />
        );
    }

    if (purpose === 'DELETE') {
        buttonSection = (
            <DeleteItemButtons
                cancelFn={backCancelBtnFn}
                deleteFn={saveDrFn}
                confirmeDeleteFn={(e) => setConfirmDeleteText(e.target.value)}
                confirmDeleteTextValue={confirmDeleteText}
                confirmDeleteTextPlaceHolder='Enter GR Number'
                uidValue={pddrObj.pddrUID}
            />
        );
    }

    // ==================================================================
    // Button section for users with PDDR Request permission.
    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.PDDR_REQUEST) &&
        !authUser.permissions.includes(permissions.PDDR_APPROVE)
    ) {
        if (
            pddrObj.meta.action === userActions.CREATED ||
            pddrObj.meta.action === userActions.UPDATED ||
            pddrObj.meta.action === userActions.REJECTED
        ) {
            buttonSection = (
                <BackSendEditDelButtons
                    backBtnFn={backCancelBtnFn}
                    sendForApprovalBtnFn={() =>
                        statusChangeLocalFn(userActions.SENT_FOR_APPROVAL)
                    }
                    editBtnFn={gotoEditViewFn}
                    deleteBtnFn={gotoDeleteViewFn}
                />
            );
        }
    }

    // =========================================================================
    // Button section for users with PDDR Approve permision ===================

    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.PDDR_APPROVE) &&
        !authUser.permissions.includes(permissions.PDDR_REQUEST)
    ) {
        // if document status is SENT_FOR_APPROVAL
        if (pddrObj.meta.action === userActions.SENT_FOR_APPROVAL) {
            buttonSection = (
                <ApproveRejectButtons
                    backBtnFn={backCancelBtnFn}
                    approveBtnFn={() =>
                        statusChangeLocalFn(userActions.APPROVED)
                    }
                    rejectBtnFn={() =>
                        statusChangeLocalFn(userActions.REJECTED)
                    }
                />
            );
        }
    }

    // =========================================================================
    //! button section for users with both PDPO Request and Approve permissions.
    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.PDDR_APPROVE) &&
        authUser.permissions.includes(permissions.PDDR_REQUEST)
    ) {
        if (
            pddrObj.meta.action === userActions.CREATED ||
            pddrObj.meta.action === userActions.UPDATED ||
            pddrObj.meta.action === userActions.REJECTED
        ) {
            buttonSection = (
                <BackSendEditDelButtons
                    backBtnFn={backCancelBtnFn}
                    sendForApprovalBtnFn={() =>
                        statusChangeLocalFn(userActions.SENT_FOR_APPROVAL)
                    }
                    editBtnFn={gotoEditViewFn}
                    deleteBtnFn={gotoDeleteViewFn}
                />
            );
        }

        // if document status is SENT_FOR_APPROVAL
        if (pddrObj.meta.action === userActions.SENT_FOR_APPROVAL) {
            buttonSection = (
                <ApproveRejectButtons
                    backBtnFn={backCancelBtnFn}
                    approveBtnFn={() =>
                        statusChangeLocalFn(userActions.APPROVED)
                    }
                    rejectBtnFn={() =>
                        statusChangeLocalFn(userActions.REJECTED)
                    }
                />
            );
        }
    }

    if (!authUser.subscriptionStatusIsActive) {
        buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    }

    let generalErrorUI = (
        <div className='general-error-message'>{generalError}</div>
    );

    return (
        <div className='common-form'>
            <section className='form-title-section'>Goods Receipt Form</section>

            {mainContent}

            {buttonSection}
            {generalErrorUI}

            <Watermark purpose={purpose} currentDocument={currentDocument} />
        </div>
    );
};
