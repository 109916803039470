/**
 * Sept 1, 2020: Function approvePdsiFn created. this will change status of PDSI to
 * approve and change status of all related DR's (PO's no longer an option)
 * to 'APPROVED - LOCKED'.
 *
 *
 *
 */

import { db, firebase } from '../firebase_config/firebaseConfig';
import { userActions, systemActions } from '../lib/constants';
import { responseFn, formatCustomErrorObj } from '../lib/util';
import dbopsGetDocument from './dbopsGetDocument';
import validate from '../validators';
import mListHelper from './mListHelper';
import { docNames, appMessage } from '../lib/messages';
import pdObjToMListItem from '../lib/masterListConverters/pdObjToMListItem';

import {
    PDSIS_COL,
    MASTER_LISTS_COL,
    MASTER_PDSI_LIST_DOC,
    DELETED_PDSIS_COL,
    MASTER_PDDR_LIST_DOC,
    PDDRS_COL,
} from '../lib/constants';

// ================================================
// function to fetch each supplier document.
const getSiFn = async (docID) => dbopsGetDocument(PDSIS_COL, docID, 'PDSI');

// ======================================================
const createSiFn = async (userCreds, siObj) => {
    // add metadata to siObj to be created in firestore.
    siObj.meta = {
        action: userActions.CREATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // add metaHistory
    siObj.metaHistory = [];

    // get references
    let pdsiRef = db.collection(PDSIS_COL).doc(siObj.pdsiUID);
    let pdsiListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_PDSI_LIST_DOC);

    const transactionFn = async (transaction) => {
        // get docs
        let pdsiDoc = await transaction.get(pdsiRef);
        let pdsiListDoc = await transaction.get(pdsiListRef);

        // exist checking.
        if (pdsiDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1002,
                    appMessage.alreadyExist(docNames.PDSI)
                )
            );

        await transaction.set(pdsiRef, siObj);

        // create or update suppliers list document in (master lists collection)
        if (pdsiListDoc.exists) {
            let pdsiInfoObj = pdObjToMListItem(siObj);

            let newData = mListHelper.updateMasterList(
                pdsiListDoc,
                siObj.pdsiUID,
                pdsiInfoObj
            );

            await transaction.set(pdsiListRef, newData);
        } else {
            let pdsiInfoObj = pdObjToMListItem(siObj);

            let newData = mListHelper.addMasterList(siObj.pdsiUID, pdsiInfoObj);

            await transaction.set(pdsiListRef, newData);
        }

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyCreated(docNames.PDSI)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorCreating(docNames.PDSI)
        );
    }
};

// ===============================================
const updateSiFn = async (userCreds, siObj, documentBasis) => {
    if (siObj.metaHistory.length > 99) siObj.metaHistory.shift();

    siObj.metaHistory.push(siObj.meta);
    // add metadata to siObj to be created in firestore.
    siObj.meta = {
        action: userActions.UPDATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // get references
    let pdsiRef = db.collection(PDSIS_COL).doc(siObj.pdsiUID);
    let pdsiListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_PDSI_LIST_DOC);

    const transactionFn = async (transaction) => {
        // get docs
        let pdsiDoc = await transaction.get(pdsiRef);
        let pdsiListDoc = await transaction.get(pdsiListRef);

        // exist checking.
        if (!pdsiDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.PDSI)
                )
            );

        if (!validate.noChange(pdsiDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.PDSI)
                )
            );
        }

        let pdsiInfoObj = pdObjToMListItem(siObj);

        let newData = mListHelper.updateMasterList(
            pdsiListDoc,
            siObj.pdsiUID,
            pdsiInfoObj
        );

        await transaction.set(pdsiListRef, newData);

        await transaction.set(pdsiRef, siObj);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyUpdated(docNames.PDSI)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorUpdating(docNames.PDSI)
        );
    }
};

// ================================================================
const deleteSiFn = async (userCreds, siObj, documentBasis) => {
    if (siObj.metaHistory.length > 99) siObj.metaHistory.shift();

    siObj.metaHistory.push(siObj.meta);

    siObj.meta = {
        action: userActions.DELETED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let pdsiRef = db.collection(PDSIS_COL).doc(siObj.pdsiUID);
    let pdsiListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_PDSI_LIST_DOC);

    let deletedPdsiRef = db.collection(DELETED_PDSIS_COL).doc();

    const transactionFn = async (transaction) => {
        let pdsiDoc = await transaction.get(pdsiRef);

        if (!pdsiDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotDeleteBecauseDocNotExist(docNames.PDSI)
                )
            );

        if (!validate.noChange(pdsiDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotDeleteBecauseDocChanged(docNames.PDSI)
                )
            );
        }

        let pdsiListDoc = await transaction.get(pdsiListRef);
        await transaction.get(deletedPdsiRef);

        let newData = mListHelper.deletePropertyFromMasterList(
            pdsiListDoc,
            siObj.pdsiUID
        );

        await transaction.delete(pdsiRef);
        await transaction.set(pdsiListRef, newData);
        await transaction.set(deletedPdsiRef, siObj);
        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyDeleted(docNames.PDSI)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorDeleting(docNames.PDSI)
        );
    }
};

// ================================================================
const statusChangeFn = async (userCreds, siObj, documentBasis) => {
    if (siObj.metaHistory.length > 99) siObj.metaHistory.shift();

    siObj.metaHistory.push(siObj.meta);

    // update metadata in siObj.
    siObj.meta = {
        action: userCreds.action,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let pdsiRef = db.collection(PDSIS_COL).doc(siObj.pdsiUID);
    let pdsiListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_PDSI_LIST_DOC);

    const transactionFn = async (transaction) => {
        //1. perform all transaction gets
        let pdsiDoc = await transaction.get(pdsiRef);
        let pdsiListDoc = await transaction.get(pdsiListRef);

        //2. check pdsiDoc against documentBasis if there is any change.
        if (!pdsiDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.PDSI)
                )
            );

        // ! this does not check meta data.
        if (!validate.noChange(pdsiDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.PDSI)
                )
            );
        }
        // ! meta data check if status of document changed.
        if (pdsiDoc.data().meta.action !== documentBasis.meta.action) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.PDSI)
                )
            );
        }

        let pdsiInfoObj = pdObjToMListItem(siObj);

        let newData = mListHelper.updateMasterList(
            pdsiListDoc,
            siObj.pdsiUID,
            pdsiInfoObj
        );

        // write to masterlist and pdpo document in firebase.
        await transaction.set(pdsiListRef, newData);
        await transaction.set(pdsiRef, siObj);
        return Promise.resolve('TransactionFn Completed Successfully');
    };
    try {
        await db.runTransaction(transactionFn);
        return responseFn(null, false, null, appMessage.successfulUpdateStatus);
    } catch (e) {
        return responseFn(null, true, e, appMessage.errorUpdatingStatus);
    }
};

const approvePdsiFn = async (userCreds, siObj, documentBasis) => {
    if (siObj.metaHistory.length > 99) siObj.metaHistory.shift();

    siObj.metaHistory.push(siObj.meta);

    // update metadata in siObj.
    siObj.meta = {
        action: userCreds.action,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // get references for documents needed. =========================
    let pdsiRef = db.collection(PDSIS_COL).doc(siObj.pdsiUID);
    let pdsiListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_PDSI_LIST_DOC);
    let pddrListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_PDDR_LIST_DOC);

    let documentBasisDrArrRefs = documentBasis.pddrArr.map((dr) =>
        db.collection(PDDRS_COL).doc(dr.pddrUID)
    );

    const transactionFn = async (transaction) => {
        let existError = false;
        let editedError = false;

        // =============================================================
        // START TRANSACTION GET =================================
        let pdsiDoc = await transaction.get(pdsiRef);
        let pdsiListDoc = await transaction.get(pdsiListRef);
        let pddrListDoc = await transaction.get(pddrListRef);

        let documentBasisDrArrDocs = documentBasisDrArrRefs.map(
            async (drRef) => {
                return await transaction.get(drRef);
            }
        );

        try {
            documentBasisDrArrDocs = await Promise.all(documentBasisDrArrDocs);
        } catch (e) {
            return Promise.reject(
                formatCustomErrorObj(
                    900,
                    appMessage.prommiseAllError(
                        'dbopsPDSIs',
                        'documentBasisDrArr'
                    )
                )
            );
        }

        // END TRANSACTION GET =============================================

        // START CHECK FOR CHANGE AND EXISTENCE =================================
        if (!pdsiDoc.exists) {
            return Promise.reject(
                //! this should never run.
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.PDSI)
                )
            );
        }

        // ! this does not check meta data.
        if (!validate.noChange(pdsiDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.PDSI)
                )
            );
        }
        // ! meta data check if status of document changed.
        if (pdsiDoc.data().meta.action !== documentBasis.meta.action) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.PDSI)
                )
            );
        }

        // check if there are any changes to the DRs involved. only proceed with transaction
        // if pddr status is still APPROVED.
        documentBasisDrArrDocs.forEach((docBasisDr) => {
            if (!docBasisDr.exists) existError = true;

            // ! meta data check if status of document changed. MUST be approved, if not,
            //! it means that dr was marked APPROVED - LOCKED by another PDSI.
            if (docBasisDr.data().meta.action !== userActions.APPROVED) {
                editedError = true;
            }
        });

        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopsPDSIs',
                        docNames.PDSI,
                        'PDDR'
                    )
                )
            );
        }
        if (editedError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,

                    'One or more DRs in this Invoice was already locked'
                )
            );
        }

        // END CHECK FOR CHANGE AND EXISTENCE ==================================

        // START TRANSACTION SET ===============================================
        let tempMasterPddrList = pddrListDoc.data();

        documentBasisDrArrDocs.forEach(async (dr, drIndex) => {
            let drCopy = {
                ...dr.data(),
            };

            // change status of dr object.
            if (drCopy.metaHistory.length > 99) drCopy.metaHistory.shift();

            drCopy.metaHistory.push(drCopy.meta);

            // update metadata in drCopy.
            drCopy.meta = {
                action: systemActions.LOCKED,
                uid: systemActions.UID,
                email: systemActions.EMAIL,
                timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
            };

            // update temporary pdpo master list
            let pddrInfoObj = pdObjToMListItem(drCopy);

            // this variable will be used outside the loop to update
            // the masterList ONCE.
            tempMasterPddrList = mListHelper.updateTempMList(
                tempMasterPddrList,
                drCopy.pddrUID,
                pddrInfoObj
            );

            await transaction.set(documentBasisDrArrRefs[drIndex], drCopy, {
                merge: true,
            });
        });

        // if (pdsiDoc.exists) {
        //     return Promise.reject(
        //         //! this should never run.
        //         formatCustomErrorObj(
        //             1000,
        //             appMessage.cannotEditBecauseDocNotExist(docNames.PDSI)
        //         )
        //     );
        // }

        // After updating all the PDDR's, update the master list of PDDRs
        await transaction.set(pddrListRef, tempMasterPddrList);

        await transaction.set(pdsiRef, siObj);

        let pdsiInfoObj = pdObjToMListItem(siObj);
        let newData = mListHelper.updateMasterList(
            pdsiListDoc,
            siObj.pdsiUID,
            pdsiInfoObj
        );
        await transaction.set(pdsiListRef, newData);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(null, false, null, appMessage.successfulUpdateStatus);
    } catch (e) {
        return responseFn(null, true, e, appMessage.errorUpdatingStatus);
    }
};

export default {
    getSiFn,
    createSiFn,
    updateSiFn,
    deleteSiFn,
    statusChangeFn,
    approvePdsiFn,
};
