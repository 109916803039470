import React, { useState, useContext, useEffect } from 'react';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import { SideNav, Nav, Overlay, Spinner, AlertMessage } from './components';
import {
    LoginPage,
    SuppliersPage,
    Dashboard,
    CustomersPage,
    RawMaterialsPage,
    SemiFGsPage,
    FinshedGoodsPage,
    InventoryRMsPage,
    InventorySFGsPage,
    InventoryFGsPage,
    PDPOsPage,
    PDDRsPage,
    PDSIsPage,
    AssemblyOrdersPage,
    ProdOrdersPage,
    SDPOsPage,
    SDDRsPage,
    SDSIsPage,
    ProfilePage,
    UserManagementPage,
    AppSettingPage,
    ProductionDeliveredPage,
    AssemblyDeliveredPage,
    SetupCCPage,
    SubscriptionInvoicesPage,
    PDVRsPage,
    SDCRsPage,
    ReportsPage,
    InventoryTransferPage,
} from './pages';
import { AuthContext, MasterListContext, DataShareContext } from './contexts';
import { auth, db, htmlTitle } from './firebase_config/firebaseConfig';
import firebaseAuth from './firebase_auth/firebaseAuth';
import {
    MASTER_LISTS_COL,
    USERS_COL,
    MasterListDocs,
    routes,
    str,
    SG_NUMBERS_COL,
    GLOBAL_VARIABLES_DOC,
    SERVER_TIMESTAMP,
    BL_SUBSCRIPTION_DOC,
    SUBSCRIPTION_COL,
    permissions,
} from './lib/constants';
import converter from './lib/masterListConverters';
import { jsDateObjToISODate, serviceShouldStillBeActive } from './lib/util';

import './css/app.css';

// const testAlert = {
//     detailObj1: {
//         mainMessage: 'hello there how are youos',
//         detailsArr: [
//             'dfadsf',
//             'dfadsf',
//             'dfadsf',
//             'dfadsf',
//             'dfadsf',
//             'dfadsf',
//             'dfadsf',
//             'dfadsf',
//             'dfadsf',
//             'dfadsf',
//             'dfadsf',
//             'dfadsf',
//         ],
//     },
//     detailObj2: {
//         mainMessage: 'hello there how are toooo!!!!!',
//         detailsArr: [
//             'dfadsf',
//             'dfadsf',
//             'dfadsf',
//             'dfadsf',
//             'dfadsf',
//             'dfadsf',
//             'dfadsf',
//             'dfadsf',
//             'dfadsf',
//             'dfadsf',
//             'dfadsf',
//             'dfadsf',
//         ],
//     },
// };

const App = (props) => {
    // Contexts ===================================
    let {
        setMasterSuppliersList,
        setMasterCustomersList,
        setMasterRawMaterialsList,
        setMasterSemiFGsList,
        setMasterFinishedGoodsList,
        setMasterInventoryRMsList,
        setMasterInventorySFGsList,
        setMasterInventoryFGsList,
        setMasterPurchaseOrdersList,
        setMasterDeliveryReceiptsList,
        setMasterPDSalesInvoicesList,
        setMasterProdOrdersList,
        setMasterAssemblyList,
        setMasterSdpoList,
        setMasterSddrList,
        setMasterSdsiList,
        setMasterUsersList,
        setMasterProductionDeliveredList,
        setMasterAssemblyDeliveredList,
        setMasterSubscriptionInvoicesList,
        setMasterPdvrList,
        setMasterSdcrList,
        setMasterReportsList,
        setMasterInvTransferList,
    } = useContext(MasterListContext);

    let { authUser, setAuthUser } = useContext(AuthContext);

    let {
        setDefaultTaxRateFromDB,
        setRawMatCategoriesInDB,

        serverDate,
        setServerDate,

        subscriptionEndDate,
        setSubscriptionEndDate,

        // subscriptionStartDate,
        setSubscriptionStartDate,

        // subscriptionCancelledDate,
        setSubscriptionCancelledDate,
    } = useContext(DataShareContext);

    // local state ===========================
    let [alertErrorMsg, setAlertErrorMsg] = useState('');
    let [alertMessage, setAlertMessage] = useState('');
    let [isLoading, setIsLoading] = useState(false);
    let [appClass, setAppClass] = useState('app');

    // Functions to update global state. ===========================
    const updateGlobalState = (listProp, dbdocument) => {
        // console.log(listProp, dbdocument);
        //! redundant check
        if (dbdocument && dbdocument.data) {
            if (listProp === str.SUPPLIERS)
                setMasterSuppliersList(
                    converter.dbSupplierListToAppSupListFn(dbdocument.data)
                );
            if (listProp === str.CUSTOMERS)
                setMasterCustomersList(
                    converter.dbCustomerListToAppCusListFn(dbdocument.data)
                );
            if (listProp === str.RAW_MATERIALS)
                setMasterRawMaterialsList(
                    converter.dbRmListToAppRmListFn(dbdocument.data)
                );
            if (listProp === str.SEMI_FGS)
                setMasterSemiFGsList(
                    converter.dbSfgListToAppSfgListFn(dbdocument.data)
                );
            if (listProp === str.FGS)
                setMasterFinishedGoodsList(
                    converter.dbFgListToAppFgListFn(dbdocument.data)
                );

            if (listProp === str.INV_RMS)
                setMasterInventoryRMsList(
                    converter.dbRmInvListToAppRmInvListFn(dbdocument.data)
                );

            if (listProp === str.INV_SFGS)
                setMasterInventorySFGsList(
                    converter.dbSfgInvListToAppSfgInvListFn(dbdocument.data)
                );
            if (listProp === str.INV_FGS)
                setMasterInventoryFGsList(
                    converter.dbFgInvListToAppFgInvListFn(dbdocument.data)
                );
            if (listProp === str.PDPOS)
                setMasterPurchaseOrdersList(
                    converter.dbPdListToAppPdListFn(dbdocument.data, 'pdpo')
                );
            if (listProp === str.PDDRS)
                setMasterDeliveryReceiptsList(
                    converter.dbPdListToAppPdListFn(dbdocument.data, 'pddr')
                );
            if (listProp === str.PDSIS)
                setMasterPDSalesInvoicesList(
                    converter.dbPdListToAppPdListFn(dbdocument.data, 'pdsi')
                );
            if (listProp === str.PDVRS)
                setMasterPdvrList(
                    converter.dbPdListToAppPdListFn(dbdocument.data, 'pdvr')
                );
            if (listProp === str.PRODUCTION)
                setMasterProdOrdersList(
                    converter.dbOperationListToAppOpListFn(
                        dbdocument.data,
                        'productionUID'
                    )
                );
            if (listProp === str.PRODUCTION_DELIVERED)
                setMasterProductionDeliveredList(
                    converter.dbOperationListToAppOpListFn(
                        dbdocument.data,
                        'productionDeliveredUID'
                    )
                );
            if (listProp === str.ASSEMBLY)
                setMasterAssemblyList(
                    converter.dbOperationListToAppOpListFn(
                        dbdocument.data,
                        'assemblyUID'
                    )
                );
            if (listProp === str.ASSEMBLY_DELIVERED)
                setMasterAssemblyDeliveredList(
                    converter.dbOperationListToAppOpListFn(
                        dbdocument.data,
                        'assemblyDeliveredUID'
                    )
                );
            if (listProp === str.SDPOS)
                setMasterSdpoList(
                    converter.dbSdListToAppSdListFn(dbdocument.data, 'sdpo')
                );
            if (listProp === str.SDDRS)
                setMasterSddrList(
                    converter.dbSdListToAppSdListFn(dbdocument.data, 'sddr')
                );
            if (listProp === str.SDSIS)
                setMasterSdsiList(
                    converter.dbSdListToAppSdListFn(dbdocument.data, 'sdsi')
                );
            if (listProp === str.SDCRS)
                setMasterSdcrList(
                    converter.dbSdListToAppSdListFn(dbdocument.data, 'sdcr')
                );
            if (listProp === str.USERS)
                setMasterUsersList(
                    converter.dbUsersListToAppUsersListFn(dbdocument.data)
                );
            if (listProp === str.SUBSCRIPTION_INVOICES)
                setMasterSubscriptionInvoicesList(
                    converter.dbSubsInvoiceListToAppSubsListFn(dbdocument.data)
                );
            if (listProp === str.REPORTS)
                setMasterReportsList(
                    converter.dbReportsListToAppReportsListFn(dbdocument.data)
                );
            if (listProp === str.INV_TRANSFER)
                setMasterInvTransferList(
                    converter.dbInvTransferToAppInvTransferListFn(
                        dbdocument.data
                    )
                );
        }
    };

    // useEffect to change title of the browser tab.
    useEffect(() => {
        document.title = htmlTitle;
    }, []);

    // useEffect to initialize auth.  =======================
    useEffect(() => {
        //! inactivity signout
        //! window.onbeforeunload = () => auth.signOut();

        let authUnsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user && user.emailVerified) {
                // user is set as authUser obj in context and it is a reference
                // so anychange to user will reflect realtime in the ui.
                user.permissions = [...authUser.permissions];
                user.initializeApp = true;

                //default to false.
                user.subscriptionStatusIsActive = false;

                // console.log('auth State');

                //! check subscriptionStatus.
                const getSubscriptionDates = async () => {
                    let doc = await db
                        .collection(SG_NUMBERS_COL)
                        .doc(SERVER_TIMESTAMP)
                        .get();
                    let serverDate =
                        doc.exists && doc.data().serverTimestamp
                            ? jsDateObjToISODate(
                                  doc.data().serverTimestamp.toDate()
                              )
                            : '2120-01-01';

                    let subscriptionDoc = await db
                        .collection(SUBSCRIPTION_COL)
                        .doc(BL_SUBSCRIPTION_DOC)
                        .get();
                    subscriptionDoc = subscriptionDoc.data();

                    return {
                        endDate: subscriptionDoc.subscriptionEndDate,
                        serverDate,
                    };
                };

                let dates = await getSubscriptionDates();

                if (
                    serviceShouldStillBeActive(
                        new Date(dates.endDate),
                        new Date(dates.serverDate),
                        2
                    )
                ) {
                    user.subscriptionStatusIsActive = true;
                } else {
                    user.subscriptionStatusIsActive = false;
                }

                //! end check subscription ================================

                setAuthUser(user);
            } else if (user && !user.emailVerified) {
                user.sendEmailVerification();
                auth.signOut();
                setAuthUser({ permissions: [], initializeApp: false });
            } else {
                setAuthUser({ permissions: [], initializeApp: false });
            }
        });

        return () => authUnsubscribe();
    }, []);

    // useEffect to get permissions of authorized user.
    // useEffect to get the Global variables from DB.
    // useEffect to get serverTimestamp from DB.
    // useEffect to get subscriptionStatus from DB.
    useEffect(() => {
        let permissionsSubscribe = null;

        if (authUser.uid) {
            permissionsSubscribe = db
                .collection(USERS_COL)
                .doc(authUser.uid)
                .onSnapshot((doc) => {
                    let permissionsArr =
                        doc.exists && Array.isArray(doc.data().permissions)
                            ? doc.data().permissions
                            : [];

                    if (!doc.exists)
                        console.error(
                            'App: Permission document for user was not found.'
                        );

                    let completeUser = {
                        ...authUser,
                        permissions: permissionsArr,
                        permissions_bckup: [...permissionsArr],

                        //! initializeApp property will remain true;
                    };
                    setAuthUser(completeUser);
                });
        }

        let globalVarSubscribe = null;
        if (authUser.uid) {
            globalVarSubscribe = db
                .collection(SG_NUMBERS_COL)
                .doc(GLOBAL_VARIABLES_DOC)
                .onSnapshot((doc) => {
                    let taxRateFromDB =
                        doc.exists && doc.data().defaultTaxRate
                            ? doc.data().defaultTaxRate
                            : 0;

                    let rawMatCategoriesInDB =
                        doc.exists && doc.data().rmCategories
                            ? doc.data().rmCategories
                            : {
                                  0: '',
                                  1: '',
                                  2: '',
                                  3: '',
                                  4: '',
                                  5: '',
                                  6: '',
                                  7: '',
                                  8: '',
                                  9: '',
                              };

                    if (!doc.exists)
                        console.error(
                            'App: Global Variables document for App was not found.'
                        );

                    setDefaultTaxRateFromDB(taxRateFromDB);
                    setRawMatCategoriesInDB(rawMatCategoriesInDB);
                });
        }

        let serverTimestampSubscribe = null;
        if (authUser.uid) {
            serverTimestampSubscribe = db
                .collection(SG_NUMBERS_COL)
                .doc(SERVER_TIMESTAMP)
                .onSnapshot(async (doc) => {
                    let serverDate =
                        doc.exists && doc.data().serverTimestamp
                            ? jsDateObjToISODate(
                                  doc.data().serverTimestamp.toDate()
                              )
                            : '2120-01-01';

                    if (!doc.exists)
                        console.error(
                            'App: ServerTimestamp document for App was not found.'
                        );

                    //! for Testing subscription status.
                    // serverDate = '2020-09-18';
                    setServerDate(serverDate);
                });
        }

        let subscriptionStatusSubscribe = null;
        if (authUser.uid) {
            subscriptionStatusSubscribe = db
                .collection(SUBSCRIPTION_COL)
                .doc(BL_SUBSCRIPTION_DOC)
                .onSnapshot((doc) => {
                    let _subscriptionEndDate =
                        doc.exists && doc.data().subscriptionEndDate !== null
                            ? doc.data().subscriptionEndDate
                            : null;

                    let _subscriptionStartDate =
                        doc.exists && doc.data().subscriptionStartDate !== null
                            ? doc.data().subscriptionStartDate
                            : null; // if something is wrong. do not allow subscription.

                    let _subscriptionCancelledDate =
                        doc.exists &&
                        doc.data().subscriptionCancelledDate !== null
                            ? doc.data().subscriptionCancelledDate
                            : null;

                    if (!doc.exists)
                        console.error(
                            'App: Subscription status for app was not found.'
                        );

                    setSubscriptionEndDate(_subscriptionEndDate);
                    setSubscriptionCancelledDate(_subscriptionCancelledDate);
                    setSubscriptionStartDate(_subscriptionStartDate);
                });
        }

        return () => {
            if (authUser.uid) {
                console.log('unsubscribing permissions listener.');
                permissionsSubscribe();

                console.log(
                    'unsubscribing global variables document listener.'
                );
                globalVarSubscribe();

                console.log(
                    'unsubscribing server timestamp document listener.'
                );
                serverTimestampSubscribe();

                console.log('unsubscribing subscription status listener.');
                subscriptionStatusSubscribe();
            }
        };
    }, [authUser.initializeApp]);

    //! Check for subscription status ====================================
    //! there is a separate check for subscription status on auth change.
    useEffect(() => {
        // default, no access.

        if (serverDate && subscriptionEndDate) {
            if (
                serviceShouldStillBeActive(
                    new Date(subscriptionEndDate),
                    new Date(serverDate),
                    2
                )
            ) {
                let completeUser = {
                    ...authUser,
                    subscriptionStatusIsActive: true,
                };
                setAuthUser(completeUser);
            } else {
                let completeUser = {
                    ...authUser,
                    subscriptionStatusIsActive: false,
                };
                setAuthUser(completeUser);
            }
        }
    }, [serverDate, subscriptionEndDate]);

    //! ==================================================================

    // Initialize the app after logging in.  ==================================
    useEffect(() => {
        let unsubsribe = null;

        if (authUser.uid) {
            unsubsribe = db.collection(MASTER_LISTS_COL).onSnapshot(
                (snapshot) => {
                    snapshot.docChanges().forEach(function (change) {
                        if (
                            change.type === 'added' ||
                            change.type === 'modified'
                        ) {
                            // console.log('added: ', change.doc.id);
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_CUSTOMERS_LIST_DOC
                            )
                                updateGlobalState(
                                    str.CUSTOMERS,
                                    change.doc.data()
                                );
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_SUPPLIERS_LIST_DOC
                            )
                                updateGlobalState(
                                    str.SUPPLIERS,
                                    change.doc.data()
                                );
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_RAW_MATERIALS_LIST_DOC
                            )
                                updateGlobalState(
                                    str.RAW_MATERIALS,
                                    change.doc.data()
                                );
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_SEMI_FGS_LIST_DOC
                            )
                                updateGlobalState(
                                    str.SEMI_FGS,
                                    change.doc.data()
                                );
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_FINISHED_GOODS_LIST_DOC
                            )
                                updateGlobalState(str.FGS, change.doc.data());
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_INVENTORY_RM_LIST_DOC
                            )
                                updateGlobalState(
                                    str.INV_RMS,
                                    change.doc.data()
                                );
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_INVENTORY_SFG_LIST_DOC
                            )
                                updateGlobalState(
                                    str.INV_SFGS,
                                    change.doc.data()
                                );
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_INVENTORY_FG_LIST_DOC
                            )
                                updateGlobalState(
                                    str.INV_FGS,
                                    change.doc.data()
                                );
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_PDPO_LIST_DOC
                            )
                                updateGlobalState(str.PDPOS, change.doc.data());
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_PDDR_LIST_DOC
                            )
                                updateGlobalState(str.PDDRS, change.doc.data());
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_PDSI_LIST_DOC
                            )
                                updateGlobalState(str.PDSIS, change.doc.data());
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_PDVR_LIST_DOC
                            )
                                updateGlobalState(str.PDVRS, change.doc.data());
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_PROD_ORDER_LIST_DOC
                            )
                                updateGlobalState(
                                    str.PRODUCTION,
                                    change.doc.data()
                                );

                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_ASSEMBLY_ORDER_LIST_DOC
                            )
                                updateGlobalState(
                                    str.ASSEMBLY,
                                    change.doc.data()
                                );
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_ASSEMBLY_DELIVERED_LIST_DOC
                            )
                                updateGlobalState(
                                    str.ASSEMBLY_DELIVERED,
                                    change.doc.data()
                                );
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_PRODUCTION_DELIVERED_LIST_DOC
                            )
                                updateGlobalState(
                                    str.PRODUCTION_DELIVERED,
                                    change.doc.data()
                                );
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_SDPO_LIST_DOC
                            )
                                updateGlobalState(str.SDPOS, change.doc.data());
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_SDDR_LIST_DOC
                            )
                                updateGlobalState(str.SDDRS, change.doc.data());
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_SDSI_LIST_DOC
                            )
                                updateGlobalState(str.SDSIS, change.doc.data());
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_SDCR_LIST_DOC
                            )
                                updateGlobalState(str.SDCRS, change.doc.data());
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_USERS_LIST_DOC
                            )
                                updateGlobalState(str.USERS, change.doc.data());
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_SUBSCRIPTION_INVOICES_LIST_DOC
                            )
                                updateGlobalState(
                                    str.SUBSCRIPTION_INVOICES,
                                    change.doc.data()
                                );
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_REPORTS_LIST_DOC
                            )
                                updateGlobalState(
                                    str.REPORTS,
                                    change.doc.data()
                                );
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_INVENTORY_TRANSFER_LIST_DOC
                            )
                                updateGlobalState(
                                    str.INV_TRANSFER,
                                    change.doc.data()
                                );
                        }

                        // this remove conditional should never run.
                        // for safety, if there is a <remove> event,
                        // set the state to empty arrays.
                        if (change.type === 'removed') {
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_CUSTOMERS_LIST_DOC
                            )
                                setMasterCustomersList([]);
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_SUPPLIERS_LIST_DOC
                            )
                                setMasterSuppliersList([]);
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_RAW_MATERIALS_LIST_DOC
                            )
                                setMasterSuppliersList([]);
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_SEMI_FGS_LIST_DOC
                            )
                                setMasterSemiFGsList([]);
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_FINISHED_GOODS_LIST_DOC
                            )
                                setMasterFinishedGoodsList([]);
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_INVENTORY_RM_LIST_DOC
                            )
                                setMasterInventoryRMsList([]);
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_INVENTORY_SFG_LIST_DOC
                            )
                                setMasterInventorySFGsList([]);
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_INVENTORY_FG_LIST_DOC
                            )
                                setMasterInventoryFGsList([]);
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_PDPO_LIST_DOC
                            )
                                setMasterPurchaseOrdersList([]);
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_PDDR_LIST_DOC
                            )
                                setMasterDeliveryReceiptsList([]);
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_PDSI_LIST_DOC
                            )
                                setMasterPDSalesInvoicesList([]);
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_PDVR_LIST_DOC
                            )
                                setMasterPdvrList([]);
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_PROD_ORDER_LIST_DOC
                            )
                                setMasterPurchaseOrdersList([]);
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_ASSEMBLY_ORDER_LIST_DOC
                            )
                                setMasterAssemblyList([]);

                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_ASSEMBLY_DELIVERED_LIST_DOC
                            )
                                setMasterAssemblyDeliveredList([]);
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_PRODUCTION_DELIVERED_LIST_DOC
                            )
                                setMasterProductionDeliveredList([]);

                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_SDPO_LIST_DOC
                            )
                                setMasterSdpoList([]);
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_SDDR_LIST_DOC
                            )
                                setMasterSddrList([]);
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_SDSI_LIST_DOC
                            )
                                setMasterSdsiList([]);
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_SDCR_LIST_DOC
                            )
                                setMasterSdcrList([]);
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_USERS_LIST_DOC
                            )
                                setMasterUsersList([]);
                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_USERS_LIST_DOC
                            )
                                setMasterSubscriptionInvoicesList([]);

                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_REPORTS_LIST_DOC
                            )
                                setMasterReportsList([]);

                            if (
                                change.doc.id ===
                                MasterListDocs.MASTER_INVENTORY_TRANSFER_LIST_DOC
                            )
                                setMasterInvTransferList([]);
                        }
                    });
                },
                (error) => {
                    setAlertErrorMsg('App An error occured with ML listeners.');
                }
            );
        }

        return () => {
            if (authUser.uid) {
                console.log('unsubscribing Master Lists listeners');
                unsubsribe();
            }
        };
    }, [authUser.initializeApp]);

    // Manage Alert messages and error messages
    // AlertErrorMessage is lower in the code and will always
    // execute to override AlertMessage.  This useEffect will
    // overwrite alertError message if there is an in coming alert
    // message.
    useEffect(() => {
        if (alertErrorMsg && alertMessage) {
            setAlertErrorMsg('');
        }
    }, [alertMessage]);

    //! console logs for checking subscription status ======================
    // console.log('server date:', serverDate);
    // console.log('start date:', subscriptionStartDate);
    // console.log('end date:', subscriptionEndDate);
    // console.log('cancelled date:', subscriptionCancelledDate);
    // console.log(
    //     serviceShouldStillBeActive(
    //         new Date(subscriptionEndDate),
    //         new Date(serverDate),
    //         2
    //     )
    // );

    // console.log('Status', authUser.subscriptionStatusIsActive);
    //! ======================================================================

    // ========================================================
    // Functions ==============================================

    const setNavbarTitle = () => {
        let currentLoc = props.history.location.pathname.split('/')[1];
        let locMap = {
            dashboard: 'Dashboard',
            suppliers: 'Setup Suppliers',
            customers: 'Setup Customers',
            rawMaterials: 'Setup Raw Materials',
            semiFGs: 'Setup Semi-Finished Goods',
            finishedGoods: 'Setup Finished Goods',
            inventoryRMs: 'Raw Materials Inventory',
            inventorySFGs: 'Semi-Finished Goods Inventory',
            inventoryFGs: 'Finished Goods Inventory',
            inventoryTransfer: 'Inventory transfer',
            PDPOs: 'Purchase Dept: Purchase Order',
            PDDRs: 'Purchase Dept: Goods Receipt',
            PDSIs: 'Purchase Dept: Supplier Invoice',
            PDVRs: 'Purchase Dept: Voucher Receipt',
            ProductionOrder: 'Operations Dept: SFG Production - Order',
            ProductionDelivered: 'Operations Dept: SFG Production',
            Assembly: 'Operations Dept: FG Assembly - Order',
            AssemblyDelivered: 'Operations Dept: FG Assembly',
            SDPOs: 'Sales Dept: Sales Order',
            SDDRs: 'Sales Dept: Delivery Receipt',
            SDSIs: 'Sales Dept: Customer Invoice',
            SDCRs: 'Sales Dept: Counter Receipt',
            profile: 'User Profile',
            uam: 'User Access Management',
            appSettings: 'Application Settings',
            setupCC: 'Setup Credit Cards',
            invoices: 'Subscription Invoices',
            reports: 'Reports',
        };

        return locMap[currentLoc] || 'Dashboard';
    };

    //! new function for title =====================================

    const toggleSideNavFn = () => {
        if (appClass === 'app') {
            setAppClass('app app-sidenav-slide');
            document.querySelector('body').classList.add('hide-all-overflow');
        } else {
            setAppClass('app');
            document
                .querySelector('body')
                .classList.remove('hide-all-overflow');
        }
    };

    const hideAlertFn = () => {
        setAlertErrorMsg('');
        setAlertMessage('');
    };

    const timedAlertMsg = () => {
        setTimeout(hideAlertFn, 4000);
    };

    const logoutFn = async () => {
        // await will ensure that going back to dashboard will not
        // flicker or show before logout.
        await firebaseAuth.logoutFn();
        props.history.push('/');
    };

    const gotoProfilePageFn = () => {
        props.history.push(routes.PROFILE);
    };

    const permissionAndSubscriptionCheckFn = (permissionArr) => {
        let permitted = false;
        permissionArr.forEach((permission) => {
            if (authUser.permissions.includes(permission)) permitted = true;
        });

        if (!authUser.subscriptionStatusIsActive) permitted = false;

        return permitted;
    };

    // Conditionals for which components to display. ===========================

    let spinnerOverlay = null;
    if (isLoading)
        spinnerOverlay = (
            <Overlay>
                <Spinner />
            </Overlay>
        );

    let sidenavOverlay = null;
    let sideNav = <SideNav logoutFn={logoutFn} />;

    if (appClass.includes('app-sidenav-slide')) {
        sidenavOverlay = <Overlay onClickFn={toggleSideNavFn} />;
        sideNav = (
            <SideNav
                toggleSideNavFn={toggleSideNavFn}
                authUser={authUser}
                logoutFn={logoutFn}
            />
        );
    }

    let appMessage = null;
    if (alertMessage) {
        appMessage = (
            <AlertMessage
                alertMessage={alertMessage}
                hideAlertFn={hideAlertFn}
            />
        );
        timedAlertMsg();
    }
    if (alertErrorMsg) {
        if (typeof alertErrorMsg === 'string') {
            appMessage = (
                <AlertMessage
                    isError
                    alertMessage={alertErrorMsg}
                    hideAlertFn={hideAlertFn}
                />
            );
            timedAlertMsg();
        } else {
            // alertMessage is in Object form {detailObj1: {mainMessage, detailsArr}, detailObj2: {same} }

            appMessage = (
                <AlertMessage
                    isError
                    detailObj1={alertErrorMsg.detailObj1}
                    detailObj2={alertErrorMsg.detailObj2}
                    useDetails={true}
                    hideAlertFn={hideAlertFn}
                />
            );
        }
    }

    let currentYear = serverDate.split('-')[0];

    // router ==========================================
    let appView = (
        <>
            {/* 
                Alert Message outside of App so position fixed is relative to the root
                and not relative to the App element. This way, position fixed will not 
                move when sidebar moves from left to right on mobile.
            */}
            {appMessage}
            <div className={appClass}>
                {spinnerOverlay}
                {sidenavOverlay}

                <div className='sidenav-wrapper'>{sideNav}</div>

                <div className='content'>
                    <Nav
                        toggleSideNavFn={toggleSideNavFn}
                        title={setNavbarTitle()}
                        logoutFn={logoutFn}
                        profileFn={gotoProfilePageFn}
                        authUser={authUser}></Nav>

                    <Switch>
                        <Route
                            exact
                            path={['/', routes.DASHBOARD]}
                            render={(props) => {
                                return <Dashboard></Dashboard>;
                            }}
                        />

                        <Route
                            exact
                            path={routes.PROFILE}
                            render={(props) => {
                                return (
                                    <ProfilePage
                                        setIsLoading={setIsLoading}
                                        setAlertMessage={setAlertMessage}
                                        setAlertErrorMsg={
                                            setAlertErrorMsg
                                        }></ProfilePage>
                                );
                            }}
                        />

                        <Route
                            exact
                            path={routes.SUPPLIERS}
                            render={(props) => {
                                if (
                                    permissionAndSubscriptionCheckFn([
                                        permissions.SUPPLIER_SETUP,
                                    ])
                                ) {
                                    return (
                                        <SuppliersPage
                                            setIsLoading={setIsLoading}
                                            setAlertMessage={setAlertMessage}
                                            setAlertErrorMsg={setAlertErrorMsg}
                                        />
                                    );
                                } else return <Dashboard></Dashboard>;
                            }}
                        />

                        <Route
                            exact
                            path={routes.CUSTOMERS}
                            render={(props) => {
                                if (
                                    permissionAndSubscriptionCheckFn([
                                        permissions.CUSTOMER_SETUP,
                                    ])
                                ) {
                                    return (
                                        <CustomersPage
                                            setIsLoading={setIsLoading}
                                            setAlertMessage={setAlertMessage}
                                            setAlertErrorMsg={setAlertErrorMsg}
                                        />
                                    );
                                } else return <Dashboard></Dashboard>;
                            }}
                        />

                        <Route
                            exact
                            path={routes.RAW_MATERIALS}
                            render={(props) => {
                                if (
                                    permissionAndSubscriptionCheckFn([
                                        permissions.RM_SETUP,
                                    ])
                                ) {
                                    return (
                                        <RawMaterialsPage
                                            setIsLoading={setIsLoading}
                                            setAlertMessage={setAlertMessage}
                                            setAlertErrorMsg={setAlertErrorMsg}
                                        />
                                    );
                                } else return <Dashboard></Dashboard>;
                            }}
                        />

                        <Route
                            exact
                            path={routes.SFGS}
                            render={(props) => {
                                if (
                                    permissionAndSubscriptionCheckFn([
                                        permissions.SFG_SETUP,
                                    ])
                                ) {
                                    return (
                                        <SemiFGsPage
                                            setIsLoading={setIsLoading}
                                            setAlertMessage={setAlertMessage}
                                            setAlertErrorMsg={setAlertErrorMsg}
                                        />
                                    );
                                } else return <Dashboard></Dashboard>;
                            }}
                        />

                        <Route
                            exact
                            path={routes.FINISHED_GOODS}
                            render={(props) => {
                                if (
                                    permissionAndSubscriptionCheckFn([
                                        permissions.FG_SETUP,
                                    ])
                                ) {
                                    return (
                                        <FinshedGoodsPage
                                            setIsLoading={setIsLoading}
                                            setAlertMessage={setAlertMessage}
                                            setAlertErrorMsg={setAlertErrorMsg}
                                        />
                                    );
                                } else return <Dashboard></Dashboard>;
                            }}
                        />

                        <Route
                            exact
                            path={routes.INV_RMS}
                            render={(props) => {
                                if (
                                    permissionAndSubscriptionCheckFn([
                                        permissions.INV_RM,
                                    ])
                                ) {
                                    return (
                                        <InventoryRMsPage
                                            setIsLoading={setIsLoading}
                                            setAlertMessage={setAlertMessage}
                                        />
                                    );
                                } else return <Dashboard></Dashboard>;
                            }}
                        />

                        <Route
                            exact
                            path={routes.INV_SFGS}
                            render={(props) => {
                                if (
                                    permissionAndSubscriptionCheckFn([
                                        permissions.INV_SFG,
                                    ])
                                ) {
                                    return (
                                        <InventorySFGsPage
                                            setIsLoading={setIsLoading}
                                            setAlertMessage={setAlertMessage}
                                        />
                                    );
                                } else return <Dashboard></Dashboard>;
                            }}
                        />

                        <Route
                            exact
                            path={routes.INV_FGS}
                            render={(props) => {
                                if (
                                    permissionAndSubscriptionCheckFn([
                                        permissions.INV_FG,
                                    ])
                                ) {
                                    return (
                                        <InventoryFGsPage
                                            setIsLoading={setIsLoading}
                                            setAlertMessage={setAlertMessage}
                                        />
                                    );
                                } else return <Dashboard></Dashboard>;
                            }}
                        />

                        <Route
                            exact
                            path={routes.INV_TRANSFERS}
                            render={(props) => {
                                if (
                                    permissionAndSubscriptionCheckFn([
                                        permissions.INV_TRANSFER_REQUEST,
                                        permissions.INV_TRANSFER_APPROVE,
                                    ])
                                ) {
                                    return (
                                        <InventoryTransferPage
                                            setIsLoading={setIsLoading}
                                            setAlertMessage={setAlertMessage}
                                            setAlertErrorMsg={setAlertErrorMsg}
                                        />
                                    );
                                } else return <Dashboard></Dashboard>;
                            }}
                        />

                        <Route
                            exact
                            path={routes.PDPOS}
                            render={(props) => {
                                if (
                                    permissionAndSubscriptionCheckFn([
                                        permissions.PDPO_REQUEST,
                                        permissions.PDPO_APPROVE,
                                    ])
                                ) {
                                    return (
                                        <PDPOsPage
                                            setIsLoading={setIsLoading}
                                            setAlertMessage={setAlertMessage}
                                            setAlertErrorMsg={setAlertErrorMsg}
                                        />
                                    );
                                } else return <Dashboard></Dashboard>;
                            }}
                        />

                        <Route
                            exact
                            path={routes.PDDRS}
                            render={(props) => {
                                if (
                                    permissionAndSubscriptionCheckFn([
                                        permissions.PDDR_REQUEST,
                                        permissions.PDDR_APPROVE,
                                    ])
                                ) {
                                    return (
                                        <PDDRsPage
                                            setIsLoading={setIsLoading}
                                            setAlertMessage={setAlertMessage}
                                            setAlertErrorMsg={setAlertErrorMsg}
                                        />
                                    );
                                } else return <Dashboard></Dashboard>;
                            }}
                        />

                        <Route
                            exact
                            path={routes.PDSIS}
                            render={(props) => {
                                if (
                                    permissionAndSubscriptionCheckFn([
                                        permissions.PDSI_APPROVE,
                                        permissions.PDSI_REQUEST,
                                    ])
                                ) {
                                    return (
                                        <PDSIsPage
                                            setIsLoading={setIsLoading}
                                            setAlertMessage={setAlertMessage}
                                            setAlertErrorMsg={setAlertErrorMsg}
                                        />
                                    );
                                } else return <Dashboard></Dashboard>;
                            }}
                        />

                        <Route
                            exact
                            path={routes.PDVRS}
                            render={(props) => {
                                if (
                                    permissionAndSubscriptionCheckFn([
                                        permissions.PDVR_APPROVE,
                                        permissions.PDVR_REQUEST,
                                    ])
                                ) {
                                    return (
                                        <PDVRsPage
                                            setIsLoading={setIsLoading}
                                            setAlertMessage={setAlertMessage}
                                            setAlertErrorMsg={setAlertErrorMsg}
                                        />
                                    );
                                } else return <Dashboard></Dashboard>;
                            }}
                        />

                        <Route
                            exact
                            path={routes.PRODUCTION}
                            render={(props) => {
                                if (
                                    permissionAndSubscriptionCheckFn([
                                        permissions.PROD_ORDER_APPROVE,
                                        permissions.PROD_ORDER_REQUEST,
                                    ])
                                ) {
                                    return (
                                        <ProdOrdersPage
                                            setIsLoading={setIsLoading}
                                            setAlertMessage={setAlertMessage}
                                            setAlertErrorMsg={setAlertErrorMsg}
                                        />
                                    );
                                } else return <Dashboard></Dashboard>;
                            }}
                        />

                        <Route
                            exact
                            path={routes.PRODUCTION_DELIVERED}
                            render={(props) => {
                                if (
                                    permissionAndSubscriptionCheckFn([
                                        permissions.PRODUCTION_DELIVERED_APPROVE,
                                        permissions.PRODUCTION_DELIVERED_REQUEST,
                                    ])
                                ) {
                                    return (
                                        <ProductionDeliveredPage
                                            setIsLoading={setIsLoading}
                                            setAlertMessage={setAlertMessage}
                                            setAlertErrorMsg={setAlertErrorMsg}
                                        />
                                    );
                                } else return <Dashboard></Dashboard>;
                            }}
                        />

                        <Route
                            exact
                            path={routes.ASSEMBLY}
                            render={(props) => {
                                if (
                                    permissionAndSubscriptionCheckFn([
                                        permissions.ASSEMBLY_ORDER_APPROVE,
                                        permissions.ASSEMBLY_ORDER_REQUEST,
                                    ])
                                ) {
                                    return (
                                        <AssemblyOrdersPage
                                            setIsLoading={setIsLoading}
                                            setAlertMessage={setAlertMessage}
                                            setAlertErrorMsg={setAlertErrorMsg}
                                        />
                                    );
                                } else return <Dashboard></Dashboard>;
                            }}
                        />

                        <Route
                            exact
                            path={routes.ASSEMBLY_DELIVERED}
                            render={(props) => {
                                if (
                                    permissionAndSubscriptionCheckFn([
                                        permissions.ASSEMBLY_DELIVERED_APPROVE,
                                        permissions.ASSEMBLY_DELIVERED_REQUEST,
                                    ])
                                ) {
                                    return (
                                        <AssemblyDeliveredPage
                                            setIsLoading={setIsLoading}
                                            setAlertMessage={setAlertMessage}
                                            setAlertErrorMsg={setAlertErrorMsg}
                                        />
                                    );
                                } else return <Dashboard></Dashboard>;
                            }}
                        />

                        <Route
                            exact
                            path={routes.SDPOS}
                            render={(props) => {
                                if (
                                    permissionAndSubscriptionCheckFn([
                                        permissions.SDPO_APPROVE,
                                        permissions.SDPO_REQUEST,
                                    ])
                                ) {
                                    return (
                                        <SDPOsPage
                                            setIsLoading={setIsLoading}
                                            setAlertMessage={setAlertMessage}
                                            setAlertErrorMsg={setAlertErrorMsg}
                                        />
                                    );
                                } else return <Dashboard></Dashboard>;
                            }}
                        />

                        <Route
                            exact
                            path={routes.SDDRS}
                            render={(props) => {
                                if (
                                    permissionAndSubscriptionCheckFn([
                                        permissions.SDDR_APPROVE,
                                        permissions.SDDR_REQUEST,
                                    ])
                                ) {
                                    return (
                                        <SDDRsPage
                                            setIsLoading={setIsLoading}
                                            setAlertMessage={setAlertMessage}
                                            setAlertErrorMsg={setAlertErrorMsg}
                                        />
                                    );
                                } else return <Dashboard></Dashboard>;
                            }}
                        />

                        <Route
                            exact
                            path={routes.SDSIS}
                            render={(props) => {
                                if (
                                    permissionAndSubscriptionCheckFn([
                                        permissions.SDSI_APPROVE,
                                        permissions.SDSI_REQUEST,
                                    ])
                                ) {
                                    return (
                                        <SDSIsPage
                                            setIsLoading={setIsLoading}
                                            setAlertMessage={setAlertMessage}
                                            setAlertErrorMsg={setAlertErrorMsg}
                                        />
                                    );
                                } else return <Dashboard></Dashboard>;
                            }}
                        />

                        <Route
                            exact
                            path={routes.SDCRS}
                            render={(props) => {
                                if (
                                    permissionAndSubscriptionCheckFn([
                                        permissions.SDCR_APPROVE,
                                        permissions.SDCR_REQUEST,
                                    ])
                                ) {
                                    return (
                                        <SDCRsPage
                                            setIsLoading={setIsLoading}
                                            setAlertMessage={setAlertMessage}
                                            setAlertErrorMsg={setAlertErrorMsg}
                                        />
                                    );
                                } else return <Dashboard></Dashboard>;
                            }}
                        />

                        <Route
                            exact
                            path={routes.UAM}
                            render={(props) => {
                                if (
                                    permissionAndSubscriptionCheckFn([
                                        permissions.APP_ADMIN,
                                    ])
                                ) {
                                    return (
                                        <UserManagementPage
                                            setIsLoading={setIsLoading}
                                            setAlertMessage={setAlertMessage}
                                            setAlertErrorMsg={setAlertErrorMsg}
                                        />
                                    );
                                } else return <Dashboard></Dashboard>;
                            }}
                        />

                        <Route
                            exact
                            path={routes.APP_SETTINGS}
                            render={(props) => {
                                if (
                                    permissionAndSubscriptionCheckFn([
                                        permissions.APP_ADMIN,
                                    ])
                                ) {
                                    return (
                                        <AppSettingPage
                                            setIsLoading={setIsLoading}
                                            setAlertMessage={setAlertMessage}
                                            setAlertErrorMsg={setAlertErrorMsg}
                                        />
                                    );
                                } else return <Dashboard></Dashboard>;
                            }}
                        />

                        <Route
                            exact
                            path={routes.SETUP_CC}
                            render={(props) => {
                                if (
                                    authUser.permissions.includes(
                                        permissions.OWNER
                                    )
                                ) {
                                    return (
                                        <SetupCCPage
                                            setIsLoading={setIsLoading}
                                            setAlertMessage={setAlertMessage}
                                            setAlertErrorMsg={setAlertErrorMsg}
                                        />
                                    );
                                } else return <Dashboard></Dashboard>;
                            }}
                        />

                        <Route
                            exact
                            path={routes.SUBSCRIPTION_INVOICES}
                            render={(props) => {
                                if (
                                    authUser.permissions.includes(
                                        permissions.OWNER
                                    )
                                ) {
                                    return (
                                        <SubscriptionInvoicesPage
                                            setIsLoading={setIsLoading}
                                            setAlertMessage={setAlertMessage}
                                            setAlertErrorMsg={setAlertErrorMsg}
                                        />
                                    );
                                } else return <Dashboard></Dashboard>;
                            }}
                        />

                        <Route
                            exact
                            path={routes.REPORTS}
                            render={(props) => {
                                if (
                                    permissionAndSubscriptionCheckFn([
                                        permissions.VIEW_REPORTS,
                                    ])
                                ) {
                                    return (
                                        <ReportsPage
                                            setIsLoading={setIsLoading}
                                            setAlertMessage={setAlertMessage}
                                            setAlertErrorMsg={setAlertErrorMsg}
                                        />
                                    );
                                } else return <Dashboard></Dashboard>;
                            }}
                        />

                        <Route
                            render={(props) => {
                                return <Redirect to={routes.DASHBOARD} />;
                            }}
                        />
                    </Switch>

                    <div className='footer'>
                        <span>Business Logic 2020 {`- ${currentYear}`}</span>
                    </div>
                </div>
            </div>
        </>
    );

    if (!authUser.uid) appView = <LoginPage />;

    return appView;
};

export default withRouter(App);
