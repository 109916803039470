import React from 'react';
import { InputDS } from '../../components';

import '../PagesCommon.css';
import './PDDRsPage.css';
import { displayReadableNumber } from '../../lib/util';

export default (props) => {
    let {
        pdpoObj,
        poIndex,

        updateDeliveredQtyFn,

        trashIcon,
    } = props;

    let listOfRms = pdpoObj.rmArr.map((rm, idx) => {
        let overrideInfo = '';
        if (rm.rmOverrideQty !== 0) {
            overrideInfo =
                rm.rmOverrideQty < 0
                    ? `(${displayReadableNumber(rm.rmOverrideQty)})`
                    : `(+${displayReadableNumber(rm.rmOverrideQty)})`;
        }
        return (
            <div className='span-2-columns-container' key={idx}>
                <div className='view-table-row'>
                    <div className='cell'>
                        <span className='mobile-label m-right-15'>
                            {`${idx + 1}.`}
                        </span>
                        <span className='desktop-view m-lr-15'>
                            {`${idx + 1}.`}
                        </span>{' '}
                        <span>{rm.rmUID}</span>
                    </div>

                    <div className='cell'>
                        <div className='width25'>
                            <span className='mobile-label'>Ordered Qty:</span>{' '}
                            <span>
                                {displayReadableNumber(rm.rmOrderedQty)}
                            </span>
                        </div>
                        <div className='width25'>
                            <span className='mobile-label'>Balance Qty:</span>{' '}
                            <span>
                                {displayReadableNumber(rm.rmBalanceQty)}{' '}
                                {overrideInfo}
                            </span>
                        </div>
                        <div className='width25'>
                            <span className='mobile-label'>Unit:</span>{' '}
                            <span>{rm.rmUnit}</span>
                        </div>
                        <div className='width25'>
                            <span className='mobile-label'>Delivered Qty:</span>{' '}
                            <InputDS
                                type='number'
                                initialValue={rm.drDeliveredQtyForRm}
                                getInputValue={(val) =>
                                    updateDeliveredQtyFn(val, poIndex, idx)
                                }
                                errorMessage={
                                    rm.drDeliveredQtyForRmError
                                }></InputDS>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <div className='subform'>
            <div className='subform-label-qtys-section'>
                <div className='label'>
                    {`${poIndex + 1}. ${pdpoObj.pdpoUID}`}
                </div>

                <div className='icon'>{trashIcon}</div>
            </div>

            <div className='subform-list-section'>
                <div className='sls-label sls-item'>
                    <div className='cell'>RM List </div>
                    <div className='cell desktop-view'></div>
                </div>
                <div className='error-message'>{pdpoObj.rmErrorMessage}</div>

                <div className='span-2-columns-container'>
                    <div className='view-table-heading'>
                        <div className='cell'>
                            <span className='m-left-15'>
                                RM Type - Description
                            </span>
                        </div>
                        <div className='cell'>
                            <span className='width25'>Ordered Qty:</span>
                            <span className='width25'>Balance Qty:</span>
                            <span className='width25'>Unit:</span>
                            <span className='width25'>Delivered Qty:</span>
                        </div>
                    </div>
                </div>

                {listOfRms}
            </div>
        </div>
    );
};
