import React from 'react';
import './TextArea.css';

export default (props) => {

  let {key, id, label, disabled, className, maxLength,
    minLength, required, onChange, placeholder, value,
    errorMessage
  } = props;

  return (
    <div className="textarea-wrapper" key={key}>
      <label className="textarea-label" htmlFor={id}>{label}</label>
      <textarea 
        disabled={disabled}
        id={id}
        className={className}
        required={required}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        maxLength={maxLength}
        minLength={minLength}
        ></textarea>
        <span className="textarea-error">{errorMessage}</span>
    </div>
  );
}