import React from 'react';
import { BackOnlyButton, HelpTip } from '../../components';
import { displayMoney, sortReportItemsByAmount } from '../../lib/util';
import ReportItemsEach from './ReportItemsEach';
import tooltips from '../../lib/tooltips';

import './ReportPage.css';

export default ({ backCancelBtnFn, salesReportByItemObj }) => {
    //! Sale By Item ===========================================

    let salesReportByFGItemUI = [];
    let salesReportBySFGItemUI = [];
    let itemTotalUI = [];
    let fgItemSalesArr = [];
    let sfgItemSalesArr = [];
    let salesByFgItemMap = salesReportByItemObj.fgMap;
    let salesBySfgItemMap = salesReportByItemObj.sfgMap;

    for (let key in salesByFgItemMap) {
        let itemObj = {};
        itemObj.itemUID = key;
        itemObj.total = salesByFgItemMap[key];

        fgItemSalesArr.push(itemObj);
    }

    //! Sort in place
    sortReportItemsByAmount(fgItemSalesArr);

    fgItemSalesArr.forEach((item, index) => {
        salesReportByFGItemUI.push(
            <ReportItemsEach
                key={index}
                index={index}
                item={item}
                mobileLabel='Sales Amount'
            />
        );
    });

    for (let key in salesBySfgItemMap) {
        let itemObj = {};
        itemObj.itemUID = key;
        itemObj.total = salesBySfgItemMap[key];

        sfgItemSalesArr.push(itemObj);
    }

    //! Sort in place
    sortReportItemsByAmount(sfgItemSalesArr);

    sfgItemSalesArr.forEach((item, index) => {
        salesReportBySFGItemUI.push(
            <ReportItemsEach
                key={index}
                index={index}
                item={item}
                mobileLabel='Sales Amount'
            />
        );
    });

    itemTotalUI.push(
        <div className='span-2-columns-container' key='accountsReceivableTotal'>
            <div className='view-table-row'>
                <div className='cell'></div>

                <div className='cell show-border pad-around-10 tj-right pad-right-15'>
                    <span className='width50'>
                        <span className='parent-labels'>Total:</span>
                    </span>
                    <span className='width50'>
                        <span className='parent-labels'>
                            {displayMoney(`${salesReportByItemObj.total}`)}
                        </span>
                    </span>
                </div>
            </div>
        </div>
    );

    let fgHeading = null;
    let fgHeadingMobile = null;
    let sfgHeading = null;
    let sfgHeadingMobile = null;

    if (salesReportByFGItemUI.length > 0) {
        fgHeading = (
            <div className='view-table-heading label-padding label-highlight m-bottom-10 m-top-20'>
                <div className='cell'>
                    <span className='m-left-15'>Finished Goods</span>
                </div>

                <div className='cell tj-right pad-right-15'>
                    <span className='width100'>
                        <span className=''>Sales Amount</span>
                    </span>
                </div>
            </div>
        );
        fgHeadingMobile = (
            <div className='mobile-label m-right-15 pad-around-10'>
                Finished Goods
            </div>
        );
    }

    if (salesReportBySFGItemUI.length > 0) {
        sfgHeading = (
            <div className='view-table-heading label-padding label-highlight m-bottom-10 m-top-20'>
                <div className='cell'>
                    <span className='m-left-15'>Semi Finished Goods</span>
                </div>

                <div className='cell tj-right pad-right-15'>
                    <span className='width100'>
                        <span className=''></span>
                    </span>
                </div>
            </div>
        );
        sfgHeadingMobile = (
            <div className='mobile-label m-right-15 pad-around-10'>
                Semi Finished Goods
            </div>
        );
    }

    let helptip = tooltips.reportSalesItem ? (
        <HelpTip content={tooltips.reportSalesItem} />
    ) : null;

    return (
        <section className='form-main-section'>
            <div className='common-form'>
                <section className='form-title-section'>
                    <div className='report-title'>
                        <span>{`Sales Report By Item - ${salesReportByItemObj.reportMonth} ${salesReportByItemObj.reportYear}`}</span>
                        {helptip}
                    </div>
                </section>

                <div className='span-2-columns-container'>
                    {fgHeadingMobile}

                    {fgHeading}
                    {salesReportByFGItemUI}
                    {sfgHeadingMobile}
                    {sfgHeading}
                    {salesReportBySFGItemUI}
                    {itemTotalUI}
                </div>

                <BackOnlyButton backBtnFn={backCancelBtnFn} />

                <div className='watermark'></div>
            </div>
        </section>
    );
};
