import React, { useState, useContext } from 'react';
import { dbopsGetDocument } from '../../firebase_dbops';
import {
    MasterListContext,
    AuthContext,
    DataShareContext,
} from '../../contexts';
import { InputIcon, ListView2, InfoTip } from '../../components';
import SubscriptionInvoiceItem from '../../components/CollectionItems/SubscriptionInvoiceItem/SubscriptionInvoiceItem';
import { functions } from '../../firebase_config/firebaseConfig';
import { SUBSCRIPTION_INVOICES_COL } from '../../lib/constants';
import { appDateString } from '../../lib/util';

import '../PagesCommon.css';
import './SubscriptionInvoicesPage.css';

import SubscriptionInvoicesForm from './SubscriptionInvoicesForm';

const view = {
    LIST_VIEW: 'listView',
    CREATE_SUBSCRIPTION: 'createSub',
    CANCEL_SUBSCRIPTION: 'cancelSub',
    SUBS_INVOICE_VIEW: 'subInvoiceView',
};

export default (props) => {
    let { setIsLoading, setAlertErrorMsg, setAlertMessage } = props;

    let { masterSubscriptionInvoicesList } = useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);
    let {
        subscriptionStartDate,
        serverDate,
        subscriptionEndDate,
        subscriptionCancelledDate,
    } = useContext(DataShareContext);

    let [formView, setFormView] = useState(view.LIST_VIEW);
    let [currentDocument, setCurrentDocument] = useState('');
    let [searchText, setSearchText] = useState('');

    // functions to change views.
    const gotoCreateSuscriptionViewFn = () =>
        setFormView(view.CREATE_SUBSCRIPTION);
    const gotoListViewFn = () => setFormView(view.LIST_VIEW);
    const gotoCancelSubsriptionViewFn = () =>
        setFormView(view.CANCEL_SUBSCRIPTION);
    const gotoInvoiceViewFn = () => setFormView(view.SUBS_INVOICE_VIEW);

    // function to get supplier document.
    const getDocumentFromDBFn = async (docID) => {
        setIsLoading(true);
        let response = await dbopsGetDocument(SUBSCRIPTION_INVOICES_COL, docID);
        if (response.error)
            setAlertErrorMsg(response.errorObj.message || response.message);
        else {
            setCurrentDocument(response.data);
            gotoInvoiceViewFn();
        }
        setIsLoading(false);
    };

    // function to create Subscription
    const createSubscriptionFn = async (subscriptionData) => {
        setIsLoading(true);

        const createSubscriptionCldFn = functions.httpsCallable(
            'createSubscriptionCldFn'
        );
        // write to db.
        let response = null;
        try {
            response = await createSubscriptionCldFn(subscriptionData);
        } catch (e) {
            // catch https error
            setAlertErrorMsg(e.message);
            setIsLoading(false);
            return;
        }

        //! firebase cloudfunctions has a different format in returning responses.
        //! it is always in a data property.
        if (response.data.error) {
            console.error(response.data.errorObj);

            //! Alert message taken out as it only pertains to communicating with stripe server.
            //! Success does not necessarily mean successful subscription.  2020-08-23.
            // setAlertErrorMsg(
            //     (response.data.errorObj.errorInfo &&
            //         response.data.errorObj.errorInfo.message) ||
            //         response.data.message
            // );
            setIsLoading(false);
            gotoListViewFn();

            return;
        } else {
            setAlertMessage(response.data.message);
        }

        gotoListViewFn();
        // spinner off.
        setIsLoading(false);
    };

    // function to delete supplier.
    const cancelSubscriptionFn = async (subscriptionData) => {
        setIsLoading(true);

        const cancelSubscriptionCldFn = functions.httpsCallable(
            'cancelSubscriptionCldFn'
        );
        // write to db.
        let response = null;
        try {
            response = await cancelSubscriptionCldFn(subscriptionData);
        } catch (e) {
            // catch https error
            setAlertErrorMsg(e.message);
            setIsLoading(false);
            return;
        }

        //! firebase cloudfunctions has a different format in returning responses.
        //! it is always in a data property.
        if (response.data.error) {
            console.error(response.data.errorObj);
            setAlertErrorMsg(
                (response.data.errorObj.errorInfo &&
                    response.data.errorObj.errorInfo.message) ||
                    response.data.message
            );
            setIsLoading(false);
            gotoListViewFn();
            return;
        } else {
            setAlertMessage(response.data.message);
        }

        gotoListViewFn();
        // spinner off.
        setIsLoading(false);
    };

    const retryPaymentFn = async (data) => {
        setIsLoading(true);

        const retryPaymentCldFn = functions.httpsCallable('retryPaymentCldFn');
        // write to db.
        let response = null;
        try {
            response = await retryPaymentCldFn(data);
        } catch (e) {
            // catch https error
            setAlertErrorMsg(e.message);
            setIsLoading(false);
            return;
        }

        //! firebase cloudfunctions has a different format in returning responses.
        //! it is always in a data property.
        if (response.data.error) {
            console.error(response.data.errorObj);
            setAlertErrorMsg(
                (response.data.errorObj.errorInfo &&
                    response.data.errorObj.errorInfo.message) ||
                    response.data.message
            );
            setIsLoading(false);
            gotoListViewFn();
            return;
        } else {
            setAlertMessage(response.data.message);
        }

        gotoListViewFn();
        // spinner off.
        setIsLoading(false);
    };

    // filter function =====================================
    let filteredSubscriptionInvoicesList =
        masterSubscriptionInvoicesList.filter((subsInv) => {
            return (
                subsInv.webhookObjUID
                    .toUpperCase()
                    .includes(searchText.toUpperCase()) ||
                subsInv.subscriptionInvoiceUID
                    .toUpperCase()
                    .includes(searchText.toUpperCase()) ||
                subsInv.subscriptionInvoiceBillingCycle
                    .toUpperCase()
                    .includes(searchText.toUpperCase())
            );
        });

    let createSubscriptionBtnUI = (
        <button
            className='btn btn-primary'
            onClick={gotoCreateSuscriptionViewFn}
        >
            Subscribe Form
        </button>
    );

    let cancelSubscriptionBtnUI = (
        <button
            className='btn btn-danger'
            onClick={gotoCancelSubsriptionViewFn}
        >
            Cancel Subscription Form
        </button>
    );

    let primaryButton =
        subscriptionStartDate === null
            ? createSubscriptionBtnUI
            : cancelSubscriptionBtnUI;

    if (
        // Subscription is active and not cancelled yet.
        authUser.subscriptionStatusIsActive &&
        subscriptionCancelledDate === null
    ) {
        primaryButton = cancelSubscriptionBtnUI;
    } else if (
        // Subscription is active but has been cancelled already.
        authUser.subscriptionIsActive &&
        subscriptionCancelledDate !== null
    ) {
        primaryButton = null;
    } else if (
        // Subscription is not Active but the User is subscribed already.
        // this scenario means that subscription was submitted and then payment failed.
        !authUser.subscriptionStatusIsActive &&
        subscriptionStartDate !== null &&
        subscriptionCancelledDate === null
    ) {
        primaryButton = cancelSubscriptionBtnUI;
    } else if (
        // Subscritpion is not Active. Not Subscribed.
        !authUser.subscriptionStatusIsActive &&
        subscriptionCancelledDate === null &&
        subscriptionStartDate === null
    ) {
        primaryButton = createSubscriptionBtnUI;
    } else if (
        // Subscription is not Active and subscription was cancelled already.
        !authUser.subscriptionStatusIsActive &&
        subscriptionCancelledDate !== null &&
        subscriptionStartDate !== null
    ) {
        primaryButton = createSubscriptionBtnUI;
    } else {
        primaryButton = null;
    }

    let subscriptionInfoText = (
        <div className='flex-column'>
            {subscriptionStartDate ? (
                <span>
                    Subscription Start Date:{' '}
                    {`${appDateString(subscriptionStartDate)}`}
                </span>
            ) : null}
            {subscriptionEndDate ? (
                <span className='m-top-10'>
                    Valid through: {`${appDateString(subscriptionEndDate)}`}
                </span>
            ) : null}
            {subscriptionCancelledDate ? (
                <span className='m-top-10'>
                    Subscription Cancelled Date:{' '}
                    {`${appDateString(subscriptionCancelledDate)}`}
                </span>
            ) : null}
        </div>
    );

    let subscriptionInfo = <InfoTip content={subscriptionInfoText} />;

    if (
        subscriptionStartDate === null &&
        subscriptionEndDate === null &&
        subscriptionCancelledDate === null
    )
        subscriptionInfo = null;

    let topSectionContent = (
        <section className='top-section'>
            <InputIcon
                id='suppliers-search-input'
                type='text'
                placeholder='Search'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                icon={
                    <i
                        className='ms-Icon ms-Icon--Search'
                        aria-hidden='true'
                    ></i>
                }
            />
            <div className='subscriptin-button-flow'>
                {subscriptionInfo}
                {primaryButton}
            </div>
        </section>
    );

    let mainSectionContent = (
        <ListView2
            getDocumentFromDBFn={getDocumentFromDBFn}
            list={filteredSubscriptionInvoicesList}
            keyProperty={'webhookObjUID'}
            CollectionItemComponent={SubscriptionInvoiceItem}
        />
    );

    if (formView !== view.LIST_VIEW) topSectionContent = null;

    if (formView === view.CREATE_SUBSCRIPTION) {
        mainSectionContent = (
            <SubscriptionInvoicesForm
                purpose='SUBSCRIBE'
                backCancelBtnFn={gotoListViewFn}
                createSubscriptionFn={createSubscriptionFn}
                setAlertErrorMsg={setAlertErrorMsg}
                setIsLoading={setIsLoading}
            />
        );
    }

    if (formView === view.CANCEL_SUBSCRIPTION) {
        mainSectionContent = (
            <SubscriptionInvoicesForm
                purpose='CANCEL'
                backCancelBtnFn={gotoListViewFn}
                cancelSubscriptionFn={cancelSubscriptionFn}
                setAlertErrorMsg={setAlertErrorMsg}
                setIsLoading={setIsLoading}
            />
        );
    }

    if (formView === view.SUBS_INVOICE_VIEW) {
        mainSectionContent = (
            <SubscriptionInvoicesForm
                purpose='VIEW'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoListViewFn}
                setAlertErrorMsg={setAlertErrorMsg}
                setIsLoading={setIsLoading}
                retryPaymentFn={retryPaymentFn}
            />
        );
    }

    return (
        <div className='content-layout'>
            {topSectionContent}

            <section className='main-section'>{mainSectionContent}</section>
        </div>
    );
};
