import {
    responseFn,
    clone,
    saveWithTwoDecimalOnly,
    saveWithFiveDecimals,
    totalsGrossCalc,
} from '../lib/util';
import { regEx, lengths } from '../lib/constants';
import { generalMessage, appMessage } from '../lib/messages';
import { validateUID } from '../lib/util';

export const pdsi = (siObj) => {
    let pdsi = {
        ...siObj,
        pdsiUID: siObj.pdsiUID.trim(),
        pdsiNumber: siObj.pdsiUID.trim(),
        supplierUID: siObj.supplierUID,
        pdpoArr: clone(siObj.pdpoArr),
        pddrArr: clone(siObj.pddrArr),
    };

    // clear all errors
    delete pdsi.pdsiUIDError;
    delete pdsi.taxRateError;
    delete pdsi.expandedWithholdingTaxRateError;
    delete pdsi.discountError;
    delete pdsi.arrError;
    delete pdsi.supplierError;
    delete pdsi.pdsiDateError;
    delete pdsi.noteError;

    let objError = false;
    let hackError = '';
    let proceedWithDiscountChecking = true;

    // validate pdsiUID
    objError = validateUID(pdsi.pdsiUID).error;
    pdsi.pdsiUIDError = validateUID(pdsi.pdsiUID).errorMessage;

    // validate taxRate
    if (Number(pdsi.taxRate) > 100 || Number(pdsi.taxRate) < 0) {
        proceedWithDiscountChecking = false;
        objError = true;
        pdsi.taxRateError = generalMessage.INVALID_RATE;
    }

    if (isNaN(pdsi.taxRate)) {
        proceedWithDiscountChecking = false;
        objError = true;
        pdsi.taxRateError = generalMessage.INVALID;
    }

    // validate expandedWithholdingTaxRate
    if (
        Number(pdsi.expandedWithholdingTaxRate) > 100 ||
        Number(pdsi.expandedWithholdingTaxRate) < 0
    ) {
        proceedWithDiscountChecking = false;
        objError = true;
        pdsi.expandedWithholdingTaxRateError = generalMessage.INVALID_RATE;
    }

    if (isNaN(pdsi.expandedWithholdingTaxRate)) {
        proceedWithDiscountChecking = false;
        objError = true;
        pdsi.expandedWithholdingTaxRateError = generalMessage.INVALID;
    }

    if (pdsi.pdsiDate === '') {
        objError = true;
        pdsi.pdsiDateError = generalMessage.REQUIRED;
    }

    if (!regEx.date.test(pdsi.pdsiDate)) {
        objError = true;
        pdsi.pdsiDateError = generalMessage.INVALID_DATE;
    }

    if (pdsi.note.length > lengths.noteLength) {
        objError = true;
        pdsi.noteError = generalMessage.INVALID_LENGTH;
    }

    if (pdsi.pddrArr.length === 0 && pdsi.pdpoArr.length === 0) {
        objError = true;
        pdsi.arrError = 'Must have at least one PO or DR';
    }

    // there should always be a supplierUID.
    if (pdsi.supplierUID === '') {
        objError = true;
        pdsi.supplierError = 'Must choose a supplier';
    }

    //! this should not run. ==================
    if (pdsi.pdpoArr.length > 0 && pdsi.pddrArr.length > 0)
        hackError = appMessage.sourceCodeErrorMessage(
            'PDSI: Both DR and PO array has elements.'
        );
    if (pdsi.pddrArr.length > 0 && pdsi.pdpoArr.length > 0)
        hackError = appMessage.sourceCodeErrorMessage(
            'PDSI: Both DR and PO array has elements.'
        );

    if (
        pdsi.pddrArr.length > lengths.pdsiFgOrSfgArrLength ||
        pdsi.pdpoArr.length > lengths.pdsiFgOrSfgArrLength
    ) {
        hackError = appMessage.sourceCodeErrorMessage(
            'PDSI: pdpo or pddr array length error.'
        );
    }

    pdsi.pddrArr.forEach((pddr) => {
        if (pddr.supplierUID !== pdsi.supplierUID) {
            hackError = appMessage.sourceCodeErrorMessage(
                'PDSI: Not all suppliers in the DR documents equal the Supplier Invoice supplier.'
            );
        }
    });

    pdsi.pdpoArr.forEach((pdpo) => {
        if (pdpo.supplierUID !== pdsi.supplierUID) {
            hackError = appMessage.sourceCodeErrorMessage(
                'PDSI: Not all suppliers in the PO documents equal the Supplier Invoice supplier.'
            );
        }
    });

    // validate discount -  only check if there is no error in the tax rate
    // and expandedWitholdingtaxRate.

    let totalsNett = 0;
    if (proceedWithDiscountChecking) {
        //! check with AmountBox Logic

        let grossTotal = 0;
        let subtotalAmount = 0;
        let expandedWithholdingAmount = 0;

        if (pdsi.pdpoArr.length > 0) {
            pdsi.pdpoArr.forEach((po) => {
                po.rmArr.forEach((rm) => {
                    subtotalAmount += saveWithTwoDecimalOnly(
                        rm.rmCost * rm.rmOrderedQty
                    );
                });
            });
        }
        if (pdsi.pddrArr.length > 0) {
            pdsi.pddrArr.forEach((dr) => {
                dr.pdpoArr.forEach((po) => {
                    po.rmArr.forEach((rm) => {
                        subtotalAmount += saveWithTwoDecimalOnly(
                            rm.rmCost * rm.drDeliveredQtyForRm
                        );
                    });
                });
            });
        }

        grossTotal = totalsGrossCalc(subtotalAmount, Number(pdsi.taxRate));

        expandedWithholdingAmount = saveWithTwoDecimalOnly(
            (grossTotal * Number(pdsi.expandedWithholdingTaxRate)) / 100
        );

        if (
            Number(pdsi.discount) >
                subtotalAmount - expandedWithholdingAmount ||
            Number(pdsi.discount) < 0
        ) {
            objError = true;
            pdsi.discountError = generalMessage.INVALID_AMOUNT;
        }

        if (isNaN(pdsi.discount)) {
            objError = true;
            pdsi.discountError = generalMessage.INVALID;
        }

        totalsNett = saveWithTwoDecimalOnly(
            subtotalAmount - expandedWithholdingAmount - Number(pdsi.discount)
        );
    }

    //! ==================================

    // check suppliers of each pdpo and pdsi.

    pdsi.taxRate = saveWithTwoDecimalOnly(pdsi.taxRate);

    if (objError || hackError) {
        let errorObj = {
            pdsiObj: pdsi,
            hackError: hackError,
        };
        return responseFn(null, true, errorObj, 'App: Error with pdsi object.');
    }

    // if there is no error
    let validPdpoArr = pdsi.pdpoArr.map((po) => {
        let validRmArr = po.rmArr.map((rm) => {
            return {
                rmCost: saveWithTwoDecimalOnly(rm.rmCost),
                rmOrderedQty: saveWithFiveDecimals(rm.rmOrderedQty),
                rmUID: rm.rmUID,
                rmUnit: rm.rmUnit,
            };
        });
        return {
            pdpoUID: po.pdpoUID,
            supplierUID: po.supplierUID,
            rmArr: validRmArr,
        };
    });

    let validPddrArr = pdsi.pddrArr.map((dr) => {
        let validPoArr = dr.pdpoArr.map((po) => {
            let validRmArr = po.rmArr.map((rm) => {
                return {
                    rmCost: saveWithTwoDecimalOnly(rm.rmCost),
                    drDeliveredQtyForRm: saveWithFiveDecimals(
                        rm.drDeliveredQtyForRm
                    ),
                    rmUID: rm.rmUID,
                    rmUnit: rm.rmUnit,
                };
            });
            return {
                pdpoUID: po.pdpoUID,
                supplierUID: po.supplierUID,
                rmArr: validRmArr,
            };
        });
        return {
            pddrUID: dr.pddrUID,
            supplierUID: dr.supplierUID,
            pdpoArr: validPoArr,
        };
    });

    pdsi = {
        ...pdsi,
        pdsiUID: pdsi.pdsiUID,
        pdsiNumber: pdsi.pdsiNumber,
        supplierUID: pdsi.supplierUID,
        pdpoArr: validPdpoArr,
        pddrArr: validPddrArr,
        taxRate: saveWithTwoDecimalOnly(pdsi.taxRate),
        pdsiDate: pdsi.pdsiDate,
        note: pdsi.note,
        pdsiTotalsNett: totalsNett,
    };

    let data = {
        pdsiObj: pdsi,
    };
    return responseFn(data, false, {}, 'App: Validation successful.');
};
