import React, { useState, useContext, useEffect } from 'react';
import { dbopsAssembly } from '../../firebase_dbops';
import {
    MasterListContext,
    AuthContext,
    DataShareContext,
} from '../../contexts';
import { InputIcon, ListView2 } from '../../components';
import OperationItem from '../../components/CollectionItems/OperationItem/OperationItem';
import { displayDate } from '../../lib/util';
import { permissions, userActions, lengths } from '../../lib/constants';
import '../PagesCommon.css';
import './AssemblyPage.css';

import AssemblyForm from './AssemblyForm';

const view = {
    LIST_VIEW: 'listView',
    CREATE_ASSEMBLY: 'addAssembly',
    EDIT_ASSEMBLY: 'editAssembly',
    DELETE_ASSEMBLY: 'deleteAssembly',
    ASSEMBLY_PROFILE: 'assemblyProfile',
    OVERRIDE_ASSEMBLY_ORDER: 'overrideAssemblyOrder',
};

export default (props) => {
    let { setIsLoading, setAlertErrorMsg, setAlertMessage } = props;

    let { masterAssemblyList } = useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);
    let { dataShare } = useContext(DataShareContext);

    let [formView, setFormView] = useState(view.LIST_VIEW);
    let [currentDocument, setCurrentDocument] = useState('');
    let [searchText, setSearchText] = useState('');

    //! ==========================================================
    //? Code to handle creating PO from inventory FG.
    useEffect(() => {
        if (dataShare.isActive) {
            setFormView(view.CREATE_ASSEMBLY);
        }
    }, []);
    //! ==========================================================

    // functions to change views.
    const addAssemblyFn = () => setFormView(view.CREATE_ASSEMBLY);
    const gotoListViewFn = () => setFormView(view.LIST_VIEW);
    const gotoEditViewFn = () => setFormView(view.EDIT_ASSEMBLY);
    const gotoDeleteViewFn = () => setFormView(view.DELETE_ASSEMBLY);
    const gotoAssemblyProfileViewFn = () => setFormView(view.ASSEMBLY_PROFILE);
    const gotoOverrideViewFn = () => setFormView(view.OVERRIDE_ASSEMBLY_ORDER);

    // function to get assembly document.
    const getDocumentFromDBFn = async (docID) => {
        setIsLoading(true);
        let response = await dbopsAssembly.getAssemblyFn(docID);
        if (response.error)
            setAlertErrorMsg(response.errorObj.message || response.message);
        else {
            setCurrentDocument(response.data);
            gotoAssemblyProfileViewFn();
        }
        setIsLoading(false);
    };

    // function to create Supplier
    const createAssemblyFn = async (assemblyObj) => {
        // spinner on.
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        // write to db.
        let response = await dbopsAssembly.createAssemblyFn(
            userCreds,
            assemblyObj
        );
        if (response.error) setAlertErrorMsg(response.errorObj.message);
        else setAlertMessage(response.message);

        gotoListViewFn();

        // spinner off.
        setIsLoading(false);
    };

    // function to update assembly.
    const updateAssemblyFn = async (
        assemblyObj,
        documentBasis,
        userActionPassed
    ) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsAssembly.updateAssemblyFn(
            userCreds,
            assemblyObj,
            documentBasis,
            userActionPassed
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);

            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
        } else setAlertMessage(response.message);

        gotoListViewFn();
        await getDocumentFromDBFn(assemblyObj.assemblyUID);

        setIsLoading(false);
    };

    // function to delete assembly.
    const deleteAssemblyFn = async (assemblyObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsAssembly.deleteAssemblyFn(
            userCreds,
            assemblyObj,
            documentBasis
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);

            if (response.errorObj.code === 1001) {
                gotoListViewFn();
                await getDocumentFromDBFn(assemblyObj.assemblyUID);
                setIsLoading(false);
                return;
            }
        } else setAlertMessage(response.message);

        gotoListViewFn();

        setIsLoading(false);
    };

    // function for status change of document.
    const statusChangeFn = async (
        userActionPassed,
        assemblyObj,
        documentBasis
    ) => {
        setIsLoading(true);

        let userCreds = {
            action: userActionPassed,
            uid: authUser.uid,
            email: authUser.email,
        };

        let dbFn = dbopsAssembly.statusChangeFn;
        if (userActionPassed === userActions.APPROVED) {
            dbFn = dbopsAssembly.approveAssemblyOrderFn;
        }

        let response = await dbFn(userCreds, assemblyObj, documentBasis);
        if (response.error) {
            // meaning: response.data contains detailed error obj.
            if (response.data) setAlertErrorMsg(response.data);
            else setAlertErrorMsg(response.errorObj.message);

            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
        } else setAlertMessage(response.message);

        gotoListViewFn();
        await getDocumentFromDBFn(assemblyObj.assemblyUID);

        setIsLoading(false);
    };

    const overrideAssemblyOrderFn = async (assemblyObj, documentBasis) => {
        setIsLoading(true);
        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };
        let response = await dbopsAssembly.overrideAssemblyOrderFn(
            userCreds,
            assemblyObj,
            documentBasis
        );
        if (response.error) {
            // meaning: response.data contains detailed error obj.
            if (response.data) setAlertErrorMsg(response.data);
            else setAlertErrorMsg(response.errorObj.message);

            // if error is because document has already been deleted,
            // go back to list view.
            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
            // if error is because document has since changed, just continue
            // flow of the program.
        } else setAlertMessage(response.message);
        // this get document ensures state in FE state (currentDocument) is updated.
        gotoListViewFn();
        await getDocumentFromDBFn(assemblyObj.assemblyUID);
        setIsLoading(false);
    };

    // filter function =====================================
    let filteredAssemblyList = masterAssemblyList.filter((assembly) => {
        return (
            assembly.assemblyUID
                .toUpperCase()
                .includes(searchText.toUpperCase()) ||
            displayDate(assembly.assemblyOrderDate)
                .toUpperCase()
                .includes(searchText.toUpperCase()) ||
            assembly.status.toUpperCase().includes(searchText.toUpperCase()) ||
            assembly.assemblyDescription
                .toUpperCase()
                .includes(searchText.toUpperCase())
        );
    });

    let addAssemblyBtnUI = (
        <button className='btn btn-primary' onClick={addAssemblyFn}>
            Create Assembly - Order
        </button>
    );
    if (
        !authUser.permissions.includes(permissions.ASSEMBLY_ORDER_REQUEST) ||
        masterAssemblyList.length >= lengths.masterPOSDeptsLimits ||
        !authUser.subscriptionStatusIsActive
    ) {
        addAssemblyBtnUI = null;
    }

    let topSectionContent = (
        <section className='top-section'>
            <InputIcon
                id='suppliers-search-input'
                type='text'
                placeholder='Search'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                icon={
                    <i
                        className='ms-Icon ms-Icon--Search'
                        aria-hidden='true'></i>
                }
            />
            {addAssemblyBtnUI}
        </section>
    );

    let mainSectionContent = (
        <ListView2
            getDocumentFromDBFn={getDocumentFromDBFn}
            list={filteredAssemblyList}
            keyProperty={'assemblyUID'}
            CollectionItemComponent={OperationItem}
        />
    );

    if (formView !== view.LIST_VIEW) topSectionContent = null;

    if (formView === view.CREATE_ASSEMBLY) {
        mainSectionContent = (
            <AssemblyForm
                purpose='ADD'
                backCancelBtnFn={gotoListViewFn}
                createAssemblyFn={createAssemblyFn}
                setIsLoading={setIsLoading}
                setAlertErrorMsg={setAlertErrorMsg}
            />
        );
    }

    if (formView === view.EDIT_ASSEMBLY) {
        mainSectionContent = (
            <AssemblyForm
                purpose='EDIT'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoAssemblyProfileViewFn}
                updateAssemblyFn={updateAssemblyFn}
                setAlertErrorMsg={setAlertErrorMsg}
                setIsLoading={setIsLoading}
            />
        );
    }

    if (formView === view.DELETE_ASSEMBLY) {
        mainSectionContent = (
            <AssemblyForm
                purpose='DELETE'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoAssemblyProfileViewFn}
                deleteAssemblyFn={deleteAssemblyFn}
                setAlertErrorMsg={setAlertErrorMsg}
                setIsLoading={setIsLoading}
            />
        );
    }

    if (formView === view.ASSEMBLY_PROFILE) {
        mainSectionContent = (
            <AssemblyForm
                purpose='VIEW'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoListViewFn}
                gotoEditViewFn={gotoEditViewFn}
                gotoDeleteViewFn={gotoDeleteViewFn}
                gotoOverrideViewFn={gotoOverrideViewFn}
                statusChangeFn={statusChangeFn}
                setIsLoading={setIsLoading}
                setAlertErrorMsg={setAlertErrorMsg}
            />
        );
    }

    if (formView === view.OVERRIDE_ASSEMBLY_ORDER) {
        mainSectionContent = (
            <AssemblyForm
                purpose='OVERRIDE'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoAssemblyProfileViewFn}
                overrideAssemblyOrderFn={overrideAssemblyOrderFn}
                setIsLoading={setIsLoading}
                setAlertErrorMsg={setAlertErrorMsg}
            />
        );
    }

    return (
        <div className='content-layout'>
            {topSectionContent}

            <section className='main-section'>{mainSectionContent}</section>
        </div>
    );
};
