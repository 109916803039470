import React, { useState, useEffect, useContext } from 'react';
import { AuthContext, DataShareContext } from '../../contexts';
import { routes } from '../../lib/constants';
import { db } from '../../firebase_config/firebaseConfig';
import { useHistory } from 'react-router-dom';
import { InfoTip, HelpTip } from '../../components';
import tooltips from '../../lib/tooltips';

import {
    UpdateItemButtons,
    DeleteItemButtons,
    BackOnlyButton,
    Select,
    Input,
} from '../../components';
import {
    appDateShort,
    appDateString,
    displayMoney,
    generateBillingCycleFromWebhookObj,
} from '../../lib/util';

import '../PagesCommon.css';
import './SubscriptionInvoicesPage.css';

export default (props) => {
    let {
        purpose,
        backCancelBtnFn,
        createSubscriptionFn,
        cancelSubscriptionFn,
        setIsLoading,
        setAlertErrorMsg,
        currentDocument,
        retryPaymentFn,
    } = props;

    let { authUser } = useContext(AuthContext);
    let { serverDate } = useContext(DataShareContext);

    const history = useHistory();

    let [defaultPaymentMethod, setDefaultPaymentMethod] = useState(null);
    let [defaultPaymentMethodExp, setDefaultPaymentMethodExp] = useState(null);
    let [subscriptionObj, setSubscriptionObj] = useState({});

    let [showRetryPaymentForm, setShowRetryPaymentForm] = useState(false);

    let [confirmDeleteText, setConfirmDeleteText] = useState('');

    let [coupon, setCoupon] = useState('');

    //Repayment states
    let [chosenPaymentMethod, setChosenPaymentMethod] = useState('');
    let [chosenPaymentMethodError, setChosenPaymentMethodError] = useState('');

    useEffect(() => {
        const getSubscriptionDetails = async () => {
            setIsLoading(true);
            let blSubRef = db
                .collection('Subscription')
                .doc('BusinessLogicSubscription');

            let blSubDoc;

            try {
                blSubDoc = await blSubRef.get();
            } catch (e) {
                setAlertErrorMsg('Error retrieving subsription details');
                setIsLoading(false);

                return;
            }

            let docData = blSubDoc.data();

            //! this should never run
            //! these two is setup during app setup in backend through admin scripts.
            if (
                docData.stripeCustomerDetails.id === '' ||
                docData.stripeSubscriptionPriceID === ''
            ) {
                console.error('Error with customerID and/or priceID');
                setAlertErrorMsg('Error retrieving subsription details');
                setIsLoading(false);

                return;
            }

            setSubscriptionObj(docData);

            // get default paymentMethod ====================

            if (
                docData.stripeCustomerDetails.invoice_settings
                    .default_payment_method
            ) {
                let defaultPM = docData.paymentMethodsArr.filter((pm) => {
                    return (
                        pm.id ===
                        docData.stripeCustomerDetails.invoice_settings
                            .default_payment_method
                    );
                })[0];

                setDefaultPaymentMethod(
                    `${defaultPM.card.brand.toUpperCase()} ...${
                        defaultPM.card.last4
                    }`
                );

                setDefaultPaymentMethodExp(
                    `${defaultPM.card.exp_month} / ${defaultPM.card.exp_year}`
                );
            }

            // end get default paymentMethod ================
            setIsLoading(false);
        };

        getSubscriptionDetails();
    }, []);

    // =====================================================

    const redirectToSetupCCFn = () => history.push(routes.SETUP_CC);

    const primaryBtnFn = async () => {
        if (purpose === 'SUBSCRIBE') {
            let requiredData = {
                customerID: subscriptionObj.stripeCustomerDetails.id,
                priceID: subscriptionObj.stripeSubscriptionPriceID,
                couponID: coupon,
            };

            createSubscriptionFn(requiredData);
        }

        if (purpose === 'CANCEL') {
            let requiredData = {
                subscriptionID: subscriptionObj.subscriptionID,
            };

            cancelSubscriptionFn(requiredData);
        }

        if (purpose === 'VIEW' && !currentDocument.paid) {
            if (!chosenPaymentMethod) {
                setChosenPaymentMethodError('Must choose a credit card');
                return;
            }

            // update default payment method in customer first.

            // if no error.  attempt the payment.

            // if no error
            let data = {
                paymentMethodID: chosenPaymentMethod,
                invoiceID: currentDocument.id,
                customerID: subscriptionObj.stripeCustomerDetails.id,
            };
            retryPaymentFn(data);
        }
    };

    const retryPaymentFormFn = () => {
        let toggle = showRetryPaymentForm ? false : true;
        setShowRetryPaymentForm(toggle);
    };

    // conditional render of contents  =============================
    let buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    let mainContent = null;
    let formTitle = null;
    let today = appDateString(serverDate);
    let billingCycle = (
        <>
            <span>{today}</span>
            <InfoTip content={tooltips.subscriptionBillingCycle} />
        </>
    );
    // let billingCycle = `${appDateShort(today)} - ${appDateShort(
    //     new Date(today.getFullYear(), today.getMonth() + 1, today.getDate())
    // )}`;

    if (purpose === 'SUBSCRIBE') {
        formTitle = 'Subscription Form';
        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>BusinessLogic ID</p>
                    <p className='fpii-text'>{authUser.email}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Date</p>
                    <p className='fpii-text'>{appDateString(serverDate)}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Bill To</p>
                    <p className='fpii-text'>
                        <span className='m-right-10'>
                            {defaultPaymentMethod}
                        </span>
                        <span className='m-left-10'>
                            exp {defaultPaymentMethodExp}
                        </span>
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Use Coupon</p>
                    <Input
                        type='text'
                        maxLength='20'
                        onChange={(e) => setCoupon(e.target.value)}
                        value={coupon}
                        errorMessage={''}
                    ></Input>
                </div>

                <div className='span-2-columns-container label-padding label-highlight'>
                    <label className='parent-labels'>Billing Details</label>
                </div>

                <div className='span-2-columns-container view-table-heading'>
                    <div className='cell'>Description</div>
                    <div className='cell'>
                        <span className='width50'>Billing Date</span>
                        <span className='width50'>Total</span>
                    </div>
                </div>

                <div className='span-2-columns-container view-table-row'>
                    <div className='cell'>
                        {subscriptionObj.subscriptionDescription}
                    </div>
                    <div className='cell'>
                        <span className='width50'>{billingCycle}</span>
                        <span className='width50'>
                            {displayMoney(subscriptionObj.subscriptionPrice)}
                        </span>
                    </div>
                </div>
            </section>
        );
    }

    let appliedCouponDetails = null;
    if (purpose === 'VIEW' && currentDocument && currentDocument.discount) {
        appliedCouponDetails = (
            <div className='form-profile-info-items'>
                <p className='fpii-label'>Coupon Applied</p>
                <p className='fpii-text'>{`(${
                    currentDocument.discount.coupon.id
                }) - ${
                    ((currentDocument.subtotal - currentDocument.total) /
                        currentDocument.subtotal) *
                    100
                }% discount valid through ${appDateShort(
                    new Date(currentDocument.discount.end * 1000)
                )}`}</p>
            </div>
        );
    }

    if (purpose === 'VIEW') {
        let invoiceObj = currentDocument;

        formTitle = 'Subscription Invoice Form';

        let status = invoiceObj.paid
            ? 'Paid'
            : `Payment Failed (${invoiceObj._appAddedproperty_paymentAttempt})`;

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>BusinessLogic ID</p>
                    <p className='fpii-text'>{authUser.email}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Document Date</p>
                    <p className='fpii-text'>
                        {appDateShort(new Date(currentDocument.created * 1000))}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Invoice Number</p>
                    <p className='fpii-text'>
                        {invoiceObj.number || invoiceObj.id}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Status</p>
                    <p className='fpii-text'>{status}</p>
                </div>

                {appliedCouponDetails}

                <div className='span-2-columns-container label-padding label-highlight'>
                    <label className='parent-labels'>Billing Details</label>
                </div>

                <div className='span-2-columns-container view-table-heading'>
                    <div className='cell'>Description</div>
                    <div className='cell'>
                        <span className='width50'>Billing Cycle</span>
                        <span className='width50'>Total</span>
                    </div>
                </div>

                <div className='span-2-columns-container view-table-row'>
                    <div className='cell'>
                        {subscriptionObj.subscriptionDescription}
                    </div>
                    <div className='cell'>
                        <span className='width50'>
                            {generateBillingCycleFromWebhookObj(
                                currentDocument
                            )}
                        </span>
                        <span className='width50'>
                            {displayMoney(currentDocument.total / 100)}
                        </span>
                    </div>
                </div>
            </section>
        );
    }

    if (purpose === 'VIEW' && showRetryPaymentForm) {
        const paymentMethodOptions = subscriptionObj.paymentMethodsArr.map(
            (pm) => {
                return {
                    valueUI: `${pm.card.brand.toUpperCase()} ...${
                        pm.card.last4
                    }`,
                    value: pm.id,
                };
            }
        );

        formTitle = 'Retry Payment Form';

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>BusinessLogic ID</p>
                    <p className='fpii-text'>{authUser.email}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Date</p>
                    <p className='fpii-text'>{appDateString(serverDate)}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Bill To</p>
                    <div className='pad-around-5'>
                        {' '}
                        <Select
                            optionsArr={paymentMethodOptions}
                            value={chosenPaymentMethod}
                            onChange={(e) =>
                                setChosenPaymentMethod(e.target.value)
                            }
                            placeholder='Required'
                            errorMessage={chosenPaymentMethodError}
                        />
                    </div>
                </div>

                {appliedCouponDetails}

                <div className='span-2-columns-container label-padding label-highlight'>
                    <label className='parent-labels'>Billing Details</label>
                </div>

                <div className='span-2-columns-container view-table-heading'>
                    <div className='cell'>Description</div>
                    <div className='cell'>
                        <span className='width50'>Billing Cycle</span>
                        <span className='width50'>Total</span>
                    </div>
                </div>

                <div className='span-2-columns-container view-table-row'>
                    <div className='cell'>
                        {subscriptionObj.subscriptionDescription}
                    </div>
                    <div className='cell'>
                        <span className='width50'>
                            {generateBillingCycleFromWebhookObj(
                                currentDocument
                            )}
                        </span>
                        <span className='width50'>
                            {displayMoney(currentDocument.total / 100)}
                        </span>
                    </div>
                </div>
            </section>
        );
    }

    if (purpose === 'CANCEL') {
        formTitle = 'Subscription Cancellation Form';
        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>BusinessLogic ID</p>
                    <p className='fpii-text'>{authUser.email}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Date</p>
                    <p className='fpii-text'>{appDateString(serverDate)}</p>
                </div>

                <div className='span-2-columns-container label-padding label-highlight'>
                    <label className='parent-labels'>
                        Cancellation Details
                    </label>
                </div>

                <div className='span-2-columns-container view-table-heading'>
                    <div className='cell'>Description</div>
                    <div className='cell'>Service End Date</div>
                </div>

                <div className='span-2-columns-container view-table-row'>
                    <div className='cell'>
                        {subscriptionObj.subscriptionDescription}
                    </div>
                    <div className='cell'>
                        {subscriptionObj.subscriptionEndDate ||
                            appDateString(serverDate)}

                        <div>
                            <HelpTip content='After unsubscribing from the service, application will continue to work upto service end date' />
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    if (purpose === 'SUBSCRIBE') {
        buttonSection = (
            <UpdateItemButtons
                saveText={'Subscribe'}
                saveFn={primaryBtnFn}
                cancelFn={backCancelBtnFn}
            />
        );
    }

    if (purpose === 'CANCEL') {
        buttonSection = (
            <DeleteItemButtons
                deleteBtnText={'Unsubscribe'}
                cancelFn={backCancelBtnFn}
                deleteFn={primaryBtnFn}
                confirmeDeleteFn={(e) => setConfirmDeleteText(e.target.value)}
                confirmDeleteTextValue={confirmDeleteText}
                confirmDeleteTextPlaceHolder='Enter "Unsubscribe"'
                uidValue={'Unsubscribe'}
            />
        );
    }

    if (
        purpose === 'VIEW' &&
        currentDocument &&
        currentDocument.paid === false &&
        !showRetryPaymentForm
    ) {
        buttonSection = (
            <UpdateItemButtons
                saveText={'Retry Payment Form'}
                saveFn={retryPaymentFormFn}
                cancelFn={backCancelBtnFn}
            />
        );
    }

    if (
        purpose === 'VIEW' &&
        currentDocument &&
        currentDocument.paid === false &&
        showRetryPaymentForm
    ) {
        buttonSection = (
            <UpdateItemButtons
                saveText={'Submit Payment'}
                saveFn={primaryBtnFn}
                cancelFn={retryPaymentFormFn}
            />
        );
    }

    // if there is no default payment method.
    if (
        subscriptionObj.stripeCustomerDetails &&
        (subscriptionObj.stripeCustomerDetails.invoice_settings
            .default_payment_method === null ||
            subscriptionObj.stripeCustomerDetails.invoice_settings
                .default_payment_method === '')
    ) {
        buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;

        mainContent = (
            <div className='flex-row'>
                Please{' '}
                <span
                    className='text-link m-lr-5'
                    onClick={redirectToSetupCCFn}
                >
                    Setup Credit Card
                </span>{' '}
                before subscribing. Ensure there is a default Credit Card.
            </div>
        );
    }

    return (
        <div className='common-form'>
            <section className='form-title-section'>{formTitle}</section>

            {mainContent}

            {buttonSection}

            <div className='watermark'></div>
        </div>
    );
};
