import { responseFn } from '../lib/util';
import { regEx, lengths } from '../lib/constants';
import { generalMessage } from '../lib/messages';

export const authUserPermissionsNoChange = (
    permissionsArrCurrent,
    permissionsArrOriginal
) => {
    let noChange = true;

    let permObjCur = {};
    let permObjOrg = {};
    permissionsArrCurrent.forEach((perm) => (permObjCur[perm] = true));
    permissionsArrOriginal.forEach((perm) => (permObjOrg[perm] = true));

    for (let key in permObjCur) {
        if (!permObjOrg[key]) noChange = false;
    }

    if (noChange) {
        for (let key in permObjOrg) {
            if (!permObjCur[key]) noChange = false;
        }
    }

    return noChange;
};

export const authUser = (userObj, purpose) => {
    // userObj already is a copy. no need to make a copy.

    let displayNameError = '';
    let emailError = '';
    let passwordError = '';

    userObj.displayName = userObj.displayName.trim();
    userObj.email = userObj.email.trim();

    if (purpose !== 'DELETE') {
        if (userObj.displayName.length > lengths.displayName)
            displayNameError = generalMessage.INVALID_LENGTH;

        if (!regEx.email.test(userObj.email))
            emailError = generalMessage.INVALID_FORMAT;
        if (userObj.email === '' || userObj.email === ' ')
            emailError = generalMessage.REQUIRED;
        if (userObj.email.length > lengths.email)
            emailError = generalMessage.INVALID_LENGTH;
    }

    if (purpose === 'ADD') {
        if (userObj.password.length > lengths.password)
            passwordError = generalMessage.INVALID_LENGTH;
        if (userObj.password.length < 6)
            passwordError = 'Must be at least 6 characters';
        if (userObj.password === '' || userObj.password === ' ')
            passwordError = generalMessage.REQUIRED;
    }

    if (emailError || passwordError || displayNameError) {
        let errorObj = {
            emailError,
            displayNameError,
            passwordError,
        };
        return responseFn(null, true, errorObj, 'Error detected');
    }

    let valid = {
        userUID: userObj.userUID,
        displayName: userObj.displayName,
        email: userObj.email,
        password: userObj.password,
        permissions: userObj.permissions,
        emailVerified: false,
    };

    if (purpose !== 'ADD') {
        delete valid.password;
        delete valid.emailVerified;
    }

    return responseFn(valid, false, {}, 'no error');
};
