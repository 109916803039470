import React, { useState, useContext } from 'react';
import { dbopsInvTransfer } from '../../firebase_dbops';
import { MasterListContext, AuthContext } from '../../contexts';
import { InputIcon, ListView2 } from '../../components';
import { permissions, lengths, userActions } from '../../lib/constants';
import { displayDate } from '../../lib/util';
import InventoryTransferItem from '../../components/CollectionItems/InventoryTransferItem/InventoryTransferItem';

import '../PagesCommon.css';
import './InventoryTransferPage.css';

import InventoryTransferForm from './InventoryTransferForm';

const view = {
    LIST_VIEW: 'listView',
    CREATE_INV_TRANS: 'createInvTransfer',
    EDIT_INV_TRANS: 'editInvTransfer',
    DELETE_INV_TRANS: 'deleteInvTransfer',
    INV_TRANS_PROFILE: 'invTransferProfile',
};

export default (props) => {
    let { setIsLoading, setAlertErrorMsg, setAlertMessage } = props;

    let { masterInvTranferList } = useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);

    let [formView, setFormView] = useState(view.LIST_VIEW);
    let [currentDocument, setCurrentDocument] = useState('');
    let [searchText, setSearchText] = useState('');

    // functions to change views.
    const createInvTransferBtnFn = () => setFormView(view.CREATE_INV_TRANS);
    const gotoListViewFn = () => setFormView(view.LIST_VIEW);
    const gotoEditViewFn = () => setFormView(view.EDIT_INV_TRANS);
    const gotoDeleteViewFn = () => setFormView(view.DELETE_INV_TRANS);
    const gotoInvTransferProfileViewFn = () =>
        setFormView(view.INV_TRANS_PROFILE);

    // function to get Inventory transfer document.
    const getDocumentFromDBFn = async (docID) => {
        setIsLoading(true);
        let response = await dbopsInvTransfer.getInvTransferFn(docID);
        if (response.error)
            setAlertErrorMsg(response.errorObj.message || response.message);
        else {
            setCurrentDocument(response.data);
            gotoInvTransferProfileViewFn();
        }
        setIsLoading(false);
    };

    // function to create Supplier
    const createInvTransferFn = async (invTransferObj) => {
        // spinner on.
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        // write to db.
        let response = await dbopsInvTransfer.createInvTransferFn(
            userCreds,
            invTransferObj
        );
        if (response.error) setAlertErrorMsg(response.errorObj.message);
        else setAlertMessage(response.message);

        gotoListViewFn();

        // spinner off.
        setIsLoading(false);
    };

    // function to update Inventory transfer.
    const updateInvTransferFn = async (invTransferObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsInvTransfer.updateInvTransferFn(
            userCreds,
            invTransferObj,
            documentBasis
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);
            // if error, it's because document has already been deleted,
            // go back to list view.
            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
            // if error is because document has since changed, just continue
            // flow of the program.
        } else setAlertMessage(response.message);

        // this get document ensures state in FE state (currentDocument) is updated.
        gotoListViewFn();
        await getDocumentFromDBFn(invTransferObj.invTransferUID);

        setIsLoading(false);
    };

    // function to delete Inventory transfer.
    const deleteInvTransferFn = async (invTransferObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsInvTransfer.deleteInvTransferFn(
            userCreds,
            invTransferObj,
            documentBasis
        );

        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);

            // if error is because document has changed before changes
            // could be applied, get latest version of Inventory transfer and
            // go back to Inventory transfer profile view.
            if (response.errorObj.code === 1001) {
                gotoListViewFn();
                await getDocumentFromDBFn(invTransferObj.invTransferUID);
                setIsLoading(false); // redundant
                return;
            }
            // all other errors can continue flow of program.
        } else setAlertMessage(response.message);

        gotoListViewFn();

        setIsLoading(false);
    };

    // function for status change of document.
    const statusChangeFn = async (
        userActionPassed,
        invTransferObj,
        documentBasis
    ) => {
        setIsLoading(true);

        let userCreds = {
            action: userActionPassed,
            uid: authUser.uid,
            email: authUser.email,
        };

        let dbFn = dbopsInvTransfer.statusChangeFn;
        if (userActionPassed === userActions.APPROVED) {
            dbFn = dbopsInvTransfer.approveInvTransferFn;
        }

        let response = await dbFn(userCreds, invTransferObj, documentBasis);
        if (response.error) {
            // meaning: response.data contains detailed error obj.
            if (response.data) setAlertErrorMsg(response.data);
            else setAlertErrorMsg(response.errorObj.message);

            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
        } else setAlertMessage(response.message);

        gotoListViewFn();
        await getDocumentFromDBFn(invTransferObj.invTransferUID);

        setIsLoading(false);
    };

    // filter function =====================================
    let filteredInvTransferList = masterInvTranferList.filter((invTrans) => {
        return (
            invTrans.invTransferUID
                .toUpperCase()
                .includes(searchText.toUpperCase()) ||
            invTrans.subjectUID
                .toUpperCase()
                .includes(searchText.toUpperCase()) ||
            displayDate(invTrans.invTransferDate)
                .toUpperCase()
                .includes(searchText.toUpperCase()) ||
            invTrans.status.toUpperCase().includes(searchText.toUpperCase())
        );
    });

    let createInvTranserBtnUI = (
        <button className='btn btn-primary' onClick={createInvTransferBtnFn}>
            Create Inventory Transfer
        </button>
    );
    if (
        !authUser.permissions.includes(permissions.INV_TRANSFER_REQUEST) ||
        masterInvTranferList.length >= lengths.masterListLimit ||
        !authUser.subscriptionStatusIsActive
    ) {
        createInvTranserBtnUI = null;
    }

    let topSectionContent = (
        <section className='top-section'>
            <InputIcon
                type='text'
                placeholder='Search'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                icon={
                    <i
                        className='ms-Icon ms-Icon--Search'
                        aria-hidden='true'></i>
                }
            />
            {createInvTranserBtnUI}
        </section>
    );

    let mainSectionContent = (
        <ListView2
            getDocumentFromDBFn={getDocumentFromDBFn}
            list={filteredInvTransferList}
            keyProperty={'invTransferUID'}
            CollectionItemComponent={InventoryTransferItem}
        />
    );

    if (formView !== view.LIST_VIEW) topSectionContent = null;

    if (formView === view.CREATE_INV_TRANS) {
        mainSectionContent = (
            <InventoryTransferForm
                purpose='ADD'
                backCancelBtnFn={gotoListViewFn}
                createInvTransferFn={createInvTransferFn}
            />
        );
    }

    if (formView === view.EDIT_INV_TRANS) {
        mainSectionContent = (
            <InventoryTransferForm
                purpose='EDIT'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoInvTransferProfileViewFn}
                updateInvTransferFn={updateInvTransferFn}
            />
        );
    }

    if (formView === view.DELETE_INV_TRANS) {
        mainSectionContent = (
            <InventoryTransferForm
                purpose='DELETE'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoInvTransferProfileViewFn}
                deleteInvTransferFn={deleteInvTransferFn}
            />
        );
    }

    if (formView === view.INV_TRANS_PROFILE) {
        mainSectionContent = (
            <InventoryTransferForm
                purpose='VIEW'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoListViewFn}
                gotoEditViewFn={gotoEditViewFn}
                gotoDeleteViewFn={gotoDeleteViewFn}
                statusChangeFn={statusChangeFn}
            />
        );
    }

    return (
        <div className='content-layout'>
            {topSectionContent}

            <section className='main-section'>{mainSectionContent}</section>
        </div>
    );
};
