import React from 'react';
import { InputDS } from '../../components';

import '../PagesCommon.css';
import './SDDRsPage.css';
import '../PDPOsPage/PDPOsPage.css';
import { displayReadableNumber } from '../../lib/util';

export default (props) => {
    let {
        sdpoObj,
        sdpoIndex,

        updateDeliveredQtyFn,

        trashIcon,
    } = props;

    let listOfSfgs = sdpoObj.sfgArr.map((sfg, idx) => {
        let overrideInfo = '';
        if (sfg.sfgOverrideQty !== 0) {
            overrideInfo =
                sfg.sfgOverrideQty < 0
                    ? `(${displayReadableNumber(sfg.sfgOverrideQty)})`
                    : `(+${displayReadableNumber(sfg.sfgOverrideQty)})`;
        }

        return (
            <div className='span-2-columns-container' key={idx}>
                <div className='view-table-row'>
                    <div className='cell'>
                        <span className='mobile-label m-right-15'>
                            {`${idx + 1}.`}
                        </span>
                        <span className='desktop-view m-lr-15'>
                            {`${idx + 1}.`}
                        </span>{' '}
                        <span>{sfg.sfgUID}</span>
                    </div>

                    <div className='cell'>
                        <div className='width25'>
                            <span className='mobile-label'>Ordered Qty:</span>{' '}
                            <span>
                                {displayReadableNumber(sfg.sfgOrderedQty)}
                            </span>
                        </div>
                        <div className='width25'>
                            <span className='mobile-label'>Balance Qty:</span>{' '}
                            <span>
                                {displayReadableNumber(sfg.sfgBalanceQty)}{' '}
                                {overrideInfo}
                            </span>
                        </div>
                        <div className='width25'>
                            <span className='mobile-label'>Unit:</span>{' '}
                            <span>{sfg.sfgUnit}</span>
                        </div>
                        <div className='width25'>
                            <span className='mobile-label'>Delivered Qty:</span>{' '}
                            <InputDS
                                type='number'
                                initialValue={sfg.sddrDeliveredQtyForSfg}
                                getInputValue={(val) =>
                                    updateDeliveredQtyFn(
                                        val,
                                        sdpoIndex,
                                        'sfgArr',
                                        idx,
                                        'sddrDeliveredQtyForSfg'
                                    )
                                }
                                errorMessage={
                                    sfg.sddrDeliveredQtyForSfgError
                                }></InputDS>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    let listOfFgs = sdpoObj.fgArr.map((fg, idx) => {
        let overrideInfo = '';
        if (fg.fgOverrideQty !== 0) {
            overrideInfo =
                fg.fgOverrideQty < 0
                    ? `(${displayReadableNumber(fg.fgOverrideQty)})`
                    : `(+${displayReadableNumber(fg.fgOverrideQty)})`;
        }

        return (
            <div className='span-2-columns-container' key={idx}>
                <div className='view-table-row'>
                    <div className='cell'>
                        <span className='mobile-label m-right-15'>
                            {`${idx + 1}.`}
                        </span>
                        <span className='desktop-view m-lr-15'>
                            {`${idx + 1}.`}
                        </span>{' '}
                        <span>{fg.fgUID}</span>
                    </div>

                    <div className='cell'>
                        <div className='width25'>
                            <span className='mobile-label'>Ordered Qty:</span>{' '}
                            <span>
                                {displayReadableNumber(fg.fgOrderedQty)}
                            </span>
                        </div>
                        <div className='width25'>
                            <span className='mobile-label'>Balance Qty:</span>{' '}
                            <span>
                                {displayReadableNumber(fg.fgBalanceQty)}{' '}
                                {overrideInfo}
                            </span>
                        </div>

                        <div className='width25'>
                            <span className='mobile-label'>Unit:</span>{' '}
                            <span>{fg.fgUnit}</span>
                        </div>
                        <div className='width25'>
                            <span className='mobile-label'>Delivered Qty:</span>{' '}
                            <InputDS
                                type='number'
                                initialValue={fg.sddrDeliveredQtyForFg}
                                getInputValue={(val) =>
                                    updateDeliveredQtyFn(
                                        val,
                                        sdpoIndex,
                                        'fgArr',
                                        idx,
                                        'sddrDeliveredQtyForFg'
                                    )
                                }
                                errorMessage={
                                    fg.sddrDeliveredQtyForFgError
                                }></InputDS>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    let sfgSection = (
        <div className='subform-list-section'>
            <div className='sls-label sls-item'>
                <div className='cell'>SFG List </div>
                <div className='cell desktop-view'></div>
            </div>
            <div className='error-message'>{sdpoObj.sfgErrorMessage}</div>

            <div className='span-2-columns-container'>
                <div className='view-table-heading'>
                    <div className='cell'>
                        <span className='m-left-15'>SFG Name</span>
                    </div>
                    <div className='cell'>
                        <span className='width25'>Ordered Qty:</span>
                        <span className='width25'>Balance Qty:</span>
                        <span className='width25'>Unit:</span>
                        <span className='width25'>Delivered Qty:</span>
                    </div>
                </div>
            </div>

            {listOfSfgs}
        </div>
    );

    let fgSection = (
        <div className='subform-list-section'>
            <div className='sls-label sls-item'>
                <div className='cell'>FG List </div>
                <div className='cell desktop-view'></div>
            </div>
            <div className='error-message'>{sdpoObj.fgErrorMessage}</div>

            <div className='span-2-columns-container'>
                <div className='view-table-heading'>
                    <div className='cell'>
                        <span className='m-left-15'>FG Name</span>
                    </div>
                    <div className='cell'>
                        <span className='width25'>Ordered Qty:</span>
                        <span className='width25'>Balance Qty:</span>
                        <span className='width25'>Unit:</span>
                        <span className='width25'>Delivered Qty:</span>
                    </div>
                </div>
            </div>

            {listOfFgs}
        </div>
    );

    if (listOfFgs.length === 0) fgSection = null;
    if (listOfSfgs.length === 0) sfgSection = null;

    return (
        <div className='subform'>
            <div className='subform-label-qtys-section'>
                <div className='label'>
                    {' '}
                    {`${sdpoIndex + 1}. ${sdpoObj.sdpoUID}`}
                </div>

                <div className='icon'>{trashIcon}</div>
            </div>

            {sfgSection}

            {fgSection}
        </div>
    );
};
