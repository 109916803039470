import React, { useState, useEffect, useContext } from 'react';
import {
    Datalist,
    AddButton,
    Input,
    CreateItemButtons,
    UpdateItemButtons,
    DeleteItemButtons,
    BackOnlyButton,
    BackSendEditDelButtons,
    ApproveRejectButtons,
    OverrideWithBackButtons,
    OverrideSaveButtons,
    HelpTip,
    TextArea,
    Watermark,
} from '../../components';
import tooltips from '../../lib/tooltips';

import {
    AuthContext,
    MasterListContext,
    DataShareContext,
} from '../../contexts';
import { dbopsFinishedGoods } from '../../firebase_dbops';
import {
    permissions,
    userActions,
    lengths,
    systemActions,
} from '../../lib/constants';
import { clone, displayDate, saveWithFiveDecimals } from '../../lib/util';
import AssemFGForm from './AssemFGForm';
import AssemFGView from './AssemFGView';

import validate from '../../validators';

import '../PagesCommon.css';
import './AssemblyPage.css';

let defaultAssembly = {
    assemblyUID: '',
    assemblyNumber: '',
    fgArr: [],
    meta: { action: '' },
    note: '',
};

export default (props) => {
    let {
        purpose,
        currentDocument,

        backCancelBtnFn,
        gotoEditViewFn,
        gotoDeleteViewFn,
        gotoOverrideViewFn,

        createAssemblyFn,
        deleteAssemblyFn,
        updateAssemblyFn,
        statusChangeFn,
        overrideAssemblyOrderFn,

        setIsLoading,
        setAlertErrorMsg,
    } = props;

    let {
        masterFinishedGoodsList,
        masterInventorySFGsList,
        masterInventoryRMsList,
    } = useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);
    let { dataShare, setDataShare, defaultDataShare, serverDate } = useContext(
        DataShareContext
    );

    let [generalError, setGeneralError] = useState('');
    let [
        datalistDisplayResetCounter,
        setDatalistDisplayResetCounter,
    ] = useState(1);

    let [assemblyObj, setAssemblyObj] = useState(defaultAssembly);
    let [fgUIDstate, setFgUID] = useState('');
    let [assemblyOrderDateInState, setAssemblyOrderDateInState] = useState(
        serverDate
    );
    let [noteInState, setNoteInState] = useState('');
    let [description, setDescription] = useState('');

    let [confirmDeleteText, setConfirmDeleteText] = useState('');
    let [preview, setPreview] = useState(false);

    // set assemblyObj from currentDocument if purpose !== 'ADD'
    useEffect(() => {
        if (purpose !== 'ADD') {
            // making a copy so no side effect will change currentDocument.
            let currentDocumentCopy = {
                // will copy meta and metaHistory as is.
                ...currentDocument,
                // copy rmArr and not a reference.
                fgArr: clone(currentDocument.fgArr),
            };

            setAssemblyOrderDateInState(currentDocument.assemblyOrderDate);
            setAssemblyObj(currentDocumentCopy);
            setNoteInState(currentDocumentCopy.note || '');
            setDescription(currentDocumentCopy.assemblyDescription || '');
        }
    }, []);

    //! ==========================================================
    //? Code to handle creating Assembly Order from inventory FG.

    useEffect(() => {
        if (dataShare.isActive) {
            setFgUID(dataShare.data);
        }
    }, []);

    useEffect(() => {
        if (fgUIDstate && dataShare.isActive) {
            //! reset dataShare to default values
            setDataShare(defaultDataShare);

            addToFgArrFn();
        }
    }, [fgUIDstate]);
    //! ==========================================================

    const cancelBtnFromEditViewFn = () => {
        // 1. reset values.
        setAssemblyObj(currentDocument);

        // 2. reset errorMessages.
        setGeneralError('');

        // 3. go back to profile view.
        backCancelBtnFn();
    };

    // =====================================================

    const deleteErrors = (assemblyCopy) => {
        delete assemblyCopy.assemblyOrderDateError;
        delete assemblyCopy.noteError;
        delete assemblyCopy.assemblyDescriptionError;

        assemblyCopy.fgArr.forEach((fg) => {
            delete fg.fgOrderedQtyError;
            delete fg.fgAssembledQtyError;

            fg.rmArr.forEach((rm) => {
                delete rm.rmProjectedUseQtyError;
                delete rm.rmActualUsedQtyError;
            });

            fg.sfgArr.forEach((sfg) => {
                delete sfg.sfgProjectedUseQtyError;
                delete sfg.sfgActualUsedQtyError;
            });
        });
        setGeneralError('');
    };

    const addToFgArrFn = async () => {
        let assemblyCopy = {
            ...assemblyObj,
            fgArr: clone(assemblyObj.fgArr),
        };

        deleteErrors(assemblyCopy);

        setIsLoading(true);
        let response = await dbopsFinishedGoods.getFGFn(fgUIDstate);
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message || response.message);
            setIsLoading(false);
            return;
        }

        let fgDoc = response.data;
        fgDoc.fgOrderedQty = 0;
        fgDoc.fgBalanceQty = 0;
        fgDoc.fgOverrideQty = 0;
        fgDoc.fgAssembledQty = 0;

        fgDoc.rmArr.forEach((rm) => {
            rm.rmProjectedUseQty = 0;
            rm.rmActualUsedQty = 0;
            rm.rmBalanceQty = 0;
            rm.rmOverrideQty = 0;
        });

        fgDoc.sfgArr.forEach((sfg) => {
            sfg.sfgProjectedUseQty = 0;
            sfg.sfgActualUsedQty = 0;
            sfg.sfgBalanceQty = 0;
            sfg.sfgOverrideQty = 0;
        });

        assemblyCopy.fgArr.push(fgDoc);

        setAssemblyObj(assemblyCopy);
        setDatalistDisplayResetCounter(datalistDisplayResetCounter + 1);
        setIsLoading(false);
    };

    const removeFgFromArrFn = (index) => {
        let assemblyCopy = {
            ...assemblyObj,
            fgArr: clone(assemblyObj.fgArr),
        };

        deleteErrors(assemblyCopy);

        assemblyCopy.fgArr.splice(index, 1);
        setAssemblyObj(assemblyCopy);
    };

    const updateFgValFn = (val, fgIndex, propertyName) => {
        let assemblyCopy = {
            ...assemblyObj,
            fgArr: clone(assemblyObj.fgArr),
        };

        // perform this only when not in override.
        if (purpose !== 'OVERRIDE') {
            //!  default value for rms that are alone in their group.
            let rmAloneMap = {};

            assemblyCopy.fgArr[fgIndex].rmArr.forEach((rm) => {
                rmAloneMap[rm.group] =
                    rmAloneMap[rm.group] !== undefined ? false : true;
            });
            assemblyCopy.fgArr[fgIndex].rmArr.forEach((rm) => {
                if (rmAloneMap[rm.group])
                    rm.rmProjectedUseQty = saveWithFiveDecimals(
                        rm.rmRequiredQty * val
                    );
            });
            //! =====================================================

            //!  default value for sfgs that are alone in their group.
            let sfgAloneMap = {};

            assemblyCopy.fgArr[fgIndex].sfgArr.forEach((sfg) => {
                sfgAloneMap[sfg.group] =
                    sfgAloneMap[sfg.group] !== undefined ? false : true;
            });
            assemblyCopy.fgArr[fgIndex].sfgArr.forEach((sfg) => {
                if (sfgAloneMap[sfg.group])
                    sfg.sfgProjectedUseQty = saveWithFiveDecimals(
                        sfg.sfgRequiredQty * val
                    );
            });
            //! =====================================================
        }

        assemblyCopy.fgArr[fgIndex][propertyName] = val;

        setAssemblyObj(assemblyCopy);
    };

    const updateRmValFn = (val, fgIndex, rmIndex, propertyName) => {
        let assemblyCopy = {
            ...assemblyObj,
            fgArr: clone(assemblyObj.fgArr),
        };

        assemblyCopy.fgArr[fgIndex].rmArr[rmIndex][propertyName] = val;

        setAssemblyObj(assemblyCopy);
    };

    const updateSfgValFn = (val, fgIndex, sfgIndex, propertyName) => {
        let assemblyCopy = {
            ...assemblyObj,
            fgArr: clone(assemblyObj.fgArr),
        };

        assemblyCopy.fgArr[fgIndex].sfgArr[sfgIndex][propertyName] = val;

        setAssemblyObj(assemblyCopy);
    };

    // for saving functionality
    const previewAssemblyBtnFn = () => {
        let assemblyCopy = {
            ...assemblyObj,
            fgArr: clone(assemblyObj.fgArr),
        };
        assemblyCopy.assemblyOrderDate = assemblyOrderDateInState;
        assemblyCopy.note = noteInState;
        assemblyCopy.assemblyDescription = description;

        let response = validate.assembly(
            assemblyCopy,
            purpose,
            masterInventorySFGsList,
            masterInventoryRMsList
        );
        if (response.error) {
            setAssemblyObj(response.errorObj.assemblyObj);
            setAlertErrorMsg(response.errorObj.hackError);
            setGeneralError(response.errorObj.generalError);
        } else {
            // no error
            setAssemblyObj(response.data);
            setPreview(true);
        }
    };

    const saveAssemblyFn = (userActionPassed) => {
        let assemblyCopy = {
            ...assemblyObj,
            fgArr: clone(assemblyObj.fgArr),
        };
        assemblyCopy.assemblyOrderDate = assemblyOrderDateInState;
        assemblyCopy.note = noteInState;
        assemblyCopy.assemblyDescription = description;

        // check if there is a change in the payload from currentDocument
        if (
            purpose === 'EDIT' &&
            validate.noChange(assemblyCopy, currentDocument)
        ) {
            backCancelBtnFn();
            return;
        }

        let response = validate.assembly(
            assemblyCopy,
            purpose,
            masterInventorySFGsList,
            masterInventoryRMsList,
            userActionPassed
        );
        if (response.error) {
            console.error(response.errorObj.assemblyObj);
            setAssemblyObj(response.errorObj.assemblyObj);
            setAlertErrorMsg(response.errorObj.hackError);
            setGeneralError(response.errorObj.generalError);
            return;
        }

        //! this will ensure no tempRmIndex or tempSfgIndex that the app puts into
        //! the assemblyObj to display correctly.
        let validObj = {
            ...response.data,
            fgArr: clone(response.data.fgArr),
        };
        setAssemblyObj(response.data);

        // console.log(validObj);

        if (purpose === 'ADD') createAssemblyFn(validObj);
        if (purpose === 'EDIT') updateAssemblyFn(validObj, currentDocument);
        if (purpose === 'DELETE') deleteAssemblyFn(validObj, currentDocument);

        if (purpose === 'OVERRIDE')
            overrideAssemblyOrderFn(validObj, currentDocument);
    };

    const statusChangeLocalFn = (userActionPassed) => {
        let assemblyCopy = {
            ...assemblyObj,
            fgArr: clone(assemblyObj.fgArr),
        };
        assemblyCopy.assemblyOrderDate = assemblyOrderDateInState;
        assemblyCopy.note = noteInState;
        assemblyCopy.assemblyDescription = description;

        let response = validate.assembly(
            assemblyCopy,
            purpose,
            masterInventorySFGsList,
            masterInventoryRMsList,
            userActionPassed
        );
        // Rejecting status should be allowed regardless of validation.
        if (response.error) {
            console.error(response.errorObj.assemblyObj);
            setAssemblyObj(response.errorObj.assemblyObj);
            setAlertErrorMsg(response.errorObj.hackError);
            setGeneralError(response.errorObj.generalError);
            return;
        }

        //! this will ensure no tempRmIndex or tempSfgIndex that the app puts into
        //! the assemblyObj to display correctly.
        let validObj = {
            ...response.data,
            fgArr: clone(response.data.fgArr),
        };

        statusChangeFn(userActionPassed, validObj, currentDocument);
    };

    // conditional render of contents  =============================

    let fgArrUI = [];
    let noteUI = null;

    let buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    let mainContent = null;
    let datalist = null;

    assemblyObj = {
        ...assemblyObj,
        fgArr: clone(assemblyObj.fgArr),
    };

    let addBtn = <AddButton disabled />;

    let assemblyOrderDateInputUI = (
        <div className='form-profile-info-items'>
            <p className='fpii-label'>Assembly Order Date</p>
            <Input
                type='date'
                maxLength='12'
                onChange={(e) => setAssemblyOrderDateInState(e.target.value)}
                value={assemblyOrderDateInState}
                errorMessage={assemblyObj.assemblyOrderDateError}></Input>
        </div>
    );

    let assemblyOrderDateDisplayUI = (
        <div className='form-profile-info-items'>
            <p className='fpii-label'>Assembly Order Date</p>
            <p className='fpii-text'>
                {assemblyObj.assemblyOrderDate
                    ? displayDate(assemblyObj.assemblyOrderDate)
                    : '---'}
            </p>
        </div>
    );

    let fgListLabel = (
        <div className='span-2-columns-container label-padding label-highlight'>
            <label className='parent-labels'>Finished Goods List</label>
        </div>
    );

    if (
        (purpose === 'ADD' && !preview) ||
        (purpose === 'EDIT' &&
            (assemblyObj.meta.action === userActions.CREATED ||
                assemblyObj.meta.action === userActions.UPDATED ||
                assemblyObj.meta.action === userActions.REJECTED))
    ) {
        let datalistOptions = masterFinishedGoodsList.map((fg) => fg.fgUID);
        let fgAlreadyInTheUI = assemblyObj.fgArr.map((fg) => fg.fgUID);

        if (
            datalistOptions.includes(fgUIDstate) &&
            !fgAlreadyInTheUI.includes(fgUIDstate)
        )
            addBtn = <AddButton onClick={addToFgArrFn} />;

        let datalistLabel = (
            <span>
                Add Finished Good
                <HelpTip content={tooltips.assemblyOrderFgArr}></HelpTip>
            </span>
        );

        datalist = (
            <div className='get-item-from-db-container'>
                <div className='datalist-with-addbtn-container'>
                    <Datalist
                        label={datalistLabel}
                        list={datalistOptions}
                        initialValue={''}
                        getInputValue={(val) => setFgUID(val)}
                        errorMessage={''}
                        resetCounter={datalistDisplayResetCounter}></Datalist>
                    {addBtn}
                </div>
            </div>
        );

        for (let index = 0; index < assemblyObj.fgArr.length; index += 1) {
            let trashIcon = (
                <i
                    className='ms-Icon ms-Icon--Delete clickable-icon'
                    aria-hidden='true'
                    onClick={() => removeFgFromArrFn(index)}></i>
            );

            let fgCopy = {
                ...assemblyObj.fgArr[index],
                rmArr: clone(assemblyObj.fgArr[index].rmArr),
                sfgArr: clone(assemblyObj.fgArr[index].sfgArr),
            };

            fgArrUI.push(
                <div className='span-2-columns-container' key={index}>
                    <AssemFGForm
                        trashIcon={trashIcon}
                        fgIndex={index}
                        fgObj={fgCopy}
                        masterInventoryRMsList={masterInventoryRMsList}
                        masterInventorySFGsList={masterInventorySFGsList}
                        updateFgValFn={updateFgValFn}
                        updateRmValFn={updateRmValFn}
                        updateSfgValFn={updateSfgValFn}
                    />
                </div>
            );
        }

        if (fgArrUI.length === 0) fgListLabel = null;

        noteUI = (
            <div className='span-2-columns-container'>
                <TextArea
                    label={<span className='parent-labels'>Notes</span>}
                    maxLength={lengths.noteLength}
                    value={noteInState}
                    onChange={(e) => setNoteInState(e.target.value)}
                    errorMessage={assemblyObj.noteError}
                />
            </div>
        );

        //! Max number of FGS in the list
        if (assemblyObj.fgArr.length >= lengths.assemblyFgArr) datalist = null;

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Assembly Order</p>
                    <p className='fpii-text'>
                        {assemblyObj.assemblyUID || '(Auto Generated)'}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Status</p>
                    <p className='fpii-text'>
                        {' '}
                        {(assemblyObj.meta && assemblyObj.meta.action) || '---'}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Description</p>
                    <Input
                        type='text'
                        maxLength={lengths.description}
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                        errorMessage={
                            assemblyObj.assemblyDescriptionError
                        }></Input>
                </div>

                {assemblyOrderDateInputUI}

                {fgListLabel}

                <div className='span-2-columns-container'> {fgArrUI}</div>

                {datalist}

                {noteUI}
            </section>
        );
    }

    if (
        purpose === 'OVERRIDE' ||
        (purpose === 'ADD' && preview) ||
        purpose === 'VIEW' ||
        purpose === 'DELETE'
    ) {
        fgArrUI = assemblyObj.fgArr.map((fg, index) => {
            return (
                <AssemFGView
                    key={fg.fgUID}
                    fgObj={fg}
                    fgIndex={index}
                    purpose={purpose}
                    updateFgValFn={updateFgValFn}
                    updateSfgValFn={updateSfgValFn}
                    updateRmValFn={updateRmValFn}
                />
            );
        });

        noteUI = (
            <div className='span-2-columns-container'>
                <TextArea
                    label={<span className='parent-labels'>Notes</span>}
                    disabled
                    maxLength={lengths.longTextLength}
                    value={noteInState}
                />
            </div>
        );

        if (
            (assemblyObj.meta.action === userActions.SENT_FOR_APPROVAL ||
                purpose === 'OVERRIDE') &&
            authUser.permissions.includes(permissions.ASSEMBLY_ORDER_APPROVE)
        ) {
            noteUI = (
                <div className='span-2-columns-container'>
                    <TextArea
                        label={<span className='parent-labels'>Notes</span>}
                        maxLength={lengths.noteLength}
                        value={noteInState}
                        onChange={(e) => setNoteInState(e.target.value)}
                        errorMessage={assemblyObj.noteError}
                    />
                </div>
            );
        }

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Assembly Order</p>
                    <p className='fpii-text'>
                        {assemblyObj.assemblyUID || `(Auto Generated)`}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Status</p>
                    <p className='fpii-text'>
                        {(assemblyObj.meta && assemblyObj.meta.action) || '---'}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Description</p>
                    <p className='fpii-text'>
                        {assemblyObj.assemblyDescription || ''}
                    </p>
                </div>

                {assemblyOrderDateDisplayUI}

                {fgListLabel}

                <div className='span-2-columns-container'> {fgArrUI}</div>
                {noteUI}
            </section>
        );
    }

    // =========================================================================
    // Button sections for users with Request permision ===================

    if (purpose === 'ADD') {
        buttonSection = (
            <CreateItemButtons
                createText='Create Order'
                previewText='Preview Order'
                cancelPreviewFn={() => setPreview(false)}
                cancelFn={backCancelBtnFn}
                createFn={saveAssemblyFn}
                previewFn={previewAssemblyBtnFn}
                isPreview={preview}
            />
        );
    }

    if (purpose === 'EDIT') {
        buttonSection = (
            <UpdateItemButtons
                saveFn={saveAssemblyFn}
                cancelFn={cancelBtnFromEditViewFn}
            />
        );
    }

    if (purpose === 'DELETE') {
        buttonSection = (
            <DeleteItemButtons
                cancelFn={backCancelBtnFn}
                deleteFn={() => saveAssemblyFn(userActions.DELETED)}
                confirmeDeleteFn={(e) => setConfirmDeleteText(e.target.value)}
                confirmDeleteTextValue={confirmDeleteText}
                confirmDeleteTextPlaceHolder='Enter Assembly Number'
                uidValue={assemblyObj.assemblyUID}
            />
        );
    }

    // ==================================================================
    // Button section for users with Request permission.
    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.ASSEMBLY_ORDER_REQUEST) &&
        !authUser.permissions.includes(permissions.ASSEMBLY_ORDER_APPROVE)
    ) {
        if (
            assemblyObj.meta.action === userActions.CREATED ||
            assemblyObj.meta.action === userActions.UPDATED ||
            assemblyObj.meta.action === userActions.REJECTED
        ) {
            buttonSection = (
                <BackSendEditDelButtons
                    backBtnFn={backCancelBtnFn}
                    sendForApprovalBtnFn={() =>
                        statusChangeLocalFn(userActions.SENT_FOR_APPROVAL)
                    }
                    editBtnFn={gotoEditViewFn}
                    deleteBtnFn={gotoDeleteViewFn}
                />
            );
        }
    }

    // =========================================================================
    // Button section for users with Approve permision ===================

    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.ASSEMBLY_ORDER_APPROVE) &&
        !authUser.permissions.includes(permissions.ASSEMBLY_ORDER_REQUEST)
    ) {
        if (assemblyObj.meta.action === userActions.SENT_FOR_APPROVAL) {
            buttonSection = (
                <ApproveRejectButtons
                    backBtnFn={backCancelBtnFn}
                    approveBtnFn={() =>
                        statusChangeLocalFn(userActions.APPROVED)
                    }
                    rejectBtnFn={() =>
                        statusChangeLocalFn(userActions.REJECTED)
                    }
                />
            );
        }

        if (
            assemblyObj.meta.action === userActions.APPROVED ||
            assemblyObj.meta.action === userActions.OVERRIDDEN ||
            assemblyObj.meta.action === systemActions.FULFILLED
        ) {
            buttonSection = (
                <OverrideWithBackButtons
                    backBtnFn={backCancelBtnFn}
                    overrideBtnFn={gotoOverrideViewFn}
                />
            );
        }
    }

    // =========================================================================
    //! button section for users with both Request and Approve permissions.
    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.ASSEMBLY_ORDER_APPROVE) &&
        authUser.permissions.includes(permissions.ASSEMBLY_ORDER_REQUEST)
    ) {
        if (
            assemblyObj.meta.action === userActions.CREATED ||
            assemblyObj.meta.action === userActions.UPDATED ||
            assemblyObj.meta.action === userActions.REJECTED
        ) {
            buttonSection = (
                <BackSendEditDelButtons
                    backBtnFn={backCancelBtnFn}
                    sendForApprovalBtnFn={() =>
                        statusChangeLocalFn(userActions.SENT_FOR_APPROVAL)
                    }
                    editBtnFn={gotoEditViewFn}
                    deleteBtnFn={gotoDeleteViewFn}
                />
            );
        }

        if (assemblyObj.meta.action === userActions.SENT_FOR_APPROVAL) {
            buttonSection = (
                <ApproveRejectButtons
                    backBtnFn={backCancelBtnFn}
                    approveBtnFn={() =>
                        statusChangeLocalFn(userActions.APPROVED)
                    }
                    rejectBtnFn={() =>
                        statusChangeLocalFn(userActions.REJECTED)
                    }
                />
            );
        }

        // if document status is APPROVED
        if (
            assemblyObj.meta.action === userActions.APPROVED ||
            assemblyObj.meta.action === userActions.OVERRIDDEN ||
            assemblyObj.meta.action === systemActions.FULFILLED
        ) {
            buttonSection = (
                <OverrideWithBackButtons
                    backBtnFn={backCancelBtnFn}
                    overrideBtnFn={gotoOverrideViewFn}
                />
            );
        }
    }

    if (
        purpose === 'OVERRIDE' &&
        authUser.permissions.includes(permissions.PDPO_APPROVE)
    ) {
        buttonSection = (
            <OverrideSaveButtons
                cancelFn={cancelBtnFromEditViewFn}
                saveFn={() => saveAssemblyFn(userActions.OVERRIDDEN)}
            />
        );
    }

    if (!authUser.subscriptionStatusIsActive) {
        buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    }

    let generalErrorUI = (
        <div className='general-error-message'>{generalError}</div>
    );

    return (
        <div className='common-form'>
            <section className='form-title-section'>
                Assembly - Order Form
            </section>

            {mainContent}
            {buttonSection}
            {generalErrorUI}

            <Watermark purpose={purpose} currentDocument={currentDocument} />
        </div>
    );
};
