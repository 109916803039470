import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { routes, permissions } from '../../lib/constants';
import { htmlTitle } from '../../firebase_config/firebaseConfig';

import './SideNav.css';
import { AuthContext } from '../../contexts';

export default (props) => {
    let { authUser } = useContext(AuthContext);

    let [showBodyOf, setShowBodyOf] = useState(['', false]);
    let [previousMenuId, setPreviousMenuId] = useState('');

    useEffect(() => {
        if (showBodyOf[1] === true) {
            // nothing is open
            if (previousMenuId === '') {
                let currentElem = document.querySelector(
                    `#${showBodyOf[0]} .group-link-body`
                );
                if (currentElem)
                    currentElem.style.height = `${currentElem.scrollHeight}px`;

                // turn arrow up.
                let currentArrow = document.querySelector(
                    `#${showBodyOf[0]} .group-link-head .arrow-icon`
                );
                if (currentArrow) currentArrow.classList.add('icon-spin');

                // set previous
                setPreviousMenuId(showBodyOf[0]);
            }

            // something is open before
            if (previousMenuId !== '' && showBodyOf[0] !== previousMenuId) {
                // open current menu
                let currentElem = document.querySelector(
                    `#${showBodyOf[0]} .group-link-body`
                );
                if (currentElem)
                    currentElem.style.height = `${currentElem.scrollHeight}px`;

                // turn arrow up.
                let currentArrow = document.querySelector(
                    `#${showBodyOf[0]} .group-link-head .arrow-icon`
                );
                if (currentArrow) currentArrow.classList.add('icon-spin');

                // close the old menu
                let prevElem = document.querySelector(
                    `#${previousMenuId} .group-link-body`
                );
                if (prevElem) prevElem.style.height = '0';

                // turn arrow down.
                let prevArrow = document.querySelector(
                    `#${previousMenuId} .group-link-head .arrow-icon`
                );
                if (prevArrow) prevArrow.classList.remove('icon-spin');

                setPreviousMenuId(showBodyOf[0]);
            }

            // same menu was clicked. // close it.
            if (showBodyOf[0] === previousMenuId && previousMenuId !== '') {
                let currentElem = document.querySelector(
                    `#${showBodyOf[0]} .group-link-body`
                );
                if (currentElem) currentElem.style.height = '0';

                // turn arrow down.
                let currentArrow = document.querySelector(
                    `#${showBodyOf[0]} .group-link-head .arrow-icon`
                );
                if (currentArrow) currentArrow.classList.remove('icon-spin');

                setPreviousMenuId('');
            }
        }
    }, [showBodyOf]);

    const showBodyFn = (menuKey, e) => {
        let groupHeadClicked =
            e.target.classList.contains('group-link-head') ||
            e.target.classList.contains('arrow-icon') ||
            e.target.classList.contains('link-icon');
        setShowBodyOf([menuKey, groupHeadClicked]);
    };

    const permissionAndSubscriptionCheckFn = (permissionArr) => {
        let permitted = false;
        permissionArr.forEach((permission) => {
            if (authUser.permissions.includes(permission)) permitted = true;
        });

        if (!authUser.subscriptionStatusIsActive) permitted = false;

        return permitted;
    };

    let adminSettingsLink = null;
    let paymentsLink = null;
    let inventoryLink = null;
    let reportLink = null;
    let purchaseDeptLink = null;
    let operationsDeptLink = null;
    let salesDeptLink = null;
    let setupLink = null;

    // payment link
    if (authUser.permissions.includes(permissions.OWNER)) {
        paymentsLink = (
            <div
                id='payments-group'
                className='group-link'
                onClick={(e) => showBodyFn('payments-group', e)}>
                <div className='group-link-head'>
                    <i
                        className='link-icon ms-Icon ms-Icon--M365InvoicingLogo'
                        aria-hidden='true'></i>
                    Payments
                    <i
                        className='arrow-icon ms-Icon ms-Icon--ChevronDown'
                        aria-hidden='true'></i>
                </div>
                <div className='group-link-body'>
                    <li className='body-link link'>
                        <NavLink
                            to={routes.SUBSCRIPTION_INVOICES}
                            onClick={props.toggleSideNavFn}
                            activeClassName='activeLink'>
                            <span className='spacer'></span>
                            Invoices
                        </NavLink>
                    </li>

                    <li className='body-link link'>
                        <NavLink
                            to={routes.SETUP_CC}
                            onClick={props.toggleSideNavFn}
                            activeClassName='activeLink'>
                            <span className='spacer'></span>
                            Setup Credit Cards
                        </NavLink>
                    </li>
                </div>
            </div>
        );
    }

    // admin link
    if (
        authUser.permissions.includes(permissions.APP_ADMIN) &&
        authUser.subscriptionStatusIsActive
    ) {
        adminSettingsLink = (
            <div
                id='settings-group'
                className='group-link'
                onClick={(e) => showBodyFn('settings-group', e)}>
                <div className='group-link-head'>
                    <i
                        className='link-icon ms-Icon ms-Icon--Admin'
                        aria-hidden='true'></i>
                    Admin Settings
                    <i
                        className='arrow-icon ms-Icon ms-Icon--ChevronDown'
                        aria-hidden='true'></i>
                </div>
                <div className='group-link-body'>
                    <li className='body-link link'>
                        <NavLink
                            to={routes.UAM}
                            onClick={props.toggleSideNavFn}
                            activeClassName='activeLink'>
                            <span className='spacer'></span>
                            User Access Management
                        </NavLink>
                    </li>
                    <li className='body-link link'>
                        <NavLink
                            to={routes.APP_SETTINGS}
                            onClick={props.toggleSideNavFn}
                            activeClassName='activeLink'>
                            <span className='spacer'></span>
                            Application Settings
                        </NavLink>
                    </li>
                </div>
            </div>
        );
    }

    // inventory link
    if (
        permissionAndSubscriptionCheckFn([
            permissions.INV_FG,
            permissions.INV_SFG,
            permissions.INV_RM,
            permissions.INV_TRANSFER_REQUEST,
            permissions.INV_TRANSFER_APPROVE,
        ])
    ) {
        inventoryLink = (
            <div
                id='inventory-group'
                className='group-link'
                onClick={(e) => showBodyFn('inventory-group', e)}>
                <div className='group-link-head'>
                    <i
                        className='link-icon ms-Icon ms-Icon--CheckList'
                        aria-hidden='true'></i>
                    Inventory
                    <i
                        className='arrow-icon ms-Icon ms-Icon--ChevronDown'
                        aria-hidden='true'></i>
                </div>
                <div className='group-link-body'>
                    {permissionAndSubscriptionCheckFn([permissions.INV_RM]) ? (
                        <li className='body-link link'>
                            <NavLink
                                to={routes.INV_RMS}
                                onClick={props.toggleSideNavFn}
                                activeClassName='activeLink'>
                                <span className='spacer'></span>
                                Raw Materials
                            </NavLink>
                        </li>
                    ) : null}

                    {permissionAndSubscriptionCheckFn([permissions.INV_SFG]) ? (
                        <li className='body-link link'>
                            <NavLink
                                to={routes.INV_SFGS}
                                onClick={props.toggleSideNavFn}
                                activeClassName='activeLink'>
                                <span className='spacer'></span>
                                Semi-Finished Goods
                            </NavLink>
                        </li>
                    ) : null}

                    {permissionAndSubscriptionCheckFn([permissions.INV_FG]) ? (
                        <li className='body-link link'>
                            <NavLink
                                to={routes.INV_FGS}
                                onClick={props.toggleSideNavFn}
                                activeClassName='activeLink'>
                                <span className='spacer'></span>
                                Finished Goods
                            </NavLink>
                        </li>
                    ) : null}

                    {permissionAndSubscriptionCheckFn([
                        permissions.INV_TRANSFER_REQUEST,
                        permissions.INV_TRANSFER_APPROVE,
                    ]) ? (
                        <li className='body-link link'>
                            <NavLink
                                to={routes.INV_TRANSFERS}
                                onClick={props.toggleSideNavFn}
                                activeClassName='activeLink'>
                                <span className='spacer'></span>
                                Inventory Transfers
                            </NavLink>
                        </li>
                    ) : null}
                </div>
            </div>
        );
    }

    // purchase department link
    if (
        permissionAndSubscriptionCheckFn([
            permissions.PDPO_APPROVE,
            permissions.PDPO_REQUEST,
            permissions.PDDR_APPROVE,
            permissions.PDDR_REQUEST,
            permissions.PDVR_APPROVE,
            permissions.PDVR_REQUEST,
            permissions.PDSI_APPROVE,
            permissions.PDSI_REQUEST,
        ])
    ) {
        purchaseDeptLink = (
            <div
                id='PD-group'
                className='group-link'
                onClick={(e) => showBodyFn('PD-group', e)}>
                <div className='group-link-head'>
                    <i
                        className='link-icon ms-Icon ms-Icon--EntitlementRedemption'
                        aria-hidden='true'></i>
                    Purchase Department
                    <i
                        className='arrow-icon ms-Icon ms-Icon--ChevronDown'
                        aria-hidden='true'></i>
                </div>
                <div className='group-link-body'>
                    {permissionAndSubscriptionCheckFn([
                        permissions.PDPO_REQUEST,
                        permissions.PDPO_APPROVE,
                    ]) ? (
                        <li className='body-link link'>
                            <NavLink
                                to={routes.PDPOS}
                                onClick={props.toggleSideNavFn}
                                activeClassName='activeLink'>
                                <span className='spacer'></span>
                                Purchase Orders
                            </NavLink>
                        </li>
                    ) : null}

                    {permissionAndSubscriptionCheckFn([
                        permissions.PDDR_REQUEST,
                        permissions.PDDR_APPROVE,
                    ]) ? (
                        <li className='body-link link'>
                            <NavLink
                                to={routes.PDDRS}
                                onClick={props.toggleSideNavFn}
                                activeClassName='activeLink'>
                                <span className='spacer'></span>
                                Goods Receipts
                            </NavLink>
                        </li>
                    ) : null}

                    {permissionAndSubscriptionCheckFn([
                        permissions.PDSI_REQUEST,
                        permissions.PDSI_APPROVE,
                    ]) ? (
                        <li className='body-link link'>
                            <NavLink
                                to={routes.PDSIS}
                                onClick={props.toggleSideNavFn}
                                activeClassName='activeLink'>
                                <span className='spacer'></span>
                                Supplier Invoices
                            </NavLink>
                        </li>
                    ) : null}

                    {permissionAndSubscriptionCheckFn([
                        permissions.PDVR_REQUEST,
                        permissions.PDVR_APPROVE,
                    ]) ? (
                        <li className='body-link link'>
                            <NavLink
                                to={routes.PDVRS}
                                onClick={props.toggleSideNavFn}
                                activeClassName='activeLink'>
                                <span className='spacer'></span>
                                Voucher Receipts
                            </NavLink>
                        </li>
                    ) : null}
                </div>
            </div>
        );
    }

    // operations department link
    if (
        permissionAndSubscriptionCheckFn([
            permissions.PROD_ORDER_APPROVE,
            permissions.PROD_ORDER_REQUEST,
            permissions.PRODUCTION_DELIVERED_APPROVE,
            permissions.PRODUCTION_DELIVERED_REQUEST,
            permissions.ASSEMBLY_ORDER_APPROVE,
            permissions.ASSEMBLY_ORDER_REQUEST,
            permissions.ASSEMBLY_DELIVERED_REQUEST,
            permissions.ASSEMBLY_DELIVERED_APPROVE,
        ])
    ) {
        operationsDeptLink = (
            <div
                id='operations-group'
                className='group-link'
                onClick={(e) => showBodyFn('operations-group', e)}>
                <div className='group-link-head'>
                    <i
                        className='link-icon ms-Icon ms-Icon--EMI'
                        aria-hidden='true'></i>
                    Operations Department
                    <i
                        className='arrow-icon ms-Icon ms-Icon--ChevronDown'
                        aria-hidden='true'></i>
                </div>
                <div className='group-link-body'>
                    {permissionAndSubscriptionCheckFn([
                        permissions.PROD_ORDER_APPROVE,
                        permissions.PROD_ORDER_REQUEST,
                    ]) ? (
                        <li className='body-link link'>
                            <NavLink
                                to={routes.PRODUCTION}
                                onClick={props.toggleSideNavFn}
                                activeClassName='activeLink'>
                                <span className='spacer'></span>
                                SFG Job Orders
                            </NavLink>
                        </li>
                    ) : null}

                    {permissionAndSubscriptionCheckFn([
                        permissions.PRODUCTION_DELIVERED_APPROVE,
                        permissions.PRODUCTION_DELIVERED_REQUEST,
                    ]) ? (
                        <li className='body-link link'>
                            <NavLink
                                to={routes.PRODUCTION_DELIVERED}
                                onClick={props.toggleSideNavFn}
                                activeClassName='activeLink'>
                                <span className='spacer'></span>
                                SFG Produced
                            </NavLink>
                        </li>
                    ) : null}

                    {permissionAndSubscriptionCheckFn([
                        permissions.ASSEMBLY_ORDER_APPROVE,
                        permissions.ASSEMBLY_ORDER_REQUEST,
                    ]) ? (
                        <li className='body-link link'>
                            <NavLink
                                to={routes.ASSEMBLY}
                                onClick={props.toggleSideNavFn}
                                activeClassName='activeLink'>
                                <span className='spacer'></span>
                                FG Assembly - Orders
                            </NavLink>
                        </li>
                    ) : null}

                    {permissionAndSubscriptionCheckFn([
                        permissions.ASSEMBLY_DELIVERED_REQUEST,
                        permissions.ASSEMBLY_DELIVERED_APPROVE,
                    ]) ? (
                        <li className='body-link link'>
                            <NavLink
                                to={routes.ASSEMBLY_DELIVERED}
                                onClick={props.toggleSideNavFn}
                                activeClassName='activeLink'>
                                <span className='spacer'></span>
                                FG Assembled
                            </NavLink>
                        </li>
                    ) : null}
                </div>
            </div>
        );
    }

    // sales department link
    if (
        permissionAndSubscriptionCheckFn([
            permissions.SDPO_APPROVE,
            permissions.SDPO_REQUEST,
            permissions.SDDR_APPROVE,
            permissions.SDDR_REQUEST,
            permissions.SDSI_APPROVE,
            permissions.SDSI_REQUEST,
            permissions.SDCR_APPROVE,
            permissions.SDCR_REQUEST,
        ])
    ) {
        salesDeptLink = (
            <div
                id='SD-group'
                className='group-link'
                onClick={(e) => showBodyFn('SD-group', e)}>
                <div className='group-link-head'>
                    <i
                        className='link-icon ms-Icon ms-Icon--Money'
                        aria-hidden='true'></i>
                    Sales Department
                    <i
                        className='arrow-icon ms-Icon ms-Icon--ChevronDown'
                        aria-hidden='true'></i>
                </div>
                <div className='group-link-body'>
                    {permissionAndSubscriptionCheckFn([
                        permissions.SDPO_APPROVE,
                        permissions.SDPO_REQUEST,
                    ]) ? (
                        <li className='body-link link'>
                            <NavLink
                                to={routes.SDPOS}
                                onClick={props.toggleSideNavFn}
                                activeClassName='activeLink'>
                                <span className='spacer'></span>
                                Sales Orders
                            </NavLink>
                        </li>
                    ) : null}

                    {permissionAndSubscriptionCheckFn([
                        permissions.SDDR_APPROVE,
                        permissions.SDDR_REQUEST,
                    ]) ? (
                        <li className='body-link link'>
                            <NavLink
                                to={routes.SDDRS}
                                onClick={props.toggleSideNavFn}
                                activeClassName='activeLink'>
                                <span className='spacer'></span>
                                Delivery Receipts
                            </NavLink>
                        </li>
                    ) : null}

                    {permissionAndSubscriptionCheckFn([
                        permissions.SDSI_APPROVE,
                        permissions.SDSI_REQUEST,
                    ]) ? (
                        <li className='body-link link'>
                            <NavLink
                                to={routes.SDSIS}
                                onClick={props.toggleSideNavFn}
                                activeClassName='activeLink'>
                                <span className='spacer'></span>
                                Customer Invoices
                            </NavLink>
                        </li>
                    ) : null}

                    {permissionAndSubscriptionCheckFn([
                        permissions.SDCR_APPROVE,
                        permissions.SDCR_REQUEST,
                    ]) ? (
                        <li className='body-link link'>
                            <NavLink
                                to={routes.SDCRS}
                                onClick={props.toggleSideNavFn}
                                activeClassName='activeLink'>
                                <span className='spacer'></span>
                                Counter Receipts
                            </NavLink>
                        </li>
                    ) : null}
                </div>
            </div>
        );
    }

    // setup link
    if (
        permissionAndSubscriptionCheckFn([
            permissions.FG_SETUP,
            permissions.SFG_SETUP,
            permissions.RM_SETUP,
            permissions.SUPPLIER_SETUP,
            permissions.CUSTOMER_SETUP,
        ])
    ) {
        setupLink = (
            <div
                id='setup-group'
                className='group-link'
                onClick={(e) => showBodyFn('setup-group', e)}>
                <div className='group-link-head'>
                    <i
                        className='link-icon ms-Icon ms-Icon--Settings'
                        aria-hidden='true'></i>
                    Setup
                    <i
                        className='arrow-icon ms-Icon ms-Icon--ChevronDown'
                        aria-hidden='true'></i>
                </div>
                <div className='group-link-body'>
                    {permissionAndSubscriptionCheckFn([
                        permissions.SUPPLIER_SETUP,
                    ]) ? (
                        <li className='body-link link'>
                            <NavLink
                                to={routes.SUPPLIERS}
                                onClick={props.toggleSideNavFn}
                                activeClassName='activeLink'>
                                <span className='spacer'></span>
                                Suppliers
                            </NavLink>
                        </li>
                    ) : null}

                    {permissionAndSubscriptionCheckFn([
                        permissions.CUSTOMER_SETUP,
                    ]) ? (
                        <li className='body-link link'>
                            <NavLink
                                to={routes.CUSTOMERS}
                                onClick={props.toggleSideNavFn}
                                activeClassName='activeLink'>
                                <span className='spacer'></span>
                                Customers
                            </NavLink>
                        </li>
                    ) : null}

                    {permissionAndSubscriptionCheckFn([
                        permissions.RM_SETUP,
                    ]) ? (
                        <li className='body-link link'>
                            <NavLink
                                to={routes.RAW_MATERIALS}
                                onClick={props.toggleSideNavFn}
                                activeClassName='activeLink'>
                                <span className='spacer'></span>
                                Raw Materials
                            </NavLink>
                        </li>
                    ) : null}

                    {permissionAndSubscriptionCheckFn([
                        permissions.SFG_SETUP,
                    ]) ? (
                        <li className='body-link link'>
                            <NavLink
                                to={routes.SFGS}
                                onClick={props.toggleSideNavFn}
                                activeClassName='activeLink'>
                                <span className='spacer'></span>
                                Semi-Finished Goods
                            </NavLink>
                        </li>
                    ) : null}

                    {permissionAndSubscriptionCheckFn([
                        permissions.FG_SETUP,
                    ]) ? (
                        <li className='body-link link'>
                            <NavLink
                                to={routes.FINISHED_GOODS}
                                onClick={props.toggleSideNavFn}
                                activeClassName='activeLink'>
                                <span className='spacer'></span>
                                Finished Goods
                            </NavLink>
                        </li>
                    ) : null}
                </div>
            </div>
        );
    }

    // reports link
    if (permissionAndSubscriptionCheckFn([permissions.VIEW_REPORTS])) {
        reportLink = (
            <li className='link'>
                <NavLink
                    to={routes.REPORTS}
                    onClick={props.toggleSideNavFn}
                    activeClassName='activeLink'>
                    <i
                        className='link-icon ms-Icon ms-Icon--ReportDocument'
                        aria-hidden='true'></i>
                    Reports
                </NavLink>
            </li>
        );
    }

    // console.log('show', showBodyOf, 'previous', previousMenuId);
    return (
        <div id='sidenav' className='sidenav'>
            <div className='brand-container'>
                <div className='brand'>
                    <i
                        className='brand-icon ms-Icon ms-Icon--CubeShape'
                        aria-hidden='true'></i>
                    <span className='brand-name'>Business Logic</span>
                </div>
                <div className='company-name'>{htmlTitle}</div>
            </div>

            <ul className='links'>
                <li className='link link-logout'>
                    <NavLink
                        to={routes.PROFILE}
                        onClick={props.toggleSideNavFn}
                        activeClassName='activeLink'>
                        <i
                            className='link-icon ms-Icon ms-Icon--Contact'
                            aria-hidden='true'></i>
                        User Profile
                    </NavLink>
                </li>
                <li className='link'>
                    <NavLink
                        to={routes.DASHBOARD}
                        onClick={props.toggleSideNavFn}
                        activeClassName='activeLink'>
                        <i
                            className='link-icon ms-Icon ms-Icon--BIDashboard'
                            aria-hidden='true'></i>
                        Dashboard
                    </NavLink>
                </li>

                {inventoryLink}
                {purchaseDeptLink}
                {operationsDeptLink}
                {salesDeptLink}
                {setupLink}

                {reportLink}
                {adminSettingsLink}

                {paymentsLink}

                <li className='link link-logout' onClick={props.logoutFn}>
                    <i
                        className='link-icon ms-Icon ms-Icon--SignOut'
                        aria-hidden='true'></i>
                    Logout
                </li>
            </ul>
        </div>
    );
};
