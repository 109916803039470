import React, { useState, useContext } from 'react';
import { dbopsSemiFGs } from '../../firebase_dbops';
import { MasterListContext } from '../../contexts';
import { InputIcon, ListView2, Checkbox, Select } from '../../components';
import InventorySfgFgItem from '../../components/CollectionItems/InventorySfgFgItem/InventorySfgFgItem';

import '../PagesCommon.css';
import './InventorySFGs.css';

import InventorySFGsForm from './InventorySFGsForm';

const view = {
    LIST_VIEW: 'listView',
    INV_SFG: 'inventorySFGview',
};

const selectOptions = [
    { value: 'None' },
    { value: 'SFG Name ⬆︎' },
    { value: 'SFG Name ⬇︎' },
    { value: 'Unit ⬆︎' },
    { value: 'Unit ⬇︎' },
    { value: 'Low Threshold ⬆︎' },
    { value: 'Low Threshold ⬇︎' },
    { value: 'Projected Qty ⬆︎' },
    { value: 'Projected Qty ⬇︎' },
    { value: 'Actual Qty ⬆︎' },
    { value: 'Actual Qty ⬇︎' },
];

export default (props) => {
    let { setIsLoading, setAlertErrorMsg } = props;

    let { masterInventorySFGsList } = useContext(MasterListContext);

    let [formView, setFormView] = useState(view.LIST_VIEW);
    let [currentDocument, setCurrentDocument] = useState('');
    let [searchText, setSearchText] = useState('');
    let [showLowInventory, setShowLowInventory] = useState(false);

    let [sortValue, setSortValue] = useState('');

    // sorting functions =========================
    // Added 10-29-2023

    const sortFn = (sortVal, arrToSort) => {

        let propNameMap = {
            'SFG Name ⬆︎': 'sfgUID',
            'SFG Name ⬇︎': 'sfgUID',
            'Unit ⬆︎': 'sfgUnit',
            'Unit ⬇︎': 'sfgUnit',
            'Low Threshold ⬆︎': 'sfgLowThreshold',
            'Low Threshold ⬇︎': 'sfgLowThreshold',
            'Projected Qty ⬆︎': 'sfgProjectedQty',
            'Projected Qty ⬇︎': 'sfgProjectedQty',
            'Actual Qty ⬆︎': 'sfgActualQty',
            'Actual Qty ⬇︎': 'sfgActualQty',
        }

        let tempSortedArr = arrToSort.sort((a,b)=> {
            let propName = propNameMap[sortVal];
            let propA = a[propName];
            let propB = b[propName];

            if(sortVal.includes('⬆︎')) {
                if(propA > propB) return 1;
                if(propA < propB) return -1;
                return 0;
            } else {
                if(propA < propB) return 1;
                if(propA > propB) return -1;
                return 0;
            }
        });

        return tempSortedArr.map(item => item);
    }

    // End
    // sorting functions =========================

    // functions to change views.
    const gotoListViewFn = () => setFormView(view.LIST_VIEW);
    const gotoSFGProfileViewFn = () => setFormView(view.INV_SFG);

    // function to get supplier document.
    const getDocumentFromDBFn = async (docID) => {
        setIsLoading(true);
        let response = await dbopsSemiFGs.getSFGFn(docID);
        if (response.error)
            setAlertErrorMsg(response.errorObj.message || response.message);
        else {
            setCurrentDocument(response.data);
            gotoSFGProfileViewFn();
        }
        setIsLoading(false);
    };

    const toggleLowInventoryFn = () => {
        setShowLowInventory(!showLowInventory);
    };

    // filter function ====================================
    let filteredSFGsList = masterInventorySFGsList.filter((sfg) => {
        return (
            sfg.sfgUID.toUpperCase().includes(searchText.toUpperCase()) ||
            sfg.sfgCode.toUpperCase().includes(searchText.toUpperCase()) ||
            sfg.sfgLowThreshold
                .toString()
                .toUpperCase()
                .includes(searchText.toUpperCase()) ||
            sfg.sfgProjectedQty
                .toString()
                .toUpperCase()
                .includes(searchText.toUpperCase()) ||
            sfg.sfgActualQty
                .toString()
                .toUpperCase()
                .includes(searchText.toUpperCase())
        );
    });

    if (showLowInventory) {
        filteredSFGsList = filteredSFGsList.filter((sfg) => {
            return (
                sfg.sfgActualQty < sfg.sfgLowThreshold ||
                sfg.sfgProjectedQty < sfg.sfgLowThreshold
            );
        });
    }

    if(sortValue && sortValue !== 'None') {
        filteredSFGsList = sortFn(sortValue, filteredSFGsList)
    }
    // console.log('>>>>', filteredSFGsList);

    let topSectionContent = (
        <section className='top-section'>
            <div className='flex-row-to-column'>
                <InputIcon
                    type='text'
                    placeholder='Search'
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    icon={
                        <i
                            className='ms-Icon ms-Icon--Search'
                            aria-hidden='true'
                        ></i>
                    }
                />

                <div className="sort-select">
                    <Select
                        label=''
                        optionsArr={selectOptions}
                        value={sortValue}
                        onChange={(e) => setSortValue(e.target.value)}
                        placeholder='Sort by'
                        errorMessage=''
                        placeholderText='Sort by...'
                    />
                </div>

                <span className='m-left-15'>
                    <Checkbox
                        label='Low Inventory'
                        onChange={toggleLowInventoryFn}
                    />
                </span>
            </div>
        </section>
    );

    let mainSectionContent = (
        <ListView2
            getDocumentFromDBFn={getDocumentFromDBFn}
            list={filteredSFGsList}
            keyProperty={'sfgUID'}
            CollectionItemComponent={InventorySfgFgItem}
        />
    );

    if (formView !== view.LIST_VIEW) topSectionContent = null;

    if (formView === view.INV_SFG) {
        mainSectionContent = (
            <InventorySFGsForm
                currentDocument={currentDocument}
                backCancelBtnFn={gotoListViewFn}
            />
        );
    }

    return (
        <div className='content-layout'>
            {topSectionContent}

            <section className='main-section'>{mainSectionContent}</section>
        </div>
    );
};
