import React from 'react';

export default (props) => {
    let { backBtnFn, createBtnFn, createBtnText } = props;

    let buttonSection = (
        <section className='form-button-section'>
            <button className='left-button-control btn' onClick={backBtnFn}>
                Back
            </button>
            <button
                className='right-button-control btn btn-primary'
                onClick={createBtnFn}
            >
                {createBtnText}
            </button>
        </section>
    );

    return buttonSection;
};
