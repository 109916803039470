import React, { useState, createContext } from 'react';

export const DataShareContext = createContext();

export default (props) => {
    const defaultDataShare = {
        isActive: false,
        page: '',
        data: '',
    };

    let [dataShare, setDataShare] = useState(defaultDataShare);
    let [defaultTaxRateFromDB, setDefaultTaxRateFromDB] = useState(0);
    let [subscriptionStartDate, setSubscriptionStartDate] = useState(null);
    let [subscriptionEndDate, setSubscriptionEndDate] = useState(null);
    let [serverDate, setServerDate] = useState('');

    let [subscriptionCancelledDate, setSubscriptionCancelledDate] = useState(
        null
    );

    let [rawMatCategoriesInDB, setRawMatCategoriesInDB] = useState({
        0: '',
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
        7: '',
        8: '',
        9: '',
    });

    return (
        <DataShareContext.Provider
            value={{
                dataShare,
                setDataShare,
                defaultDataShare,
                defaultTaxRateFromDB,
                setDefaultTaxRateFromDB,
                subscriptionStartDate,
                setSubscriptionStartDate,
                subscriptionEndDate,
                setSubscriptionEndDate,
                serverDate,
                setServerDate,
                subscriptionCancelledDate,
                setSubscriptionCancelledDate,
                rawMatCategoriesInDB,
                setRawMatCategoriesInDB,
            }}>
            {props.children}
        </DataShareContext.Provider>
    );
};
