import { userActions, systemActions } from '../../../lib/constants';
import colors from './chartColors';

let {
    CREATED,
    UPDATED,
    SENT_FOR_APPROVAL,
    REJECTED,
    APPROVED,
    SENT_TO_SUPPLIER,
    OVERRIDDEN,
} = userActions;

let {
    created,
    updated,
    forApproval,
    approved,
    sentTo,
    override,
    fulfilled,
    rejected,
} = colors;

export default (list) => {
    const statusArr = [
        CREATED,
        UPDATED,
        'PENDING APPROVAL',
        APPROVED,
        SENT_TO_SUPPLIER,
        systemActions.FULFILLED,
        OVERRIDDEN,
        REJECTED,
    ];
    const dataValues = [0, 0, 0, 0, 0, 0, 0, 0];
    const colorsArr = [
        created,
        updated,
        forApproval,
        approved,
        sentTo,
        fulfilled,
        override,
        rejected,
    ];

    list.forEach((item) => {
        if (item.status === CREATED) dataValues[0] += 1;
        if (item.status === UPDATED) dataValues[1] += 1;
        if (item.status === SENT_FOR_APPROVAL) dataValues[2] += 1;
        if (item.status === APPROVED) dataValues[3] += 1;
        if (item.status === SENT_TO_SUPPLIER) dataValues[4] += 1;
        if (item.status === systemActions.FULFILLED) dataValues[5] += 1;
        if (item.status === OVERRIDDEN) dataValues[6] += 1;
        if (item.status === REJECTED) dataValues[7] += 1;
    });

    return {
        dataLabels: statusArr,
        dataValues: dataValues,
        dataBackgroundColors: colorsArr,
    };
};
