import React from 'react';

import './SignatureLine.css';

export default (props) => {
    return (
        <div className='signature-box show-on-print-only'>
            <div className='signature-section'>
                <span>Prepared by</span>
            </div>
            <div className='signature-section'>
                <span>Received by</span>
            </div>
        </div>
    );
};
