import React from 'react';
import { displayMoney, saveWithTwoDecimalOnly } from '../../lib/util';

export default (props) => {
    let { rmArr } = props;

    // console.log(rmArr);

    let totalsNet = rmArr.reduce((acc, rm) => {
        let currentAmount = saveWithTwoDecimalOnly(
            Number(rm.rmOrderedQty) * Number(rm.rmCost)
        );

        currentAmount = saveWithTwoDecimalOnly(
            currentAmount + Number(rm.rmOverrideQty) * Number(rm.rmCost)
        );

        return (acc += currentAmount);
    }, 0);

    let amountBox = (
        <>
            <div className='desktop-view'></div>
            <div className='amount-box'>
                <div>Totals:</div>{' '}
                <div className='text-bold'>{displayMoney(totalsNet)}</div>
            </div>
        </>
    );

    return amountBox;
};
