export const generalMessage = {
    REQUIRED: 'Required',
    INVALID: 'Invalid Entry',
    INVALID_LENGTH: 'Invalid Length',
    INVALID_FORMAT: 'Invalid Format',
    INVALID_SLASH: 'Cannot contain "/"',
    INVALID_UID: 'Invalid UID Format',
    INVALID_AMOUNT: 'Invalid Amount',
    INVALID_RATE: 'Invalid Rate',
    INVALID_DATE: 'Invalid Date',
    GREATER_ZERO: 'Must be greater than zero',
    POSITIVE_NUMBER: 'Must be a positive number',
    NOT_ENOUGH_INVENTORY: 'Not enough inventory',
    RECENT_INVENTORY_CHANGES: 'Insufficient quantity in inventory',
};

export const appMessage = {
    alreadyExist: (text) => `${text} already exists`,

    successfullyCreated: (text) => `Successfully created ${text}`,

    errorCreating: (text) => `Error creating ${text}`,

    successfullyUpdated: (text) => `Successfully updated ${text}`,

    errorUpdating: (text) => `Error updating ${text}`,

    successfullyDeleted: (text) => `Successfully deleted ${text}`,

    errorDeleting: (text) => `Error deleting ${text}`,

    cannotEditBecauseDocNotExist: (text) => {
        console.error(
            `${text} document cannot be edited as document no longer exist`
        );
        return `${text} no longer exist`;
    },

    cannotEditBecauseDocChanged: (text) => {
        console.error(
            `${text} document cannot be edited as document was modified before changes could be applied`
        );
        return `${text} was modified before changes could be applied`;
    },

    cannotDeleteBecauseDocNotExist: (text) => {
        console.error(
            `${text} document cannot be edited as document no longer exist`
        );
        return `${text} no longer exist`;
    },

    cannotDeleteBecauseDocChanged: (text) => {
        console.error(
            `${text} document cannot be edited as document was modified before changes could be applied`
        );
        return `${text} was modified before delete could be performed`;
    },

    sourceCodeErrorMessage: (text) => {
        console.error(
            `App: Source Code Issue. Invalid input type or length detected. (${text})`
        );
        return `App: Source Code Issue. Invalid input type or length detected. (${text})`;
    },

    cannotEditBecauseOneItemInArrayDeleted: (file, document, itemName) => {
        console.error(
            `Cannot edit ${document} document as one of the ${itemName} document has already been deleted. (${file})`
        );
        return `${itemName} has been deleted`;
    },

    cannotEditBecauseOneItemInArrayChanged: (file, document, itemName) => {
        console.error(
            `Cannot edit ${document} document as one of the ${itemName} document has been modified. (${file})`
        );
        return `${itemName} has been modified`;
    },

    prommiseAllError: (file, arrayName) => {
        console.error(`Sys: PromiseAll error. (${arrayName} in ${file})`);
        return `Sys: PromiseAll error. (${arrayName} in ${file})`;
    },

    balanceError: (firstQty, secondQty, docType) => {
        console.error(
            `${firstQty} was greater than the remaining ${secondQty} in one or more of the ${docType} document(s).`
        );
        return 'Balance Error';
    },

    successfulUpdateStatus: 'Successfully updated status',
    errorUpdatingStatus: 'Error updating status',
};

export const docNames = {
    SUPPLIER: 'Supplier',
    CUSTOMER: 'Customer',
    RM: 'Raw Material',
    SFG: 'Semi-Finished Good',
    FG: 'Finished Good',
    PDPO: 'PO',
    PDDR: 'DR',
    PDSI: 'SI',
    PDVR: 'VR',
    PRODUCTION: 'Production Order',
    ASSEMBLY: 'Assembly Order',
    SDPO: 'PO',
    SDDR: 'DR',
    SDSI: 'SI',
    SDCR: 'CR',
    USER: 'User',
    PRODUCTION_DELIVERED: 'Production Delivered',
    ASSEMBLY_DELIVERED: 'Assembly Delivered',
    INV_TRANSFER: 'Inventory Transfer',
};
