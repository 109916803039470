import React from 'react';
import './Input_Icon.css';

export default React.forwardRef((props, ref) => {

  let {key, id, disabled, className, maxLength,
    minLength, required, onChange, placeholder, value,
    errorMessage, type, onKeyDown, autoFocus
  } = props;

  return (
    <div className="input-icon-wrapper" key={key}>

        <div className='ii-icon-container'>
            {props.icon}
        </div>

        <input 
            ref={ref}
            disabled={disabled}
            type={type}
            id={id}
            className={className}
            required={required}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            maxLength={maxLength}
            minLength={minLength}
            autoFocus={autoFocus}
            onKeyDown={onKeyDown}
            ></input>

        <span className="ii-input-error">{errorMessage}</span>
        
    </div>
  );
});