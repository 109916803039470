import React from 'react';
import '../CollectionItems.css';
import './UsersItem.css';

export default (props) => {
    let { obj, serialNumber, onClick, heading } = props;

    let serial = 'S/No.';
    let userEmail = 'Email';
    let displayName = 'Display Name';

    let headingClass = 'text-bold list-table-heading';

    let serialLabel = serial + ':';
    let userEmailLabel = userEmail + ':';
    let displayNameLabel = displayName + ':';

    if (!heading) {
        serial = serialNumber;
        userEmail = obj.email;
        displayName = obj.displayName;
        headingClass = '';
    }

    return (
        <div
            className={`user-item collection-item ${headingClass}`}
            onClick={onClick}
        >
            <div className='collection-item-entry serial'>
                {' '}
                <span className='entry-label'>{serialLabel}</span>
                {serial}
            </div>
            <div className='collection-item-entry user-email'>
                {' '}
                <span className='entry-label'>{userEmailLabel}</span>
                {userEmail}
            </div>
            <div className='collection-item-entry display-name'>
                {' '}
                <span className='entry-label'>{displayNameLabel}</span>
                {displayName}
            </div>
        </div>
    );
};
