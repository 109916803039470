export const MASTER_LISTS_COL = 'MasterLists';

export const USERS_COL = 'Users';
export const MASTER_USERS_LIST_DOC = 'UsersList';

export const SUPPLIERS_COL = 'Suppliers';
export const MASTER_SUPPLIERS_LIST_DOC = 'SuppliersList';
export const DELETED_SUPPLIER_COL = 'DeletedSuppliers';

export const CUSTOMERS_COL = 'Customers';
export const MASTER_CUSTOMERS_LIST_DOC = 'CustomersList';
export const DELETED_CUSTOMERS_COL = 'DeletedCustomers';

export const RAW_MATERIALS_COL = 'RawMaterials';
export const MASTER_RAW_MATERIALS_LIST_DOC = 'RawMaterialsList';
export const DELETED_RAW_MATERIALS_COL = 'DeletedRawMaterials';

export const SEMI_FGS_COL = 'SemiFGs';
export const MASTER_SEMI_FGS_LIST_DOC = 'SemiFGsList';
export const DELETED_SEMI_FGS_COL = 'DeletedSemiFGs';

export const FINISHED_GOODS_COL = 'FinishedGoods';
export const MASTER_FINISHED_GOODS_LIST_DOC = 'FinishedGoodsList';
export const DELETED_FINISHED_GOODS_COL = 'DeletedFinishedGoods';

export const INVENTORY_TRANSFER_COL = 'InventoryTransfers';
export const MASTER_INVENTORY_TRANSFER_LIST_DOC = 'InventoryTransferList';
export const DELETED_INVENTORY_TRANSFER_COL = 'DeletedInventoryTransfers';

export const MASTER_INVENTORY_RM_LIST_DOC = 'InventoryRMList';
export const MASTER_INVENTORY_SFG_LIST_DOC = 'InventorySFGList';
export const MASTER_INVENTORY_FG_LIST_DOC = 'InventoryFinishedGoodsList';

export const COST_HISTORY_COL = 'CostHistoryDocs';

export const SG_NUMBERS_COL = 'SGNumbers';
export const PDPO_COUNTER_DOC = 'PdpoCounter';
export const PROD_ORDER_COUNTER_DOC = 'ProductionOrderCounter';
export const PRODUCTION_DELIVERED_COUNTER_DOC = 'ProductionDeliveredCounter';
export const ASSEMBLY_COUNTER_DOC = 'AssemblyOrderCounter';
export const ASSEMBLY_DELIVERED_COUNTER_DOC = 'AssemblyDeliveredCounter';
export const SDPO_QUOTE_COUNTER_DOC = 'SdpoQuoteCounter';
export const SDDR_COUNTER_DOC = 'SddrCounter';
export const SDSI_COUNTER_DOC = 'SdsiCounter';
export const GLOBAL_VARIABLES_DOC = 'GlobalVariables';
export const SERVER_TIMESTAMP = 'ServerTimestamp';
export const INV_TRANSFER_COUNTER_DOC = 'InvTransferCounter';

export const SUBSCRIPTION_COL = 'Subscription';
export const BL_SUBSCRIPTION_DOC = 'BusinessLogicSubscription';

export const PDPOS_COL = 'PDPOs';
export const MASTER_PDPO_LIST_DOC = 'PDPOsList';
export const DELETED_PDPOS_COL = 'DeletedPDPOs';

export const PDDRS_COL = 'PDDRs';
export const MASTER_PDDR_LIST_DOC = 'PDDRsList';
export const DELETED_PDDRS_COL = 'DeletedPDDRs';

export const PDSIS_COL = 'PDSIs';
export const MASTER_PDSI_LIST_DOC = 'PDSIsList';
export const DELETED_PDSIS_COL = 'DeletedPDSIs';

export const PDVRS_COL = 'PDVRs';
export const MASTER_PDVR_LIST_DOC = 'PDVRsList';
export const DELETED_PDVRS_COL = 'DeletedPDVRs';

export const ASSEMBLY_COL = 'AssemblyOrders';
export const MASTER_ASSEMBLY_ORDER_LIST_DOC = 'AssemblyOrdersList';
export const DELETED_ASSEMBLY_COL = 'DeletedAssemblyOrders';

export const ASSEMBLY_DELIVERED_COL = 'AssemblyDelivered';
export const MASTER_ASSEMBLY_DELIVERED_LIST_DOC = 'AssemblyDeliveredList';
export const DELETED_ASSEMBLY_DELIVERED_COL = 'DeletedAssemblyDelivered';

export const PROD_ORDER_COL = 'ProdOrders';
export const MASTER_PROD_ORDER_LIST_DOC = 'ProdOrdersList';
export const DELETED_PROD_ORDER_COL = 'DeletedProdOrders';

export const PRODUCTION_DELIVERED_COL = 'ProductionDelivered';
export const MASTER_PRODUCTION_DELIVERED_LIST_DOC = 'ProductionDeliveredList';
export const DELETED_PRODUCTION_DELIVERED_COL = 'DeletedProductionDelivered';

export const SDPOS_COL = 'SDPOs';
export const MASTER_SDPO_LIST_DOC = 'SDPOsList';
export const DELETED_SDPOS_COL = 'DeletedSDPOs';

export const SDDRS_COL = 'SDDRs';
export const MASTER_SDDR_LIST_DOC = 'SDDRsList';
export const DELETED_SDDRS_COL = 'DeletedSDDRs';

export const SDSIS_COL = 'SDSIs';
export const MASTER_SDSI_LIST_DOC = 'SDSIsList';
export const DELETED_SDSIS_COL = 'DeletedSDSIs';

export const SDCRS_COL = 'SDCRs';
export const MASTER_SDCR_LIST_DOC = 'SDCRsList';
export const DELETED_SDCRS_COL = 'DeletedSDCRs';

export const MASTER_SUBSCRIPTION_INVOICES_LIST_DOC = 'SubscriptionInvoicesList';
export const SUBSCRIPTION_INVOICES_COL = 'SubscriptionsInvoices';

export const MASTER_REPORTS_LIST_DOC = 'ReportsList';
export const REPORTS_COL = 'Reports';

export const MasterListDocs = {
    MASTER_SUPPLIERS_LIST_DOC,
    MASTER_RAW_MATERIALS_LIST_DOC,
    MASTER_CUSTOMERS_LIST_DOC,
    MASTER_SEMI_FGS_LIST_DOC,
    MASTER_FINISHED_GOODS_LIST_DOC,
    MASTER_INVENTORY_RM_LIST_DOC,
    MASTER_INVENTORY_SFG_LIST_DOC,
    MASTER_INVENTORY_FG_LIST_DOC,
    MASTER_INVENTORY_TRANSFER_LIST_DOC,
    MASTER_PDPO_LIST_DOC,
    MASTER_PDDR_LIST_DOC,
    MASTER_PDSI_LIST_DOC,
    MASTER_ASSEMBLY_ORDER_LIST_DOC,
    MASTER_ASSEMBLY_DELIVERED_LIST_DOC,
    MASTER_PRODUCTION_DELIVERED_LIST_DOC,
    MASTER_PROD_ORDER_LIST_DOC,
    MASTER_SDPO_LIST_DOC,
    MASTER_SDDR_LIST_DOC,
    MASTER_SDSI_LIST_DOC,
    MASTER_USERS_LIST_DOC,
    MASTER_SUBSCRIPTION_INVOICES_LIST_DOC,
    MASTER_PDVR_LIST_DOC,
    MASTER_SDCR_LIST_DOC,
    MASTER_REPORTS_LIST_DOC,
};

export const str = {
    SUPPLIERS: 'Suppliers',
    CUSTOMERS: 'Customers',
    RAW_MATERIALS: 'RawMaterials',
    SEMI_FGS: 'SemiFGs',
    FGS: 'FinishedGoods',
    INV_RMS: 'InventoryRMs',
    INV_SFGS: 'InventorySFGs',
    INV_FGS: 'InventoryFGs',
    INV_TRANSFER: 'InventoryTransfers',
    PDPOS: 'PDPOS',
    PDDRS: 'PDDRS',
    PDSIS: 'PDSIS',
    PDVRS: 'PDVRS',
    PRODUCTION: 'ProductionOrders',
    PRODUCTION_DELIVERED: 'ProductionDelivered',
    ASSEMBLY: 'AssemblyOrders',
    ASSEMBLY_DELIVERED: 'AssemblyDelivered',
    SDPOS: 'SDPOS',
    SDDRS: 'SDDRS',
    SDSIS: 'SDSIS',
    SDCRS: 'SDCRS',
    USERS: 'Users',
    SUBSCRIPTION_INVOICES: 'SubscriptionInvoices',
    REPORTS: 'Reports',

    // propertyName placeholder
    PLACEHOLDER: 'placeholder',

    // propertyNames on masterlist
    SUPPLIER_UID: 'supplierUID',
    CUSTOMER_UID: 'customerUID',

    RM_UID: 'rmUID',
    RM_UNIT: 'rmUnit',
    RM_CODE: 'rmCode',
    RM_LOW_THRESHOLD: 'rmLowThreshold',
    RM_ACTUAL_QTY: 'rmActualQty',
    RM_PROJECTED_QTY: 'rmProjectedQty',

    SFG_UID: 'sfgUID',
    SFG_UNIT: 'sfgUnit',
    SFG_CODE: 'sfgCode',
    SFG_LOW_THRESHOLD: 'sfgLowThreshold',
    SFG_ACTUAL_QTY: 'sfgActualQty',
    SFG_PROJECTED_QTY: 'sfgProjectedQty',

    FG_UNIT: 'fgUnit',
    FG_UID: 'fgUID',
    FG_CODE: 'fgCode',
    FG_LOW_THRESHOLD: 'fgLowThreshold',
    FG_ACTUAL_QTY: 'fgActualQty',
    FG_PROJECTED_QTY: 'fgProjectedQty',

    PDPO_UID: 'pdpoUID',
    PDDR_UID: 'pddrUID',
    PRODUCTION_UID: 'productionUID',
    ASSEMBLY_UID: 'assemblyUID',
    CONTACT_NUMBER: 'contactNumber1',
    CONTACT_PERSON: 'contactPerson',
    RM_CATEGORY: 'rmCategory',
    STATUS: 'status',
    PDSI_UID: 'pdsiUID',
    SDPO_UID: 'sdpoUID',
    SDDR_UID: 'sddrUID',
    SDSI_UID: 'sdsiUID',
    DATE: 'date',
};

export const routes = {
    DASHBOARD: '/dashboard',
    SUPPLIERS: '/suppliers',
    CUSTOMERS: '/customers',
    RAW_MATERIALS: '/rawMaterials',
    SFGS: '/semiFGs',
    FINISHED_GOODS: '/finishedGoods',
    INV_RMS: '/inventoryRMs',
    INV_SFGS: '/inventorySFGs',
    INV_FGS: '/inventoryFGs',
    INV_TRANSFERS: '/inventoryTransfers',
    PDPOS: '/PDPOs',
    PDDRS: '/PDDRs',
    PDSIS: '/PDSIs',
    ASSEMBLY: '/Assembly',
    PRODUCTION: '/ProductionOrder',
    SDPOS: '/SDPOs',
    SDDRS: '/SDDRs',
    SDSIS: '/SDSIs',
    PROFILE: '/profile',
    UAM: '/uam',
    APP_SETTINGS: '/appSettings',
    SETUP_CC: '/setupCC',
    SUBSCRIPTION_INVOICES: '/invoices',

    PRODUCTION_DELIVERED: '/ProductionDelivered',
    ASSEMBLY_DELIVERED: '/AssemblyDelivered',

    PDVRS: '/PDVRs',
    SDCRS: '/SDCRs',

    REPORTS: '/reports',
};

export const customerPriceList = {
    PRICE_LIST_1: 'Price List 1',
    PRICE_LIST_2: 'Price List 2',
    PRICE_LIST_3: 'Price List 3',
    PRICE_LIST_4: 'Price List 4',
    PRICE_LIST_5: 'Price List 5',
};

export const userActions = {
    CREATED: 'CREATED',
    UPDATED: 'UPDATED',
    DELETED: 'DELETED',

    SENT_FOR_APPROVAL: 'PENDING APPROVAL',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED',

    SENT_TO_SUPPLIER: 'SENT TO SUPPLIER',
    MARK_AS_DELIVERED: 'DELIVERED',
    MARK_AS_INVOICED: 'INVOICED',
    MARK_AS_COLLECTED: 'COLLECTED',

    OVERRIDDEN: 'OVERRIDDEN',

    PAYMENT_SENT: 'PAID',
    MARK_AS_PAID: 'PAID',

    QUOTE: 'QUOTE',
};

export const systemActions = {
    FULFILLED: 'FULFILLED',
    SENT_TO_SUPPLIER: userActions.SENT_TO_SUPPLIER,
    UPDATED: userActions.UPDATED,
    LOCKED: 'APPROVED - INVOICED',
    SDSI_LOCKED: 'DELIVERED - INVOICED',
    PAID: userActions.MARK_AS_PAID,
    IN_VOUCHER: 'IN VOUCHER',
    IN_COLLECTION: 'IN COLLECTION',

    UID: '<APP>',
    EMAIL: '<System>',
};

export const permissions = {
    RM_SETUP: 'rmSetup',
    SFG_SETUP: 'sfgSetup',
    FG_SETUP: 'fgSetup',
    INV_RM: 'inventoryRM',
    INV_SFG: 'inventorySFG',
    INV_FG: 'inventoryFG',
    INV_TRANSFER_REQUEST: 'inventoryTransferRequest',
    INV_TRANSFER_APPROVE: 'inventoryTransferApprove',
    CUSTOMER_SETUP: 'customerSetup',
    SUPPLIER_SETUP: 'supplierSetup',
    PDPO_REQUEST: 'pdpoRequest',
    PDPO_APPROVE: 'pdpoApprove',
    PDDR_REQUEST: 'pddrRequest',
    PDDR_APPROVE: 'pddrApprove',
    PDSI_REQUEST: 'pdsiRequest',
    PDSI_APPROVE: 'pdsiApprove',
    PDVR_REQUEST: 'pdvrRequest',
    PDVR_APPROVE: 'pdvrApprove',
    ASSEMBLY_ORDER_APPROVE: 'assemblyApprove',
    ASSEMBLY_ORDER_REQUEST: 'assemblyRequest',
    ASSEMBLY_DELIVERED_REQUEST: 'assemblyDeliveredRequest',
    ASSEMBLY_DELIVERED_APPROVE: 'assemblyDeliveredApprove',
    PROD_ORDER_REQUEST: 'prodOrderRequest',
    PROD_ORDER_APPROVE: 'prodOrderApprove',
    PRODUCTION_DELIVERED_REQUEST: 'productionDeliveredRequest',
    PRODUCTION_DELIVERED_APPROVE: 'productionDeliveredApprove',
    SDPO_REQUEST: 'sdpoRequest',
    SDPO_APPROVE: 'sdpoApprove',
    SDDR_REQUEST: 'sddrRequest',
    SDDR_APPROVE: 'sddrApprove',
    SDSI_REQUEST: 'sdsiRequest',
    SDSI_APPROVE: 'sdsiApprove',
    SDCR_REQUEST: 'sdcrRequest',
    SDCR_APPROVE: 'sdcrApprove',
    APP_ADMIN: 'AppAdmin',
    OWNER: 'Owner',
    VIEW_REPORTS: 'viewReports',
};

export const lengths = {
    masterListLimit: 5000,
    masterPOSDeptsLimits: 5000,
    usersList: 20,

    uid: 40,
    rmDesc: 20,
    rmType: 20,
    notes: 250,
    phone: 15,
    code: 10,
    unit: 10,
    contactPerson: 20,

    password: 20,
    displayName: 40,
    email: 40,
    noteLength: 1000,

    description: 40,

    numberInputLength: 10,
    numberInputAmount: 9999999,

    shortInputLength: 20,
    regInputLength: 40,

    regTextLength: 250,
    longTextLength: 350,

    sfgSetupRmArrLength: 20,

    fgSetupRmArrLength: 20,
    fgSetupSfgArrLength: 20,

    pdpoRmArrLength: 20,

    pddrPoArrLength: 20,

    pdsiFgOrSfgArrLength: 20,

    pdvrPdsiArrLength: 20,
    sdcrSdsiArrLength: 20,

    supplierCanvasLength: 5,
    costHistoryArrLength: 20,

    prodOrderSfgArr: 5,
    productionDeliveredArr: 4,

    assemblyFgArr: 5,
    assemblyDeliveredArr: 2,

    sdpoSfgArrLength: 20,
    sdpoFgArrLength: 20,

    sddrSdpoArrLength: 10,

    sdsiFgOrSfgArrLength: 20,

    invTransferItemsLength: 20,
};

export const regEx = {
    phone: /^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/,
    date: /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/,
    email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};
