import React from 'react';
import './InventorySfgFgItem.css';
import '../CollectionItems.css';
import { displayReadableNumber } from '../../../lib/util';

export default (props) => {
    let { obj, serialNumber, onClick, heading, keyProperty } = props;

    let prefix = keyProperty === 'sfgUID' ? 'sfg' : 'fg';

    let serial = 'S/No.';
    let itemName = prefix === 'sfg' ? 'SFG Name' : 'FG Name';
    let unit = 'Unit';
    let lowThreshold = 'Low Threshold';
    let projectedQty = 'Projected Qty';
    let actualQty = 'Actual Qty';
    let headingClass = 'text-bold list-table-heading';

    let serialLabel = 'S/No.:';
    let itemNameLabel = itemName;
    let unitLabel = 'Unit:';
    let lowLabel = 'Low Threshold:';
    let projectedLabel = 'Projected Qty:';
    let actualLabel = 'Actual Qty:';

    if (!heading) {
        serial = serialNumber;
        itemName = obj[`${prefix}UID`];
        unit = obj[`${prefix}Unit`];
        lowThreshold = obj[`${prefix}LowThreshold`];
        projectedQty = obj[`${prefix}ProjectedQty`];
        actualQty = obj[`${prefix}ActualQty`];
        headingClass = '';
    }

    let lowThresholdRed = '';
    let actualRed = '';
    let projectedRed = '';

    if (lowThreshold > actualQty && !heading) {
        lowThresholdRed = 'text-warning';
        actualRed = 'text-warning';
    }
    if (lowThreshold > projectedQty && !heading) {
        lowThresholdRed = 'text-warning';
        projectedRed = 'text-warning';
    }

    // let lowThresholdRed = !heading ? 'tj-right' : '';
    // let actualRed = !heading ? 'tj-right' : '';
    // let projectedRed = !heading ? 'tj-right' : '';

    // if (lowThreshold > actualQty && !heading) {
    //     lowThresholdRed = 'text-warning tj-right';
    //     actualRed = 'text-warning tj-right';
    // }
    // if (lowThreshold > projectedQty && !heading) {
    //     lowThresholdRed = 'text-warning tj-right';
    //     projectedRed = 'text-warning tj-right';
    // }

    if (!heading) {
        lowThreshold = displayReadableNumber(lowThreshold);
        projectedQty = displayReadableNumber(projectedQty);
        actualQty = displayReadableNumber(actualQty);
    }

    return (
        <div
            className={`inventory-sfgfg-item collection-item ${headingClass}`}
            onClick={onClick}
        >
            <div className='collection-item-entry serial'>
                {' '}
                <span className='entry-label'>{serialLabel}</span>
                {serial}
            </div>
            <div className='collection-item-entry name'>
                {' '}
                <span className='entry-label'>{itemNameLabel}</span>
                {itemName}
            </div>
            <div className='collection-item-entry unit'>
                {' '}
                <span className='entry-label'>{unitLabel}</span>
                {unit}
            </div>
            <div className='collection-item-entry lowthreshold'>
                {' '}
                <span className='entry-label'>{lowLabel}</span>
                <span className={lowThresholdRed}>{lowThreshold}</span>
            </div>
            <div className='collection-item-entry projected-qty'>
                {' '}
                <span className='entry-label'>{projectedLabel}</span>
                <span className={projectedRed}>{projectedQty}</span>
            </div>
            <div className='collection-item-entry actual-qty'>
                {' '}
                <span className='entry-label'>{actualLabel}</span>
                <span className={actualRed}>{actualQty}</span>
            </div>
        </div>
    );
};
