import React, { useState, useEffect, useContext } from 'react';
import {
    DataShareContext,
    AuthContext,
    MasterListContext,
} from '../../contexts';
import { useHistory } from 'react-router-dom';
import { routes, permissions, lengths } from '../../lib/constants';
import { InventoryButtons, BackOnlyButton } from '../../components';

import '../PagesCommon.css';
import '../RawMaterialsPage/RawMaterials.css';
import { displayReadableNumber } from '../../lib/util';

export default (props) => {
    let {
        currentDocument,

        backCancelBtnFn,
    } = props;

    let { masterInventoryRMsList } = useContext(MasterListContext);
    let { dataShare, setDataShare } = useContext(DataShareContext);
    let { authUser } = useContext(AuthContext);

    const history = useHistory();

    let [rmUID, setRmUID] = useState('');
    let [rmDescription, setRmDesciption] = useState('');
    let [rmCategory, setRmCategory] = useState('');
    let [rmCode, setRmCode] = useState('');
    let [rmType, setRmType] = useState('');
    let [rmUnit, setRmUnit] = useState('');
    let [rmLowThreshold, setRmLowThreshold] = useState('');
    let [rmActualQty, setRmActualQty] = useState('');
    let [rmProjectedQty, setRmProjectedQty] = useState('');

    useEffect(() => {
        setRmUID(currentDocument.rmUID);
        setRmDesciption(currentDocument.rmDescription);
        setRmCategory(currentDocument.rmCategory);
        setRmCode(currentDocument.rmCode);
        setRmType(currentDocument.rmType);
        setRmUnit(currentDocument.rmUnit);
        setRmLowThreshold(currentDocument.rmLowThreshold);
        setRmActualQty(currentDocument.rmActualQty);
        setRmProjectedQty(currentDocument.rmProjectedQty);
    }, []);

    useEffect(() => {
        if (dataShare.isActive) history.push(routes.PDPOS);
    }, [dataShare.isActive]);

    const createPoFn = () => setDataShare({ isActive: true, data: rmUID });

    // conditional render of contents  =============================
    let mainContent = (
        <section className='form-main-section'>
            <div className='form-profile-info-items'>
                <p className='parent-labels'>RM Type</p>
                <p className=''>{rmType}</p>
            </div>
            <div className='form-profile-info-items'>
                <p className='parent-labels'>RM Description</p>
                <p className=''>{rmDescription}</p>
            </div>
            <div className='form-profile-info-items'>
                <p className='parent-labels'>RM Category</p>
                <p className=''>{rmCategory}</p>
            </div>
            <div className='form-profile-info-items'>
                <p className='parent-labels'>RM Code</p>
                <p className=''>{rmCode}</p>
            </div>
            <div className='form-profile-info-items'>
                <p className='parent-labels'>RM Unit</p>
                <p className=''>{rmUnit}</p>
            </div>
            <div className='form-profile-info-items'>
                <p className='parent-labels'>RM Low Threshold</p>
                <p className=''>{displayReadableNumber(rmLowThreshold)}</p>
            </div>
            <div className='form-profile-info-items'>
                <p className='parent-labels'>RM Projected Qty</p>
                <p className=''>{displayReadableNumber(rmProjectedQty)}</p>
            </div>
            <div className='form-profile-info-items'>
                <p className='parent-labels'>RM Actual Qty</p>
                <p className=''>{displayReadableNumber(rmActualQty)}</p>
            </div>
        </section>
    );

    let buttonSection = (
        <InventoryButtons
            backBtnFn={backCancelBtnFn}
            createBtnFn={createPoFn}
            createBtnText='Create Purchase Order'
        />
    );
    if (
        !authUser.permissions.includes(permissions.INV_RM) ||
        masterInventoryRMsList.length >= lengths.masterListLimit ||
        !authUser.subscriptionStatusIsActive
    ) {
        buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    }

    return (
        <div className='common-form'>
            <section className='form-title-section'>
                Raw Material Profile
            </section>
            {mainContent}
            {buttonSection}
            <div className='general-error-message'></div>
            <div className='watermark'></div>
        </div>
    );
};
