export default (listStringFormat, prefix) => {
    let newArr = [];
    let sdObjInObjFormat = JSON.parse(listStringFormat);

    for (let key in sdObjInObjFormat) {
        let obj = {
            [`${prefix}UID`]: key,
            customerUID: sdObjInObjFormat[key].a,
            [`${prefix}Date`]: sdObjInObjFormat[key].b,
            status: sdObjInObjFormat[key].c,
        };

        if (prefix === 'sdcr') {
            obj.sdcrTotalsNett = sdObjInObjFormat[key].d
                ? sdObjInObjFormat[key].d
                : '';
        }

        newArr.unshift(obj);
    }

    return newArr;
};
