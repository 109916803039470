/**
 *
 * schema of cost History document
 *
 * {
 *      chUID: 'rmUID - supplierName',
 *      cost:
 *      costDate:
 *      costHistoryArr: [
 *          {
 *              cost:
 *              costDate:
 *          }
 *      ]
 * }
 *
 *
 */

import { db } from '../firebase_config/firebaseConfig';
import { responseFn, formatCustomErrorObj } from '../lib/util';

import { COST_HISTORY_COL } from '../lib/constants';

// ================================================
// function to fetch each costHistory document.
const getCostHistoryDocFn = async (docID) => {
    let customerRef = db.collection(COST_HISTORY_COL).doc(docID);
    try {
        let document = await customerRef.get();
        if (document.exists)
            return responseFn(
                document.data(),
                false,
                null,
                'App: Get Cost History Document Successful.'
            );
        else
            return responseFn(
                null,
                true,
                formatCustomErrorObj(
                    1000,
                    'App: Cost History Document does not exist.'
                ),
                'App: Cost History Document does not exist.'
            );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            'App: Unexpected Error Getting Cost History Document.'
        );
    }
};

export default {
    getCostHistoryDocFn,
};
