import { db, firebase } from '../firebase_config/firebaseConfig';
import { userActions, systemActions } from '../lib/constants';
import {
    responseFn,
    formatCustomErrorObj,
    formatAlertErrorObj,
    saveWithFiveDecimals,
} from '../lib/util';
import validate from '../validators';
import mListHelper from './mListHelper';
import dbopsGetDocument from './dbopsGetDocument';
import { docNames, appMessage } from '../lib/messages';
import {
    sdObjToMListItem,
    sfgInvObjToMListItemFn,
    fgInvObjToMListItemFn,
} from '../lib/masterListConverters';

import {
    FINISHED_GOODS_COL,
    SEMI_FGS_COL,
    SDDRS_COL,
    MASTER_LISTS_COL,
    MASTER_SDDR_LIST_DOC,
    MASTER_SDPO_LIST_DOC,
    SDPOS_COL,
    DELETED_SDDRS_COL,
    SDDR_COUNTER_DOC,
    SG_NUMBERS_COL,
    MASTER_INVENTORY_FG_LIST_DOC,
    MASTER_INVENTORY_SFG_LIST_DOC,
} from '../lib/constants';

// ================================================
// function to fetch SDDR document.
const getSddrFn = async (docID) => dbopsGetDocument(SDDRS_COL, docID, 'SDDR');

// ======================================================
const createSddrFn = async (userCreds, sddrObj) => {
    //! sddrObj.sdpoArr has same sddrUID at the same index as documentBasisSdpoArr.

    // add metadata to sddrObj to be created in firestore.
    sddrObj.meta = {
        action: userActions.CREATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // add metaHistory
    sddrObj.metaHistory = [];

    const transactionFn = async (transaction) => {
        let sddrCounterRef = db
            .collection(SG_NUMBERS_COL)
            .doc(SDDR_COUNTER_DOC);

        let sddrCounterDoc = await transaction.get(sddrCounterRef);
        let currentSddrNumber = sddrCounterDoc.data().SddrNumber.toString();

        // add server generated number to sddrObj.
        sddrObj.sddrUID = currentSddrNumber;
        sddrObj.sddrNumber = currentSddrNumber;

        // =============================================================
        // perform 'transaction get operations' =========================
        let sddrRef = db.collection(SDDRS_COL).doc(sddrObj.sddrUID);
        let sddrListRef = db
            .collection(MASTER_LISTS_COL)
            .doc(MASTER_SDDR_LIST_DOC);

        let sddrDoc = await transaction.get(sddrRef);
        let sddrListDoc = await transaction.get(sddrListRef);

        if (sddrDoc.exists) {
            return Promise.reject(
                //! this should never run as SDPO number is server generated.
                formatCustomErrorObj(
                    1002,
                    appMessage.alreadyExist(docNames.SDDR)
                )
            );
        }

        await transaction.set(sddrRef, sddrObj);

        // update master list
        if (sddrListDoc.exists) {
            let sddrInfoObj = sdObjToMListItem(sddrObj);

            let newData = mListHelper.updateMasterList(
                sddrListDoc,
                sddrObj.sddrUID,
                sddrInfoObj
            );

            await transaction.set(sddrListRef, newData);
        } else {
            let sddrInfoObj = sdObjToMListItem(sddrObj);

            let newData = mListHelper.addMasterList(
                sddrObj.sddrUID,
                sddrInfoObj
            );

            await transaction.set(sddrListRef, newData);
        }

        await transaction.set(
            sddrCounterRef,
            { SddrNumber: sddrCounterDoc.data().SddrNumber + 1 },
            { merge: true }
        );

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyCreated(docNames.SDDR)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorCreating(docNames.SDDR)
        );
    }
};

// ===============================================
const updateSddrFn = async (userCreds, sddrObj, documentBasis) => {
    if (sddrObj.metaHistory.length > 99) sddrObj.metaHistory.shift();

    sddrObj.metaHistory.push(sddrObj.meta);

    // update metadata in sddrObj.
    sddrObj.meta = {
        action: userActions.UPDATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // get references for documents needed. =========================
    let sddrRef = db.collection(SDDRS_COL).doc(sddrObj.sddrUID);
    let sddrListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_SDDR_LIST_DOC);

    // end get references ===========================================

    const transactionFn = async (transaction) => {
        // =============================================================
        // perform 'transaction get operations' =========================
        let sddrDoc = await transaction.get(sddrRef);
        let sddrListDoc = await transaction.get(sddrListRef);

        if (!sddrDoc.exists) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.SDDR)
                )
            );
        }

        if (!validate.noChange(sddrDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.SDDR)
                )
            );
        }

        await transaction.set(sddrRef, sddrObj);

        // update master list
        if (sddrListDoc.exists) {
            let sddrInfoObj = sdObjToMListItem(sddrObj);

            let newData = mListHelper.updateMasterList(
                sddrListDoc,
                sddrObj.sddrUID,
                sddrInfoObj
            );

            await transaction.set(sddrListRef, newData);
        } else {
            let sddrInfoObj = sdObjToMListItem(sddrObj);

            let newData = mListHelper.addMasterList(
                sddrObj.sddrUID,
                sddrInfoObj
            );

            await transaction.set(sddrListRef, newData);
        }

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyUpdated(docNames.SDDR)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorUpdating(docNames.SDDR)
        );
    }
};

// ================================================================
const deleteSddrFn = async (userCreds, sddrObj, documentBasis) => {
    // update meta object and metaHistory.
    if (sddrObj.metaHistory.length > 99) sddrObj.metaHistory.shift();

    sddrObj.metaHistory.push(sddrObj.meta);

    sddrObj.meta = {
        action: userActions.DELETED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // get references for documents needed. =========================
    let sddrRef = db.collection(SDDRS_COL).doc(sddrObj.sddrUID);
    let deletedSddrRef = db.collection(DELETED_SDDRS_COL).doc();
    let sddrListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_SDDR_LIST_DOC);

    // end get references ===========================================

    const transactionFn = async (transaction) => {
        // =============================================================
        // perform 'transaction get operations' =========================
        let sddrDoc = await transaction.get(sddrRef);
        let sddrListDoc = await transaction.get(sddrListRef);
        await transaction.get(deletedSddrRef);

        // end get operations ===================================================

        if (!sddrDoc.exists) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotDeleteBecauseDocNotExist(docNames.SDDR)
                )
            );
        }

        if (!validate.noChange(sddrDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotDeleteBecauseDocChanged(docNames.SDDR)
                )
            );
        }

        let newSddrListData = mListHelper.deletePropertyFromMasterList(
            sddrListDoc,
            sddrObj.sddrUID
        );

        await transaction.delete(sddrRef); // delete the dr in the dr collection.
        await transaction.set(deletedSddrRef, sddrObj); // add the dr in the deletedDr Col.
        await transaction.set(sddrListRef, newSddrListData); // masterlist pddr

        return Promise.resolve('TransactionFn Completed Successfully');
    };
    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyDeleted(docNames.SDDR)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorDeleting(docNames.SDDR)
        );
    }
};

// ================================================================
const statusChangeFn = async (userCreds, sddrObj, documentBasis) => {
    // move old metadate into metahistory. ensure meta data history length
    // is 100 or less. will be checked by security rules.
    if (sddrObj.metaHistory.length > 99) sddrObj.metaHistory.shift();

    sddrObj.metaHistory.push(sddrObj.meta);

    // update metadata in sddrObj.
    sddrObj.meta = {
        action: userCreds.action,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // get references.
    let sddrRef = db.collection(SDDRS_COL).doc(sddrObj.sddrUID);
    let sddrListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_SDDR_LIST_DOC);

    const transactionFn = async (transaction) => {
        let sddrDoc = await transaction.get(sddrRef);
        let sddrListDoc = await transaction.get(sddrListRef);

        if (!sddrDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.SDDR)
                )
            );
        // ! this does not check meta data.
        if (!validate.noChange(sddrDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.SDDR)
                )
            );
        }
        // ! meta data check if status of document changed.
        if (sddrDoc.data().meta.action !== documentBasis.meta.action) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.SDDR)
                )
            );
        }

        let sddrInfoObj = sdObjToMListItem(sddrObj);

        let newData = mListHelper.updateMasterList(
            sddrListDoc,
            sddrObj.sddrUID,
            sddrInfoObj
        );

        // write to masterlist and sdpo document in firebase.
        await transaction.set(sddrListRef, newData);
        await transaction.set(sddrRef, sddrObj);
        return Promise.resolve('TransactionFn Completed Successfully');
    };
    try {
        await db.runTransaction(transactionFn);
        return responseFn(null, false, null, appMessage.successfulUpdateStatus);
    } catch (e) {
        return responseFn(null, true, e, appMessage.errorUpdatingStatus);
    }
};

const approveSddrFn = async (
    userCreds,
    sddrObj,
    documentBasis,
    documentBasisSdpoArr
) => {
    if (sddrObj.metaHistory.length > 99) sddrObj.metaHistory.shift();

    sddrObj.metaHistory.push(sddrObj.meta);

    // update metadata in sddrObj.
    sddrObj.meta = {
        action: userCreds.action,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // get references for documents needed. =========================
    let sddrRef = db.collection(SDDRS_COL).doc(sddrObj.sddrUID);
    let sddrListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_SDDR_LIST_DOC);
    let sdpoListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_SDPO_LIST_DOC);

    let documentBasisSdpoArrRefs = documentBasisSdpoArr.map((sdpo) =>
        db.collection(SDPOS_COL).doc(sdpo.sdpoUID)
    );

    // create a map of all the fgObjs from all the pdpoObjs.
    let fgMap = {};

    sddrObj.sdpoArr.forEach((sdpo) => {
        sdpo.fgArr.forEach((fg) => {
            fgMap[fg.fgUID] = {
                computedDeliveredQty: 0,
                newSdpoNumbersArr: [],
            };
        });
    });

    // get references for Fgs involved.
    let fgMapRefs = {};
    for (let key in fgMap) {
        fgMapRefs[key] = db.collection(FINISHED_GOODS_COL).doc(key);
    }

    // create a map of all the sfgObjs from all the pdpoObjs.
    let sfgMap = {};

    sddrObj.sdpoArr.forEach((sdpo) => {
        sdpo.sfgArr.forEach((sfg) => {
            sfgMap[sfg.sfgUID] = {
                computedDeliveredQty: 0,
                newSdpoNumbersArr: [],
            };
        });
    });

    // get references for Fgs involved.
    let sfgMapRefs = {};
    for (let key in sfgMap) {
        sfgMapRefs[key] = db.collection(SEMI_FGS_COL).doc(key);
    }

    let sfgInvListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_INVENTORY_SFG_LIST_DOC);

    let fgInvListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_INVENTORY_FG_LIST_DOC);

    // end get references ===========================================

    let existError = false;
    let editedError = false;
    let balanceError = false;

    let whatFG = [];
    let whatSFG = [];
    let sfgBalanceErrorMsg = '';
    let fgBalanceErrorMsg = '';

    const transactionFn = async (transaction) => {
        // =============================================================
        // START TRANSACTION GET =================================
        let sddrDoc = await transaction.get(sddrRef);
        let sddrListDoc = await transaction.get(sddrListRef);
        let sdpoListDoc = await transaction.get(sdpoListRef);

        let fgMapDocs = {};
        for (let key in fgMapRefs) {
            fgMapDocs[key] = await transaction.get(fgMapRefs[key]);
        }

        let sfgMapDocs = {};
        for (let key in sfgMapRefs) {
            sfgMapDocs[key] = await transaction.get(sfgMapRefs[key]);
        }

        let documentBasisSdpoArrDocs = documentBasisSdpoArrRefs.map(
            async (poRef) => await transaction.get(poRef)
        );
        try {
            documentBasisSdpoArrDocs = await Promise.all(
                documentBasisSdpoArrDocs
            );
        } catch (e) {
            return Promise.reject(
                formatCustomErrorObj(
                    900,
                    appMessage.prommiseAllError('dbopsSDDRs', 'sdpoArr')
                )
            );
        }

        let sfgInvListDoc = await transaction.get(sfgInvListRef);
        let fgInvListDoc = await transaction.get(fgInvListRef);

        // END TRANSACTION GET =============================================

        // START CHECK FOR CHANGE AND EXISTENCE =================================

        if (!sddrDoc.exists) {
            return Promise.reject(
                //! this should never run as SDPO number is server generated.
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.SDDR)
                )
            );
        }

        // ! this does not check meta data.
        if (!validate.noChange(sddrDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.SDDR)
                )
            );
        }
        // ! meta data check if status of document changed.
        if (sddrDoc.data().meta.action !== documentBasis.meta.action) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.SDDR)
                )
            );
        }

        for (let key in fgMapDocs) {
            if (!fgMapDocs[key].exists) existError = true;
        }
        //! this is an edge case scenario. should not really happen but is possible.
        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopsSDDRs',
                        docNames.SDDR,
                        'FG'
                    )
                )
            );
        }

        for (let key in sfgMapDocs) {
            if (!sfgMapDocs[key].exists) existError = true;
        }
        //! this is an edge case scenario. should not really happen but is possible.
        if (existError) {
            console.error(
                'Delete SDDR function. FG exist error. One of the SFGs have already been deleted. Unable to apply changes and change SDPO status back from FULFILLED.'
            );
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopsSDDRs',
                        docNames.SDDR,
                        'SFG'
                    )
                )
            );
        }

        documentBasisSdpoArrDocs.forEach((docBasisSdpo, index) => {
            if (!docBasisSdpo.exists) existError = true;

            if (
                !validate.noChange(
                    docBasisSdpo.data(),
                    documentBasisSdpoArr[index]
                )
            )
                editedError = true;
        });

        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopsSDDRs',
                        docNames.SDDR,
                        'SDPO'
                    )
                )
            );
        }
        if (editedError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseOneItemInArrayChanged(
                        'dbopsSDDRs',
                        docNames.SDDR,
                        'SDPO'
                    )
                )
            );
        }

        // END CHECK FOR CHANGE AND EXISTENCE ==================================

        // START TRANSACTION SET ===============================================

        // copy all the sdpoNumbersArr of rms from firebase to fgMap.newSdpoNumbersArr.
        for (let key in fgMapDocs) {
            fgMap[key].newSdpoNumbersArr = [
                ...fgMapDocs[key].data().sdpoNumbersArr,
            ];
        }

        for (let key in sfgMapDocs) {
            sfgMap[key].newSdpoNumbersArr = [
                ...sfgMapDocs[key].data().sdpoNumbersArr,
            ];
        }

        // determine the correct fgDeliveredQty to be subtracted for each FG.
        sddrObj.sdpoArr.forEach((sdpo) => {
            sdpo.fgArr.forEach((fg) => {
                // fgMap should include the fg.fgUID as property.
                fgMap[fg.fgUID].computedDeliveredQty = saveWithFiveDecimals(
                    fgMap[fg.fgUID].computedDeliveredQty +
                        fg.sddrDeliveredQtyForFg
                );
            });
        });
        // same for sfg
        sddrObj.sdpoArr.forEach((sdpo) => {
            sdpo.sfgArr.forEach((sfg) => {
                sfgMap[sfg.sfgUID].computedDeliveredQty = saveWithFiveDecimals(
                    sfgMap[sfg.sfgUID].computedDeliveredQty +
                        sfg.sddrDeliveredQtyForSfg
                );
            });
        });

        // ================================================================
        // Change balance qty and delivered Qty in the sdpoObjects read from firebase.

        //! build a temporary masterList that can be updated every time a poObj
        //! is checked in the sdpoArr.
        let tempMasterSDPOList = sdpoListDoc.data();

        sddrObj.sdpoArr.forEach(async (sdpo, poIndex) => {
            // make a copy of the sddrObj. change fgObjs in fgArr with correct
            // fgBalanceQty and fgDeliveredQty. update firebase sddrObj with this
            // new sddrObj.
            let sdpoCopy = {
                ...documentBasisSdpoArrDocs[poIndex].data(),
            };

            // variable to be used to check if sdpo can be marked 'FULFILLED'
            let allFgsDelivered = [];

            sdpo.fgArr.forEach((fg, fgIndex) => {
                let fgObjFromSdpo_DeliveredQty =
                    sdpoCopy.fgArr[fgIndex].fgDeliveredQty;
                let fgObjFromSdpo_BalanceQty =
                    sdpoCopy.fgArr[fgIndex].fgBalanceQty;

                // check balance qty and delivered qty.
                if (
                    saveWithFiveDecimals(
                        saveWithFiveDecimals(
                            fgObjFromSdpo_BalanceQty +
                                sdpoCopy.fgArr[fgIndex].fgOverrideQty
                        ) - fg.sddrDeliveredQtyForFg
                    ) < 0
                ) {
                    balanceError = true;
                }
                // end test ==================================================

                sdpoCopy.fgArr[fgIndex] = {
                    ...sdpoCopy.fgArr[fgIndex],
                    fgDeliveredQty: saveWithFiveDecimals(
                        fgObjFromSdpo_DeliveredQty + fg.sddrDeliveredQtyForFg
                    ),
                    fgBalanceQty: saveWithFiveDecimals(
                        fgObjFromSdpo_BalanceQty - fg.sddrDeliveredQtyForFg
                    ),
                };

                // if balanceQty of this particular FG is O, push true to
                // allFgsDelivered Array to make system aware if status can be changed
                // to FULFILLED. //! integrated fgOverrideQty during calculations.
                if (
                    saveWithFiveDecimals(
                        sdpoCopy.fgArr[fgIndex].fgBalanceQty +
                            sdpoCopy.fgArr[fgIndex].fgOverrideQty
                    ) === 0
                ) {
                    allFgsDelivered.push(true);
                } else allFgsDelivered.push(false);
            });

            // variable to be used to check if sdpo can be marked 'FULFILLED'
            let allSfgsDelivered = [];

            sdpo.sfgArr.forEach((sfg, sfgIndex) => {
                let sfgObjFromSdpo_DeliveredQty =
                    sdpoCopy.sfgArr[sfgIndex].sfgDeliveredQty;
                let sfgObjFromSdpo_BalanceQty =
                    sdpoCopy.sfgArr[sfgIndex].sfgBalanceQty;

                // check balance qty and delivered qty.
                if (
                    saveWithFiveDecimals(
                        saveWithFiveDecimals(
                            sfgObjFromSdpo_BalanceQty +
                                sdpoCopy.sfgArr[sfgIndex].sfgOverrideQty
                        ) - sfg.sddrDeliveredQtyForSfg
                    ) < 0
                ) {
                    balanceError = true;
                }
                // end test ==================================================

                sdpoCopy.sfgArr[sfgIndex] = {
                    ...sdpoCopy.sfgArr[sfgIndex],
                    sfgDeliveredQty: saveWithFiveDecimals(
                        sfgObjFromSdpo_DeliveredQty + sfg.sddrDeliveredQtyForSfg
                    ),
                    sfgBalanceQty: saveWithFiveDecimals(
                        sfgObjFromSdpo_BalanceQty - sfg.sddrDeliveredQtyForSfg
                    ),
                };

                if (
                    saveWithFiveDecimals(
                        sdpoCopy.sfgArr[sfgIndex].sfgBalanceQty +
                            sdpoCopy.sfgArr[sfgIndex].sfgOverrideQty
                    ) === 0
                ) {
                    allSfgsDelivered.push(true);
                } else allSfgsDelivered.push(false);
            });

            // check if status of this SDPO can be changed to FULFILLED.

            if (
                allFgsDelivered.every((ans) => ans === true) &&
                allSfgsDelivered.every((ans) => ans === true)
            ) {
                //!================================================================
                // since status is changing to FULFILLED, remove sdpoUID
                // in each of the related FGs and SFGs.
                sdpoCopy.fgArr.forEach((fg) => {
                    let newArr = [...fgMap[fg.fgUID].newSdpoNumbersArr];
                    newArr = newArr.filter(
                        (sdpoNumber) => sdpoNumber !== sdpoCopy.sdpoUID
                    );
                    fgMap[fg.fgUID].newSdpoNumbersArr = newArr;
                });
                sdpoCopy.sfgArr.forEach((sfg) => {
                    let newArr = [...sfgMap[sfg.sfgUID].newSdpoNumbersArr];
                    newArr = newArr.filter(
                        (sdpoNumber) => sdpoNumber !== sdpoCopy.sdpoUID
                    );
                    sfgMap[sfg.sfgUID].newSdpoNumbersArr = newArr;
                });
                //! ============================================================

                // change status of sdpo object.
                if (sdpoCopy.metaHistory.length > 99)
                    sdpoCopy.metaHistory.shift();

                sdpoCopy.metaHistory.push(sdpoCopy.meta);

                // update metadata in sdpoCopy.
                sdpoCopy.meta = {
                    action: systemActions.FULFILLED,
                    uid: systemActions.UID,
                    email: systemActions.EMAIL,
                    timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
                };

                // update temporary sdpo master list
                let sdpoInfoObj = sdObjToMListItem(sdpoCopy);

                // this variable will be used outside the loop to update
                // the masterList ONCE.
                tempMasterSDPOList = mListHelper.updateTempMList(
                    tempMasterSDPOList,
                    sdpoCopy.sdpoUID,
                    sdpoInfoObj
                );
            }

            // set updated PoObj with transactions.
            await transaction.set(documentBasisSdpoArrRefs[poIndex], sdpoCopy, {
                merge: true,
            });
        });

        if (balanceError) {
            return Promise.reject(
                formatCustomErrorObj(
                    800,
                    appMessage.balanceError(
                        'Delivered Qty',
                        'Balance Qty',
                        'SFG or FG'
                    )
                )
            );
        }

        // After updating all the SDPO's, update the master list of PDPOs
        await transaction.set(sdpoListRef, tempMasterSDPOList);

        // =================================================================
        // =================================================================

        // for FG inventory process.
        let tempFgInventoryList = fgInvListDoc.data();

        for (let key in fgMap) {
            let data = {
                fgActualQty: saveWithFiveDecimals(
                    fgMapDocs[key].data().fgActualQty -
                        fgMap[key].computedDeliveredQty
                ),
                sdpoNumbersArr: fgMap[key].newSdpoNumbersArr,
            };

            if (data.fgActualQty < 0) {
                balanceError = true;
                whatFG.push(fgMapDocs[key].data().fgUID);
                fgBalanceErrorMsg = 'Insufficient FG Actual Qty';
            }

            let fgCopy = fgMapDocs[key].data();
            fgCopy.fgActualQty = data.fgActualQty;
            let inventoryFgInfoObj = fgInvObjToMListItemFn(fgCopy);

            tempFgInventoryList = mListHelper.updateTempMList(
                tempFgInventoryList,
                fgCopy.fgUID,
                inventoryFgInfoObj
            );

            await transaction.set(fgMapRefs[key], data, { merge: true });
        }

        // for SFG inventory process.
        let tempSfgInventoryList = sfgInvListDoc.data();

        for (let key in sfgMap) {
            let data = {
                sfgActualQty: saveWithFiveDecimals(
                    sfgMapDocs[key].data().sfgActualQty -
                        sfgMap[key].computedDeliveredQty
                ),
                sdpoNumbersArr: sfgMap[key].newSdpoNumbersArr,
            };

            if (data.sfgActualQty < 0) {
                balanceError = true;
                whatSFG.push(sfgMapDocs[key].data().sfgUID);
                sfgBalanceErrorMsg = 'Insufficient FG Actual Qty';
            }

            let sfgCopy = sfgMapDocs[key].data();
            sfgCopy.sfgActualQty = data.sfgActualQty;
            let inventorySfgInfoObj = sfgInvObjToMListItemFn(sfgCopy);

            tempSfgInventoryList = mListHelper.updateTempMList(
                tempSfgInventoryList,
                sfgCopy.sfgUID,
                inventorySfgInfoObj
            );

            await transaction.set(sfgMapRefs[key], data, { merge: true });
        }

        // will be triggered if there is a balance error from sfg or fg.
        if (balanceError) {
            return Promise.reject(
                formatCustomErrorObj(800, `Insufficient FG Actual Qty`)
            );
        }

        await transaction.set(sddrRef, sddrObj);

        // set new sfg inventory master list
        await transaction.set(sfgInvListRef, tempSfgInventoryList);

        // set new fg inventory master list
        await transaction.set(fgInvListRef, tempFgInventoryList);

        // update master list
        if (sddrListDoc.exists) {
            let sddrInfoObj = sdObjToMListItem(sddrObj);

            let newData = mListHelper.updateMasterList(
                sddrListDoc,
                sddrObj.sddrUID,
                sddrInfoObj
            );

            await transaction.set(sddrListRef, newData);
        } else {
            let sddrInfoObj = sdObjToMListItem(sddrObj);

            let newData = mListHelper.addMasterList(
                sddrObj.sddrUID,
                sddrInfoObj
            );

            await transaction.set(sddrListRef, newData);
        }

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(null, false, null, appMessage.successfulUpdateStatus);
    } catch (e) {
        let customErrorObj =
            sfgBalanceErrorMsg || fgBalanceErrorMsg
                ? formatAlertErrorObj(
                      sfgBalanceErrorMsg,
                      whatSFG,
                      fgBalanceErrorMsg,
                      whatFG
                  )
                : null;
        return responseFn(
            customErrorObj,
            true,
            e,
            appMessage.errorUpdatingStatus
        );
    }
};

export default {
    getSddrFn,
    createSddrFn,
    updateSddrFn,
    deleteSddrFn,
    statusChangeFn,
    approveSddrFn,
};
