import React from 'react';

export default (props) => {
    let { cancelFn, saveFn, saveText = 'Save', icon } = props;

    let buttonSection = (
        <section className='form-button-section'>
            <button className='left-button-control btn' onClick={cancelFn}>
                Cancel
            </button>

            <span className='m-top-10 m-right-10'>{icon}</span>
            <button
                // className='right-button-control btn btn-primary'
                className='btn btn-primary'
                onClick={saveFn}
            >
                {saveText}
            </button>
        </section>
    );

    return buttonSection;
};
