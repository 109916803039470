import { responseFn, saveWithTwoDecimalOnly } from '../lib/util';
import { regEx, lengths } from '../lib/constants';
import { generalMessage, appMessage } from '../lib/messages';
import { saveWithFiveDecimals } from '../lib/util';

export const assemblyDelivered = (assemblyDeliveredCopy) => {
    delete assemblyDeliveredCopy.assemblyDeliveredDateError;
    delete assemblyDeliveredCopy.assemblyOrderArrError;
    delete assemblyDeliveredCopy.noteError;
    delete assemblyDeliveredCopy.assemblyDeliveredDescriptionError;

    assemblyDeliveredCopy.assemblyOrderArr.forEach((assemblyOrder) => {
        assemblyOrder.fgArr.forEach((fg) => {
            delete fg.fgAssembledQtyForThisAssemblyError;
            fg.fgAssembledQtyForThisAssembly = saveWithFiveDecimals(
                fg.fgAssembledQtyForThisAssembly
            );

            fg.rmArr.forEach((rm) => {
                delete rm.rmUsedQtyForThisFgAssembledError;
                rm.rmUsedQtyForThisFgAssembled = saveWithFiveDecimals(
                    rm.rmUsedQtyForThisFgAssembled
                );
            });

            fg.sfgArr.forEach((sfg) => {
                delete sfg.sfgUsedQtyForThisFgAssembledError;
                sfg.sfgUsedQtyForThisFgAssembled = saveWithFiveDecimals(
                    sfg.sfgUsedQtyForThisFgAssembled
                );
            });
        });
    });

    let hackError = '';
    let objError = false;
    let generalError = '';

    if (assemblyDeliveredCopy.assemblyDeliveredDate === '') {
        objError = true;
        assemblyDeliveredCopy.assemblyDeliveredDateError =
            generalMessage.REQUIRED;
    }

    if (!regEx.date.test(assemblyDeliveredCopy.assemblyDeliveredDate)) {
        objError = true;
        assemblyDeliveredCopy.assemblyDeliveredDateError =
            generalMessage.INVALID_DATE;
    }

    if (assemblyDeliveredCopy.note.length > lengths.noteLength) {
        objError = true;
        assemblyDeliveredCopy.noteError = generalMessage.INVALID_LENGTH;
    }

    if (
        assemblyDeliveredCopy.assemblyDeliveredDescription.length >
        lengths.description
    ) {
        objError = true;
        assemblyDeliveredCopy.assemblyDeliveredDescriptionError =
            generalMessage.INVALID_LENGTH;
    }

    if (assemblyDeliveredCopy.assemblyOrderArr.length < 1) {
        generalError = 'Must have atleast one Assembly Order';
    }

    if (
        assemblyDeliveredCopy.assemblyOrderArr.length >
        lengths.assemblyDeliveredArr
    ) {
        hackError = appMessage.sourceCodeErrorMessage(
            'Assembly Delivered Page: assemblyOrderArr length error.'
        );
    }

    assemblyDeliveredCopy.assemblyOrderArr.forEach((assemblyOrder) => {
        assemblyOrder.fgArr.forEach((fg) => {
            // validate fgAssembledQtyForThisAssembly
            if (isNaN(fg.fgAssembledQtyForThisAssembly)) {
                objError = true;
                fg.fgAssembledQtyForThisAssemblyError = generalMessage.INVALID;
            }

            if (fg.fgAssembledQtyForThisAssembly < 0) {
                objError = true;
                fg.fgAssembledQtyForThisAssemblyError =
                    generalMessage.POSITIVE_NUMBER;
            }

            if (fg.fgAssembledQtyForThisAssembly > lengths.numberInputAmount) {
                objError = true;
                fg.fgAssembledQtyForThisAssemblyError =
                    generalMessage.INVALID_AMOUNT;
            }

            // validate fgAssembledQtyForThisAssembly. compare against sfgBalanceQty.
            // must not go over sfgBalanceQty.
            if (
                fg.fgAssembledQtyForThisAssembly >
                saveWithFiveDecimals(fg.fgBalanceQty + fg.fgOverrideQty)
            ) {
                objError = true;
                fg.fgAssembledQtyForThisAssemblyError =
                    'Assembled Qty cannot exceed the Balance Qty';
            }

            fg.rmArr.forEach((rm) => {
                // validate rmUsedQtyForThisFgAssembled
                if (isNaN(rm.rmUsedQtyForThisFgAssembled)) {
                    objError = true;
                    rm.rmUsedQtyForThisFgAssembledError =
                        generalMessage.INVALID;
                }

                if (rm.rmUsedQtyForThisFgAssembled < 0) {
                    objError = true;
                    rm.rmUsedQtyForThisFgAssembledError =
                        generalMessage.POSITIVE_NUMBER;
                }

                if (
                    rm.rmUsedQtyForThisFgAssembled > lengths.numberInputAmount
                ) {
                    objError = true;
                    rm.rmUsedQtyForThisFgAssembledError =
                        generalMessage.INVALID_AMOUNT;
                }

                // validate rmUsedQtyForThisFgAssembled. compare against rmBalanceQty.
                // must not go over rmBalanceQty.
                if (
                    rm.rmUsedQtyForThisFgAssembled >
                    saveWithFiveDecimals(rm.rmBalanceQty + rm.rmOverrideQty)
                ) {
                    objError = true;
                    rm.rmUsedQtyForThisFgAssembledError =
                        'Actual Used Qty cannot exceed the Balance Qty';
                }
            });

            fg.sfgArr.forEach((sfg) => {
                // validate rmUsedQtyForThisFgAssembled
                if (isNaN(sfg.sfgUsedQtyForThisFgAssembled)) {
                    objError = true;
                    sfg.sfgUsedQtyForThisFgAssembledError =
                        generalMessage.INVALID;
                }

                if (sfg.sfgUsedQtyForThisFgAssembled < 0) {
                    objError = true;
                    sfg.sfgUsedQtyForThisFgAssembledError =
                        generalMessage.POSITIVE_NUMBER;
                }

                if (
                    sfg.sfgUsedQtyForThisFgAssembled > lengths.numberInputAmount
                ) {
                    objError = true;
                    sfg.sfgUsedQtyForThisFgAssembledError =
                        generalMessage.INVALID_AMOUNT;
                }

                // validate rmUsedQtyForThisFgAssembled. compare against rmBalanceQty.
                // must not go over rmBalanceQty.
                if (
                    sfg.sfgUsedQtyForThisFgAssembled >
                    saveWithFiveDecimals(sfg.sfgBalanceQty + sfg.sfgOverrideQty)
                ) {
                    objError = true;
                    sfg.sfgUsedQtyForThisFgAssembledError =
                        'Actual Used Qty cannot exceed the Balance Qty';
                }
            });
        });
    });

    if (hackError || objError || generalError) {
        let errorObj = {
            assemblyDeliveredObj: assemblyDeliveredCopy,
            hackError,
            generalError,
        };
        return responseFn(null, true, errorObj, 'Error detected');
    }

    let validAssemblyOrderArr = assemblyDeliveredCopy.assemblyOrderArr.map(
        (assemblyOrder) => {
            let validFgArr = assemblyOrder.fgArr.map((fg) => {
                let validRmArr = fg.rmArr.map((rm) => {
                    return {
                        rmUID: rm.rmUID,
                        rmUnit: rm.rmUnit,
                        group: rm.group,
                        rmUsedQtyForThisFgAssembled:
                            rm.rmUsedQtyForThisFgAssembled,
                        // already a Number type.
                    };
                });

                let validSfgArr = fg.sfgArr.map((sfg) => {
                    return {
                        sfgUID: sfg.sfgUID,
                        sfgUnit: sfg.sfgUnit,
                        group: sfg.group,
                        sfgUsedQtyForThisFgAssembled:
                            sfg.sfgUsedQtyForThisFgAssembled, // already a Number type.
                    };
                });

                return {
                    fgUID: fg.fgUID,
                    fgAssembledQtyForThisAssembly:
                        fg.fgAssembledQtyForThisAssembly,
                    fgUnit: fg.fgUnit,
                    rmArr: validRmArr,
                    sfgArr: validSfgArr,
                };
            });

            return {
                assemblyUID: assemblyOrder.assemblyUID,
                fgArr: validFgArr,
            };
        }
    );

    let valid = {
        meta: assemblyDeliveredCopy.meta || {},
        metaHistory: assemblyDeliveredCopy.metaHistory || [],
        assemblyDeliveredUID: assemblyDeliveredCopy.assemblyDeliveredUID,
        assemblyDeliveredNumber: assemblyDeliveredCopy.assemblyDeliveredNumber,
        assemblyDeliveredDate: assemblyDeliveredCopy.assemblyDeliveredDate,
        assemblyOrderArr: validAssemblyOrderArr,
        note: assemblyDeliveredCopy.note,
        assemblyDeliveredDescription:
            assemblyDeliveredCopy.assemblyDeliveredDescription,
    };

    let data = {
        validAssemblyDeliveredObj: valid,
        assemblyDeliveredObj: assemblyDeliveredCopy,
    };

    return responseFn(data, false, {}, 'no error');
};
