import { responseFn } from '../lib/util';
import { regEx, lengths } from '../lib/constants';
import { generalMessage, appMessage } from '../lib/messages';
import { saveWithFiveDecimals } from '../lib/util';

export const productionDelivered = (productionDeliveredCopy) => {
    delete productionDeliveredCopy.productionDeliveredDateError;
    delete productionDeliveredCopy.prodOrderArrError;
    delete productionDeliveredCopy.noteError;
    delete productionDeliveredCopy.productionDeliveredDescriptionError;

    productionDeliveredCopy.prodOrderArr.forEach((prodOrder) => {
        prodOrder.sfgArr.forEach((sfg) => {
            delete sfg.sfgProducedQtyForThisProductionError;
            sfg.sfgProducedQtyForThisProduction = saveWithFiveDecimals(
                sfg.sfgProducedQtyForThisProduction
            );

            sfg.rmArr.forEach((rm) => {
                delete rm.rmUsedQtyForThisSfgProducedError;
                rm.rmUsedQtyForThisSfgProduced = saveWithFiveDecimals(
                    rm.rmUsedQtyForThisSfgProduced
                );
            });
        });
    });

    let hackError = '';
    let objError = false;
    let generalError = '';

    if (productionDeliveredCopy.productionDeliveredDate === '') {
        objError = true;
        productionDeliveredCopy.productionDeliveredDateError =
            generalMessage.REQUIRED;
    }

    if (!regEx.date.test(productionDeliveredCopy.productionDeliveredDate)) {
        objError = true;
        productionDeliveredCopy.productionDeliveredDateError =
            generalMessage.INVALID_DATE;
    }

    if (productionDeliveredCopy.note.length > lengths.noteLength) {
        objError = true;
        productionDeliveredCopy.noteError = generalMessage.INVALID_LENGTH;
    }

    if (
        productionDeliveredCopy.productionDeliveredDescription.length >
        lengths.description
    ) {
        objError = true;
        productionDeliveredCopy.productionDeliveredDescriptionError =
            generalMessage.INVALID_LENGTH;
    }

    if (productionDeliveredCopy.prodOrderArr.length < 1) {
        generalError = 'Must have atleast one Production Order';
    }

    if (
        productionDeliveredCopy.prodOrderArr.length >
        lengths.productionDeliveredArr
    ) {
        hackError = appMessage.sourceCodeErrorMessage(
            'Production Delivered Page: prodOrderArr length error.'
        );
    }

    productionDeliveredCopy.prodOrderArr.forEach((prodOrder) => {
        prodOrder.sfgArr.forEach((sfg) => {
            // validate sfgProducedQtyForThisProduction
            if (isNaN(sfg.sfgProducedQtyForThisProduction)) {
                objError = true;
                sfg.sfgProducedQtyForThisProductionError =
                    generalMessage.INVALID;
            }

            if (sfg.sfgProducedQtyForThisProduction < 0) {
                objError = true;
                sfg.sfgProducedQtyForThisProductionError =
                    generalMessage.POSITIVE_NUMBER;
            }

            if (
                sfg.sfgProducedQtyForThisProduction > lengths.numberInputAmount
            ) {
                objError = true;
                sfg.sfgProducedQtyForThisProductionError =
                    generalMessage.INVALID_AMOUNT;
            }

            // validate sfgProducedQtyForThisProduction. compare against sfgBalanceQty.
            // must not go over sfgBalanceQty.
            if (
                sfg.sfgProducedQtyForThisProduction >
                saveWithFiveDecimals(sfg.sfgBalanceQty + sfg.sfgOverrideQty)
            ) {
                objError = true;
                sfg.sfgProducedQtyForThisProductionError =
                    'Produced Qty cannot exceed the Balance Qty';
            }

            sfg.rmArr.forEach((rm) => {
                // validate rmUsedQtyForThisSfgProduced
                if (isNaN(rm.rmUsedQtyForThisSfgProduced)) {
                    objError = true;
                    rm.rmUsedQtyForThisSfgProducedError =
                        generalMessage.INVALID;
                }

                if (rm.rmUsedQtyForThisSfgProduced < 0) {
                    objError = true;
                    rm.rmUsedQtyForThisSfgProducedError =
                        generalMessage.POSITIVE_NUMBER;
                }

                if (
                    rm.rmUsedQtyForThisSfgProduced > lengths.numberInputAmount
                ) {
                    objError = true;
                    rm.rmUsedQtyForThisSfgProducedError =
                        generalMessage.INVALID_AMOUNT;
                }

                // validate rmUsedQtyForThisSfgProduced. compare against rmBalanceQty.
                // must not go over rmBalanceQty.
                if (
                    rm.rmUsedQtyForThisSfgProduced >
                    saveWithFiveDecimals(rm.rmBalanceQty + rm.rmOverrideQty)
                ) {
                    objError = true;
                    rm.rmUsedQtyForThisSfgProducedError =
                        'Actual Used Qty cannot exceed the Balance Qty';
                }
            });
        });
    });

    if (hackError || objError || generalError) {
        let errorObj = {
            productionDeliveredObj: productionDeliveredCopy,
            hackError,
            generalError,
        };
        return responseFn(null, true, errorObj, 'Error detected');
    }

    let validProdOrderArr = productionDeliveredCopy.prodOrderArr.map(
        (prodOrder) => {
            let validSfgArr = prodOrder.sfgArr.map((sfg) => {
                let validRmArr = sfg.rmArr.map((rm) => {
                    return {
                        rmUID: rm.rmUID,
                        rmUnit: rm.rmUnit,
                        group: rm.group,
                        rmUsedQtyForThisSfgProduced:
                            rm.rmUsedQtyForThisSfgProduced, // already a Number type.
                    };
                });

                return {
                    sfgUID: sfg.sfgUID,
                    sfgProducedQtyForThisProduction:
                        sfg.sfgProducedQtyForThisProduction,
                    sfgUnit: sfg.sfgUnit,
                    rmArr: validRmArr,
                };
            });

            return {
                productionUID: prodOrder.productionUID,
                sfgArr: validSfgArr,
            };
        }
    );

    let valid = {
        meta: productionDeliveredCopy.meta || {},
        metaHistory: productionDeliveredCopy.metaHistory || [],
        productionDeliveredUID: productionDeliveredCopy.productionDeliveredUID,
        productionDeliveredNumber:
            productionDeliveredCopy.productionDeliveredNumber,
        productionDeliveredDate:
            productionDeliveredCopy.productionDeliveredDate,
        prodOrderArr: validProdOrderArr,
        note: productionDeliveredCopy.note,
        productionDeliveredDescription:
            productionDeliveredCopy.productionDeliveredDescription,
    };

    let data = {
        validProductionDeliveredObj: valid,
        productionDeliveredObj: productionDeliveredCopy,
    };

    return responseFn(data, false, {}, 'no error');
};
