import React from 'react';
import './AlertMessage.css';

export default (props) => {
    let { detailObj } = props;

    let detailsUI =
        detailObj && detailObj.detailsArr
            ? detailObj.detailsArr.map((detail, index) => (
                  <p className='details' key={`detail-${index}`}>
                      - {detail}
                  </p>
              ))
            : null;

    return (
        <div className='detailed-message-box'>
            <div className='main'>{detailObj.mainMessage}</div>
            {detailsUI}
        </div>
    );
};
