import React, { useEffect, useContext, useState } from 'react';
import { UpdateItemButtons } from '../../components';
import { Input } from '../../components';
import { DataShareContext, AuthContext } from '../../contexts';
import validate from '../../validators';

import '../PagesCommon.css';
import './AppSettingPage.css';

export default (props) => {
    let { saveAppSettingFn } = props;

    let { authUser } = useContext(AuthContext);
    let { defaultTaxRateFromDB } = useContext(DataShareContext);
    let { rawMatCategoriesInDB } = useContext(DataShareContext);

    let [isEditing, setIsEditing] = useState(false);
    let [taxRate, setTaxRate] = useState(0);
    let [taxRateError, setTaxRateError] = useState('');

    let [rawMatCategories, setRawMatCategories] = useState({
        0: '',
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
        7: '',
        8: '',
        9: '',
    });

    useEffect(() => {
        //reset errors everytime isEditing is triggered.
        setTaxRateError('');
        setTaxRate(defaultTaxRateFromDB);
        setRawMatCategories(rawMatCategoriesInDB);
    }, [isEditing]);

    useEffect(() => {
        setTaxRate(defaultTaxRateFromDB);
    }, [defaultTaxRateFromDB]);

    useEffect(() => {
        setRawMatCategories(rawMatCategoriesInDB);
    }, [rawMatCategoriesInDB]);

    const saveBtnFn = () => {
        //1. validate input

        let response = validate.appSettings({ taxRate, rawMatCategories });
        if (response.error) {
            setTaxRateError(response.errorObj.taxRateError);
            return;
        }

        // no error
        saveAppSettingFn(response.data);
        setIsEditing(false);
    };

    let changeTaxRateFn = (e) => setTaxRate(e.target.value);

    let changeRawMatCategoryFn = (e) => {
        let copyOfCategoriesArr = { ...rawMatCategories };
        copyOfCategoriesArr[e.target.id] = e.target.value;

        setRawMatCategories(copyOfCategoriesArr);
    };

    // conditional render of contents  =============================
    let buttonSection = null;
    let mainContent = null;

    let disabledInput = false;

    if (!isEditing) {
        disabledInput = true;
        changeTaxRateFn = () => console.log('--');
        changeRawMatCategoryFn = () => console.log('--');
    }

    mainContent = (
        <section className='form-main-section'>
            <div className='span-2-columns-container label-padding label-highlight'>
                <label className='parent-labels'>Tax Rate</label>
            </div>
            <Input
                label='Default Tax Rate:'
                type='number'
                disabled={disabledInput}
                value={taxRate}
                onChange={changeTaxRateFn}
                errorMessage={taxRateError}
            />
            <div className='span-2-columns-container label-padding label-highlight m-top-20'>
                <label className='parent-labels'>Raw Material Categories</label>
            </div>

            <Input
                id={0}
                disabled={disabledInput}
                value={rawMatCategories[0]}
                onChange={changeRawMatCategoryFn}
            />

            <Input
                id={1}
                disabled={disabledInput}
                value={rawMatCategories[1]}
                onChange={changeRawMatCategoryFn}
            />

            <Input
                id={2}
                disabled={disabledInput}
                value={rawMatCategories[2]}
                onChange={changeRawMatCategoryFn}
            />

            <Input
                id={3}
                disabled={disabledInput}
                value={rawMatCategories[3]}
                onChange={changeRawMatCategoryFn}
            />

            <Input
                id={4}
                disabled={disabledInput}
                value={rawMatCategories[4]}
                onChange={changeRawMatCategoryFn}
            />

            <Input
                id={5}
                disabled={disabledInput}
                value={rawMatCategories[5]}
                onChange={changeRawMatCategoryFn}
            />

            <Input
                id={6}
                disabled={disabledInput}
                value={rawMatCategories[6]}
                onChange={changeRawMatCategoryFn}
            />

            <Input
                id={7}
                disabled={disabledInput}
                value={rawMatCategories[7]}
                onChange={changeRawMatCategoryFn}
            />

            <Input
                id={8}
                disabled={disabledInput}
                value={rawMatCategories[8]}
                onChange={changeRawMatCategoryFn}
            />

            <Input
                id={9}
                disabled={disabledInput}
                value={rawMatCategories[9]}
                onChange={changeRawMatCategoryFn}
            />

            <div className='desktop-view form-profile-info-items'></div>
        </section>
    );

    if (isEditing) {
        buttonSection = (
            <UpdateItemButtons
                saveFn={saveBtnFn}
                cancelFn={() => setIsEditing(false)}
            />
        );
    } else {
        buttonSection = (
            <section className='form-button-section'>
                <button
                    className='goto-right btn btn-primary'
                    onClick={() => {
                        setIsEditing(true);
                    }}>
                    Edit
                </button>
            </section>
        );
    }

    if (!authUser.subscriptionStatusIsActive) {
        buttonSection = null;
    }

    return (
        <div className='common-form'>
            <section className='form-title-section'>
                Application Settings
            </section>

            {mainContent}

            <div className='span-2-columns-container m-top-20'></div>

            {buttonSection}

            <div className='watermark'>
                <span></span>
            </div>
        </div>
    );
};
