import { db, firebase } from '../firebase_config/firebaseConfig';
import { userActions } from '../lib/constants';
import { responseFn, formatCustomErrorObj } from '../lib/util';
import validate from '../validators';
import mListHelper from './mListHelper';
import { docNames, appMessage } from '../lib/messages';
import dbopsGetDocument from './dbopsGetDocument';

import {
    fgInvObjToMListItemFn,
    fgObjToMListItemFn,
} from '../lib/masterListConverters';

import {
    FINISHED_GOODS_COL,
    MASTER_LISTS_COL,
    MASTER_FINISHED_GOODS_LIST_DOC,
    DELETED_FINISHED_GOODS_COL,
    MASTER_INVENTORY_FG_LIST_DOC,
} from '../lib/constants';

// ================================================
// function to fetch each finished good document.
const getFGFn = async (docID) =>
    dbopsGetDocument(FINISHED_GOODS_COL, docID, docNames.FG);

// ======================================================
const createFGFn = async (userCreds, payload) => {
    // add metadata to payload to be created in firestore.
    payload.meta = {
        action: userActions.CREATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // add metaHistory
    payload.metaHistory = [];

    let newFGRef = db.collection(FINISHED_GOODS_COL).doc(payload.fgUID);

    let fgListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_FINISHED_GOODS_LIST_DOC);

    let inventoryFGsListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_INVENTORY_FG_LIST_DOC);

    const transactionFn = async (transaction) => {
        let newFGDoc = await transaction.get(newFGRef);
        let fgListDoc = await transaction.get(fgListRef);
        let inventoryFGsListDoc = await transaction.get(inventoryFGsListRef);

        // create new finished good document
        if (newFGDoc.exists) {
            return Promise.reject(
                formatCustomErrorObj(1002, appMessage.alreadyExist(docNames.FG))
            );
        }
        await transaction.set(newFGRef, payload);

        // create or update fgs list document in (master lists collection)
        if (fgListDoc.exists) {
            let fgInfoObj = fgObjToMListItemFn(payload);

            let newData = mListHelper.updateMasterList(
                fgListDoc,
                payload.fgUID,
                fgInfoObj
            );

            transaction.set(fgListRef, newData);
        } else {
            let fgInfoObj = fgObjToMListItemFn(payload);

            let newData = mListHelper.addMasterList(payload.fgUID, fgInfoObj);

            transaction.set(fgListRef, newData);
        }

        // create or update inventory fgs list document in (master lists collection)
        if (inventoryFGsListDoc.exists) {
            let fgInvInfoObj = fgInvObjToMListItemFn(payload);

            let newData = mListHelper.updateMasterList(
                inventoryFGsListDoc,
                payload.fgUID,
                fgInvInfoObj
            );

            transaction.set(inventoryFGsListRef, newData);
        } else {
            let fgInvInfoObj = fgInvObjToMListItemFn(payload);

            let newData = mListHelper.addMasterList(
                payload.fgUID,
                fgInvInfoObj
            );

            transaction.set(inventoryFGsListRef, newData);
        }
        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyCreated(docNames.FG)
        );
    } catch (e) {
        return responseFn(null, true, e, appMessage.errorCreating(docNames.FG));
    }
};

// ===============================================
const updateFGFn = async (userCreds, payload, documentBasis) => {
    // move old metadate into metahistory. ensure meta data history length
    // is 100 or less. will be checked by security rules.
    if (payload.metaHistory.length > 99) payload.metaHistory.shift();

    payload.metaHistory.push(payload.meta);

    // update metadata in payload.
    payload.meta = {
        action: userActions.UPDATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let fgRef = db.collection(FINISHED_GOODS_COL).doc(payload.fgUID);

    let fgsListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_FINISHED_GOODS_LIST_DOC);

    let inventoryFGsListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_INVENTORY_FG_LIST_DOC);

    const transactionFn = async (transaction) => {
        let fgsListDoc = await transaction.get(fgsListRef);
        let inventoryFGsListDoc = await transaction.get(inventoryFGsListRef);
        let fgDoc = await transaction.get(fgRef);

        if (!fgDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.FG)
                )
            );

        // if there is a change in finished good document from the time
        // it was read prior to editting and now (transaction.get),
        // throw an error.
        if (!validate.noChange(fgDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotDeleteBecauseDocChanged(docNames.FG)
                )
            );
        }

        let fgInfoObj = fgObjToMListItemFn(payload);

        let newData = mListHelper.updateMasterList(
            fgsListDoc,
            payload.fgUID,
            fgInfoObj
        );

        let fgInvInfoObj = fgInvObjToMListItemFn(payload);

        let newInvData = mListHelper.updateMasterList(
            inventoryFGsListDoc,
            payload.fgUID,
            fgInvInfoObj
        );

        await transaction.set(fgsListRef, newData);
        await transaction.set(inventoryFGsListRef, newInvData);
        await transaction.set(fgRef, payload);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyUpdated(docNames.FG)
        );
    } catch (e) {
        return responseFn(null, true, e, appMessage.errorUpdating(docNames.FG));
    }
};

// ================================================================
const deleteFGFn = async (userCreds, payload, documentBasis) => {
    if (payload.metaHistory.length > 99) payload.metaHistory.shift();

    payload.metaHistory.push(payload.meta);

    payload.meta = {
        action: userActions.DELETED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let fgRef = db.collection(FINISHED_GOODS_COL).doc(payload.fgUID);

    let fgsListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_FINISHED_GOODS_LIST_DOC);

    let inventoryFGsListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_INVENTORY_FG_LIST_DOC);

    let deletedFGRef = db.collection(DELETED_FINISHED_GOODS_COL).doc();

    const transactionFn = async (transaction) => {
        let fgDoc = await transaction.get(fgRef);

        if (!fgDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotDeleteBecauseDocNotExist(docNames.FG)
                )
            );

        // if there is a change in finished good document from the time
        // it was read prior to deleting and now (transaction.get),
        // throw an error.
        if (!validate.noChange(fgDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotDeleteBecauseDocChanged(docNames.FG)
                )
            );
        }

        let fgsListDoc = await transaction.get(fgsListRef);
        let inventoryFGsListDoc = await transaction.get(inventoryFGsListRef);
        await transaction.get(deletedFGRef);

        let newData = mListHelper.deletePropertyFromMasterList(
            fgsListDoc,
            payload.fgUID
        );

        let newInventoryFGsListData = mListHelper.deletePropertyFromMasterList(
            inventoryFGsListDoc,
            payload.fgUID
        );

        await transaction.delete(fgRef);
        await transaction.set(fgsListRef, newData);
        await transaction.set(inventoryFGsListRef, newInventoryFGsListData);
        await transaction.set(deletedFGRef, payload);
        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyDeleted(docNames.FG)
        );
    } catch (e) {
        return responseFn(null, true, e, appMessage.errorDeleting(docNames.FG));
    }
};

export default {
    getFGFn,
    createFGFn,
    updateFGFn,
    deleteFGFn,
};
