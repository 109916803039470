import React, { useEffect, useState } from 'react';
import { Pagination } from '../../components';
import { buildPages } from '../../lib/util';

import './ListView2.css';

export default (props) => {
    let {
        getDocumentFromDBFn,
        list,
        CollectionItemComponent,
        keyProperty,
    } = props;

    //! =========================================================
    //! PAGINATION state and functions

    //! State
    const [pagesArr, setPagesArr] = useState([]);
    const [page, setPage] = useState(0);

    //! Initialize pagesArr with useEffect
    useEffect(() => {
        setPagesArr(buildPages(list.length));
        setPage(0);
    }, [list]);

    //! functions for pagination
    const gotoFirstPageFn = () => setPage(0);

    const gotoPreviousPageFn = () => {
        let prevPage = page - 1 < 0 ? 0 : page - 1;
        setPage(prevPage);
    };

    const gotoNextPageFn = () => {
        let nextPage =
            page + 1 > pagesArr.length - 1 ? pagesArr.length - 1 : page + 1;
        setPage(nextPage);
    };

    const gotoLastPageFn = () => setPage(pagesArr.length - 1);

    //! array for pagination
    let finalListToBeRendered = [];
    if (Array.isArray(pagesArr[page])) {
        finalListToBeRendered = list.slice(
            pagesArr[page][0],
            pagesArr[page][1]
        );
    }

    //! end of pagination section
    //! ===============================================================

    let items = finalListToBeRendered.map((item, index) => {
        return (
            <CollectionItemComponent
                key={item[keyProperty]}
                keyProperty={keyProperty}
                serialNumber={pagesArr[page][0] + 1 + index}
                obj={item}
                onClick={() => getDocumentFromDBFn(item[keyProperty])}
            />
        );
    });

    // pagination buttons
    let pagination = null;
    if (pagesArr.length > 1) {
        let info = `Page ${page + 1} of ${pagesArr.length}`;
        pagination = (
            <Pagination
                gotoFirstPageFn={gotoFirstPageFn}
                gotoPreviousPageFn={gotoPreviousPageFn}
                gotoNextPageFn={gotoNextPageFn}
                gotoLastPageFn={gotoLastPageFn}
                paginationInfo={info}
            ></Pagination>
        );
    }

    return (
        <>
            <div className='list-view-top-filler'></div>

            <div className='common-list-view2'>
                {/* main list */}
                <CollectionItemComponent
                    heading={true}
                    keyProperty={keyProperty}
                />
                {items}
            </div>
            <div className='list-view-bottom-filler'></div>
            <div className='pagination-controls'>
                {/* pagination button */}
                {pagination}
                {/* list specific controls */}
            </div>
        </>
    );
};
