import { db, firebase } from '../firebase_config/firebaseConfig';
import { userActions } from '../lib/constants';
import { responseFn, formatCustomErrorObj } from '../lib/util';
import mListHelper from './mListHelper';
import validate from '../validators';
import { docNames, appMessage } from '../lib/messages';
import dbopsGetDocument from './dbopsGetDocument';
import customerObjToMListItem from '../lib/masterListConverters/customerObjToMListItem';

import {
    CUSTOMERS_COL,
    MASTER_LISTS_COL,
    MASTER_CUSTOMERS_LIST_DOC,
    DELETED_CUSTOMERS_COL,
} from '../lib/constants';

// ================================================
// function to fetch each customer document.
const getCustomerFn = async (docID) =>
    dbopsGetDocument(CUSTOMERS_COL, docID, docNames.CUSTOMER);

// ======================================================
const createCustomerFn = async (userCreds, payload) => {
    // add metadata to payload to be created in firestore.
    payload.meta = {
        action: userActions.CREATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // add metaHistory
    payload.metaHistory = [];

    let newCustomerRef = db.collection(CUSTOMERS_COL).doc(payload.customerUID);

    let customersListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_CUSTOMERS_LIST_DOC);

    const transactionFn = async (transaction) => {
        let newCustomerDoc = await transaction.get(newCustomerRef);
        let customersListDoc = await transaction.get(customersListRef);

        // create new customer document
        if (newCustomerDoc.exists) {
            return Promise.reject(
                formatCustomErrorObj(
                    1002,
                    appMessage.alreadyExist(docNames.CUSTOMER)
                )
            );
        }
        await transaction.set(newCustomerRef, payload);

        // create or update customers list document in (master lists collection)
        if (customersListDoc.exists) {
            let customerInfoObj = customerObjToMListItem(payload);

            let newData = mListHelper.updateMasterList(
                customersListDoc,
                payload.customerUID,
                customerInfoObj
            );

            transaction.set(customersListRef, newData);
        } else {
            let customerInfoObj = customerObjToMListItem(payload);

            let newData = mListHelper.addMasterList(
                payload.customerUID,
                customerInfoObj
            );

            transaction.set(customersListRef, newData);
        }
        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyCreated(docNames.CUSTOMER)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorCreating(docNames.CUSTOMER)
        );
    }
};

// ===============================================
const updateCustomerFn = async (userCreds, payload, documentBasis) => {
    // move old metadate into metahistory. ensure meta data history length
    // is 100 or less. will be checked by security rules.
    if (payload.metaHistory.length > 99) payload.metaHistory.shift();

    payload.metaHistory.push(payload.meta);

    // update metadata in payload.
    payload.meta = {
        action: userActions.UPDATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let customerRef = db.collection(CUSTOMERS_COL).doc(payload.customerUID);

    let customersListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_CUSTOMERS_LIST_DOC);

    const transactionFn = async (transaction) => {
        let customerDoc = await transaction.get(customerRef);

        if (!customerDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.CUSTOMER)
                )
            );

        // if there is a change in customer document from the time
        // it was read prior to editting and now (transaction.get),
        // throw an error.
        if (!validate.noChange(customerDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.CUSTOMER)
                )
            );
        }

        let customersListDoc = await transaction.get(customersListRef);

        let customerInfoObj = customerObjToMListItem(payload);

        let newData = mListHelper.updateMasterList(
            customersListDoc,
            payload.customerUID,
            customerInfoObj
        );

        await transaction.set(customersListRef, newData);

        await transaction.set(customerRef, payload);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyUpdated(docNames.CUSTOMER)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorUpdating(docNames.CUSTOMER)
        );
    }
};

// ================================================================
const deleteCustomerFn = async (userCreds, payload, documentBasis) => {
    if (payload.metaHistory.length > 99) payload.metaHistory.shift();

    payload.metaHistory.push(payload.meta);

    payload.meta = {
        action: userActions.DELETED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let customerRef = db.collection(CUSTOMERS_COL).doc(payload.customerUID);

    let customersListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_CUSTOMERS_LIST_DOC);

    let deletedCustomerRef = db.collection(DELETED_CUSTOMERS_COL).doc();

    const transactionFn = async (transaction) => {
        let customerDoc = await transaction.get(customerRef);

        if (!customerDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotDeleteBecauseDocNotExist(docNames.CUSTOMER)
                )
            );

        // if there is a change in customer document from the time
        // it was read prior to deleting and now (transaction.get),
        // throw an error.
        if (!validate.noChange(customerDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotDeleteBecauseDocChanged(docNames.CUSTOMER)
                )
            );
        }

        let customersListDoc = await transaction.get(customersListRef);
        await transaction.get(deletedCustomerRef);

        let newData = mListHelper.deletePropertyFromMasterList(
            customersListDoc,
            payload.customerUID
        );

        await transaction.delete(customerRef);
        await transaction.set(customersListRef, newData);
        await transaction.set(deletedCustomerRef, payload);
        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyDeleted(docNames.CUSTOMER)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorDeleting(docNames.CUSTOMER)
        );
    }
};

export default {
    getCustomerFn,
    createCustomerFn,
    updateCustomerFn,
    deleteCustomerFn,
};
