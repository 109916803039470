import React from 'react';
import './DashCard.css';

export default (props) => {
    let {
        bgColor,
        mainIcon,
        mainIconColor,
        mainHeading,
        mainText,
        // bottomIcon,
        // bottomText,
    } = props;

    return (
        <div className='dashcard' style={{ backgroundColor: `${bgColor}` }}>
            <section className='top-section-card'>
                <div className='top-icon' style={{ color: `${mainIconColor}` }}>
                    {mainIcon}
                </div>

                <div className='main-info'>
                    <div className='main-heading'>{mainHeading}</div>
                    <div className='main-text'>{mainText}</div>
                </div>
            </section>
        </div>
    );
};

//  <section className='bottom-section'>
//         <div className='bottom-icon'>{bottomIcon}</div>
//         <div className='bottom-text'>{bottomText}</div>
//     </section>
