import { db, firebase } from '../firebase_config/firebaseConfig';
import { userActions, systemActions } from '../lib/constants';
import {
    responseFn,
    formatCustomErrorObj,
    saveWithFiveDecimals,
} from '../lib/util';
import validate from '../validators';
import mListHelper from './mListHelper';
import dbopsGetDocument from './dbopsGetDocument';
import { docNames, appMessage } from '../lib/messages';
import {
    pdObjToMListItem,
    rmInvObjToMListItemFn,
} from '../lib/masterListConverters';

import {
    RAW_MATERIALS_COL,
    PDDRS_COL,
    MASTER_LISTS_COL,
    MASTER_PDDR_LIST_DOC,
    MASTER_PDPO_LIST_DOC,
    PDPOS_COL,
    DELETED_PDDRS_COL,
    MASTER_INVENTORY_RM_LIST_DOC,
} from '../lib/constants';

// ================================================
// function to fetch PDDR document.
const getDrFn = async (docID) => dbopsGetDocument(PDDRS_COL, docID, 'PDDR');

// ======================================================
const createDrFn = async (userCreds, drObj) => {
    // add metadata to drObj to be created in firestore.
    drObj.meta = {
        action: userActions.CREATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // add metaHistory
    drObj.metaHistory = [];

    // get references for documents needed. =========================
    let pddrRef = db.collection(PDDRS_COL).doc(drObj.pddrUID);
    let pddrListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_PDDR_LIST_DOC);

    // end get references ===========================================

    const transactionFn = async (transaction) => {
        // =============================================================
        // perform 'transaction get operations' =========================
        let pddrDoc = await transaction.get(pddrRef);
        let pddrListDoc = await transaction.get(pddrListRef);

        if (pddrDoc.exists) {
            return Promise.reject(
                //! this should never run as PO number is server generated.
                formatCustomErrorObj(
                    1002,
                    appMessage.alreadyExist(docNames.PDDR)
                )
            );
        }

        await transaction.set(pddrRef, drObj);

        // update master list
        if (pddrListDoc.exists) {
            let pddrInfoObj = pdObjToMListItem(drObj);

            let newData = mListHelper.updateMasterList(
                pddrListDoc,
                drObj.pddrUID,
                pddrInfoObj
            );

            await transaction.set(pddrListRef, newData);
        } else {
            let pddrInfoObj = pdObjToMListItem(drObj);

            let newData = mListHelper.addMasterList(drObj.pddrUID, pddrInfoObj);

            await transaction.set(pddrListRef, newData);
        }

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyCreated(docNames.PDDR)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorCreating(docNames.PDDR)
        );
    }
};

// ===============================================
const updateDrFn = async (userCreds, drObj, documentBasis) => {
    if (drObj.metaHistory.length > 99) drObj.metaHistory.shift();

    drObj.metaHistory.push(drObj.meta);

    // update metadata in drObj.
    drObj.meta = {
        action: userActions.UPDATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // get references for documents needed. =========================
    let pddrRef = db.collection(PDDRS_COL).doc(drObj.pddrUID);
    let pddrListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_PDDR_LIST_DOC);

    // end get references ===========================================

    const transactionFn = async (transaction) => {
        // =============================================================
        // perform 'transaction get operations' =========================
        let pddrDoc = await transaction.get(pddrRef);
        let pddrListDoc = await transaction.get(pddrListRef);

        if (!pddrDoc.exists) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.PDDR)
                )
            );
        }

        if (!validate.noChange(pddrDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.PDDR)
                )
            );
        }

        await transaction.set(pddrRef, drObj);

        // update master list
        if (pddrListDoc.exists) {
            let pddrInfoObj = pdObjToMListItem(drObj);

            let newData = mListHelper.updateMasterList(
                pddrListDoc,
                drObj.pddrUID,
                pddrInfoObj
            );

            await transaction.set(pddrListRef, newData);
        } else {
            let pddrInfoObj = pdObjToMListItem(drObj);

            let newData = mListHelper.addMasterList(drObj.pddrUID, pddrInfoObj);

            await transaction.set(pddrListRef, newData);
        }

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyUpdated(docNames.PDDR)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorUpdating(docNames.PDDR)
        );
    }
};

// ================================================================
const deleteDrFn = async (userCreds, drObj, documentBasis) => {
    // update meta object and metaHistory.
    if (drObj.metaHistory.length > 99) drObj.metaHistory.shift();

    drObj.metaHistory.push(drObj.meta);

    drObj.meta = {
        action: userActions.DELETED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // get references for documents needed. =========================
    let pddrRef = db.collection(PDDRS_COL).doc(drObj.pddrUID);
    let deletedPddrRef = db.collection(DELETED_PDDRS_COL).doc();
    let pddrListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_PDDR_LIST_DOC);

    // end get references ===========================================

    const transactionFn = async (transaction) => {
        // =============================================================
        // perform 'transaction get operations' =========================
        let pddrDoc = await transaction.get(pddrRef);
        let pddrListDoc = await transaction.get(pddrListRef);
        await transaction.get(deletedPddrRef);

        // end get operations ===================================================

        if (!pddrDoc.exists) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotDeleteBecauseDocNotExist(docNames.PDDR)
                )
            );
        }

        if (!validate.noChange(pddrDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotDeleteBecauseDocChanged(docNames.PDDR)
                )
            );
        }

        let newPddrListData = mListHelper.deletePropertyFromMasterList(
            pddrListDoc,
            drObj.pddrUID
        );

        await transaction.delete(pddrRef); // delete the dr in the dr collection.
        await transaction.set(deletedPddrRef, drObj); // add the dr in the deletedDr Col.
        await transaction.set(pddrListRef, newPddrListData); // masterlist pddr

        return Promise.resolve('TransactionFn Completed Successfully');
    };
    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyDeleted(docNames.PDDR)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorDeleting(docNames.PDDR)
        );
    }
};

// ================================================================
const statusChangeFn = async (userCreds, drObj, documentBasis) => {
    // move old metadate into metahistory. ensure meta data history length
    // is 100 or less. will be checked by security rules.
    if (drObj.metaHistory.length > 99) drObj.metaHistory.shift();

    drObj.metaHistory.push(drObj.meta);

    // update metadata in drObj.
    drObj.meta = {
        action: userCreds.action,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // get references.
    let pddrRef = db.collection(PDDRS_COL).doc(drObj.pddrUID);
    let pddrListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_PDDR_LIST_DOC);

    const transactionFn = async (transaction) => {
        let pddrDoc = await transaction.get(pddrRef);
        let pddrListDoc = await transaction.get(pddrListRef);

        if (!pddrDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.PDDR)
                )
            );
        // ! this does not check meta data.
        if (!validate.noChange(pddrDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.PDDR)
                )
            );
        }
        // ! meta data check if status of document changed.
        if (pddrDoc.data().meta.action !== documentBasis.meta.action) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.PDDR)
                )
            );
        }

        let pddrInfoObj = pdObjToMListItem(drObj);

        let newData = mListHelper.updateMasterList(
            pddrListDoc,
            drObj.pddrUID,
            pddrInfoObj
        );

        // write to masterlist and pdpo document in firebase.
        await transaction.set(pddrListRef, newData);
        await transaction.set(pddrRef, drObj);
        return Promise.resolve('TransactionFn Completed Successfully');
    };
    try {
        await db.runTransaction(transactionFn);
        return responseFn(null, false, null, appMessage.successfulUpdateStatus);
    } catch (e) {
        return responseFn(null, true, e, appMessage.errorUpdatingStatus);
    }
};

const approvePddrFn = async (
    userCreds,
    drObj,
    documentBasis,
    documentBasisPoArr
) => {
    if (drObj.metaHistory.length > 99) drObj.metaHistory.shift();

    drObj.metaHistory.push(drObj.meta);

    // update metadata in drObj.
    drObj.meta = {
        action: userCreds.action,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // get references for documents needed. =========================
    let pddrRef = db.collection(PDDRS_COL).doc(drObj.pddrUID);
    let pddrListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_PDDR_LIST_DOC);
    let pdpoListRef = db.collection(MASTER_LISTS_COL).doc(MASTER_PDPO_LIST_DOC);

    let rmInvListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_INVENTORY_RM_LIST_DOC);

    let documentBasisPoArrRefs = documentBasisPoArr.map((po) =>
        db.collection(PDPOS_COL).doc(po.pdpoUID)
    );

    // create a map of all the rmObs from all the pdpoObjs.
    let rmMap = {};

    drObj.pdpoArr.forEach((po) => {
        po.rmArr.forEach((rm) => {
            rmMap[rm.rmUID] = { computedDeliveredQty: 0, newPoNumbersArr: [] };
        });
    });

    // get references for Rms involved.
    let rmMapRefs = {};
    for (let key in rmMap) {
        rmMapRefs[key] = db.collection(RAW_MATERIALS_COL).doc(key);
    }
    // end get references ===========================================

    const transactionFn = async (transaction) => {
        let existError = false;
        let editedError = false;
        let balanceError = false;

        // =============================================================
        // START TRANSACTION GET =================================
        let pddrDoc = await transaction.get(pddrRef);
        let pddrListDoc = await transaction.get(pddrListRef);
        let pdpoListDoc = await transaction.get(pdpoListRef);
        let rmInvListDoc = await transaction.get(rmInvListRef);

        let rmMapDocs = {};
        for (let key in rmMapRefs) {
            rmMapDocs[key] = await transaction.get(rmMapRefs[key]);
        }

        let documentBasisPoArrDocs = documentBasisPoArrRefs.map(
            async (poRef) => await transaction.get(poRef)
        );
        try {
            documentBasisPoArrDocs = await Promise.all(documentBasisPoArrDocs);
        } catch (e) {
            return Promise.reject(
                formatCustomErrorObj(
                    900,
                    appMessage.prommiseAllError(
                        'dbopsPDDRs',
                        'documentBasisPoArr'
                    )
                )
            );
        }

        // END TRANSACTION GET =============================================

        // START CHECK FOR CHANGE AND EXISTENCE =================================

        if (!pddrDoc.exists) {
            return Promise.reject(
                //! this should never run as PO number is server generated.
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.PDDR)
                )
            );
        }

        // ! this does not check meta data.
        if (!validate.noChange(pddrDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.PDDR)
                )
            );
        }
        // ! meta data check if status of document changed.
        if (pddrDoc.data().meta.action !== documentBasis.meta.action) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.PDDR)
                )
            );
        }

        for (let key in rmMapDocs) {
            if (!rmMapDocs[key].exists) existError = true;
        }
        //! this is an edge case scenario. should not really happen but is possible.
        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopsPDDRs',
                        docNames.PDDR,
                        'RM'
                    )
                )
            );
        }

        // check if there are any changes to the POs involved. only proceed with transaction
        // if pdpo has no changes compared to the documentBasisDocs.
        documentBasisPoArrDocs.forEach((docBasisPo, index) => {
            if (!docBasisPo.exists) existError = true;

            // if there is a change in pdpo document from the time
            // it was read prior to editting and now (transaction.get),
            // throw an error.
            if (
                !validate.noChange(docBasisPo.data(), documentBasisPoArr[index])
            )
                editedError = true;
        });

        if (existError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseOneItemInArrayDeleted(
                        'dbopsPDDRs',
                        docNames.PDDR,
                        'PDPO'
                    )
                )
            );
        }
        if (editedError) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseOneItemInArrayChanged(
                        'dbopsPDDRs',
                        docNames.PDDR,
                        'PDPO'
                    )
                )
            );
        }

        // END CHECK FOR CHANGE AND EXISTENCE ==================================

        // START TRANSACTION SET ===============================================

        // copy all the poNumbersArr of rms from firebase to rmMap.newPoNumbersArr.
        for (let key in rmMapDocs) {
            rmMap[key].newPoNumbersArr = [
                ...rmMapDocs[key].data().poNumbersArr,
            ];
        }

        // determine the correct rmDeliveredQty to be added for each RM.
        drObj.pdpoArr.forEach((po) => {
            po.rmArr.forEach((rm) => {
                // rmMap should include the rm.rmUID as property.
                rmMap[rm.rmUID].computedDeliveredQty = saveWithFiveDecimals(
                    rmMap[rm.rmUID].computedDeliveredQty +
                        rm.drDeliveredQtyForRm
                );
            });
        });

        // ================================================================
        // Change balance qty and delivered Qty in the PoObjects read from firebase.

        //! build a temporary masterList that can be updated every time a poObj
        //! is checked in the poArr.  the final list will all changes to the
        //! status of the poObj(s) can only be written once using transaction.
        let tempMasterPDPOList = pdpoListDoc.data();

        drObj.pdpoArr.forEach(async (po, poIndex) => {
            // make a copy of the drObj. change rmObjs in rmArr with correct
            // rmBalanceQty and rmDeliveredQty. update firebase drObj with this
            // new drObj.
            let poCopy = {
                ...documentBasisPoArrDocs[poIndex].data(),
            };

            // variable to be used to check if po can be marked 'FULFILLED'
            let allRmsDelivered = [];

            po.rmArr.forEach((rm, rmIndex) => {
                let rmObjFromPo_DeliveredQty =
                    poCopy.rmArr[rmIndex].rmDeliveredQty;
                let rmObjFromPo_BalanceQty = poCopy.rmArr[rmIndex].rmBalanceQty;

                // check balance qty and delivered qty.
                if (
                    saveWithFiveDecimals(
                        saveWithFiveDecimals(
                            rmObjFromPo_BalanceQty +
                                poCopy.rmArr[rmIndex].rmOverrideQty
                        ) - rm.drDeliveredQtyForRm
                    ) < 0
                ) {
                    balanceError = true;
                }

                // end test ==================================================

                poCopy.rmArr[rmIndex] = {
                    ...poCopy.rmArr[rmIndex],
                    rmDeliveredQty: saveWithFiveDecimals(
                        rmObjFromPo_DeliveredQty + rm.drDeliveredQtyForRm
                    ),
                    rmBalanceQty: saveWithFiveDecimals(
                        rmObjFromPo_BalanceQty - rm.drDeliveredQtyForRm
                    ),
                };

                // if balanceQty of this particular RM is O, push true to
                // allRmsDelivered Array to make system aware if status can be changed
                // to FULFILLED. //! integrated rmOverrideQty during calculations.
                if (
                    saveWithFiveDecimals(
                        poCopy.rmArr[rmIndex].rmBalanceQty +
                            poCopy.rmArr[rmIndex].rmOverrideQty
                    ) === 0
                ) {
                    allRmsDelivered.push(true);
                } else allRmsDelivered.push(false);
            });

            // check if status of this PO can be changed to FULFILLED.

            if (allRmsDelivered.every((ans) => ans === true)) {
                //!================================================================
                // since status is changing to FULFILLED, remove pdpoUID
                // in each of the related RMs.
                poCopy.rmArr.forEach((rm) => {
                    let newArr = [...rmMap[rm.rmUID].newPoNumbersArr];
                    newArr = newArr.filter(
                        (poNumber) => poNumber !== poCopy.pdpoUID
                    );
                    rmMap[rm.rmUID].newPoNumbersArr = newArr;
                });
                //! ============================================================

                // change status of po object.
                if (poCopy.metaHistory.length > 99) poCopy.metaHistory.shift();

                poCopy.metaHistory.push(poCopy.meta);

                // update metadata in poCopy.
                poCopy.meta = {
                    action: systemActions.FULFILLED,
                    uid: systemActions.UID,
                    email: systemActions.EMAIL,
                    timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
                };

                // update temporary pdpo master list
                let pdpoInfoObj = pdObjToMListItem(poCopy);

                // this variable will be used outside the loop to update
                // the masterList ONCE.
                tempMasterPDPOList = mListHelper.updateTempMList(
                    tempMasterPDPOList,
                    poCopy.pdpoUID,
                    pdpoInfoObj
                );
            }

            // set updated PoObj with transactions.
            await transaction.set(documentBasisPoArrRefs[poIndex], poCopy, {
                merge: true,
            });
        });

        if (balanceError) {
            return Promise.reject(
                formatCustomErrorObj(
                    800,
                    appMessage.balanceError(
                        'Delivered Qty',
                        'Balance Qty',
                        'RM'
                    )
                )
            );
        }

        // After updating all the PDPO's, update the master list of PDPOs
        await transaction.set(pdpoListRef, tempMasterPDPOList);

        // =================================================================
        // =================================================================

        let tempRmInventoryList = rmInvListDoc.data();

        // change actual Qty in the rms
        for (let key in rmMap) {
            let data = {
                rmActualQty: saveWithFiveDecimals(
                    rmMapDocs[key].data().rmActualQty +
                        rmMap[key].computedDeliveredQty
                ),
                poNumbersArr: rmMap[key].newPoNumbersArr,
            };

            let rmCopy = rmMapDocs[key].data();
            rmCopy.rmActualQty = data.rmActualQty;
            let inventoryRmInfoObj = rmInvObjToMListItemFn(rmCopy);

            tempRmInventoryList = mListHelper.updateTempMList(
                tempRmInventoryList,
                rmCopy.rmUID,
                inventoryRmInfoObj
            );

            await transaction.set(rmMapRefs[key], data, { merge: true });
        }

        await transaction.set(pddrRef, drObj);

        // update rmInventory master list
        await transaction.set(rmInvListRef, tempRmInventoryList);

        // update master list
        if (pddrListDoc.exists) {
            let pddrInfoObj = pdObjToMListItem(drObj);

            let newData = mListHelper.updateMasterList(
                pddrListDoc,
                drObj.pddrUID,
                pddrInfoObj
            );

            await transaction.set(pddrListRef, newData);
        } else {
            let pddrInfoObj = pdObjToMListItem(drObj);

            let newData = mListHelper.addMasterList(drObj.pddrUID, pddrInfoObj);

            await transaction.set(pddrListRef, newData);
        }

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(null, false, null, appMessage.successfulUpdateStatus);
    } catch (e) {
        return responseFn(null, true, e, appMessage.errorUpdatingStatus);
    }
};

export default {
    getDrFn,
    createDrFn,
    updateDrFn,
    deleteDrFn,
    statusChangeFn,
    approvePddrFn,
};
