import React, { useState, useEffect, useContext } from 'react';
import {
    Datalist,
    AddButton,
    Input,
    CreateItemButtons,
    UpdateItemButtons,
    DeleteItemButtons,
    BackOnlyButton,
    BackSendEditDelButtons,
    BackWithMarkAsButtons,
    ApproveRejectButtons,
    HelpTip,
    TextArea,
    SignatureLine,
    Watermark,
} from '../../components';
import {
    AuthContext,
    MasterListContext,
    DataShareContext,
} from '../../contexts';
import { dbopsSDPOs } from '../../firebase_dbops';
import { permissions, userActions, lengths } from '../../lib/constants';
import { clone, displayReadableNumber, displayDate } from '../../lib/util';
import validate from '../../validators';
import tooltips from '../../lib/tooltips';
import AddedSdpoForm from './AddedSdpoForm';

import '../PagesCommon.css';
import './SDDRsPage.css';

let defaultSDDR = {
    sddrUID: '',
    sddrNumber: '',
    customerUID: '',
    priceListNum: '',
    sdpoArr: [],
    meta: { action: '' },
    note: '',
};

export default (props) => {
    let {
        purpose,
        currentDocument,

        backCancelBtnFn,
        gotoEditViewFn,
        gotoDeleteViewFn,

        createSddrFn,
        deleteSddrFn,
        updateSddrFn,
        statusChangeFn,

        setIsLoading,
        setAlertErrorMsg,
    } = props;

    let { masterSdpoList } = useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);
    let { serverDate } = useContext(DataShareContext);

    let [generalError, setGeneralError] = useState('');

    let [
        datalistDisplayResetCounter,
        setDatalistDisplayResetCounter,
    ] = useState(1);

    let [sddrUIDinState, setSddrUIDinState] = useState('');
    let [sddrObj, setSDDRObj] = useState(defaultSDDR);
    let [sdpoUIDinState, setSdpoUIDinState] = useState('');
    let [preview, setPreview] = useState(false);
    let [addSdpoError, setAddSdpoError] = useState('');
    let [sddrDateInState, setSddrDateInState] = useState(serverDate);
    let [noteInState, setNoteInState] = useState('');

    let [currentSdpoDocsArr, setCurrentSdpoDocsArr] = useState([]);
    let [confirmDeleteText, setConfirmDeleteText] = useState('');

    // using the currentDocument.  fetch from firebase all the SDPO documents
    // and integrate with a COPY of currentDocument before setting as sddrObj.
    // Important info is in the sdpoObjects in the sddrObj.sdpoArr.

    const copySddrObjAndCleanErrors = (sddrObjProp) => {
        // clean all error messages
        delete sddrObjProp.customerUIDError;
        delete sddrObjProp.sddrUIDError;
        delete sddrObjProp.sdpoArrError;
        delete sddrObjProp.sddrDateError;
        delete sddrObjProp.noteError;

        sddrObjProp.sdpoArr.forEach((sdpoObj) => {
            sdpoObj.fgArr.forEach((fgObj) => {
                delete fgObj.sddrDeliveredQtyForFgError;
            });
            sdpoObj.sfgArr.forEach((sfgObj) => {
                delete sfgObj.sddrDeliveredQtyForSfgError;
            });
        });

        setGeneralError('');
        setAddSdpoError('');

        return {
            ...sddrObjProp,
            sdpoArr: clone(sddrObjProp.sdpoArr),
        };
    };

    const getSdpos = async () => {
        setIsLoading(true);
        let tempSdpoArr = currentDocument.sdpoArr.map(async (sdpo) => {
            return await dbopsSDPOs.getSdpoFn(sdpo.sdpoUID);
        });
        try {
            tempSdpoArr = await Promise.all(tempSdpoArr);
        } catch (e) {
            console.error(
                'POs have been Cleaned up by system already. DR was never APPROVED. Edge case. Cannot edit or delete or APPROVE. Contact System adminstrator.'
            );
            setAlertErrorMsg(e.message || 'Error getting document');
            setIsLoading(true);
            return;
        }
        tempSdpoArr = tempSdpoArr.map((dbSdpoObj) => dbSdpoObj.data);

        setCurrentSdpoDocsArr(tempSdpoArr);

        let sddrCopy = {
            ...currentDocument,
            sdpoArr: clone(currentDocument.sdpoArr),
        };

        sddrCopy.sdpoArr.forEach((sdpo, sdpoIndex) => {
            sdpo.fgArr.forEach((fg, fgIndex) => {
                fg.fgOrderedQty =
                    tempSdpoArr[sdpoIndex].fgArr[fgIndex].fgOrderedQty;
                fg.fgBalanceQty =
                    tempSdpoArr[sdpoIndex].fgArr[fgIndex].fgBalanceQty;
                fg.fgOverrideQty =
                    tempSdpoArr[sdpoIndex].fgArr[fgIndex].fgOverrideQty;
            });
        });

        sddrCopy.sdpoArr.forEach((sdpo, sdpoIndex) => {
            sdpo.sfgArr.forEach((sfg, sfgIndex) => {
                sfg.sfgOrderedQty =
                    tempSdpoArr[sdpoIndex].sfgArr[sfgIndex].sfgOrderedQty;
                sfg.sfgBalanceQty =
                    tempSdpoArr[sdpoIndex].sfgArr[sfgIndex].sfgBalanceQty;
                sfg.sddrPrevDeliveredQtyForSfg = sfg.sddrDeliveredQtyForSfg;
                sfg.sfgOverrideQty =
                    tempSdpoArr[sdpoIndex].sfgArr[sfgIndex].sfgOverrideQty;
            });
        });

        setSDDRObj(sddrCopy);
        setSddrUIDinState(sddrCopy.sddrUID);
        setIsLoading(false);

        //! return tempSdpoArr as the currentSdpoDocsArr to
        //! be used for APPROVED status change.
        return tempSdpoArr;
    };

    // set sddrObj from currentDocument if purpose !== 'ADD'
    useEffect(() => {
        if (purpose === 'VIEW' || purpose === 'DELETE') {
            // making a copy so no side effect will change currentDocument.
            let currentDocumentCopy = {
                // will copy meta and metaHistory as is.
                ...currentDocument,
                // copy sdpoArr and not a reference.
                sdpoArr: clone(currentDocument.sdpoArr),
            };
            setSddrDateInState(currentDocumentCopy.sddrDate);
            setSDDRObj(currentDocumentCopy);
            setNoteInState(currentDocumentCopy.note || '');
        }

        if (purpose === 'EDIT') {
            getSdpos();
        }
    }, [purpose]);

    const cancelBtnFromEditViewFn = () => {
        // 1. reset values.
        setSDDRObj(currentDocument);

        // 2. reset errorMessages.
        setGeneralError('');

        // 3. go back to profile view.
        backCancelBtnFn();
    };

    // =====================================================

    const addToSdpoArrFn = async () => {
        let sddrObjCopy = copySddrObjAndCleanErrors(sddrObj);

        // get the customer of the pdpo from master list.
        let customerChosen = masterSdpoList.filter((sdpo) => {
            return sdpo.sdpoUID === sdpoUIDinState;
        })[0].customerUID;

        // determine which customer the dr came from using the first pdpo
        // being attempted to be added.
        if (sddrObjCopy.sdpoArr.length === 0)
            sddrObjCopy.customerUID = customerChosen;
        // sddrObj already has a customerUID;
        else {
            // if pdpo being added into the sdpoArr does
            // not have the same customer as the first sdpoObj already in the
            // sdpoArr, do not allow it to be added.
            if (customerChosen !== sddrObjCopy.customerUID) {
                setAddSdpoError(
                    'The PO you are attempting to add does not have the same customer already in this SDDR.'
                );
                return;
            }
        }

        // start process to get from firebase.
        setIsLoading(true);

        let response = await dbopsSDPOs.getSdpoFn(sdpoUIDinState);
        if (response.error) {
            setAddSdpoError(response.errorObj.message || response.message);
            setIsLoading(false);

            return;
        }

        // ===========================================================================
        // make a copy to ensure it is not referencing the same objects in the
        // currentSdpoDocsArr during editing. meta will be copied but it is ok, it will
        // not be edited.
        let sdpoCopy = {
            ...response.data,
            fgArr: clone(response.data.fgArr),
            sfgArr: clone(response.data.sfgArr),
        };

        let fgMap = {};
        let sfgMap = {};
        if (purpose === 'EDIT') {
            //! if sdpoObj was removed from array and then added again, check currentDocument for
            //! the original values and integrate into sdpoArr. without this logic, sddrDeliveredQty
            //! will be set to zero.
            let sdpoUIDsInOrigSddr = currentDocument.sdpoArr.map(
                (sdpo) => sdpo.sdpoUID
            );

            if (sdpoUIDsInOrigSddr.includes(sdpoCopy.sdpoUID)) {
                let sdpoIndex = sdpoUIDsInOrigSddr.indexOf(sdpoCopy.sdpoUID);
                let sdpoObj = currentDocument.sdpoArr[sdpoIndex];
                sdpoObj.fgArr.forEach((fg) => {
                    fgMap[fg.fgUID] = fg;
                });
                sdpoObj.sfgArr.forEach((sfg) => {
                    sfgMap[sfg.sfgUID] = sfg;
                });
            }

            //! =============================================================================
        }

        sdpoCopy.fgArr.forEach((fg) => {
            // each fgObj in fgArr must have sddrDeliveredQtyForFg. this property is used
            // by the HTML input element as value. get existing values if present in currentDocument.
            // if not set to zero just like in creating a new DR.
            if (purpose === 'ADD') {
                fg.sddrDeliveredQtyForFg = 0;
                fg.sddrPrevDeliveredQtyForFg = 0;
            }

            if (purpose === 'EDIT') {
                fg.sddrDeliveredQtyForFg =
                    (fgMap[fg.fgUID] &&
                        fgMap[fg.fgUID].sddrDeliveredQtyForFg) ||
                    0;
                fg.sddrPrevDeliveredQtyForFg = fg.sddrDeliveredQtyForFg;
            }
        });

        sdpoCopy.sfgArr.forEach((sfg) => {
            // same logic above for fg.
            if (purpose === 'ADD') {
                sfg.sddrDeliveredQtyForSfg = 0;
                sfg.sddrPrevDeliveredQtyForSfg = 0;
            }

            if (purpose === 'EDIT') {
                sfg.sddrDeliveredQtyForSfg =
                    (sfgMap[sfg.sfgUID] &&
                        sfgMap[sfg.sfgUID].sddrDeliveredQtyForSfg) ||
                    0;
                sfg.sddrPrevDeliveredQtyForSfg = sfg.sddrDeliveredQtyForSfg;
            }
        });

        sddrObjCopy.sdpoArr.push(sdpoCopy);

        // copy of sdpo for currentSdpoDocsArr - original copies from firebase.
        // will never be modified.
        let sdpoCopyForArr = {
            ...response.data,
            fgArr: clone(response.data.fgArr),
            sfgArr: clone(response.data.sfgArr),
        };
        let arrCopy = [...currentSdpoDocsArr];
        arrCopy.push(sdpoCopyForArr);

        // Testing if copies are not referencing each other.
        // sdpoCopyForArr.fgArr[0].fgUID = 'changed';
        // console.log(sdpoCopy);
        // console.log(sdpoCopyForArr);

        // ==================================================================

        setCurrentSdpoDocsArr(arrCopy);
        setSDDRObj(sddrObjCopy);

        setAddSdpoError('');
        setDatalistDisplayResetCounter(datalistDisplayResetCounter + 1);
        setIsLoading(false);
    };

    const removeSdpoFn = (index) => {
        let sddrObjCopy = copySddrObjAndCleanErrors(sddrObj);

        let currentSdpoDocsArrCopy = [...currentSdpoDocsArr];

        currentSdpoDocsArrCopy.splice(index, 1);
        sddrObjCopy.sdpoArr.splice(index, 1);

        if (sddrObjCopy.sdpoArr.length === 0) sddrObjCopy.customerUID = '';
        setSDDRObj(sddrObjCopy);
        setCurrentSdpoDocsArr(currentSdpoDocsArrCopy);
    };

    const updateDeliveredQtyFn = (
        val,
        sdpoIndex,
        arrName,
        itemIndex,
        propertyName
    ) => {
        let sddrObjCopy = {
            ...sddrObj,
            sdpoArr: clone(sddrObj.sdpoArr),
        };
        let itemObj = {
            ...sddrObjCopy.sdpoArr[sdpoIndex][arrName][itemIndex],
            [propertyName]: val,
        };
        sddrObjCopy.sdpoArr[sdpoIndex][arrName][itemIndex] = itemObj;
        setSDDRObj(sddrObjCopy);
    };

    // for saving functionality
    const previewDrBtnFn = () => {
        let sddrObjCopy = { ...sddrObj };
        sddrObjCopy.sddrUID = sddrObjCopy.sddrUID || sddrUIDinState;
        sddrObjCopy.sddrDate = sddrDateInState;
        sddrObjCopy.note = noteInState;

        let response = validate.sddr(sddrObjCopy, purpose);
        if (response.error) {
            setSDDRObj(response.errorObj.sddrObj);
            setGeneralError(response.errorObj.sddrObj.sdpoArrError);
            setAlertErrorMsg(response.errorObj.hackError);
        } else {
            // no error
            setSDDRObj(response.data.sddrObj);
            setPreview(true);
        }
    };

    const saveDrFn = () => {
        let sddrObjCopy = { ...sddrObj };
        sddrObjCopy.sddrUID = sddrObjCopy.sddrUID || sddrUIDinState;
        sddrObjCopy.sddrDate = sddrDateInState;
        sddrObjCopy.note = noteInState;

        // check if there is a change in the payload from currentDocument
        if (
            purpose === 'EDIT' &&
            // validate.sddrNoChange(sddrObj, currentDocument)
            validate.sddrNoChange(sddrObjCopy, currentDocument)
        ) {
            backCancelBtnFn();
            return;
        }

        let response = validate.sddr(sddrObjCopy, purpose);

        if (response.error) {
            setSDDRObj(response.errorObj.sddrObj);
            setGeneralError(response.errorObj.sddrObj.sdpoArrError);
            setAlertErrorMsg(response.errorObj.hackError);
            return;
        }

        setSDDRObj(response.data.sddrObj);

        let validSddrObj = response.data.validSddrObj;

        if (purpose === 'ADD') createSddrFn(validSddrObj);
        if (purpose === 'EDIT') updateSddrFn(validSddrObj, currentDocument);
        if (purpose === 'DELETE') deleteSddrFn(validSddrObj, currentDocument);
    };

    const statusChangeLocalFn = async (userActionPassed) => {
        let sddrObjCopy = { ...sddrObj };
        sddrObjCopy.sddrUID = sddrObjCopy.sddrUID || sddrUIDinState;
        sddrObjCopy.sddrDate = sddrDateInState;
        sddrObjCopy.note = noteInState;

        let response = validate.sddr(sddrObjCopy, purpose);

        if (response.error) {
            setSDDRObj(response.errorObj.sddrObj);
            setGeneralError(response.errorObj.sddrObj.sdpoArrError);
            setAlertErrorMsg(response.errorObj.hackError);
            return;
        }

        let validSddrObj = response.data.validSddrObj;

        if (userActionPassed === userActions.APPROVED) {
            let documentBasisSdpoArr = await getSdpos();
            statusChangeFn(
                userActionPassed,
                validSddrObj,
                currentDocument,
                documentBasisSdpoArr
            );
        } else {
            statusChangeFn(userActionPassed, validSddrObj, currentDocument);
        }
    };

    // conditional render of contents  =============================

    let sdpoArrUI = [];
    let noteUI = null;
    let buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    let mainContent = null;
    let datalist = null;

    let addBtn = <AddButton disabled />;

    if ((purpose === 'ADD' && !preview) || purpose === 'EDIT') {
        let datalistOptions = masterSdpoList
            .filter((sdpo) => {
                return (
                    sdpo.status === userActions.APPROVED ||
                    sdpo.status === userActions.OVERRIDDEN
                );
            })
            .map((sdpo) => sdpo.sdpoUID); // just get the sdpoUID (string) and put into the list

        let sdpoAlreadyInTheUI = sddrObj.sdpoArr.map((sdpo) => sdpo.sdpoUID);

        if (
            datalistOptions.includes(sdpoUIDinState) &&
            !sdpoAlreadyInTheUI.includes(sdpoUIDinState)
        )
            addBtn = <AddButton onClick={addToSdpoArrFn} />;

        let datalistLabel = (
            <span>
                Add Sales Order
                <HelpTip content={tooltips.sddrPoArr}></HelpTip>
            </span>
        );

        datalist = (
            <div className='get-item-from-db-container'>
                <div className='datalist-with-addbtn-container'>
                    <Datalist
                        label={datalistLabel}
                        list={datalistOptions}
                        initialValue={''}
                        getInputValue={(val) => setSdpoUIDinState(val)}
                        errorMessage={addSdpoError}
                        resetCounter={datalistDisplayResetCounter}></Datalist>
                    {addBtn}
                </div>
            </div>
        );

        for (let index = 0; index < sddrObj.sdpoArr.length; index += 1) {
            let trashIcon = (
                <i
                    className='ms-Icon ms-Icon--Delete clickable-icon'
                    aria-hidden='true'
                    onClick={() => removeSdpoFn(index)}></i>
            );

            //! =======================
            sdpoArrUI.push(
                <div className='span-2-columns-container' key={index}>
                    <AddedSdpoForm
                        trashIcon={trashIcon}
                        sdpoIndex={index}
                        sdpoObj={sddrObj.sdpoArr[index]}
                        updateDeliveredQtyFn={updateDeliveredQtyFn}
                    />
                </div>
            );
        }

        noteUI = (
            <div className='span-2-columns-container'>
                <TextArea
                    label={<span className='parent-labels'>Notes</span>}
                    maxLength={lengths.noteLength}
                    value={noteInState}
                    onChange={(e) => setNoteInState(e.target.value)}
                    errorMessage={sddrObj.noteError}
                />
            </div>
        );

        //! Max number of Po in the list
        if (sddrObj.sdpoArr.length >= lengths.sddrSdpoArrLength)
            datalist = null;

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Delivery Receipt</p>
                    {/* {sddrUID} */}
                    <p className='fpii-text'>
                        {sddrObj.sddrUID || '(Auto Generated)'}
                    </p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Status</p>
                    <p className='fpii-text'>{sddrObj.meta.action || '---'}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Customer</p>
                    <p className='fpii-text'>{sddrObj.customerUID || '---'}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Date </p>
                    <Input
                        type='date'
                        maxLength='12'
                        onChange={(e) => setSddrDateInState(e.target.value)}
                        value={sddrDateInState}
                        errorMessage={sddrObj.sddrDateError}></Input>
                </div>

                {sdpoArrUI}

                {datalist}

                {noteUI}
            </section>
        );
    }

    if (
        (purpose === 'ADD' && preview) ||
        purpose === 'VIEW' ||
        purpose === 'DELETE'
    ) {
        let fgsOfSdpo = [];
        let sfgsOfSdpo = [];
        let sdpoArrUI = sddrObj.sdpoArr.map((sdpo, index) => {
            fgsOfSdpo = sdpo.fgArr.map((fg, fgIndex) => {
                return (
                    <div
                        className='span-2-columns-container'
                        key={`${fg.fgUID} ${sdpo.sdpoUID}`}>
                        <div className='view-table-row'>
                            <div className='cell'>
                                <span className='mobile-label m-right-15'>
                                    {`${fgIndex + 1}.`}
                                </span>
                                <span className='desktop-view m-lr-15'>
                                    {`${fgIndex + 1}.`}
                                </span>{' '}
                                <span>{fg.fgUID}</span>
                            </div>

                            <div className='cell'>
                                <div className='width50'>
                                    <span className='mobile-label'>
                                        Delivered Qty:
                                    </span>{' '}
                                    <span>
                                        {' '}
                                        {displayReadableNumber(
                                            fg.sddrDeliveredQtyForFg
                                        )}
                                    </span>
                                </div>
                                <div className='width50'>
                                    <span className='mobile-label'>Unit:</span>{' '}
                                    <span>{fg.fgUnit}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });

            sfgsOfSdpo = sdpo.sfgArr.map((sfg, sfgIndex) => {
                return (
                    <div
                        className='span-2-columns-container'
                        key={`${sfg.sfgUID} ${sdpo.sdpoUID}`}>
                        <div className='view-table-row'>
                            <div className='cell'>
                                <span className='mobile-label m-right-15'>
                                    {`${sfgIndex + 1}.`}
                                </span>
                                <span className='desktop-view m-lr-15'>
                                    {`${sfgIndex + 1}.`}
                                </span>{' '}
                                <span>{sfg.sfgUID}</span>
                            </div>

                            <div className='cell'>
                                <div className='width50'>
                                    <span className='mobile-label'>
                                        Delivered Qty:
                                    </span>{' '}
                                    <span>
                                        {' '}
                                        {displayReadableNumber(
                                            sfg.sddrDeliveredQtyForSfg
                                        )}
                                    </span>
                                </div>
                                <div className='width50'>
                                    <span className='mobile-label'>Unit:</span>{' '}
                                    <span>{sfg.sfgUnit}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });

            let sfgSection = (
                <>
                    <div className='parent-labels m-top-10 pad-around-5 bg-grey-l2'>
                        SFG List
                    </div>

                    <div className='span-2-columns-container'>
                        <div className='view-table-heading'>
                            <div className='cell'>
                                <span className='m-left-15'>SFG Name</span>
                            </div>
                            <div className='cell'>
                                <span className='width50'>Delivered Qty</span>
                                <span className='width50'>Unit</span>
                            </div>
                        </div>
                    </div>
                </>
            );

            let fgSection = (
                <>
                    <div className='parent-labels m-top-10 pad-around-5 bg-grey-l2'>
                        FG List
                    </div>

                    <div className='span-2-columns-container'>
                        <div className='view-table-heading'>
                            <div className='cell'>
                                <span className='m-left-15'>FG Name</span>
                            </div>
                            <div className='cell'>
                                <span className='width50'>Delivered Qty</span>
                                <span className='width50'>Unit</span>
                            </div>
                        </div>
                    </div>
                </>
            );

            if (sfgsOfSdpo.length === 0) sfgSection = null;
            if (fgsOfSdpo.length === 0) fgSection = null;

            return (
                <div
                    className='span-2-columns-container show-border pad-around-10'
                    key={`${sdpo.sdpoUID}`}>
                    <div className='span-2-columns-container'>
                        <span>
                            <span className='parent-labels'>
                                PO {`${index + 1}:`}
                            </span>{' '}
                            {sdpo.sdpoUID}
                        </span>
                    </div>
                    {sfgSection}
                    {sfgsOfSdpo}
                    {fgSection}
                    {fgsOfSdpo}
                </div>
            );
        });

        noteUI = (
            <div className='span-2-columns-container hide-on-print'>
                <TextArea
                    label={<span className='parent-labels'>Notes</span>}
                    disabled
                    maxLength={lengths.longTextLength}
                    value={noteInState}
                />
            </div>
        );

        if (
            sddrObj.meta.action === userActions.SENT_FOR_APPROVAL &&
            authUser.permissions.includes(permissions.SDDR_APPROVE)
        ) {
            noteUI = (
                <div className='span-2-columns-container hide-on-print'>
                    <TextArea
                        label={<span className='parent-labels'>Notes</span>}
                        maxLength={lengths.noteLength}
                        value={noteInState}
                        onChange={(e) => setNoteInState(e.target.value)}
                        errorMessage={sddrObj.noteError}
                    />
                </div>
            );
        }

        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Delivery Receipt</p>
                    <p className='fpii-text'>{sddrObj.sddrUID}</p>
                </div>

                <div className='form-profile-info-items hide-on-print'>
                    <p className='fpii-label'>Status</p>
                    <p className='fpii-text'>{sddrObj.meta.action || '---'}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Customer</p>
                    <p className='fpii-text'>{sddrObj.customerUID}</p>
                </div>

                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Date</p>
                    <p className='fpii-text'>
                        {sddrObj.sddrDate
                            ? displayDate(sddrObj.sddrDate)
                            : '---'}
                    </p>
                </div>

                <div className='span-2-columns-container label-padding label-highlight'>
                    <label className='parent-labels'>
                        List of Purchase Orders
                    </label>
                </div>

                {sdpoArrUI}

                {noteUI}

                <SignatureLine />
            </section>
        );
    }

    // =========================================================================
    // Button sections for users with SDDR Request permision ===================

    if (purpose === 'ADD') {
        buttonSection = (
            <CreateItemButtons
                createText='Create DR'
                previewText='Preview DR'
                cancelPreviewFn={() => setPreview(false)}
                cancelFn={backCancelBtnFn}
                createFn={saveDrFn}
                previewFn={previewDrBtnFn}
                isPreview={preview}
            />
        );
    }

    if (purpose === 'EDIT') {
        buttonSection = (
            <UpdateItemButtons
                saveFn={saveDrFn}
                cancelFn={cancelBtnFromEditViewFn}
            />
        );
    }

    if (purpose === 'DELETE') {
        buttonSection = (
            <DeleteItemButtons
                cancelFn={backCancelBtnFn}
                deleteFn={saveDrFn}
                confirmeDeleteFn={(e) => setConfirmDeleteText(e.target.value)}
                confirmDeleteTextValue={confirmDeleteText}
                confirmDeleteTextPlaceHolder='Enter DR Number'
                uidValue={sddrObj.sddrUID}
            />
        );
    }

    // ==================================================================
    // Button section for users with SDDR Request permission.
    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.SDDR_REQUEST) &&
        !authUser.permissions.includes(permissions.SDDR_APPROVE)
    ) {
        if (
            sddrObj.meta.action === userActions.CREATED ||
            sddrObj.meta.action === userActions.UPDATED ||
            sddrObj.meta.action === userActions.REJECTED
        ) {
            buttonSection = (
                <BackSendEditDelButtons
                    backBtnFn={backCancelBtnFn}
                    sendForApprovalBtnFn={() =>
                        statusChangeLocalFn(userActions.SENT_FOR_APPROVAL)
                    }
                    editBtnFn={gotoEditViewFn}
                    deleteBtnFn={gotoDeleteViewFn}
                />
            );
        }
        if (sddrObj.meta.action === userActions.APPROVED) {
            buttonSection = (
                <BackWithMarkAsButtons
                    backBtnFn={backCancelBtnFn}
                    markAsBtnFn={() =>
                        statusChangeLocalFn(userActions.MARK_AS_DELIVERED)
                    }
                    markAsBtnText='Mark as Delivered'
                />
            );
        }
    }

    // =========================================================================
    // Button section for users with SDDR Approve permision ===================

    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.SDDR_APPROVE) &&
        !authUser.permissions.includes(permissions.SDDR_REQUEST)
    ) {
        // if document status is SENT_FOR_APPROVAL
        if (sddrObj.meta.action === userActions.SENT_FOR_APPROVAL) {
            buttonSection = (
                <ApproveRejectButtons
                    backBtnFn={backCancelBtnFn}
                    approveBtnFn={() =>
                        statusChangeLocalFn(userActions.APPROVED)
                    }
                    rejectBtnFn={() =>
                        statusChangeLocalFn(userActions.REJECTED)
                    }
                />
            );
        }
    }

    // =========================================================================
    //! button section for users with both PDPO Request and Approve permissions.
    if (
        purpose === 'VIEW' &&
        authUser.permissions.includes(permissions.SDDR_APPROVE) &&
        authUser.permissions.includes(permissions.SDDR_REQUEST)
    ) {
        if (
            sddrObj.meta.action === userActions.CREATED ||
            sddrObj.meta.action === userActions.UPDATED ||
            sddrObj.meta.action === userActions.REJECTED
        ) {
            buttonSection = (
                <BackSendEditDelButtons
                    backBtnFn={backCancelBtnFn}
                    sendForApprovalBtnFn={() =>
                        statusChangeLocalFn(userActions.SENT_FOR_APPROVAL)
                    }
                    editBtnFn={gotoEditViewFn}
                    deleteBtnFn={gotoDeleteViewFn}
                />
            );
        }

        // if document status is SENT_FOR_APPROVAL
        if (sddrObj.meta.action === userActions.SENT_FOR_APPROVAL) {
            buttonSection = (
                <ApproveRejectButtons
                    backBtnFn={backCancelBtnFn}
                    approveBtnFn={() =>
                        statusChangeLocalFn(userActions.APPROVED)
                    }
                    rejectBtnFn={() =>
                        statusChangeLocalFn(userActions.REJECTED)
                    }
                />
            );
        }

        // if document status is APPROVED
        if (sddrObj.meta.action === userActions.APPROVED) {
            buttonSection = (
                <BackWithMarkAsButtons
                    backBtnFn={backCancelBtnFn}
                    markAsBtnFn={() =>
                        statusChangeLocalFn(userActions.MARK_AS_DELIVERED)
                    }
                    markAsBtnText='Mark as Delivered'
                />
            );
        }
    }

    if (!authUser.subscriptionStatusIsActive) {
        buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    }

    let generalErrorUI = (
        <div className='general-error-message'>{generalError}</div>
    );

    return (
        <div className='common-form'>
            <section className='form-title-section'>
                Delivery Receipt Form
            </section>

            {mainContent}

            {buttonSection}
            {generalErrorUI}

            <Watermark purpose={purpose} currentDocument={currentDocument} />
        </div>
    );
};
