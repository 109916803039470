import { responseFn, saveWithTwoDecimalOnly } from '../lib/util';
import { lengths, regEx } from '../lib/constants';
import { generalMessage, appMessage } from '../lib/messages';
import { validateUID } from '../lib/util';

export const sdcr = (sdcr) => {
    // clear all errors

    console.log(sdcr);

    let errorInObj = false;

    delete sdcr.customerUIDError;
    delete sdcr.sdcrUIDError;
    delete sdcr.sdsiArrError;
    delete sdcr.sdcrDateError;
    delete sdcr.noteError;
    delete sdcr.sdcrDiscountError;

    let hackError = '';

    sdcr.sdcrUID = sdcr.sdcrUID.trim();

    // validate sdcrUID
    errorInObj = validateUID(sdcr.sdcrUID).error;
    sdcr.sdcrUIDError = validateUID(sdcr.sdcrUID).errorMessage;

    //  validate if there is at least one SI.
    if (sdcr.sdsiArr.length === 0) {
        errorInObj = true;
        sdcr.sdsiArrError = 'Must have at least one SI';
    }

    if (sdcr.sdsiArr.length > lengths.sdcrSdsiArrLength) {
        errorInObj = true;
        hackError = appMessage.sourceCodeErrorMessage(
            'SDCR: sdsiArr length error.'
        );
    }

    if (sdcr.sdcrDate === '') {
        errorInObj = true;
        sdcr.sdcrDateError = generalMessage.REQUIRED;
    }

    if (!regEx.date.test(sdcr.sdcrDate)) {
        errorInObj = true;
        sdcr.sdcrDateError = generalMessage.INVALID_DATE;
    }

    if (sdcr.note.length > lengths.noteLength) {
        errorInObj = true;
        sdcr.noteError = generalMessage.INVALID_LENGTH;
    }

    let sdsiTotals = 0;

    sdcr.sdsiArr.forEach((siObj) => {
        // validate same customerUID for all pdpoObjs in sdsiArr
        // ! should never run unless UI was hacked.
        if (siObj.customerUID !== sdcr.customerUID) {
            errorInObj = true;
            hackError = appMessage.sourceCodeErrorMessage(
                'SDCR: customerUID not equal in sdcrObj schema.'
            );
        }

        sdsiTotals = saveWithTwoDecimalOnly(sdsiTotals + siObj.sdsiTotalsNett);
    });

    // validate sdcrDiscount
    if (sdcr.sdcrDiscount === '') sdcr.sdcrDiscount = 0;

    // sdcr.sdcrDiscount cannot be greater than sdsiTotals
    if (Number(sdcr.sdcrDiscount) > sdsiTotals) {
        errorInObj = true;
        sdcr.sdcrDiscountError = 'Cannot be greater than Totals (Gross)';
    } else {
        if (isNaN(sdcr.sdcrDiscount)) {
            console.log('1');
            errorInObj = true;
            sdcr.sdcrDiscountError = generalMessage.INVALID;
        }
        if (!isNaN(sdcr.sdcrDiscount) && Number(sdcr.sdcrDiscount) < 0) {
            console.log('2');
            errorInObj = true;
            sdcr.sdcrDiscountError = generalMessage.INVALID;
        }
    }

    let sdcrTotalsNett = saveWithTwoDecimalOnly(sdsiTotals - sdcr.sdcrDiscount);

    if (errorInObj || hackError) {
        let errorObj = {
            sdcrObj: sdcr,
            hackError,
        };
        // console.log(errorObj);
        return responseFn(null, true, errorObj, 'App: Error with SDCR object.');
    }

    let correctSchemaSDSIArr = sdcr.sdsiArr.map((si) => {
        return {
            sdsiUID: si.sdsiUID,
            sdsiDate: si.sdsiDate,
            sdsiTotalsNett: si.sdsiTotalsNett,
            customerUID: si.customerUID,
        };
    });

    // proper schema
    let correctSchema = {
        meta: sdcr.meta || {},
        metaHistory: sdcr.metaHistory || [],
        sdcrUID: sdcr.sdcrUID,
        sdcrNumber: sdcr.sdcrUID,
        sdcrDate: sdcr.sdcrDate,
        customerUID: sdcr.customerUID,
        note: sdcr.note,
        sdsiArr: correctSchemaSDSIArr,
        sdcrDiscount: saveWithTwoDecimalOnly(sdcr.sdcrDiscount),
        sdcrTotalsNett,
    };

    return responseFn(correctSchema, false, {}, 'App: Validation successful.');
};
