import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts';
import { permissions, lengths, customerPriceList } from '../../lib/constants';
import {
    Input,
    TextArea,
    UpdateItemButtons,
    DeleteItemButtons,
    SetupViewButtons,
    BackOnlyButton,
    Select,
    Watermark,
} from '../../components';
import { getWaterMarkDateTime } from '../../lib/util';

import validate from '../../validators';

import '../PagesCommon.css';
import './CustomersPage.css';

const priceOptions = [
    { value: customerPriceList.PRICE_LIST_1 },
    { value: customerPriceList.PRICE_LIST_2 },
    { value: customerPriceList.PRICE_LIST_3 },
    { value: customerPriceList.PRICE_LIST_4 },
    { value: customerPriceList.PRICE_LIST_5 },
];

export default (props) => {
    let {
        purpose,
        backCancelBtnFn,
        createCustomerFn,
        gotoEditViewFn,
        gotoDeleteViewFn,
        currentDocument,
        deleteCustomerFn,
        updateCustomerFn,
    } = props;

    let { authUser } = useContext(AuthContext);

    let [customerName, setCustomerName] = useState('');
    let [customerCode, setCustomerCode] = useState('');
    let [contactPerson, setContactPerson] = useState('');
    let [faxNumber, setFaxNumber] = useState('');
    let [contactNumber1, setContactNumber1] = useState('');
    let [contactNumber2, setContactNumber2] = useState('');
    let [address, setAddress] = useState('');
    let [note, setNote] = useState('');
    let [confirmDeleteText, setConfirmDeleteText] = useState('');
    let [priceListNum, setPriceListNum] = useState('');
    let [discount, setDiscount] = useState(0);

    let [customerNameErr, setCustomerNameErr] = useState('');
    let [contactNumber1Err, setContactNumber1Err] = useState('');
    let [contactNumber2Err, setContactNumber2Err] = useState('');
    let [faxNumberErr, setFaxNumberErr] = useState('');
    let [contactPersonErr, setContactPersonErr] = useState('');
    let [customerCodeErr, setCustomerCodeErr] = useState('');
    let [addressErr, setAddressErr] = useState('');
    let [noteErr, setNoteErr] = useState('');
    let [priceListNumError, setPriceListNumError] = useState('');
    let [discountError, setDiscountError] = useState('');

    useEffect(() => {
        if (purpose !== 'ADD') {
            setCustomerName(currentDocument.customerName);
            setCustomerCode(currentDocument.customerCode);
            setContactPerson(currentDocument.contactPerson);
            setFaxNumber(currentDocument.faxNumber);
            setContactNumber1(currentDocument.contactNumber1);
            setContactNumber2(currentDocument.contactNumber2);
            setAddress(currentDocument.address);
            setNote(currentDocument.note);
            setPriceListNum(currentDocument.priceListNum || '');
            setDiscount(currentDocument.discount || 0);
        }
    }, []);

    // =====================================================
    // Bug fix - 2020-03-25
    /*
        1. From profile view, when you press edit, it goes to edit view.
        2. If you change something but dont save it, then go back to profile view,
            the changes stays on UI even if actual values are not changed in DB.
        3. Must reset values of (RM/Customer/supplier Form) before going back to 
            profile view.
        4. 2020-03-28 - reset errorMessages also.
    */

    const cancelBtnFromEditViewFn = () => {
        // 1. reset values.
        setCustomerName(currentDocument.customerName);
        setCustomerCode(currentDocument.customerCode);
        setContactPerson(currentDocument.contactPerson);
        setFaxNumber(currentDocument.faxNumber);
        setContactNumber1(currentDocument.contactNumber1);
        setContactNumber2(currentDocument.contactNumber2);
        setAddress(currentDocument.address);
        setNote(currentDocument.note);
        setPriceListNum(currentDocument.priceListNum || '');
        setDiscount(currentDocument.discount || 0);

        // 2. reset errorMessages
        setCustomerNameErr('');
        setCustomerCodeErr('');
        setContactPersonErr('');
        setFaxNumberErr('');
        setContactNumber1Err('');
        setContactNumber2Err('');
        setAddressErr('');
        setNoteErr('');
        setPriceListNumError('');
        setDiscountError('');

        // 3. go back to profile view.
        backCancelBtnFn();
    };

    // =====================================================

    const saveBtnFn = () => {
        let customerObj = {
            // ensure that meta and metaHistory properties will be added in payload.
            ...currentDocument,
            // overwrite other properties
            customerName: customerName.trim(),
            customerCode: customerCode.trim(),
            contactPerson: contactPerson.trim(),
            faxNumber: faxNumber.trim(),
            contactNumber1: contactNumber1.trim(),
            contactNumber2: contactNumber2.trim(),
            address: address.trim(),
            note: note.trim(),
            discount,
            priceListNum,
        };

        // check if there is a change in the payload from currentDocument
        if (
            purpose === 'EDIT' &&
            validate.noChange(customerObj, currentDocument)
        ) {
            backCancelBtnFn();
            return;
        }

        // validate
        let response = validate.customer(customerObj, purpose);
        if (response.error) {
            let e = response.errorObj;
            setCustomerNameErr(e.customerNameErr);
            setContactNumber1Err(e.contactNumber1Err);
            setContactNumber2Err(e.contactNumber2Err);
            setFaxNumberErr(e.faxNumberErr);
            setContactPersonErr(e.contactPersonErr);
            setCustomerCodeErr(e.customerCodeErr);
            setAddressErr(e.addressErr);
            setNoteErr(e.noteErr);
            setPriceListNumError(e.priceListNumError);
            setDiscountError(e.discountError);
            return;
        }

        let completeCustomerObj = response.data;

        if (purpose === 'ADD') createCustomerFn(completeCustomerObj);
        if (purpose === 'EDIT')
            updateCustomerFn(completeCustomerObj, currentDocument);
        if (purpose === 'DELETE')
            deleteCustomerFn(completeCustomerObj, currentDocument);
    };

    // conditional render of contents  =============================
    let buttonSection = null;
    let mainContent = null;

    let uidInput = (
        <Input
            label='Customer Name'
            maxLength={lengths.uid}
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            placeholder='Required'
            errorMessage={customerNameErr}
        />
    );
    if (purpose === 'EDIT') {
        uidInput = (
            <Input
                label='Customer Name'
                disabled
                value={customerName}
                errorMessage={customerNameErr}
            />
        );
    }

    if (purpose === 'ADD' || purpose === 'EDIT') {
        mainContent = (
            <section className='form-main-section'>
                {uidInput}
                <Input
                    label='Customer Code'
                    maxLength={lengths.code}
                    value={customerCode}
                    onChange={(e) => setCustomerCode(e.target.value)}
                    errorMessage={customerCodeErr}
                />
                <Input
                    label='Contact Person'
                    maxLength={lengths.contactPerson}
                    value={contactPerson}
                    onChange={(e) => setContactPerson(e.target.value)}
                    errorMessage={contactPersonErr}
                />
                <Input
                    label='Phone Number 1'
                    maxLength={lengths.phone}
                    value={contactNumber1}
                    onChange={(e) => setContactNumber1(e.target.value)}
                    placeholder='Required'
                    errorMessage={contactNumber1Err}
                />
                <Input
                    label='Phone Number 2'
                    maxLength={lengths.phone}
                    value={contactNumber2}
                    onChange={(e) => setContactNumber2(e.target.value)}
                    errorMessage={contactNumber2Err}
                />
                <Input
                    label='Fax Number'
                    maxLength={lengths.phone}
                    value={faxNumber}
                    onChange={(e) => setFaxNumber(e.target.value)}
                    errorMessage={faxNumberErr}
                />

                <Select
                    label='Price List'
                    optionsArr={priceOptions}
                    value={priceListNum}
                    onChange={(e) => setPriceListNum(e.target.value)}
                    placeholder='Required'
                    errorMessage={priceListNumError}
                />

                <Input
                    label='Discount (%)'
                    type='number'
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                    errorMessage={discountError}
                />

                <TextArea
                    label='Address'
                    maxLength={lengths.regTextLength}
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    errorMessage={addressErr}
                />
                <TextArea
                    label='Note'
                    maxLength={lengths.longTextLength}
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    errorMessage={noteErr}
                />
            </section>
        );
    }

    if (purpose === 'VIEW' || purpose === 'DELETE') {
        mainContent = (
            <section className='form-main-section'>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Customer Name</p>
                    <p className='fpii-text'>{customerName}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Customer Code</p>
                    <p className='fpii-text'>{customerCode}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Contact Person</p>
                    <p className='fpii-text'>{contactPerson}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Phone Number 1</p>
                    <p className='fpii-text'>{contactNumber1}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Phone Number 2</p>
                    <p className='fpii-text'>{contactNumber2}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Fax Number</p>
                    <p className='fpii-text'>{faxNumber}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Price List</p>
                    <p className='fpii-text'>{priceListNum}</p>
                </div>
                <div className='form-profile-info-items'>
                    <p className='fpii-label'>Discount (%)</p>
                    <p className='fpii-text'>{discount}</p>
                </div>
                <div className='form-profile-info-items-tall'>
                    <p className='fpii-label'>Address</p>
                    <p className='fpii-text'>{address}</p>
                </div>
                <div className='form-profile-info-items-tall'>
                    <p className='fpii-label'>Note</p>
                    <p className='fpii-text'>{note}</p>
                </div>
            </section>
        );
    }

    if (purpose === 'ADD') {
        buttonSection = (
            <UpdateItemButtons saveFn={saveBtnFn} cancelFn={backCancelBtnFn} />
        );
    }

    if (purpose === 'EDIT') {
        buttonSection = (
            <UpdateItemButtons
                saveFn={saveBtnFn}
                cancelFn={cancelBtnFromEditViewFn}
            />
        );
    }

    if (purpose === 'DELETE') {
        buttonSection = (
            <DeleteItemButtons
                cancelFn={backCancelBtnFn}
                deleteFn={saveBtnFn}
                confirmeDeleteFn={(e) => setConfirmDeleteText(e.target.value)}
                confirmDeleteTextValue={confirmDeleteText}
                confirmDeleteTextPlaceHolder='Enter customer name'
                uidValue={customerName}
            />
        );
    }

    if (purpose === 'VIEW') {
        buttonSection = (
            <SetupViewButtons
                backBtnFn={backCancelBtnFn}
                deleteBtnFn={gotoDeleteViewFn}
                editBtnFn={gotoEditViewFn}
            />
        );
    }

    if (
        !authUser.permissions.includes(permissions.CUSTOMER_SETUP) ||
        !authUser.subscriptionStatusIsActive
    ) {
        buttonSection = <BackOnlyButton backBtnFn={backCancelBtnFn} />;
    }

    return (
        <div className='common-form'>
            <section className='form-title-section'>Customer Profile</section>

            {mainContent}

            {buttonSection}

            <Watermark purpose={purpose} currentDocument={currentDocument} />
        </div>
    );
};
