import { db, firebase } from '../firebase_config/firebaseConfig';
import { userActions } from '../lib/constants';
import { responseFn, formatCustomErrorObj } from '../lib/util';
import validate from '../validators';
import mListHelper from './mListHelper';
import { docNames, appMessage } from '../lib/messages';
import dbopsGetDocument from './dbopsGetDocument';

import {
    sfgInvObjToMListItemFn,
    sfgObjToMListItemFn,
} from '../lib/masterListConverters';

import {
    SEMI_FGS_COL,
    MASTER_LISTS_COL,
    MASTER_SEMI_FGS_LIST_DOC,
    DELETED_SEMI_FGS_COL,
    MASTER_INVENTORY_SFG_LIST_DOC,
} from '../lib/constants';

// ================================================
// function to fetch each sfg document.
const getSFGFn = async (docID) =>
    dbopsGetDocument(SEMI_FGS_COL, docID, docNames.SFG);

// ======================================================
const createSFGFn = async (userCreds, payload) => {
    // add metadata to payload to be created in firestore.
    payload.meta = {
        action: userActions.CREATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };
    // add metaHistory
    payload.metaHistory = [];

    let newSFGRef = db.collection(SEMI_FGS_COL).doc(payload.sfgUID);

    let sfgListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_SEMI_FGS_LIST_DOC);

    let inventorySfgListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_INVENTORY_SFG_LIST_DOC);

    const transactionFn = async (transaction) => {
        let newSFGDoc = await transaction.get(newSFGRef);
        let sfgListDoc = await transaction.get(sfgListRef);
        let inventorySfgListDoc = await transaction.get(inventorySfgListRef);

        // create new sfg document
        if (newSFGDoc.exists) {
            return Promise.reject(
                formatCustomErrorObj(
                    1002,
                    appMessage.alreadyExist(docNames.SFG)
                )
            );
        }
        await transaction.set(newSFGRef, payload);

        // create or update sfgs list document in (master lists collection)
        if (sfgListDoc.exists) {
            let sfgInfoObj = sfgObjToMListItemFn(payload);

            let newData = mListHelper.updateMasterList(
                sfgListDoc,
                payload.sfgUID,
                sfgInfoObj
            );

            transaction.set(sfgListRef, newData);
        } else {
            let sfgInfoObj = sfgObjToMListItemFn(payload);

            let newData = mListHelper.addMasterList(payload.sfgUID, sfgInfoObj);

            transaction.set(sfgListRef, newData);
        }

        // create or update inventory sfgs list document in (master lists collection)
        if (inventorySfgListDoc.exists) {
            let sfgInvInfoObj = sfgInvObjToMListItemFn(payload);

            let newData = mListHelper.updateMasterList(
                inventorySfgListDoc,
                payload.sfgUID,
                sfgInvInfoObj
            );

            transaction.set(inventorySfgListRef, newData);
        } else {
            let sfgInvInfoObj = sfgInvObjToMListItemFn(payload);

            let newData = mListHelper.addMasterList(
                payload.sfgUID,
                sfgInvInfoObj
            );

            transaction.set(inventorySfgListRef, newData);
        }
        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyCreated(docNames.SFG)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorDeleting(docNames.SFG)
        );
    }
};

// ===============================================
const updateSFGFn = async (userCreds, payload, documentBasis) => {
    // move old metadate into metahistory. ensure meta data history length
    // is 100 or less. will be checked by security rules.
    if (payload.metaHistory.length > 99) payload.metaHistory.shift();

    payload.metaHistory.push(payload.meta);

    // update metadata in payload.
    payload.meta = {
        action: userActions.UPDATED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    let sfgRef = db.collection(SEMI_FGS_COL).doc(payload.sfgUID);

    let sfgsListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_SEMI_FGS_LIST_DOC);

    let inventorySfgListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_INVENTORY_SFG_LIST_DOC);

    const transactionFn = async (transaction) => {
        let inventorySfgListDoc = await transaction.get(inventorySfgListRef);
        let sfgsListDoc = await transaction.get(sfgsListRef);
        let sfgDoc = await transaction.get(sfgRef);

        if (!sfgDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotEditBecauseDocNotExist(docNames.SFG)
                )
            );

        // if there is a change in sfg document from the time
        // it was read prior to editting and now (transaction.get),
        // throw an error.
        if (!validate.noChange(sfgDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotEditBecauseDocChanged(docNames.SFG)
                )
            );
        }

        let sfgInfoObj = sfgObjToMListItemFn(payload);

        let newData = mListHelper.updateMasterList(
            sfgsListDoc,
            payload.sfgUID,
            sfgInfoObj
        );

        let sfgInvInfoObj = sfgInvObjToMListItemFn(payload);

        let newInvData = mListHelper.updateMasterList(
            inventorySfgListDoc,
            payload.sfgUID,
            sfgInvInfoObj
        );

        await transaction.set(sfgsListRef, newData);
        await transaction.set(inventorySfgListRef, newInvData);
        await transaction.set(sfgRef, payload);

        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyUpdated(docNames.SFG)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorUpdating(docNames.SFG)
        );
    }
};

// ================================================================
const deleteSFGFn = async (userCreds, payload, documentBasis) => {
    if (payload.metaHistory.length > 99) payload.metaHistory.shift();

    payload.metaHistory.push(payload.meta);

    payload.meta = {
        action: userActions.DELETED,
        uid: userCreds.uid,
        email: userCreds.email,
        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
    };

    // use sfgUID as docuid is ok. see updateSFGFn
    let sfgRef = db.collection(SEMI_FGS_COL).doc(payload.sfgUID);

    let sfgsListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_SEMI_FGS_LIST_DOC);

    let inventorySfgListRef = db
        .collection(MASTER_LISTS_COL)
        .doc(MASTER_INVENTORY_SFG_LIST_DOC);

    let deletedSFGRef = db.collection(DELETED_SEMI_FGS_COL).doc();

    const transactionFn = async (transaction) => {
        let sfgDoc = await transaction.get(sfgRef);

        if (!sfgDoc.exists)
            return Promise.reject(
                formatCustomErrorObj(
                    1000,
                    appMessage.cannotDeleteBecauseDocNotExist(docNames.SFG)
                )
            );

        // if there is a change in sfg document from the time
        // it was read prior to deleting and now (transaction.get),
        // throw an error.
        if (!validate.noChange(sfgDoc.data(), documentBasis)) {
            return Promise.reject(
                formatCustomErrorObj(
                    1001,
                    appMessage.cannotDeleteBecauseDocChanged(docNames.SFG)
                )
            );
        }

        let sfgsListDoc = await transaction.get(sfgsListRef);
        let inventorySFGsListDoc = await transaction.get(inventorySfgListRef);
        await transaction.get(deletedSFGRef);

        let newData = mListHelper.deletePropertyFromMasterList(
            sfgsListDoc,
            payload.sfgUID
        );

        let newInventorySfgsListData = mListHelper.deletePropertyFromMasterList(
            inventorySFGsListDoc,
            payload.sfgUID
        );

        await transaction.delete(sfgRef);
        await transaction.set(sfgsListRef, newData);
        await transaction.set(inventorySfgListRef, newInventorySfgsListData);
        await transaction.set(deletedSFGRef, payload);
        return Promise.resolve('TransactionFn Completed Successfully');
    };

    try {
        await db.runTransaction(transactionFn);
        return responseFn(
            null,
            false,
            null,
            appMessage.successfullyDeleted(docNames.SFG)
        );
    } catch (e) {
        return responseFn(
            null,
            true,
            e,
            appMessage.errorDeleting(docNames.SFG)
        );
    }
};

export default {
    getSFGFn,
    createSFGFn,
    updateSFGFn,
    deleteSFGFn,
};
