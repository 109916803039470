import React from 'react';
import { saveWithTwoDecimalOnly, sortReportItems } from '../../lib/util';

import ReportsForm from './ReportsForm';
import ReportItemsEach from './ReportItemsEach';
import tooltips from '../../lib/tooltips';

export default ({ backCancelBtnFn, accountsReceivablesObj }) => {
    //! Accounts Receivable ===========================================
    let accountsReceivableUI = [];

    let customerAR_arr = [];
    let map = accountsReceivablesObj.customerMap;

    for (let key in map) {
        let totalForCustomer = 0;
        let customerAR_obj = {};
        customerAR_obj.itemUID = key;

        map[key].forEach((invoiceObj) => {
            let invoiceNumber = Object.keys(invoiceObj)[0];
            totalForCustomer = saveWithTwoDecimalOnly(
                totalForCustomer + invoiceObj[invoiceNumber]
            );
        });
        customerAR_obj.total = totalForCustomer;
        customerAR_arr.push(customerAR_obj);
    }

    sortReportItems(customerAR_arr);

    customerAR_arr.forEach((item, index) => {
        accountsReceivableUI.push(
            <ReportItemsEach
                key={index}
                index={index}
                item={item}
                mobileLabel='Invoice Amount'
            />
        );
    });

    return (
        <ReportsForm
            helpText={tooltips.reportAR}
            uid={`Accounts Receivables - As of ${accountsReceivablesObj.reportMonth} ${accountsReceivablesObj.reportYear}`}
            nameLabel='Customer'
            amountLabel='Amount Receivable'
            total={accountsReceivablesObj.total}
            list={accountsReceivableUI}
            backCancelBtnFn={backCancelBtnFn}
        />
    );
};
