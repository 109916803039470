import React from 'react';
import { HelpTip, InputDS } from '../../components';
import tooltips from '../../lib/tooltips';
import '../PagesCommon.css';
import './ProdOrdersPage.css';
import { displayReadableNumber, saveWithFiveDecimals } from '../../lib/util';

const GroupedRM = (props) => {
    let { rmArr, sfgUID, sfgIndex, purpose, updateRmValFn } = props;

    let rms = rmArr.map((rm) => {
        let overrideUI = (
            <div className='width20'>
                <span className='mobile-label'>Override Qty:</span>{' '}
                <span> {displayReadableNumber(rm.rmOverrideQty)}</span>
            </div>
        );

        if (purpose === 'OVERRIDE') {
            overrideUI = (
                <div className='width20'>
                    <span className='mobile-label'>Override Qty:</span>{' '}
                    <span>
                        <InputDS
                            label=''
                            type='number'
                            initialValue={rm.rmOverrideQty}
                            getInputValue={(val) =>
                                updateRmValFn(
                                    val,
                                    sfgIndex,
                                    rm.tempRmIndex,
                                    'rmOverrideQty'
                                )
                            }
                            errorMessage={rm.rmOverrideQtyError}></InputDS>
                    </span>
                </div>
            );
        }

        return (
            <div
                className='span-2-columns-container'
                key={`${sfgUID}${rm.rmUID}`}>
                <div className='view-table-row grid-row-40-60'>
                    <div className='cell'>
                        <div className='width16'>
                            <span className='mobile-label'>Grp:</span>{' '}
                            <span className='desktop-view m-left-15'>
                                {' '}
                                {rm.group}
                            </span>
                            <span className='mobile-label'> {rm.group}</span>
                        </div>
                        <div className=''>
                            <span className='mobile-label'>RM:</span>{' '}
                            <span> {rm.rmUID}</span>
                        </div>
                    </div>
                    <div className='cell'>
                        <div className='width20'>
                            {' '}
                            <span className='mobile-label'>
                                Actual Qty (Used):
                            </span>{' '}
                            <span>
                                {' '}
                                {displayReadableNumber(rm.rmActualUsedQty)}
                            </span>
                        </div>
                        <div className='width20'>
                            <span className='mobile-label'>
                                Projected Qty (Planned):
                            </span>{' '}
                            <span>
                                {' '}
                                {displayReadableNumber(rm.rmProjectedUseQty)}
                            </span>
                        </div>
                        <div className='width20'>
                            <span className='mobile-label'>Balance Qty:</span>{' '}
                            <span>
                                {' '}
                                {displayReadableNumber(
                                    saveWithFiveDecimals(
                                        Number(rm.rmBalanceQty) +
                                            Number(rm.rmOverrideQty)
                                    )
                                )}
                            </span>
                        </div>
                        {overrideUI}
                        <div className='width20'>
                            <span className='mobile-label'>Unit:</span>{' '}
                            <span>{rm.rmUnit}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    return <div className='span-2-columns-container'>{rms}</div>;
};

export default (props) => {
    let { sfgObj, sfgIndex, purpose, updateRmValFn, updateSfgValFn } = props;

    let groupedRmMap = {};

    sfgObj.rmArr.forEach((rm, rmIndex) => {
        rm.tempRmIndex = rmIndex;
        if (groupedRmMap[rm.group] === undefined) {
            groupedRmMap[rm.group] = [];
            groupedRmMap[rm.group].push(rm);
        } else groupedRmMap[rm.group].push(rm);
    });

    let listOfRms = [];

    for (let gkey in groupedRmMap) {
        let component = (
            <GroupedRM
                key={`${sfgObj.sfgUID}${gkey}`}
                sfgUID={sfgObj.sfgUID}
                sfgIndex={sfgIndex}
                rmArr={groupedRmMap[gkey]}
                purpose={purpose}
                updateRmValFn={updateRmValFn}
            />
        );
        listOfRms.push(component);
    }

    let sfgOverrideUI = (
        <span className='width20 flex-column-to-row'>
            <span className='font-size-13 text-bold m-right-5'>
                Override Qty:
            </span>{' '}
            <span>
                <span>{displayReadableNumber(sfgObj.sfgOverrideQty)} </span>
            </span>
        </span>
    );

    if (purpose === 'OVERRIDE') {
        sfgOverrideUI = (
            <span className='width20 flex-column-to-row'>
                <span className='font-size-13 text-bold m-right-5'>
                    Override Qty
                </span>{' '}
                <span>
                    <span>
                        <InputDS
                            label=''
                            type='number'
                            initialValue={sfgObj.sfgOverrideQty}
                            getInputValue={(val) =>
                                updateSfgValFn(val, sfgIndex, 'sfgOverrideQty')
                            }
                            errorMessage={sfgObj.sfgOverrideQtyError}></InputDS>
                    </span>
                </span>
            </span>
        );
    }

    return (
        <div
            className='span-2-columns-container show-border-darker pad-around-10 m-bottom-10'
            key={`${sfgObj.sfgUID}${sfgIndex}`}>
            <div className='view-table-row grid-row-40-60 bg-grey-l2 pad-around-10'>
                <div className='cell'>
                    <span className='m-right-5'>{`${sfgIndex + 1}. `}</span>
                    <span>{sfgObj.sfgUID}</span>
                </div>
                <div className='cell'>
                    <span className='width20 flex-column-to-row'>
                        <span className='font-size-13 text-bold m-right-5'>
                            Produced Qty{' '}
                            <HelpTip
                                content={tooltips.prodOrderSfgProducedQty}
                            />
                        </span>{' '}
                        <span>
                            {displayReadableNumber(sfgObj.sfgProducedQty)}{' '}
                        </span>
                    </span>

                    <span className='width20 flex-column-to-row'>
                        <span className='font-size-13 text-bold m-right-5'>
                            Ordered Qty
                        </span>{' '}
                        <span>
                            {displayReadableNumber(sfgObj.sfgOrderedQty)}{' '}
                        </span>
                    </span>

                    <span className='width20 flex-column-to-row'>
                        <span className='font-size-13 text-bold m-right-5'>
                            Balance Qty
                        </span>{' '}
                        <span>
                            <span>
                                {displayReadableNumber(
                                    saveWithFiveDecimals(
                                        Number(sfgObj.sfgBalanceQty) +
                                            Number(sfgObj.sfgOverrideQty)
                                    )
                                )}{' '}
                            </span>
                        </span>
                    </span>

                    {sfgOverrideUI}

                    <span className='width20 flex-column-to-row'>
                        <span className='font-size-13 text-bold m-right-5'>
                            Unit
                        </span>{' '}
                        <span>{sfgObj.sfgUnit}</span>
                    </span>
                </div>
            </div>

            <div className='span-2-columns-container show-border m-top-10 pad-around-10'>
                <div className='view-table-heading grid-row-40-60'>
                    <div className='cell'>
                        <span className='width16'>
                            <span className='m-left-15'>Grp</span>
                        </span>
                        <span>RM Items</span>
                    </div>
                    <div className='cell'>
                        <span className='width20'>
                            Actual Qty (Used){' '}
                            <HelpTip
                                content={tooltips.prodOrderRmActualUsedQty}
                            />
                        </span>

                        <span className='width20'>Projected Qty (Planned)</span>
                        <span className='width20'>Balance Qty</span>
                        <span className='width20'>Override Qty</span>
                        <span className='width20'>Unit</span>
                    </div>
                </div>
                {listOfRms}
            </div>
        </div>
    );
};
