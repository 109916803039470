import React, { useState, useContext, useEffect } from 'react';
import { AuthContext, MasterListContext } from '../../contexts';
import firebaseAuth from '../../firebase_auth/firebaseAuth';
import { db } from '../../firebase_config/firebaseConfig';
import mListHelper from '../../firebase_dbops/mListHelper';
import '../PagesCommon.css';
import './ProfilePage.css';
import ProfileForm from './ProfileForm';
import validate from '../../validators';
import { formatCustomErrorObj } from '../../lib/util';
import {
    MASTER_USERS_LIST_DOC,
    USERS_COL,
    MASTER_LISTS_COL,
} from '../../lib/constants';

const view = {
    CHANGE_NAME_VIEW: 'change-n',
    CHANGE_PASSWORD_VIEW: 'change-p',
    UAM_VIEW: 'uam-view',
    SUPER_USER_VIEW: 's-view',
    PROFILE_VIEW: 'profile',
};

export default (props) => {
    let { setIsLoading, setAlertErrorMsg, setAlertMessage } = props;

    let { authUser, setAuthUser } = useContext(AuthContext);
    let { masterUsersList } = useContext(MasterListContext);

    let [formView, setFormView] = useState(view.PROFILE_VIEW);

    let [newPassword, setNewPassword] = useState('');
    let [currentPassword, setCurrentPassword] = useState('');
    let [name, setName] = useState(authUser.displayName || '');

    let [nameError, setNameError] = useState('');
    let [newPasswordError, setNewPasswordError] = useState('');
    let [currentPasswordError, setCurrentPasswordError] = useState('');

    const deleteErrors = () => {
        setNameError('');
        setNewPasswordError('');
        setCurrentPasswordError('');
    };

    useEffect(() => {
        setName(authUser.displayName);
        setNewPassword('');
        setCurrentPassword('');
        deleteErrors();
    }, [formView]);

    // functions to change views.
    const gotoChangeNameViewFn = () => setFormView(view.CHANGE_NAME_VIEW);
    const gotoChangePasswordViewFn = () =>
        setFormView(view.CHANGE_PASSWORD_VIEW);
    const gotoProfileViewFn = () => setFormView(view.PROFILE_VIEW);

    const changeNameFn = async (displayName) => {
        deleteErrors();

        // put displayName in an object before validation.
        let validated = validate.userProfile(
            { displayName },
            'change displayName'
        );
        if (validated.error) {
            setNameError(validated.errorObj.displayNameError);
            return;
        }

        setIsLoading(true);

        //! 1. change displayName in Firebase Authentication.
        let response = await firebaseAuth.updateProfileFn(
            validated.data.displayName
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message || response.message);
            setIsLoading(false);
            return;
        }

        // change displayName in authUser in context
        let authUserCopy = Object.assign({}, authUser);
        authUserCopy.displayName = displayName;
        setAuthUser(authUserCopy);

        let shouldUpdateUserDoc = masterUsersList.filter(
            (user) => user.email === authUser.email
        );

        //! if user updating display name is in the masterlist, updtate the masterlist.
        if (shouldUpdateUserDoc.length !== 0) {
            //! 2. if no Error, change firestore user doc and masterUsersListDoc
            //! in a transaction.
            let userRef = db.collection(USERS_COL).doc(authUser.uid);
            let masterUsersListRef = db
                .collection(MASTER_LISTS_COL)
                .doc(MASTER_USERS_LIST_DOC);

            const transactionFn = async (transaction) => {
                let userDoc = await transaction.get(userRef);
                let masterUsersListDoc = await transaction.get(
                    masterUsersListRef
                );

                // check doc exists but no need to check doc change. (just overwrite any change)
                // only one user expected to have access to this user anyway.
                if (!userDoc.exists)
                    return Promise.reject(
                        formatCustomErrorObj(1000, 'User does not exist')
                    );

                let userInfoObj = {
                    a: authUser.email,
                    b: displayName,
                };

                let newData = mListHelper.updateMasterList(
                    masterUsersListDoc,
                    authUserCopy.uid,
                    userInfoObj
                );

                await transaction.set(
                    userRef,
                    { displayName },
                    { merge: true }
                );

                await transaction.set(masterUsersListRef, newData);
            };

            try {
                await db.runTransaction(transactionFn);
                setAlertMessage('Succefully updated display name');
            } catch (e) {
                console.error(e);
                setAlertErrorMsg(
                    (e && e.message) || 'Error updating user display name'
                );
                setIsLoading(false);
                return;
            }
        }

        gotoProfileViewFn();
        setIsLoading(false);
    };

    const changePasswordFn = async (currentPassword, newPassword) => {
        deleteErrors();

        // put currentPassword and newPassword in an object before valiation.
        let validated = validate.userProfile(
            { currentPassword, newPassword },
            'change password'
        );
        if (validated.error) {
            setCurrentPasswordError(validated.errorObj.currentPasswordError);
            setNewPasswordError(validated.errorObj.newPasswordError);
            return;
        }

        setIsLoading(true);
        let response = await firebaseAuth.changePasswordFn(
            validated.data.currentPassword,
            validated.data.newPassword
        );
        if (response.error) {
            if (
                response.errorObj &&
                response.errorObj.code === 'auth/wrong-password'
            ) {
                setCurrentPasswordError(response.errorObj.message);
                setIsLoading(false);
                return;
            }
            if (
                response.errorObj &&
                response.errorObj.code === 'auth/weak-password'
            ) {
                setNewPasswordError(response.errorObj.message);
                setIsLoading(false);

                return;
            }

            setAlertErrorMsg(
                (response.errorObj && response.errorObj.message) ||
                    response.message
            );
            setIsLoading(false);
            return;
        }

        setAlertMessage(response.message);
        gotoProfileViewFn();
        setIsLoading(false);
    };

    let mainSectionContent = null;

    if (formView === view.CHANGE_NAME_VIEW) {
        mainSectionContent = (
            <ProfileForm
                purpose={view.CHANGE_NAME_VIEW}
                backCancelBtnFn={gotoProfileViewFn}
                changeNameFn={changeNameFn}
                nameError={nameError}
                authUser={authUser}
                view={view}
                name={name}
                setName={setName}
            />
        );
    }

    if (formView === view.CHANGE_PASSWORD_VIEW) {
        mainSectionContent = (
            <ProfileForm
                purpose={view.CHANGE_PASSWORD_VIEW}
                backCancelBtnFn={gotoProfileViewFn}
                changePasswordFn={changePasswordFn}
                newPasswordError={newPasswordError}
                currentPasswordError={currentPasswordError}
                authUser={authUser}
                view={view}
                newPassword={newPassword}
                setNewPassword={setNewPassword}
                currentPassword={currentPassword}
                setCurrentPassword={setCurrentPassword}
            />
        );
    }

    if (formView === view.PROFILE_VIEW) {
        mainSectionContent = (
            <ProfileForm
                purpose={view.PROFILE_VIEW}
                gotoChangeNameViewFn={gotoChangeNameViewFn}
                gotoChangePasswordViewFn={gotoChangePasswordViewFn}
                authUser={authUser}
                view={view}
            />
        );
    }

    return (
        <div className='content-layout'>
            <section className='main-section'>{mainSectionContent}</section>
        </div>
    );
};
