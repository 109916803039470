export default (subsInvoiceMasterlistStringFormat) => {
    let newArr = [];
    let subsInvoiceObjsInObjFormat = JSON.parse(
        subsInvoiceMasterlistStringFormat
    );

    for (let key in subsInvoiceObjsInObjFormat) {
        newArr.unshift({
            webhookObjUID: key,
            subscriptionInvoiceUID: subsInvoiceObjsInObjFormat[key].a,
            subscriptionInvoiceBillingCycle: subsInvoiceObjsInObjFormat[key].b,
            subscriptionInvoicePrice: subsInvoiceObjsInObjFormat[key].c,
            invoiceStatus: subsInvoiceObjsInObjFormat[key].d,
        });
    }

    return newArr;
};
