import React from 'react';

export default (props) => {
    let { purpose, redirectLinkFn, views } = props;

    let heading = '';
    let linkText = null;

    if (purpose === 'verifyEmailSent') {
        heading = 'Email verification sent. Please check your email.';
        linkText = (
            <>
                <span
                    className='email-sent-redirect-link'
                    id={views.RESEND_VERIFICATION}
                    onClick={redirectLinkFn}
                >
                    {' '}
                    Resend{' '}
                </span>
                verification email or
            </>
        );
    }
    if (purpose === 'passResetSent') {
        heading = 'Password reset email sent. Please check your email.';
        linkText = (
            <>
                <span
                    className='email-sent-redirect-link'
                    id={views.FORGET_PASSWORD}
                    onClick={redirectLinkFn}
                >
                    {' '}
                    Resend{' '}
                </span>
                reset email or
            </>
        );
    }

    return (
        <div className='login-form'>
            <div className='lgf-heading'> {heading} </div>
            <div className='redirect-text'>
                {linkText}
                <span
                    className='email-sent-redirect-link'
                    id={views.LOGIN}
                    onClick={redirectLinkFn}
                >
                    {' '}
                    Login{' '}
                </span>
                .
            </div>
        </div>
    );
};
