import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import {
    AuthContextProvider,
    MasterListProvider,
    DataShareProvider,
} from './contexts';
import './css/normalize.css';
import './css/index.css';
import './css/fabric-icons/css/fabric-icons.css';
import './css/fabric-icons-2/css/fabric-icons.css';
import './css/fabric-icons-Report/css/fabric-icons.css';
import App from './App';

let app = (
    <BrowserRouter>
        <AuthContextProvider>
            <DataShareProvider>
                <MasterListProvider>
                    <App />
                </MasterListProvider>
            </DataShareProvider>
        </AuthContextProvider>
    </BrowserRouter>
);

ReactDOM.render(app, document.getElementById('root'));
