import React, { useState, useContext, useEffect } from 'react';
import { dbopsPDPOs } from '../../firebase_dbops';
import {
    MasterListContext,
    AuthContext,
    DataShareContext,
} from '../../contexts';
import { InputIcon, ListView2 } from '../../components';
import PDSDItem from '../../components/CollectionItems/PDSDItem/PDSDItem';
import { permissions, userActions, lengths } from '../../lib/constants';
import { displayDate } from '../../lib/util';

import '../PagesCommon.css';
import './PDPOsPage.css';

import PDPOsForm from './PDPOsForm';

const view = {
    LIST_VIEW: 'listView',
    CREATE_PURCHASE_ORDER: 'createPO',
    EDIT_PURCHASE_ORDER: 'editPO',
    DELETE_PURCHASE_ORDER: 'deletePO',
    PURCHASE_ORDER_PROFILE: 'PoProfile',
    OVERRIDE_PURCHASE_ORDER: 'overridePO',
};

export default (props) => {
    let { setIsLoading, setAlertErrorMsg, setAlertMessage } = props;

    let { masterPurchaseOrdersList } = useContext(MasterListContext);
    let { authUser } = useContext(AuthContext);
    let { dataShare } = useContext(DataShareContext);

    let [formView, setFormView] = useState(view.LIST_VIEW);
    let [currentDocument, setCurrentDocument] = useState('');
    let [searchText, setSearchText] = useState('');

    //! ==========================================================
    //? Code to handle creating PO from inventory RM.
    useEffect(() => {
        if (dataShare.isActive) {
            setFormView(view.CREATE_PURCHASE_ORDER);
        }
    }, []);
    //! ==========================================================

    // functions to change views.
    const createPurchaseOrderBtnFn = () =>
        setFormView(view.CREATE_PURCHASE_ORDER);
    const gotoListViewFn = () => setFormView(view.LIST_VIEW);
    const gotoEditViewFn = () => setFormView(view.EDIT_PURCHASE_ORDER);
    const gotoDeleteViewFn = () => setFormView(view.DELETE_PURCHASE_ORDER);
    const gotoPoProfileViewFn = () => setFormView(view.PURCHASE_ORDER_PROFILE);
    const gotoOverRideViewFn = () => setFormView(view.OVERRIDE_PURCHASE_ORDER);

    // function to get pdpoObj document.
    const getDocumentFromDBFn = async (docID) => {
        setIsLoading(true);
        let response = await dbopsPDPOs.getPoFn(docID);
        if (response.error)
            setAlertErrorMsg(response.errorObj.message || response.message);
        else {
            setCurrentDocument(response.data);
            gotoPoProfileViewFn();
        }
        setIsLoading(false);
    };

    // function to create Supplier
    const createPurchaseOrderFn = async (poObj) => {
        //! rmArr are rmObjects with complete schema from RawMaterials collection
        // rmArr needed to be updated in database for costHistory.

        // spinner on.
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        // write to db.
        let response = await dbopsPDPOs.createPoFn(userCreds, poObj);
        if (response.error) setAlertErrorMsg(response.errorObj.message);
        else setAlertMessage(response.message);

        gotoListViewFn();

        // spinner off.
        setIsLoading(false);
    };

    // function to update pdpoObj.
    const updatePurchaseOrderFn = async (poObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsPDPOs.updatePoFn(
            userCreds,
            poObj,
            documentBasis
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);
            // if error is because document has already been deleted,
            // go back to list view.
            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
            // if error is because document has since changed, just continue
            // flow of the program.
        } else setAlertMessage(response.message);

        // this get document ensures state in FE state (currentDocument) is updated.
        gotoListViewFn();
        await getDocumentFromDBFn(poObj.pdpoUID);

        setIsLoading(false);
    };

    // function to delete pdpoObj.
    const deletePurchaseOrderFn = async (poObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsPDPOs.deletePoFn(
            userCreds,
            poObj,
            documentBasis
        );
        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);

            // if error is because document has changed before changes
            // could be applied, get latest version of pdpoObj and
            // go back to pdpoObj profile view.
            if (response.errorObj.code === 1001) {
                gotoListViewFn();
                await getDocumentFromDBFn(poObj.pdpoUID);
                setIsLoading(false); // redundant
                return;
            }
            // all other errors can continue flow of program.
        } else setAlertMessage(response.message);

        gotoListViewFn();

        setIsLoading(false);
    };

    // function for status change of document.
    const statusChangeFn = async (
        userActionPassed,
        poObj,
        documentBasis,
        costHistArr
    ) => {
        setIsLoading(true);

        let userCreds = {
            action: userActionPassed,
            uid: authUser.uid,
            email: authUser.email,
        };

        let dbFn = dbopsPDPOs.statusChangeFn;
        if (userActionPassed === userActions.APPROVED) {
            dbFn = dbopsPDPOs.approvePdpoFn;
        }

        let response = await dbFn(userCreds, poObj, documentBasis, costHistArr);
        if (response.error) {
            // meaning: response.data contains detailed error obj.
            if (response.data) setAlertErrorMsg(response.data);
            else setAlertErrorMsg(response.errorObj.message);

            // if error is because document has already been deleted,
            // go back to list view.
            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }

            // if error has a returned custom error obj in response.data,
            // set a different alert.
            if (response.data) {
            }

            // if error is because document has since changed, just continue
            // flow of the program.
        } else setAlertMessage(response.message);

        // this get document ensures state in FE state (currentDocument) is updated.
        gotoListViewFn();
        await getDocumentFromDBFn(poObj.pdpoUID);

        setIsLoading(false);
    };

    const overRidePurchaseOrderFn = async (poObj, documentBasis) => {
        setIsLoading(true);

        let userCreds = {
            uid: authUser.uid,
            email: authUser.email,
        };

        let response = await dbopsPDPOs.overRidePoFn(
            userCreds,
            poObj,
            documentBasis
        );

        if (response.error) {
            setAlertErrorMsg(response.errorObj.message);
            // if error is because document has already been deleted,
            // go back to list view.
            if (response.errorObj.code === 1000) {
                gotoListViewFn();
                setIsLoading(false);
                return;
            }
            // if error is because document has since changed, just continue
            // flow of the program.
        } else setAlertMessage(response.message);

        // this get document ensures state in FE state (currentDocument) is updated.
        gotoListViewFn();
        await getDocumentFromDBFn(poObj.pdpoUID);

        setIsLoading(false);
    };

    // filter function =====================================
    let filteredPurchaseOrdersList = masterPurchaseOrdersList.filter(
        (pdpoObj) => {
            return (
                pdpoObj.pdpoUID
                    .toUpperCase()
                    .includes(searchText.toUpperCase()) ||
                displayDate(pdpoObj.pdpoDate)
                    .toUpperCase()
                    .includes(searchText.toUpperCase()) ||
                pdpoObj.status
                    .toUpperCase()
                    .includes(searchText.toUpperCase()) ||
                pdpoObj.supplierUID
                    .toUpperCase()
                    .includes(searchText.toUpperCase())
            );
        }
    );

    let addPdpoBtnFn = (
        <button className='btn btn-primary' onClick={createPurchaseOrderBtnFn}>
            Create Purchase Order
        </button>
    );

    if (
        !authUser.permissions.includes(permissions.PDPO_REQUEST) ||
        masterPurchaseOrdersList.length >= lengths.masterPOSDeptsLimits ||
        !authUser.subscriptionStatusIsActive
    ) {
        addPdpoBtnFn = null;
    }

    let topSectionContent = (
        <section className='top-section'>
            <InputIcon
                id='suppliers-search-input'
                type='text'
                placeholder='Search'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                icon={
                    <i
                        className='ms-Icon ms-Icon--Search'
                        aria-hidden='true'></i>
                }
            />
            {addPdpoBtnFn}
        </section>
    );

    // console.log(masterPurchaseOrdersList);
    let mainSectionContent = (
        <ListView2
            getDocumentFromDBFn={getDocumentFromDBFn}
            list={filteredPurchaseOrdersList}
            keyProperty={'pdpoUID'}
            CollectionItemComponent={PDSDItem}
        />
    );

    if (formView !== view.LIST_VIEW) topSectionContent = null;

    if (formView === view.CREATE_PURCHASE_ORDER) {
        mainSectionContent = (
            <PDPOsForm
                purpose='ADD'
                backCancelBtnFn={gotoListViewFn}
                createPOFn={createPurchaseOrderFn}
                setIsLoading={setIsLoading}
                setAlertErrorMsg={setAlertErrorMsg}
            />
        );
    }

    if (formView === view.EDIT_PURCHASE_ORDER) {
        mainSectionContent = (
            <PDPOsForm
                purpose='EDIT'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoPoProfileViewFn}
                updatePOFn={updatePurchaseOrderFn}
                setIsLoading={setIsLoading}
                setAlertErrorMsg={setAlertErrorMsg}
            />
        );
    }

    if (formView === view.DELETE_PURCHASE_ORDER) {
        mainSectionContent = (
            <PDPOsForm
                purpose='DELETE'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoPoProfileViewFn}
                deletePOFn={deletePurchaseOrderFn}
                setIsLoading={setIsLoading}
                setAlertErrorMsg={setAlertErrorMsg}
            />
        );
    }

    if (formView === view.PURCHASE_ORDER_PROFILE) {
        mainSectionContent = (
            <PDPOsForm
                purpose='VIEW'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoListViewFn}
                gotoEditViewFn={gotoEditViewFn}
                gotoDeleteViewFn={gotoDeleteViewFn}
                gotoOverRideViewFn={gotoOverRideViewFn}
                statusChangeFn={statusChangeFn}
                setAlertErrorMsg={setAlertErrorMsg}
            />
        );
    }

    if (formView === view.OVERRIDE_PURCHASE_ORDER) {
        mainSectionContent = (
            <PDPOsForm
                purpose='OVERRIDE'
                currentDocument={currentDocument}
                backCancelBtnFn={gotoPoProfileViewFn}
                overridePOFn={overRidePurchaseOrderFn}
                setIsLoading={setIsLoading}
                setAlertErrorMsg={setAlertErrorMsg}
            />
        );
    }

    return (
        <div className='content-layout'>
            {topSectionContent}

            <section className='main-section'>{mainSectionContent}</section>
        </div>
    );
};
