import React from 'react';
import { InputDS } from '../../components';
import { saveWithFiveDecimals, displayReadableNumber } from '../../lib/util';

import '../PagesCommon.css';
import './ProdOrdersPage.css';

const GroupedRM = (props) => {
    let {
        rmArr,
        sfgUID,
        sfgOrderedQty,
        sfgIndex,
        updateRmValFn,
        masterInventoryRMsList,
    } = props;

    let requiredQty = 0;
    let totalUsed = 0;
    let rmUnequalRequiredAndProjectedQtyError = '';

    let rms = rmArr.map((rm) => {
        requiredQty = saveWithFiveDecimals(
            saveWithFiveDecimals(rm.rmRequiredQty) *
                saveWithFiveDecimals(sfgOrderedQty)
        );
        totalUsed += saveWithFiveDecimals(rm.rmProjectedUseQty);
        rmUnequalRequiredAndProjectedQtyError =
            rm.rmUnequalRequiredAndProjectedQtyError;

        let rawMatInList = masterInventoryRMsList.filter(
            (rawMat) => rawMat.rmUID === rm.rmUID
        )[0];

        let availableQty = `${rawMatInList.rmProjectedQty} ${rawMatInList.rmUnit}`;

        let textWarningColor = '';
        let textWarningColorInput = '';
        if (
            Number(rawMatInList.rmProjectedQty) < Number(rm.rmProjectedUseQty)
        ) {
            textWarningColor = 'text-warning';
            textWarningColorInput = 'text-warning-wrapper';
        }

        return (
            <div
                className='span-2-columns-container'
                key={`${sfgUID}${rm.rmUID}`}>
                <div className='view-table-row-operations'>
                    <div className='cell'>
                        <div className='width16'>
                            <span className='mobile-label'>Grp:</span>{' '}
                            <span className='desktop-view m-left-15'>
                                {' '}
                                {rm.group}
                            </span>
                            <span className='mobile-label'> {rm.group}</span>
                        </div>
                        <div className=''>
                            <span className='mobile-label'>RM:</span>{' '}
                            <span> {rm.rmUID}</span>
                        </div>
                    </div>

                    <div className='cell'>
                        <div className='width33'>
                            {' '}
                            <span className='mobile-label'>
                                Available RM Qty:
                            </span>{' '}
                            <span className={`${textWarningColor}`}>
                                {availableQty}
                            </span>
                        </div>
                        <div className='width33'>
                            <span className='mobile-label'>
                                Projected Qty (Planned):
                            </span>{' '}
                            <div className={`${textWarningColorInput}`}>
                                <InputDS
                                    type='number'
                                    initialValue={rm.rmProjectedUseQty}
                                    getInputValue={(val) =>
                                        updateRmValFn(
                                            val,
                                            sfgIndex,
                                            rm.tempRmIndex,
                                            'rmProjectedUseQty'
                                        )
                                    }
                                    errorMessage={
                                        rm.rmProjectedUseQtyError
                                    }></InputDS>
                            </div>
                        </div>
                        <div className='width33 pad-left-15'>
                            {' '}
                            <span className='mobile-label'>Unit:</span>{' '}
                            {rm.rmUnit}
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    let redText = totalUsed === requiredQty ? '' : 'text-red';

    return (
        <div className='sfg-group'>
            {rms}
            <div className='view-table-row-operations'>
                <div className='cell'></div>
                <div className='cell'>
                    <span className='mobile-label m-right-5'>Grp Qty:</span>{' '}
                    <span className='desktop-view width33'></span>
                    <span className={`total-used m-right-5 ${redText}`}>
                        {displayReadableNumber(totalUsed)}
                    </span>
                    <span className={`denominator ${redText}`}>
                        / {displayReadableNumber(requiredQty)}
                    </span>
                </div>
            </div>
            <div className='view-table-row-operations'>
                <div className='cell'></div>
                <div className='cell'>
                    <span className='error-message'>
                        {rmUnequalRequiredAndProjectedQtyError}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default (props) => {
    let {
        sfgObj,
        sfgIndex,

        updateSfgValFn,
        updateRmValFn,

        trashIcon,

        masterInventoryRMsList,
    } = props;

    let groupedRmMap = {};

    sfgObj.rmArr.forEach((rm, rmIndex) => {
        rm.tempRmIndex = rmIndex;
        if (groupedRmMap[rm.group] === undefined) {
            groupedRmMap[rm.group] = [];
            groupedRmMap[rm.group].push(rm);
        } else groupedRmMap[rm.group].push(rm);
    });

    let listOfRms = [];

    for (let gkey in groupedRmMap) {
        let component = (
            <GroupedRM
                key={`${sfgObj.sfgUID}${gkey} `}
                sfgIndex={sfgIndex}
                rmArr={groupedRmMap[gkey]}
                updateRmValFn={updateRmValFn}
                sfgUID={sfgObj.sfgUID}
                sfgOrderedQty={sfgObj.sfgOrderedQty}
                masterInventoryRMsList={masterInventoryRMsList}
            />
        );
        listOfRms.push(component);
    }

    return (
        <div className='subform'>
            <div className='subform-label-qtys-section'>
                <div className='label'>
                    {' '}
                    {`${sfgIndex + 1}. ${sfgObj.sfgUID}`}
                </div>

                <div className='icon'>{trashIcon}</div>
            </div>

            <div className='subform-2-column-section'>
                <div className='flex-row'>
                    <InputDS
                        label='Order Quantity'
                        type='number'
                        initialValue={sfgObj.sfgOrderedQty}
                        getInputValue={(val) =>
                            updateSfgValFn(val, sfgIndex, 'sfgOrderedQty')
                        }
                        errorMessage={sfgObj.sfgOrderedQtyError}></InputDS>
                    <span className='m-left-10 pad-top-10'>
                        {sfgObj.sfgUnit}
                    </span>
                </div>
            </div>

            <div className='subform-list-section'>
                <div className='sls-label sls-item'>
                    <div className='cell'>RM List </div>
                    <div className='cell desktop-view'></div>
                </div>
            </div>

            <div className='span-2-columns-container'>
                <div className='view-table-heading-operations'>
                    <div className='cell'>
                        <span className='m-left-15 width16'>Grp</span>
                        <span className=''>RM Type - Description</span>
                    </div>
                    <div className='cell'>
                        <span className='width33'>Available RM Qty</span>
                        <span className='width33'>Projected Qty (Planned)</span>
                        <span className='width33 pad-left-15'>Unit</span>
                    </div>
                </div>
            </div>

            {listOfRms}
        </div>
    );
};
