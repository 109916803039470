export default (supplierMasterlistStringFormat) => {
    let newArr = [];
    let supplierObjsInObjFormat = JSON.parse(supplierMasterlistStringFormat);

    for (let key in supplierObjsInObjFormat) {
        newArr.push({
            supplierUID: key,
            contactPerson: supplierObjsInObjFormat[key].a,
            contactNumber1: supplierObjsInObjFormat[key].b,
        });
    }

    return newArr;
};
